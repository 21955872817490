import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const NoShowsBook = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.5 23.5H5.607h0a2.075 2.075 0 01-2.08-1.664h0A2 2 0 015.5 19.5h14a1 1 0 001-1v-17 0a1 1 0 00-1-1h-14 0a2 2 0 00-2 2s0 0 0 0v19M6.5 21.5h14"></path>
        <path d="M7.26 9.914h0a.489.489 0 010-.584h0a6.739 6.739 0 015.271-2.888h0A6.739 6.739 0 0117.8 9.33h0a.489.489 0 010 .584h0a6.742 6.742 0 01-5.269 2.886h0A6.742 6.742 0 017.26 9.914zM15.06 7.02L10 12.23"></path>
      </g>
    </StyledIcon>
  )
}

export default NoShowsBook
