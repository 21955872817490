import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getOrgId,
  getLocationId,
  getUserAvatar,
  formatPhone,
} from 'src/helpers'
import { components } from 'react-select'
import transparentize from 'polished/lib/color/transparentize'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SelectOptionLabel, SelectRow } from 'views/AppointmentModal/styles'
import { Select } from 'components/Select2/Select'
import { icons } from 'components/Icon'

interface Props {
  onSelectClick?: any
  onBlur?: any
  selectedEmployees?: any
  allEmployees?: any
}
export const AddEmployeesSelect = ({
  onSelectClick,
  onBlur,
  selectedEmployees,
  allEmployees,
}: Props) => {
  const selectedTheme = useContext(ThemeContext)
  const selectStyles = {
    control: (styles, other) => {
      return {
        ...styles,
        boxShadow: 'none',
        backgroundColor: selectedTheme.colors.white + ' !important',
        fontSize: '13px', // to match the button font next to the select
        fontWeight: '400 !important',
        minWidth: '170px',
        width: '170px',
        height: '32px',
        padding: '0 !important',
        borderColor: selectedTheme.colors.primary + ' !important',
      }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.accent3)
          : null,
        fontWeight: isSelected ? 500 : 400,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.accent3),
        },
      }
    },
    valueContainer: styles => {
      return {
        ...styles,
        margin: 0,
        padding: '0 10px !important',
      }
    },
    placeholder: styles => {
      return {
        ...styles,
        color: selectedTheme.colors.primary + ' !important',
      }
    },
    clearIndicator: styles => {
      return {
        ...styles,
        display: 'none',
      }
    },
    indicatorSeparator: styles => {
      return {
        ...styles,
        display: 'none',
      }
    },
    dropdownIndicator: styles => {
      return {
        ...styles,
        color: selectedTheme.colors.primary + ' !important',
      }
    },
    menuList: styles => {
      return {
        ...styles,
        width: '220px !important',
        maxWidth: '220px !important',
      }
    },
    menu: styles => {
      return {
        ...styles,
        width: '220px !important',
        maxWidth: '220px !important',
      }
    },
    input: styles => {
      return {
        ...styles,
        color: selectedTheme.colors.primary + ' !important',
      }
    },
  }

  const { t } = useTranslation()

  return (
    <Select
      closeMenuOnSelect={false}
      value={selectedEmployees}
      hideSelectedOptions={false}
      isMulti
      components={selectComponents}
      options={allEmployees}
      styles={selectStyles}
      onChange={onSelectClick}
      placeholder={t('translation.LocationEmployees.label-add-existing')}
      controlShouldRenderValue={false}
      onBlur={onBlur}
    />
  )
}

const Option = props => {
  return (
    <components.Option {...props}>
      <SelectRow>
        {props.isSelected && (
          <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
            {props.isSelected && <icons.CheckMarkIcon color="grayDarker" />}
          </span>
        )}

        {getUserAvatar(props.data, true, 'smaller')}
        <SelectOptionLabel
          style={{
            textOverflow: 'ellipsis !important',
            maxWidth: '100%',
          }}
        >
          {props.data.firstName + ' ' + props.data.lastName}
        </SelectOptionLabel>
      </SelectRow>
    </components.Option>
  )
}

const MobileOption = props => {
  const isSelected = props.selectProps.value?.find(
    val => val.id === props.data?.id
  )

  return (
    <SelectRow>
      <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
        {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
      </span>

      <div className="w-[40px]">
        {getUserAvatar(props.data, true, 'smaller')}
      </div>
      <SelectOptionLabel>
        {props.data.firstName + ' ' + props.data.lastName}
      </SelectOptionLabel>
    </SelectRow>
  )
}

const IndicatorsContainer = props => {
  return (
    <div
      style={{
        cursor: 'pointer',
      }}
    >
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const selectComponents = {
  IndicatorsContainer,
  Option,
  MobileOption,
}
