import styled from 'styled-components'
import TooltipImport from '@reach/tooltip'
import {
  fontFamily,
  fontSizeSmall,
  fontWeight,
  lineHeightSmall,
} from '../style/fonts'
import { grayDarker, white } from '../style/colors'
import { viteLegacyImport } from 'src/sdk/viteLegacyImport'
const Tooltip = viteLegacyImport(TooltipImport)
export const TooltipWrapper = styled.div`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  z-index: 8;
  height: 100%;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`

export const TooltipStyled = styled(Tooltip)``

export const TooltipStyle = {
  position: 'absolute',
  background: grayDarker,
  color: white,
  border: 'none',
  borderRadius: '0.286rem',
  padding: '0.571rem',
  maxWidth: '300px',
  whiteSpace: 'normal',
  zIndex: 9999,
}

export const Label = styled.label`
  color: ${props => props.color};
  font-family: ${fontFamily};
  font-size: ${props => (props.isHotkey ? '0.714rem' : fontSizeSmall)};
  margin-left: ${props => (props.isHotkey ? '0.571rem' : '0')};
  font-weight: ${fontWeight};
  line-height: ${lineHeightSmall};
  cursor: inherit;
`

export const HotkeyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LabelWrapper = styled.div``
