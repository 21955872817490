import React from 'react'
import TooltipImport from 'rc-tooltip'
import PropTypes from 'prop-types'
import { useSdk } from 'sdk'
import { viteLegacyImport } from 'src/sdk/viteLegacyImport'
const Tooltip = viteLegacyImport(TooltipImport)

export const Tooltip2 = props => {
  const overlayStyle = {
    borderRadius: '4px',
    background: 'transparent',
  }

  const overlayInnerStyle = {
    border: 'none',
    backgroundColor: '#333',
    color: '#ffffff',
    width: 'auto',
    height: 'auto',
    padding: '10px',
  }

  const { t, history } = useSdk()
  const { useHistoryRouting } = props

  return (
    <Tooltip
      placement={'top'}
      overlayStyle={overlayStyle}
      overlayInnerStyle={overlayInnerStyle}
      overlay={
        <div className="max-w-[250px]">
          <div className="whitespace-pre-wrap">
            {props.helpText}{' '}
            {props.helpLink && (
              <a
                className="text-zoyya-accent3 underline ml-[2px] inline cursor-pointer"
                onClick={
                  useHistoryRouting
                    ? () =>
                        props.helpLink ? history.push(props.helpLink) : null
                    : undefined
                }
                href={useHistoryRouting ? undefined : props.helpLink}
                target={
                  useHistoryRouting
                    ? undefined
                    : props.isSameTab
                    ? ''
                    : '_blank'
                }
              >
                {t('translation.Tooltip2.label-here')}
              </a>
            )}
          </div>
        </div>
      }
      arrowContent={<div className="rc-tooltip-arrow-inner infoButton" />}
    >
      {props.children}
    </Tooltip>
  )
}

Tooltip2.propTypes = {
  /** onClick function */
  onClick: PropTypes.func,
  /** The alt text for the avatar image. */
  helpText: PropTypes.string,
  /** The fallback url for the avatar image. */
  helpLink: PropTypes.string,
  /** Uses the react router for navigatin when `true`, otherwise it uses default browser routing. Defaults to false. */
  useHistoryRouting: PropTypes.bool,
  /** If using default browser routing, determines if the link will be opened in the same tab or new one */
  isSameTab: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

Tooltip2.defaultProps = {
  helpText: '',
  helpLink: '',
  useHistoryRouting: false,
  isSameTab: true,
}
