import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppServices } from 'sdk/appServices'
import { useSdk } from 'sdk'
import {
  Loader,
  FormContentContainer,
  SectionContainer,
  FormField,
  Button,
  icons,
  Form,
  Tab,
  TabView,
} from 'components'
import { FormFieldsWrapper, FormFooterContainer } from 'components/Form/Form'
import {
  FormPageContainer,
  TableItem,
  ListBodyWrapper,
  ListContainer,
} from 'src/styles'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import moment from 'moment'
import styled from 'styled-components'
import ReactTable from 'components/Table/Table'
import {
  useCreatePosDeviceMutation,
  useDeletePosDeviceMutation,
  usePosDeviceAllQuery,
  useUpdatePosDeviceMutation,
  PosDeviceAllDocument,
  usePosDevice_GetQuery,
  usePosOperatorsAllQuery,
  PosDevice,
  usePosActiveJournalGetQuery,
  PosDevice_HasIssuedReceiptsDocument,
  Exact,
  PosDeviceAllQuery,
  PosActiveJournalGetQuery,
} from 'state/graphql'
import Dropzone from 'react-dropzone'
import {
  Container,
  Modal,
  ModalWrapper,
  FormWrapper,
  ClientWarningRow,
  ClientWarningText,
} from 'views/Appointment/Appointment'
import { WarningTriangle } from 'components/Icon/Icons/WarningTriangle'
import { useParams } from 'react-router-dom'
import Tooltip from 'components/Tooltip/Tooltip'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { useTableHeightCalculator } from 'components/Table/useTableHeightCalculator'
import {
  IPosDevice,
  PosReceiptStore,
  printPDF,
  printTestPos,
} from 'state/local/posReceiptStore'
import { usePosStore } from 'state/local/posReceiptStore'
import { CashRegister } from 'components/Icon/Icons/CashRegister'
import {
  formatPriceNumberWithDelimiter,
  isIBANValid,
  isOibValid,
  getFullReportSrc,
} from 'src/helpers'
import * as forge from 'node-forge'
import { StyledFieldsContainer } from 'components/Form/Form'
import { device } from '../../constants'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import * as JSPM from 'jsprintmanager'
import { withErrorHandler } from 'sdk/widthErrorHandler'
import { assert } from 'sdk/assert'
import { useFeatureFlags } from 'src/state/local/appStore'
import { RadioButton } from 'src/components/Radio/Radio'

type TServiceRowWrapperProps = {
  canRemoveService?: boolean
  freeSpace?: boolean
}
export const ServiceRowWrapper = styled.div<TServiceRowWrapperProps>`
  position: relative;
  border: 1px solid #dbdfe26b;
  background: #f4f4ff96;
  border-radius: 4px 0 0 4px;
  padding: 10px 20px;
  ${StyledFieldsContainer} {
    background: ${props => props.theme.colors.lightBackground};
  }
  width: 100%;
  margin-bottom: 20px;

  @media ${device.tablet} {
    padding: ${props => (props.canRemoveService ? '10px' : '10px 15px')};
    border: ${props => (props.freeSpace ? '1px solid #dbdfe26b' : 'none')};
    overflow: hidden;
    padding-right: ${props =>
      props.freeSpace ? '5px' : props.canRemoveService ? '10px' : '15px'};
  }
`

type AnyObject = {
  [key: string]: any
}

type Attribute = {
  name: string
  value: string
  [key: string]: any
}

type CertConf = {
  commonName?: string
  organizationName?: string
  organizationUnit?: string
  locality?: string
  stateOrProvinceName?: string
  countryName?: string
  emailAddress?: string
}

export type InfoType = {
  friendlyName?: string
  subject: CertConf
  issuer: CertConf
  serialNumber: string
  version: number
  validity: {
    notBefore: Date
    notAfter: Date
  }
  isValid: Boolean
  altNames?: string[]
}

export const getP12CertInfo = (
  certBase64: string,
  password: string
): InfoType | null => {
  try {
    const p12Der = forge.util.decode64(certBase64)
    const p12Asn1 = forge.asn1.fromDer(p12Der)
    const p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, false, password)
    const bags = p12.getBags({ bagType: forge.pki.oids.certBag })

    if (!bags || !bags?.[forge.pki.oids.certBag]?.[0])
      throw new Error(
        'Neuspjela verifikacija certifikata, molimo provjerite lozinku i pokušajte ponovno.'
      )

    // @ts-ignore
    const data = bags[forge.pki.oids.certBag][0]

    return {
      friendlyName: data.attributes.friendlyName[0],
      subject: data.cert.subject?.attributes.reduce(
        (a: AnyObject, r: Attribute) => {
          r.name && (a[r.name] = Buffer.from(r.value, 'latin1').toString())
          return a
        },
        {}
      ),
      issuer: data.cert.issuer?.attributes.reduce(
        (a: AnyObject, r: Attribute) => {
          r.name && (a[r.name] = Buffer.from(r.value, 'latin1').toString())
          return a
        },
        {}
      ),
      serialNumber: data.cert.serialNumber,
      version: data.cert.version,
      validity: data.cert.validity,
      isValid:
        data.cert.validity.notBefore <= new Date() &&
        data.cert.validity.notAfter >= new Date(),
      altNames: data.cert.extensions
        .find((r: Attribute) => (r.name = 'subjectAltName'))
        ?.altNames?.map((r: Attribute) => r.value),
    }
  } catch (err) {
    console.log(err)
    return {
      error: 'Neuspjela verifikacija certifikata, molimo pokušajte ponovno.',
    } as any
  }
}

export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

const getCertDataObject = (
  str: string
): {
  issuer: string
  subject: string
  notBefore: Date
  notAfter: Date
  certVatId: string
} | null => {
  const obj = JSON.parse(str || '{}')

  if ('issuerDN' in obj) {
    const vatParts = obj?.subjectX500Principal?.split(', ')
    const vatId = vatParts[2].split(' ').at(-1).slice(2)

    return {
      issuer: obj.issuerDN,
      subject: obj.subjectX500Principal,
      notBefore: obj.notBefore,
      notAfter: obj.notAfter,
      certVatId: vatId,
    }
  } else if ('issuer' in obj) {
    const vatParts = obj?.subject?.organizationName?.split(' ')
    const certVatId = vatParts?.at(-1)?.slice(2)
    return {
      issuer: `${obj?.issuer?.commonName}, ${obj?.issuer?.organizationName}, ${obj?.issuer?.countryName}`,
      subject: `${obj?.subject?.commonName}, ${obj?.subject?.organizationName}, ${obj?.subject?.countryName}`,
      notBefore: obj?.validity?.notBefore,
      notAfter: obj?.validity?.notAfter,
      certVatId,
    }
  } else return null
}

const DeviceSchema = t =>
  Yup.object().shape({
    storeName: Yup.string().required(
      t('translation.ServiceEditorForm.validation-name-required')
    ),
    fisDeviceId: Yup.string().required(
      'Oznaka naplatnog uređaja je obvezno polje'
    ),
    fisLocationId: Yup.string().required(
      'Oznaka poslovnog prostora je obvezno polje'
    ),
    storeAddress: Yup.string().required('Adresa je obvezno polje'),
    storeCity: Yup.string().required('Grad je obvezno polje'),
    storeVatId: Yup.string()
      .required('OIB je obvezno polje')
      .test('validStoreVatId', 'Format OIB-a nije ispravan', function (value) {
        return (
          value?.length === 11 &&
          !value?.includes(' ') &&
          !!value?.match(/^[0-9]\d*(\.\d+)?$/) &&
          isOibValid(value)
        )
      }),
    storeIban: Yup.string()
      .nullable()
      .test('validStoreIban', 'Format IBAN-a nije ispravan', function (value) {
        if (!value) return true
        return isIBANValid(value)
      }),
    defaultDepositAmount: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    storeZip: Yup.string()
      .required('Poštanski broj je obvezno polje')
      .matches(
        /^[0-9]\d*(\.\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      ),
    printerCols: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    codeTable: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    characterSet: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
  })
type TPosSettingsDevicesModalProps = {
  closeModal: () => any
  loading: boolean
  isNew: boolean
  deviceId?: string
  activeJournal: PosActiveJournalGetQuery['posActiveJournal_get']
  refetchDevices: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<PosDeviceAllQuery>>
}
const PosSettingsDevicesModal = (props: TPosSettingsDevicesModalProps) => {
  const {
    closeModal,
    loading,
    isNew,
    deviceId,
    activeJournal,
    refetchDevices,
  } = props
  const { t } = useTranslation()
  const appServices = useAppServices()
  const { apolloClient } = appServices
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [isAdvancedOpened, setIsAdvancedOpened] = useState<boolean>(false)
  const [isPrinterTesting, setIsPrinterTesting] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(true)
  const formRef = useRef<any>()
  const { orgId } = useParams<any>()

  const { showPopup } = usePopup()

  const { selectPosDevice } = PosReceiptStore
  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)

  const { data: posOperators, loading: loadingPosOperators } =
    usePosOperatorsAllQuery()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
      skip: !isNew,
    }
  )

  const org = organizationData?.organization?.get

  const { data: posDeviceData, loading: posDeviceLoading } =
    usePosDevice_GetQuery({
      variables: {
        id: deviceId || '',
      },
      skip: !deviceId,
    })
  const posDevice = posDeviceData?.posDevice_get
  const posDeviceCertData = getCertDataObject(posDevice?.certData as any)

  const featuresFlags = useFeatureFlags()
  const printClient = featuresFlags.printClient

  const [installedPrinters, setInstalledPrinters] = useState<any>([])
  const [isPrinterInstalled, setIsPrinterInstalled] = useState<boolean>(false)

  const getPrinters = useCallback(async () => {
    try {
      if (printClient === 'neodynamicPrintService') {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters().then(function (printersList) {
            setInstalledPrinters(printersList)
          })
          setIsPrinterInstalled(true)
        }
      } else {
        const printResponse = await fetch(
          'http://localhost:7969/print/printers',
          {
            method: 'GET',
          }
        )
        const result:
          | { name: string; isDefault: boolean; status: string }[]
          | [] = await printResponse.json()

        setIsPrinterInstalled(true)
        setInstalledPrinters(
          result?.map(x => {
            return x?.name
          })
        )
      }
    } finally {
      setIsBusy(false)
    }
  }, [])

  useEffect(() => {
    getPrinters()
  }, [getPrinters])

  const [uploadedCertificateFile, setUploadedCertificateFile] =
    useState<File | null>(null)
  const [uploadedCertificateData, setUploadedCertificateData] = useState<any>()
  const [uploadedCertificateError, setUploadedCertificateError] =
    useState<any>()

  const [createDeviceMutation] = useCreatePosDeviceMutation()
  const [updateDeviceMutation] = useUpdatePosDeviceMutation()
  //const [deleteDeviceMutation] = useDeletePosDeviceMutation()

  const handleCertificateSelect = async acceptedFiles => {
    if (acceptedFiles?.[0]?.type?.includes('x-pkcs12')) {
      setUploadedCertificateFile(acceptedFiles[0])
    }
  }

  const handleVerifyCertificate = async (password: string) => {
    const base64: string = (await fileToBase64(
      uploadedCertificateFile
    )) as string
    const base64Stripped = base64.slice(base64.indexOf('base64,') + 7)
    const cert = getP12CertInfo(base64Stripped, password)

    //@ts-ignore
    if (cert.error) {
      appServices.toast.danger(
        'Neispravna lozinka za otključavanje certifikata'
      )
      //@ts-ignore
      setUploadedCertificateError(cert.error)
    } else {
      const vatParts = cert?.subject?.organizationName?.split(' ')
      const certVatId = vatParts?.at(-1)?.slice(2)
      setUploadedCertificateData({
        issuer: `${cert?.issuer?.commonName}, ${cert?.issuer?.organizationName}, ${cert?.issuer?.countryName}`,
        subject: `${cert?.subject?.commonName}, ${cert?.subject?.organizationName}, ${cert?.subject?.countryName}`,
        notBefore: cert?.validity?.notBefore,
        notAfter: cert?.validity?.notAfter,
        certVatId,
      })
    }
  }

  const certificateInput = useRef<any>()

  const handleSubmit = async values => {
    if (!values) return
    try {
      setIsBusy(true)

      const result =
        !values?.id && isNew
          ? await createDeviceMutation({
              variables: {
                // file: uploadedCertificateData ? uploadedCertificateFile : null,
                input: {
                  storeName: values?.storeName,
                  storeAddress: values?.storeAddress,
                  storeCity: values?.storeCity,
                  storeZip: values?.storeZip,
                  storeVatId: values?.storeVatId,
                  storeIban: values?.storeIban,
                  defaultDepositAmount: values?.defaultDepositAmount
                    ? parseFloat(values?.defaultDepositAmount)
                    : 0,
                  fisDeviceId: values?.fisDeviceId,
                  fisLocationId: values?.fisLocationId,
                  defaultOperatorId: values?.defaultOperatorId,
                  fisCertificatePassword: values?.certPassword,
                  printerName: values?.printerName?.id || null,
                  deviceName: values?.deviceName,
                  printerCols: Number(values?.printerCols),
                  printerBrand: values?.printerBrand,
                  printerModel: values?.printerModel,
                  encoding: values?.encoding,
                  codeTable: Number(values?.codeTable),
                  characterSet: Number(values?.characterSet),
                  pulsePin: values?.pulsePin?.id || null,
                  printerType: values?.printerType?.id || 'ROLL',
                  vatStatus: values.vatStatus,
                  vatExemptText: values.vatExemptText,
                },
              },
              refetchQueries: [
                {
                  query: PosDeviceAllDocument,
                },
              ],
            })
          : await updateDeviceMutation({
              variables: {
                //file: uploadedCertificateData ? uploadedCertificateFile : null,
                input: {
                  id: values?.id,
                  storeName: values?.storeName,
                  storeAddress: values?.storeAddress,
                  storeCity: values?.storeCity,
                  storeZip: values?.storeZip,
                  storeVatId: values?.storeVatId,
                  storeIban: values?.storeIban,
                  defaultDepositAmount: values?.defaultDepositAmount
                    ? parseFloat(values?.defaultDepositAmount)
                    : 0,
                  fisDeviceId: values?.fisDeviceId,
                  fisLocationId: values?.fisLocationId,
                  defaultOperatorId: values?.defaultOperatorId,
                  fisCertificatePassword: values?.certPassword,
                  printerName: values?.printerName?.id || null,
                  deviceName: values?.deviceName,
                  printerCols: Number(values?.printerCols),
                  printerBrand: values?.printerBrand,
                  printerModel: values?.printerModel,
                  encoding: values?.encoding,
                  codeTable: Number(values?.codeTable),
                  characterSet: Number(values?.characterSet),
                  pulsePin: values?.pulsePin?.id || null,
                  printerType: values?.printerType?.id || 'ROLL',
                  vatStatus: values.vatStatus,
                  vatExemptText: values.vatExemptText,
                },
              },
              refetchQueries: [
                {
                  query: PosDeviceAllDocument,
                },
              ],
            })
      if (result.errors?.length) {
        appServices.toast.danger(result?.errors[0]?.message)
      } else {
        if (uploadedCertificateData) {
          const formData = new FormData()

          // file must be appended last for fastify-multipart to work
          const posDeviceId = isNew
            ? result?.data?.['posDevice_create']?.id
            : result?.data?.['posDevice_update']?.id
          formData.append('deviceId', posDeviceId)
          formData.append('password', values?.certPassword)
          formData.append('file', uploadedCertificateFile as Blob)

          const response = await fetch(
            `${import.meta.env.VITE_API_URL}/api/upload/${orgId}/fisCertUpload`,
            {
              method: 'POST',
              body: formData,
              credentials: 'include',
            }
          )
          const resultJson = await response.json()

          if (!response.ok || !resultJson?.success) {
            appServices.toast.danger(
              'Došlo je do pogreške prilikom prijenosa certifikata'
            )
          } else {
            await refetchDevices()
          }
        }
        if (isNew) {
          appServices.toast.success('Naplatni uređaj uspješno dodan')
        } else {
          const updatedDevice: PosDevice = result?.data?.['posDevice_update']

          if (selectedPosDevice?.id === updatedDevice?.id) {
            selectPosDevice(updatedDevice)
          }
          appServices.toast.success('Naplatni uređaj uspješno ažuriran')

          if (isPrinterTesting)
            handlePrint(
              values?.id,
              values?.printerType?.id,
              values?.printerName?.id
            )
        }
        closeModal()
      }
    } catch (err) {
      appServices.toast.danger('Došlo je do pogreške')
      //console.log(err.message)
    } finally {
      setIsBusy(false)
    }
  }

  const initialValues = isNew
    ? {
        storeName: org?.orgName,
        storeAddress: org?.address,
        storeCity: org?.city,
        storeZip: org?.zipCode,
        storeVatId: org?.vatId,
        storeIban: org?.iban,
        printerCols: '48',
        printerBrand: '',
        printerModel: '',
        encoding: '',
        codeTable: 39,
        characterSet: 14,
        vatStatus: org?.vatStatus,
        printerName:
          installedPrinters?.length > 0
            ? {
                id: installedPrinters[0],
                title: installedPrinters[0],
              }
            : null,
        printerType: {
          id: 'ROLL',
          title: 'ROLA',
        },
      }
    : {
        ...posDevice,
        pulsePin: posDevice?.pulsePin
          ? {
              id: posDevice?.pulsePin,
              title: posDevice?.pulsePin,
            }
          : null,
        printerType: posDevice?.printerType
          ? {
              id: posDevice?.printerType,
              title: posDevice?.printerType === 'ROLL' ? 'ROLA' : 'A4',
            }
          : {
              id: 'ROLL',
              title: 'ROLA',
            },
        printerName: posDevice?.printerName
          ? {
              id: posDevice?.printerName,
              title: posDevice?.printerName,
            }
          : null,
        codeTable: posDevice?.codeTable || 39,
        characterSet: posDevice?.characterSet || 14,
        vatStatus: posDevice?.vatStatus || org?.vatStatus,
      }
  const vatStatusOptions = [
    { id: 'Y', title: t('translation.OrganizationDesktop.pdv-yes') },
    { id: 'N', title: t('translation.OrganizationDesktop.pdv-no') },
  ]

  const handlePrint = withErrorHandler(
    async (deviceId: string, printType: string, printerName: string) => {
      assert(deviceId, 'No device selected')

      const result =
        printType === 'ROLL'
          ? await printTestPos(printClient, deviceId)
          : await printPDF(
              printClient,
              'https://files.zoyya.com/files/printTest.pdf',
              printerName,
              'testPDF.pdf',
              false,
              'Račun je uspješno isprintan!'
            )

      result?.success
        ? appServices.toast.success(result?.text)
        : appServices.toast.danger(result?.text)
    }
  )

  const handleDefaultDeviceSelect = async event => {
    event.preventDefault()
    event.stopPropagation()
    if (!posDevice) return

    if (selectedPosDevice && selectedPosDevice?.id === posDevice?.id) {
      selectPosDevice(posDevice as IPosDevice)
      //closeModal()
      appServices?.toast.success(
        `Uređaj ${
          posDevice?.deviceName ? posDevice?.deviceName + ' ' : ' '
        }uspješno postavljen kao zadan`
      )
      return
    }

    if (selectedPosDevice && selectedPosDevice?.id !== posDevice?.id) {
      showPopup({
        onConfirm: () => {
          selectPosDevice(posDevice as IPosDevice)
          appServices?.toast.success(
            `Uređaj ${
              posDevice?.deviceName ? posDevice?.deviceName + ' ' : ' '
            }uspješno postavljen kao zadan`
          )
        },
        titleText: 'Jeste li sigurni da želite promijeniti naplatni uređaj?',
        contentText:
          'Promjena utječe na brojeve računa! Ako niste sigurni kontaktirajte Zoyya podršku.',
      })
      return
    } else {
      // check if selected device has previous issued receipts
      const result = await apolloClient.query({
        query: PosDevice_HasIssuedReceiptsDocument,
        variables: {
          id: posDevice.id,
        },
      })
      if (result.errors) {
        appServices?.toast.danger(result.errors[0].message)
      } else {
        const hasIssuedReceipts = result.data
        if (hasIssuedReceipts) {
          showPopup({
            onConfirm: () => {
              selectPosDevice(posDevice as IPosDevice)
              appServices?.toast.success(
                `Uređaj ${
                  posDevice?.deviceName ? posDevice?.deviceName + ' ' : ' '
                }uspješno postavljen kao zadan`
              )
            },
            titleText: `Jeste li sigurni da ovo računalo želite povezati sa naplatnim uređajem ${posDevice.fisLocationId}/${posDevice.fisDeviceId}?`,
            contentText:
              'Odabrani naplatni uređaj je već korišten za izdavanje računa! Ako niste sigurni kontaktirajte Zoyya podršku.',
          })
        } else {
          selectPosDevice(posDevice as IPosDevice)
          appServices?.toast.success(
            `Uređaj ${
              posDevice?.deviceName ? posDevice?.deviceName + ' ' : ' '
            }uspješno postavljen kao zadan`
          )
        }
      }
    }
  }

  const getOs = () => {
    const os = ['Windows', 'Linux', 'Mac'] // add your OS values
    return os.find(v => navigator.appVersion.indexOf(v) >= 0)
  }

  const checkPrinterFormValues = (form: any) => {
    if (!selectedTabIndex && !!form?.errors?.codeTable) setSelectedTabIndex(1)

    if (
      !selectedTabIndex &&
      (!!form?.errors?.characterSet || !!form?.errors?.printerCols)
    ) {
      setIsAdvancedOpened(true)
      setSelectedTabIndex(1)
    }
    if (
      selectedTabIndex &&
      (form?.errors?.storeName ||
        form?.errors?.fisDeviceId ||
        form?.errors?.fisLocationId ||
        form?.errors?.storeAddress ||
        form?.errors?.storeCity ||
        form?.errors?.storeVatId ||
        form?.errors?.storeIban ||
        form?.errors?.defaultDepositAmount ||
        form?.errors?.storeZip)
    )
      setSelectedTabIndex(0)

    return !!form?.errors
  }

  return (
    <aside
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        minWidth: 200,
        background: 'white',
        zIndex: 12200,
      }}
    >
      <Container>
        <Modal>
          <ModalWrapper>
            <FormWrapper>
              {loading ||
              isBusy ||
              posDeviceLoading ||
              loadingPosOperators ||
              loadingOrgData ? (
                <div style={{ minHeight: '500px' }}>
                  <Loader isComponent />
                </div>
              ) : (
                <Form
                  initialValues={initialValues}
                  isInitialValid={true}
                  validationSchema={DeviceSchema(t)}
                  onSubmit={handleSubmit}
                >
                  {form => {
                    formRef.current = form
                    return (
                      <>
                        <TabView
                          selectedTabIndex={selectedTabIndex}
                          setSelectedTabIndex={setSelectedTabIndex}
                          isSingleTab={false}
                          hasFooter={false}
                        >
                          <Tab
                            hasFooter={false}
                            title={
                              isNew
                                ? 'Dodaj naplatni uređaj'
                                : 'Uredi naplatni uređaj'
                            }
                          >
                            <div className="p-[20px] flex flex-col h-[inherit]">
                              <FieldRow>
                                <FieldColumn
                                  style={{ flexDirection: 'column' }}
                                >
                                  <FieldRow style={{ marginBottom: 5 }}>
                                    <FormField.Text
                                      label={'Naziv organizacije'}
                                      placeholder={
                                        'Naziv koji će se ispisati na računu'
                                      }
                                      name="storeName"
                                      spellCheck="false"
                                      autoFocus={!form?.values?.storeName}
                                      type="text"
                                    />
                                  </FieldRow>
                                  <FieldRow style={{ marginBottom: 5 }}>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Poslovni prostor'}
                                        placeholder={
                                          'Oznaka poslovnog prostora'
                                        }
                                        name="fisLocationId"
                                        type="text"
                                      />
                                    </FieldColumn>

                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Naplatni uređaj'}
                                        placeholder={'Oznaka naplatnog uređaja'}
                                        name="fisDeviceId"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                  </FieldRow>
                                  <FieldRow style={{ marginBottom: 5 }}>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Adresa'}
                                        placeholder={'Adresa'}
                                        name="storeAddress"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Grad'}
                                        placeholder={'Grad'}
                                        name="storeCity"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                  </FieldRow>
                                  <FieldRow style={{ marginBottom: 5 }}>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Poštanski broj'}
                                        placeholder={'Pošt. broj'}
                                        name="storeZip"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'OIB'}
                                        placeholder={'OIB'}
                                        name="storeVatId"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                  </FieldRow>
                                  <FieldRow style={{ marginBottom: 5 }}>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Polog'}
                                        placeholder={'Iznos pologa'}
                                        name="defaultDepositAmount"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'IBAN'}
                                        placeholder={'IBAN'}
                                        name="storeIban"
                                        spellCheck="false"
                                        type="text"
                                      />
                                    </FieldColumn>
                                  </FieldRow>
                                  <FieldRow style={{ marginBottom: 5 }}>
                                    <FieldColumn>
                                      <FormField.Text
                                        label={'Naziv uređaja'}
                                        placeholder={'Npr. PC 1'}
                                        name="deviceName"
                                        type="text"
                                      />
                                    </FieldColumn>

                                    <FieldColumn>
                                      <FormField.Select
                                        label="Zadani operater"
                                        name="defaultOperatorId"
                                        options={
                                          posOperators?.posOperators_all!
                                        }
                                        parseValue={val =>
                                          posOperators?.posOperators_all?.find(
                                            e => e?.id === val
                                          )
                                        }
                                        formatValue={val => val?.id}
                                        isSearchable={false}
                                        //customChange={selectOperator}
                                      />
                                    </FieldColumn>
                                  </FieldRow>
                                  <FieldRow>
                                    <RadioButton
                                      options={vatStatusOptions}
                                      setValue={form.setFieldValue}
                                      value={form.values.vatStatus}
                                      name="vatStatus"
                                      label={
                                        vatStatusOptions.map(e => e.title)[0]
                                      }
                                      title={t(
                                        'translation.OrganizationDesktop.pdv'
                                      )}
                                      horizontal={true}
                                    />
                                  </FieldRow>
                                  <FieldRow>
                                    <FormField.Text
                                      label={'Napomena na dnu računa'}
                                      placeholder={'Napomena na dnu računa'}
                                      name="vatExemptText"
                                      type="text"
                                    />
                                  </FieldRow>
                                  <FieldRow>
                                    <FieldColumn></FieldColumn>
                                  </FieldRow>
                                </FieldColumn>
                              </FieldRow>

                              {posDeviceCertData &&
                              form.values?.storeVatId &&
                              form.values?.storeVatId !==
                                posDeviceCertData?.certVatId ? (
                                <ServiceRowWrapper>
                                  <ClientWarningRow style={{ marginBottom: 0 }}>
                                    <ClientWarningText
                                      style={{
                                        alignItems: 'end',
                                        marginBottom: 0,
                                      }}
                                    >
                                      <WarningTriangle
                                        color={'orange'}
                                        size="extraSmall"
                                        style={{ height: 25, width: 25 }}
                                      />
                                      <p>
                                        {
                                          'OIB korisnika certifikata i OIB uređaja se ne podudaraju'
                                        }
                                      </p>
                                    </ClientWarningText>
                                  </ClientWarningRow>
                                </ServiceRowWrapper>
                              ) : uploadedCertificateData &&
                                form.values?.storeVatId &&
                                form.values?.storeVatId !==
                                  uploadedCertificateData?.certVatId ? (
                                <ServiceRowWrapper>
                                  <ClientWarningRow style={{ marginBottom: 0 }}>
                                    <ClientWarningText
                                      style={{
                                        alignItems: 'end',
                                        marginBottom: 0,
                                      }}
                                    >
                                      <WarningTriangle
                                        color={'orange'}
                                        size="extraSmall"
                                        style={{ height: 25, width: 25 }}
                                      />
                                      <p>
                                        {
                                          'OIB korisnika certifikata i OIB uređaja se ne podudaraju'
                                        }
                                      </p>
                                    </ClientWarningText>
                                  </ClientWarningRow>
                                </ServiceRowWrapper>
                              ) : null}
                              <ServiceRowWrapper>
                                {!posDeviceCertData &&
                                !uploadedCertificateData ? (
                                  <ClientWarningRow style={{ marginBottom: 0 }}>
                                    <ClientWarningText
                                      style={{
                                        alignItems: 'end',
                                        marginBottom: 0,
                                      }}
                                    >
                                      <WarningTriangle
                                        color={
                                          uploadedCertificateError
                                            ? 'red'
                                            : 'orange'
                                        }
                                        size="extraSmall"
                                        style={{ height: 25, width: 25 }}
                                      />
                                      <p>
                                        {uploadedCertificateError
                                          ? uploadedCertificateError
                                          : 'Nedostaje fiskalni certifikat'}
                                      </p>
                                    </ClientWarningText>
                                  </ClientWarningRow>
                                ) : uploadedCertificateData ? (
                                  <>
                                    <p className="font-semibold mb-[20px]">
                                      {'Detalji učitanog fiskalnog certifikata'}
                                    </p>
                                    <div>
                                      <ul style={{ listStyle: 'none' }}>
                                        <li>
                                          <b>{'Izdavatelj: '}</b>
                                          {`${uploadedCertificateData?.issuer}`}
                                        </li>
                                        <li>
                                          <b>{'Korisnik: '}</b>
                                          {`${uploadedCertificateData?.subject}`}
                                        </li>
                                        <li>
                                          <b>{'OIB korisnika: '}</b>
                                          {uploadedCertificateData?.certVatId}
                                        </li>
                                        <li>
                                          <b>{'Vrijedi od: '}</b>
                                          {moment(
                                            uploadedCertificateData?.notBefore
                                          )?.format('DD.MM.YYYY.')}
                                        </li>
                                        <li>
                                          <b>{'Vrijedi do: '}</b>
                                          {moment(
                                            uploadedCertificateData?.notAfter
                                          )?.format('DD.MM.YYYY.')}
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : posDeviceCertData ? (
                                  <>
                                    <p className="font-semibold mb-[20px]">
                                      {'Detalji aktivnog fiskalnog certifikata'}
                                    </p>
                                    <div>
                                      <ul style={{ listStyle: 'none' }}>
                                        <li>
                                          <b>{'Izdavatelj: '}</b>
                                          {`${posDeviceCertData?.issuer}`}
                                        </li>
                                        <li>
                                          <b>{'Korisnik: '}</b>
                                          {`${posDeviceCertData?.subject}`}
                                        </li>
                                        <li>
                                          <b>{'OIB korisnika: '}</b>
                                          {posDeviceCertData?.certVatId}
                                        </li>
                                        <li>
                                          <b>{'Vrijedi od: '}</b>
                                          {moment(
                                            posDeviceCertData?.notBefore
                                          )?.format('DD.MM.YYYY.')}
                                        </li>
                                        <li>
                                          <b>{'Vrijedi do: '}</b>
                                          {moment(
                                            posDeviceCertData?.notAfter
                                          )?.format('DD.MM.YYYY.')}
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : null}
                              </ServiceRowWrapper>

                              {uploadedCertificateFile &&
                              !uploadedCertificateData ? (
                                <FieldRow>
                                  <FormField.Text
                                    label={'Unesite lozinku certifikata:'}
                                    placeholder={'Lozinka certifikata'}
                                    name="certPassword"
                                    type="password"
                                    autoFocus
                                    spellCheck="false"
                                  />
                                </FieldRow>
                              ) : null}

                              <FieldRow style={{ paddingBottom: '20px' }}>
                                <FieldColumn>
                                  <Button
                                    onClick={event => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      if (
                                        !posDeviceCertData &&
                                        !uploadedCertificateData &&
                                        !uploadedCertificateFile
                                      ) {
                                        certificateInput.current.open()
                                      } else if (
                                        uploadedCertificateFile &&
                                        !uploadedCertificateData
                                      ) {
                                        handleVerifyCertificate(
                                          form.values.certPassword
                                        )
                                      } else {
                                        setUploadedCertificateData(null)
                                        setUploadedCertificateFile(null)
                                        certificateInput.current.open()
                                      }
                                    }}
                                    buttonType={'secondaryLink'}
                                    size={'large'}
                                    label={
                                      !posDeviceCertData &&
                                      !uploadedCertificateData &&
                                      !uploadedCertificateFile
                                        ? 'Učitaj fiskalni certifikat'
                                        : uploadedCertificateFile &&
                                          !uploadedCertificateData
                                        ? 'Otključaj certifikat'
                                        : 'Zamijeni fiskalni certifikat'
                                    }
                                  />

                                  <Dropzone
                                    ref={certificateInput}
                                    onDrop={handleCertificateSelect}
                                    accept="application/x-pkcs12"
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                      </div>
                                    )}
                                  </Dropzone>
                                </FieldColumn>
                                {posDevice &&
                                posDeviceCertData &&
                                posDevice?.storeVatId &&
                                posDevice?.storeVatId ===
                                  posDeviceCertData?.certVatId ? (
                                  <FieldColumn
                                    style={{ justifyContent: 'end' }}
                                  >
                                    <Button
                                      onClick={handleDefaultDeviceSelect}
                                      buttonType={'secondaryLink'}
                                      size={'large'}
                                      label={'Postavi kao zadani uređaj'}
                                      disabled={!!activeJournal}
                                      tooltip={
                                        !!activeJournal
                                          ? 'Molimo zatvorite aktivnu blagajnu prije promjene uređaja'
                                          : null
                                      }
                                    />
                                  </FieldColumn>
                                ) : null}
                              </FieldRow>
                            </div>
                          </Tab>
                          <Tab hasFooter={false} title={'Postavke printera'}>
                            {isPrinterInstalled ? (
                              <div className="p-[20px] flex flex-col h-[inherit]">
                                <FieldRow>
                                  <FieldColumn
                                    style={{ flexDirection: 'column' }}
                                  >
                                    <FieldRow style={{ marginBottom: 5 }}>
                                      <FieldColumn>
                                        <FormField.Select
                                          label="Naziv printera"
                                          name="printerName"
                                          options={installedPrinters?.map(
                                            printer => {
                                              return {
                                                id: printer,
                                                title: printer,
                                              }
                                            }
                                          )}
                                          isSearchable={false}
                                        />
                                      </FieldColumn>

                                      <FieldColumn>
                                        <FormField.Select
                                          label="Tip printera"
                                          name="printerType"
                                          options={[
                                            { id: 'ROLL', title: 'ROLA' },
                                            { id: 'A4', title: 'A4' },
                                          ]}
                                          isSearchable={false}
                                          isClearable={false}
                                        />
                                      </FieldColumn>
                                    </FieldRow>
                                    {form?.values?.printerType?.id ===
                                    'ROLL' ? (
                                      <>
                                        <FieldRow style={{ marginBottom: 5 }}>
                                          <FieldColumn>
                                            <FormField.Text
                                              label={'Marka printera'}
                                              name="printerBrand"
                                              spellCheck="false"
                                              type="text"
                                            />
                                          </FieldColumn>

                                          <FieldColumn>
                                            <FormField.Text
                                              label={'Model printera'}
                                              name="printerModel"
                                              spellCheck="false"
                                              type="text"
                                            />
                                          </FieldColumn>
                                        </FieldRow>

                                        {isAdvancedOpened ? (
                                          <>
                                            <FieldRow
                                              style={{ marginBottom: 5 }}
                                            >
                                              <FieldColumn>
                                                <FormField.Text
                                                  label={'Encoding'}
                                                  name="Encoding"
                                                  spellCheck="false"
                                                  type="text"
                                                />
                                              </FieldColumn>
                                              <FieldColumn>
                                                <FormField.Select
                                                  label="Pulse pin"
                                                  name="pulsePin"
                                                  options={[
                                                    { id: 2, title: 2 },
                                                    { id: 5, title: 5 },
                                                  ]}
                                                  isSearchable={false}
                                                />
                                              </FieldColumn>
                                            </FieldRow>
                                            <FieldRow
                                              style={{ marginBottom: 5 }}
                                            >
                                              <FieldColumn>
                                                <FormField.Text
                                                  label={'Code table'}
                                                  name="codeTable"
                                                  spellCheck="false"
                                                  type="text"
                                                />
                                              </FieldColumn>
                                              <FieldColumn>
                                                <FormField.Text
                                                  label={'Character set'}
                                                  name="characterSet"
                                                  spellCheck="false"
                                                  type="text"
                                                />
                                              </FieldColumn>
                                            </FieldRow>
                                            <FieldRow>
                                              <FieldColumn>
                                                <FormField.Text
                                                  label={
                                                    'Broj stupaca na ispisu'
                                                  }
                                                  name="printerCols"
                                                  spellCheck="false"
                                                  type="text"
                                                />
                                              </FieldColumn>
                                              <FieldColumn></FieldColumn>
                                            </FieldRow>
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}

                                    <FieldRow style={{ marginTop: 5 }}>
                                      <FieldColumn>
                                        {form?.values?.printerType?.id ===
                                        'ROLL' ? (
                                          <Button
                                            key={'advanced'}
                                            label={'Dodatne postavke'}
                                            type="button"
                                            size={'small'}
                                            buttonType={'link'}
                                            iconRight
                                            iconComponent={
                                              !isAdvancedOpened ? (
                                                <icons.ChevronDown size="smaller" />
                                              ) : (
                                                <icons.ArrowUpDrop size="smaller" />
                                              )
                                            }
                                            onClick={e => {
                                              e.preventDefault()
                                              setIsAdvancedOpened(prev => !prev)
                                            }}
                                          />
                                        ) : null}
                                      </FieldColumn>
                                      {!isNew ? (
                                        <FieldColumn
                                          style={{ justifyContent: 'flex-end' }}
                                        >
                                          <Button
                                            type="button"
                                            onClick={e => {
                                              e.preventDefault()
                                              setIsPrinterTesting(true)

                                              form.submitForm()
                                              checkPrinterFormValues(form)
                                            }}
                                            buttonType={'secondaryLink'}
                                            size={'large'}
                                            label={'Testiraj printer'}
                                          />
                                        </FieldColumn>
                                      ) : null}
                                    </FieldRow>
                                  </FieldColumn>
                                </FieldRow>
                              </div>
                            ) : (
                              <div className="p-[20px]">
                                <ClientWarningRow>
                                  <ClientWarningText>
                                    <WarningTriangle color={'orange'} />
                                    <p>
                                      Podrška za ispis nije instalirana ili
                                      pisač nije spojen
                                    </p>
                                  </ClientWarningText>
                                </ClientWarningRow>

                                <FieldRow style={{ marginTop: '24px' }}>
                                  {getOs() === 'Mac' ||
                                  getOs() === 'Windows' ? (
                                    <FieldColumn
                                      style={{ alignItems: 'center' }}
                                    >
                                      <span className="mr-[8px]">
                                        Preuzmite podršku za ispis:
                                      </span>
                                      <a
                                        href={
                                          getOs() === 'Windows'
                                            ? 'https://files.zoyya.com/files/JSPrintManager6-win.exe'
                                            : 'https://files.zoyya.com/files/JSPrintManager-iOS.pkg'
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="border-[1px] border-zoyya-primary text-zoyya-primary p-[8px] rounded-[4px]"
                                      >
                                        <icons.PhoneDownload />
                                        Preuzmi
                                      </a>
                                    </FieldColumn>
                                  ) : getOs() === 'Linux' ? (
                                    <FieldColumn>
                                      <div className="flex">
                                        <span className="mr-[4px]">
                                          Za pomoć pri instalaciji obratite se
                                          korisničkoj podršci na
                                        </span>
                                        <a
                                          href={'mailto:pomoc@zoyya.com'}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-zoyya-primary"
                                        >
                                          pomoc@zoyya.com
                                        </a>
                                      </div>
                                    </FieldColumn>
                                  ) : null}
                                </FieldRow>
                              </div>
                            )}
                          </Tab>
                        </TabView>
                      </>
                    )
                  }}
                </Form>
              )}
            </FormWrapper>

            <FormFooterContainer hasShadow>
              <Button
                buttonType={'text'}
                name={'close'}
                label={t('translation.JournalModal.label-close')}
                footerButton
                onClick={() => {
                  closeModal()
                }}
              />

              {!isPrinterInstalled && selectedTabIndex === 1 ? null : (
                <Button
                  onClick={async () => {
                    formRef.current.submitForm()

                    setTimeout(() => {
                      if (
                        !selectedTabIndex &&
                        formRef.current?.errors?.codeTable
                      )
                        setSelectedTabIndex(1)
                      if (
                        !selectedTabIndex &&
                        (formRef.current?.errors?.characterSet ||
                          formRef.current?.errors?.printerCols)
                      ) {
                        setIsAdvancedOpened(true)
                        setSelectedTabIndex(1)
                      }
                      if (
                        selectedTabIndex &&
                        (formRef.current?.errors?.storeName ||
                          formRef.current?.errors?.fisDeviceId ||
                          formRef.current?.errors?.fisLocationId ||
                          formRef.current?.errors?.storeAddress ||
                          formRef.current?.errors?.storeCity ||
                          formRef.current?.errors?.storeVatId ||
                          formRef.current?.errors?.storeIban ||
                          formRef.current?.errors?.defaultDepositAmount ||
                          formRef.current?.errors?.storeZip)
                      )
                        setSelectedTabIndex(0)
                    }, 100)
                  }}
                  type="button"
                  buttonType={'primary'}
                  name={'saveChanges'}
                  disabled={false}
                  label={'Spremi'}
                />
              )}
            </FormFooterContainer>
          </ModalWrapper>
        </Modal>
      </Container>
    </aside>
  )
}
const PosSettingsDevices = () => {
  const { navigateTo } = useSdk()
  const appServices = useAppServices()
  const [deleteDeviceMutation] = useDeletePosDeviceMutation()
  const { deviceId, orgId, locationId } = useParams<any>()
  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)
  const [isNew, setIsNew] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const { tableContainerRef, tableHeight } = useTableHeightCalculator(
    selectedPosDevice ? 420 : 230
  )
  const { showPopup } = usePopup()

  const deleteDevice = async id => {
    setIsBusy(true)
    if (!id) return
    try {
      const result = await deleteDeviceMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: PosDeviceAllDocument,
          },
        ],
      })
      if (result.errors?.length) {
        appServices.toast.danger(result.errors[0]?.message)
      } else {
        appServices.toast.success('Naplatni uređaj uspješno obrisan')
      }
    } catch (err) {
      appServices.toast.danger('Došlo je do pogreške')
    } finally {
      setIsBusy(false)
    }
  }

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const { data: posOperators } = usePosOperatorsAllQuery()
  const org = organizationData?.organization?.get

  const {
    data: devicesData,
    loading: devicesLoading,
    refetch: refetchDevices,
  } = usePosDeviceAllQuery()
  const devices = devicesData?.posDevice_all || []

  const { data: journalData, loading: journalLoading } =
    usePosActiveJournalGetQuery({
      variables: {
        input: {
          fisLocationId: selectedPosDevice?.fisLocationId as any,
          fisDeviceId: selectedPosDevice?.fisDeviceId as any,
        },
      },
      skip:
        !selectedPosDevice?.fisLocationId ||
        (locationId !== selectedPosDevice?.locationId &&
          orgId !== selectedPosDevice?.orgId),
    })

  const activeJournal = journalData?.posActiveJournal_get

  const deviceColumns = [
    {
      Header: '',
      accessor: 'certWarningSign',
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      Cell: props => {
        const certData = getCertDataObject(props.cell.row.original?.certData)
        const isSoonExpired = moment()
          .add(1, 'month')
          .isAfter(moment(certData?.notAfter))
        const isExpired = moment().isAfter(moment(certData?.notAfter))
        const isVatCorrect =
          props.cell.row.original?.storeVatId === certData?.certVatId
        return (
          <TableItem style={{ maxWidth: 50, margin: 0 }}>
            {!certData ? (
              <Tooltip key={'tooltip'} label={'Nedostaje fiskalni certifikat!'}>
                <icons.WarningTriangle size={'small'} color="orange" />
              </Tooltip>
            ) : isSoonExpired ? (
              <Tooltip
                key={'tooltip'}
                label={'Fiskalni certifikat uskoro prestaje bit valjan!'}
              >
                <icons.WarningTriangle size={'small'} color="orange" />
              </Tooltip>
            ) : isExpired ? (
              <Tooltip
                key={'tooltip'}
                label={'Fiskalni certifikat je istekao!'}
              >
                <icons.WarningTriangle size={'small'} color="orange" />
              </Tooltip>
            ) : !isVatCorrect ? (
              <Tooltip
                key={'tooltip'}
                label={'OIB certifikata i OIB uređaja se ne podudaraju!'}
              >
                <icons.WarningTriangle size={'small'} color="orange" />
              </Tooltip>
            ) : null}
          </TableItem>
        )
      },
    },
    {
      Header: 'Uređaj',
      accessor: 'deviceName',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.deviceName}</span>
        </TableItem>
      ),
    },
    {
      Header: 'Naziv organizacije',
      accessor: 'storeName',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          <span>
            {
              props.cell.row.original
                ?.storeName /*  || 'Naziv uređaja/lokacije' */
            }
          </span>
        </TableItem>
      ),
    },
    {
      Header: 'Posl. prostor',
      accessor: 'fisLocationId',
      minWidth: 108,
      width: 108,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.fisLocationId}</span>
        </TableItem>
      ),
    },
    {
      Header: 'Napl. uređaj',
      accessor: 'fisDeviceId',
      minWidth: 108,
      width: 108,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.fisDeviceId}</span>
        </TableItem>
      ),
    },
    {
      Header: 'OIB',
      accessor: 'storeVatId',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.storeVatId}</span>
        </TableItem>
      ),
    },
    {
      Header: 'Adresa',
      accessor: 'storeAddress',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.storeAddress}</span>
        </TableItem>
      ),
    },
    {
      Header: 'Grad',
      accessor: 'storeCity',
      minWidth: 90,
      width: 90,
      Cell: props => (
        <TableItem style={{ flexGrow: 0 }}>
          <span>{props.cell.row.original?.storeCity}</span>
        </TableItem>
      ),
    },
    /*  {
      Header: 'Pošt. broj',
      accessor: 'storeZip',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.storeZip}</span>
        </TableItem>
      ),
    }, */
    {
      Header: 'Polog',
      accessor: 'defaultDepositAmount',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem style={{ justifyContent: 'end' }}>
          <span>
            {formatPriceNumberWithDelimiter(
              props.cell.row.original?.defaultDepositAmount
            ) +
              ' ' +
              (org?.currency?.currencyCode || 'EUR')}
          </span>
        </TableItem>
      ),
    },
    /* {
        Header: 'Status',
        accessor: 'status',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <TableItem>
            <Badge
              style={{
                background: !props.cell.row.original?.isActive
                  ? selectedTheme.colors.accent1
                  : selectedTheme.colors.accent2,
              }}
            >
              {props.cell.row.original?.isActive ? 'AKTIVAN' : 'NEAKTIVAN'}
            </Badge>
          </TableItem>
        ),
      }, */
    {
      Header: '',
      accessor: 'editDevice',
      minWidth: 40,
      width: 40,
      maxWidth: 40,
      Cell: props => (
        <TableItem style={{ minWidth: 50, margin: 0 }}>
          <Button
            buttonType={'secondary'}
            iconComponent={<icons.Edit size={'smaller'} />}
            hasIconOnly
            size={'small'}
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              navigateTo.cashRegisterSettingsDevices({
                deviceId: props.cell.row.original?.id,
              })
            }}
            tooltip={'Uredi uređaj'}
            style={{ height: '35px' }}
          />
        </TableItem>
      ),
    },
    {
      Header: '',
      accessor: 'deleteDevice2',
      minWidth: 50,
      width: 50,
      Cell: props => (
        <TableItem style={{ minWidth: 50, margin: 0 }}>
          <Button
            buttonType={'secondary'}
            iconComponent={<icons.Trash size={'smaller'} />}
            hasIconOnly
            size={'small'}
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              showPopup({
                onConfirm: () => deleteDevice(props.cell.row.original?.id),
                titleText: `Jeste li sigurni da želite obrisati uređaj
                ${
                  props.cell.row.original?.deviceName
                    ? props.cell.row.original?.deviceName
                    : ''
                }`,
              })
            }}
            tooltip={'Izbriši uređaj'}
            style={{ height: '35px' }}
          />
        </TableItem>
      ),
    },
  ]

  return (
    <FormPageContainer style={{ padding: '20px 15px', flex: '1 1' }}>
      {devicesLoading || loadingOrgData || isBusy || journalLoading ? (
        <Loader isComponent />
      ) : null}
      <FormFieldsWrapper style={{ height: '100%' }}>
        <FormContentContainer
          style={{ padding: '5px 10px', height: '100%' }}
          ref={tableContainerRef}
        >
          <SectionContainer
            title={'Naplatni uređaji'}
            sectionContainerStyle={{ minHeight: 580, height: '100%' }}
            sectionBodyStyle={{
              minHeight: 400,
              maxHeight: '100%',
              height: '100%',
              padding: 0,
              paddingTop: '0.2rem',
              paddingLeft: '0.5rem',
            }}
          >
            {selectedPosDevice &&
            locationId === selectedPosDevice?.locationId &&
            orgId === selectedPosDevice?.orgId ? (
              <div>
                <p>Zadani naplatni uređaj:</p>
                <div className="p-[15px_20px] m-[10px_0_15px] w-[fit-content] rounded-[4px] border-[1px] border-zoyya-outline bg-zoyya-lightBackground">
                  <div className="flex items-center border-b-[1px] border-[#e1e4e8] pb-[15px] mb-[15px]">
                    <CashRegister
                      size="large"
                      style={{ marginRight: 20, strokeWidth: 1 }}
                    />
                    <div>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <span>Naziv organizacije:</span>
                          <span className="font-medium ml-[5px]">
                            {selectedPosDevice?.storeName}
                          </span>
                        </div>
                        <div className="w-[1px] bg-[#e1e4e8] m-[0_20px]" />
                        <div>
                          <span>Poslovni prostor:</span>
                          <span className="font-medium ml-[5px]">
                            {selectedPosDevice?.fisLocationId}
                          </span>
                        </div>
                        <div className="w-[1px] bg-[#e1e4e8] m-[0_20px]" />
                        <div>
                          <span>Naplatni uređaj:</span>
                          <span className="font-medium ml-[5px]">
                            {selectedPosDevice?.fisDeviceId}
                          </span>
                        </div>
                      </div>
                      <p className="text-zoyya-secondaryDarker">
                        {`${selectedPosDevice?.storeAddress}, ${selectedPosDevice?.storeCity}, ${selectedPosDevice?.storeZip}`}
                      </p>
                    </div>
                  </div>
                  <div>
                    {selectedPosDevice?.deviceName ? (
                      <p style={{ margin: '8px 0' }}>
                        Naziv uređaja:
                        <span className="font-medium ml-[5px]">
                          {selectedPosDevice?.deviceName}
                        </span>
                      </p>
                    ) : null}
                    <p style={{ margin: '8px 0' }}>
                      OIB:
                      <span className="font-medium ml-[5px]">
                        {selectedPosDevice?.storeVatId}
                      </span>
                    </p>
                    <p style={{ margin: '8px 0' }}>
                      Polog:
                      <span className="font-medium ml-[5px]">
                        {formatPriceNumberWithDelimiter(
                          selectedPosDevice?.defaultDepositAmount
                        ) +
                          ' ' +
                          (org?.currency?.currencyCode || 'EUR')}
                      </span>
                    </p>
                    {selectedPosDevice?.storeIban ? (
                      <p style={{ margin: '8px 0' }}>
                        IBAN:
                        <span className="font-medium ml-[5px]">
                          {selectedPosDevice?.storeIban}
                        </span>
                      </p>
                    ) : null}

                    {selectedPosDevice?.defaultOperatorId ? (
                      <p style={{ margin: '8px 0 0' }}>
                        Zadani operater:
                        <span className="font-medium ml-[5px]">
                          {
                            posOperators?.posOperators_all?.find(
                              e =>
                                e?.id === selectedPosDevice?.defaultOperatorId
                            )?.firstName
                          }
                        </span>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <ServiceRowWrapper>
                <ClientWarningRow style={{ marginBottom: 0 }}>
                  <ClientWarningText
                    style={{
                      alignItems: 'end',
                      marginBottom: 0,
                    }}
                  >
                    <WarningTriangle
                      color={'orange'}
                      size="extraSmall"
                      style={{ height: 25, width: 25, marginLeft: 0 }}
                    />
                    <p>{'Nedostaje zadani naplatni uređaj'}</p>
                  </ClientWarningText>
                </ClientWarningRow>
              </ServiceRowWrapper>
            )}

            <Button
              buttonType={'primary'}
              iconComponent={<icons.Add size={'smaller'} />}
              size={'medium'}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                // deleteOperator(props.cell.row.original?.id)
                setIsNew(true)
              }}
              style={{ marginBottom: 10 }}
              label={'Dodaj uređaj'}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <div style={{ minWidth: '67%' }}>
                <ListContainer style={{ padding: 0 }}>
                  <ListBodyWrapper id={'devices-list-body-wrapper'}>
                    <ReactTable
                      tableData={devices}
                      columns={deviceColumns}
                      tableHeight={tableHeight}
                      onRowClick={item =>
                        navigateTo.cashRegisterSettingsDevices({
                          deviceId: item?.id,
                        })
                      }
                      isSelected={{ id: deviceId }}
                    />
                  </ListBodyWrapper>
                </ListContainer>
              </div>
            </div>
          </SectionContainer>
        </FormContentContainer>
      </FormFieldsWrapper>
      {isNew || deviceId ? (
        <PosSettingsDevicesModal
          deviceId={deviceId}
          isNew={isNew}
          refetchDevices={refetchDevices}
          closeModal={() => {
            setIsNew(false)
            navigateTo.cashRegisterSettingsDevices({})
          }}
          loading={devicesLoading}
          activeJournal={activeJournal}
        />
      ) : null}
    </FormPageContainer>
  )
}

export default PosSettingsDevices
