import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { FlexRow, FlexColumn } from 'components/Flex/Flex'
import { NavLink, useLocation } from 'react-router-dom'
import { device } from '../../constants'
import { useSdk } from 'src/sdk'
import { useOnClickOutside } from 'src/helpers'

type TProps = {
  /**
   * Additional className to pass to the outer tabView container
   */
  className?: string

  /**
   * index of the selected (active) Tab
   */
  selectedTabIndex?: number
  setSelectedTabIndex?: any
  useRoutes?: boolean
  children: any
  isSingleTab: any
  hasFooter: any
  maxVisibleTabs?: number
}

/***
 * The tab view component
 * @param props component props
 *
 */

export const TabView = (props: TProps) => {
  const l = useLocation()
  const { isMobile } = useSdk()
  const {
    className,
    selectedTabIndex,
    setSelectedTabIndex,
    children,
    useRoutes,
    isSingleTab,
    hasFooter,
    maxVisibleTabs = isMobile ? 100 : 100,
  } = props

  const [isDropdownVisible, setDropdownVisible] = useState(false)

  const childrenArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  ) as any[]

  const shouldDisplayMoreTab = childrenArray.length > maxVisibleTabs + 1

  const handleMoreTabClick = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleDropdownItemClick = (index: number) => {
    setSelectedTabIndex(index)
    setDropdownVisible(false)
  }

  const dropdownRef = useRef<any>()
  useOnClickOutside(dropdownRef, () => {
    setDropdownVisible(false)
  })

  return (
    <TabViewContainer className={className} hasFooter={hasFooter}>
      <TabItemStripContainer>
        <TabItems>
          {childrenArray.slice(0, maxVisibleTabs).map((child, index) => (
            <TabItemHeader
              key={index}
              title={child.props.title}
              isActive={index === selectedTabIndex || isSingleTab}
              onClick={() => setSelectedTabIndex(index)}
              route={child.props.route}
              id={child.props.id}
              useRoutes={useRoutes}
            />
          ))}
          {shouldDisplayMoreTab ? (
            <>
              <TabItemHeader
                key="more"
                title="Više"
                isActive={false}
                onClick={handleMoreTabClick}
              />
              {isDropdownVisible && (
                <div
                  ref={dropdownRef}
                  className="absolute top-[60px] z-50 right-[10px] lg:right-4 px-5 py-3 rounded-sm bg-white border"
                >
                  {childrenArray.slice(maxVisibleTabs).map((child, index) => (
                    <TabItemHeader
                      isMoreMenuItem
                      key={maxVisibleTabs + index}
                      title={child.props.title}
                      isActive={
                        maxVisibleTabs + index === selectedTabIndex ||
                        isSingleTab
                      }
                      onClick={() =>
                        handleDropdownItemClick(maxVisibleTabs + index)
                      }
                      route={child.props.route}
                      id={child.props.id}
                      useRoutes={useRoutes}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            childrenArray
              .slice(maxVisibleTabs, maxVisibleTabs + 1)
              .map((child, index) => (
                <TabItemHeader
                  key={maxVisibleTabs + index}
                  title={child.props.title}
                  isActive={
                    maxVisibleTabs + index === selectedTabIndex || isSingleTab
                  }
                  onClick={() => setSelectedTabIndex(maxVisibleTabs + index)}
                  route={child.props.route}
                  id={child.props.id}
                  useRoutes={useRoutes}
                />
              ))
          )}
        </TabItems>
      </TabItemStripContainer>
      <TabContentContainer hasFooter={hasFooter}>
        {useRoutes
          ? childrenArray.find(e => e.props.route === l.pathname)
          : childrenArray[selectedTabIndex || 0]}
      </TabContentContainer>
    </TabViewContainer>
  )
}

export const TabContentContainer = styled.div<any>`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  z-index: 9;
  background: ${props => props.theme.colors.light};
  @media ${device.tablet} {
    height: 100%;
    max-height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const TabItemStripContainer = styled(FlexRow)`
  min-height: 55px;
  height: 55px;
  background: ${props => props.theme.colors.mainBackground};
  border-radius: 0.714rem 0.714rem 0 0;
  @media ${device.tablet} {
    background: none;
    flex-direction: row;
  }
`

export const TabItems = styled.div`
  display: flex;
  /* @media ${device.tablet} {
    display: none;
  } */

  @media ${device.tablet} {
    width: 100%;
    position: fixed;
    height: 55px;
    top: 0;
    z-index: 1000;
    background: white;
  }
`
export const TabViewContainer = styled<any>(FlexColumn).attrs(props => ({
  hasFooter: props.hasFooter,
}))`
  flex: 1;

  @media ${device.tablet} {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
  }
`

const TabItemContainer = styled.div<any>`
  padding: ${props => (props.isMoreMenuItem ? '7px' : ' 0px 29px')};
  display: flex;
  align-items: center;
  font-weight: ${props => (props.active && props.isMoreMenuItem ? '500' : '')};
  justify-content: ${props => (props.isMoreMenuItem ? '' : 'center')};
  color: ${props =>
    props.isMoreMenuItem
      ? props.active
        ? props.theme.colors.primary
        : props.theme.colors.text
      : props.theme.colors.primary};
  background: ${props =>
    props.isMoreMenuItem
      ? ''
      : props.active
      ? props.theme.colors.light
      : props.theme.colors.mainBackground};
  cursor: pointer;
  border-radius: 0.714rem 0.714rem 0px 0px;
  text-align: ${props => (props.isMoreMenuItem ? 'left' : 'center')};
  @media ${device.tablet} {
    background: initial;
    padding: ${props => (props.isMoreMenuItem ? '7px' : ' 10px 15px')};
    display: flex;
    align-items: center;
    width: 100%;
    color: ${props =>
      props.active ? props.theme.colors.primary : props.theme.colors.text};
    border-bottom: ${props =>
      !props.isMoreMenuItem
        ? `2px solid ${
            props.active
              ? props.theme.colors.primary
              : props.theme.colors.outline
          }`
        : ''};
  }
`

export const TabItemNavLinkContainer = styled<any>(NavLink).attrs(props => ({
  activeClassName: 'active',
}))`
  padding: 15px 40px;
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.mainBackground};
  cursor: pointer;
  border-radius: 0.714rem 0.714rem 0px 0px;

  @media ${device.tablet} {
    display: none;
    background: transparent;
    padding: 10px;
    color: ${props => props.theme.colors.secondaryDark};
  }

  :active,
  :focus {
    outline: none;
  }

  &.active {
    color: ${props => props.theme.colors.text};
    background: ${props => props.theme.colors.light};

    @media ${device.tablet} {
      font-weight: 600;
    }
  }
`

const TabItemHeader = (props: any) => {
  const { title, isActive, onClick, route, useRoutes, id, isMoreMenuItem } =
    props

  return useRoutes ? (
    <TabItemNavLinkContainer
      id={id}
      onClick={onClick}
      activeClassName="active"
      to={route || ''}
      data-cy={`TabItem_${id || title}`}
    >
      {title}
    </TabItemNavLinkContainer>
  ) : (
    <TabItemContainer
      onClick={onClick}
      active={isActive}
      data-cy={'tabItem'}
      isMoreMenuItem={isMoreMenuItem}
    >
      {title}
    </TabItemContainer>
  )
}

export const StyledTabContainer = styled.div.attrs((props: any) => ({
  hasFooter: props.hasFooter,
  isFullHeight: props.isFullHeight,
}))`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.isFullHeight ? 1 : null)};
  @media ${device.tablet} {
    padding: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TTabProps = {
  id?: string
  title: string
  route?: string
  children?: any
  loading?: boolean
  hasFooter: any
  isFullHeight?: boolean
  className?: string
  openMoreMenu?: boolean
}

export const Tab = (props: TTabProps) => {
  const { children, hasFooter, isFullHeight, className, title } = props
  return (
    <StyledTabContainer
      isFullHeight={isFullHeight}
      hasFooter={hasFooter}
      className={className}
    >
      {children}
    </StyledTabContainer>
  )
}
