import styled, { css } from 'styled-components'
import { FlexColumn } from 'components'
import * as colors from '../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../style/fonts'
import { inputPadding } from '../Input/style'
import { grayLight } from '../style/colors'

export const StyledMenuListContainer = styled(FlexColumn)`
  overflow-y: auto;
  flex: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledCloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  align-self: flex-end;
  padding: 3px;
  position: absolute;
  top: 20px;
  color: white;
  z-index: 999999999;
  right: 15px;
`

export const StyledSelectValueContainer = styled.div<any>`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? colors.redLight : colors.white)};
  border-radius: 4px;
  border: 1px solid
    ${({ hasError, hideBorder }) =>
      hideBorder ? 'none' : hasError ? colors.red : colors.grayLight};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? colors.red : colors.grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  ${props => (props.hideBorder ? undefined : inputPadding)};
  padding-left: ${props => (props.hasIcon ? '40px' : 'auto')};
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};

  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? colors.red : colors.blue)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? colors.redLighter : colors.blueLighter)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  ${props =>
    props.disabled &&
    css`
      background: #f1f1f2;
      border: 1px solid ${grayLight};
      color: #818e9c;
      cursor: not-allowed;

      &::placeholder {
        color: ${props => props.theme.colors.secondaryDark};
      }
    `}

  :disabled {
    background: ${colors.grayLight};
    border: 1px solid ${colors.grayLight};
    color: ${colors.grayDark};
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
  ${props => props.style}
`

export const StyledDropDownContainer = styled(FlexColumn)<{
  isSearchable?: boolean
}>`
  align-self: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: ${props =>
    props.isSearchable
      ? 'calc((var(--vh, 1vh) * -90))'
      : 'calc((var(--vh, 1vh) * -70))'};
  height: ${props =>
    props.isSearchable
      ? 'calc((var(--vh, 1vh) * 90))'
      : 'calc((var(--vh, 1vh) * 70))'};
  width: 100%;
  animation-name: ${props =>
    props.isSearchable ? 'anim-open90vh ' : 'anim-open70vh'};

  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  @keyframes anim-open70vh {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc((var(--vh, 1vh) * -70)));
    }
  }
  @keyframes anim-open90vh {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc((var(--vh, 1vh) * -90)));
    }
  }
`
