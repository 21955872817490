import styled from 'styled-components'
import { transparentize } from 'polished'
import { fontFamily } from 'components/style/fonts'
import { device } from '../../../../constants'

export const DateRangeCalendarContainer = styled.div`
  margin: 10px 0px;
  overflow: hidden;
  display: flex;
  top: 50px;
  position: absolute;
  background: ${props => props.theme.colors.light};
  box-shadow: 0px 0px 10px 0px #8080803b;
  border-radius: 4px;
  z-index: 9999999999999999;

  @media ${device.tablet} {
    /* width: 100vw; */
    height: 100vh;
    margin: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
  }
`

export const DateRangeLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const DateRangeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -5px;

  span {
    color: ${props => props.theme.colors.secondaryDark};
  }
`
export const DateArrow = styled.div`
  width: 25px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: ${props => props.theme.colors.text};
  }
  &:hover {
    span {
      color: ${props => props.theme.colors.text};
    }
  }
`

export const DateRangeContainer = styled.div`
  display: flex;
  align-content: center;
  border-radius: 4px;
  margin: auto;
  border: 1px solid ${props => transparentize(0.4, props.theme.colors.outline)};
`

export const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DateButton = styled.button`
  padding: 12px;
  white-space: nowrap;
  font-weight: 500;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  font-size: 14px;
  font-family: ${fontFamily};

  &:focus,
  :active {
    border: none;
    outline: none;
  }

  svg {
    margin-left: 10px;
  }
`

export const DateRangeArrow = styled(DateButton)`
  box-shadow: none;
  transform: none;
  color: #101928;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 40px;
  background: ${props => props.theme.colors.light};
  border: none;
  border-radius: 4px;

  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-left: 1px solid
      ${props => transparentize(0.4, props.theme.colors.outline)};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`

export const DateRangeDate = styled(DateButton)`
  background: ${props => props.theme.colors.light};
  min-width: 180px;
  border: none;
  cursor: pointer;
  @media ${device.tablet} {
    min-width: 0px;
  }
  svg {
    margin-left: 20px;

    @media ${device.tablet} {
      margin-left: 5px;
    }
  }
`

export const ArrowLeft = styled(DateRangeArrow)`
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  padding-left: 8px;
  padding-right: 8px;

  span {
    color: ${props => props.theme.colors.secondaryDark};
    font-size: 12px;
  }
`

export const DateRangeName = styled.p`
  font-size: 16px;
  margin-bottom: 0px;
  text-align: center;
  margin-right: auto;
  color: ${props => props.theme.colors.text};
`

export const DateRangeCalendarStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(10px, 1fr));
  grid-template-rows: minmax(10px, 20px) repeat(6, minmax(10px, 40px));
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
  margin-top: 10px;
`

export const DateRangeMonthlyStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(45px, 1fr));
  grid-template-rows: 50px;
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
`

export const MonthDayName = styled.span<{
  isDisabled?: boolean
  isCurrent?: boolean
  isHoliday?: boolean
  isSelected?: boolean
  isHovered?: boolean
  isSmall?: boolean
  isHidden?: boolean
}>`
  letter-spacing: 1px;
  font-size: 10px;
  background: ${props =>
    props.isDisabled
      ? transparentize(0.75, props.theme.colors.grayLight)
      : props.isCurrent && !props.isHoliday
      ? props.theme.colors.accent4
      : props.isSelected
      ? props.theme.colors.primary
      : props.isHovered
      ? transparentize(0.9, props.theme.colors.primary)
      : 'transparent'};
  color: ${props =>
    props.isDisabled
      ? props.theme.colors.grayDark
      : (props.isCurrent && !props.isHoliday) || props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.text};
  padding: ${props => (props.isSmall && !props.isCurrent ? '0' : '3px 6px;')};
  border-radius: 4px;

  width: 30px;
  height: 30px;
  display: ${props => (props.isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props =>
      props.isDisabled
        ? undefined
        : transparentize(0.9, props.theme.colors.primary)};
  }
`

export const DateRangeCalendarMonth = styled.div`
  text-align: right;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(n + 1):nth-of-type(-n + 4) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 8) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 12) {
    grid-row: 3;
  }

  &:nth-of-type(4n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(4n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(4n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(4n + 4) {
    grid-column: 4/4;
  }
`

export const DateRangeCalendarDay = styled.div<{ isDisabled?: boolean }>`
  text-align: right;
  padding: 5px;
  cursor: ${props => (props.isDisabled ? undefined : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(7n + 7) {
    border-right: 0;
  }

  &:nth-of-type(n + 1):nth-of-type(-n + 7) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 14) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 21) {
    grid-row: 3;
  }

  &:nth-of-type(n + 22):nth-of-type(-n + 28) {
    grid-row: 4;
  }

  &:nth-of-type(n + 29):nth-of-type(-n + 35) {
    grid-row: 5;
  }

  &:nth-of-type(7n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(7n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(7n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(7n + 4) {
    grid-column: 4/4;
  }

  &:nth-of-type(7n + 5) {
    grid-column: 5/5;
  }

  &:nth-of-type(7n + 6) {
    grid-column: 6/6;
  }
  &:nth-of-type(7n + 7) {
    grid-column: 7/7;
  }
`

export const DateRangeDayName = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text};
  text-align: center;
  font-weight: 600;
`

export const DateRangeDayDisabled = styled(DateRangeCalendarDay)`
  span {
    color: ${props => props.theme.colors.accent5};
    font-weight: 500;
  }
`
