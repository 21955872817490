import React, { useContext, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import moment from 'moment'
import { device } from '../../../constants'
import {
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
  changeServiceStartTime,
  changeServiceDurations,
  formatPriceNumberWithDelimiter,
} from '../../../helpers'
import { useSdk } from 'sdk'
import { icons } from 'components'
import { TServiceStartTimeProps, TServiceProps } from './Service'
import { Button, Loader } from 'components'
import { StartTimeSelect } from 'views/Appointment/components/StartTimeSelect'
import { DurationSelect } from 'views/Appointment/components/DurationSelect'
import { ServiceSelect } from 'views/Appointment/components/ServiceSelect'
import { EmployeeSelect } from 'views/Appointment/components/EmployeeSelect'
import { ResourceTypesSelect } from './ResourceTypesSelect'
import { ButtonStyled } from 'components/Button/Button'
import {
  ServiceDiscount,
  StyledDiscountInput,
} from 'src/views/CashRegister/NewReceipt/styles'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { RouterOutput, trpc } from 'src/trpc'
import { useParams } from 'react-router'
import { GiftCardSelect } from './GiftCardSelect'
import { useFeatureFlags } from 'src/state/local/appStore'
import { Gift } from 'src/components/Icon/Icons/Gift'

type TServiceFormProps = {
  index?: number
  item?: any
  remove: any
  replace: any
  isMobile: boolean
  allResources: any
  workingEmployees: (arg0: string, arg1: string) => any[]
  workingResources: (arg0: string, arg1: string) => any[]
  flattenedServices: TServiceProps[]
  values: any
  isLoading: boolean
  selectedServices: any[]
  setIsMenuOpen: (arg0: boolean) => void
  timeSlotDuration: number
  setFieldValue: (arg0: string, arg1: any) => void
  getWorkHours: (
    arg0: string,
    arg1: string,
    arg2: number
  ) => TServiceStartTimeProps[]
  onNewService: () => void
  services: any[]
  duration: { id: number; title: string }[]
  validateForm: () => any
  setError: any
  indexInEdit: number | undefined
  idxInEdit: any
  itemInEdit: any
}
export const ServiceForm = (props: TServiceFormProps) => {
  const {
    index,
    item,
    remove,
    replace,
    isMobile,
    allResources,
    workingEmployees,
    workingResources,
    flattenedServices,
    values,
    isLoading,
    selectedServices,
    setIsMenuOpen,
    timeSlotDuration,
    setFieldValue,
    getWorkHours,
    onNewService,
    services,
    duration,
    indexInEdit,
    validateForm,
    setError,
    idxInEdit,
    itemInEdit,
  } = props

  const { locationId, orgId } = useParams<{
    locationId: string
    orgId: string
  }>()

  const employees = workingEmployees(values.date, item.startTime?.id)
  const employee = employees?.find(x => x.id === item.employee)
  const resources = workingResources(values.date, item.startTime?.id)
  const service = flattenedServices?.find(x => x.id === item.service)
  const inappropriateResources = allResources
    ?.filter(x => !resources?.find(y => y?.id === x?.id))
    ?.map(x => {
      return { ...x, inappropriate: true }
    })
  const selectedService = flattenedServices?.find(x => x.id === item?.service)
  const { t } = useSdk()

  const [addGiftCardEnabled, setAddGiftCardEnabled] = React.useState(false)

  const isStatusDraft = values.status === 'DRAFT'
  const selectedTheme = useContext(ThemeContext)

  const featuresFlags = useFeatureFlags()
  const loyaltyFeatureFlag = featuresFlags.loyalty === 'true'

  const { data: giftCardsData, isLoading: giftCardsLoading } =
    trpc.giftCard_appointment_findMany.useQuery(
      {
        locationId: BigInt(locationId),
        orgId,
        serviceId: BigInt(item?.service || 0),
        giftCardAmount: 0,
        balanceWhitelistGiftCardId: BigInt(item?.original?.giftCard?.id || 0),
      },
      {
        enabled: !!values?.client && !!item?.service && loyaltyFeatureFlag,
        cacheTime: 0,
      }
    )
  const giftCards = giftCardsData as RouterOutput['giftCard_findOne'][] | []

  const filterEmployeeOptions = (
    candidate: {
      data: any
      label: string
      value: string
    },
    input: string
  ) => {
    return input
      ? candidate?.data?.typeName
          ?.toLowerCase()
          ?.includes(input.toLowerCase()) ||
          candidate?.data?.firstName
            ?.toLowerCase()
            ?.includes(input.toLowerCase()) ||
          candidate?.data?.lastName
            ?.toLowerCase()
            ?.includes(input.toLowerCase()) ||
          candidate?.data?.nickName
            ?.toLowerCase()
            ?.includes(input.toLowerCase()) ||
          candidate?.data?.name?.toLowerCase()?.includes(input.toLowerCase())
      : true
  }

  const filterOptions = (
    candidate: {
      data: any
      label: string
      value: string
    },
    input: string
  ) => {
    if (input)
      return candidate?.data?.name?.toLowerCase()?.includes(input.toLowerCase())

    return true
  }

  const filterGiftCardOptions = (
    candidate: {
      data: any
      label: string
      value: string
    },
    input: string
  ) => {
    if (input)
      return (
        candidate?.data?.name?.toLowerCase()?.includes(input.toLowerCase()) ||
        candidate?.data?.client?.fullName
          ?.toLowerCase()
          ?.includes(input.toLowerCase()) ||
        candidate?.data?.code?.toLowerCase()?.includes(input.toLowerCase())
      )

    return true
  }

  const [inputValue, setInputValue] = React.useState<number | undefined>()

  useEffect(() => {
    setInputValue(
      item?.discountValue === 0 || !item?.discountValue
        ? ''
        : item?.discountValue
    )
  }, [item.discountValue, item?.discountType])

  useEffect(() => {
    if (item?.giftCard) {
      setAddGiftCardEnabled(true)
    }
  }, [item.giftCard])

  const updateDiscount = e => {
    const value = !e.target.value
      ? 0
      : parseFloat(e.target.value?.toString().replace(',', '.'))
    setInputValue(e.target.value)

    const newTotalAmount =
      item?.discountType === 'percent'
        ? item?.price * (1 - value / 100)
        : item?.price - value > 0
        ? item?.price - value
        : 0

    const discountAmount =
      item?.discountType === 'percent'
        ? parseFloat((item?.price * (value / 100)).toFixed(2))
        : item?.price - value > 0
        ? value
        : item?.price

    const discountRate =
      item?.discountType === 'percent'
        ? value
        : item?.price - value > 0
        ? parseFloat(((value / item?.price) * 100).toFixed(2))
        : 0

    const giftCardAmount =
      item?.giftCard?.giftCardType === 'GIFTCARD' &&
      parseFloat(item?.giftCard?.discountRate) === 0
        ? item?.giftCard?.balance >= newTotalAmount
          ? newTotalAmount
          : item?.giftCardAmount
        : item?.giftCardAmount

    replace(idxInEdit, {
      ...itemInEdit,
      discountType: item?.discountType,
      totalAmount: newTotalAmount,
      discountRate,
      discountAmount,
      discountValue: value,
      giftCardAmount:
        giftCardAmount + discountAmount > item?.price
          ? giftCardAmount - (giftCardAmount + discountAmount - item?.price)
          : giftCardAmount,
    })
  }

  const updateDiscountType = type => {
    const discountInputValue = inputValue ? inputValue : 0

    const newTotalAmount =
      type === 'percent'
        ? item?.price * (1 - discountInputValue / 100)
        : item?.price - discountInputValue > 0
        ? item?.price - discountInputValue
        : 0

    const discountAmount =
      type === 'percent'
        ? parseFloat((item?.price * (discountInputValue / 100)).toFixed(2))
        : item?.price - discountInputValue > 0
        ? discountInputValue
        : 0

    const discountRate =
      type === 'percent'
        ? discountInputValue
        : item?.price - discountInputValue > 0
        ? parseFloat(((discountInputValue / item?.price) * 100).toFixed(2))
        : 0

    replace(idxInEdit, {
      ...itemInEdit,
      discountType: type,
      totalAmount: newTotalAmount,
      discountRate,
      discountAmount,
      discountValue: discountInputValue,
    })
  }

  const { data: startTimesData, isLoading: startTimesLoading } =
    trpc.startTimeGet.useQuery({
      orgId: orgId,
      locationId: locationId,
      timeSlotDuration: timeSlotDuration,
      employeeId: item?.employee || null,
      date: moment(values?.date).format('YYYY-MM-DD'),
    })

  const clientGiftCards = giftCards?.filter(
    x => x?.clientId === BigInt(values?.client?.id || 0)
  )

  const giftCardsOptions =
    giftCards?.length && !giftCardsLoading
      ? clientGiftCards?.length
        ? [
            {
              label: 'Poklon bonovi klijenta',
              options: clientGiftCards,
            },
            // dont show other giftcards for now
            /* {
              label: 'Ostali poklon bonovi',
              options: otherGiftCards,
            }, */
          ]
        : [
            {
              label: 'Svi poklon bonovi',
              options: giftCards?.filter(
                x => x?.clientId !== BigInt(values?.client?.id || 0)
              ),
            },
          ]
      : []

  const hasGiftCardInOriginal = item?.original?.giftCard?.id

  useEffect(() => {
    if (giftCardsData && !giftCardsLoading && clientGiftCards?.length) {
      setAddGiftCardEnabled(true)
    }
  }, [clientGiftCards, giftCardsData, giftCardsLoading])

  var filteredOptions = giftCardsOptions

  values?.deletedServices?.forEach(service => {
    filteredOptions = [
      {
        label: filteredOptions[0]?.label,
        options: filteredOptions[0]?.options?.map(option => {
          return option?.id === service?.giftCard?.id &&
            service?.giftCard?.giftCardType === 'GIFTCARD'
            ? {
                ...option,
                balance: (option?.balance || 0) + service?.giftCardAmount,
              }
            : option?.id === service?.giftCard?.id &&
              service?.giftCard?.giftCardType === 'VOUCHER'
            ? {
                ...option,
                items: option?.items?.map(x => {
                  return x?.serviceId === BigInt(service?.service)
                    ? {
                        ...x,
                        quantity: (x?.quantity || 0) + 1,
                      }
                    : x
                }),
              }
            : option
        }),
      },
    ]
  })

  values?.services?.forEach(service => {
    if (!service?.original?.giftCard?.id && !!service?.giftCard?.id) {
      // new giftCard is added
      filteredOptions = [
        {
          label: filteredOptions[0]?.label,
          options: filteredOptions[0]?.options?.map(option => {
            return option?.id === service?.giftCard?.id &&
              service?.giftCard?.giftCardType === 'GIFTCARD'
              ? {
                  ...option,
                  balance: (option?.balance || 0) - service?.giftCardAmount,
                }
              : option?.id === service?.giftCard?.id &&
                service?.giftCard?.giftCardType === 'VOUCHER'
              ? {
                  ...option,
                  items: option?.items?.map(x => {
                    return x?.serviceId === BigInt(service?.service)
                      ? {
                          ...x,
                          quantity: (x?.quantity || 0) - 1,
                        }
                      : x
                  }),
                }
              : option
          }),
        },
      ]
    } else if (!!service?.original?.giftCard?.id && !service?.giftCard?.id) {
      // if original giftCard is deleted
      filteredOptions = [
        {
          label: filteredOptions[0]?.label,
          options: filteredOptions[0]?.options?.map(option => {
            return option?.id === service?.original?.giftCard?.id &&
              service?.original?.giftCard?.giftCardType === 'GIFTCARD'
              ? {
                  ...option,
                  balance:
                    (option?.balance || 0) + service?.original?.giftCardAmount,
                }
              : option?.id === service?.original?.giftCard?.id &&
                service?.original?.giftCard?.giftCardType === 'VOUCHER'
              ? {
                  ...option,
                  items: option?.items?.map(x => {
                    return x?.serviceId === BigInt(service?.original?.service)
                      ? {
                          ...x,
                          quantity: (x?.quantity || 0) + 1,
                        }
                      : x
                  }),
                }
              : option
          }),
        },
      ]
    } else if (
      service?.original?.giftCard?.id === service?.giftCard?.id &&
      service?.original?.giftCardAmount !== service?.giftCardAmount &&
      service?.giftCard?.giftCardType === 'GIFTCARD' // if giftCard is same, but amount is changed
    ) {
      filteredOptions = [
        {
          label: filteredOptions[0]?.label,
          options: filteredOptions[0]?.options?.map(x => {
            return x?.id === service?.original?.giftCard?.id
              ? {
                  ...x,
                  balance:
                    x?.balance +
                    service?.original?.giftCardAmount -
                    service?.giftCardAmount,
                }
              : x
          }),
        },
      ]
    } else if (
      !!service?.original?.giftCard?.id &&
      !!service?.giftCard?.id &&
      service?.original?.giftCard?.id !== service?.giftCard?.id // if giftCard is changed
    ) {
      filteredOptions = [
        {
          label: filteredOptions[0]?.label,
          options: filteredOptions[0]?.options?.map(option => {
            return service?.giftCard?.giftCardType === 'GIFTCARD' &&
              option?.id === service?.giftCard?.id
              ? {
                  ...option,
                  balance: (option?.balance || 0) - service?.giftCardAmount,
                }
              : service?.original?.giftCard?.giftCardType === 'GIFTCARD' &&
                option?.id === service?.original?.giftCard?.id
              ? {
                  ...option,
                  balance:
                    (option?.balance || 0) + service?.original?.giftCardAmount,
                }
              : service?.giftCard?.giftCardType === 'VOUCHER' &&
                option?.id === service?.giftCard?.id
              ? {
                  ...option,
                  items: option?.items?.map(x => {
                    return x?.serviceId === BigInt(service?.service)
                      ? {
                          ...x,
                          quantity: (x?.quantity || 0) - 1,
                        }
                      : x
                  }),
                }
              : service?.original?.giftCard?.giftCardType === 'VOUCHER' &&
                option?.id === service?.original?.giftCard?.id
              ? {
                  ...option,
                  items: option?.items?.map(x => {
                    return x?.serviceId === BigInt(service?.original?.service)
                      ? {
                          ...x,
                          quantity: (x?.quantity || 0) + 1,
                        }
                      : x
                  }),
                }
              : option
          }),
        },
      ]
    }
  })

  const filterByRemainingValue = (option: any) => {
    if (
      //@ts-ignore
      option?.id === values?.services[index]?.giftCard?.id ||
      (option?.giftCardType === 'GIFTCARD' && option?.balance > 0)
    )
      return true

    if (option?.giftCardType === 'VOUCHER')
      return (
        option?.items?.find(x => x?.serviceId === BigInt(item?.service || 0))
          ?.quantity > 0
      )

    return false
  }

  const filteredUsedGiftCardOptions = filteredOptions[0]?.options?.filter(
    filterByRemainingValue
  )

  return (
    <>
      {isLoading || startTimesLoading || (giftCards && giftCardsLoading) ? (
        <div style={{ minHeight: '500px' }}>
          <Loader isComponent />
        </div>
      ) : (
        <div className="flex flex-col w-[100%] h-full lg:bg-zoyya-lighterBackground lg:border border-zoyya-grayDarker lg:rounded-md lg:px-5 lg:py-3 lg:mb-6">
          {isMobile && (
            <div className="w-[100%] flex-row justify-between items-center flex -mt-3 lg:mt-0 px-5">
              <div className="text-[24px] font-medium mr-[auto]">
                {item?.isNew
                  ? t('translation.ServicesList.button-add-service')
                  : t('translation.ServicesList.button-update-service')}
              </div>
              <Button
                buttonType={'text'}
                hasIconOnly
                iconComponent={<icons.Cross size="small" />}
                size="small"
                onClick={() => {
                  setIsMenuOpen(false)
                  //if we saved the item in edit, add it to services
                  //othwrwise, remove it
                  item?.isNew
                    ? remove(index)
                    : replace(index, {
                        ...item,
                        ...item?.original,
                        isNew: false,
                        isEdit: false,
                        id: index,
                      })

                  if (
                    !values?.services?.filter(
                      x => x?.service && x?.id !== index
                    ).length
                  )
                    setFieldValue('sendPaymentRequest', false)
                }}
              />
            </div>
          )}
          <div className=" overflow-hidden flex bg-zoyya-lighterBackground m-5 lg:m-0 px-5 lg:p-0 lg:border-0 lg:bg-transparent border border-zoyya-grayDarker rounded-md">
            <div className=" overflow-auto no-scrollbar relative lg:overflow-hidden lg:h-[auto] lg:overflow-visible md:h-[80vh] w-[100%] py-3 lg:py-0">
              {!isMobile &&
              indexInEdit === index &&
              !item?.isNew &&
              !isStatusDraft ? (
                <ButtonTW
                  className="ml-auto"
                  variant={'text'}
                  icon={
                    <icons.Trash
                      color={selectedTheme.colors.accent5}
                      size={'smaller'}
                    />
                  }
                  size={'small'}
                  onClick={() => {
                    if (item?.original?.id && item?.original?.giftCard?.id) {
                      const deletedServices = values?.deletedServices || []
                      setFieldValue('deletedServices', [
                        ...deletedServices,
                        item?.original,
                      ])
                    }
                    remove(index)
                    if (
                      !selectedServices?.filter(
                        x => x?.service && x?.id !== index
                      ).length
                    ) {
                      setFieldValue('sendPaymentRequest', false)
                    }
                  }}
                />
              ) : null}
              <div className="flex-1 overflow-auto">
                <div className="w-[100%] lg:w-[auto] flex-col-reverse lg:flex-row lg:mb-[10px] mb-0 justify-between items-center flex">
                  <div className="flex-[1] w-[100%] lg:mr-[20px] lg:max-w-[180px] mb-[20px] lg:mb-0">
                    <StartTimeSelect
                      name={`services[${index}].startTime`}
                      minusBtnDisabled={item?.startTime?.id === '00:00'}
                      minusBtnOnClick={event => {
                        event.stopPropagation()
                        changeServiceStartTime(
                          '-',
                          item,
                          timeSlotDuration,
                          replace
                        )
                      }}
                      plusBtnDisabled={
                        (item?.endTime === '00:00' ||
                          item?.endTime ===
                            getServiceHoursAndMinutes(
                              getServiceDurationInMinutes('24:00') -
                                timeSlotDuration
                            )) &&
                        getServiceDurationInMinutes(item?.startTime?.title) > 0
                      }
                      plusBtnOnClick={event => {
                        event.stopPropagation()
                        changeServiceStartTime(
                          '+',
                          item,
                          timeSlotDuration,
                          replace
                        )
                      }}
                      hasPlusMinusButtons={true}
                      isDisabled={isStatusDraft}
                      index={index}
                      workHours={startTimesData?.slice(0, -1)}
                    />
                  </div>
                  <div className="flex-[1] w-[100%] mb-[20px] lg:mb-0">
                    <ServiceSelect
                      isDisabled={isStatusDraft || item?.giftCard?.id}
                      employee={employee}
                      filterOptions={filterOptions}
                      name={`services[${index}].service`}
                      onNewService={onNewService}
                      services={services}
                      flattenedServices={flattenedServices}
                    />
                  </div>
                </div>

                <div className="w-[100%] lg:w-[auto] flex-col lg:flex-row lg:mb-[10px] mb-0 justify-between items-center flex">
                  <div className="flex-[1] w-[100%] lg:mr-[20px] lg:max-w-[180px] mb-[20px] lg:mb-0">
                    <DurationSelect
                      isDisabled={isStatusDraft}
                      duration={duration?.filter(
                        x =>
                          getServiceDurationInMinutes(x?.id) <=
                          Number(
                            getServiceDurationInMinutes('24:00') -
                              timeSlotDuration -
                              getServiceDurationInMinutes(
                                item?.startTime?.title
                              )
                          )
                      )}
                      index={index}
                      minusBtnDisabled={item?.durationMinutes === 0}
                      minusBtnOnClick={event => {
                        event.stopPropagation()
                        changeServiceDurations(
                          '-',
                          item,
                          selectedServices,
                          timeSlotDuration,
                          replace
                        )
                      }}
                      plusBtnDisabled={
                        getServiceDurationInMinutes(
                          selectedServices[selectedServices?.length - 1]
                            ?.endTime
                        ) >=
                          getServiceDurationInMinutes('24:00') -
                            timeSlotDuration &&
                        getServiceDurationInMinutes(item?.startTime?.title) > 0
                      }
                      plusBtnOnClick={event => {
                        event.stopPropagation()
                        changeServiceDurations(
                          '+',
                          item,
                          selectedServices,
                          timeSlotDuration,
                          replace
                        )
                      }}
                      hasPlusMinusButtons={true}
                    />
                  </div>
                  <div className="flex-[1] w-[100%] mb-[20px] lg:mb-0">
                    <EmployeeSelect
                      employees={employees}
                      filterOptions={filterEmployeeOptions}
                      name={`services[${index}].employee`}
                    />
                  </div>

                  {!!selectedService && !!selectedService?.resourceType?.id && (
                    <div className="lg:ml-[20px] flex-[1] w-[100%] mb-[20px] lg:mb-0">
                      <ResourceTypesSelect
                        label={
                          service?.resourceType?.name ||
                          t('translation.ResourceEditorForm.label-resource')
                        }
                        resourceTypes={
                          resources?.length && inappropriateResources?.length
                            ? [...resources, ...inappropriateResources]
                            : resources?.length
                            ? resources
                            : inappropriateResources?.length
                            ? inappropriateResources
                            : []
                        }
                        name={`services[${index}].resourceId`}
                      />
                    </div>
                  )}
                </div>

                {giftCardsData?.length && loyaltyFeatureFlag ? (
                  <div className="mb-[5px]">
                    {clientGiftCards?.length ? null : !hasGiftCardInOriginal ||
                      !filteredUsedGiftCardOptions?.length ? (
                      <div className="my-4">
                        <Button
                          type="button"
                          buttonType={
                            addGiftCardEnabled ? 'primary' : 'secondary'
                          }
                          label={'Dodaj poklon bon'}
                          iconComponent={<Gift size={'small'} />}
                          onClick={e => {
                            setAddGiftCardEnabled(!addGiftCardEnabled)
                            /*  if (addGiftCardEnabled) {
                            setFieldValue(`services[${index}].giftCard`, null)
                          } */
                          }}
                          size="small"
                          style={{ width: '180px' }}
                        />
                      </div>
                    ) : null}
                    {addGiftCardEnabled &&
                    (filteredUsedGiftCardOptions?.length ||
                      item?.giftCard?.id) ? (
                      <GiftCardSelect
                        name={`services[${index}].giftCard`}
                        isDisabled={isStatusDraft}
                        index={index}
                        options={[
                          {
                            label: filteredOptions[0]?.label,
                            options: filteredUsedGiftCardOptions,
                          },
                        ]}
                        allGiftCards={giftCards}
                        isClearable
                        serviceId={item?.service}
                        currency={item?.currencyId}
                        filterGiftCardOptions={filterGiftCardOptions}
                      />
                    ) : null}
                  </div>
                ) : null}

                <div className="flex items-end justify-between">
                  {isMobile &&
                  selectedService &&
                  selectedService?.price > 0 &&
                  item?.giftCard?.giftCardType !== 'VOUCHER' ? (
                    <div className="flex flex-col">
                      <p className="font-medium mt-[5px]">
                        {t('translation.ServicesList.label-price')}
                      </p>
                      <div className="flex gap-3 items-center">
                        <div className="flex flex-col items-end">
                          {inputValue && inputValue !== 0 ? (
                            <p>{`${formatPriceNumberWithDelimiter(
                              item?.totalAmount
                            )} ${item?.currencyId}`}</p>
                          ) : null}
                          <p
                            className={`${
                              inputValue && inputValue !== 0
                                ? 'text-sm text-zoyya-secondaryDark line-through'
                                : ''
                            }`}
                          >{`${formatPriceNumberWithDelimiter(
                            item?.price
                          )} ${item?.currencyId}`}</p>
                        </div>
                        <ServiceDiscount style={{ position: 'unset' }}>
                          <div
                            style={{
                              width: 65,
                              marginRight: 5,
                            }}
                          >
                            <StyledDiscountInput
                              name={`services[${index}].discountValue`}
                              type="text"
                              value={inputValue}
                              onChange={e => updateDiscount(e)}
                              placeholder="popust"
                            />
                          </div>
                          <Button
                            type="button"
                            buttonType={
                              item?.discountType === 'percent'
                                ? 'primary'
                                : 'secondary'
                            }
                            label={'%'}
                            onClick={() => updateDiscountType('percent')}
                            size="small"
                            style={{
                              fontSize: 16,
                              fontWeight: 300,
                            }}
                          />
                          <Button
                            type="button"
                            buttonType={
                              item?.discountType === 'amount'
                                ? 'primary'
                                : 'secondary'
                            }
                            label={item?.currencyId}
                            onClick={() => updateDiscountType('amount')}
                            size="small"
                            style={{ width: 'auto' }}
                          />
                        </ServiceDiscount>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-end mt-auto">
            {!isMobile &&
            selectedService &&
            selectedService?.price > 0 &&
            item?.giftCard?.giftCardType !== 'VOUCHER' ? (
              <div className="flex flex-col">
                <p className="font-medium mt-[5px]">
                  {t('translation.ServicesList.label-price')}
                </p>
                <div className="flex gap-3 items-center">
                  <div className="flex flex-col items-end">
                    {inputValue && inputValue !== 0 ? (
                      <p>{`${formatPriceNumberWithDelimiter(
                        item?.totalAmount
                      )} ${item?.currencyId}`}</p>
                    ) : null}

                    <p
                      className={`${
                        inputValue && inputValue !== 0
                          ? 'text-sm text-zoyya-secondaryDark line-through'
                          : ''
                      }`}
                    >
                      {`${formatPriceNumberWithDelimiter(
                        item?.price
                      )} ${item?.currencyId}`}
                    </p>
                  </div>
                  <ServiceDiscount style={{ position: 'unset' }}>
                    <div
                      style={{
                        width: 65,
                        marginRight: 5,
                      }}
                    >
                      <StyledDiscountInput
                        name={`services[${index}].discountValue`}
                        type="text"
                        value={inputValue}
                        onChange={e => updateDiscount(e)}
                        placeholder="popust"
                      />
                    </div>
                    <Button
                      type="button"
                      buttonType={
                        item?.discountType === 'percent'
                          ? 'primary'
                          : 'secondary'
                      }
                      label={'%'}
                      onClick={() => updateDiscountType('percent')}
                      size="small"
                      style={{
                        fontSize: 16,
                        fontWeight: 300,
                      }}
                    />
                    <Button
                      type="button"
                      buttonType={
                        item?.discountType === 'amount'
                          ? 'primary'
                          : 'secondary'
                      }
                      label={item?.currencyId}
                      onClick={() => updateDiscountType('amount')}
                      size="small"
                      style={{ width: 'auto' }}
                    />
                  </ServiceDiscount>
                </div>
              </div>
            ) : null}

            {/*   <div className="flex ">
              <Button
                type="button"
                buttonType={addGiftCardEnabled ? 'primary' : 'secondary'}
                label={'Dodaj poklon bon'}
                iconComponent={<Gift size={'small'} />}
                onClick={e => {
                  setAddGiftCardEnabled(prev => !prev)
                  console.log(e)
                }}
                size="small"
                style={{ width: '180px' }}
              />
            </div> */}
            <ButtonsRow>
              {!isMobile && (
                <Button
                  label={t('translation.AppointmentModal.button-cancelText')}
                  buttonType={'secondary'}
                  size={'small'}
                  onClick={e => {
                    e.preventDefault()

                    item?.isNew
                      ? remove(index)
                      : replace(index, {
                          ...item,
                          ...item?.original,
                          isNew: false,
                          isEdit: false,
                          id: index,
                        })
                    if (
                      !selectedServices?.filter(
                        x => x?.service && x?.id !== index
                      ).length
                    ) {
                      setFieldValue('sendPaymentRequest', false)
                    }
                  }}
                />
              )}
              {isMobile &&
              indexInEdit === index &&
              !item?.isNew &&
              !isStatusDraft ? (
                <Button
                  label={t('translation.DeleteButton.label-delete')}
                  buttonType={'primaryAccent'}
                  iconComponent={
                    <icons.Trash
                      color={selectedTheme.colors.accent5}
                      size={'smaller'}
                    />
                  }
                  size={'small'}
                  onClick={() => {
                    if (item?.original?.id && item?.original?.giftCard?.id) {
                      const deletedServices = values?.deletedServices || []
                      setFieldValue('deletedServices', [
                        ...deletedServices,
                        item?.original,
                      ])
                    }
                    remove(index)
                    if (
                      !selectedServices?.filter(
                        x => x?.service && x?.id !== index
                      ).length
                    )
                      setFieldValue('sendPaymentRequest', false)

                    setIsMenuOpen(false)
                  }}
                />
              ) : null}
              <Button
                label={t('translation.AppointmentModal.button-okText')}
                type="button"
                buttonType={isMobile ? 'primary' : 'secondaryLink'}
                size={isMobile ? 'medium' : 'small'}
                onClick={async () => {
                  const newErrors = await validateForm()
                  setError(newErrors?.services)
                  if (!newErrors?.services) {
                    const endTime = moment(
                      moment(itemInEdit?.startTimeUtc).format('YYYY-MM-DD') +
                        ' ' +
                        itemInEdit?.startTime?.id?.toString()
                    )
                      .add(
                        getServiceDurationInMinutes(itemInEdit?.duration),
                        'minutes'
                      )
                      .format('HH:mm')
                    const endTimeUtc = moment(
                      moment(itemInEdit?.startTimeUtc).format('YYYY-MM-DD') +
                        ' ' +
                        endTime?.toString()
                    )
                      .utc(true)
                      .format()
                    const employeeProfessions = employee?.professions
                      ? [...employee?.professions?.map(y => y?.id), undefined]
                      : [undefined]
                    const hasProfession = employeeProfessions?.includes(
                      service?.profession?.id?.toString()
                    )

                    replace(idxInEdit, {
                      ...itemInEdit,
                      durationMinutes: getServiceDurationInMinutes(
                        itemInEdit?.duration
                      ),
                      endTime: endTime,
                      endTimeUtc: endTimeUtc,
                      isEdit: false,
                      isNew: false,
                      id: index,
                      missingProfession: !service?.profession?.id
                        ? false
                        : !hasProfession,
                      original: {
                        duration: itemInEdit?.duration,
                        durationMinutes: getServiceDurationInMinutes(
                          itemInEdit?.duration
                        ),
                        employee: itemInEdit?.employee,
                        endTime: endTime,
                        endTimeUtc: endTimeUtc,
                        startTime: itemInEdit?.startTime,
                        startTimeUtc: itemInEdit?.startTimeUtc,
                        resourceId: itemInEdit?.resourceId,
                        resourceType: itemInEdit?.resourceType,
                        service: itemInEdit?.service,
                        discountType: item?.discountType,
                        totalAmount: itemInEdit?.totalAmount,
                        discountRate: itemInEdit?.discountRate,
                        discountAmount: itemInEdit?.discountAmount,
                        discountValue: itemInEdit?.discountValue,
                        giftCardAmount: itemInEdit?.original?.giftCardAmount,
                        giftCard: itemInEdit?.original?.giftCard,
                      },
                      discountType: item?.discountType,
                      totalAmount: itemInEdit?.totalAmount,
                      discountRate: itemInEdit?.discountRate,
                      discountAmount: itemInEdit?.discountAmount,
                      discountValue: itemInEdit?.discountValue,
                    })
                    setIsMenuOpen(false)
                  }
                }}
              />
            </ButtonsRow>
          </div>
        </div>
      )}
    </>
  )
}

export const ButtonsRow = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: end;
  margin-top: 10px;
  ${ButtonStyled} {
    &:last-child {
      margin-left: 15px;
    }
  }

  @media ${device.tablet} {
    margin-top: auto;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    width: 100%;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: white;

    ${ButtonStyled} {
      height: 50px;
      flex: 1;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
`
