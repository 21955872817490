import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { getOrgId, getLocationId } from 'src/helpers'
import { trpc } from 'src/trpc'

export const useACL = () => {
  const { orgId, locationId } = useParams<{
    orgId: string
    locationId: string
  }>()

  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsLoading,
  } = trpc.getUserPermissions.useQuery({
    orgId: orgId || getOrgId()!,
    locationId: locationId ? BigInt(locationId) : BigInt(getLocationId() || 0),
  })

  const hasPermission =
    !permissionsLoading && permissions && !permissionsError
      ? (policy: keyof typeof permissions) => {
          return permissions?.[policy]?.value === 'allow'
        }
      : () => true

  const isEmailPlaceholder = email => email === '*****@mail.com'
  const isPhonePlaceholder = phone => phone === '+385*******'

  return {
    permissions,
    hasPermission,
    isEmailPlaceholder,
    isPhonePlaceholder,
    permissionsFetched: !permissionsLoading && permissions && !permissionsError,
  }
}
