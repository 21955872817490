import React, { useContext, useEffect, useRef, useCallback } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useFlexLayout,
  useResizeColumns,
} from 'react-table'
import Button from '../Button/Button'
import { StyledMenuIcon, TableHeaderItem, TableRow } from '../../styles'
import { TableStyled } from './styles'
import { Loader } from '../Loader/Loader'
import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'
import { SearchBarWrapper } from '../SearchBar/style'
import SearchIcon from '../Icon/Icons/Search'
import Cross from '../Icon/Icons/Cross'
import * as Styles from '../Input/style'
import { MobileContext } from 'src/Context'
import { useSdk } from 'sdk'
import { FixedSizeList as List } from 'react-window'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

function TableComponent({
  columns,
  data,
  skipReset,
  loading,
  onRowClick,
  setData,
  isMove,
  onMove,
  hasGlobalFilter,
  globalSearch,
  searchValue,
  hasHeader,
  hasTabsHeader,
  tableHeight,
  fetchMoreData,
  isInfinite,
  hasMoreData,
  innerWidth,
  isSelected,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 100,
      maxWidth: 400,
    }),
    []
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    state,
    rows,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      initialState: { pageIndex: 1, pageSize: 50 },
      manualPagination: true,
      columns,
      data,
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      disableMultiSort: true,
      manualGlobalFilter: !!globalSearch,
      defaultColumn,
    },
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns
  )

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = data[dragIndex]
    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  const inputRef = useRef()

  function GlobalFilter({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 200)

    const onGlobalSearchChange = useAsyncDebounce(value => {
      globalSearch(value || '')
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 20)

    const isMobile = useContext(MobileContext)
    const { t } = useSdk()

    return (
      <SearchBarWrapper
        isHeader
        style={{
          position: 'absolute',
          top: isMobile ? '65px' : '75px',
          zIndex: '999',
          maxWidth: isMobile ? 'calc(100% - 128px)' : '400px',
          marginLeft: isMobile ? '110px' : 0,
        }}
      >
        <div className="w-full p-[3px]">
          <Styles.InputFieldWrapper size={'small'}>
            <SearchIcon />
            <Styles.InputStyled
              placeholder={t('translation.Table.label-search')}
              type="text"
              ref={inputRef}
              size={'small'}
              value={searchValue || value || ''}
              onChange={e => {
                if (globalSearch) {
                  onGlobalSearchChange(e.target.value)
                } else {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }
              }}
              data-cy={`input_services`}
            />
          </Styles.InputFieldWrapper>
        </div>
        {searchValue && (
          <div
            className="absolute right-[10px] top-[10px] text-zoyya-secondaryDark cursor-pointer max-md:top-[15px]"
            onClick={() => {
              setValue('')
              onChange('')
            }}
          >
            <Cross size={'large'} color={'black'} />
          </div>
        )}
      </SearchBarWrapper>
    )
  }

  const TableWithStyles = () => {
    return (
      <TableStyled hasHeader={hasHeader} hasTabsHeader={hasTabsHeader}>
        <div className="table rt-table">
          {headerGroups.map(headerGroup => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="tr rt-thead -header"
              style={{
                display: 'flex',
                flex: '1 0 auto',
                minWidth: innerWidth ? innerWidth : 1390,
              }}
            >
              {headerGroup.headers.map(column => (
                <TableHeaderItem {...column.getHeaderProps()} className="th">
                  <div>
                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                    </span>
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  </div>
                </TableHeaderItem>
              ))}
            </div>
          ))}

          <div
            {...getTableProps()}
            className={'rt-tbody'}
            style={{ minWidth: innerWidth ? innerWidth : 1390 }}
          >
            {loading ? <Loader isComponent /> : null}
            {parseInt(tableHeight) > 0 && (
              <div
                style={{
                  overflow: 'auto',
                  height: tableHeight,
                  width: '100%',
                }}
              >
                {page.map((row, index) => {
                  return (
                    prepareRow(row) || (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        moveRow={moveRow}
                        isMove={isMove}
                        data={data}
                        onRowClick={onRowClick}
                        onMove={onMove}
                        isSelected={isSelected}
                        {...row.getRowProps()}
                      />
                    )
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </TableStyled>
    )
  }

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && hasMoreData) {
      fetchMoreData()
    }
  }, [inView, hasMoreData])

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <TableRow
          ref={index + 1 === rows?.length - 15 ? ref : null}
          className="tr"
          {...row.getRowProps({
            onClick: () => onRowClick(row.original),
            style,
          })}
          isSelected={
            isSelected?.id !== undefined && isSelected?.id === row.original?.id
          }
        >
          {row?.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </TableRow>
      )
    },
    [prepareRow, rows, onRowClick]
  )

  return (
    <TableSection>
      {hasGlobalFilter && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {isInfinite && tableHeight ? (
        <TableStyled
          isScrollable
          hasHeader={hasHeader}
          hasTabsHeader={hasTabsHeader}
        >
          <div className="table rt-table">
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr rt-thead -header"
              >
                {headerGroup.headers.map(column => (
                  <TableHeaderItem {...column.getHeaderProps()} className="th">
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                      </span>
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? 'isResizing' : ''
                        }`}
                      />
                    </div>
                  </TableHeaderItem>
                ))}
              </div>
            ))}

            <div {...getTableProps()} className={'rt-tbody'}>
              {loading ? <Loader isComponent /> : null}

              <List
                className="List"
                height={tableHeight}
                itemCount={rows.length}
                itemSize={50}
              >
                {RenderRow}
              </List>
            </div>
          </div>
        </TableStyled>
      ) : (
        <TableWithStyles />
      )}
    </TableSection>
  )
}
const DND_ITEM_TYPE = 'row'

const Row = ({
  row,
  index,
  moveRow,
  onRowClick,
  isMove,
  onMove,
  data,
  isSelected,
}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item, monitor) {
      onMove(data)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  const { t } = useSdk()

  preview(drop(dropRef))
  drag(dragRef)

  const getIsExpired = rowData => {
    if (
      'isInvitation' in rowData &&
      'isLocked' in rowData &&
      'hasOrgMembership' in rowData
    ) {
      return (
        rowData.isInvitation || rowData.isLocked || !rowData.hasOrgMembership
      )
    }

    return false
  }
  return (
    <TableRow
      ref={dropRef}
      style={{ opacity }}
      isExpired={getIsExpired(row.original)}
      {...row.getRowProps({
        onClick: () => onRowClick(row.original),
      })}
      className="tr"
      data-cy={'table_row_' + index}
      isSelected={
        isSelected?.id !== undefined && isSelected?.id === row.original?.id
      }
    >
      {row.cells.map(cell => {
        return (
          <div {...cell.getCellProps()} className="td">
            {isMove && cell?.column?.id === 'move' ? (
              <div ref={dragRef} className="moveButton td">
                <Button
                  buttonType={'text'}
                  iconComponent={<StyledMenuIcon size="smaller" />}
                  hasIconOnly
                  size={'small'}
                  tooltip={t('translation.Table.label-move')}
                />
              </div>
            ) : (
              cell.render('Cell')
            )}
          </div>
        )
      })}
    </TableRow>
  )
}

const ReactTable = props => {
  const {
    columns,
    tableData,
    setPageNumber,
    pageNumber,
    loading,
    onRowClick,
    setPageLength,
    isMove,
    onMove,
    hasPagination,
    isUpdating,
    hasGlobalFilter,
    globalSearch,
    searchValue,
    hasHeader,
    hasTabsHeader,
    tableHeight,
    fetchMoreData,
    isInfinite,
    hasMoreData,
    innerWidth,
    isSelected,
  } = props
  const [data, setData] = React.useState(tableData)

  useEffect(() => {
    if (!isUpdating) {
      setData(tableData)
    }
  }, [isUpdating, tableData])

  return (
    <TableComponent
      columns={columns}
      data={data}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      loading={loading}
      onRowClick={onRowClick}
      setPageLength={setPageLength}
      setData={setData}
      isMove={isMove}
      onMove={onMove}
      hasHeader={hasHeader}
      hasGlobalFilter={hasGlobalFilter}
      globalSearch={globalSearch}
      hasPagination={hasPagination}
      searchValue={searchValue}
      hasTabsHeader={hasTabsHeader}
      tableHeight={tableHeight}
      fetchMoreData={fetchMoreData}
      isInfinite={isInfinite}
      hasMoreData={hasMoreData}
      innerWidth={innerWidth}
      isSelected={isSelected}
    />
  )
}

export const TableSection = styled.div`
  max-height: 100%;
  width: 100%;
  display: flex;
`

export default ReactTable
