import { icons } from 'components/Icon'
import React from 'react'
import styled from 'styled-components'
import {device} from "../../constants";

type TProps = {
  phoneNumber: string
  mobileUserDetails?: boolean;
}

type TStyledButtonsContainer = {
  mobileUserDetails?: boolean
}
export const StyledButtonsContainer = styled.div<TStyledButtonsContainer>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => (props.mobileUserDetails ? "0" : "15px")};
`

type TStyledLinkButton = {
  mobileUserDetails?: boolean
}
export const StyledLinkButton = styled.a<TStyledLinkButton>`
  display: flex;
  flex-direction: column;
  margin: ${props => (props.mobileUserDetails ? "0" : "0.5rem")};
  margin-right: ${props => (props.mobileUserDetails ? "10px" : "0.5rem")};
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
  
  @media ${device.tablet}{
      span {
      display: none;
      };
  }
`
export const MessagingButtons = (props: TProps) => {
  return (
    <StyledButtonsContainer mobileUserDetails={props.mobileUserDetails}>
      <StyledLinkButton
        mobileUserDetails={props.mobileUserDetails}
        href={`sms:${props.phoneNumber};`}
        target="_blank"
      >
        <icons.Sms></icons.Sms>
        <span>SMS</span>
      </StyledLinkButton>
      <StyledLinkButton
        href={`https://wa.me/${props.phoneNumber}`}
        target="_blank"
      >
        <icons.WhatsApp></icons.WhatsApp>
        <span>WhatsApp</span>
      </StyledLinkButton>
    </StyledButtonsContainer>
  )
}
