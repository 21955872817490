import React from 'react'
import {
  ProductSearchDocument,
  ProductTypeAllDocument,
  useProductCreateMutation,
  useProductTypeAllQuery,
} from 'src/state/graphql'
import { ProductForm } from './ProductForm'
import { useSdk } from 'sdk'
import { useLocation } from 'react-router-dom'
import { useHeader } from 'src/mobile/Header'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { TProduct } from './EditProduct'
import { TypeProductType } from '../ProductsList'

type TypeNewProduct = {
  setShowAddProductForm?: (boolean) => any
}

const NewProduct = (props: TypeNewProduct) => {
  const { setShowAddProductForm } = props
  const { t, appServices, navigateTo, useParams } = useSdk()
  const { pathname } = useLocation()
  const { typeId, locationId, orgId } = useParams<{
    orgId: string
    typeId: string
    locationId: string
  }>()

  useHeader({
    content: t('translation.UpgradePageProducts.label-new-product'),
    onGoBack: navigateTo.productsList,
  })

  const loadOrg = useQuery(getOrganizationByIdGql, { variables: { id: orgId } })
  const { data: organizationData } = loadOrg
  const org = organizationData?.organization?.get

  const [
    productCreate,
    { loading: loadingProductCreate },
  ] = useProductCreateMutation()

  const {
    data: productTypesData,
    loading: productTypesLoading,
  } = useProductTypeAllQuery()

  const productTypes =
    (productTypesData?.productType_all as TypeProductType[]) || []

  const defaultProduct = {
    name: '',
    barCode: null,
    uom: 'PC',
    vatRate: org?.vatStatus === 'Y' ? 0.25 : 0,
    retailPrice: 0,
    type:
      typeId && typeId !== '*'
        ? { id: typeId }
        : productTypes && productTypes[0]
        ? { id: productTypes[0]?.id }
        : null,
    status: 'ACTIVE',
    listPrice: null,
    purchasePrice: null,
    listDiscountRate: 0,
  } as TProduct

  const handleSubmit = async (values: TProduct) => {
    const input = {
      productTypeId: values.type?.id,
      name: values.name,
      code: values.code,
      retailPrice: values.retailPrice
        ? parseFloat(values.retailPrice?.toString().replace(',', '.'))
        : parseFloat('0'),
      vatRate: values.vatRate ? values.vatRate / 100 : parseFloat('0'),
      uom: values.uom,
      barCode: values.barCode,
      description: values?.description || null,
      listPrice: parseFloat(
        values.listPrice?.toString().replace(',', '.') || '0'
      ),
      purchasePrice: values.purchasePrice
        ? parseFloat(values.purchasePrice?.toString().replace(',', '.') || '0')
        : parseFloat(values.listPrice?.toString().replace(',', '.') || '0'),
      listDiscountRate:
        parseFloat(
          values?.listDiscountRate?.toString().replace(',', '.') || '0'
        ) / 100,
    }

    let result = await productCreate({
      variables: { input },
      awaitRefetchQueries: true, // to prevent ProductList duplicate new record due to initial fetchMore with stale data
      refetchQueries: [
        {
          query: ProductTypeAllDocument,
        },
        ...(typeId !== '*'
          ? [
              {
                query: ProductSearchDocument,
                variables: {
                  input: {
                    productTypeId: Number(typeId),
                    searchTerm: '',
                    fetch: {
                      cursor: null,
                      limit: 40,
                    },
                  },
                },
              },
            ]
          : []),
      ],
    })
    appServices
      .handleMutationResult(
        result,
        t('translation.EditProduct.toast-product-created')
      )

      .onSuccess(() => {
        pathname.includes(`/wms/goodsReceived`)
          ? setShowAddProductForm?.(false)
          : navigateTo.productsList({
              locationId,
              typeId: typeId && typeId !== '*' ? typeId : productTypes[0]?.id,
            })
      })
  }

  return (
    <ProductForm
      initialValues={defaultProduct}
      onSubmit={handleSubmit}
      loading={loadingProductCreate || productTypesLoading}
      isNew={true}
      setShowAddProductForm={setShowAddProductForm}
    />
  )
}

export default NewProduct
