import React, { useContext } from 'react'
import SocialLogin from 'react-social-login'
import Button from './Button'
import { Facebook } from '../Icon/Icons/Facebook'
import { Google } from '../Icon/Icons/Google'
import { MobileContext } from 'src/Context'
import i18n from 'src/localization'
import { useSdk } from 'src/sdk'
const providerAppId = {
  facebook: '832171173987474',
  google:
    '687616046414-tosav169mahectvb4jon1ud6p4iqvuis.apps.googleusercontent.com',
}
import { useGoogleLogin } from '@react-oauth/google'
import {
  GetGoogleUserInfoDocument,
  GetGoogleUserInfoQuery,
} from 'src/state/graphql'

const providerButtonIcon = {
  facebook: <Facebook />,
  google: <Google />,
}

const providerName = {
  facebook: 'facebookLogin',
  google: 'googleLogin',
}

class SocialLoginButtonBase extends React.Component<any> {
  render() {
    const providerButtonText = {
      facebook: i18n.t('translation.SocialLogin.continueWithFB'),
      google: i18n.t('translation.SocialLogin.continueWithGoogle'),
    }

    const handleLogin = () => {
      this.props.triggerLogin()
      this.props.onClick && this.props.onClick()
    }

    const provider = this.props.children as string
    const label = this.props.label as string
    return (
      <Button
        onClick={handleLogin}
        style={{ margin: 'auto', height: 50 }}
        type="button"
        buttonType={'secondaryLink'}
        iconComponent={providerButtonIcon[provider]}
        label={label ? label : providerButtonText[provider]}
        fullWidth
        size={this.props.size || (this.props.isMobile ? 'large' : 'extraLarge')}
        name={providerName[provider]}
      />
    )
  }
}

const SocialLoginButtonComponent = SocialLogin(SocialLoginButtonBase)

type TProps = {
  provider: 'facebook' | 'google'
  label?: string
  onLoginSuccess?: (user) => any
  onLoginFailure?: (user) => any
  onBusy?: (isBusy: boolean) => any
  children?: any
  size?: string
  onClick?: () => void
}

export const SocialLoginButton = (props: TProps) => {
  const { provider, label, size, onClick } = props
  const handleSuccess = user => {
    props.onLoginSuccess?.(user)
  }
  const handleFailure = user => {
    props.onLoginFailure?.(user)
  }
  const appId = providerAppId[provider]
  const isMobile = useContext(MobileContext)
  if (provider === 'google') {
    return (
      <GoogleLoginButton
        {...props}
        size={size}
        onSuccess={handleSuccess}
        onFailure={handleFailure}
      ></GoogleLoginButton>
    )
  }
  return (
    <SocialLoginButtonComponent
      provider={provider}
      appId={appId}
      label={label}
      autoLogin={false}
      onLoginSuccess={handleSuccess}
      onLoginFailure={handleFailure}
      isMobile={isMobile}
      size={size}
      onClick={onClick}
    >
      {provider}
    </SocialLoginButtonComponent>
  )
}

const GoogleLoginButton = props => {
  const { label, isMobile, size, onSuccess } = props
  const { apolloClient, t, toast } = useSdk()

  const providerButtonText = {
    facebook: t('translation.SocialLogin.continueWithFB'),
    google: t('translation.SocialLogin.continueWithGoogle'),
  }
  const handleSuccess = async tokenResponse => {
    const { access_token } = tokenResponse

    const queryResult = await apolloClient.query<GetGoogleUserInfoQuery>({
      query: GetGoogleUserInfoDocument,
      variables: {
        input: {
          accessToken: access_token,
        },
      },
    })

    if (queryResult?.errors?.length) {
      toast.danger(
        queryResult?.errors?.[0]?.message || t('translation.SocialLogin.error')
      )
      return
    }

    const userInfo = queryResult?.data?.user?.getGoogleUserInfo
    if (!userInfo?.id) {
      toast.danger(t('translation.SocialLogin.error'))
      return
    }

    const result = {
      _profile: {
        id: userInfo?.id,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        profilePicUrl: userInfo?.avatarUrl,
        email: userInfo?.email,
      },
      token: {
        accessToken: access_token,
      },
      _provider: 'google',
    }
    onSuccess(result)
  }
  const handleFailure = error => {
    toast.danger(error?.message || t('translation.SocialLogin.error'))
  }

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleFailure,
    onNonOAuthError: handleFailure,
  })

  const handleClick = () => {
    login()
  }

  /* const isFacebookApp = () => {
    var ua = navigator.userAgent || navigator.vendor || (window as any).opera
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
  } */
  return (
    <Button
      onClick={handleClick}
      style={{ margin: 'auto', height: 50 }}
      type="button"
      buttonType={'secondaryLink'}
      iconComponent={providerButtonIcon.google}
      label={label ? label : providerButtonText.google}
      fullWidth
      size={size || (isMobile ? 'large' : 'extraLarge')}
      name={'googleLogin'}
    />
  )
}
