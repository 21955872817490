import React from 'react'
import { PaymentMethodButton } from './styles'
import { usePosStore } from 'state/local/posReceiptStore'
import { PosReceiptStore } from 'state/local/posReceiptStore'
import { icons } from 'components'
import { TReceiptStore } from '../ReceiptDetailsModal'

type PaymentOptionButtonProps = {
  isSelected?: boolean
  isDisabled?: boolean
  icon?: any
  text?: string
  onPress?: () => void
}
const PaymentOptionButton = (props: PaymentOptionButtonProps) => {
  const { isSelected, isDisabled, icon, text, onPress } = props
  return (
    <PaymentMethodButton
      isSelected={isSelected}
      onClick={!isDisabled ? onPress : () => {}}
      isDisabled={isDisabled}
    >
      {icon}
      <p style={{ marginTop: 5 }}>{text}</p>
    </PaymentMethodButton>
  )
}

export const PaymentMethodSelector = (props: {
  isDisabled: boolean
  selectOption: (val: any) => void
}) => {
  const receipt = usePosStore(store => store.pos.receipt) as TReceiptStore

  const selectedMethod = receipt?.paymentMethod as
    | 'CASH'
    | 'CARD'
    | 'WIRE'
    | 'VIVA_WALLET'
  const { isDisabled, selectOption } = props
  return (
    <div>
      <p style={{ fontSize: 14, fontWeight: 500 }}>Način plaćanja</p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          marginTop: 8,
          gap: '10px',
        }}
      >
        <PaymentOptionButton
          onPress={() => {
            selectOption('CASH')
          }}
          isDisabled={isDisabled}
          text="Gotovina"
          icon={<icons.Coins />}
          isSelected={selectedMethod === 'CASH'}
        />

        <PaymentOptionButton
          onPress={() => {
            selectOption('CARD')
          }}
          isDisabled={isDisabled}
          text="Kartica"
          icon={<icons.CreditCard />}
          isSelected={selectedMethod === 'CARD'}
        />
        <PaymentOptionButton
          onPress={() => {
            selectOption('WIRE')
          }}
          isDisabled={isDisabled}
          text="Transakcijski račun"
          icon={<icons.Globe />}
          isSelected={selectedMethod === 'WIRE'}
        />
      </div>
    </div>
  )
}
