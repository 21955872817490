import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import moment from 'moment'
import { getStatusColor, getStatusLabel } from 'src/helpers'
import { useSdk } from 'sdk'
import { RatingStars } from 'views/ReviewPage/ReviewPage'
import { icons } from 'components/Icon'

const AppointmentCardGroup = props => {
  const { event } = props
  const { t } = useSdk()
  const selectedTheme = useContext(ThemeContext)

  function getFillColor(reviewRating) {
    return reviewRating && reviewRating >= 1 ? '#ffc359' : 'none'
  }

  function getRatingStars(reviewRating) {
    return (
      <RatingStars
        style={{
          marginTop: 0,
          justifyContent: 'flex-end',
        }}
      >
        <icons.RatingStar
          size={'smaller'}
          color={'#ffc359'}
          fill={getFillColor(reviewRating)}
        />
        <icons.RatingStar
          size={'smaller'}
          color={'#ffc359'}
          fill={getFillColor(reviewRating)}
        />
        <icons.RatingStar
          size={'smaller'}
          color={'#ffc359'}
          fill={getFillColor(reviewRating)}
        />
        <icons.RatingStar
          size={'smaller'}
          color={'#ffc359'}
          fill={getFillColor(reviewRating)}
        />
        <icons.RatingStar
          size={'smaller'}
          color={'#ffc359'}
          fill={getFillColor(reviewRating)}
        />
      </RatingStars>
    )
  }

  return (
    <ClientItemRow>
      <ClientListItem>
        <ClientItemColumn>
          <ClientItemHour>{`${event?.startTime} - ${event?.endTime}`}</ClientItemHour>
          <ClientItemDate>
            {moment(event?.date).format("DD MMM 'YY")}
          </ClientItemDate>
        </ClientItemColumn>
        <ClientItemDivider />
        <ClientItemColumn>
          <ClientItemLabel>
            {t('translation.ClientDetails.services')}
          </ClientItemLabel>
          {event?.services?.map(service => (
            <ClientItemName key={service?.id}>{service?.name}</ClientItemName>
          ))}
        </ClientItemColumn>
        <ClientItemDivider />
        <ClientItemColumn>
          <ClientItemLabel>
            {t('translation.AppointmentGroup.resource')}
          </ClientItemLabel>
          <ClientItemName>{`${
            !event?.assignedTo?.nickName
              ? event?.assignedTo?.firstName || ''
              : event?.assignedTo?.nickName || ''
          }`}</ClientItemName>
        </ClientItemColumn>
        <ClientItemColumn>
          <ClientAppointmentStatus
            color={getStatusColor(event?.status, selectedTheme)}
            reason={event?.reason}
          >
            {getStatusLabel(event?.status, t, false, event?.reason)}
          </ClientAppointmentStatus>
          {getRatingStars(event?.reviewRating)}
        </ClientItemColumn>
      </ClientListItem>
      {event?.notes ? (
        <ClientItemNote>
          <ClientNote>{t('translation.ClientDetails.note') + ': '}</ClientNote>
          {event?.notes}
        </ClientItemNote>
      ) : null}
    </ClientItemRow>
  )
}

export default AppointmentCardGroup

type TClientItemRowProps = {}
export const ClientItemRow = styled.div<TClientItemRowProps>`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: 10px;
  background: #f4f4ff96;
  border-radius: 4px;
`

type TClientListItemProps = {}
export const ClientListItem = styled.div<TClientListItemProps>`
  display: flex;
  align-items: flex-start;
  padding: 0px;
`

type TClientItemColumnProps = {}
export const ClientItemColumn = styled.div<TClientItemColumnProps>`
  display: flex;
  flex-direction: column;
  :nth-child(1) {
    width: 135px;
  }
  :nth-child(3) {
    width: 270px;
    padding-left: 20px;
  }
  :nth-child(5) {
    padding-left: 20px;
    width: 145px;
  }
  :nth-child(6) {
    margin-left: auto;
  }
`

type TClientItemDateProps = {}
export const ClientItemDate = styled.span<TClientItemDateProps>`
  font-size: 14px;
  font-weight: 500;
`

type TClientItemDividerProps = {}
export const ClientItemDivider = styled.div<TClientItemDividerProps>`
  width: 2px;
  height: 40px;
`

type TClientItemLabelProps = {}
export const ClientItemLabel = styled.span<TClientItemLabelProps>`
  font-size: 12px;
  color: ${props => props.theme.colors.secondaryDark};
`

type TClientItemNameProps = {}
export const ClientItemName = styled.span<TClientItemNameProps>`
  font-weight: 500;
`

type TClientAppointmentStatusProps = { color?: string; reason?: string }
export const ClientAppointmentStatus = styled.div<
  TClientAppointmentStatusProps
>`
  display: flex;
  flex-direction: column;
  color: ${props =>
    props.reason === 'ORG_CANCELED' || props.reason === 'CLIENT_CANCELED'
      ? props.theme.colors.accent1
      : props.color};
  padding: 3px 20px;
  border-radius: 4px;
  margin-left: auto;
  font-size: 10px;
`

type TClientItemNoteProps = {}
export const ClientItemNote = styled.span<TClientItemNoteProps>`
  padding-top: 3px;
  font-size: 12px;
  color: ${props => props.theme.colors.secondaryDark};
`

type TClientItemHourProps = {}
export const ClientItemHour = styled.span<TClientItemHourProps>`
  font-size: 12px;
  color: ${props => props.theme.colors.secondaryDark};
`
type TClientNote = {}
export const ClientNote = styled.span<TClientNote>`
  color: ${props => props.theme.colors.accent5};
`
