import React from 'react'
import Button from '../Button/Button'
import { useSdk } from '../../sdk'
import { FieldContainer } from 'components/Form/FieldContainer'
import { FieldColumn } from 'views/Settings/styles'
import { device } from '../.././constants'
import styled from 'styled-components'
import { useACL } from 'src/sdk/acl'

type TProps = {
  title: string
  text: string
  notFormField?: boolean
  handleClick: () => any
  fullWidthButton?: boolean
  isAvailableOnAdvancedPlan?: boolean
}
export const PlanUpgradeField = (props: TProps) => {
  const { t } = useSdk()
  const { hasPermission } = useACL()
  return (
    <FieldColumn>
      <FieldContainer label={props.title} helpText={props.text}>
        <PlanUpgradeFieldColumn>
          {props.notFormField ? (
            <PlanUpgradeFieldText>
              {t('translation.App.online-booking-disabled')}
            </PlanUpgradeFieldText>
          ) : null}

          <Button
            type="button"
            label={
              props.isAvailableOnAdvancedPlan
                ? t('translation.UpgradePage.button-label-business-plan')
                : t('translation.UpgradePage.button-label')
            }
            buttonType="primaryOutline"
            onClick={
              hasPermission('ACCESS_BILLING') ? props.handleClick : () => ({})
            }
            disabled={!hasPermission('ACCESS_BILLING')}
            size={'medium'}
            fullWidth={props.fullWidthButton}
          />
        </PlanUpgradeFieldColumn>
      </FieldContainer>
    </FieldColumn>
  )
}

const PlanUpgradeFieldText = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  font-style: italic;
  color: ${props => props.theme.colors.secondaryDark};
`

const PlanUpgradeFieldColumn = styled.div<any>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: baseline;
  @media ${device.tablet} {
    width: 100%;
    :not(:last-child) {
      padding-right: 0;
    }
  }
`
