import React, { useState } from 'react'
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js'

import { Button } from 'components'
import { formatPriceNumberWithDelimiter, getPlanName } from 'src/helpers'
import Avatar from 'components/Avatar/Avatar'
import { Checkbox } from 'components/Checkbox/Checkbox'
import {
  ModalContainer,
  UserAvatarName,
  TotalLabel,
  TotalPrice,
  TotalInterval,
  CardFields,
  BottomSection,
  BillingTextSection,
  BillingText,
  ModalRight,
  ModalRightRow,
  ModalRightTitle,
  CreditCardFormStyled,
} from './CreditCardForm'
import {
  ModalRightWarning,
  PlanTitle,
} from 'views/Settings/Billing/Modals/OrgDataForm'
import { Step1PDV } from './style'
import { icons } from 'components'
import { useSdk } from 'sdk'
import { ModalRightWarning as ErrorWrapper } from './ConfirmationForm'
import {
  GetOrganizationByIdQuery,
  PaymentPlan,
  SmsPackage,
} from 'src/state/graphql'

type TStripeCheckoutFormProps = {
  selectedPlan: PaymentPlan | null
  setIsBusy: (value: boolean) => void
  cycle: string
  org?: GetOrganizationByIdQuery['organization']['get'] | null
  isUpdatePayment?: boolean
  shouldShowPlanQuantity?: boolean
}
export const StripeCheckoutForm = (props: TStripeCheckoutFormProps) => {
  const {
    org,
    selectedPlan,
    cycle,
    isUpdatePayment,
    setIsBusy,
    shouldShowPlanQuantity,
  } = props

  const stripe = useStripe()
  const elements = useElements()
  const { t } = useSdk()
  const [paymentError, setPaymentError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [isTermsConfirmed, setIsTermsConfirmed] = useState(true)

  const handleTermsChange = () => {
    setIsTermsConfirmed(!isTermsConfirmed)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setPaymentError('')
    setIsBusy(true)
    setIsLoading(true)

    const { error }: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url:
          window.location.href +
          `?paymentItem=${isUpdatePayment ? 'cardChange' : 'plan'}`,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setPaymentError(error.message)
    } else {
      setPaymentError('An unexpected error occured.')
    }

    setIsLoading(false)
    setIsBusy(false)
  }

  return (
    <CreditCardFormStyled>
      <form id="payment-form" onSubmit={handleSubmit}>
        <ModalContainer data-cy={'ccForm'}>
          <CardFields isUpdatePayment={isUpdatePayment}>
            <PaymentElement id="payment-element" />
            <AddressElement
              options={{ mode: 'billing' }}
              id="address-element"
            />
            <Button
              buttonType={'primary'}
              type={'submit'}
              style={{ marginTop: '10px' }}
              id="submit"
              name={'saveChanges'}
              fullWidth
              disabled={isLoading || !stripe || !elements || !isTermsConfirmed}
              label={
                isUpdatePayment
                  ? t('translation.CreditCardForm.saveCard')
                  : t('translation.CreditCardForm.create-subsciption')
              }
            />

            {paymentError && (
              <ErrorWrapper
                isDowngrade
                style={{
                  marginTop: 20,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <span>{paymentError}</span>
              </ErrorWrapper>
            )}
          </CardFields>

          {isUpdatePayment ? (
            <ModalRight>
              <PlanTitle>
                {t('translation.CreditCardForm.cardChange')}
              </PlanTitle>
              <ModalRightWarning hasBottomMargin>
                <span>{t('translation.CreditCardForm.cardChangeHint')}</span>
              </ModalRightWarning>
            </ModalRight>
          ) : (
            <ModalRight>
              <PlanTitle>
                {shouldShowPlanQuantity
                  ? `${org?.subscriptionQuantity} x `
                  : ''}
                {getPlanName(selectedPlan?.id)}
                {' Plan'}
              </PlanTitle>
              <ModalRightRow>
                <icons.CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
                <span>{t('translation.CreditCardForm.mjesecna-naplata')}</span>
              </ModalRightRow>
              <ModalRightRow>
                <icons.CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
                <span>{t('translation.CreditCardForm.firstPayment')} </span>
              </ModalRightRow>
              <ModalRightRow>
                <icons.CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
                <span>{t('translation.CreditCardForm.cancelAnytime')}</span>
              </ModalRightRow>
              <ModalRightRow>
                <icons.CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
                <span>{t('translation.CreditCardForm.r1-available')}</span>
              </ModalRightRow>

              <ModalRightTitle style={{ flexDirection: 'column' }}>
                <TotalLabel style={{ width: '100%', paddingLeft: 10 }}>
                  {t('translation.CreditCardForm.ukupno')}
                </TotalLabel>
                <div>
                  <TotalPrice>
                    {cycle === '1 month'
                      ? formatPriceNumberWithDelimiter(
                          parseFloat(
                            (
                              (selectedPlan?.priceMonthly || 0) *
                              (org?.subscriptionQuantity || 0) *
                              (org?.country?.countryCode === 'HR' ? 0.8 : 1)
                            )?.toFixed(2)
                          )
                        ) +
                        ' ' +
                        org?.stripeCurrencyId
                      : formatPriceNumberWithDelimiter(
                          parseFloat(
                            (
                              (selectedPlan?.priceYearly || 0) *
                              (org?.subscriptionQuantity || 0) *
                              (org?.country?.countryCode === 'HR' ? 0.8 : 1)
                            )?.toFixed(2)
                          )
                        ) +
                        ' ' +
                        org?.stripeCurrencyId}
                  </TotalPrice>
                  {org?.country?.countryCode === 'HR' ? (
                    <Step1PDV>{t('translation.PlansForm.pdv')}</Step1PDV>
                  ) : null}

                  <TotalInterval>
                    {cycle === '1 month'
                      ? t('translation.helpers.mjesecno')
                      : t('translation.helpers.godisnje')}
                  </TotalInterval>
                </div>
              </ModalRightTitle>
              <Checkbox
                html={`<div style="display: flex; width: 100%; color: #333;">${t(
                  'translation.LocationBooking.label-terms'
                )} <a style="margin-left: 5px; color: #6a4ee1; text-decoration: underline;" href="https://zoyya.com/partner/terms-of-use"  
target="_blank"  rel="noopener noreferrer">${t(
                  'translation.LocationBooking.label-termUrl'
                )}</a></div>`}
                checked={isTermsConfirmed}
                handleChange={handleTermsChange}
              />
            </ModalRight>
          )}
        </ModalContainer>
      </form>
    </CreditCardFormStyled>
  )
}

type TStripeSmsCheckoutFormProps = {
  selectedPackage: SmsPackage
  setIsBusy: (value: boolean) => void
  org?: GetOrganizationByIdQuery['organization']['get'] | null
}

export const StripeSmsCheckoutForm = ({
  org,
  setIsBusy,
  selectedPackage,
}: TStripeSmsCheckoutFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useSdk()
  const [paymentError, setPaymentError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const isCroatianOrg = org?.country?.countryCode === 'HR'
  const [isTermsConfirmed, setIsTermsConfirmed] = useState(true)

  const handleTermsChange = () => {
    setIsTermsConfirmed(!isTermsConfirmed)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setPaymentError('')
    setIsBusy(true)
    setIsLoading(true)

    const { error }: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href + '?paymentItem=sms',
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setPaymentError(error.message)
    } else {
      setPaymentError('An unexpected error occured.')
    }

    setIsLoading(false)
    setIsBusy(false)
  }

  return (
    <CreditCardFormStyled>
      <form id="payment-form" onSubmit={handleSubmit}>
        <ModalContainer data-cy={'ccForm'}>
          <CardFields>
            <PaymentElement id="payment-element" />
            <AddressElement
              options={{ mode: 'billing' }}
              id="address-element"
            />
            <Button
              buttonType={'primary'}
              type={'submit'}
              style={{ marginTop: '10px' }}
              id="submit"
              name={'saveChanges'}
              fullWidth
              disabled={isLoading || !stripe || !elements || !isTermsConfirmed}
              label={t('translation.CreditCardForm.purchase')}
            />

            {paymentError && (
              <ErrorWrapper
                isDowngrade
                style={{
                  marginTop: 20,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              >
                <span>{paymentError}</span>
              </ErrorWrapper>
            )}
          </CardFields>

          <ModalRight>
            <PlanTitle>
              {t('translation.CreditCardForm.packageName', {
                packageName: selectedPackage?.packageName,
              })}
            </PlanTitle>
            <ModalRightRow>
              <icons.CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
              <span>
                {selectedPackage?.units}{' '}
                {t(
                  'translation.BillingContainer.units',
                  selectedPackage?.units ? { plural: 'S' } : null
                )}
              </span>
            </ModalRightRow>

            <ModalRightTitle>
              <TotalLabel>{t('translation.CreditCardForm.ukupno')}</TotalLabel>
              <TotalPrice sms>
                {formatPriceNumberWithDelimiter(
                  parseFloat(
                    (
                      selectedPackage?.price * (isCroatianOrg ? 0.8 : 1)
                    ).toFixed(2)
                  )
                ) +
                  ' ' +
                  org?.stripeCurrencyId}
                {isCroatianOrg ? (
                  <Step1PDV>{t('translation.PlansForm.pdv')}</Step1PDV>
                ) : null}
              </TotalPrice>
            </ModalRightTitle>

            <Checkbox
              html={`<div style="display: flex; width: 100%; color: #333;">${t(
                'translation.LocationBooking.label-terms'
              )} <a style="margin-left: 5px; color: #6a4ee1; text-decoration: underline;" href="https://zoyya.com/partner/terms-of-use"  
target="_blank"  rel="noopener noreferrer">${t(
                'translation.LocationBooking.label-termUrl'
              )}</a></div>`}
              checked={isTermsConfirmed}
              handleChange={handleTermsChange}
            />
          </ModalRight>
        </ModalContainer>
      </form>
    </CreditCardFormStyled>
  )
}
