import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSdk } from 'sdk'
import { icons } from 'components'

const NoInternetBanner = styled.div`
  position: fixed;
  z-index: 9999999999999999;
  top: 0;
  left: 50%;
  width: max-content;
  height: 50px;
  background: ${props => props.theme.colors.accent1};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 0px 10px 0px #9c9c9c;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  transform: translateX(-50%);
`

export const OnlineChecker = ({ children }) => {
  const [isNavigatorOnline, setIsNavigatorOnline] = useState(true)
  const [shouldShowBanner, setShouldShowBanner] = useState(false)
  const { t } = useSdk()

  const handleConnectionChange = useCallback(() => {
    if (navigator.onLine) {
      setIsNavigatorOnline(true)
      window.location.reload()
    } else {
      setIsNavigatorOnline(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)

    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
  }, [])

  const text = t('translation.OnlineChecker.mobile-text')


  return (
    <>
      {!isNavigatorOnline ? (
        <NoInternetBanner>
          <icons.WifiWarning color="white" style={{ marginRight: 5 }} />
          {text}
        </NoInternetBanner>
      ) : null}
      {children}
    </>
  )
}
