import React, { useEffect, useState, useRef, useContext } from 'react'
import styled from 'styled-components'
import { device } from '../../constants'
import Button from 'components/Button/Button'
import { useSdk } from 'sdk'
import { locationGetGql, searchServiceTypesGql } from 'state/gql'
import { getLocationId } from 'src/helpers'
import { useQuery } from '@apollo/client'
import { chain } from 'lodash'
import { useGetMe } from 'state/queries'
import { useLocationSetBookingReminderMutation } from 'state/graphql'
import { MobileContext, WizardContext } from 'src/Context'
import { Overlay } from 'components/Overlay/Overlay'
import { icons } from 'components/Icon'

export const GetStartedPopup = props => {
  const { t, navigateTo } = useSdk()
  const [showInfo, setShowInfo] = useState(false)
  const [steps, setSteps] = useState([])
  const [incompleteSteps, setIncompleteSteps] = useState(0)
  const { isOpen, setIsOpen } = useContext(WizardContext)

  const [
    locationSetBookingReminderMutation,
  ] = useLocationSetBookingReminderMutation()
  const handleBookingReminderButton = async () => {
    await locationSetBookingReminderMutation({
      variables: {
        input: {
          showBookingReminder: false,
        },
      },
      refetchQueries: [
        {
          query: locationGetGql,
          variables: { id: getLocationId() },
          skip: !getLocationId(),
        },
      ],
    })
  }

  const userData = useGetMe()
  const user = userData?.data?.user?.me

  const { data: locationData, loading: locationLoading } = useQuery(
    locationGetGql,
    {
      variables: { id: getLocationId() },
      skip: !getLocationId(),
    }
  )

  const initialValues = {
    ...locationData?.location?.get,
    termsAndConditions: false,
  }
  const { data: serviceTypesData } = useQuery(searchServiceTypesGql, {
    variables: { locationId: initialValues?.id },
    skip: !initialValues?.id,
  })
  const serviceTypes = serviceTypesData?.serviceType?.search || []
  const services = chain(serviceTypes)
    .map('services')
    .flatten()
    .value()
  const servicesWithOnlineBooking = chain(serviceTypes)
    .map('services')
    .flatten()
    .filter({ bookingAllowed: true })
    .value()

  const hasServices = serviceTypesData?.serviceType?.search
    ? services?.length > 0
    : false
  const hasServiceWithOnlineBooking = servicesWithOnlineBooking?.length > 0
  const location = locationData?.location?.get

  let numberOfIncompleteSteps = []
  let result

  useEffect(() => {
    if (!serviceTypesData?.serviceType?.search) return
    const oldSteps = [
      {
        stepNum: 1,
        title: t('translation.GetStartedPopup.stepOne-title'),
        description: t('translation.GetStartedPopup.stepOne-description'),
        buttonClickAction: () => {
          navigateTo.editLocation()
          setIsOpen(false)
        },
        buttonLabel: t('translation.GetStartedPopup.stepOne-buttonLabel'),
        buttonName: 'goToBranchData',
        isCompleted: getStepCompletionStatus({ stepNum: 1, location }),
      },
      {
        stepNum: 2,
        title: t('translation.GetStartedPopup.stepTwo-title'),
        description: t('translation.GetStartedPopup.stepTwo-description'),
        buttonClickAction: () => {
          navigateTo.newService({ typeId: serviceTypes[0]?.id })
          setIsOpen(false)
        },
        buttonLabel: t('translation.GetStartedPopup.stepTwo-buttonLabel'),
        buttonName: 'createNewService',
        isCompleted: getStepCompletionStatus({ stepNum: 2, location }),
      },
      {
        stepNum: 3,
        title: t('translation.GetStartedPopup.stepThree-title'),
        description: t('translation.GetStartedPopup.stepThree-description'),
        buttonClickAction: () => {
          navigateTo.onlineBooking()
          setIsOpen(false)
        },
        buttonLabel: t('translation.GetStartedPopup.stepThree-buttonLabel'),
        buttonName: 'goToOnlineBookingSettings',
        isCompleted: getStepCompletionStatus({ stepNum: 3, location }),
      },
    ]

    //calculate how many incomplete steps left
    numberOfIncompleteSteps = oldSteps.map(a => a.isCompleted === true)
    function getOccurrence(array, value) {
      return array.filter(v => v === value).length
    }
    result = getOccurrence(numberOfIncompleteSteps, false) // number of incomplete steps
    setIncompleteSteps(result)

    // set first incomplete step visible
    let isAnyStepNotCompleted
    const newSteps = oldSteps.map(step => {
      if (!step.isCompleted && !isAnyStepNotCompleted) {
        isAnyStepNotCompleted = true
        return { ...step, isStepVisible: true }
      } else {
        return { ...step, isStepVisible: false }
      }
    })
    setSteps(newSteps)
  }, [location, hasServices, hasServiceWithOnlineBooking, serviceTypesData])

  const getStepCompletionStatus = ({ stepNum, location }) => {
    switch (stepNum) {
      case 1:
        return !location?.imageUrl.includes('/avatar/null') &&
          location?.description &&
          location?.address &&
          location?.city
          ? true
          : false
      case 2:
        return (hasServices && hasServiceWithOnlineBooking) ||
          (hasServices && location?.showBookingReminder === false) ||
          (hasServices && location.onlineBooking_listOnZoyya)
          ? true
          : false
      case 3:
        return location?.onlineBooking_listOnZoyya === true ||
          location?.showBookingReminder === false
          ? true
          : false
      default:
        return false
    }
  }
  const containerRef = useRef()
  const isMobile = useContext(MobileContext)

  return (
    <>
      {incompleteSteps !== 0 && !locationLoading ? (
        <>
          {isMobile && isOpen ? (
            <Overlay setIsOpen={() => setIsOpen(false)} />
          ) : null}
          <Container ref={containerRef}>
            {isOpen ? (
              <Window>
                <Header>
                  <Greeting>
                    {t('translation.GetStartedPopup.greeting')}{' '}
                    {user?.firstName}
                  </Greeting>
                  <RotatedHand />
                  <CloseButton onClick={() => setIsOpen(false)}>
                    <icons.Cross
                      size="extraSmall"
                      stroke={3}
                      style={{ strokeWidth: 4 }}
                    />
                  </CloseButton>
                </Header>
                <Main>
                  <p>{t('translation.GetStartedPopup.subtitle')}</p>
                  {steps.map((step, idx) => {
                    return (
                      <GuideStep key={idx}>
                        <GuideStepHeader
                          onClick={() => {
                            // if we click on one tab, close other opened tabs
                            // if we click on opened tab, close it
                            const newSteps = steps.map(x =>
                              x.stepNum === step.stepNum
                                ? x.isStepVisible
                                  ? { ...x, isStepVisible: false }
                                  : { ...x, isStepVisible: true }
                                : { ...x, isStepVisible: false }
                            )
                            setSteps(newSteps)
                            setShowInfo(showInfo ? showInfo : !showInfo)
                          }}
                          isCompleted={step.isCompleted}
                        >
                          {step.isCompleted ? (
                            <CheckBox>
                              <icons.CheckFilled
                                size={'extraSmall'}
                                color={'#fff'}
                              />
                            </CheckBox>
                          ) : null}
                          <GuideStepTitle isCompleted={step.isCompleted}>
                            {step.title}
                          </GuideStepTitle>
                        </GuideStepHeader>
                        {step.isStepVisible && (
                          <GuideStepMain>
                            <GuideStepDescription>
                              {step.description}
                            </GuideStepDescription>
                            <div style={{ display: 'flex' }}>
                              <Button
                                buttonType={'primary'}
                                label={step.buttonLabel}
                                size={'small'}
                                onClick={step.buttonClickAction}
                                name={step.buttonName}
                              />

                              {(idx === 2 && incompleteSteps === 1) ||
                              (idx === 2 &&
                                incompleteSteps === 2 &&
                                hasServices) ? (
                                <Button
                                  buttonType={'text'}
                                  label={t(
                                    'translation.GetStartedPopup.label-no-online-booking'
                                  )}
                                  size={'small'}
                                  style={{ marginLeft: '5px' }}
                                  name={'noOnlineBooking'}
                                  onClick={handleBookingReminderButton}
                                />
                              ) : null}
                            </div>
                          </GuideStepMain>
                        )}
                      </GuideStep>
                    )
                  })}
                </Main>
              </Window>
            ) : null}
          </Container>
          <AvatarContainer>
            <Avatar
              src={'/assets/ivana-yellow-small.png'}
              onClick={() => setIsOpen(!isOpen)}
            />
            <StepsLeft>{incompleteSteps}</StepsLeft>
          </AvatarContainer>
        </>
      ) : null}
    </>
  )
}

const Container = styled.div`
  position: absolute;
  bottom: 105px;
  left: 75px;
  right: auto;
  z-index: 99999999;
  @media ${device.tablet} {
    bottom: 145px;
    right: 15px;
    left: auto;
  }
  @media ${device.mobileS} {
    right: 5px;
    left: auto;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media all and (display-mode: standalone) {
      /* CSS specific to standalone applications */
      bottom: 155px;
    }
  }
`
const Window = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  height: auto;
  background-color: ${props => props.theme.colors.light};
  border-radius: 10px;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 20px 15px;
  animation: window 0.5s ease-out;
  @keyframes window {
    0% {
      transform: scale(0.1);
      transform-origin: bottom left;
    }
    100% {
      transform: scale(1);
    }
  }

  @media ${device.tablet} {
    @keyframes window {
      0% {
        transform: scale(0.1);
        transform-origin: bottom right;
      }
      100% {
        transform: scale(1);
      }
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
const Greeting = styled.h1`
  font-weight: 500;
  font-size: 20px;
  margin-right: 5px;
`
const RotatedHand = styled(icons.Hand)`
  transform: rotate(-30deg);
  position: relative;
  bottom: 3px;
  stroke: #af8e34;
  fill: #ffcf4b;
  stroke-width: 0.5;
`
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 7px;
  padding: 10px;
`

const Main = styled.div`
  p {
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 300;
  }
`

const GuideStep = styled.div`
  box-shadow: 0px 1px 3px 0px #00000029;
  border-radius: 5px;
  padding: 10px;
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`
const GuideStepHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.isCompleted ? '#B8B8B8' : '#3D3D3D')};
  text-decoration: ${props => (props.isCompleted ? 'line-through' : 'none')};
  cursor: ${props => (props.isCompleted ? 'default' : 'pointer')};
`

const CheckBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #6fcf97;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
`

const GuideStepTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
`

const GuideStepMain = styled.div`
  padding: 10px 0 5px;
`

const GuideStepDescription = styled.p``

const StepsLeft = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: ${props => props.theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 32px;
  position: absolute;
  bottom: 40px;
  animation: dot 0.5s ease-out, bounce 2s ease 2s;
  @keyframes dot {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-8px);
    }
    50% {
      transform: scale(1, 1) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
`

const AvatarContainer = styled.button`
  height: 50px;
  text-align: right;
  position: absolute;
  bottom: 35px;
  left: 75px;
  right: auto;
  outline: none;
  border: none;
  z-index: 9;
  background-color: transparent;
  border-radius: 50px;
  box-shadow: 0px 1px 8px 0px #00000040;
  transition: 0.3s;
  animation: crescendo 0.5s ease-out;
  @media ${device.tablet} {
    bottom: 75px;
    right: 15px;
    left: auto;
  }
  @media ${device.mobileS} {
    right: 5px;
    left: auto;
  }

  @keyframes crescendo {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(1);
    }
  }
  :hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media all and (display-mode: standalone) {
      /* CSS specific to standalone applications */
      bottom: 85px;
    }
  }
`

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  cursor: pointer;
`

export const BannerCloseButton = styled.div`
  position: absolute;
  right: 8px;
  @media ${device.tablet} {
    top: 3px;
    right: 0px;
    button {
      background-color: transparent;
      border: none;
      color: white;
      span {
        font-size: 30px !important;
      }
    }
  }
`
