import React from 'react'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { useCalendarStore } from 'src/state/local/calendarStore'
import { useTranslation } from 'react-i18next'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { Button } from 'components'
import { useSdk } from 'src/sdk'
export const AppointmentHotelFooter = (props: {
  onSaveClick: () => void
  onCloseClick: () => void
  isSaveDisabled?: boolean
  isSaveLoading?: boolean
  saveButtonLabel?: string
  disabledMessage?: string
  hideCloseButton?: boolean
  showIssueReceiptButton?: boolean
  issueReceiptButtonProps: {
    onClick: () => void
    label: string
    isLoading?: boolean
  }
}) => {
  const { issueReceiptButtonProps } = props

  const { t } = useTranslation()

  return (
    <div className="bg-white shadow-[0_0_10px_rgba(0,0,0,0.1)] lg:shadow-none justify-center p-[15px] min-h-[85px] border-t lg:bg-zoyya-lightBackground border-zoyya-outline flex flex-col">
      <div className="flex lg:flex-row gap-2 flex-col items-center">
        {props.disabledMessage ? (
          <div className="flex-1 text-sm text-zoyya-accent5 flex items-center gap-2">
            <WarningTriangle className="shrink-0" size="small" />{' '}
            <p>{props.disabledMessage || ''}</p>
          </div>
        ) : null}

        {/* desktop buttons */}
        <div
          className={`lg:flex flex-row gap-2 hidden w-full ${
            props.showIssueReceiptButton ? 'justify-between' : 'justify-end'
          }`}
        >
          {props.showIssueReceiptButton ? (
            <div className="self-start">
              <Button
                buttonType={'primary'}
                type={'button'}
                name={'issueReceipt'}
                label={issueReceiptButtonProps?.label}
                whiteLoader
                isLoading={issueReceiptButtonProps?.isLoading}
                footerButton
                onClick={issueReceiptButtonProps.onClick}
                style={{
                  marginRight: 'auto',
                  paddingRight: issueReceiptButtonProps.isLoading
                    ? '60px'
                    : undefined,
                }}
              />
            </div>
          ) : null}
          <div className="flex gap-2">
            {!props.hideCloseButton ? (
              <ButtonTW
                label={t('translation.AppointmentWizard.button-close')}
                onClick={props.onCloseClick}
                variant="text"
              />
            ) : null}

            <ButtonTW
              label={
                props.saveButtonLabel ||
                t('translation.AppointmentModal.button-save')
              }
              disabled={props.isSaveDisabled}
              onClick={props.onSaveClick}
              isLoading={props.isSaveLoading}
            />
          </div>
        </div>
        {/* mobile buttons */}
        <div className="flex lg:hidden flex-row gap-[12px] w-full ">
          {!props.hideCloseButton ? (
            <ButtonTW
              label={t('translation.AppointmentWizard.button-close')}
              onClick={props.onCloseClick}
              variant="secondary"
              size="large"
              className="flex-1"
            />
          ) : null}
          <ButtonTW
            label={
              props.saveButtonLabel ||
              t('translation.AppointmentModal.button-save')
            }
            disabled={props.isSaveDisabled}
            onClick={props.onSaveClick}
            isLoading={props.isSaveLoading}
            size="large"
            className="flex-1"
          />
        </div>
      </div>
    </div>
  )
}
