import React, { useCallback } from 'react'
import { useField, useFormikContext, FormikContextType } from 'formik'
import { merge } from 'lodash'
import { FieldContainer } from 'components/Form/FieldContainer'
import { useFormContext } from 'components/Form/Form'
import { FormatOptionLabelMeta } from 'react-select'
import { Select } from 'components/Select2/Select'

type TFormFieldSelectProps = {
  name: string
  options: any[]
  menuIsOpen?: boolean
  isDisabled?: boolean
  /**
   * @deprecated  Use isDisabled instead.
   */
  disabled?: boolean
  allowSelectAll?: boolean
  isAsync?: boolean
  noOptionsMessage?: (obj: { inputValue: string }) => string | null
  label?: string
  isMulti?: boolean
  loadOptions?: (inputValue?: any, callback?: () => any) => any
  closeMenuOnSelect?: boolean
  styles?: any
  components?: any
  formatOptionLabel?: (
    option: any,
    labelMeta: FormatOptionLabelMeta<any, any>
  ) => React.ReactNode
  getOptionLabel?: (option) => any
  getOptionValue?: (option) => any
  formatValue?: (option) => any
  onValueChanged?: (
    newValue: any,
    values: any,
    formik: FormikContextType<any>
  ) => any
  parseValue?: (value) => any
  placeholder?: string
  controlShouldRenderValue?: boolean
  hideSelectedOptions?: boolean
  isSearchable?: boolean
  helpText?: string
  helpLink?: string
  onMenuOpen?: any
  onMenuClose?: any
  isClearable?: boolean
  filterOption?: any
  openMenuOnFocus?: boolean
  openMenuOnClick?: boolean
  customComponent?: any
  hideChevron?: boolean
  mobileStyles?: any
  isServiceSelect?: boolean
  customChange?: any
  isEmployeeSelect?: boolean
  hasPlusMinusButtons?: boolean
  minusBtnDisabled?: boolean
  minusBtnOnClick?: any
  plusBtnDisabled?: boolean
  plusBtnOnClick?: any
  selectMinWidth?: string
  hideSeparator?: boolean
}

export const FormFieldSelect = (props: TFormFieldSelectProps) => {
  const [field, meta] = useField(props)
  const fmk = useFormikContext()
  const formContext = useFormContext()
  const getValueFromField = () => {
    return props.parseValue ? props.parseValue(field.value) : field.value
  }
  const handleChange = (value, meta) => {
    let selected = value
    const newValue = props.formatValue ? props.formatValue(selected) : selected

    formContext?.valueChange(field, field.name, newValue)
    setTimeout(() => {
      const values = Object.assign({}, fmk.values)
      let mergedValues = merge({}, values, { [field.name]: newValue })

      //glitch with validation if the fmk.setFieldValue is called before the change handle completes
      //so we call the valueChanged inside timeout
      props.onValueChanged?.(newValue, mergedValues, fmk)
      fmk.validateForm()
    }, 0)
    props.customChange && props.customChange(newValue)
  }

  return (
    <FieldContainer
      label={props.label}
      error={meta.error}
      helpText={props.helpText}
      helpLink={props.helpLink}
      warningPosition
    >
      <Select
        error={meta.error}
        styles={props.styles}
        isClearable={props.isClearable}
        components={props.components}
        isMulti={props.isMulti}
        loadOptions={props.loadOptions}
        isAsync={props.isAsync}
        allowSelectAll={props.allowSelectAll}
        value={getValueFromField()}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        onBlur={field.onBlur}
        onChange={handleChange}
        data-cy={`input_${props.name}`}
        formatOptionLabel={props.formatOptionLabel}
        isDisabled={props.isDisabled || props.disabled}
        closeMenuOnSelect={props.closeMenuOnSelect}
        noOptionsMessage={props.noOptionsMessage}
        name={props.name}
        isOptionSelected={o => false}
        options={props.options}
        menuPlacement="auto"
        menuIsOpen={props.menuIsOpen}
        placeholder={props.placeholder}
        controlShouldRenderValue={props.controlShouldRenderValue}
        hideSelectedOptions={props.hideSelectedOptions}
        isSearchable={props.isSearchable}
        onMenuOpen={props.onMenuOpen}
        isOptionDisabled={option => option.disabled}
        onMenuClose={props.onMenuClose}
        filterOption={props.filterOption}
        openMenuOnFocus={props.openMenuOnFocus}
        openMenuOnClick={props.openMenuOnClick}
        customComponent={props.customComponent}
        hideChevron={props.hideChevron}
        hideSeparator={props.hideSeparator}
        mobileStyles={props.mobileStyles}
        isServiceSelect={props.isServiceSelect}
        isEmployeeSelect={props.isEmployeeSelect}
        hasPlusMinusButtons={props.hasPlusMinusButtons}
        minusBtnDisabled={props.minusBtnDisabled}
        minusBtnOnClick={props.minusBtnOnClick}
        plusBtnDisabled={props.plusBtnDisabled}
        plusBtnOnClick={props.plusBtnOnClick}
        selectMinWidth={props.selectMinWidth}
      />
    </FieldContainer>
  )
}
export const FormFieldColorPicker = props => {
  const { extractValue } = props
  const [field, meta] = useField(props)
  const formContext = useFormContext()
  const defaultValueExtractor = useCallback(item => item?.id, [])
  const valueExtractor = extractValue ? extractValue : defaultValueExtractor
  const handleChange = item => {
    const value = valueExtractor(item)
    formContext?.valueChange(field, field.name, value)
  }

  return props.label ? (
    <FieldContainer
      label={props.label}
      error={meta.error}
      helpText={props.helpText}
      helpLink={props.helpLink}
    >
      <Select
        name={props.name}
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        onChange={handleChange}
        onBlur={field.onBlur}
        styles={props.styles}
        data-cy={`input_${props.name}`}
        value={{ color: field.value }}
        openMenuOnFocus={props.openMenuOnFocus}
        openMenuOnClick={props.openMenuOnClick}
        formatOptionLabel={e => (
          <div
            data-cy={'mobileSelect_value'}
            style={{
              background: e.color,
              width: '100%',
              height: '100%',
              padding: '4px',
              color: 'white',
            }}
          >
            {e.color}
          </div>
        )}
        options={props.options}
        error={meta.error}
        isSearchable={props.isSearchable}
      />
    </FieldContainer>
  ) : (
    <Select
      name={props.name}
      isDisabled={props.isDisabled}
      isMulti={props.isMulti}
      onChange={handleChange}
      onBlur={field.onBlur}
      styles={props.styles}
      customComponent={props.customComponent}
      value={{ color: field.value }}
      formatOptionLabel={e => (
        <div
          data-cy={'mobileSelect_value'}
          style={{
            background: e.color,
            width: '100%',
            height: '100%',
            padding: '4px',
            color: 'white',
          }}
        >
          {e.color}
        </div>
      )}
      options={props.options}
      error={meta.error}
      isSearchable={props.isSearchable}
    />
  )
}

const defaultGetOptionLabel = o => o.name || o.title || o?.toString()
const defaultGetOptionValue = o => o.id

FormFieldSelect.defaultProps = {
  noOptionsMessage: () => 'No data',
  getOptionLabel: defaultGetOptionLabel,
  getOptionValue: defaultGetOptionValue,
  closeMenuOnSelect: true,
  isSearchable: true,
}
