import React, { useCallback, useContext } from 'react'
import { getHoursInDay, themeAccentColors } from 'src/helpers'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { useGetLocationLoading, useGetServiceTypes } from 'state/queries'
import { useProfessionAllQuery } from 'state/graphql'
import { useParams } from 'react-router'
import {
  Button,
  DeleteButton,
  FormField,
  FormContentContainer,
} from 'components'
import { FormFooterButtonsContainer } from 'components'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { getServiceHoursAndMinutes, serialize } from 'src/helpers'
import { MobileContext } from 'src/Context'
import { PlanUpgradeField } from 'components/PlanUpgradeField/PlanUpgradeField'
import { CheckMarkIcon } from 'components/Icon/Icons/Checkmark'
import { SelectOptionLabel, SelectRow } from 'views/AppointmentModal/styles'
import { useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { EurExchangeRate } from '../../../../constants'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import { LoaderTW } from 'src/sdk/tw/Loader'
import { useLocation } from 'react-router-dom'

type ServiceFormProps = {
  isNew?: boolean
  onDelete?: any
  hasOnlineBooking?: boolean
  setOpenBillingModal?: any
  form?: any
  isAppointment?: boolean
  onGoBack?: any
  setShowAddServiceForm?: (boolean) => any
}
export const ServiceForm = (props: ServiceFormProps) => {
  const {
    isNew,
    onDelete,
    hasOnlineBooking,
    setOpenBillingModal,
    form,
    isAppointment,
    onGoBack,
    setShowAddServiceForm,
  } = props
  const { t, navigateTo } = useSdk()
  const { pathname } = useLocation<any>()
  const { typeId, locationId, orgId } = useParams<any>()
  const { data: serviceTypes, loading: serviceLoading } =
    useGetServiceTypes(locationId)
  const loadOrg = useQuery(getOrganizationByIdGql, {
    variables: { id: orgId },
    fetchPolicy: 'cache-first',
  })
  const { data: organizationData } = loadOrg
  const org = organizationData?.organization?.get

  const { data: professionsData, loading: loadingProfessions } =
    useProfessionAllQuery()

  const professionsAll = professionsData?.profession?.all
  const professions = professionsAll?.filter(x => x?.kind === 'USER')
  const resourceTypes = professionsAll?.filter(x => x?.kind === 'RESOURCE')

  useHeader({
    content: isAppointment
      ? null
      : t('translation.ServiceEditorForm.label-service'),
    onGoBack: navigateTo.servicesList,
  })
  const bookingAllowed = [
    {
      id: true,
      title: t('translation.ServiceEditorForm.label-online-booking-enabled'),
    },
    {
      id: false,
      title: t('translation.ServiceEditorForm.label-online-booking-disabled'),
    },
  ]

  const bookingConfirmation = [
    {
      id: true,
      title: t('translation.ServiceEditorForm.requiresConfirmation'),
    },
    {
      id: false,
      title: t('translation.ServiceEditorForm.doesntRequireConfirmation'),
    },
  ]

  const viewLocationData = useGetLocationLoading(locationId)
  const location = viewLocationData.data?.location?.get

  const durationOptions = getHoursInDay(location?.timeSlotDuration)

  const getServiceHOurs = useCallback(val => {
    return typeof val !== 'string' ? getServiceHoursAndMinutes(val) : val
  }, [])
  const isMobile = useContext(MobileContext)

  const MobileOption = props => {
    const isSelected = props.selectProps.value?.id === props.data?.id
    return (
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>

        <SelectOptionLabel>
          {props.data.title || props.data?.name}
        </SelectOptionLabel>
      </SelectRow>
    )
  }
  const selectComponents = {
    MobileOption,
  }

  if (serviceLoading || loadingProfessions) return <LoaderTW isComponent />

  return (
    <FormContentContainer style={{ paddingBottom: 0 }}>
      {isAppointment ? (
        <div
          className="flex items-center bg-zoyya-lightBackground h-[60px] p-6 -mt-3 lg:-m-6"
          style={{ marginBottom: 0 }}
        >
          <ArrowLeft onClick={onGoBack} className="mr-3 !hidden lg:!block" />
          <div className="text-[22px] cursor-pointer flex items-center">
            {t('translation.AppointmentModal.title-newService')}
          </div>
        </div>
      ) : null}
      {isAppointment ? (
        <div className="overflow-auto px-4 lg:px-1 pt-4 no-scrollbar">
          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-name')}
                name="name"
                autoFocus
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Select
                label={t('translation.ServiceEditorForm.label-service-type')}
                name="type"
                options={serviceTypes}
                parseValue={val => {
                  return serviceTypes?.find(e => e.id === val?.id)
                }}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="profession"
                label={t('translation.ServiceEditorForm.label-profession')}
                options={professions!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            {hasOnlineBooking ? (
              <>
                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.label-booking-settings'
                    )}
                    name="bookingAllowed"
                    parseValue={val => bookingAllowed.find(e => e.id === val)}
                    formatValue={e => e?.id}
                    options={bookingAllowed}
                    helpText={t(
                      'translation.ServiceEditorForm.tooltips-info-service-online-booking'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>

                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.bookingConfirmation'
                    )}
                    name="hasBookingConfirmation"
                    parseValue={val =>
                      bookingConfirmation.find(e => e.id === val)
                    }
                    formatValue={e => e?.id}
                    options={bookingConfirmation}
                    helpText={t(
                      'translation.ServiceEditorForm.bookingConfirmationHint'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>
              </>
            ) : null}
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Select
                label={t('translation.ServiceEditorForm.label-gross-duration')}
                name="durationMinutes"
                parseValue={val =>
                  durationOptions.find(e => e?.id === getServiceHOurs(val))
                }
                formatValue={e => e?.id}
                options={durationOptions}
                components={selectComponents}
                helpText={t(
                  'translation.ServiceEditorForm.label-gross-duration-helpText'
                )}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-net-duration')}
                name="netDurationMinutes"
                helpText={t(
                  'translation.ServiceEditorForm.label-net-duration-helpText'
                )}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-price')}
                name="price"
                shouldDisplayZeroAsValue
                parseValue={value => {
                  return !value ? parseFloat('0') : parseFloat(value)
                }}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.currency')}
                name="currencyId"
                disabled
                helpText={t('translation.ServiceEditorForm.tooltip-currency')}
              />
            </FieldColumn>
            {org?.vatStatus === 'Y' ? (
              <FieldColumn>
                <FormField.Text
                  label={t('translation.ServiceEditorForm.label-vat')}
                  name="vatRate"
                  shouldDisplayZeroAsValue
                  parseValue={value => {
                    return !value ? parseFloat('0') : parseFloat(value)
                  }}
                />
              </FieldColumn>
            ) : null}
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.ColorPicker
                label={t('translation.ServiceEditorForm.color')}
                name="color"
                options={themeAccentColors}
                helpText={t(
                  'translation.ServiceEditorForm.tooltips-info-service-color'
                )}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="resourceType"
                label={t('translation.ServiceEditorForm.vrsta-resursa')}
                options={resourceTypes!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
                components={selectComponents}
                parseValue={val => resourceTypes?.find(e => e?.id === val?.id)}
              />
            </FieldColumn>
          </FieldRow>

          <FieldRow>
            <FieldColumn>
              <FormField.RichTextEditor
                val={
                  !form.values.description
                    ? '<p></p>'
                    : Array.isArray(form.values.description)
                    ? serialize(form.values.description)
                    : form.values.description
                }
                label={t('translation.ServiceEditorForm.label-description')}
                name="description"
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-description'
                )}
              />
            </FieldColumn>
          </FieldRow>
        </div>
      ) : (
        <div>
          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.ServiceEditorForm.label-name')}
                name="name"
                autoFocus
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                label={t('translation.ServiceEditorForm.label-service-type')}
                name="type"
                options={serviceTypes}
                parseValue={val => {
                  return serviceTypes?.find(e => e.id === val?.id)
                }}
              />
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="profession"
                label={t('translation.ServiceEditorForm.label-profession')}
                options={professions!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            {hasOnlineBooking ? (
              <>
                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.label-booking-settings'
                    )}
                    name="bookingAllowed"
                    parseValue={val => bookingAllowed.find(e => e.id === val)}
                    formatValue={e => e?.id}
                    options={bookingAllowed}
                    helpText={t(
                      'translation.ServiceEditorForm.tooltips-info-service-online-booking'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>

                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.bookingConfirmation'
                    )}
                    name="hasBookingConfirmation"
                    parseValue={val =>
                      bookingConfirmation.find(e => e.id === val)
                    }
                    formatValue={e => e?.id}
                    options={bookingConfirmation}
                    helpText={t(
                      'translation.ServiceEditorForm.bookingConfirmationHint'
                    )}
                    components={selectComponents}
                  />
                </FieldColumn>
              </>
            ) : (
              <PlanUpgradeField
                title={t(
                  'translation.LocationBooking.label-online-booking-select'
                )}
                text={t('translation.App.online-booking-disabled')}
                handleClick={() => setOpenBillingModal(true)}
              />
            )}
            <FieldColumn>
              <FieldRow style={{ width: '100%', marginBottom: 0 }}>
                <FieldColumn>
                  <FormField.Select
                    label={t(
                      'translation.ServiceEditorForm.label-gross-duration'
                    )}
                    name="durationMinutes"
                    parseValue={val =>
                      durationOptions.find(e => e?.id === getServiceHOurs(val))
                    }
                    formatValue={e => e?.id}
                    options={durationOptions}
                    components={selectComponents}
                    helpText={t(
                      'translation.ServiceEditorForm.label-gross-duration-helpText'
                    )}
                  />
                </FieldColumn>
                <FieldColumn>
                  <FormField.Text
                    label={t(
                      'translation.ServiceEditorForm.label-net-duration'
                    )}
                    name="netDurationMinutes"
                    helpText={t(
                      'translation.ServiceEditorForm.label-net-duration-helpText'
                    )}
                  />
                </FieldColumn>
              </FieldRow>
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FieldRow style={{ width: '100%', marginBottom: 0 }}>
                <FieldColumn>
                  <FormField.Text
                    label={t('translation.ServiceEditorForm.label-price')}
                    name="price"
                    shouldDisplayZeroAsValue
                    parseValue={value => {
                      return !value ? parseFloat('0') : parseFloat(value)
                    }}
                  />
                </FieldColumn>
                <FieldColumn>
                  <FormField.Text
                    label={t('translation.ServiceEditorForm.currency')}
                    name="currencyId"
                    disabled
                    helpText={t(
                      'translation.ServiceEditorForm.tooltip-currency'
                    )}
                  />
                </FieldColumn>
              </FieldRow>
            </FieldColumn>
            <FieldColumn>
              <FieldRow style={{ width: '100%', marginBottom: 0 }}>
                {org?.vatStatus === 'Y' ? (
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.ServiceEditorForm.label-vat')}
                      name="vatRate"
                      shouldDisplayZeroAsValue
                      parseValue={value => {
                        return !value ? parseFloat('0') : parseFloat(value)
                      }}
                    />
                  </FieldColumn>
                ) : null}
                <FieldColumn>
                  <FormField.ColorPicker
                    label={t('translation.ServiceEditorForm.color')}
                    name="color"
                    options={themeAccentColors}
                    helpText={t(
                      'translation.ServiceEditorForm.tooltips-info-service-color'
                    )}
                  />
                </FieldColumn>
              </FieldRow>
            </FieldColumn>
            <FieldColumn>
              <FormField.Select
                name="resourceType"
                label={t('translation.ServiceEditorForm.vrsta-resursa')}
                options={resourceTypes!}
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-profession'
                )}
                helpLink={t('translation.HelpLinks.help')}
                components={selectComponents}
                parseValue={val => resourceTypes?.find(e => e?.id === val?.id)}
              />
            </FieldColumn>
          </FieldRow>
          <FieldRow>
            <FieldColumn>
              <FormField.RichTextEditor
                val={
                  !form.values.description
                    ? '<p></p>'
                    : Array.isArray(form.values.description)
                    ? serialize(form.values.description)
                    : form.values.description
                }
                label={t('translation.ServiceEditorForm.label-description')}
                name="description"
                helpText={t(
                  'translation.ServiceEditorForm.tooltip-info-description'
                )}
              />
            </FieldColumn>
          </FieldRow>
        </div>
      )}

      {!isNew ? (
        <DeleteButton
          withConfirm
          onConfirm={onDelete}
          confirmationMessage={t(
            'translation.ServiceEditorForm.title-service-confirm-delete'
          )}
        />
      ) : null}
      {!isMobile && !isAppointment ? (
        <FormFooterButtonsContainer>
          <Button
            footerButton
            buttonType={'text'}
            name="close"
            type={'button'}
            label={t('translation.ServiceEditorForm.button-close')}
            onClick={() => {
              pathname.includes(`/loyalty`)
                ? setShowAddServiceForm?.(false)
                : navigateTo.servicesList({
                    locationId,
                    typeId,
                  })
            }}
          />
          <Button
            type={'submit'}
            buttonType={'primary'}
            label={t('translation.ServiceEditorForm.button-save')}
            name="saveChanges"
          />
        </FormFooterButtonsContainer>
      ) : null}
    </FormContentContainer>
  )
}
