import styled from 'styled-components'
import { StyledFieldsContainer } from '../../components/Form/Form'
import { device } from '../../constants'
import { FieldRow } from '../Settings/styles'
import { ButtonStyled } from '../../components/Button/Button'

export const LoginPage = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(72, 47, 180, 0.3) 100%,
    #30364d
  );
  @media ${device.tablet} {
    display: initial;
  }
`

export const LoginForm = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;

  border-radius: 20px;
  z-index: 999;
  width: 500px;
  height: auto;

  @media ${device.tablet} {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
    ${FieldRow} {
      flex-direction: row;
    }
  }
`

export const LoginFormHeader = styled.a<{ isPartner?: boolean }>`
  height: 270px;
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin: 20px; */
  background: ${props => (props.isPartner ? '#5a47b6' : '#846fdb')};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media ${device.tablet} {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: auto;
  }
`

export const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 30px;
  z-index: 9999;

  @media ${device.tablet} {
    position: relative;
  }
`

export const LoginFormWrapper = styled.div<{ isSignupFlow?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 30px;
  ${StyledFieldsContainer} {
    height: ${props => (props.isSignupFlow ? '100%' : 'auto')};
  }

  @media ${device.tablet} {
    display: initial;
    height: 100%;
    padding-bottom: 50px;

    ${FieldRow} {
      ${ButtonStyled} {
        margin-bottom: 14px !important;
      }
    }
  }
`
