import React, { useContext, useState } from 'react'
import { components } from 'react-select'
import { SelectOptionLabel, SelectRow } from 'views/AppointmentModal/styles'
import styled, { ThemeContext } from 'styled-components'
import { Button, FormField, icons } from 'components'
import { wrapMenuList } from 'react-select-async-paginate'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'
import { useSdk } from 'sdk'
import { device } from '../../../constants'
import { EmptyList } from 'components/EmptyList/EmptyList'
import { ButtonStyled } from 'components/Button/Button'
import { ButtonLabel } from 'components/Button/style'
import Tooltip from 'components/Tooltip/Tooltip'
import { Badge } from 'src/styles'
import { formatPhoneNumber } from 'src/helpers'
import moment from 'moment'

interface Props {
  disabled?: boolean
  loadOptions?: any
  onNewClient?: any
  selectedClient?: any
  clearClient?: any
  selectClient?: any
  isPosReceipt?: boolean
}
export const Client = ({
  disabled,
  loadOptions,
  onNewClient,
  selectedClient,
  clearClient,
  selectClient,
  isPosReceipt,
}: Props) => {
  const { t } = useTranslation()
  const MenuList = wrapMenuList(props => CustomMenuList(props, t))
  const [showClientMenu, setShowClientMenu] = useState(false)
  const selectComponents = {
    MenuList: props => <MenuList {...props} onNewClient={onNewClient} />,
    MobileMenuList: props => (
      <MobileMenuList {...props} onNewClient={onNewClient} t={t} />
    ),
    Option: props => <Option {...props} />,
    ValueContainer: props => (
      <CustomControl
        props={props}
        setShowClientMenu={setShowClientMenu}
        showClientMenu={showClientMenu}
      />
    ),
    SingleValue: props => (
      <CustomSingleValue
        props={props}
        showClientMenu={showClientMenu}
        clearClient={clearClient}
      />
    ),
    DropdownIndicator: props =>
      disabled ? null : (
        <CustomDropdownIndicator
          props={props}
          showClientMenu={showClientMenu}
          isPosReceipt={isPosReceipt}
        />
      ),
    IndicatorSeparator: props => <CustomIndicatorSeparator props={props} />,
    ClearIndicator: props => (
      <CustomClearIndicator
        clearClient={clearClient}
        selectedClient={selectedClient}
      />
    ),
    MobileOption,
  }

  const selectStyles = {
    control: (styles, { hasValue }) => ({
      ...styles,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      minWidth: '200px',
      borderColor: hasValue ? 'transparent !important' : 'inherit',
    }),
    indicatorsContainer: (styles, { hasValue }) => ({
      ...styles,
      paddingRight: hasValue ? '10px' : 'inherit',
    }),
    clearIndicator: (styles, { hasValue }) => ({
      ...styles,
      paddingRight: hasValue ? '0px' : 'inherit',
    }),
    valueContainer: (styles, { hasValue }) => ({
      ...styles,
      minHeight: '60px',
      padding: hasValue ? '0 !important' : 'inherit',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? 'rgb(236 236 253 / 87%)'
          : null,
        padding: isFocused ? '10px 10px 15px;' : '10px',
        fontWeight: isSelected ? 500 : 400,
        ':active': {
          ...styles[':active'],
          backgroundColor: 'rgb(236 236 253 / 87%)',
        },
      }
    },
  }

  return (
    <FormField.Select
      isAsync
      name="client"
      options={[]}
      closeMenuOnSelect
      hideSelectedOptions={false}
      components={selectComponents}
      styles={selectStyles}
      isDisabled={disabled}
      formatOptionLabel={e => (e ? `${e.firstName} ${e.lastName}` : null)}
      loadOptions={loadOptions}
      placeholder={''}
      onMenuOpen={() => setShowClientMenu(true)}
      onMenuClose={() => setShowClientMenu(false)}
      openMenuOnFocus={!selectedClient}
      openMenuOnClick={!selectedClient}
      customComponent={
        selectedClient ? (
          <CustomClientWrapper
            clearClient={clearClient}
            props={{ data: selectedClient }}
            showClientMenu={showClientMenu}
          />
        ) : (
          <CustomEmptyClient />
        )
      }
      hideChevron={!selectedClient}
      customChange={selectClient}
    />
  )
}

const CustomEmptyClient = () => {
  const { t } = useTranslation()
  const selectedTheme = useContext(ThemeContext)

  return (
    <div style={{ width: '100%' }}>
      <AddClientButton>
        <ButtonLabel>
          {t('translation.ClientSelect.placeholder-add-client')}
        </ButtonLabel>
        <icons.Add color={selectedTheme.colors.primary} />
      </AddClientButton>
    </div>
  )
}

const CustomMenuList = (props, t) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: 1,
    config: { duration: 2500 },
  })

  const animateProps = props.options[0]
    ? {}
    : {
        transform: x
          .interpolate({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.97, 0.9, 1.0, 0.9, 1.0, 1.0, 1],
          })
          .interpolate(x => `scale(${x})`),
      }

  return (
    <components.MenuList
      {...props}
      style={{ overflowX: 'hidden' }}
      maxHeight={410}
    >
      <div style={{ position: 'relative' }}>
        <animated.div style={{ ...animateProps }}>
          <NewClientButton
            buttonType={'link'}
            label={t('translation.ClientSelect.newClient')}
            onClick={props.onNewClient}
            name={'addNewClient'}
          />
        </animated.div>
        {props.children}
      </div>
    </components.MenuList>
  )
}

const MobileMenuList = (props, t) => {
  return (
    <ClientMenuContainer>
      <NewClientButtonContainer>
        <NewClientButtonMobile
          buttonType={'primary'}
          label={props.t('translation.AppointmentModal.title-newClient')}
          onClick={props.onNewClient}
          name={'addNewClient'}
          iconComponent={
            <icons.Add size={'size14'} style={{ strokeWidth: 3 }} />
          }
          size={'medium'}
          fullWidth
        />
      </NewClientButtonContainer>

      {props.children[0].length ? (
        props.children
      ) : (
        <EmptyList
          buttonCallback={() => true}
          buttonLabel={''}
          showButton={false}
          title={props.t('translation.ClientSelect.noResult')}
        />
      )}
    </ClientMenuContainer>
  )
}

const MobileOption = props => {
  const isSelected = props.selectProps.value?.id === props.data?.id
  const { t } = useSdk()

  return (
    <>
      <ClientSelectRowMobile isSelected={isSelected}>
        <ClientContainer>
          <ClientInfo>
            <ClientHeader>
              <ClientName>
                {props.data.firstName + ' ' + props.data.lastName + ' '}
              </ClientName>
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {props.data?.status === 'BLOCKED' ? (
                  <Badge>{t('translation.ClientsList.label-blocked')}</Badge>
                ) : null}
                {props.data.paymentRequired ? (
                  <Tooltip
                    label={t('translation.ClientsList.tooltip-prepayment')}
                    key={'tooltip'}
                  >
                    <icons.CreditCard
                      style={{ strokeWidth: 1.2 }}
                      size="small"
                      color="#333"
                    />
                  </Tooltip>
                ) : null}
              </div>
            </ClientHeader>
            <ClientRow>
              <ClientAvatarContainer>
                {props.data?.user?.avatarUrl &&
                !props.data?.user?.avatarUrl?.endsWith('null') ? (
                  <ClientAvatar src={props.data?.user?.avatarUrl} />
                ) : !!props.data?.user?.google_profilePicUrl ? (
                  <ClientAvatar src={props.data?.user?.google_profilePicUrl} />
                ) : !!props.data?.user?.fb_profilePicUrl ? (
                  <ClientAvatar src={props.data?.user?.fb_profilePicUrl} />
                ) : (
                  <ClientNoAvatar>
                    {props.data?.firstName
                      ? props.data?.firstName.charAt(0).toUpperCase() +
                        props.data?.lastName.charAt(0).toUpperCase()
                      : 'NN'}
                  </ClientNoAvatar>
                )}
              </ClientAvatarContainer>
              <ClientContactRow>
                <ClientContact
                  isOrange={!props?.data?.email}
                  style={{ marginBottom: 3 }}
                >
                  {props?.data?.email ? props.data?.email : null}
                </ClientContact>
                <ClientContact isOrange={!props?.data?.mobilePhone}>
                  {props?.data?.mobilePhone ? props.data?.mobilePhone : null}
                </ClientContact>
              </ClientContactRow>
            </ClientRow>
            {props?.data?.comment ? (
              <ClientRow style={{ marginTop: 6, marginBottom: '-2px' }}>
                <CommentMessage>{props?.data?.comment}</CommentMessage>
              </ClientRow>
            ) : null}
          </ClientInfo>
        </ClientContainer>
      </ClientSelectRowMobile>
    </>
  )
}

const CustomSingleValue = ({ clearClient, props, showClientMenu }) => {
  return (
    <components.SingleValue {...props}>
      <CustomClientWrapper
        props={props}
        showClientMenu={showClientMenu}
        clearClient={clearClient}
      />
    </components.SingleValue>
  )
}

const CustomClientWrapper = ({ props, showClientMenu, clearClient }) => {
  const { t, isMobile } = useSdk()

  return showClientMenu ? null : (
    <ControlContainer style={{ width: '100%' }} hasValue>
      {props.data?.user?.avatarUrl &&
      !props.data?.user?.avatarUrl?.endsWith('null') ? (
        <ControlAvatar image={props.data?.user?.avatarUrl} hasValue />
      ) : !!props.data?.user?.google_profilePicUrl ? (
        <ControlAvatar
          image={props.data?.user?.google_profilePicUrl}
          hasValue
        />
      ) : !!props.data?.user?.fb_profilePicUrl ? (
        <ControlAvatar image={props.data?.user?.fb_profilePicUrl} hasValue />
      ) : (
        <ControlAvatar hasValue />
      )}
      <ControlRow>
        <ControlColumn>
          <ControlRow>
            <ControlName hasValue>{props.data.fullName}</ControlName>
            {props.data?.status === 'BLOCKED' ? (
              <Badge style={{ marginLeft: '10px' }}>
                {t('translation.ClientsList.label-blocked')}
              </Badge>
            ) : null}
          </ControlRow>
          {isMobile ? (
            <>
              {props.data.mobilePhone ? (
                <ControlLabel hasValue>
                  {formatPhoneNumber(props.data.mobilePhone)}
                </ControlLabel>
              ) : null}
              <ControlLabel hasValue>{props.data.email}</ControlLabel>
            </>
          ) : (
            <ControlRow>
              {props.data.mobilePhone ? (
                <ControlLabel hasValue>
                  {formatPhoneNumber(props.data.mobilePhone)}
                </ControlLabel>
              ) : null}
              <ControlLabel
                style={{ marginLeft: props.data.mobilePhone ? '50px' : 0 }}
                hasValue
              >
                {props.data.email}
              </ControlLabel>
            </ControlRow>
          )}
        </ControlColumn>
      </ControlRow>
    </ControlContainer>
  )
}

const CustomDropdownIndicator = ({ props, showClientMenu, isPosReceipt }) => {
  const { t } = useSdk()
  return props.hasValue ? (
    showClientMenu || isPosReceipt ? null : (
      <components.DropdownIndicator {...props}>
        <Button
          label={t('translation.Client.izmjeni')}
          buttonType={'text'}
          hasIconOnly={true}
          iconComponent={
            <icons.Edit color={'#a0a0a0'} size={'smaller'} stroke={2} />
          }
          size={'small'}
        />
      </components.DropdownIndicator>
    )
  ) : (
    <components.DropdownIndicator {...props} />
  )
}

const CustomIndicatorSeparator = ({ props }) => {
  return props.hasValue ? null : (
    <components.IndicatorSeparator {...props}></components.IndicatorSeparator>
  )
}

const CustomClearIndicator = ({ clearClient, selectedClient }) => {
  const { t } = useSdk()
  return !selectedClient ? null : (
    <Button
      label={t('translation.Client.izmjeni')}
      buttonType={'text'}
      hasIconOnly={true}
      iconComponent={
        <icons.Cross size={'extraSmall'} color={'#a0a0a0'} stroke={4} />
      }
      onClick={e => {
        e.preventDefault()
        clearClient()
      }}
      size={'small'}
    />
  )
}

const CustomControl = ({ props, showClientMenu, setShowClientMenu }) => {
  const { t } = useSdk()
  return (
    <components.ValueContainer {...props}>
      {!props.hasValue && !showClientMenu && (
        <ControlContainer>
          <ControlAvatar />
          <ControlRow>
            <ControlColumn>
              <ControlName>
                {t('translation.Client.odaberi-klijenta')}
              </ControlName>
              <ControlLabel>
                {t('translation.Client.ili-ostavi-prazno-za-walk-in')}
              </ControlLabel>
            </ControlColumn>
          </ControlRow>
        </ControlContainer>
      )}

      <div style={{ paddingLeft: !props.hasValue ? 15 : 0 }}>
        {props.children}
      </div>
    </components.ValueContainer>
  )
}

type TControlContainerProps = {
  hasValue?: boolean
}
export const ControlContainer = styled.div<TControlContainerProps>`
  padding: ${props => (props.hasValue ? '0' : ' 5px 10px;')};
  justify-content: flex-start;
  align-items: center;
  display: flex;
  svg {
    width: 12px;
    color: #cccccc;
    stroke-width: 4px;
    margin-right: 14px;
  }

  @media ${device.tablet} {
    padding: 0px 0px;

    svg {
      color: #333;
    }
  }
`

type TControlAvatarProps = {
  image?: string
  hasValue?: boolean
}
export const ControlAvatar = styled.div<TControlAvatarProps>`
  background-image: url(${props =>
    props.image || '/assets/default-avatar.svg'});
  background-repeat: no-repeat;
  background-size: cover;
  width: ${props => (props.hasValue ? '50px' : '40px')};
  height: ${props => (props.hasValue ? '50px' : '40px')};
  object-fit: cover;
  background-position: center;
  border-radius: 4px;
  margin-right: 15px;
  border: 1px solid #dbdfe2;
`

type TControlRowProps = {}
export const ControlRow = styled.div<TControlRowProps>`
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  flex: 1;
  align-items: center;
`

type TControlColumnProps = {}
export const ControlColumn = styled.div<TControlColumnProps>`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`

type TControlNameProps = {
  hasValue?: boolean
}
export const ControlName = styled.p<TControlNameProps>`
  color: ${props => props.theme.colors.text};
  font-weight: 500;

  font-size: ${props => (props.hasValue ? '18px' : '14px')};

  @media ${device.tablet} {
    font-size: 16px;
  }
`

type TControlLabelProps = {
  hasValue?: boolean
}
export const ControlLabel = styled.p<TControlLabelProps>`
  color: ${props => props.theme.colors.secondaryDark};

  font-size: ${props => (props.hasValue ? '14px' : '13px')};
  font-weight: 300;

  @media ${device.tablet} {
    line-height: 24px;
    font-size: 13px;
  }
`
const Option = props => {
  const isSelected = props.selectProps.value?.id === props.data?.id

  const { t } = useSdk()
  return (
    <components.Option {...props}>
      <ClientSelectRow isSelected={isSelected}>
        <ClientContainer>
          <ClientInfo>
            <ClientHeader>
              <ClientName>
                {props.data.firstName + ' ' + props.data.lastName + ' '}
              </ClientName>
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {props.data?.status === 'BLOCKED' ? (
                  <Badge>{t('translation.ClientsList.label-blocked')}</Badge>
                ) : null}
                {props.data.paymentRequired ? (
                  <Tooltip
                    label={t('translation.ClientsList.tooltip-prepayment')}
                    key={'tooltip'}
                  >
                    <icons.CreditCard
                      style={{ strokeWidth: 1.2 }}
                      size="small"
                      color="#333"
                    />
                  </Tooltip>
                ) : null}
              </div>
            </ClientHeader>
            <ClientRow>
              <ClientAvatarContainer>
                {props.data?.user?.avatarUrl &&
                !props.data?.user?.avatarUrl?.endsWith('null') ? (
                  <ClientAvatar src={props.data?.user?.avatarUrl} />
                ) : !!props.data?.user?.google_profilePicUrl ? (
                  <ClientAvatar src={props.data?.user?.google_profilePicUrl} />
                ) : !!props.data?.user?.fb_profilePicUrl ? (
                  <ClientAvatar src={props.data?.user?.fb_profilePicUrl} />
                ) : (
                  <ClientNoAvatar>
                    {props.data?.firstName
                      ? props.data?.firstName.charAt(0).toUpperCase() +
                        props.data?.lastName.charAt(0).toUpperCase()
                      : 'NN'}
                  </ClientNoAvatar>
                )}
              </ClientAvatarContainer>
              <ClientContactRow>
                <ClientContact
                  isOrange={!props?.data?.email}
                  style={{ marginBottom: 3 }}
                >
                  {props?.data?.email ? props.data?.email : null}
                </ClientContact>
                <ClientContact isOrange={!props?.data?.mobilePhone}>
                  {props?.data?.mobilePhone ? props.data?.mobilePhone : null}
                </ClientContact>
              </ClientContactRow>
            </ClientRow>
            {props?.data?.comment ? (
              <ClientRow style={{ marginTop: 6, marginBottom: '-2px' }}>
                <CommentMessage>{props?.data?.comment}</CommentMessage>
              </ClientRow>
            ) : null}
          </ClientInfo>
        </ClientContainer>
      </ClientSelectRow>
    </components.Option>
  )
}

const NewClientButton = styled(Button)`
  position: sticky;
  top: -35px;
`
const NewClientButtonMobile = styled(Button)`
  width: calc(100% - 40px);
`
const NewClientButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  left: 20px;
  background: ${props => props.theme.colors.light};
  padding: 9px 0 10px;
  z-index: 1;
  border-top: 1px solid ${props => props.theme.colors.light};
  ${ButtonStyled} {
    height: 50px;
  }
`

type TClientSelectRowProps = {
  isSelected: boolean
}
const ClientSelectRow = styled(SelectRow)<TClientSelectRowProps>`
  display: flex;
  align-items: center;
  width: auto;
  padding: 7px 8px 8px;
  border: 1px solid
    ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.lightBackground};

  border-radius: 10px;
  background: ${props => props.theme.colors.lightBackground};
  margin-bottom: -4px;
`
const ClientSelectOptionLabel = styled(SelectOptionLabel)`
  flex-shrink: 1;
`
const ClientContainer = styled.div`
  width: 100%;
`
const ClientInfo = styled.div``
const ClientName = styled.div`
  font-weight: 500;
  margin-right: 10px;
  font-size: 14px;
  color: ${props => props.theme.colors.text};
`
const ClientAvatarContainer = styled.div`
  display: flex;
`
const ClientRow = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  :nth-of-type(3) {
    @media (max-width: 345px) {
      flex-direction: column;
      align-items: baseline;
    }
  }
`
const ClientContactRow = styled.div`
  margin-left: 10px;
  overflow: hidden;
`

type TClientContactProps = {
  isOrange?: boolean
}
const ClientContact = styled.div<TClientContactProps>`
  font-size: 12px;
  color: ${props => (props.isOrange ? props.theme.colors.accent4 : '#555')};
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: ${props => (props.isOrange ? 'italic' : null)};
`

const ClientAvatar = styled.img`
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.light};
  border-radius: 50px;
  object-fit: cover;
  flex-shrink: 0;
  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }
`
const ClientNoAvatar = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.light};
  border-radius: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.143rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a4ee1;
  background: rgba(106, 78, 225, 0.22);
  flex-shrink: 0;
  @media ${device.tablet} {
    width: 50px;
    height: 50px;
  }
`
const ClientMenuContainer = styled.div`
  position: relative;
  overflow-y: auto;
  margin-top: 3px;
  padding-top: 12px;
  margin-bottom: 35px;
  &::-webkit-scrollbar {
    display: none;
  }
`

type TClientSelectRowMobileProps = {
  isSelected: boolean
}

export const ClientSelectRowMobile = styled(SelectRow)<
  TClientSelectRowMobileProps
>`
  margin: -9px;
  display: flex;
  align-items: center;
  width: auto;
  padding: 9px 10px 11px;
  border: 1px solid
    ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.lightBackground};
  margin-bottom: 5px;
  border-radius: 10px;
  background: ${props => props.theme.colors.lightBackground};
`

const CommentMessage = styled.span`
  color: ${props => props.theme.colors.accent5};
`
const ClientHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`

type TAddClientButtonProps = {}
export const AddClientButton = styled.div<TAddClientButtonProps>`
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: ${props => props.theme.colors.primary};

    svg {
      width: 16px;
      stroke-width: 4;
    }
  }
`
