import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../constants'
import Button from 'components/Button/Button'
import {
  ModalRightRow,
  PlanTitle,
} from 'views/Settings/Billing/Modals/OrgDataForm'
import { FormFooterContainer, Loader } from 'components'
import { useSdk } from 'sdk'
import { formatPriceNumberWithDelimiter, getPlanName } from 'src/helpers'
import { icons } from 'components/Icon'
import { ModalRight } from './CreditCardForm'
import { ModalRightWarning } from './ConfirmationForm'
import { GetOrganizationByIdQuery } from 'src/state/graphql'

type TSmsConfirmationFormProps = {
  selectedPackage: any
  closeModal: () => void
  setStep: (val: number) => void
  isBusy: boolean
  onSubmit: () => void
  org?: GetOrganizationByIdQuery['organization']['get'] | null
}
export const SmsConfirmationForm = (props: TSmsConfirmationFormProps) => {
  const { selectedPackage, closeModal, setStep, org, isBusy, onSubmit } = props

  const { t } = useSdk()

  return (
    <>
      <ModalCOntainer data-cy={'orgForm'}>
        {isBusy && <Loader isComponent />}
        <BodyTextSection>
          <Row>
            <Label>
              {t('translation.SmsConfirmationForm.selected-package')}
            </Label>
            <Text>{getPlanName(selectedPackage.id)}</Text>
          </Row>

          <Row>
            <Label>{t('translation.ConfirmationForm.paymentMethod')}</Label>
            <>
              <CardHeader>
                <span>{org?.subscriptionCreditCardType}</span>
              </CardHeader>
              <CardDetail>
                {/*  <span> {org?.subscriptionCreditCardHolderName}</span> */}
                <span>
                  {org?.subscriptionCreditCardType?.startsWith('SEPA')
                    ? `HR********${org?.subscriptionCreditCardNumber}`
                    : `**** **** **** ${org?.subscriptionCreditCardNumber}`}
                </span>
              </CardDetail>
            </>
          </Row>
          <>
            <Divider />

            <Row style={{ justifyContent: 'space-between' }}>
              <Label>{t('translation.SmsConfirmationForm.sms-number')}</Label>
              <Text isBold>{selectedPackage?.units}</Text>
            </Row>
            <Row style={{ marginBottom: 20, justifyContent: 'space-between' }}>
              <Label>{t('translation.ConfirmationForm.totalAmount')}</Label>
              <Text isBold>
                {formatPriceNumberWithDelimiter(selectedPackage?.price) + ' '}
                {org?.stripeCurrencyId}
              </Text>
            </Row>
          </>
        </BodyTextSection>
        <ModalRight>
          <PlanTitle>{t('translation.CreditCardForm.cardChange')}</PlanTitle>
          <ModalRightRow>
            <ModalRightWarning style={{ minWidth: 'unset', marginBottom: 20 }}>
              <span>
                {t('translation.SmsConfirmationForm.saved-card-hint')}
              </span>
            </ModalRightWarning>
          </ModalRightRow>
        </ModalRight>
      </ModalCOntainer>
      <FormFooterContainer
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            buttonType={'link'}
            type="button"
            name={'back'}
            label={t('translation.OrgDataForm.natrag')}
            iconComponent={<icons.ArrowLeft />}
            footerButton
            onClick={e => {
              setStep(!!org?.subscriptionMandateId ? 1 : 2)
              e.preventDefault()
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            buttonType={'text'}
            type="button"
            name={'close'}
            label={t('translation.OrgDataForm.close')}
            footerButton
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          />
          <Button
            buttonType={'primary'}
            type="button"
            onClick={onSubmit}
            disabled={isBusy}
            name={'saveChanges'}
            iconComponent={<icons.CheckFilled size={'extraSmall'} />}
            iconRight
            label={t('translation.ConfirmationForm.pay')}
          />
        </div>
      </FormFooterContainer>
    </>
  )
}

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  span {
    color: ${props => props.theme.colors.text};
  }
`

export const ModalRightTitle = styled.span`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  position: relative;
`

export const Currency = styled.span`
  position: absolute;
  margin-left: 5px;
  font-size: 14px;
  margin-top: 3px;
`

export const ModalCOntainer = styled.div`
  display: flex;
  padding: 0px 20px 0px 0px;
  justify-content: space-around;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0px;
  }
`

export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 30px;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 5px 20px;
    min-height: 100%;
  }
`

export const BodyImage = styled.img`
  margin-top: 20px;
  max-height: 350px;
  flex: 2;
  min-width: 440px;

  @media ${device.tablet} {
    min-width: 100vw;
    max-height: 40vh;
    margin-top: 30px;
  }
`

export const BodyTitle = styled.span`
  font-size: 32px;
  font-weight: 700;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 44px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`

export const BodyTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 50px 30px 30px;
  flex: 1;

  @media ${device.tablet} {
    margin: 30px;
  }
`

export const BodyText = styled.span``

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  button:first-child {
    margin-right: 15px;
  }
`

export const Label = styled.span`
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  margin-right: 10px;
  min-width: 135px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`

export const Text = styled.span<{ isBold?: boolean }>`
  font-weight: ${props => (props.isBold ? '600' : '400')};
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => props.theme.colors.outline};
  margin: 10px 0px;
`

export const LabelTotal = styled.span`
  font-size: 18px;
  font-weight: 600;
`
