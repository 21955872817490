import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Facebook = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M13 24H2c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h21c.6 0 1 .4 1 1v21c0 .6-.4 1-1 1h-6v-9h2.6c.3 0 .5-.2.5-.4l.4-3c0-.3-.2-.5-.4-.6H17V9.7c0-.9.8-1.7 1.7-1.7h1.8c.3 0 .5-.2.5-.5v-3c0-.3-.2-.5-.5-.5h-1.8C15.5 4 13 6.5 13 9.7V11h-2.5c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5H13v9z"
        className="st0"
      ></path>
    </StyledIcon>
  )
}
