import React, { useState } from 'react'
import { Flex, Button } from 'components'
import styled from 'styled-components'
import { useSdk } from 'sdk'
import { device } from '../../constants'
import { Trash } from 'components/Icon/Icons/Trash'

type TProps = {
  withConfirm?: boolean
  confirmationMessage?: string
  onConfirm?: () => any
  title?: string
}
export const DeleteButton = (props: TProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { title, onConfirm, withConfirm, confirmationMessage } = props

  const handleDelete = () => {
    onConfirm?.()
  }
  const handleClick = e => {
    e.preventDefault()
    if (withConfirm && !showConfirmation) {
      setShowConfirmation(true)
    } else {
      handleDelete()
    }
  }
  const handleCancel = () => {
    setShowConfirmation(false)
  }

  const { t } = useSdk()

  return (
    <StyledButtonsContainer style={{ justifyContent: 'flex-end' }}>
      {showConfirmation ? (
        <ConfirmationPanel
          onYes={handleDelete}
          onNo={handleCancel}
          message={confirmationMessage}
        />
      ) : (
        <Button
          type="button"
          buttonType="primaryAccent"
          iconComponent={<Trash size="smaller" stroke={2} />}
          onClick={handleClick}
          label={t('translation.DeleteButton.label-delete')}
        >
          {title}
        </Button>
      )}
    </StyledButtonsContainer>
  )
}
const StyledButtonsContainer = styled(Flex.Row)`
  button:last-child {
    margin-left: 6px;
  }

  @media ${device.tablet} {
    display: initial;
  }
`
const StyledMessageContainer = styled(Flex.Column)`
  justify-content: center;
  padding-right: 6px;
`
const ConfirmationPanel = props => {
  const { onYes, onNo, message } = props
  const { t } = useSdk()

  return (
    <>
      <StyledMessageContainer>{message}</StyledMessageContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="button"
          buttonType="secondary"
          onClick={onNo}
          label={t('translation.DeleteButton.label-no')}
        />
        <Button
          type="button"
          buttonType="primary"
          onClick={onYes}
          label={t('translation.DeleteButton.label-yes')}
        />
      </div>
    </>
  )
}
