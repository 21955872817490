import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Globe = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M23.5 11.941h0c.003 6.373-5.16 11.54-11.532 11.544 0 0 0 0 0 0h0C5.568 23.378.45 18.135.5 11.734h0C.505 5.62 5.393.63 11.505.5c.166-.007.332-.01.5-.01h0A11.433 11.433 0 0123.5 11.941zM11.505.5c-6 6.5-6 14.98 0 22.98M12.505.5c6 6.5 6 14.977 0 22.977M2.39 5.48h19.13M.5 11.48h23M1.99 17.48h20.09"></path>
      </g>
    </StyledIcon>
  )
}
