import { useField, useFormikContext } from 'formik'
import { merge } from 'lodash'
import React, { useContext, useState } from 'react'
import { useFormContext } from 'src/components/Form/Form'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { useClientGetQuery } from 'src/state/graphql'

interface Props {
  name: string
}
export const GrupAppointmentClientSelect = (props: Props) => {
  const [field, meta] = useField(props)
  const fmk = useFormikContext()
  const formContext = useFormContext()

  const { data: clientData, loading: isLoadingClient } = useClientGetQuery({
    variables: {
      id: field.value,
    },
    skip: !field.value,
  })
  const getValueFromField = () => {
    return field.value
  }

  const handleChange = value => {
    let selected = value
    const newValue = selected

    formContext?.valueChange(field, field.name, newValue)
    setTimeout(() => {
      //glitch with validation if the fmk.setFieldValue is called before the change handle completes
      //so we call the valueChanged inside timeout
      // props.onValueChanged?.(newValue, mergedValues, fmk)
      fmk.validateForm()
    }, 0)
  }

  return (
    <ClientSelect
      selectedItem={clientData?.client?.get || undefined}
      onItemSelected={item => handleChange(item?.id)}
    />
  )
}
