import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button } from 'components'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { useHeader } from 'mobile/Header'
import { DataTable } from 'src/sdk/tw/DataTable'
import { Column } from 'react-table'
import { RouterOutput, trpc } from 'src/trpc'
import { StickyHeader } from '../styles'
import SearchBar from 'components/SearchBar/SearchBar'
import { icons } from 'components/Icon'
import { ListHeader } from 'layout/ListHeader'
import { MobileContext } from 'src/Context'
import {
  getLoyaltyListName,
  formatPriceNumberWithDelimiter,
  resolveFullName,
  giftCardStatusOptions,
} from 'src/helpers'
import { TabContainer } from 'src/styles'
import { TabView, Tab } from 'components'
import { useFooter } from 'mobile/Footer'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Select } from 'src/components/Select2/Select'
import { CheckMarkIcon } from 'src/components/Icon/Icons/Checkmark'
import { SelectRow, SelectOptionLabel } from 'src/views/AppointmentModal/styles'
import { useACL } from 'src/sdk/acl'

export type TypeGiftCardItem = {
  description: string | null
  discountRate: any
  giftCardTemplateId: bigint
  id: bigint
  itemName: string | null
  serviceId: bigint
  serviceTypeId: bigint
  status: 'ACTIVE' | 'DELETED'
  quantity: number
  Service?: any
  ServiceType?: any
}

export const GiftCardList = () => {
  const params = useParams<{
    locationId: string
    orgId: string
    voucherType: string
  }>()
  const { locationId, orgId, voucherType } = params
  const { navigateTo, t } = useSdk()
  const isMobile = useContext(MobileContext)

  const { hasPermission } = useACL()
  const [filterText, setFilterText] = useState<string>('')
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    voucherType === 'giftcard' ? 0 : 1
  )

  const [statusFilter, setStatusFilter] = useState<
    { id: string; name: string }[]
  >([
    { id: 'ACTIVE', name: t('translation.GiftCardList.status-active') },
    { id: 'INACTIVE', name: t('translation.GiftCardList.status-inactive') },
  ])

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const queryInput = {
    orgId: orgId,
    locationId: BigInt(locationId),
    voucherType: voucherType?.toUpperCase(),
    searchTerm: filterText,
    status: statusFilter?.map(x => x?.id),
  }

  const { data, isLoading } = trpc.giftCard_findMany.useQuery(queryInput)

  const dataList = data?.sort((a, b) => Number(b?.id) - Number(a?.id)) || []

  const trpcContext = trpc.useContext()

  useEffect(() => {
    setSelectedTabIndex(voucherType === 'giftcard' ? 0 : 1)
    trpcContext.giftCardTemplate_findMany.invalidate()
    trpcContext.giftCard_findMany.invalidate()
  }, [voucherType])

  useEffect(() => {
    navigateTo.listGiftCard({
      voucherType: selectedTabIndex === 0 ? 'giftcard' : 'voucher',
    })
  }, [selectedTabIndex])

  useHeader(
    {
      content: getLoyaltyListName(voucherType, 'giftcard'),
      onGoBack: navigateTo.settings,
    },
    [voucherType]
  )
  const canCreateGiftCard = hasPermission('CREATE_GIFTCARDS')

  useFooter(
    {
      showAddButton: true,
      hasTitle: false,
      addButtonOptions: [
        ...(canCreateGiftCard
          ? [
              {
                onClick: () => {
                  navigateTo.newGiftCard({ type: voucherType })
                },
                title:
                  voucherType === 'giftcard'
                    ? 'Dodaj poklon bon'
                    : 'Dodaj paket',
                icon: <icons.Add size="smaller" />,
              },
            ]
          : []),

        {
          component: (
            <>
              <span className="text-lg font-semibold mt-[20px]">
                {'Vrste poklon bona'}
              </span>
              <div className="flex flex-col pl-[20px]">
                <span
                  className=" mt-[20px]"
                  onClick={() =>
                    navigateTo.listGiftCard({ voucherType: 'giftcard' })
                  }
                >
                  Poklon bonovi
                </span>
                <span
                  className=" my-[10px]"
                  onClick={() =>
                    navigateTo.listGiftCard({ voucherType: 'voucher' })
                  }
                >
                  Paketi
                </span>
              </div>
            </>
          ),
          onClick: () => {},
        },
        null as any,
      ],
    },
    [voucherType, canCreateGiftCard]
  )

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Oznaka',
        accessor: 'code',
        minWidth: 100,
        width: 100,
        Cell: props => <p>{props.cell.row.original?.code}</p>,
      },
      {
        Header: 'Naziv',
        accessor: 'name',
        minWidth: 200,
        width: 200,
        Cell: props => <p>{props.cell.row.original?.name}</p>,
      },
      {
        Header: 'Nositelj',
        accessor: 'client',
        minWidth: 200,
        width: 200,

        Cell: props => (
          <p>{resolveFullName(props.cell.row.original?.client, false)}</p>
        ),
      },
      {
        Header: 'Vrijedi do',
        accessor: 'validTo',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p>
            {props.cell.row.original?.validTo
              ? format(props.cell.row.original?.validTo, 'dd.MM.yyyy.')
              : ''}
          </p>
        ),
      },
      {
        Header: () => <div className="text-end pr-[20px]">{'Cijena'}</div>,
        accessor: 'price',
        minWidth: 120,
        width: 120,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.price
          )} ${org?.currency?.id}`}</p>
        ),
      },
      {
        Header: () => <div className=" text-center">{'Popust'}</div>,
        accessor: 'discountRate',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="text-center">
            {`${
              formatPriceNumberWithDelimiter(
                props.cell.row.original?.discountRate * 100,
                false
              ) || ''
            }%`}
          </p>
        ),
      },
      {
        Header: () => <div className="pr-[20px] text-end">{'Preostalo'}</div>,
        accessor: 'left',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.balance
          )} ${org?.currency?.id}`}</p>
        ),
      },
      {
        Header: () => <div className="text-center">{'Status'}</div>,
        accessor: 'status',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p
            className={`text-center ${
              props.cell.row.original?.status === 'ACTIVE'
                ? 'text-zoyya-accent2'
                : props.cell.row.original?.status === 'INACTIVE'
                ? 'text-zoyya-accent4'
                : props.cell.row.original?.status === 'EXPIRED'
                ? 'text-zoyya-accent5'
                : 'text-zoyya-accent1'
            }`}
          >
            {t(
              `translation.GiftCardList.status-single-${props.cell.row.original?.status?.toLowerCase()}`
            ).toUpperCase()}
          </p>
        ),
      },
    ],
    [org]
  )

  const MobileOption = props => {
    const isSelected = props.selectProps.value?.find(
      e => e.id === props.data?.id
    )
    return (
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>

        <SelectOptionLabel data-cy={'mobileSelect_value'}>
          {props.data?.name}
        </SelectOptionLabel>
      </SelectRow>
    )
  }

  const selectComponents = {
    MobileOption,
  }

  const ShowList = () => {
    return (
      <div className="flex flex-col overflow-y-auto w[100%] p-[0] lg:p-4 h-[calc(100vh-55px)]">
        {!isMobile ? (
          <div className="flex w-full items-center sticky top-0 z-10 pb-3">
            <StickyHeader>
              <Button
                onClick={() => navigateTo.newGiftCard({ type: voucherType })}
                iconComponent={<icons.Add size="smaller" stroke={2} />}
                buttonType={'primary'}
                label={
                  voucherType === 'giftcard' ? 'Novi poklon bon' : 'Novi paket'
                }
                disabled={!hasPermission('CREATE_GIFTCARDS')}
                name="addNew"
              />
            </StickyHeader>
            <SearchBar
              messageList={false}
              clientsList={false}
              search={filterText}
              onChange={search => setFilterText(search?.toLowerCase())}
              isOpen
              placeholder={t('translation.Table.label-search')}
              styleWrapper={{
                marginRight: 10,
                marginLeft: 10,
                width: '80%',
              }}
            />

            <>
              <Select
                closeMenuOnSelect
                value={statusFilter}
                isMulti
                isClearable={false}
                options={giftCardStatusOptions}
                styles={{
                  control: provided => ({
                    ...provided,
                    minHeight: '40px !important',
                  }),
                  valueContainer: provided => ({
                    ...provided,
                    minHeight: '40px !important',
                  }),
                  container: provided => ({
                    ...provided,
                    minWidth: '225px',
                  }),
                }}
                components={selectComponents as any}
                onChange={item => {
                  !item
                    ? setStatusFilter([
                        { id: 'ACTIVE', name: 'Aktivni' },
                        { id: 'INACTIVE', name: 'Neaktivni' },
                      ])
                    : item[item?.length - 1]?.id === 'ALL'
                    ? setStatusFilter([{ id: 'ALL', name: 'Svi' }])
                    : setStatusFilter(item.filter(item => item?.id !== 'ALL'))
                }}
              />
            </>
          </div>
        ) : (
          <div>
            <ListHeader
              hasSearch
              onSearchChange={search => setFilterText(search?.toLowerCase())}
              messageList
              clientsList
              styleWrapper={{ top: '0' }}
              placeholder={t('translation.Table.label-search')}
            />

            <Select
              closeMenuOnSelect
              value={statusFilter}
              isMulti
              isClearable={false}
              options={giftCardStatusOptions}
              components={selectComponents as any}
              styles={{
                control: provided => ({
                  ...provided,
                  minHeight: '40px !important',
                }),
                valueContainer: provided => ({
                  ...provided,
                  minHeight: '40px !important',
                }),
              }}
              onChange={item => {
                !item
                  ? setStatusFilter([
                      { id: 'ACTIVE', name: 'Aktivni' },
                      { id: 'INACTIVE', name: 'Neaktivni' },
                    ])
                  : item[item?.length - 1]?.id === 'ALL'
                  ? setStatusFilter([{ id: 'ALL', name: 'Svi' }])
                  : setStatusFilter(item.filter(item => item?.id !== 'ALL'))
              }}
            />
          </div>
        )}

        <div className="flex flex-1 pt-1">
          <div className="flex flex-row p-0 m-0 w-full">
            <DataTable
              data={dataList}
              renderMobileCard={(item: any) => (
                <GiftCardMobileCard item={item} currency={org?.currency?.id} />
              )}
              columns={columns}
              isLoading={isLoading || loadingOrgData}
              emptyListTitle={'Nema poklon bonova'}
              onRowClick={item =>
                item?.status !== 'DELETED'
                  ? navigateTo.editGiftCard({ id: item?.id })
                  : null
              }
              mobileCardHeight={90}
              tableRowHeight={70}
            />
          </div>
        </div>
      </div>
    )
  }

  if (isMobile)
    return (
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col flex-1 lg:p-4">{ShowList()}</div>
      </div>
    )

  return (
    <TabContainer>
      <TabView
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        isSingleTab={false}
        hasFooter={false}
      >
        <Tab hasFooter={false} title={t('translation.Sidebar.label-giftCards')}>
          {ShowList()}
        </Tab>

        <Tab hasFooter={false} title={t('translation.Sidebar.label-vouchers')}>
          {ShowList()}
        </Tab>
      </TabView>
    </TabContainer>
  )
}

const GiftCardMobileCard = (props: {
  item: RouterOutput['giftCard_findMany'][0]
  currency: string
}) => {
  const { item, currency } = props

  return (
    <div className="flex flex-row py-4 mx-4 border-b border-zoyya-outline items-center">
      <div className="flex flex-col flex-1">
        <div className="flex justify-between">
          <p>{item?.name}</p>
          <span>{item?.status}</span>
        </div>

        <div className="text-gray-400 mb-2 text-sm flex justify-between">
          <span>{item?.code}</span>
          <span>{`${formatPriceNumberWithDelimiter(
            item?.value
          )} ${currency}`}</span>
        </div>
      </div>
    </div>
  )
}

export default GiftCardList
