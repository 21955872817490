import React from 'react'
import styled from 'styled-components'
import { icons } from 'components/Icon'

const StyledIconButtonContainer = styled.div`
  display: flex;
  padding: 0.3rem;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

type TProps = {
  icon: keyof typeof icons
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => any
  size: 'small' | 'smaller' | 'extraSmall' | 'medium' | 'large'
}
export const IconButton = props => {
  const { icon, size, onClick } = props
  const Icon = icons[icon]
  return (
    <StyledIconButtonContainer onClick={onClick}>
      <Icon size={size} />
    </StyledIconButtonContainer>
  )
}

IconButton.defaultProps = {
  size: 'smaller',
}
