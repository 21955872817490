import React, { useRef, useEffect, useState, useMemo } from 'react'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { FormPageContainer } from 'src/styles'
import { Button, Form, FormField, icons, Loader } from 'components'
import { useSdk } from 'sdk'
import { FormFieldsWrapper } from 'components/Form/Form'
import { useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import {
  useWmsOrderItemUpdateMutation,
  useWmsOrderItemDeleteMutation,
  WmsOrderGetDocument,
  useProductTypeAllQuery,
  useWmsOrderItemCreateMutation,
  useWmsOrderCreateMutation,
  WmsOrderListDocument,
  WmsGetActiveWarehouseDocument,
  useProductTypeCurrentStockQuery,
} from 'src/state/graphql'
import { ServiceSelect } from '../CashRegister/NewReceipt/styles'
import { debounce } from 'lodash'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import {
  formatPriceNumberWithDelimiter,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import ToolTip from 'components/Tooltip/Tooltip'
import Warning from 'src/components/Icon/Icons/Warning'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import NewProduct from '../Settings/Products/ProductEditor/NewProduct'
import { t } from 'src/localization'
import moment from 'moment'
import { TypeOrderItem } from './GoodsReceivedForm'
import { RouterOutput, trpc } from 'src/trpc'

const validationSchema = (t, isGoodsRemoved) =>
  Yup.object().shape({
    inQty: Yup.string()
      .required('Količina je obavezna')
      .matches(
        /^\d+$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validOutQty',
        'Količina mora manja ili jednaka količini na skladištu',
        function (value) {
          const { parent } = this

          return isGoodsRemoved && !!value
            ? parseFloat(value) <= parseFloat(parent?.stockCount)
            : true
        }
      )
      .test('validInQty', 'Količina mora biti veća od 0', function (value) {
        return !value ? false : parseFloat(value) > 0
      }),
    productId: Yup.string().required('Proizvod je obavezan').nullable(),
    purchasePrice: Yup.string()
      .required('Nabavna cijena je obavezna')
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validPurchasePrice',
        'Nabavna cijena mora biti veća od 0',
        function (value) {
          return !value ? false : parseFloat(value) > 0
        }
      ),
    listPrice: Yup.string()
      .required('Cijena iz kataloga je obavezna')
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validListPrice',
        'Cijena iz katolaga mora biti veća od 0',
        function (value) {
          return !value ? false : parseFloat(value) > 0
        }
      ),
    listDiscountRate: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
  })

type TypeProductForm = {
  showProductsModal: boolean
  setShowProductsModal: (arg0: boolean) => void
  initialValues: TypeOrderItem
  setSelectedItem: any
  outerFormRef: any
  innerFormRef: any
}

export const ProductForm = (props: TypeProductForm) => {
  const {
    showProductsModal,
    setShowProductsModal,
    initialValues,
    setSelectedItem,
    outerFormRef,
    innerFormRef,
  } = props

  const { t, appServices, navigateTo, useParams } = useSdk()

  let inQtyRef = useRef<any>()
  const params = useParams<{
    orderId: string
    orgId: string
    locationId: string
  }>()
  const { orgId, orderId, locationId } = params
  const { pathname } = useLocation()
  const isGoodsRemoved =
    pathname.endsWith(`/wms/goodsRemoved/new`) ||
    pathname.includes(`/wms/goodsRemoved/edit`)

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get
  const hasVat = org?.vatStatus === 'Y'

  const [createOrderMutation, { loading: loadingCreateOrder }] =
    useWmsOrderCreateMutation()
  const [createItemMutation, { loading: loadingCreateItem }] =
    useWmsOrderItemCreateMutation()
  const [updateItemMutation, { loading: loadingUpdateItem }] =
    useWmsOrderItemUpdateMutation()
  const [deleteItemMutation, { loading: loadingDeleteItem }] =
    useWmsOrderItemDeleteMutation()

  const handleDeleteItem = async (id: number) => {
    let result = await deleteItemMutation({
      variables: {
        orderId: Number(orderId),
        id: Number(id),
      },
      refetchQueries: [
        {
          query: WmsOrderGetDocument,
          variables: { id: orderId },
        },
      ],
    })

    appServices
      .handleMutationResult(result, 'Stavka je uspješno obrisana')
      .onSuccess(() => {
        setSelectedItem(null)
      })
  }

  const createOrder = async () => {
    if (!outerFormRef?.current.isValid)
      throw Object.values(outerFormRef?.current?.errors)[0]
    else {
      const input = {
        supplierId: outerFormRef?.current?.values?.supplier?.id || null,
        warehouseId: outerFormRef?.current?.values?.warehouse?.id,
        orderDate: moment(outerFormRef?.current?.values.orderDate).format(
          'YYYY-MM-DD'
        ),
        refNo: outerFormRef?.current?.values?.refNo,
        invoiceNo: outerFormRef?.current?.values?.invoiceNo,
        handlingCosts:
          parseFloat(outerFormRef?.current?.values?.handlingCosts) || 0,
        warehouseType: 'RETAIL',
        documentType: isGoodsRemoved ? 'GOODS_REMOVED' : 'GOODS_RECEIVED',
      }

      let result = await createOrderMutation({
        variables: { input },
        refetchQueries: [
          {
            query: WmsOrderListDocument,
            variables: {
              input: {
                searchTerm: '',
                documentType: isGoodsRemoved
                  ? 'GOODS_RELEASED'
                  : 'GOODS_RECEIVED',
                fetch: {
                  cursor: null,
                  limit: 40,
                },
              },
            },
          },
          {
            query: WmsGetActiveWarehouseDocument,
            variables: {
              warehouseType: 'RETAIL',
            },
          },
        ],
      })

      if (!result?.errors) {
        appServices.toast.success(
          isGoodsRemoved
            ? 'Izlaz je uspješno kreiran'
            : 'Ulaz je uspješno kreiran'
        )
        outerFormRef?.current?.resetForm()
        return result?.data?.wmsOrder_create?.id
      } else throw 'Nešto je pošlo po zlu!'
    }
  }

  const handleSubmit = async values => {
    try {
      await outerFormRef.current.validateForm()

      if (!outerFormRef.current?.isValidating) {
        const input = {
          orderId:
            pathname.endsWith(`/wms/goodsReceived/new`) ||
            pathname.endsWith(`/wms/goodsRemoved/new`)
              ? Number(await createOrder())
              : Number(orderId),
          //TODO: trieba sequence risiti
          sequence: 1,
          productId: Number(values?.productId),
          inQty: isGoodsRemoved ? 0 : parseFloat(values?.inQty),
          outQty: isGoodsRemoved
            ? parseFloat(values?.inQty)
            : parseFloat(values?.outQty),
          uom: values?.uom,
          listPrice: parseFloat(
            values?.listPrice?.toString().replace(',', '.')
          ),
          listDiscountRate:
            parseFloat(values?.listDiscountRate?.toString().replace(',', '.')) /
            100,
          purchasePrice: values.purchasePrice
            ? parseFloat(values.purchasePrice?.toString().replace(',', '.'))
            : parseFloat('0'),
          retailMarginRate: parseFloat(values?.retailMarginRate) / 100,
          retailPrice: parseFloat(values?.retailPrice),
          purchaseAmount: parseFloat(values?.purchaseAmount),
          retailAmount: parseFloat(values?.retailAmount),
          unitMargin: parseFloat(values?.unitMargin),
          retailMarginAmount: parseFloat(values?.retailMarginAmount),
          vatRate: hasVat ? parseFloat(values?.vatRate) / 100 : 0,
          unitVatAmount: hasVat ? parseFloat(values?.unitVatAmount) : 0,
        }

        let result =
          values?.id === 'new'
            ? await createItemMutation({
                variables: {
                  input,
                },
                refetchQueries: [
                  {
                    query: WmsOrderGetDocument,
                    variables: { id: orderId },
                  },
                ],
              })
            : await updateItemMutation({
                variables: {
                  id: Number(values?.id),
                  input,
                },
                refetchQueries: [
                  {
                    query: WmsOrderGetDocument,
                    variables: { id: orderId },
                  },
                ],
              })

        appServices
          .handleMutationResult(
            result,
            values?.id === 'new' ? 'Stavka kreirana' : 'Stavka promijenjena'
          )
          .onSuccess(() => {
            if (pathname.endsWith(`/wms/goodsReceived/new`))
              navigateTo.editGoodsReceived({ id: input?.orderId })
            else if (isGoodsRemoved) {
              navigateTo.editGoodsRemoved({ id: input?.orderId })
            }
            setSelectedItem(null)
            outerFormRef?.current?.resetForm()
          })
      }
    } catch (e) {
      appServices.toast.danger(e)
    }
  }

  const unitsOfMeasure = [
    { id: 'PC', title: 'Komad' },
    { id: 'KG', title: 'Kilogram' },
    { id: 'LIT', title: 'Litra' },
    { id: 'M2', title: 'Kvadratni metar' },
    { id: 'M3', title: 'Kubni metar' },
  ]

  const handleValueChange = (name, value, values, form) => {
    const { setFieldValue } = form

    const listPrice = parseFloat(values?.listPrice || '0')
    const purchasePrice = parseFloat(values?.purchasePrice || '0')
    const listDiscountRate = parseFloat(values?.listDiscountRate || '0')
    const vatRate = hasVat ? parseFloat(values?.vatRate || '0') / 100 : 0
    const retailPrice = parseFloat(values?.retailPrice || '0')
    const inQty = parseFloat(values?.inQty || '1')

    if (
      name === 'listPrice' ||
      name === 'productId' ||
      name === 'listDiscountRate' ||
      name === 'inQty' ||
      name === 'purchasePrice'
    ) {
      if (hasVat) {
        const unitVatAmount = roundNumberWithTwoDecimals(
          retailPrice - retailPrice / (1 + vatRate)
        )
        setFieldValue('unitVatAmount', unitVatAmount)
        setFieldValue(
          'unitMargin',
          roundNumberWithTwoDecimals(
            retailPrice / (1 + vatRate) - purchasePrice
          )
        )
        setFieldValue(
          'retailMarginRate',
          roundNumberWithTwoDecimals(
            ((retailPrice - unitVatAmount) / purchasePrice - 1) * 100
          )
        )
        setFieldValue(
          'retailMarginAmount',
          roundNumberWithTwoDecimals(
            inQty * (retailPrice - unitVatAmount - purchasePrice)
          )
        )
      } else {
        setFieldValue('unitVatAmount', 0)
        setFieldValue(
          'unitMargin',
          roundNumberWithTwoDecimals(retailPrice - purchasePrice)
        )
        setFieldValue(
          'retailMarginRate',
          roundNumberWithTwoDecimals((retailPrice / purchasePrice - 1) * 100)
        )
        setFieldValue(
          'retailMarginAmount',
          roundNumberWithTwoDecimals(inQty * (retailPrice - purchasePrice))
        )
      }

      setFieldValue(
        'retailAmount',
        roundNumberWithTwoDecimals(retailPrice * inQty)
      )
      setFieldValue(
        'purchaseAmount',
        roundNumberWithTwoDecimals(purchasePrice * inQty)
      )
    }
  }

  const { data: productStockData, isLoading: productStockIsLoading } =
    !!initialValues?.product
      ? trpc.wmsProductCurrentStock.useQuery({
          id: BigInt(initialValues?.product?.id || 0),
          orgId: orgId,
          locationId: BigInt(locationId),
        })
      : { data: null, isLoading: false }
  const productStock = !!productStockData ? (productStockData[0] as any) : null

  if (loadingOrgData || productStockIsLoading) return <Loader isComponent />

  return (
    <FormPageContainer className="m-[40px_20px]">
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          listDiscountRate: (
            (initialValues?.listDiscountRate || 0) * 100
          )?.toString(),
          currencyId: org?.currency?.id,
          purchasePrice: initialValues?.purchasePrice?.toString(),
          retailPrice: initialValues?.retailPrice?.toString(),
          vatRate: (initialValues?.vatRate || 0) * 100,
          retailMarginRate: roundNumberWithTwoDecimals(
            (initialValues?.retailMarginRate || 0) * 100
          ),
          stockCount: productStock?.stockCount || 0,
        }}
        onValueChange={handleValueChange}
        validationSchema={validationSchema(t, isGoodsRemoved)}
      >
        {form => {
          innerFormRef.current = form
          return (
            <FormFieldsWrapper>
              <div className="bg-[#f4f4ff96] p-3 rounded-sm border border-zoyya-grayLight">
                <FieldRow>
                  <FieldColumn className="max-w-[240px]">
                    <div className="">
                      <p className="font-medium mb-3">
                        Količina (
                        {unitsOfMeasure.find(i => i.id === form.values?.uom)
                          ?.title || ''}
                        )
                      </p>
                      <div className="flex">
                        <button
                          disabled={form.values.inQty <= 1}
                          onClick={() => {
                            form.setFieldValue(
                              'inQty',
                              Number(form.values?.inQty) - 1
                            )
                          }}
                          type="button"
                          className={`w-[47.5px] h-[47.5px] mr-[8px] rounded-[4px] bg-zoyya-grayDarker ${
                            form.values.inQty <= 1
                              ? 'opacity-[0.3]'
                              : 'opacity-[1]'
                          }`}
                        >
                          <span className="text-[20px]">-</span>
                        </button>

                        <FormField.Text
                          label={''}
                          name="inQty"
                          autoFocus
                          ref={inQtyRef}
                        />

                        <button
                          onClick={() => {
                            form.setFieldValue(
                              'inQty',
                              Number(form.values?.inQty) + 1
                            )
                          }}
                          disabled={
                            isGoodsRemoved
                              ? form.values?.inQty >= form.values?.stockCount
                              : false
                          }
                          type="button"
                          className={`w-[47.5px] h-[47.5px] ml-[8px] rounded-[4px] bg-zoyya-grayDarker`}
                        >
                          <span className="text-[20px]">+</span>
                        </button>
                      </div>
                    </div>
                  </FieldColumn>

                  <FieldColumn className="flex-1 ml-[14px]">
                    <div className="flex-1">
                      <p className="font-medium mb-3">Proizvod</p>
                      <div
                        className={`h-[43px] border border-zoyya-grayLight rounded-sm flex items-center px-2 relative justify-between ${
                          form.errors?.productId
                            ? 'border-zoyya-red text-zoyya-red bg-zoyya-redLight'
                            : 'border-zoyya-grayLight text-zoyya-text bg-white'
                        }`}
                        onClick={() => setShowProductsModal(true)}
                      >
                        {form?.values?.product?.name ? (
                          <>
                            <span>{form?.values?.product?.name}</span>

                            <span className="text-zoyya-secondaryDark">
                              {`šifra: ${form?.values?.product?.code}`}
                            </span>
                          </>
                        ) : (
                          <span className="text-zoyya-secondaryDark">
                            Odaberi proizvod...
                          </span>
                        )}

                        {form.errors?.productId ? (
                          <div className="flex left-[calc(100%-56px)] top-[calc(50%-8px)] absolute z-8 items-center justify-center min-w-[28px] w-[28px] text-zoyya-red">
                            <ToolTip label={form.errors?.productId}>
                              <Warning size="smaller" />
                            </ToolTip>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </FieldColumn>
                </FieldRow>

                <FieldRow>
                  <FieldColumn>
                    <FormField.Text
                      label={'Cijena iz kataloga'}
                      name="listPrice"
                      textAlignEnd
                      disabled={isGoodsRemoved}
                      onChange={e => {
                        form.setFieldValue(
                          'purchasePrice',
                          !!parseFloat(form?.values?.listDiscountRate)
                            ? roundNumberWithTwoDecimals(
                                parseFloat(
                                  form?.values?.listPrice
                                    .toString()
                                    .replace(',', '.') || 0
                                ) -
                                  parseFloat(
                                    form?.values?.listPrice.replace(',', '.')
                                  ) *
                                    (parseFloat(
                                      form?.values?.listDiscountRate
                                        .toString()
                                        .replace(',', '.') || 0
                                    ) /
                                      100)
                              )
                            : e
                        )
                        form.validateForm()
                      }}
                    />
                  </FieldColumn>

                  <FieldColumn>
                    <FormField.Text
                      label={'Rabat (%)'}
                      name="listDiscountRate"
                      textAlignEnd
                      disabled={isGoodsRemoved}
                      onChange={e => {
                        const discount =
                          parseFloat(
                            form?.values.listPrice?.toString().replace(',', '.')
                          ) *
                          (parseFloat(
                            form?.values?.listDiscountRate
                              .toString()
                              .replace(',', '.') || 0
                          ) /
                            100)

                        form.setFieldValue(
                          'purchasePrice',
                          roundNumberWithTwoDecimals(
                            !!parseFloat(form?.values?.listDiscountRate)
                              ? parseFloat(
                                  form?.values.listPrice
                                    ?.toString()
                                    .replace(',', '.')
                                ) - discount
                              : parseFloat(
                                  form?.values.listPrice
                                    ?.toString()
                                    .replace(',', '.')
                                )
                          )
                        )
                        form.validateForm()
                      }}
                    />
                  </FieldColumn>

                  <FieldColumn>
                    <FormField.Text
                      label={'Nabavna cijena'}
                      name="purchasePrice"
                      disabled={isGoodsRemoved}
                      textAlignEnd
                      onChange={e => {
                        const newValue = !!parseFloat(
                          form?.values?.listDiscountRate
                        )
                          ? parseFloat(
                              form?.values?.purchasePrice
                                ?.toString()
                                .replace(',', '.')
                            ) /
                            ((100 -
                              parseFloat(
                                form?.values?.listDiscountRate
                                  .toString()
                                  .replace(',', '.') || 0
                              )) /
                              100)
                          : 0

                        form.setFieldValue(
                          'listPrice',
                          !!parseFloat(form?.values?.listDiscountRate)
                            ? Math.round((newValue + Number.EPSILON) * 100) /
                                100
                            : form?.values?.purchasePrice
                        )
                        form.validateForm()
                      }}
                    />
                  </FieldColumn>

                  <FieldColumn>
                    <FormField.Text
                      label={'Prodajna cijena'}
                      name="retailPrice"
                      disabled
                      textAlignEnd
                    />
                  </FieldColumn>
                </FieldRow>

                <FieldRow>
                  {hasVat ? (
                    <FieldColumn>
                      <FormField.Text
                        label={'Stopa PDV'}
                        name="vatRate"
                        disabled
                        textAlignEnd
                      />
                    </FieldColumn>
                  ) : null}
                  <FieldColumn>
                    <FormField.Text
                      label={'Marža (%)'}
                      name="retailMarginRate"
                      disabled
                      textAlignEnd
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <FormField.Text
                      label={'Iznos marže'}
                      name="retailMarginAmount"
                      textAlignEnd
                      disabled
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <FormField.Text
                      label={'Prodajna vrijednost'}
                      name="retailAmount"
                      textAlignEnd
                      disabled
                    />
                  </FieldColumn>
                </FieldRow>

                <div
                  className={`flex gap-2 mt-10 ${
                    initialValues?.id !== 'new'
                      ? 'justify-center'
                      : 'justify-end'
                  }`}
                >
                  {initialValues?.id !== 'new' ? (
                    <Button
                      type="button"
                      buttonType={'primaryAccent'}
                      label={'Obriši stavku'}
                      onClick={() => {
                        handleDeleteItem(Number(form.values.id))
                      }}
                      size="small"
                      iconComponent={<icons.Trash size="smaller" />}
                      style={{ marginRight: 'auto' }}
                    />
                  ) : null}
                  <Button
                    type="button"
                    buttonType={'secondary'}
                    label={'Odustani'}
                    onClick={() => {
                      setSelectedItem(null)

                      if (initialValues?.id === 'new')
                        outerFormRef.current.setFieldValue(
                          'items',
                          outerFormRef.current.values?.items?.filter(
                            x => x?.id !== 'new'
                          )
                        )
                    }}
                    size="medium"
                  />
                  <Button
                    type="button"
                    buttonType={'primary'}
                    label={'Spremi'}
                    onClick={() => {
                      form.submitForm()
                    }}
                    size="medium"
                  />
                </div>
              </div>
            </FormFieldsWrapper>
          )
        }}
      </Form>

      {showProductsModal ? (
        <ProductsModal
          setShowProductsModal={setShowProductsModal}
          currency={org?.currency?.id}
          formRef={innerFormRef}
          inQtyRef={inQtyRef}
        />
      ) : null}
    </FormPageContainer>
  )
}

type TypeProductsModal = {
  setShowProductsModal: (arg0: boolean) => void
  currency: string
  formRef: any
  inQtyRef: any
}

const ProductsModal = (props: TypeProductsModal) => {
  const { setShowProductsModal, currency, formRef, inQtyRef } = props

  const [showAddProductForm, setShowAddProductForm] = useState<boolean>(false)
  const [filteredServiceTypes, setFilteredServiceTypes] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const { pathname } = useLocation()

  const { data: productTypes, loading: productTypesLoading } =
    useProductTypeAllQuery()
  const {
    data: productTypesCurrentStock,
    loading: productTypesCurrentStockLoading,
  } = useProductTypeCurrentStockQuery({
    fetchPolicy: 'network-only',
  })
  const productsAll = pathname.includes(`/wms/goodsRemoved`)
    ? productTypesCurrentStock?.productType_currentStock?.filter(
        x => x?.products?.length
      )
    : productTypes?.productType_all?.filter(x => x.products?.length)

  const globalSearchMemo = useMemo(() => {
    return (searchInput, serviceTypes) => {
      if (searchInput === '') {
        return serviceTypes
      }
      let filteredData = serviceTypes
        ?.filter(element => {
          return element.products.some(service =>
            searchInput
              ? service.name
                  ?.toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                service.code?.toLowerCase().includes(searchInput.toLowerCase())
              : service
          )
        })
        .map(element => {
          let newService = Object.assign({}, element) // copies element
          return {
            ...element,
            products: newService.products.filter(service =>
              searchInput
                ? service.name
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                  service.code
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase())
                : service
            ),
          }
        })

      return filteredData
    }
  }, [productTypes, productTypesCurrentStock])

  const debouncedFiltering = useMemo(() => {
    return debounce(text => {
      if (text === null) {
        setFilteredServiceTypes(productsAll)
      }
      const filteredData = globalSearchMemo(text || '', productsAll)
      setFilteredServiceTypes(filteredData)
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)
    }, 0)
  }, [globalSearchMemo])

  useEffect(() => {
    debouncedFiltering(searchValue)
  }, [searchValue, debouncedFiltering])

  return (
    <div
      className="absolute bg-black/50 z-[9999] w-full h-full top-0 right-0 flex justify-center items-center"
      ref={popupRef}
      onClick={e => {
        if (e?.target !== popupRef?.current) return
        setShowProductsModal(false)
      }}
    >
      <div className="w-11/12 h-5/6 bg-white rounded-lg flex flex-col overflow-hidden">
        <div className="flex items-center">
          <Button
            type="button"
            buttonType={'text'}
            onClick={() => {
              setShowProductsModal(false)
              setSearchValue('')
            }}
            size="small"
            iconComponent={<icons.ArrowLeft />}
            style={{
              paddingLeft: 15,
              paddingRight: 0,
              margin: '0 5px',
            }}
          />

          {!showAddProductForm ? (
            <>
              <ServiceSelect
                placeholder="Pretraži..."
                onChange={e => setSearchValue(e.target.value)}
                ref={inputRef}
                value={searchValue}
                autoFocus={true}
              ></ServiceSelect>
              <ButtonTW
                label="+ Dodaj proizvod"
                variant="primaryOutline"
                className="flex-0 mr-[8px]"
                onClick={() => setShowAddProductForm(true)}
              />
            </>
          ) : (
            <p className=" text-[18px]">Novi proizvod</p>
          )}
        </div>

        {showAddProductForm ? (
          <NewProduct setShowAddProductForm={setShowAddProductForm} />
        ) : productTypesLoading || productTypesCurrentStockLoading ? (
          <Loader isComponent />
        ) : filteredServiceTypes?.length ? (
          <div className="overflow-auto px-3 flex-1">
            {filteredServiceTypes
              ?.sort(
                (a, b) =>
                  Number(a?.sequence)
                    ?.toString()
                    ?.localeCompare(Number(b.sequence).toString(), undefined, {
                      numeric: true,
                    })
              )
              ?.map(type => {
                return (
                  <div key={type?.id}>
                    <p className="uppercase text-sm text-zoyya-secondaryDark ml-4 mt-3 font-semibold">
                      {type.name}
                    </p>
                    <div>
                      {type?.products
                        ?.slice()
                        ?.sort(
                          (a, b) =>
                            Number(a?.sequence)
                              ?.toString()
                              ?.localeCompare(
                                Number(b.sequence).toString(),
                                undefined,
                                {
                                  numeric: true,
                                }
                              )
                        )
                        ?.map(item => {
                          return (
                            <button
                              type="button"
                              key={item?.id}
                              className="cursor-pointer hover:bg-zoyya-lightBackground px-2 rounded-md w-full flex flex-1 my-1 p-1 focus-visible:bg-zoyya-lightBackground  border border-transparent focus-visible:border focus-visible:border-purple-500 outline-0"
                              onClick={async () => {
                                setSearchValue('')
                                setShowProductsModal(false)

                                formRef.current.setFieldValue('product', {
                                  id: item?.id,
                                  name: item?.name,
                                  uom: item?.uom,
                                  code: item?.code,
                                })

                                formRef.current.setFieldValue(
                                  'productId',
                                  item?.id
                                )
                                formRef.current.setFieldValue('uom', item?.uom)
                                formRef.current.setFieldValue(
                                  'retailPrice',
                                  item.retailPrice
                                )
                                formRef.current.setFieldValue(
                                  'vatRate',
                                  parseFloat(item.vatRate || '0') * 100
                                )

                                item.listPrice &&
                                  formRef.current.setFieldValue(
                                    'listPrice',
                                    item.listPrice
                                  )
                                item.listDiscountRate &&
                                  formRef.current.setFieldValue(
                                    'listDiscountRate',
                                    parseFloat(item.listDiscountRate) * 100
                                  )
                                item.purchasePrice &&
                                  formRef.current.setFieldValue(
                                    'purchasePrice',
                                    item.purchasePrice
                                  )
                                formRef.current.setFieldValue(
                                  'stockCount',
                                  item?.stockCount || 0
                                )
                                formRef.current.validateForm()
                                inQtyRef?.current?.focus()
                              }}
                            >
                              <div
                                style={{
                                  minWidth: 200,
                                  marginRight: 'auto',
                                  textAlign: 'start',
                                }}
                              >
                                <div>{item.name}</div>

                                <div className="text-sm text-gray-500 tracking-wider">
                                  šifra: {item?.code}
                                </div>
                              </div>
                              {pathname.includes(`/wms/goodsRemoved`) ? (
                                <p>{`Dostupno: ${item?.stockCount}`}</p>
                              ) : null}
                              <p
                                style={{
                                  minWidth: 50,
                                  textAlign: 'right',
                                }}
                              >
                                {item.uom === 'PC'
                                  ? t('translation.ProductList.uom-pc')
                                  : item.uom}
                              </p>

                              <p
                                style={{
                                  marginRight: 0,
                                  minWidth: 150,
                                  textAlign: 'end',
                                }}
                              >
                                {formatPriceNumberWithDelimiter(
                                  item.retailPrice
                                ) +
                                  ' ' +
                                  currency}
                              </p>
                            </button>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
        ) : (
          <EmptyList title="Nema rezultata pretrage" />
        )}
      </div>
    </div>
  )
}
