import gql from 'graphql-tag'
import { ResourceFragmentGql } from './fragments'

export const resourceAddGql = gql`
  mutation addNewResource($input: ResourceInput, $file: Upload) {
    resource {
      add(input: $input, file: $file) {
        payload {
          ...ResourceFragment
        }
      }
    }
  }
  ${ResourceFragmentGql}
`

export const resourceUpdateGql = gql`
  mutation updateResource($input: ResourceInput!, $file: Upload) {
    resource {
      update(input: $input, file: $file) {
        payload {
          ...ResourceFragment
        }
      }
    }
  }
  ${ResourceFragmentGql}
`

export const resourceSearchGql = gql`
  query queryResources {
    resource {
      search {
        ...ResourceFragment
      }
    }
  }
  ${ResourceFragmentGql}
`

export const resourceGetGql = gql`
  query resourceGetGql($id: ID!) {
    resource {
      get(id: $id) {
        ...ResourceFragment
      }
    }
  }
  ${ResourceFragmentGql}
`

export const locationGetGql = gql`
  query getLocation($id: ID!) {
    location {
      get(id: $id) {
        prepaymentsConfig {
          prepaymentsEnabled
          hoursBeforeCancellationPeriod
          advanceAmountPercentage
          prepaymentsDeviceId
          userId
          operatorId
          prepaymentServiceId
        }
        id
        code
        name
        description
        imageUrl
        orgId
        kind
        timeSlotDuration
        onlineBooking_status
        onlineBooking_showPrices
        onlineBooking_showAddress
        onlineBooking_showEmail
        onlineBooking_showPhone
        onlineBooking_listOnZoyya
        onlineBooking_bookingFlow
        onlineBooking_showPriceList
        onlineBooking_requireOtpValidation
        notifications_emailReminder
        notifications_emailReviewRequest
        notifications_smsReviewRequest
        notifications_b2b_sendAppointmentEmails
        sendAppointmentEmailsToClients
        sendAppointmentSmsToClients
        isRwgEnabled
        localizationNamespace
        slug
        categories {
          id
          icon_url
          name
        }
        workWeek {
          mon {
            openTime
            closeTime
          }
          tue {
            openTime
            closeTime
          }
          wed {
            openTime
            closeTime
          }
          thu {
            openTime
            closeTime
          }
          fri {
            openTime
            closeTime
          }
          sat {
            openTime
            closeTime
          }
          sun {
            openTime
            closeTime
          }
        }
        mobilePhone
        phone
        email
        city
        zipCode
        address
        lat
        lng
        facebookUrl
        instagramUrl
        emailNote
        country {
          id
          countryCode
          countryName
          currency {
            id
            currencyCode
            currencyName
          }
        }
        resources {
          id
          name
          code
          firstName
          lastName
          email
          mobilePhone
          nickName
          role
          gender
          kind
          color
          showInCalendar
          receiveAppointmentEmails
          isLocked
          avatarUrl
          type {
            id
            name
          }
          locationSettings {
            onlineBooking
          }
          professions {
            id
            name
          }
          user {
            id
            firstName
            lastName
            address
            email
            role
            phone
            gender
            nickName
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
          hasOrgMembership
        }
        showBookingReminder
        notifications_smsReminder
        bookingKind
        fbPixelId
        googleAnalyticsId
      }
    }
  }
`

export const workingHoursGetGql = gql`
  query getLocationWorkHours($id: ID) {
    location {
      get(id: $id) {
        workWeek {
          mon {
            openTime
            closeTime
          }
          tue {
            openTime
            closeTime
          }
          wed {
            openTime
            closeTime
          }
          thu {
            openTime
            closeTime
          }
          fri {
            openTime
            closeTime
          }
          sat {
            openTime
            closeTime
          }
          sun {
            openTime
            closeTime
          }
        }
      }
    }
  }
`

export const inviteUserGql = gql`
  mutation inviteUser($input: InvitationInput!) {
    invitation {
      add(input: $input) {
        status {
          success
        }
        payload {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`
export const deleteServiceTypeGql = gql`
  mutation deleteServiceType($id: ID) {
    serviceTypes {
      delete(id: $id) {
        status {
          success
        }
      }
    }
  }
`

export const deleteClientGql = gql`
  mutation deleteClient($id: ID) {
    clients {
      delete(id: $id) {
        status {
          success
        }
      }
    }
  }
`

export const deleteResourceGql = gql`
  mutation deleteResource($id: ID) {
    resource {
      delete(id: $id) {
        status {
          success
        }
      }
    }
  }
`

export const addServiceTypeGql = gql`
  mutation addServiceType($input: ServiceTypeInput) {
    serviceTypes {
      add(input: $input) {
        status {
          success
        }
        payload {
          id
          name
          description
          services {
            id
            name
            description
            color
            price
            vatRate
            durationMinutes
            bookingAllowed
            profession {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const searchServiceTypesGql = gql`
  query searchServiceTypes($locationId: ID) {
    serviceType {
      search(locationId: $locationId) {
        id
        name
        description
        services {
          id
          sequence
          name
          description
          color
          price
          vatRate
          durationMinutes
          bookingAllowed
          hasBookingConfirmation
          profession {
            id
            name
          }
          resourceType {
            id
            name
          }
        }
      }
    }
  }
`
export const getResourceLocationSettingsGql = gql`
  query getResourceLocationSettings($resourceId: ID, $locationId: ID) {
    resource {
      getResourceLocationSettings(
        resourceId: $resourceId
        locationId: $locationId
      ) {
        resourceId
        locationId
        onlineBooking
      }
    }
  }
`
export const updateResourceLocationSettingsGql = gql`
  mutation updateResourceLocationSettings(
    $resourceId: ID!
    $locationId: ID!
    $onlineBooking: OnLineBookingSettingEnum
  ) {
    resource {
      updateResourceLocationSettings(
        input: {
          resourceId: $resourceId
          locationId: $locationId
          onlineBooking: $onlineBooking
        }
      ) {
        resourceId
        locationId
        onlineBooking
      }
    }
  }
`
