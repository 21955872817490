import React from 'react'
import { cls } from 'src/helpers'

export const FieldRow = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}) => {
  return (
    <div
      className={cls(
        `flex lg:items-center lg:justify-between lg:mb-4 w-full mb-0 p-1 lg:p-0 flex-col lg:flex-row ${
          className ? className : ''
        }`
      )}
      style={style}
    >
      {children}
    </div>
  )
}
