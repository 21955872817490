import React, { useState, useEffect, useRef } from 'react'
import {
  FormFooterButtonsContainerMobile,
  ContainerForFixedFooterButtons,
  HelpHint,
} from 'src/styles'
import { Button, Loader } from 'components'
import { getDayName, getHoursAndMinutes } from 'src/helpers'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { useHeader } from 'mobile/Header'
import {
  useLocationUpdateWorkWeekMutation,
  useLocationWorkHoursGetQuery,
} from 'state/graphql'
import { Form, FormContentContainer, FormField } from 'components'
import styled from 'styled-components'
import { EventContainer } from 'views/AppointmentModal/styles'
import { FieldRow, FieldColumn } from 'views/Settings/styles'
import { icons } from 'components/Icon/index'
import WorkDayMobile from 'mobile/Settings/WorkHours/WorkDayMobile'
import { isEqual } from 'lodash'

const LocationWorkHoursMobile = () => {
  const { navigateTo, appServices, t, apolloClient } = useSdk()
  const params = useParams<any>()
  const { selectedLocationId } = params
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isNewTime, setIsNewTime] = useState<boolean>(false)
  const [selectedDay, setSelectedDay] = useState<any>()
  const [selectedShift, setSelectedShift] = useState<{
    openTime: any
    closeTime: any
    index: number
  }>()

  useHeader({
    content: t('translation.SelectedLocation.label-workHours'),
    onGoBack: navigateTo.mobileBranchSettings,
  })
  const [updateLocationShifts] = useLocationUpdateWorkWeekMutation()
  const { data, loading, error } = useLocationWorkHoursGetQuery({
    variables: {
      locationId: selectedLocationId,
    },
    skip: !selectedLocationId,
  })
  const [isMondayChanged, setIsMondayChanged] = useState<any>(false)
  const [workHours, setWorkHours] = useState<any>()
  const location = data?.location?.get

  const { hours, minutes } = getHoursAndMinutes(
    location?.timeSlotDuration || 5,
    true
  )

  useEffect(() => {
    if (workHours) {
      setIsMondayChanged(!isEqual(location?.workWeek?.mon, workHours[0]?.shift))
    }
  }, [workHours])

  useEffect(() => {
    if (error) {
      appServices.toast.danger(
        t('translation.LocationWorkHours.toast-request-fail')
      )
    }

    const shifts = [
      {
        day: 'mon',
        shift: location?.workWeek?.mon,
      },
      {
        day: 'tue',
        shift: location?.workWeek?.tue,
      },
      {
        day: 'wed',
        shift: location?.workWeek?.wed,
      },
      {
        day: 'thu',
        shift: location?.workWeek?.thu,
      },
      {
        day: 'fri',
        shift: location?.workWeek?.fri,
      },
      {
        day: 'sat',
        shift: location?.workWeek?.sat,
      },
      {
        day: 'sun',
        shift: location?.workWeek?.sun,
      },
    ]
    setWorkHours(shifts)
  }, [loading])

  const handleSubmit = values => {
    setWorkHours(
      workHours.map(item => {
        if (item.day === selectedDay?.day) {
          item.shift = isNewTime
            ? [
                ...item.shift,
                {
                  openTime: values.openTime,
                  closeTime: values.closeTime,
                  __typename: 'BusinessHours',
                },
              ]
            : item?.shift?.map((item, index) => {
                return selectedShift?.index === index
                  ? {
                      openTime: values?.openTime,
                      closeTime: values?.closeTime,
                      __typename: 'BusinessHours',
                    }
                  : item
              })
        }
        return item
      })
    )
    setIsOpen(false)
  }

  const initialValues = isNewTime
    ? {
        openTime: selectedDay?.shift[0]?.closeTime,
        closeTime: selectedDay?.shift[0]?.closeTime,
      }
    : selectedShift

  const handleSaveChanges = async () => {
    const result = await updateLocationShifts({
      variables: {
        input: {
          mon: workHours[0]?.shift || [],
          tue: workHours[1]?.shift || [],
          wed: workHours[2]?.shift || [],
          thu: workHours[3]?.shift || [],
          fri: workHours[4]?.shift || [],
          sat: workHours[5]?.shift || [],
          sun: workHours[6]?.shift || [],
        },
      },
    })

    appServices
      .handleMutationResult(
        result,
        t('translation.LocationWorkHoursMobile.toast-work-hours-changed')
      )
      .onSuccess(async () => {
        await apolloClient.cache.reset()
      })
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef<any>(null)
  useOutsideAlerter(wrapperRef)

  if (loading) return <Loader isComponent />

  return (
    <>
      <ContainerForFixedFooterButtons>
        {workHours?.map(day => (
          <WorkDayMobile
            key={day?.day}
            day={day}
            workHours={workHours}
            setWorkHours={setWorkHours}
            setIsOpen={setIsOpen}
            setIsNewTime={setIsNewTime}
            setSelectedShift={setSelectedShift}
            setSelectedDay={setSelectedDay}
            isMondayChanged={isMondayChanged}
          />
        ))}
        <HelpHint style={{ paddingBottom: '20px' }}>
          <icons.Info
            size="extraSmall"
            stroke={2}
            style={{ marginRight: '5px' }}
          />
          {t('translation.LocationWorkHours.label-learn-more-about')}
          <a
            href={t('translation.HelpLinks.work-hours')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('translation.LocationWorkHours.label-work-hours')}.
          </a>
        </HelpHint>
      </ContainerForFixedFooterButtons>

      <FormFooterButtonsContainerMobile>
        <Button
          onClick={navigateTo.mobileBranchSettings}
          buttonType={'text'}
          footerButton
          name="close"
          data-cy="button_closeForm"
          label={t('translation.LocationGeneralInfo.button-close')}
        />
        <Button
          footerButton
          buttonType={'primary'}
          label={t('translation.LocationWorkHoursMobile.button-save')}
          name="saveChanges"
          fullWidth
          onClick={handleSaveChanges}
          size={'large'}
        />
      </FormFooterButtonsContainerMobile>

      {isOpen ? (
        <div className="z-[99999999999] absolute top-0 h-full w-full bg-[rgba(0,_0,_0,_0.5)]">
          <FooterOptions ref={wrapperRef}>
            <Form
              onSubmit={values => {
                const workingTimes = values?.time?.valueText?.split(' ')
                handleSubmit({
                  openTime: workingTimes[0],
                  closeTime: workingTimes[2],
                })
              }}
              initialValues={{
                time: {
                  index: 0,
                  valueText: `${initialValues?.openTime} - ${initialValues?.closeTime}`,
                },
              }}
            >
              {({ values }) => {
                return (
                  <FormContentContainer>
                    <div className="flex items-center justify-center w-full top-[2px] bg-[transparent] z-[9] relative p-[10px_10px_15px] mb-0 border-b-[1px_solid_#e9eaea]">
                      <div className="text-zoyya-text flex items-center text-[18px] p-[5px_0px_0px_5px] font-medium">
                        {getDayName(selectedDay?.day)
                          .charAt(0)
                          .toUpperCase() +
                          getDayName(selectedDay?.day)
                            .slice(1)
                            .toLowerCase()}
                      </div>
                    </div>
                    <EventContainer>
                      <FieldRow>
                        <FieldColumn>
                          <FormField.TimeInput
                            hours={hours}
                            minutes={minutes}
                            name={'time'}
                            initialValues={[
                              initialValues?.openTime?.slice(0, 2),
                              ':',
                              initialValues?.openTime?.slice(-2),
                              '-',
                              initialValues?.closeTime?.slice(0, 2),
                              ':',
                              initialValues?.closeTime?.slice(-2),
                            ]}
                          />
                        </FieldColumn>
                      </FieldRow>
                      <Button
                        disabled={
                          values?.time?.valueText?.split(' ')[0] >=
                          values?.time?.valueText?.split(' ')[2]
                        }
                        buttonType={'primary'}
                        type={'submit'}
                        label={t(
                          'translation.LocationWorkHoursMobile.button-set'
                        )}
                        fullWidth
                        size={'large'}
                      />
                    </EventContainer>
                  </FormContentContainer>
                )
              }}
            </Form>
          </FooterOptions>
        </div>
      ) : null}
    </>
  )
}
export default LocationWorkHoursMobile

export const FooterOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  position: absolute;
  bottom: 0;
  background-color: ${props => props.theme.colors.light};
  border-radius: 40px 40px 0 0;
  box-shadow: 0px 1px 8px 0px #00000026;
  animation: window 0.5s ease-out;
  @keyframes window {
    0% {
      bottom: -300px;
    }
    100% {
      bottom: 0;
    }
  }

  @supports (-webkit-touch-callout: none) {
    @media all and (display-mode: standalone) {
      padding-bottom: 15px;
    }
  }
`
