import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Professions = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M14 11.5c-.9.7-2.1.7-3 0M23.5 24v-3.1c0-.6-.3-1.2-.8-1.6-1.3-1-4.5-2.8-10.2-2.8-5.7 0-8.9 1.8-10.2 2.8-.5.4-.8 1-.8 1.6V24M14.5 8.8c.1 0 .2.1.2.2s-.1.2-.2.2-.3-.1-.3-.2.2-.2.3-.2M10.5 8.8c.1 0 .2.1.2.2s-.1.2-.2.2-.3-.1-.3-.2.2-.2.3-.2"
        fill="none"
      ></path>
      <path
        d="M18.5 7c0-3.3-2.7-6-6-6s-6 2.7-6 6v2c0 3.3 2.7 6 6 6s6-2.7 6-6V7z"
        fill="none"
      ></path>
      <path
        d="M18.5 7c-1.3 0-4.1.3-6-2-1.9 2.3-4.7 2-6 2M18.5 7.5c0 1.7.5 5 2.3 6.2.2.1.3.5.2.7 0 .1-.1.1-.2.2-.9.4-1.8.5-2.8.3M6.5 7.5c0 1.7-.5 5-2.3 6.2-.2.1-.3.5-.2.7 0 .1.1.1.2.2.9.3 1.9.4 2.8.2M8.4 16.9l-1.9 3.4c-.1.2-.1.5.2.7h4.5"
        fill="none"
      ></path>
      <path
        d="M16.6 16.9L12.5 23l-4.1-6.1M16.6 16.9l1.9 3.4c.1.2.1.5-.2.7-.1 0-.2.1-.2.1h-4.3"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
