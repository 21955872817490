import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const MessagesBubbleCheck = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.7 13.258a6 6 0 11-8.485 8.486 6 6 0 018.486-8.486"></path>
        <path d="M20.132 15.756l-2.905 3.874h0a.75.75 0 01-1.13.08l-1.5-1.5M21.29 10.477h0c.109-.485.165-.98.168-1.477 0-4.694-4.7-8.5-10.5-8.5S.458 4.307.458 9h0a7.744 7.744 0 003.018 5.96L1.458 19.5 7.1 16.9h0c.771.242 1.564.41 2.368.5"></path>
      </g>
    </StyledIcon>
  )
}
