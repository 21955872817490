import React, { useContext, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router'
import { MobileContext } from 'src/Context'

import { ChevronDown } from 'src/components/Icon/Icons/ChewronDownLight'
import { RangePopup } from './RangePopup'
import { DateRangeContainer, DateRangeDate, DateRangeWrapper } from './styles'

export const HotelDateRangePicker = ({
  onDateSet,
  dateFrom,
  dateTo,
  minDate,
  maxDate,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)

  const isMobile = useContext(MobileContext)
  const [datePeriod, setDatePeriod] = useState({
    dateFrom: moment(dateFrom).format('ddd DD.MM.YYYY.'),
    dateTo: moment(dateTo).format('ddd DD.MM.YYYY.'),
  })

  return (
    <DateRangeWrapper>
      <DateRangeContainer style={{ border: 'none' }}>
        <DateRangeDate
          style={{ fontSize: '18px' }}
          onClick={event => {
            event.stopPropagation()
            event.preventDefault()

            setShowCalendar(true)
          }}
        >
          {datePeriod.dateFrom + ' - ' + datePeriod.dateTo}
          <ChevronDown style={{ color: '#333' }} />
        </DateRangeDate>
        {showCalendar && (
          <RangePopup
            setShowCalendar={setShowCalendar}
            minDate={minDate}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxDate={maxDate}
            setInputDatePeriod={period => {
              setDatePeriod({
                dateFrom: moment(period.dateFrom).format('ddd DD.MM.YYYY.'),
                dateTo: moment(period.dateTo).format('ddd DD.MM.YYYY.'),
              })
              onDateSet({
                dateFrom: moment(period.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(period.dateTo).format('YYYY-MM-DD'),
              })
            }}
          />
        )}
      </DateRangeContainer>
    </DateRangeWrapper>
  )
}

HotelDateRangePicker.defaultProps = {
  minDate: null,
  maxDate: null,
}
