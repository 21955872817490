import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FieldColumn } from 'views/Settings/styles'
import { FormFooterContainer, Loader } from 'components'
import Button from 'components/Button/Button'
import {
  fontFamily,
  fontSize,
  fontWeight,
  fontWeightMedium,
  lineHeight,
} from 'components/style/fonts'
import { useSdk } from 'sdk'
import { StyledCheck } from 'components/Checkbox/Checkbox'
import { CheckboxInput } from 'components/Form/Fields/Checkbox'
import { device } from '../../../../constants'
import { icons } from 'components'
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { StripeCheckoutForm, StripeSmsCheckoutForm } from './StripeCheckoutForm'
import {
  PaymentPlan,
  usePaymentUpdateStripePaymentMethodMutation,
  GetOrganizationByIdQuery,
  SmsPackage,
} from 'state/graphql'
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)

type TCreditCardFormProps = {
  selectedPlan: PaymentPlan | null
  cycle: string
  setStep: (step: number) => any
  closeModal: () => any
  org?: GetOrganizationByIdQuery['organization']['get'] | null
  isUpdatePayment?: boolean
  clientSecret?: string
  shouldShowPlanQuantity?: boolean
}
type StripeThemes = 'none' | 'stripe' | 'night' | 'flat' | undefined

export const CreditCardForm = (props: TCreditCardFormProps) => {
  const {
    setStep,
    closeModal,
    org,
    selectedPlan,
    cycle,
    isUpdatePayment,
    clientSecret: clientSecretFromPlanChosing,
    shouldShowPlanQuantity,
  } = props

  const { t, appServices } = useSdk()
  const [isBusy, setIsBusy] = useState(false)
  const [
    updateStripePaymentMethod,
  ] = usePaymentUpdateStripePaymentMethodMutation()

  const [clientSecret, setClientSecret] = useState(
    clientSecretFromPlanChosing || ''
  )

  const appearance = {
    theme: 'stripe' as StripeThemes,
  }
  const options = {
    clientSecret,
    appearance,
    locale: (org?.country?.countryCode?.toLowerCase() ||
      'en-GB') as StripeElementLocale,
  }

  useEffect(() => {
    if (clientSecretFromPlanChosing) {
      setClientSecret(clientSecretFromPlanChosing)
    }
  }, [clientSecretFromPlanChosing])

  useEffect(() => {
    const setupUpdatePaymentMethod = async () => {
      if (clientSecret) return

      if (!selectedPlan) return

      const result = await updateStripePaymentMethod()
      if (!result?.errors?.length) {
        setClientSecret(
          result?.data?.payment?.updateStripePaymentMethod?.clientSecret || ''
        )
      } else {
        const error = result.errors[0]
        console.log(error)
        appServices.toast.danger(error.message)
      }
      // setIsBusy(false)
    }
    if (isUpdatePayment) {
      setupUpdatePaymentMethod()
    }
  }, [])

  return (
    <>
      {(isBusy || !clientSecret) && <Loader isComponent />}
      <>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <StripeCheckoutForm
              org={org}
              selectedPlan={selectedPlan}
              cycle={cycle}
              isUpdatePayment={isUpdatePayment}
              setIsBusy={setIsBusy}
              shouldShowPlanQuantity={shouldShowPlanQuantity}
            />
          </Elements>
        )}
        <FormFooterContainer
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <div>
            <Button
              buttonType={'link'}
              type="button"
              name={'back'}
              label={t('translation.CreditCardForm.natrag')}
              iconComponent={<icons.ArrowLeft />}
              footerButton
              onClick={e => {
                e.preventDefault()

                setStep(2)
              }}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              buttonType={'text'}
              type="button"
              name={'close'}
              label={t('translation.CreditCardForm.close')}
              footerButton
              onClick={e => {
                e.preventDefault()
                closeModal()
              }}
            />
          </div>
        </FormFooterContainer>
      </>
    </>
  )
}

type TCreditCardSmsFormProps = {
  selectedPackage: SmsPackage
  setStep: (step: number) => any
  closeModal: () => any
  org?: GetOrganizationByIdQuery['organization']['get'] | null
  clientSecret?: string
}

export const CreditCardSmsForm = (props: TCreditCardSmsFormProps) => {
  const { setStep, closeModal, org, selectedPackage, clientSecret } = props

  const [isBusy, setIsBusy] = useState(false)
  const { t } = useSdk()

  const appearance = {
    theme: 'stripe' as StripeThemes,
  }
  const options = {
    clientSecret,
    appearance,
    locale: (org?.country?.countryCode?.toLowerCase() ||
      'en-GB') as StripeElementLocale,
  }

  return (
    <CreditCardFormStyled>
      {(isBusy || !clientSecret) && <Loader isComponent />}
      <>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <StripeSmsCheckoutForm
              org={org}
              setIsBusy={setIsBusy}
              selectedPackage={selectedPackage}
            />
          </Elements>
        )}
        <FormFooterContainer
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <div>
            <Button
              buttonType={'link'}
              type="button"
              name={'back'}
              label={t('translation.CreditCardForm.natrag')}
              iconComponent={<icons.ArrowLeft />}
              footerButton
              onClick={e => {
                e.preventDefault()

                setStep(2)
              }}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              buttonType={'text'}
              type="button"
              name={'close'}
              label={t('translation.CreditCardForm.close')}
              footerButton
              onClick={e => {
                e.preventDefault()
                closeModal()
              }}
            />
          </div>
        </FormFooterContainer>
      </>
    </CreditCardFormStyled>
  )
}

export const CreditCardFormStyled = styled.div`
  ${FieldColumn} {
    flex-direction: column;
  }
`

export const StyledInput = styled.div`
  font-size: 14px;
  height: 45px;
`

export const Label = styled.label<{ textLight?: boolean }>`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${props => (props.textLight ? fontWeight : fontWeightMedium)};
  line-height: ${lineHeight};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
`

export const ModalContainer = styled.div`
  display: flex;
  padding: 0px 20px 0px 0px;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0px;
    align-items: center;
  }
`

export const ModalRight = styled.div<{ sms?: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.outline};
  flex: 1;
  background: ${props => props.theme.colors.light};
  max-width: 300px;
  border-radius: 6px;
  /* margin: 30px 0px; */
  margin: 0 30px;
  margin-top: 26px;
  margin-bottom: ${props => (props.sms ? 'auto' : '30px')};
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);

  input[type='checkbox'] {
    :before {
      border-radius: 100px;
      width: 30px;
      height: 30px;
    }
    :after {
      top: 6px;
      left: 11px;
      width: 7px;
      height: 15px;
    }
  }

  ${CheckboxInput} {
    margin-right: 30px !important;
    height: 20px !important;
    width: 5px !important;
    margin-top: -10px !important;
  }

  ${StyledCheck} {
    margin-bottom: 20px !important;
    margin-left: 20px !important;
  }

  @media ${device.tablet} {
    margin: 0px 30px 30px;
  }

  @media ${device.mobileL} {
    max-width: ${props => (props.sms ? '100%' : '300px')};
  }
`

export const ModalRightTitle = styled.span`
  display: flex;
  border-top: 1px solid ${props => props.theme.colors.outline};
  padding-top: 15px;
  padding-bottom: 10px;
  margin-top: 20px;
  align-items: center;
`

export const TotalPrice = styled.span<{ sms?: boolean }>`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  position: relative;
  margin-right: ${props => (props.sms ? '20px' : '0')};
`

export const TotalLabel = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
`

export const TotalInterval = styled.span`
  margin-left: 5px;
  font-size: 14px;
  right: 0;
  font-weight: 600;
  margin-right: 20px;
`

export const ModalRightRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 20px;
  span {
    margin-left: 10px;
  }
`

export const ModalRightCheck = styled.div``

export const CardFields = styled.div<{ isUpdatePayment?: boolean }>`
  flex: 1.5;
  padding: ${props =>
    props.isUpdatePayment ? '0px 30px 4px 30px' : '0px 30px 40px 30px'};

  @media ${device.tablet} {
    ${FieldColumn} {
      :not(last-child) {
        padding-right: 0;
      }
    }
    max-width: 100%;
  }
`

export const BottomSection = styled.div`
  display: flex;
  margin-top: 25px;
  .ant-avatar img {
    width: 70px;
    border-radius: 5em;
  }
`

export const BillingText = styled.div`
  display: inline;
`

export const UserAvatarName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  margin-top: 10px;
`

export const BillingTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`
