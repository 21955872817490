import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Products = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M22.953 18.5H11h0a42.508 42.508 0 010-13h11.953a38.909 38.909 0 010 13z"></path>
        <path d="M10.536 13.5H2.5h0a2 2 0 010-4h8.089M10.882 17.5H2.5h0a2 2 0 010-4M11.68 21.5H2.5h0a2 2 0 010-4M11 5.5l-.306-1.836h0A1 1 0 0111.68 2.5H22.3a1 1 0 01.984 1.179L22.953 5.5"></path>
        <path d="M11 18.5l-.306 1.836h0a1 1 0 00.986 1.164H22.3a1 1 0 00.984-1.179l-.331-1.821"></path>
      </g>
    </StyledIcon>
  )
}
