import React, { useState, useContext } from 'react'
import { t } from 'src/localization'
import { SelectTw } from '../Select'
import { ClientSearchQuery, useClientSearchQuery } from 'src/state/graphql'
import Tooltip from 'components/Tooltip/Tooltip'
import { icons } from 'src/components'
import moment from 'moment'
import { RouterOutput } from 'src/trpc'
import { formatPriceNumberWithDelimiter } from 'src/helpers'
import { MobileContext } from 'src/Context'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { Tooltip2 } from 'src/components/Tooltip2/Tooltip2'
//shape of the GraphQL query result, this is client item we work with in the select
type ClientItemData =
  | ClientSearchQuery['client']['searchViewClients'][0]
  | RouterOutput['giftCard_findOne']['client']

type SelectClientProps = {
  onItemSelected?: (item?: ClientItemData) => void
  selectedItem?: ClientItemData
  disabled?: boolean
  showAddNewButton?: boolean
  onNewClient?: () => void
  ItemComponent?: (props: { item: ClientItemData }) => React.ReactNode
  EmptyComponent?: () => React.ReactNode
  SelectedClientComponent?: (props: { item: ClientItemData }) => React.ReactNode
  customInputStyle?: string
  customReselectIcon?: React.ReactNode
  onComponentClick?: any
  currency?: string
  itemHeight?: number
  dropDownHeight?: number
  filterClients?: (client: ClientItemData) => boolean
}

export const ClientSelect = (props: SelectClientProps) => {
  const {
    onItemSelected,
    onNewClient: onAddNewClick,
    selectedItem,
    showAddNewButton = true,
    disabled,
    ItemComponent = Item,
    EmptyComponent = EmptyItem,
    SelectedClientComponent = SelectedClient,
    customInputStyle = 'border-0 min-h-[30px] lg:min-h-[60px]',
    customReselectIcon = (
      <div className="p-2.5 cursor-pointer text-[#a0a0a0]">
        <icons.Edit size="smaller" style={{ strokeWidth: 2 }} />
      </div>
    ),
    onComponentClick,
    currency,
    itemHeight = 125,
    dropDownHeight,
    filterClients,
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const [pageNo, setPageNo] = useState(1)
  const { data, loading, fetchMore } = useClientSearchQuery({
    variables: { searchTerm, pageNumber: 1, pageSize: 20 },
  })

  const selectData =
    filterClients && typeof filterClients === 'function'
      ? (data?.client?.searchViewClients || []).filter(filterClients)
      : data?.client?.searchViewClients || []

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }
  const handleEndReached = async () => {
    const newPage = pageNo + 1
    setPageNo(newPage)
    await fetchMore({
      variables: {
        pageNumber: newPage,
        pageSize: 20,
        searchTerm,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!prev?.client?.searchViewClients) {
          return { client: { searchViewClients: [] } }
        }

        if (!fetchMoreResult) {
          return prev
        }
        return {
          client: {
            ...prev.client,
            searchViewClients: [
              ...prev.client!.searchViewClients!,
              ...fetchMoreResult.client!.searchViewClients!,
            ],
          },
        }
      },
    })
  }
  return (
    <SelectTw
      onItemSelected={onItemSelected}
      data={selectData}
      onSearch={handleSearch}
      disabled={disabled}
      isLoading={loading}
      selectedItem={selectedItem}
      renderItem={ItemComponent}
      showAddNewButton={showAddNewButton}
      addNewButtonText={t('translation.ClientSelect.newClient')}
      onAddNewClick={onAddNewClick}
      renderEmpty={EmptyComponent}
      renderSelectedItem={SelectedClientComponent}
      itemHeight={itemHeight}
      onEndReached={handleEndReached}
      isClearable={!disabled}
      customInputStyle={customInputStyle}
      customReselectIcon={disabled ? <></> : customReselectIcon}
      onComponentClick={onComponentClick}
      currency={currency}
      dropdownHeight={dropDownHeight}
    />
  )
}
const Item = props => {
  const { item, isSelected, currency } = props
  const isMobile = useContext(MobileContext)

  return (
    <div className="px-2.5 lg:px-0 ">
      <div
        className={`${
          isSelected ? 'border-1 border-zoyya-primary' : null
        } flex border-zoyya-primary p-2 px-3 flex-col items-start rounded-lg bg-zoyya-lightBackground h-[109px] justify-center`}
      >
        <div className="justify-between flex-row flex w-full gap-3 text-zoyya-secondaryDark">
          <p className=" mr-auto line-clamp-1">
            <span className="font-medium text-zoyya-text">{`${
              item.firstName || ''
            } ${item.lastName || ''}`}</span>

            {item?.birthDate ? (
              <span className="text-zoyya-secondaryDark text-sm ml-[4px]">
                {`(${moment(item.birthDate).format(
                  t('regionalFormats.date.momentFormatDate')
                )})`}
              </span>
            ) : null}

            <span className="font-normal text-sm text-zoyya-secondaryDark">
              {`${item?.address || item?.zipCode || item.city ? ', ' : ''} ${
                item?.address ? item.address + ', ' : ''
              } ${item?.zipCode || ''} ${item?.city || ''}`}
            </span>
          </p>
          {item?.status === 'BLOCKED' ? (
            <p className="bg-zoyya-accent5 text-white px-2 uppercase text-sm rounded-sm flex items-center">
              {t('translation.ClientsList.label-blocked')}
            </p>
          ) : null}
          {item.paymentRequired ? (
            <Tooltip
              label={t('translation.ClientsList.tooltip-prepayment')}
              key={'tooltip'}
            >
              <icons.CreditCard
                style={{ strokeWidth: 1.2 }}
                size="small"
                color="#333"
              />
            </Tooltip>
          ) : null}
        </div>
        <div className="flex flex-row mt-1 items-center gap-3 w-full">
          <div className=" rounded-full overflow-hidden border-1 border-white shrink-0">
            {item?.defaultAvatarUrl &&
            !item?.defaultAvatarUrl?.endsWith('null') ? (
              <img
                src={item.defaultAvatarUrl}
                className="w-[40px] h-[40px] rounded-full"
              ></img>
            ) : (
              <div className="w-[40px] h-[40px] bg-zoyya-background flex items-center justify-center text-zoyya-primary text-sm font-medium ">
                {item?.firstName && item?.lastName
                  ? item?.firstName.charAt(0).toUpperCase() +
                    item?.lastName.charAt(0).toUpperCase()
                  : item?.firstName
                  ? item?.firstName.charAt(0).toUpperCase()
                  : 'NN'}
              </div>
            )}
          </div>

          <div className="flex flex-col text-gray-600 text-sm font-light lg:min-w-[200px] min-w-[50px] overflow-hidden">
            {item.mailboxStatus === 'DISABLED' ? (
              <p className="line-clamp-1 ">
                <span>{item.email}</span>{' '}
                <Tooltip2
                  helpText={t('translation.NewClientContainer.email-blocked')}
                >
                  <span>
                    <WarningTriangle color="orange" size="size14" />{' '}
                  </span>
                </Tooltip2>
              </p>
            ) : (
              <p className="line-clamp-1">{item.email}</p>
            )}
            <p>{item.mobilePhone}</p>
            {item?.vatId ? (
              <div className="flex text-xs">
                <span className="text-zoyya-secondaryDark ">
                  {t('translation.ClientSelect.id')}:
                </span>
                <span className="text-zoyya-secondaryDark">
                  {' ' + item?.vatId}
                </span>
              </div>
            ) : null}
          </div>

          {!isMobile ? (
            <div className="flex flex-col text-gray-600 text-sm font-light">
              {item?.totalCancelledAppointments ? (
                <span>{`${t(
                  'translation.ClientSelect.canceled-dates'
                )}: ${item?.totalCancelledAppointments}`}</span>
              ) : null}
              {item?.totalMissedAppointments ? (
                <span>{`${t(
                  'translation.ClientSelect.client-missed'
                )}: ${item?.totalMissedAppointments}`}</span>
              ) : null}
            </div>
          ) : null}

          <div className="text-gray-600 text-sm font-light grow justify-end flex">
            <div className="flex flex-col text-end mr-[8px] self-center">
              {item?.remainingGiftCardAmount ||
              item?.remainingGiftCardAmount === 0 ? (
                <span>{`${formatPriceNumberWithDelimiter(
                  item?.remainingGiftCardAmount
                )} ${currency || ''}`}</span>
              ) : null}

              {item?.remainingVoucherServices ||
              item?.remainingVoucherServices === 0 ? (
                <span>{`${item?.remainingVoucherServices} / ${item?.totalVoucherServices}`}</span>
              ) : null}
            </div>

            {item?.remainingGiftCardAmount ||
            item?.remainingGiftCardAmount === 0 ||
            item?.remainingVoucherServices ||
            item?.remainingVoucherServices === 0 ? (
              <icons.Gift size="smaller" className="stroke-2 self-center" />
            ) : null}
          </div>
        </div>

        {item?.comment ? (
          <span className="text-sm text-zoyya-accent5 line-clamp-1 mt-1">
            {item?.comment}
          </span>
        ) : null}
      </div>
    </div>
  )
}
const SelectedClient = (props: { item: ClientItemData }) => {
  const { item } = props
  return (
    <div className="flex flex-row items-center">
      <div className="rounded-sm overflow-hidden border-1 border-zoyya-grayLight">
        {item?.defaultAvatarUrl && !item?.defaultAvatarUrl?.endsWith('null') ? (
          <img src={item.defaultAvatarUrl} className="w-[40px] h-[40px]"></img>
        ) : (
          <div className="w-[50px] h-[50px] bg-default-avatar flex items-center justify-center text-zoyya-primary text-sm font-medium "></div>
        )}
      </div>

      <div className="flex flex-col ml-4 items-start ">
        <div className="flex lg:gap-10 gap-3">
          <div className="font-medium text-lg">{`${item?.firstName || ''} ${
            item?.lastName || ''
          }`}</div>
          {item?.status === 'BLOCKED' ? (
            <p className="bg-zoyya-accent5 text-white px-2 uppercase text-sm rounded-sm flex items-center">
              {t('translation.ClientsList.label-blocked')}
            </p>
          ) : null}
        </div>
        <div className="flex lg:flex-row flex-col text-zoyya-secondaryDark text-[13px] lg:text-md font-light">
          <div>{item?.mobilePhone || ''}</div>
          <div className={`${item?.mobilePhone ? 'lg:ml-14' : 'ml-0'}`}>
            <span>{item?.email || ''}</span>{' '}
            {item?.mailboxStatus === 'DISABLED' ? (
              <Tooltip2
                helpText={t('translation.NewClientContainer.email-blocked')}
              >
                <span>
                  <WarningTriangle color="orange" size="size14" />{' '}
                </span>
              </Tooltip2>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
const EmptyItem = () => {
  return (
    <>
      <div className="lg:flex lg:flex-row lg:flex-1 lg:pl-3 hidden border-1 p-3 rounded-sm">
        <div className="lg:bg-default-avatar lg:w-[40px] lg:h-[40px] lg:rounded-md lg:border-zoyya-grayLight lg:border"></div>
        <div className="lg:flex-1 lg:flex-col lg:ml-4">
          <div className="lg:font-medium ">
            {t('translation.Client.odaberi-klijenta')}
          </div>
          <div className="lg:text-zoyya-secondaryDark lg:text-[13px] lg:font-light ">
            {t('translation.Client.ili-ostavi-prazno-za-walk-in')}
          </div>
        </div>
        <div className="border-l border-[#ccc]">
          <div className="p-2.5 hover:text-[#999]  cursor-pointer text-[#ccc]">
            <icons.ChevronDownRounded />
          </div>
        </div>
      </div>
      <div className="lg:hidden flex w-full justify-between">
        <p className="text-zoyya-primary text-lg">
          {t('translation.ClientSelect.placeholder-add-client')}
        </p>
        <div className="text-zoyya-primary">
          <icons.Add size="smaller" style={{ strokeWidth: 3 }} />
        </div>
      </div>
    </>
  )
}
