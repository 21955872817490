import styled from 'styled-components'
import { transparentize } from 'polished'
import { fontFamily } from 'components/style/fonts'
import { device } from '../../../../constants'
import { ChevronLeft } from 'components/Icon/Icons/ChevronLeft'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'

export const DatePickerCalendarContainer = styled.div`
  margin: 10px 0px;
  overflow: hidden;
  display: flex;
  top: ${props => (props.isHeaderPicker ? '40px' : 'initial')};
  position: ${props => (props.isAbsolute ? 'absolute' : 'initial')};
  background: ${props => (props.isAbsolute ? 'white' : 'transparent')};
  box-shadow: ${props =>
    props.isAbsolute ? '0px 0px 10px 0px #8080803b' : 'none'};
  border-radius: ${props => (props.isAbsolute ? '4px' : '0')};
  z-index: 99999;

  @media ${device.tablet} {
    overflow: auto;
    max-height: 100%;
    margin-top: 0px;
  }
`

export const DatePickerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const DatePickerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -5px;

  span {
    color: ${props => props.theme.colors.secondaryDark};
  }
`
export const DateArrow = styled.div`
  width: 25px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: ${props => props.theme.colors.text};
  }
  &:hover {
    span {
      color: ${props => props.theme.colors.text};
    }
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-content: center;
  border-radius: 4px;
  margin: auto;
  border: 1px solid ${props => transparentize(0.4, props.theme.colors.outline)};
`

export const DatePickerWrapper = styled.div`
  width: ${props => (props.isMobile ? 'auto' : '360px')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DateButton = styled.button`
  padding: 8px 8px;
  white-space: nowrap;

  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  font-size: 14px;
  height: 32px;
  font-family: ${fontFamily};

  &:focus,
  :active {
    border: none;
    outline: none;
  }
`

export const DatePickerArrow = styled(DateButton)`
  box-shadow: none;
  transform: none;
  color: #101928;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 40px;
  background: ${props => props.theme.colors.light};
  border: none;
  border-radius: 4px;

  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-left: 1px solid
      ${props => transparentize(0.4, props.theme.colors.outline)};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`

export const DatePickerToday = styled(DateButton)`
  min-width: 80px;
  background-color: ${props =>
    !props.isToday
      ? transparentize(0.6, props.theme.colors.accent4)
      : transparentize(0.6, props.theme.colors.outline)};

  height: 32px;
  border: none;
  box-shadow: none;
  transform: none;
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
`

export const DatePickerDate = styled(DateButton)`
  background: ${props => props.theme.colors.light};
  min-width: 180px;
  border: none;
  cursor: pointer;
`

export const ArrowLeft = styled(DatePickerArrow)`
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  padding-left: 8px;
  padding-right: 8px;
`
export const StyledChevronLeft = styled(ChevronLeft)`
  color: ${props => props.theme.colors.secondaryDark};
`
export const StyledChevronRight = styled(ChevronRight)`
  color: ${props => props.theme.colors.secondaryDark};
`

export const DatePickerName = styled.p`
  font-size: 16px;

  text-align: center;
  margin-right: auto;
  color: ${props => props.theme.colors.text};
`

export const DatePickerCalendarStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(10px, 1fr));
  grid-template-rows: auto;
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
  margin-top: ${props => (props.isHeaderPicker ? '10px' : '0')};
`

export const DatePickerMonthlyStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(45px, 1fr));
  grid-template-rows: 50px;
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
`

export const MonthDayName = styled.span`
  letter-spacing: 1px;
  font-size: 10px;
  background: ${props =>
    props.isCurrent && !props.isDisabled
      ? props.theme.colors.accent4
      : props.isSelected
      ? props.theme.colors.primary
      : props.isHovered
      ? transparentize(0.9, props.theme.colors.primary)
      : 'transparent'};
  color: ${props =>
    props.isToday && props.isSelected
      ? props.theme.colors.light
      : (props.isCurrent && !props.isDisabled) || props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.text};
  padding: ${props => (props.isSmall && !props.isCurrent ? '0' : '3px 6px;')};
  border-radius: 4px;

  width: ${props => (props.isHeaderPicker ? '30px' : '20px;')};
  height: ${props => (props.isHeaderPicker ? '30px' : '22px;')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => (props.isToday ? 'bolder !important' : 'normal')};

  &:hover {
    background: ${props => transparentize(0.9, props.theme.colors.primary)};
  }
`

export const MonthName = styled.span`
  letter-spacing: 1px;
  font-size: 12px;
  background: ${props =>
    props.isCurrent && !props.isDisabled
      ? props.theme.colors.accent4
      : props.isSelected
      ? props.theme.colors.primary
      : props.isHovered
      ? transparentize(0.9, props.theme.colors.primary)
      : props.theme.colors.light};
  color: ${props =>
    (props.isCurrent && !props.isDisabled) || props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.secondaryDark};
  padding: ${props => (props.isSmall && !props.isCurrent ? '0' : '3px 6px;')};
  border-radius: 4px;
  width: 65px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props => transparentize(0.9, props.theme.colors.primary)};
  }
`

export const DatePickerCalendarMonth = styled.div`
  text-align: right;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(n + 1):nth-of-type(-n + 4) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 8) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 12) {
    grid-row: 3;
  }

  &:nth-of-type(4n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(4n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(4n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(4n + 4) {
    grid-column: 4/4;
  }
`

export const DatePickerCalendarDay = styled.div`
  text-align: right;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(7n + 7) {
    border-right: 0;
  }

  &:nth-of-type(n + 1):nth-of-type(-n + 7) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 14) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 21) {
    grid-row: 3;
  }

  &:nth-of-type(n + 22):nth-of-type(-n + 28) {
    grid-row: 4;
  }

  &:nth-of-type(n + 29):nth-of-type(-n + 35) {
    grid-row: 5;
  }

  &:nth-of-type(7n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(7n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(7n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(7n + 4) {
    grid-column: 4/4;
  }

  &:nth-of-type(7n + 5) {
    grid-column: 5/5;
  }

  &:nth-of-type(7n + 6) {
    grid-column: 6/6;
  }
  &:nth-of-type(7n + 7) {
    grid-column: 7/7;
  }
`

export const DatePickerDayName = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text};
  text-align: center;
  font-weight: 600;
`

export const DatePickerDayDisabled = styled(DatePickerCalendarDay)`
  span {
    color: ${props => props.theme.colors.accent5};
    font-weight: 500;
  }
`
