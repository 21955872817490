import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
`

const Search = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M4.683 4.683a9.16 9.16 0 1012.954 12.954A9.16 9.16 0 004.683 4.683zm1.414 1.414a7.16 7.16 0 1110.126 10.126A7.16 7.16 0 016.097 6.097z"
        clipRule="evenodd"
      ></path>
      <path d="M22.094 20.282l-4.13-4.129c-.942-.943-2.356.471-1.413 1.414l4.13 4.13c.942.943 2.356-.472 1.413-1.415z"></path>
    </StyledIcon>
  )
}
Search.displayName = 'Search'

export default Search
