import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Check = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M1.4 16.1c-.5.5-.5 1.2 0 1.7l5.8 5.8c.5.5 1.2.5 1.7 0L23.6 8.9c.5-.5.5-1.2 0-1.7l-2.3-2.3c-.5-.5-1.2-.5-1.7 0L8 16.5l-2.6-2.6c-.5-.5-1.2-.5-1.7 0l-2.3 2.2z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
