import React, { useState, useRef, useEffect } from 'react'
import { SidebarRow } from 'layout/styles'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'
import { NavigationTitle, NavigationButton } from 'src/styles'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import HamburgerMenu from 'components/Icon/Icons/HamburgerMenu'
import ReactDOM from 'react-dom'

export const Menu = React.forwardRef((props: any) => {
  const { children, isCalendarMenu } = props
  const [isOpen, setIsOpen] = useState(false)

  // close options drawer when clicked outside of it
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const newChildren = children
    ? React.cloneElement(children, { setIsOpen })
    : null
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <>
      <MenuContainer
        onClick={() => setIsOpen(true)}
        style={{ position: 'absolute', right: 15, display: 'flex' }}
        data-cy="hamburgerMenuButton"
      >
        <HamburgerMenu style={{ stroke: 'white' }} size="large" />
      </MenuContainer>
      {isOpen
        ? ReactDOM.createPortal(
            <MenuOverlay>
              <MenuOptionsContainer
                ref={wrapperRef}
                isCalendarMenu={isCalendarMenu}
              >
                {newChildren}
              </MenuOptionsContainer>
            </MenuOverlay>,
            document.getElementById(
              'react-select-portal-target'
            ) as HTMLDivElement
          )
        : null}
    </>
  )
})
type TMenuItemProps = {
  label?: string
  name?: string
  cy?: string
  icon?: any
  onClick?: () => any
  isInstallButton?: boolean
}
export const MenuItem = (props: TMenuItemProps) => {
  const { label, name, cy, icon, onClick, isInstallButton } = props
  return isInstallButton ? (
    <InstallWrapper onClick={onClick}>
      <SidebarRow style={{ flex: 1 }}>
        <InstallTitle id={name} data-cy={cy}>
          {label}
        </InstallTitle>
      </SidebarRow>
      <Button
        label={'Preuzmi'}
        buttonType={'secondary'}
        size={'small'}
      ></Button>
    </InstallWrapper>
  ) : (
    <NavigationButton
      onClick={onClick}
      style={{ minHeight: 0, margin: '0px 20px' }}
    >
      {icon}
      <SidebarRow style={{ flex: 1 }}>
        <NavigationTitle name={name || ''} id={name} data-cy={cy}>
          {label}
        </NavigationTitle>
      </SidebarRow>
      <ChevronRight stroke={2} size={'extraSmall'} style={{ color: '#333' }} />
    </NavigationButton>
  )
}

export const InstallWrapper = styled(NavigationButton)`
  background: ${props => props.theme.colors.primary};
  color: white;
  margin-left: -20px;
  margin-right: -20px;
  padding: 40px;
`

export const InstallTitle = styled.span`
  color: ${props => props.theme.colors.light};
`
const MenuContainer = styled.div``

const MenuOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`

type TMenuOptionsContainer = {
  isCalendarMenu?: boolean
}
const MenuOptionsContainer = styled.div<TMenuOptionsContainer>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 80%;
  position: absolute;
  bottom: -400px;
  background-color: ${props => props.theme.colors.light};
  border-radius: 40px 40px 0 0;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 30px 20px 0;
  padding: ${props => (props.isCalendarMenu ? '30px 20px 0' : '30px 5px')};
  animation: menu 0.5s linear;
  animation-fill-mode: forwards;
  @keyframes menu {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-400px);
    }
  }
`
