import React, { useContext, useState } from 'react'
import Button from 'components/Button/Button'
import ReactTable from 'components/Table/Table'
import { TableItem } from 'src/styles'
import { useTranslation } from 'react-i18next'
import { MobileContext } from 'src/Context'
import { useSdk } from 'sdk'
import Tooltip from 'rc-tooltip'
import {
  overlayStyle,
  overlayInnerStyle,
  InfoOverlay,
  InfoText,
} from 'views/Settings/Resources/ResourcesTable'
import { icons } from 'components'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'

export const ResourceTypesTable = ({
  selectedResourceTypes,
  removeResourceType,
  tableHeight,
}) => {
  const { t } = useTranslation()
  const { navigateTo } = useSdk()
  const isMobile = useContext(MobileContext)
  const { showPopup } = usePopup()

  const columns = React.useMemo(
    () => [
      {
        Header: t('translation.ResourcesList.label-firstName'),
        accessor: 'name',
        minWidth: 200,
        width: 200,
        Cell: props => (
          <TableItem>
            <span data-cy={'resourceTypeName'}>
              {props.cell.row.original?.name}
            </span>
          </TableItem>
        ),
      },
      {
        Header: t('translation.ResourcesList.label-resources'),
        accessor: 'resources',
        minWidth: 300,
        width: 500,
        Cell: props => (
          <TableItem>
            {props.cell.row.original?.resources?.length ? (
              <span>
                {props.cell.row.original?.resources
                  ?.filter(x => x?.id)
                  ?.map((resource, idx) => {
                    const name = `${resource?.firstName} ${
                      !resource?.lastName ? '' : resource?.lastName
                    }`

                    return (
                      name +
                      (idx < props.cell.row.original?.resources?.length - 1
                        ? ', '
                        : '')
                    )
                  })}
              </span>
            ) : null}
          </TableItem>
        ),
      },
      {
        Header: '',
        accessor: 'delete',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <Tooltip
            placement={'top'}
            overlayStyle={overlayStyle}
            overlayInnerStyle={overlayInnerStyle}
            overlay={
              <InfoOverlay>
                <InfoText>
                  {t('translation.ResourcesList.tooltip-remove-resource-type')}
                  {/* Ukloni vrstu opreme iz poslovnice */}
                </InfoText>
              </InfoOverlay>
            }
            arrowContent={<div className="rc-tooltip-arrow-inner infoButton" />}
          >
            <TableItem>
              <Button
                buttonType={'secondary'}
                iconComponent={<icons.Trash size={'smaller'} />}
                hasIconOnly
                size={'smaller'}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()

                  showPopup({
                    onConfirm: () =>
                      removeResourceType(props.cell.row.original?.id),
                    titleText: t(
                      'translation.ResourcesList.title-delete-resource-type'
                    ),
                  })
                }}
              />
            </TableItem>
          </Tooltip>
        ),
      },
    ],
    [t, isMobile, navigateTo]
  )

  return (
    <>
      <ReactTable
        tableData={selectedResourceTypes}
        columns={columns}
        isMove={!isMobile}
        hasHeader
        tableHeight={tableHeight}
        onRowClick={item =>
          navigateTo.editResourceType({
            resourceTypeId: item.id,
          })
        }
      />
    </>
  )
}
