import React, { useContext } from 'react'
import { Button } from 'components'
import moment from 'moment'
import { MobileContext } from 'src/Context'
import {
  changeServiceDurations,
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
} from '../../../helpers'
import { useSdk } from 'sdk'
import { icons } from 'components'
import { TServiceItemProps } from './Service'

type TServiceViewProps = {
  item: any
  itemToChange: TServiceItemProps
  replace: (arg0: number, arg1: TServiceItemProps) => void
  selectedServices: any[]
  timeSlotDuration: number
}
export const FreeSpaceCard = (props: TServiceViewProps) => {
  const {
    item,
    replace,
    selectedServices,
    timeSlotDuration,
    itemToChange,
  } = props

  const isMobile = useContext(MobileContext)
  const { t } = useSdk()

  const deleteFreeSpace = () => {
    const endTime = getServiceHoursAndMinutes(
      getServiceDurationInMinutes(
        selectedServices[itemToChange?.id - 1]?.endTime
      ) + selectedServices[itemToChange?.id]?.durationMinutes
    )

    const changes = {
      startTime: {
        id: selectedServices[itemToChange?.id - 1]?.endTime,
        title: selectedServices[itemToChange?.id - 1]?.endTime,
        isBusy: false,
        isInactive: false,
      },
      startTimeUtc: selectedServices[itemToChange?.id - 1]?.endTimeUtc,
      endTime,
      endTimeUtc: moment(
        moment(item?.endTimeUtc)?.format('YYYY-MM-DD') + ' ' + endTime
      ).toISOString(),
    }

    replace(selectedServices[itemToChange?.id]?.id, {
      ...selectedServices[itemToChange?.id],
      ...changes,
      original: {
        ...selectedServices[itemToChange?.id]?.original,
        ...changes,
      },
    })
  }

  return (
    <div className="flex relative">
      <div
        className={`ml-0 mr-0 w-[6px] mb-[20px] bg-red-600 rounded lg:-ml-[14px] lg:mr-[10px]`}
      />
      <div className="flex flex-col relative lg:px-[20px] rounded w-[100%] mb-[20px] px-[15px] py-[10px] border-solid border border-zoyya-grayDarker md:overflow-hidden pr-[5px]">
        <div className="flex justify-between items-center mb-[10px]">
          <p className="text-[16px] lg:text-base font-medium leading-6 text-zoyya-text">
            {t('translation.ServicesList.label-free-time')}
          </p>
          <Button
            buttonType={'text'}
            hasIconOnly={true}
            iconComponent={
              <icons.Cross
                size={'extraSmall'}
                color={!isMobile ? '#a0a0a0' : null}
                stroke={4}
              />
            }
            onClick={e => {
              e.preventDefault()
              deleteFreeSpace()
            }}
            size={'small'}
            style={{ height: 'unset' }}
          />
        </div>

        <div className="flex items-center justify-between w-[100%] md:flex-col md:items-baseline">
          <div className="flex items-center justify-between w-[100%] lg:w-[auto]">
            <div className="text-zoyya-text w-[100px]">
              {item?.startTime + ' - ' + item?.endTime}
            </div>
            <div className="flex items-center ml-[10px] w-[130px] justify-between">
              <button
                onClick={() => {
                  if (item?.durationMinutes > timeSlotDuration)
                    changeServiceDurations(
                      '-',
                      itemToChange,
                      selectedServices,
                      timeSlotDuration,
                      replace,
                      true
                    )
                }}
                type="button"
                className={`w-[30px] h-[30px] rounded-full bg-zoyya-grayDarker ${
                  item?.durationMinutes === timeSlotDuration
                    ? 'opacity-[0.3]'
                    : 'opacity-[1]'
                }`}
              >
                <span className="text-[20px]">-</span>
              </button>
              <p>{item?.durationMinutes} min</p>
              <button
                disabled={
                  itemToChange?.endTime === '00:00' ||
                  itemToChange?.endTime ===
                    getServiceHoursAndMinutes(
                      getServiceDurationInMinutes('24:00') - timeSlotDuration
                    )
                }
                onClick={() =>
                  changeServiceDurations(
                    '+',
                    itemToChange,
                    selectedServices,
                    timeSlotDuration,
                    replace,
                    true
                  )
                }
                type="button"
                className={`w-[30px] h-[30px] rounded-full bg-zoyya-grayDarker ${
                  itemToChange?.endTime === '00:00' ||
                  itemToChange?.endTime ===
                    getServiceHoursAndMinutes(
                      getServiceDurationInMinutes('24:00') - timeSlotDuration
                    )
                    ? 'opacity-[0.3]'
                    : 'opacity-[1]'
                }`}
              >
                <span className="text-[20px]">+</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
