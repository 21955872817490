/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Styles from './style'

export const AvatarWrapper = styled.div`
  ${props => Styles.wrapper[props.size]}
`

const Image = styled.div`
  ${props => Styles.image[props.type][props.size]}
`

const Avatar = props => {
  const { src, alt, type, size, style, fallbackUrl } = props
  return (
    <AvatarWrapper size={size} style={style}>
      <Image
        size={size}
        type={type}
        src={src}
        fallbackUrl={fallbackUrl}
        alt={alt}
        data-cy={`avatar_${src}`}
      />
    </AvatarWrapper>
  )
}

Avatar.propTypes = {
  /** The source of the avatar image. */
  src: PropTypes.string.isRequired,
  /** The alt text for the avatar image. */
  alt: PropTypes.string,
  /** The fallback url for the avatar image. */
  fallbackUrl: PropTypes.string,
  /** Can be `'default'` (default, plain avatar), `'status'` (online/offline indicator) or `'social'` (has social network icon from the `network` prop). */
  type: PropTypes.oneOf(['default', 'social', 'status']),
  /** Can be `'small'`, `'medium'` or `'large'` (`32px`, `40px` and `48px` respectively). */
  size: PropTypes.oneOf(['large', 'medium', 'small', 'extraLarge', 'smaller']),
  /** Makes the status indictor green when `true`. Only applicable when `{ type: 'status' }` */
  isOnline: PropTypes.bool,
}

Avatar.defaultProps = {
  type: 'default',
  size: 'small',
  fallbackUrl: '',
  isOnline: false,
}

export default Avatar
