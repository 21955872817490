import styled from 'styled-components'
import { device } from '../../../../constants'
import { CheckboxInput } from 'components/Form/Fields/Checkbox'
import { StyledCheck } from 'components/Checkbox/Checkbox'

export const Wrapper = styled.section`
  & + * {
    display: none;
  }
`

export const Title = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem 1rem;
  box-sizing: border-box;
`

export const SubTitleText = styled.span`
  color: #598016;
  font-weight: 500;
`

export const ModalContent = styled.main`
  background: #f5f5f5;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`

export const Form = styled.form`
  fieldset {
    border: transparent;
    padding: 0;
    margin: 8px 0 16px 0;
  }
`

export const StyledReason = styled.div`
  position: relative;
  padding: 4px 0;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`

export const StyledRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
`

export const CheckMark = styled.div`
  transition: all 0.2s ease-out;
  content: '';
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid #c8c8c8;
  border-radius: 100%;
  background: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    top: calc(50% - 2px);
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: transparent;
  }

  ${StyledRadio}:hover ~ & {
    box-shadow: 0px 0px 0px 3px #abb7ff;
    border-color: #2c4bff;

    &::before {
      background: #2c4bff;
    }
  }

  ${StyledRadio}:checked ~ & {
    transition: all 0.3s ease-out;
    background-color: #2c4bff;
    border-color: #2c4bff;

    &::before {
      background: #ffffff;
    }
  }
`

export const H3 = styled.h3`
  color: #3d3d3d;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
`

export const Strong = styled.span`
  color: #3d3d3d;
  font-weight: bold;
`

export const FormBlock = styled.div`
  margin-top: 14px;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
`

export const Step1Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 15px;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px;
  }
`

export const Step1Price = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #3d3d3d;
  margin-bottom: 0.5rem;
`

export const Step1Interval = styled.span`
  font-size: 0.75rem;
  color: #3d3d3d;
`
export const Step1PDV = styled.span`
  font-size: 0.75rem;
  color: #62668a;
  margin-left: 5px;
`

export const Step1FeatureList = styled.ul`
  margin: 0.5rem 0 1rem 0;
  padding: 0;
`

export const Step1Image = styled.img`
  margin: 1rem auto;
  width: 80%;
  object-fit: contain;
  opacity: 0.7;
  /* max-height: 100px; */
  height: 100px;

  @media ${device.tablet} {
    display: none;
  }
`

export const Step1Pricing = styled.section`
  margin-top: auto;
`

export const Step1Picker = styled.main`
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 20px;
  }
`

export const Step1Card = styled.section<{ selected?: boolean }>`
  background: white;
  border: ${props =>
    props.selected ? '1px solid #7860dc' : '1px solid #c8c8c8'};
  outline: ${props =>
    props.selected ? '1px solid #7860dc' : '1px solid transparent'};
  border-radius: 4px;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 260px;

  input[type='checkbox'] {
    :before {
      border-radius: 100px;
      width: 30px;
      height: 30px;
    }
    :after {
      top: 6px;
      left: 11px;
      width: 7px;
      height: 15px;
    }
  }

  ${CheckboxInput} {
    margin-right: 20px !important;
    height: 20px !important;
    width: 5px !important;
    margin-top: -10px !important;
  }

  ${StyledCheck} {
    margin-bottom: 0px !important;
  }

  @media ${device.tablet} {
    margin-bottom: 20px;
    width: 100%;
  }
`

export const Step1TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 600;
    font-size: 16px;
  }
  div:last-child {
    flex: 0 !important;
    margin-left: auto;
  }
`

export const Step1Feature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  span {
    margin-left: 5px;
  }
`
export const Step1Row = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

export const Step1Badge = styled.div`
  border: 1px solid #d0d0d0;
  background: #e3e2e2;
  color: #7a7a7a;
  font-weight: 500;
  border-radius: 4px;
  margin-left: 10px;
  padding: 0px 4px;
  /* font-size: 12px; */
  letter-spacing: 0.5px;
  font-size: 11px;
`

export const Step1ToogleText = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  margin-left: 5px;
`

export const Step1ToggleWrapper = styled.div`
  min-width: fit-content;
  margin-left: 20px;
  display: flex;
  align-items: center;
  button::after {
    background-color: ${props => props.theme.colors.primary};
  }

  @media ${device.tablet} {
    margin-left: 0px;
    margin: 15px 0px;
  }
`
