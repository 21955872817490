import * as Yup from 'yup'
import { useACL } from './acl'
import { t } from 'src/localization'
import { isValidNumber } from 'libphonenumber-js'

export const useValidationSchema = () => {
  const { hasPermission } = useACL()

  const canAccessClientPhone = hasPermission('ACCESS_CLIENT_PHONE')
  const canAccessClientEmail = hasPermission('ACCESS_CLIENT_EMAIL')

  const ClientSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t('translation.AppointmentModal.validation-firstNameRequired')
    ),
    //@ts-ignore
    email: canAccessClientEmail
      ? Yup.string()
          .email(t('translation.NewClientContainer.validation-invalidEmail'))
          .nullable()
      : undefined,
    //@ts-ignore
    mobilePhone: canAccessClientPhone
      ? Yup.string()
          .nullable()
          .test(
            'validNumber',
            t('translation.NewClientContainer.validation-invalidPhoneNUmber'),
            function(value) {
              return !value ? true : isValidNumber(value)
            }
          )
      : undefined,
  })

  return { ClientSchema }
}
