import React from 'react'
import styled from 'styled-components'
import { SectionTitle } from './SectionTitle'

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledSectionBody = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 2rem;
`
type TProps = {
  title?: string
  children?: any
  sectionContainerStyle?: any
  sectionBodyStyle?: any
}
export const SectionContainer = (props: TProps) => {
  const { title, children } = props
  return (
    <StyledSectionContainer style={props?.sectionContainerStyle}>
      <SectionTitle title={title}></SectionTitle>
      <StyledSectionBody style={props.sectionBodyStyle}>
        {children}
      </StyledSectionBody>
    </StyledSectionContainer>
  )
}
