import styled from 'styled-components'
import { AvatarWrapper } from '../../../components/Avatar/Avatar'
import { device } from '../../../constants'

type TWorkHourNameProps = {
  isInactive?: boolean
  isBusy?: boolean
  isDisabled?: boolean
  noProfession?: boolean
}

export const WorkHourName = styled.span<TWorkHourNameProps>`
  flex: 2;
  font-size: 14px;
  color: ${props =>
    props.isInactive
      ? props.theme.colors.accent5
      : props.isBusy
      ? props.theme.colors.accent4
      : props.noProfession
      ? props.theme.colors.accent1
      : props.isDisabled
      ? props.theme.colors.grayDark
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

type TWorkHourHintProps = {
  isInactive?: boolean
  isBusy?: boolean
  isDisabled?: boolean
}
export const WorkHourHint = styled.span<TWorkHourHintProps>`
  background: ${props =>
    props.isInactive
      ? props.theme.colors.accent5
      : props.isDisabled
      ? props.theme.colors.grayDark
      : props.theme.colors.accent4};
  padding: 2px 7px;
  font-size: 10px;
  border-radius: 4px;
  color: ${props => props.theme.colors.light};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
`

export const WorkHourRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const SelectRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${AvatarWrapper} {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`

type TItemRowProps = {}
export const ItemRow = styled.div<TItemRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
