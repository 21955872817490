import React, { useContext, useMemo } from 'react'
import { Button, FormField } from '../../../components'
import { useSdk } from '../../../sdk'
import transparentize from 'polished/lib/color/transparentize'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { animated, useSpring } from 'react-spring'
import { components } from 'react-select'
import {
  SelectOptionLabel,
  SelectOptionWarning,
  SelectRow,
  ServiceCircle,
  ServiceName,
  ItemRow,
} from '../../AppointmentModal/styles'
import { useParams } from 'react-router'
import { CheckMarkIcon } from 'components/Icon/Icons/Checkmark'
import { Add } from 'components/Icon/Icons/Add'
import Tooltip from 'components/Tooltip/Tooltip'
import { WarningTriangle } from '../../../components/Icon/Icons/WarningTriangle'
import { orderBy } from 'lodash'
import { WorkHourName } from './styles'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'

type TServiceSelect = {
  services: any
  filterOptions: any
  onNewService: any
  employee: any
  name: string
  flattenedServices: any
  isDisabled: boolean
  hideAddNewService?: boolean
}

export const ServiceSelect = (props: TServiceSelect) => {
  const {
    services,
    filterOptions,
    onNewService,
    employee,
    name,
    flattenedServices,
    isDisabled,
    hideAddNewService,
  } = props
  const { t } = useSdk()
  const selectedTheme = useContext(ThemeContext)
  const employeeProfessions = employee?.professions
    ? [...employee?.professions?.map(x => x.id), undefined]
    : [undefined]

  const options = useMemo(
    () =>
      services?.map(type => {
        const sortedServices = type.services
          ?.slice()
          ?.sort((a, b) =>
            a.sequence?.localeCompare(b.sequence, undefined, { numeric: true })
          )
          ?.sort(
            (a, b) =>
              (employeeProfessions?.includes(b?.profession?.id) as any) -
              (employeeProfessions?.includes(a?.profession?.id) as any)
          )

        const options = sortedServices.map(service => {
          return {
            ...service,
            id: service.id,
            label: service.name,
            typeName: type.name,
          }
        })

        return {
          ...type,
          label: type.name,
          options: orderBy(options, 'hasProfession'),
          hasProfession: !!options?.find(x =>
            employeeProfessions?.includes(x?.profession?.id)
          ),
        }
      }),
    [services, employee]
  )

  const selectStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      boxShadow: 'none',
      minWidth: '200px',
    }),
    menu: styles => ({
      ...styles,
      minWidth: '540px',
      left: '-170px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.primary)
          : null,
        fontWeight: isSelected ? 500 : 400,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.primary),
        },
      }
    },
    singleValue: styles => ({
      ...styles,
      width: '100%',
    }),
  }

  const CustomMenuList = props => {
    const { t } = useTranslation()

    const { x } = useSpring({
      from: { x: 0 },
      x: 1,
      config: { duration: 2500 },
    })

    const animateProps = props.options[0]?.options?.[0]
      ? {}
      : {
          transform: x
            .interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.0, 0.9, 1.0, 1.0, 1],
            })
            .interpolate(x => `scale(${x})`),
        }
    return (
      <components.MenuList {...props}>
        <div style={{ position: 'relative' }}>
          {hideAddNewService ? null : (
            <animated.div
              style={{
                ...animateProps,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginRight: 10,
              }}
            >
              <NewServiceButton
                buttonType={'link'}
                label={t('translation.ServiceSelect.newService')}
                onClick={props.onNewService}
                name={'addNewService'}
              />
            </animated.div>
          )}
          {props.children}
        </div>
      </components.MenuList>
    )
  }
  const MobileOption = props => {
    const { orgId } = useParams<any>()
    const { useQuery } = useSdk()
    const isSelected = props.selectProps.value?.id === props?.data?.id
    const loadOrg = useQuery(getOrganizationByIdGql, {
      variables: { id: orgId },
    })
    const { data: organizationData } = loadOrg
    const currency = organizationData?.organization?.get?.currency?.currencyCode
    const hasProfession = employeeProfessions.includes(
      props.data?.profession?.id
    )

    return (
      <SelectRow style={{ margin: '0 -9px', width: 'auto' }}>
        <ServiceColumnLeft>
          <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0 self-center">
            {isSelected && <CheckMarkIcon color="grayDarker" />}
          </span>
          <SelectOptionWarning style={{ width: '20px', marginRight: '10px' }}>
            {!hasProfession ? (
              <Tooltip label={t('translation.Appointment.noProfession')}>
                <WarningTriangle
                  size={'small'}
                  color={selectedTheme.colors.accent1}
                />
              </Tooltip>
            ) : null}
          </SelectOptionWarning>
          <ServiceCircle
            color={props.data?.color || selectedTheme.colors.primary}
            style={{ marginRight: 12, alignSelf: 'center' }}
          />

          <ServiceNameMobileSelect>{props.data?.name}</ServiceNameMobileSelect>
        </ServiceColumnLeft>
        <ServiceColumnRight>
          <SelectOptionLabel style={{ marginRight: 0, marginBottom: 5 }}>
            {props.data?.durationMinutes
              ? props.data?.durationMinutes +
                t('translation.ServiceSelect.durationMinutes')
              : ''}
          </SelectOptionLabel>
          <SelectOptionLabel style={{ marginRight: 0 }}>
            {props.data?.price
              ? props.data?.price + ' ' + (currency ? currency : '')
              : ''}
          </SelectOptionLabel>
        </ServiceColumnRight>
      </SelectRow>
    )
  }
  const IndicatorsContainer = props => {
    return (
      <div style={{ cursor: 'pointer' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    )
  }
  const SingleValue = props => {
    const { t } = useTranslation()
    const hasProfession = employeeProfessions.includes(
      props.data?.profession?.id
    )

    return (
      <components.SingleValue {...props}>
        <ItemRow>
          <WorkHourName isDisabled={props.isDisabled}>
            {props.data?.name}
          </WorkHourName>

          {!hasProfession ? (
            <Tooltip label={t('translation.Appointment.noProfession')}>
              <WarningTriangle
                size={'small'}
                color={selectedTheme.colors.accent1}
              />
            </Tooltip>
          ) : null}
        </ItemRow>
      </components.SingleValue>
    )
  }
  const MobileMenuList = (props, t) => {
    return (
      <ServiceMenuContainer>
        <NewServiceButtonContainer>
          <NewServiceButtonMobile
            buttonType={'primary'}
            label={props.t('translation.AppointmentModal.title-newService')}
            onClick={props.onNewService}
            name={'addNewService'}
            iconComponent={<Add size={'size14'} style={{ strokeWidth: 3 }} />}
            fullWidth
            size={'medium'}
          />
        </NewServiceButtonContainer>
        {props.children}
      </ServiceMenuContainer>
    )
  }

  const Option = props => {
    const hasProfession = employeeProfessions.includes(
      props.data?.profession?.id
    )
    const { orgId } = useParams<any>()
    const { useQuery } = useSdk()
    const loadOrg = useQuery(getOrganizationByIdGql, {
      variables: { id: orgId },
    })
    const { data: organizationData } = loadOrg
    const currency = organizationData?.organization?.get?.currency?.currencyCode
    return (
      <components.Option {...props}>
        <SelectRow style={{ width: '100%' }}>
          <SelectOptionWarning>
            {!hasProfession ? (
              <Tooltip label={t('translation.Appointment.noProfession')}>
                <WarningTriangle
                  size={'small'}
                  color={selectedTheme.colors.accent1}
                />
              </Tooltip>
            ) : null}
          </SelectOptionWarning>
          <ServiceName style={{ minWidth: 200 }}>
            {' '}
            {props.data?.name}
          </ServiceName>
          <SelectOptionLabel style={{ minWidth: 50 }}>
            {props.data?.durationMinutes
              ? props.data?.durationMinutes +
                t('translation.ServiceSelect.durationMinutes')
              : ''}
          </SelectOptionLabel>
          <SelectOptionLabel
            style={{ marginRight: 0, minWidth: 65, textAlign: 'end' }}
          >
            {props.data?.price
              ? props.data?.price + ' ' + (currency ? currency : '')
              : ''}
          </SelectOptionLabel>
        </SelectRow>
      </components.Option>
    )
  }

  const selectComponents = {
    MenuList: props => (
      <CustomMenuList {...props} onNewService={onNewService} />
    ),
    MobileMenuList: props => (
      <MobileMenuList {...props} onNewService={onNewService} t={t} />
    ),
    IndicatorsContainer,
    Option,
    MobileOption,
    SingleValue,
  }

  return (
    <FormField.Select
      label={t('translation.ServiceEditorForm.label-service')}
      options={options?.sort((a, b) => b?.hasProfession - a?.hasProfession)}
      hideSelectedOptions={false}
      isClearable={false}
      components={selectComponents}
      styles={selectStyles}
      name={name}
      isDisabled={isDisabled || false}
      formatValue={val => val?.id}
      parseValue={val => {
        //because of the grouped services, nested arrays
        return flattenedServices?.find(e => e.id === val)
      }}
      placeholder={t('translation.ServiceSelect.selectService')}
      filterOption={filterOptions}
      isSearchable={true}
      isServiceSelect={true}
    />
  )
}

const NewServiceButton = styled(Button)`
  position: sticky;
  top: -35px;
  margin-left: 22px;
`
const ServiceMenuContainer = styled.div`
  position: relative;
  overflow-y: auto;
  margin-top: 3px;
  margin-bottom: 55px;
  &::-webkit-scrollbar {
    display: none;
  }
`
const NewServiceButtonMobile = styled(Button)`
  width: calc(100% - 40px);
`
const NewServiceButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 59px;
  left: 20px;
  background: ${props => props.theme.colors.light};
  padding: 9px 0 10px;
  border-top: 1px solid ${props => props.theme.colors.light};
`

const ServiceColumnLeft = styled.div`
  display: flex;
  margin-right: auto;
`
const ServiceColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const ServiceNameMobileSelect = styled(ServiceName)`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: unset;
`
