import React, { useContext, useRef } from 'react'
import { PopupStore, usePopupStore } from 'src/state/local/popupStore'
import { createPortal } from 'react-dom'
import { MobileContext } from 'src/Context'
import { useOnClickOutside } from 'src/helpers'
import { ButtonTW } from '../ButtonTW'

export const PopupComponent = () => {
  const { popup: popupConfig } = usePopupStore()
  const {
    isVisible,
    onCancel,
    onConfirm,
    titleText,
    contentText,
    cancelButtonLabel,
    confirmButtonLabel,
  } = popupConfig

  const { hidePopup } = PopupStore

  const isMobile = useContext(MobileContext)

  const modalRef = useRef<any>()
  useOnClickOutside(modalRef, () => {
    hidePopup()
  })

  if (!isVisible) return null
  return createPortal(
    <div className="flex flex-col bg-black/70 top-0 left-0 fixed z-9999999999 h-full w-full pt-7 items-center justify-center animate-fading-in shadow-md">
      <div
        ref={modalRef}
        className={`flex flex-col grow-0 items-center bg-white bg-auto m-auto rounded  justify-center outline-0 p-5 animate-staging-in ${
          !isMobile ? 'w-[400px]' : 'w-11/12'
        }`}
      >
        {titleText && (
          <div className="mb-10 text-xl text-center font-semibold">
            {titleText}
          </div>
        )}
        {contentText && (
          <div className="text-md font-normal mb-10 text-center">
            {contentText}
          </div>
        )}
        <div className="flex items-center justify-around w-full">
          <ButtonTW
            label={cancelButtonLabel}
            onClick={onCancel}
            variant="text"
          />
          <ButtonTW label={confirmButtonLabel} onClick={onConfirm} />
        </div>
      </div>
    </div>,

    document.getElementById('react-select-portal-target') as HTMLDivElement
  )
}
