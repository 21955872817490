import { Button, FormContentContainer, FormField } from 'components'
import { FieldArray } from 'formik'
import moment from 'moment'
import transparentize from 'polished/lib/color/transparentize'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { Add } from 'src/components/Icon/Icons/Add'
import { Trash } from 'src/components/Icon/Icons/Trash'
import { capitalizeFirstLetter } from 'src/helpers'
import { FieldColumn, FieldRow } from 'src/sdk/tw/Form'
import { trpc } from 'src/trpc'
import styled from 'styled-components'
import { device } from '../../constants'
import { DatePicker } from './components/DatePicker'
import { TServiceProps } from './components/Service'
import { StartTimeSelect } from './components/StartTimeSelect'

type TCopyAppointmentProps = {
  event: any
  setFieldValue: any
  timeSlotDuration: number
  getDefaultAppointmentCopy: () => void
  flattenedServices: TServiceProps[]
  isPrepaymentDisabled: boolean
}

export const CopyAppointment = (props: TCopyAppointmentProps) => {
  const {
    event,
    setFieldValue,
    timeSlotDuration,
    getDefaultAppointmentCopy,
    flattenedServices,
    isPrepaymentDisabled,
  } = props

  const { locationId, orgId } = useParams<{
    locationId: string
    orgId: string
  }>()

  const {
    data: startTimesData,
    isLoading: startTimesLoading,
  } = trpc.startTimeGet.useQuery({
    orgId: orgId,
    locationId: locationId,
    timeSlotDuration,
    employeeId: null,
    date: moment(event?.date).format('YYYY-MM-DD'),
  })

  const { t, isMobile } = useSdk()

  const shouldShowEmailCheckBox = event?.appointmentCopySendPaymentRequest
    ? false
    : !!event?.client?.email

  return (
    <>
      <Wrapper>
        <TitleRow>
          <EventDateTitle>
            {t('translation.Header.label-copyAppointment-title')}
          </EventDateTitle>
        </TitleRow>
      </Wrapper>

      <FormContentContainer>
        <EventContainer style={{ minHeight: 220 }}>
          <FieldArray name="appointmentCopies">
            {({ push, remove, replace, form }) => {
              return (
                <>
                  <div className="border border-t-0 border-r-0 border-l-0 border-b-zoyya-lightBackground pb-5">
                    <div className="mb-5">
                      <div className="p-4 pb-5 lg:border border webkitThinScrollbar2  bg-zoyya-lighterBackground border-zoyya-grayDarker rounded-md flex flex-col gap-4 lg:max-h-[300px] max-h-[230px] overflow-auto ">
                        <div className="flex flex-row">
                          <div className="flex flex-col flex-1">
                            <div className="text-sm text-zoyya-secondaryDark">
                              {t('translation.AppointmentWizard.client')}
                            </div>
                            <div className="font-medium">
                              {event?.client?.fullName || '-'}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <div className="flex flex-row gap-3 items-center">
                            <div className="text-sm  flex flex-[2] text-zoyya-secondaryDark">
                              {t(
                                'translation.AppointmentWizard.label-services'
                              )}
                            </div>
                            {/*  <div className="text-sm flex flex-1 text-zoyya-secondaryDark">
                            {t('translation.AppointmentWizard.label-needed')}
                          </div> */}
                            <div className="w-18 text-sm text-zoyya-secondaryDark text-right min-w-max">
                              {t(
                                'translation.AppointmentWizard.label-duration'
                              )}
                            </div>
                            <div className="w-6 text-sm text-zoyya-secondaryDark text-right min-w-max"></div>
                          </div>
                          <div className="flex flex-col">
                            {event.services.map(bookingService => {
                              const service = flattenedServices?.find(
                                x => x.id === bookingService.service
                              )

                              return (
                                <div
                                  key={service?.id.toString()}
                                  className="text-sm lg:text-md flex flex-row border-b last:border-0 last:-mb-3 border-white py-1 last:pb-0 items-center gap-3"
                                >
                                  <div className="flex-[2] line-clamp-1 break-words">
                                    {service?.name}
                                  </div>
                                  {/*  <div className="flex-1">
                                  {service?.resourceType?.name}
                                </div> */}

                                  <div className="flex flex-row  items-center mr-6">
                                    <div className="min-w-max">
                                      {service?.durationMinutes || 0} min
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    {event?.appointmentCopies?.map((appCopy, index) => {
                      return (
                        <div
                          className="flex flex-row justify-start gap-5 items-center my-3"
                          key={index + appCopy?.startTimeUtc}
                        >
                          {!isMobile ? (
                            <div className="w-[80px]">
                              {capitalizeFirstLetter(
                                moment(appCopy.date).format('dddd')
                              )}
                            </div>
                          ) : null}
                          <div className="">
                            <DatePicker
                              isAbsolute
                              selectedDayId={moment(
                                event?.appointmentCopies[index].date
                              ).format('YYYY-MM-DD')}
                              isDisabled={index === 0}
                              view={'day'}
                              dontGoToCalendarAfterMonthChange
                              setDate={date => {
                                setFieldValue(
                                  `appointmentCopies[${index}].date`,
                                  date
                                )
                              }}
                              inputComponent={
                                <FormField.Date
                                  name={`appointmentCopies[${index}].date`}
                                  disabled
                                  greyTextIfDisabled={index === 0}
                                />
                              }
                            />
                          </div>
                          <div>
                            <div className="flex gap-2 flec-col items-center">
                              <StartTimeSelect
                                plusBtnOnClick={() => ({})}
                                minusBtnOnClick={() => ({})}
                                minusBtnDisabled={true}
                                plusBtnDisabled={true}
                                isDisabled={index === 0}
                                hideLabel
                                name={`appointmentCopies[${index}].startTime`}
                                hasPlusMinusButtons={false}
                                workHours={startTimesData?.slice(0, -1)}
                              />
                              <span>-</span>
                              <StartTimeSelect
                                plusBtnOnClick={() => ({})}
                                minusBtnOnClick={() => ({})}
                                minusBtnDisabled={true}
                                hideLabel
                                plusBtnDisabled={true}
                                hideChevron
                                hideSeparator
                                isDisabled={true}
                                name={`appointmentCopies[${index}].endTime`}
                                hasPlusMinusButtons={false}
                                workHours={startTimesData?.slice(0, -1)}
                              />
                            </div>
                          </div>
                          {index !== 0 ? (
                            <button
                              onClick={e => {
                                e.preventDefault()
                                remove(index)
                              }}
                              className="p-2 pr-0"
                              type="button"
                            >
                              <Trash
                                size="size16"
                                stroke={2.3}
                                className="text-zoyya-accent5 hover:text-zoyya-accent5/70"
                              />
                            </button>
                          ) : null}
                        </div>
                      )
                    })}
                    <div className="mt-6 flex w-full ">
                      <Button
                        label={'Dodaj novi'}
                        buttonType={'link'}
                        iconComponent={<Add size={'extraSmall'} stroke={4} />}
                        onClick={e => {
                          e.preventDefault()

                          push(getDefaultAppointmentCopy())
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-8">
                    {event?.client?.email ? (
                      <FieldRow style={{ marginTop: 0 }}>
                        <FieldColumn>
                          <FormField.Checkbox
                            label={t(
                              'translation.AppointmentModal.sendPaymentNotification'
                            )}
                            name="appointmentCopySendPaymentRequest"
                            type={'checkbox'}
                            disabled={isPrepaymentDisabled}
                            helpText={t(
                              'translation.AppointmentModal.sendPaymentNotification-hint'
                            )}
                          />
                        </FieldColumn>
                      </FieldRow>
                    ) : null}

                    {shouldShowEmailCheckBox ? (
                      <FieldRow>
                        <FieldColumn>
                          <FormField.Checkbox
                            label={t(
                              'translation.UpdateStatusModal.sentClientEmail'
                            )}
                            name="appointmentCopySendAppointmentEmail"
                            type={'checkbox'}
                            /* helpText={
                                      isNew
                                        ? t(
                                            'translation.UpdateStatusModal.sendEmailHintNew'
                                          )
                                        : t(
                                            'translation.UpdateStatusModal.sendEmailHint'
                                          )
                                    } */
                            helpText={t(
                              'translation.UpdateStatusModal.sendEmailHintNew'
                            )}
                          />
                        </FieldColumn>
                      </FieldRow>
                    ) : null}
                  </div>
                </>
              )
            }}
          </FieldArray>
        </EventContainer>
      </FormContentContainer>
    </>
  )
}

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 15px 25px 5px 25px;
  background: ${props => props.theme.colors.lightBackground};

  @media ${device.tablet} {
    flex-direction: column;
    padding: 15px 15px 5px 15px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.span`
  color: #333;
  font-family: Poppins, SFProDisplay, Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
  margin-right: 10px;
  min-width: 70px;
`

export const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
  max-width: 255px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type TStatusLabelProps = {
  status: string
}
export const StatusLabel = styled.div<TStatusLabelProps>`
  background: ${props =>
    props.status === 'cancel' || props.status === 'cancelBooking'
      ? transparentize(0.2, props.theme.colors.accent5)
      : transparentize(0.2, props.theme.colors.primary)};
  color: ${props => props.theme.colors.light};
  padding: 5px 10px;
  border-radius: 4px;
`

type TTitleRowProps = {
  status?: string
}
export const TitleRow = styled.div<TTitleRowProps>`
  padding: 11px 25px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  display: flex;
  justify-content: space-between;
  background: ${props =>
    props.status === 'cancel' || props.status === 'cancelBooking'
      ? props.theme.colors.accent1
      : props.status === 'finish'
      ? props.theme.colors.primary
      : props.status === 'confirm'
      ? props.theme.colors.accent2
      : props.theme.colors.light};

  @media ${device.tablet} {
    padding: 10px;
  }
`
export const EventContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }

  @media ${device.tablet} {
    max-height: 100%;
    overflow: hidden;
    padding: 10px 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TEventDateTitleProps = {
  status?: string
}
export const EventDateTitle = styled.div<TEventDateTitleProps>`
  font-size: 22px;
  cursor: pointer;
  color: ${props =>
    props.status === 'cancel' ||
    props.status === 'cancelBooking' ||
    props.status === 'finish' ||
    props.status === 'confirm'
      ? props.theme.colors.light
      : props.theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`
