import React from 'react'
import { Flex } from '../Flex/Flex'
import styled from 'styled-components'
import { device } from '../../constants'

const StyledToolbarContainer = styled(Flex.Row)``
const StyledItemsContainer = styled(Flex.Row)`
 > :nth-child(n) {
    margin-left: 14px;
  }

  > :first-child { margin-left: 0 !important; }

  @media ${device.tablet}{
    display: flex;
    flex-direction: row;
  }
`
export const Toolbar = props => {
  return (
    <StyledToolbarContainer>
      <StyledItemsContainer>{props.children}</StyledItemsContainer>
    </StyledToolbarContainer>
  )
}
