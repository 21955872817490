import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Register = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 6.5h0a5.5 5.5 0 105.287 7H12.5l1 1 1-1h1l1 1 1-1H19l1.5-1.5-1.5-1.5h-7.713 0A5.5 5.5 0 006 6.5z"></path>
        <path d="M5.06 10.941a1.5 1.5 0 11-2.12 2.122 1.5 1.5 0 012.12-2.122M10.5 19.522V22.5h0a1 1 0 001 1h11a1 1 0 001-1v-21 0a1 1 0 00-1-1h-11 0a1 1 0 00-1 1s0 0 0 0v4"></path>
      </g>
    </StyledIcon>
  )
}
