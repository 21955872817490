import React from 'react'
import Text from 'components/Text/Text'
import ToggleSwitch from 'views/Settings/Billing/Modals/ToggleSwitch'
import { ButtonStyled, Strong } from 'views/Settings/Billing/Modals/BillingForm'
import i18n from 'src/localization'
import { useSdk } from 'sdk'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { CheckFilled } from 'components/Icon/Icons/CheckFilled'
import { analyticsTrack, formatPriceNumberWithDelimiter } from 'src/helpers'
import {
  Step1Card,
  Step1TitleWrapper,
  Step1Image,
  Step1FeatureList,
  Step1Feature,
  Step1Pricing,
  Step1Wrapper,
  Step1Picker,
  Step1Price,
  Step1Badge,
  Step1Interval,
  Step1Row,
  Step1ToggleWrapper,
  Step1ToogleText,
  Step1PDV,
} from 'views/Settings/Billing/Modals/style'
import { ModalRightWarning } from './ConfirmationForm'
import {
  GetOrganizationByIdQuery,
  PaymentFeatures,
  PaymentPlan,
  PaymentPlans,
} from 'src/state/graphql'

type TPlanProps = {
  name: string
  imageUrl: string
  features: PaymentFeatures[]
  priceMonthly: number
  priceYearly: number
  selectedCycle: string
  isCurrentPlan: boolean
  selectPlan: () => void
  activePlan: string
  currency?: string
  id: string
  isTrial: boolean
  org: GetOrganizationByIdQuery['organization']['get'] | null
  shouldShowPlanQuantity: boolean
}

const Plan = (props: TPlanProps) => {
  const {
    name,
    imageUrl,
    features,
    priceMonthly,
    priceYearly,
    selectedCycle,
    isCurrentPlan,
    selectPlan,
    activePlan,
    currency,
    id,
    isTrial,
    org,
    shouldShowPlanQuantity,
  } = props

  const { t } = useSdk()

  const planQuantity = org?.subscriptionQuantity || 1
  const isCroatianOrg = org?.country?.countryCode === 'HR'

  const price =
    selectedCycle === '1 month'
      ? parseFloat((priceMonthly * (isCroatianOrg ? 0.8 : 1)).toFixed(2))
      : parseFloat(
          (((priceYearly * (isCroatianOrg ? 0.8 : 1)) / 12) * 100).toFixed(2)
        ) / 100

  return (
    <Step1Card
      data-cy={`plan_${id}`}
      selected={isCurrentPlan}
      onClick={() => {
        selectPlan()
        analyticsTrack('PLAN selected', { plan: id })
      }}
    >
      <Step1TitleWrapper>
        <Text type="span">{name}</Text>
        {activePlan === id && !isTrial && (
          <Step1Badge>{t('translation.PlansForm.aktivan')}</Step1Badge>
        )}
        {(activePlan !== id || isTrial) && (
          <Checkbox
            checked={isCurrentPlan}
            style={{
              marginLeft: 0,
              marginTop: 0,
              marginBottom: 10,
            }}
          />
        )}
      </Step1TitleWrapper>

      <Step1Image src={imageUrl} />
      <Step1FeatureList>
        {features?.map(feature => (
          <Step1Feature>
            <CheckFilled size={'extraSmall'} color={'#6a4ee1'} />

            <Text type="span">{feature.name}</Text>
          </Step1Feature>
        ))}
      </Step1FeatureList>
      <Step1Pricing>
        <Text type="span">
          <Step1Price>
            {shouldShowPlanQuantity && id !== 'free'
              ? `${planQuantity} x `
              : ''}{' '}
            {id !== 'free' ? formatPriceNumberWithDelimiter(price) : price}{' '}
            {currency}
          </Step1Price>
          {id !== 'free' ? (
            <>
              <Step1Interval>/mj</Step1Interval>
              {isCroatianOrg ? (
                <Step1PDV>{t('translation.PlansForm.pdv')}</Step1PDV>
              ) : null}
            </>
          ) : null}
        </Text>
      </Step1Pricing>
    </Step1Card>
  )
}

type TPlansFormProps = {
  selectPlan: (plan: string) => void
  selectedPlan: PaymentPlan | null
  cycle: string
  changeCycle: (value: string) => void
  activePlan: string
  plans: PaymentPlans['plans']
  disabledCycle: boolean
  isTrial: boolean
  trialDaysRemaining: number
  org: GetOrganizationByIdQuery['organization']['get'] | null
  shouldShowPlanQuantity: boolean
  currency?: string
}

export const PlansForm = (props: TPlansFormProps) => {
  const {
    selectPlan,
    selectedPlan,
    cycle,
    changeCycle,
    activePlan,
    plans,
    disabledCycle,
    currency,
    isTrial,
    trialDaysRemaining,
    org,
    shouldShowPlanQuantity,
  } = props

  const { t, isMobile } = useSdk()
  return (
    <div style={{ padding: '0px 20px 20px 20px' }} data-cy={'plansForm'}>
      <Step1Wrapper>
        <Text type="span">
          {selectedPlan?.id !== 'free' && (
            <div style={{ marginRight: '20px' }}>
              {t('translation.PlansForm.you-have-selected-the')}{' '}
              <Strong>{selectedPlan?.name}</Strong>{' '}
              <span style={{ marginRight: 5 }}>
                {t('translation.PlansForm.plan-and')}
              </span>
              <Strong>{t(`translation.PlansForm.${cycle}`)}</Strong>{' '}
              {t('translation.PlansForm.billing')}
            </div>
          )}
        </Text>
        <Step1ToggleWrapper>
          <ToggleSwitch
            onClick={changeCycle}
            enabled={cycle === '365 days'}
            disabled={disabledCycle}
            leftLabel={t('translation.PlansForm.1 month')}
            rightLabel={t('translation.PlansForm.365 days')}
          />
          <Step1ToogleText>
            {t('translation.PlansForm.2-months-free')}
          </Step1ToogleText>
        </Step1ToggleWrapper>
      </Step1Wrapper>
      <Step1Picker>
        {plans?.map((plan, idx) => {
          return (
            <Plan
              key={idx}
              selectPlan={() => {
                plan && selectPlan(plan?.id)
              }}
              id={plan?.id}
              imageUrl={plan?.imageUrl || ''}
              name={plan?.name}
              priceMonthly={plan?.priceMonthly}
              priceYearly={plan?.priceYearly}
              features={plan?.features}
              selectedCycle={cycle}
              isCurrentPlan={selectedPlan?.id === plan?.id}
              activePlan={activePlan}
              currency={currency}
              isTrial={isTrial}
              org={org}
              shouldShowPlanQuantity={shouldShowPlanQuantity}
            />
          )
        })}
      </Step1Picker>

      {isTrial && trialDaysRemaining > 0 && selectedPlan?.id === 'advanced' ? (
        <ModalRightWarning
          style={{
            marginTop: 20,
            marginLeft: !isMobile ? 10 : 0,
            marginRight: !isMobile ? 12 : 0,
          }}
        >
          <span>
            {t('translation.PlansForm.trial-upgrade-warning', {
              trialDaysRemaining,
            })}
          </span>
        </ModalRightWarning>
      ) : null}

      <Step1Row>
        <ButtonStyled
          buttonType="link"
          label={t('translation.PlansForm.learn-more-about-these-plans')}
          onClick={() => window.open('https://zoyya.com/pricing', '_blank')}
        />
      </Step1Row>
    </div>
  )
}
