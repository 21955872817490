import { grayLight } from 'components/style/colors'
import styled from 'styled-components'
import { fontFamily, fontWeightMedium } from 'components/style/fonts'
import { device } from '../constants'
import { transparentize } from 'polished'
import { NavBarChavron, NavBarName } from 'components/NavBarMenu/style'
import { StyledSelect } from 'components/Select2/style'
import { DatePickerCalendarContainer } from 'views/Calendar/components/DatePicker/styles'
import { boolean } from 'yup'

export const SidebarLeft = styled.div<{ orgSidebar?: boolean }>`
  width: 60px;
  height: 100%;
  background: #ffffff;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.orgSidebar
      ? props.theme.colors.orgSettingsSidebar
      : props.theme.colors.primary};
`

export const SidebarLeftItems = styled.div<{
  isBottom?: boolean
  orgSidebar?: boolean
}>`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.light};
  flex-direction: column;
  background: ${props =>
    props.orgSidebar
      ? props.theme.colors.orgSettingsSidebar
      : props.theme.colors.primary};
  margin-bottom: ${props => (props.isBottom ? '0' : 'auto')};
  margin-top: ${props => (props.isBottom ? 'auto' : '0')};
`

export const SidebarLeftItem = styled.div<{
  isSelected?: boolean
  isCollapsed?: boolean
  orgSidebar?: boolean
  isTriangleDark?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.isSelected && !props.isCollapsed
      ? props.theme.colors.light
      : props.isSelected && props.isCollapsed && !props.orgSidebar
      ? props.theme.colors.primary
      : props.isSelected && props.isCollapsed && props.orgSidebar
      ? props.theme.colors.orgSettingsSidebar
      : transparentize(0.3, props.theme.colors.light)};

  background: ${props =>
    props.isSelected && props.isCollapsed
      ? props.theme.colors.light
      : 'transparent'};
  width: ${props => (props.orgSidebar ? '50px' : '45px')};
  height: ${props => (props.orgSidebar ? '50px' : '45px')};
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.1s ease-in-out;
  transition-property: color, stroke-width;
  &:hover {
    color: ${props =>
      props.isSelected ? undefined : props.theme.colors.light};
    //font-weight: ${props => (props.isSelected ? undefined : 600)};
    svg {
      stroke-width: ${props => (props.isSelected ? undefined : '1.65px')};
    }
  }
  &:after {
    border-style: solid;
    border-width: 15.5px 10px 15.5px 0;
    content: '';
    display: ${props =>
      props.isSelected && !props.isCollapsed ? 'block' : 'none'};
    position: absolute;
    top: 50%;
    right: -9px;
    height: 0;
    width: 0;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    border-color: ${props =>
      props.isTriangleDark
        ? 'transparent #dcdde5 transparent transparent'
        : 'transparent #edeef4 transparent transparent'};
  }

  svg {
    margin-top: 4px;
  }
`

export const SidebarRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`

export const SidebarHint = styled.span`
  color: inherit;
  font-family: ${fontFamily};
  font-size: 8px;
  max-width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  line-height: 12px;
  @media ${device.tablet} {
    font-size: 0.9rem;
    line-height: 1rem;
    color: ${props => props.theme.colors.secondaryDark};
    margin-top: initial;
  }
`

export const SidebarIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LogoIcon = styled.img`
  height: 35px;
`

export const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background: ${props => props.theme.colors.primary};
`
export const SidebarIcons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: auto;

  ${DatePickerCalendarContainer} {
    z-index: 9;
  }

  ${StyledSelect} {
    width: 235px;
    padding-left: 15px;
    margin-top: 5px;

    .react-select__control {
      background: transparent;
    }

    .react-select__control .react-select__value-container {
      flex-wrap: wrap;
    }

    .react-select__clear-indicator {
      display: none;
    }
  }
`

export const ListHeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

export const ListHeaderButton = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 1.286rem;
  span {
    color: ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.secondaryDark};
    font-size: 0.857rem;
    margin-right: 0.571rem;
  }

  span:first-child {
    font-size: 1rem;
  }
`

export const OrganizationSwitchIcon = styled.div<{
  size?: string
  background?: string
}>`
  height: ${props =>
    props.size === 'medium'
      ? '34px'
      : props.size === 'small'
      ? '26px'
      : props.size === 'large'
      ? '40px'
      : props.size === 'size50'
      ? '50px'
      : props.size === 'extraLarge'
      ? '64px'
      : '20px'};
  width: ${props =>
    props.size === 'medium'
      ? '34px'
      : props.size === 'small'
      ? '26px'
      : props.size === 'large'
      ? '40px'
      : props.size === 'size50'
      ? '50px'
      : props.size === 'extraLarge'
      ? '64px'
      : '20px'};
  font-style: normal;
  font-weight: 500;
  font-size: 0.786rem;
  line-height: 1.143rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.light};
  background: ${props => props.background};
  border-radius: 100px;
  min-width: 20px;
  text-transform: uppercase;
  min-height: 20px;
  margin-left: auto;
`

export const SidebarStyled = styled.div`
  display: flex;
  position: relative;
`
