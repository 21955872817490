import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: ${props => props.fill || 'none'};
`

export const WarningTriangle = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        fill="inherit"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M22.553 22.581h0a.569.569 0 01-.553.894H2h0a.569.569 0 01-.553-.894L11.553 2.37c.246-.492.648-.492.894 0zM12 16.98v-7"></path>
        <path d="M11.991 18.979h0a.246.246 0 00-.241.255h0a.255.255 0 00.254.245h.005a.246.246 0 00.241-.255h0a.255.255 0 00-.25-.245h-.005"></path>
      </g>
    </StyledIcon>
  )
}
