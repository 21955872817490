import { Loader } from 'components'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { ChangelogItem } from 'src/components/ChangelogItem/ChangelogItem'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { useACL } from 'src/sdk/acl'
import { trpc } from 'src/trpc'

export const GiftCardChangelog = ({ giftCardId }) => {
  const { locationId, orgId } = useParams<{ [key: string]: string }>()
  const { t, isMobile } = useSdk()
  const { hasPermission } = useACL()
  const trpcContext = trpc.useContext()

  const { data: logs, isLoading: changelogLoading } =
    trpc.giftCard_getChangelog.useQuery({
      orgId,
      locationId: BigInt(locationId),
      giftCardId,
    })

  useEffect(() => {
    trpcContext.giftCard_getChangelog.invalidate()
  }, [])
  const { data: orgRoles } = trpc.getOrgRoles.useQuery()

  return (
    <div className="flex-1">
      {changelogLoading ? (
        <div style={{ minHeight: '500px' }}>
          <Loader isComponent />
        </div>
      ) : (
        <div>
          {logs?.length ? (
            logs?.map(log => {
              return <ChangelogItem log={log} orgRoles={orgRoles || []} />
            })
          ) : (
            <EmptyList title="Nema zapisa u logu izmjena" />
          )}
        </div>
      )}
    </div>
  )
}
