export const withErrorHandler = <T extends (...args: any[]) => any>(fn: T) => {
  return (...args: Parameters<T>) => {
    try {
      return fn(...args)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
