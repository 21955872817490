import React, { useContext, useRef } from 'react'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import {
  FormPageContainer,
  FormContainer,
  FormFooterButtonsContainerMobile,
} from 'src/styles'
import {
  Button,
  DeleteButton,
  Form,
  FormField,
  FormFooterButtonsContainer,
  Loader,
} from 'components'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { FormFieldsWrapper } from 'components/Form/Form'
import { MobileContext } from 'src/Context'
import { useParams } from 'react-router'
// import { useProfessionAllQuery } from 'state/graphql'

type TResourceTypeEditorFormProps = {
  onSubmit: (values: any) => Promise<void>
  // initialValues: {
  //   name: String
  //   id?: String
  // } | null
  initialValues: any
  validationSchema: any
  isEdit: boolean
  loading: boolean
  onDelete?: () => any
}

export const ResourceTypeEditorForm = (props: TResourceTypeEditorFormProps) => {
  const {
    onSubmit,
    initialValues,
    validationSchema,
    isEdit,
    loading,
    onDelete,
  } = props
  const { t, navigateTo } = useSdk()
  const isMobile = useContext(MobileContext)
  let formRef = useRef<any>()
  const params = useParams<any>()
  const { selectedLocationId } = params

  useHeader({
    content: t('translation.ResourceTypeEditorForm.label-resource-type'),
    onGoBack: () => {
      navigateTo.resourcesList({ selectedLocationId, selectedTab: 1 })
    },
  })

  // const {
  //   data: professionsData,
  //   loading: loadingProfessions,
  // } = useProfessionAllQuery()

  // const professionsAll = professionsData?.profession?.all
  // const resourceTypes = professionsAll?.filter(x => x?.kind === 'RESOURCE')

  if (loading) return <Loader isComponent />

  return (
    <>
      <FormPageContainer>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {form => {
            formRef.current = form
            return (
              <FormFieldsWrapper>
                <FormContainer>
                  <FieldRow>
                    <FieldColumn>
                      <FormField.Text
                        label={t(
                          'translation.ResourceTypeEditorForm.label-name'
                        )}
                        name="name"
                        autoFocus
                      />
                    </FieldColumn>
                  </FieldRow>

                  {isEdit ? (
                    <DeleteButton
                      withConfirm
                      onConfirm={onDelete}
                      confirmationMessage={t(
                        'translation.ResourcesList.title-delete-resource-type'
                      )}
                    />
                  ) : null}
                  {!isMobile ? (
                    <FormFooterButtonsContainer>
                      <Button
                        footerButton
                        buttonType={'text'}
                        name="closeForm"
                        type={'button'}
                        label={t(
                          'translation.ResourceTypeEditorForm.button-close'
                        )}
                        onClick={() =>
                          navigateTo.resourcesList({
                            ...params,
                            selectedTab: 1,
                          })
                        }
                      />
                      <Button
                        type={'submit'}
                        buttonType={'primary'}
                        name="saveChanges"
                        label={t(
                          'translation.ResourceTypeEditorForm.button-save'
                        )}
                      />
                    </FormFooterButtonsContainer>
                  ) : null}
                </FormContainer>
              </FormFieldsWrapper>
            )
          }}
        </Form>
      </FormPageContainer>
      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.ResourceTypeEditorForm.button-close')}
            onClick={() =>
              navigateTo.resourcesList({
                ...params,
                selectedTab: 1,
              })
            }
          />
          <Button
            onClick={() => formRef.current.submitForm()}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceTypeEditorForm.button-save')}
          />
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}
