import React from 'react'
import { AppLoading, LoadingCenter, Spinner } from './styles'
import ReactDOM from 'react-dom'

export const Loader = props => {
  const { isComponent } = props
  return isComponent ? (
    <AppLoading isComponent={isComponent} data-cy={'zoyya-loading'}>
      <LoadingCenter>
        <Spinner image={'/loader.svg'}></Spinner>
      </LoadingCenter>
    </AppLoading>
  ) : (
    ReactDOM.createPortal(
      <AppLoading isComponent={isComponent} data-cy={'zoyya-loading'}>
        <LoadingCenter>
          <Spinner image={'/loader.svg'}></Spinner>
        </LoadingCenter>
      </AppLoading>,
      document.getElementById('react-select-portal-target') as any
    )
  )
}
