import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { fontFamily } from 'components/style/fonts'
import { device } from '../../constants'
import {
  Button,
  Form,
  FormContentContainer,
  FormField,
  FormFooterButtonsContainer,
  Loader,
} from 'components'
import { FieldColumn, FieldRow } from '../Settings/styles'
import { RatingStar } from 'components/Icon/Icons/RatingStar'
import { StyledFieldsContainer } from '../../components/Form/Form'
import { useSdk, DateUtil } from 'sdk'
import { publicAssets } from 'assets/publicAssets'
import NotFound from 'components/NotFound/NotFound'
import {
  useAppointmentGetReviewQuery,
  useAppointmentReviewMutation,
} from 'state/graphql'
export const Container = styled.div`
  font-family: ${fontFamily};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: end;
  position: relative;
  text-align: center;
  background: #7760db;
  height: 100vh;
  padding-bottom: 80px;
  img {
    width: 100vw;
    max-height: 55vh;
  }

  ${StyledFieldsContainer} {
    background: transparent;
    min-width: 600px;
    max-width: 100vw;

    label {
      color: white;
    }

    @media ${device.tablet} {
      min-width: 80vw;
      display: flex;
    }
  }

  @media ${device.tablet} {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
  }
`

export const Title = styled.h1`
  color: white;
  font-size: 1.5vw;
  font-weight: 500;
  margin-bottom: 1.429rem;

  @media ${device.tablet} {
    font-size: 16px;
  }
`

export const TitleBig = styled(Title)`
  font-size: 3vw;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 26px;
    margin-top: 20px;
  }
`

export const Text = styled.p`
  color: white;
  font-size: 1.143rem;
  margin-bottom: 1.429rem;
  line-height: 26px;
  max-width: 700px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`

export const RatingStars = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`

export const Label = styled.span`
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-right: 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 580px;
  max-width: 100vw;
  padding: 20px;

  @media ${device.tablet} {
    display: initial;
    min-width: 0px;
  }
`

export const BigText = styled.span`
  color: white;
  font-size: 20px;
`

const ReviewPage = () => {
  const { t, navigateTo, appServices } = useSdk()
  const { token } = useParams()

  const { data, loading: loadingQuery } = useAppointmentGetReviewQuery({
    variables: { token },
    skip: !token,
  })
  const [appointmentReviewMutation] = useAppointmentReviewMutation()
  const onSubmit = async values => {
    const result = await appointmentReviewMutation({
      variables: { comment: values.comment, rating: ratingValue, token },
    })
    appServices.handleMutationResult(result).onSuccess(navigateTo.thankYou)
  }

  const [ratingValue, setRatingValue] = useState(0)
  const reviewData = data?.appointment?.getReview

  useEffect(() => {
    if (reviewData?.rating) {
      setRatingValue(reviewData?.rating)
    }
  }, [reviewData])

  if (!token) return <NotFound />
  if (loadingQuery) return <Loader isComponent />

  return (
    <Container>
      <img src={publicAssets.reviewSvg} alt="" />
      <TitleBig>{reviewData?.locationName}</TitleBig>
      {reviewData?.rating ? (
        <Title>
          {`${t(
            'translation.ReviewPage.yourReviewTitle'
          )} ${DateUtil.toShortDateString(reviewData?.appointmentDate)}`}
        </Title>
      ) : (
        <Title>
          {`${t(
            'translation.ReviewPage.reviewTitle'
          )} ${DateUtil.toShortDateString(reviewData?.appointmentDate)}`}
        </Title>
      )}
      <RatingStars>
        <RatingStar
          size={'extraExtraLarge'}
          color={'#ffc359'}
          onClick={() => setRatingValue(1)}
          fill={ratingValue >= 1 ? '#ffc359' : 'none'}
        />
        <RatingStar
          size={'extraExtraLarge'}
          color={'#ffc359'}
          onClick={() => setRatingValue(2)}
          fill={ratingValue >= 2 ? '#ffc359' : 'none'}
        />
        <RatingStar
          size={'extraExtraLarge'}
          color={'#ffc359'}
          onClick={() => setRatingValue(3)}
          fill={ratingValue >= 3 ? '#ffc359' : 'none'}
        />
        <RatingStar
          size={'extraExtraLarge'}
          color={'#ffc359'}
          onClick={() => setRatingValue(4)}
          fill={ratingValue >= 4 ? '#ffc359' : 'none'}
        />
        <RatingStar
          size={'extraExtraLarge'}
          color={'#ffc359'}
          onClick={() => setRatingValue(5)}
          fill={ratingValue >= 5 ? '#ffc359' : 'none'}
        />
      </RatingStars>
      <Form onSubmit={onSubmit} initialValues={reviewData}>
        {() => {
          return (
            <>
              <FormContentContainer>
                <FieldRow>
                  <FieldColumn>
                    <FormField.TextArea
                      rows={2}
                      label={t('translation.ReviewPage.reviewComment')}
                      name="comment"
                      autoFocus
                    />
                  </FieldColumn>
                </FieldRow>
                <FormFooterButtonsContainer>
                  <Button
                    buttonType={'secondaryLink'}
                    type={'submit'}
                    label={t('translation.ReviewPage.reviewButtonLabel')}
                    style={{ margin: 'auto' }}
                    size={'large'}
                  />
                </FormFooterButtonsContainer>
              </FormContentContainer>
            </>
          )
        }}
      </Form>
    </Container>
  )
}
export default ReviewPage
