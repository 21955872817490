import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Week = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M5 22.5c0 .6-.2 1-.5 1h-2c-.3 0-.5-.4-.5-1v-20c0-.6.2-1 .5-1h2c.3 0 .5.4.5 1v20zM11 22.5c0 .6-.2 1-.5 1h-2c-.3 0-.5-.4-.5-1v-20c0-.6.2-1 .5-1h2c.3 0 .5.4.5 1v20zM17 22.5c0 .6-.2 1-.5 1h-2c-.3 0-.5-.4-.5-1v-20c0-.6.2-1 .5-1h2c.3 0 .5.4.5 1v20z"
        fill="none"
      ></path>
      <g>
        <path
          d="M23 22.5c0 .6-.2 1-.5 1h-2c-.3 0-.5-.4-.5-1v-20c0-.6.2-1 .5-1h2c.3 0 .5.4.5 1v20z"
          fill="none"
        ></path>
      </g>
    </StyledIcon>
  )
}
