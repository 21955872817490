import React, { CSSProperties, ReactNode, useContext } from 'react'
import { animated, useSpring } from 'react-spring'
import { MobileContext } from 'src/Context'

type AnimatedDivProps = {
  children?: ReactNode
  handlers?: any
  style?: CSSProperties
}

export const AnimatedDiv = ({
  children,
  handlers,
  style,
}: AnimatedDivProps) => {
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 700 },
  })

  const isMobile = useContext(MobileContext)

  return (
    <animated.div
      style={{
        ...animationProps,
        overflow: isMobile ? 'auto' : 'hidden',
        width: '100%',
        height: '100%',
        ...style,
      }}
      //@ts-ignore
      handlers={handlers}
    >
      {children}
    </animated.div>
  )
}
