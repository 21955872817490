import React, { FC } from 'react'
import { Button } from 'components'
import { useSdk } from 'sdk'
import { Checkbox } from 'components/Checkbox/Checkbox'
import styled from 'styled-components'
import { icons } from 'components/Icon/index'
import { device } from '../../../constants'
import { getDayName } from 'src/helpers'

type TWorkDayMobile = {
  day?: any
  workHours?: any
  setWorkHours?: any
  setIsOpen?: any
  setIsNewTime?: any
  setSelectedShift?: any
  setSelectedDay?: any
  isMondayChanged?: boolean
  signupFlow?: boolean
}

const WorkDayMobile: FC<TWorkDayMobile> = props => {
  const {
    day,
    workHours,
    setWorkHours,
    setIsOpen,
    setIsNewTime,
    setSelectedShift,
    setSelectedDay,
    isMondayChanged,
    signupFlow,
  } = props
  const { t } = useSdk()

  const onDeleteShiftHandle = index => {
    setWorkHours(
      workHours.map(item => {
        if (item.day === day?.day) {
          item.shift =
            day?.shift.length < 2
              ? [
                  {
                    openTime: '08:00',
                    closeTime: '16:00',
                    __typename: 'BusinessHours',
                  },
                ]
              : day?.shift.filter((shift, idx) => index !== idx)
        }
        return item
      })
    )
  }

  const onDayCheckboxClick = () => {
    setWorkHours(
      workHours.map(item => {
        if (item.day === day?.day) {
          item.shift = day?.shift?.length
            ? []
            : [
                {
                  openTime: '08:00',
                  closeTime: '16:00',
                  __typename: 'BusinessHours',
                },
              ]
        }

        return item
      })
    )
  }

  const onCopyToAllClick = e => {
    e.preventDefault()
    const shift = day?.shift
    setWorkHours(
      workHours.map(item => {
        if (item.day !== day?.day) item.shift = shift
        return item
      })
    )
  }

  return (
    <Container>
      <Top isClosed={!day?.shift?.length}>
        <Checkbox
          isSmall
          style={{ margin: 0, position: 'relative', top: 4 }}
          label={
            getDayName(day?.day)
              .charAt(0)
              .toUpperCase() +
            getDayName(day?.day)
              .slice(1, 3)
              .toLowerCase()
          }
          checked={day?.shift?.length}
          handleChange={onDayCheckboxClick}
        />
        {!day?.shift?.length ? (
          <div
            className="text-[16px] h-[30.25px] items-center ml-auto mr-0 flex justify-center"
            data-cy={'time-notWorking'}
          >
            {t('translation.LocationWorkHoursMobile.label-nonworking')}
          </div>
        ) : (
          <div className="flex items-center justify-end">
            <div>
              {day?.shift.map((shift, index) => {
                return (
                  <div
                    className="flex items-center justify-end [&:nth-child(2)]:mt-[10px]"
                    key={index}
                  >
                    <div
                      className="flex items-center justify-end relative top-[1]"
                      onClick={() => {
                        setIsOpen(true)
                        setIsNewTime(false)
                        setSelectedDay(day)
                        setSelectedShift({ ...shift, index })
                      }}
                    >
                      <span className="text-[16px] min-w-[45px] text-center">
                        {shift?.openTime}
                      </span>
                      <span className="text-[14px] m-[0_13px] text-zoyya-dark">
                        -
                      </span>
                      <span className="text-[16px] min-w-[45px] text-center">
                        {shift?.closeTime}
                      </span>
                    </div>
                    {day?.shift?.length > 1 ? (
                      <Button
                        onClick={() => onDeleteShiftHandle(index)}
                        style={{ paddingRight: 0, marginLeft: '25px' }}
                        buttonType={'link'}
                        name={'addShift'}
                        size={'small'}
                        hasIconOnly
                        iconComponent={<StyledCrossIcon size="extraSmall" />}
                      />
                    ) : null}
                  </div>
                )
              })}
            </div>
            {day?.shift?.length < 2 ? (
              <Button
                onClick={e => {
                  e.preventDefault()
                  setIsOpen(true)
                  setIsNewTime(true)
                  setSelectedDay(day)
                }}
                style={{ paddingRight: 0, marginLeft: '23px' }}
                buttonType={'link'}
                name={'addShift'}
                size={'small'}
                hasIconOnly
                iconComponent={
                  <icons.Add size="size14" style={{ strokeWidth: 3 }} />
                }
              />
            ) : null}
          </div>
        )}
      </Top>
      {(day?.shift?.length && day?.day === 'mon' && isMondayChanged) ||
      (day?.shift?.length && day?.day === 'mon' && signupFlow) ? (
        <CopyContainer>
          <icons.Copy size="small" />
          <CopyInner>
            <span className="mr-[10px]">
              {t('translation.LocationWorkHoursMobile.label-copy-to-all')}
            </span>

            <button
              className="text-zoyya-primary bg-transparent border-none text-[14px]"
              onClick={e => onCopyToAllClick(e)}
            >
              {t('translation.LocationWorkHoursMobile.button-copy-to-all')}
            </button>
          </CopyInner>
        </CopyContainer>
      ) : null}
    </Container>
  )
}
export default WorkDayMobile

const StyledCrossIcon = styled(icons.Cross)`
  stroke-width: 3;
  color: #dd3656;
`

const Container = styled.div`
  //min-height: 80px;
  padding: 20px 0;
  :not(:last-of-type) {
    border-bottom: 1px solid #f0f0f0;
  }
`

const Top = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-height: 32.5px;
  color: ${props => (props.isClosed ? '#a9acb3' : props.theme.colors.text)};
  input {
    width: 22px;
    margin-right: 0;
    margin-left: 0;
    :before {
      width: 25px;
      height: 25px;
      top: -6px;
    }
    :checked:before {
      width: 25px;
      height: 25px;
      top: -6px;
    }
    :checked:after {
      width: 7px;
      height: 14px;
      top: -2px;
      left: 6.5px;
    }
  }
  label {
    margin-bottom: 0 !important;
    font-size: 16px;
    margin-left: 13px;
    color: ${props => (props.isClosed ? '#a9acb3' : props.theme.colors.text)};
  }
`

const CopyContainer = styled.div`
  background: ${props => props.theme.colors.lightBackground};
  margin-top: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #6a4ee16b;
  display: flex;
  align-items: center;

  @media ${device.mobileM} {
    padding: 10px;
  }
`

const CopyInner = styled.div`
  margin-left: 20px;

  @media ${device.mobileM} {
    margin-left: 10px;
  }
`
