import React from 'react'
import { BannerDiv, BannerWrapper } from './styles'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'

export const ServiceWorkerMessage = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <BannerWrapper>
      <BannerDiv>
        <span>{t('translation.ServiceWorkerMessage.label-new-version')}</span>
        <Button
          onClick={onClick}
          label={t('translation.ServiceWorkerMessage.label-download')}
          buttonType={'secondary'}
        />
      </BannerDiv>
    </BannerWrapper>
  )
}
