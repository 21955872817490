import React from 'react'
import { ServiceItem, ServicePrice } from './styles'
import { PosReceiptStore } from 'state/local/posReceiptStore'
import { usePosStore } from 'state/local/posReceiptStore'
import { formatPriceNumberWithDelimiter } from 'src/helpers'
import { icons } from 'src/components'
import Tooltip from 'src/components/Tooltip/Tooltip'

export const PosReceiptItem = props => {
  const { service, org, editable, loading, productsOutOfStock } = props
  const { selectItem, removeSelectedItem, removeItem } = PosReceiptStore
  const receipt = usePosStore(store => store.pos)

  return (
    <>
      <ServiceItem
        isOutOfStock={
          service?.stockCount <= 0 ||
          productsOutOfStock.find(
            x => x.id === service.productId && x?.stock === 'out'
          )
        }
      >
        {service?.stockCount <= 0 ||
        productsOutOfStock.find(
          x => x.id === service.productId && x?.stock === 'out'
        ) ? (
          <Tooltip
            label={
              service?.stockCount <= 0
                ? 'Proizvoda nema na stanju'
                : 'Za odabrani proizvod nema dovoljno količine na stanju'
            }
          >
            <icons.WarningTriangle size="smaller" className="mr-2" />
          </Tooltip>
        ) : null}

        <span className="font-semibold mr-[5px]">{service.quantity}x</span>
        <span className="mr-auto">
          <div>
            <span>{service.itemDescription}</span>
            {service?.paymentRequestId && service?.paymentRequestReceiptNo ? (
              <span className="italic">{` (br: ${service?.paymentRequestReceiptNo})`}</span>
            ) : null}
          </div>
        </span>

        <div className="flex flex-col m-[0_30px] items-end shrink-0">
          {service.discount ? (
            <span>
              {formatPriceNumberWithDelimiter(service?.finalPrice) +
                ' ' +
                org?.currency?.id}{' '}
            </span>
          ) : null}
          <ServicePrice discount={service.discount}>
            {formatPriceNumberWithDelimiter(service?.price) +
              ' ' +
              org?.currency?.id}
          </ServicePrice>
        </div>

        {editable && !loading ? (
          service?.paymentRequestId ? (
            <div className="w-[18px] h-[18px]"></div>
          ) : service?.giftCardId ? (
            <icons.Trash
              size="smaller"
              onClick={() => {
                receipt?.selectedItem && removeSelectedItem()
                removeItem(service.id)
              }}
              style={{ flexShrink: 0, cursor: 'pointer' }}
            />
          ) : (
            <icons.Edit
              size="smaller"
              onClick={() => {
                receipt?.selectedItem && removeSelectedItem()
                selectItem(service.id)
              }}
              style={{ flexShrink: 0, cursor: 'pointer' }}
            />
          )
        ) : null}
      </ServiceItem>
    </>
  )
}
