import gql from 'graphql-tag'

export const getOrganizationByIdGql = gql`
  query getOrganizationById($id: ID) {
    organization {
      get(id: $id) {
        id
        orgName
        description
        avatarUrl
        webUrl
        vatId
        address
        zipCode
        phone
        mobilePhone
        city
        orgUrlName
        paymentEnabled
        downgradeAmount
        downgradeDate
        language
        downgradePlan
        vatStatus
        stripeCurrencyId
        stripePriceId
        prepaymentsProvider
        stripeConnectAccountId

        orgFeatures {
          planId
          hasClients
          hasCustomTimeSlotDuration
          hasEmployees
          hasFreeSms
          hasHolidays
          hasInvitations
          hasLocations
          hasOrgUrlName
          hasProfessions
          hasReminders
          hasReporting
          hasResources
          hasSchedule
          hasPos
          hasOnlineBooking
        }
        paymentPlans {
          id
          plans {
            id
            name
            imageUrl
            priceMonthly
            priceYearly
            features {
              id
              name
            }
            interval
          }
          upgradeChargeAmountMonthly
          upgradeChargeAmountYearly
        }
        country {
          id
          countryName
          countryCode
        }
        email
        currency {
          id
          currencyCode
          currencyName
        }
        subscriptionStartDate
        subscriptionType
        subscriptionId
        subscriptionEndDate
        subscriptionCustomerId
        subscriptionMandateId
        subscriptionPlan
        subscriptionStatus
        subscriptionAmount
        subscriptionCreditCardNumber
        subscriptionCreditCardType
        subscriptionCreditCardHolderName
        subscriptionCreditCardExpirationDate
        subscriptionQuantity
        subscriptionCycle
        hasVivaMerchantId
        hasVivaApiKey
        locations {
          id
          orgId
          code
          name
          isActive
          mobilePhone
          description
          phone
          email
          city
          onlineBooking_listOnZoyya
          onlineBooking_status
          country {
            id
            countryName
            countryCode
          }
          zipCode
          address
          imageUrl
          categories {
            id
            name
            icon_url
          }
        }
        smsReminders_enabled
        vatStatus
        vatExemptText
      }
    }
  }
`
