import React, { useEffect, useRef, useState } from 'react'
import {
  DatePickerCalendarContainerMobile,
  DatePickerLeftMobile,
  DatePickerHeaderMobile,
  DateArrowMobile,
  DatePickerNameMobile,
  DatePickerCalendarStyledMobile,
  MonthDayNameMobile,
  DatePickerCalendarDayMobile,
  DatePickerDayNameMobile,
  DatePickerDayDisabledMobile,
  DatePickerOverlay,
  DatePickerDayHolidayMobile,
} from './styles'
import moment from 'moment'
import { useParams } from 'react-router'
import { times } from 'lodash'
import { getISODate, getLocationId } from 'src/helpers'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage } from '../../../../localization'
import { ChevronLeft } from 'components/Icon/Icons/ChevronLeft'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'
import styled from 'styled-components'
import { useHolidayAllQuery } from 'state/graphql'

const StyledChevronLeft = styled(ChevronLeft)`
  color: ${props => props.theme.colors.secondaryDark};
`
const StyledChevronRight = styled(ChevronRight)`
  color: ${props => props.theme.colors.secondaryDark};
`
export const DatePickerCalendar = ({
  goToCalendar,
  view,
  setDate,
  wrapperRef,
  dontGoToCalendarAfterMonthChange,
  selectedDayId,
}) => {
  const { t } = useTranslation()

  const names = [
    t('translation.DatepickerCalendar.days.day-mon'),
    t('translation.DatepickerCalendar.days.day-tue'),
    t('translation.DatepickerCalendar.days.day-wen'),
    t('translation.DatepickerCalendar.days.day-thu'),
    t('translation.DatepickerCalendar.days.day-fri'),
    t('translation.DatepickerCalendar.days.day-sat'),
    t('translation.DatepickerCalendar.days.day-sun'),
  ]

  const daysInMonth = []

  let { dayId } = useParams()
  const [currentDate, setCurrentDate] = useState()

  useEffect(() => setCurrentDate(selectedDayId || dayId), [
    selectedDayId,
    dayId,
  ])

  const previousMonth = moment(currentDate)
    .subtract(1, 'month')
    .endOf('month')
  const nextMonth = moment(currentDate)
    .add(1, 'month')
    .startOf('month')

  const timesIndex =
    moment(currentDate)
      .startOf('month')
      .isoWeekday() - 1

  times(timesIndex, function() {
    daysInMonth.unshift({
      day: previousMonth.format('DD'),
      month: previousMonth.format('MM'),
      year: previousMonth.format('YYYY'),
      isDisabled: true,
    }) // your format
    previousMonth.subtract(1, 'day')
  })

  const monthDate = moment(currentDate).startOf('month') // change to a date in the month of interest

  times(monthDate.daysInMonth(), function(n) {
    daysInMonth.push({
      day: monthDate.format('DD'),
      month: monthDate.format('MM'),
      year: monthDate.format('YYYY'),
    }) // your format
    monthDate.add(1, 'day')
  })

  const timesEndIndex =
    7 -
    moment(currentDate)
      .endOf('month')
      .isoWeekday()

  times(timesEndIndex, function(n) {
    daysInMonth.push({
      day: nextMonth.format('DD'),
      month: nextMonth.format('MM'),
      year: nextMonth.format('YYYY'),
      isDisabled: true,
    }) // your format
    nextMonth.add(1, 'day')
  })

  const weight = Number(localStorage.getItem('calendarWidth'))
  const height = Number(localStorage.getItem('calendarHeight'))

  const calendarRef = useRef()
  const calendarHeight = (calendarRef?.current?.clientHeight || height) - 50
  const calendarWidth = calendarRef?.current?.clientWidth || weight

  localStorage.setItem('calendarWidth', calendarWidth)
  localStorage.setItem('calendarHeight', calendarHeight)

  const [hoveredDays, setHoveredDays] = useState([])
  const [selectedDays, setSelectedDays] = useState([])

  useEffect(() => {
    if (currentDate && view === 'week') {
      const startOfWeek = moment(currentDate).startOf('isoWeek')
      const endOfWeek = moment(currentDate).endOf('isoWeek')
      let days = []
      let day = startOfWeek

      while (day <= endOfWeek) {
        days.push(day.toDate())
        day = day.clone().add(1, 'd')
      }
      setSelectedDays(days)
    }
  }, [currentDate, view])

  const setHoveredItems = selectedDay => {
    if (!selectedDay) {
      setHoveredDays([])
    } else if (view === 'week') {
      const currentDay =
        selectedDay.year + '-' + selectedDay.month + '-' + selectedDay.day
      const startOfWeek = moment(currentDay).startOf('isoWeek')
      const endOfWeek = moment(currentDay).endOf('isoWeek')
      let days = []
      let day = startOfWeek

      while (day <= endOfWeek) {
        days.push(day.toDate())
        day = day.clone().add(1, 'd')
      }
      setHoveredDays(days)
    }
  }

  function getHovered(idx) {
    return !!hoveredDays.filter(
      x =>
        getISODate(x) ===
        daysInMonth[idx].year +
          '-' +
          daysInMonth[idx].month +
          '-' +
          daysInMonth[idx].day
    )[0]
  }

  function getSelected(idx) {
    return !!selectedDays.filter(
      x =>
        getISODate(x) ===
        daysInMonth[idx].year +
          '-' +
          daysInMonth[idx].month +
          '-' +
          daysInMonth[idx].day
    )[0]
  }

  const { data } = useHolidayAllQuery({
    variables: { locationId: getLocationId() },
  })
  const holidays = data?.Holiday?.all

  return (
    <DatePickerOverlay>
      <DatePickerCalendarContainerMobile ref={wrapperRef}>
        <DatePickerLeftMobile>
          <DatePickerHeaderMobile>
            <DateArrowMobile
              onClick={() => {
                setCurrentDate(previousMonth)
                if (dontGoToCalendarAfterMonthChange) {
                  return
                }
                goToCalendar && goToCalendar(getISODate(previousMonth), true)
              }}
            >
              <StyledChevronLeft size="extraSmall" stroke={5} />
            </DateArrowMobile>
            <DatePickerNameMobile>
              {moment(currentDate)
                .locale(getCurrentLanguage())
                .format('MMMM')}
            </DatePickerNameMobile>
            <DateArrowMobile
              onClick={e => {
                e.preventDefault()
                setCurrentDate(nextMonth)
                if (dontGoToCalendarAfterMonthChange) {
                  return
                }
                goToCalendar && goToCalendar(getISODate(nextMonth), true)
              }}
            >
              <StyledChevronRight size="extraSmall" stroke={5} />
            </DateArrowMobile>
          </DatePickerHeaderMobile>
          <DatePickerCalendarStyledMobile
            ref={calendarRef}
            onMouseLeave={() => setHoveredItems([])}
          >
            {names.map(name => (
              <DatePickerDayNameMobile key={name}>
                {name}
              </DatePickerDayNameMobile>
            ))}
            {daysInMonth.map((day, idx) => {
              const date =
                daysInMonth[idx].year +
                '-' +
                daysInMonth[idx].month +
                '-' +
                daysInMonth[idx].day

              const isHoliday = holidays?.find(
                x => getISODate(x.date) === getISODate(date)
              )?.id
              const isToday =
                getISODate(day?.year + '-' + day?.month + '-' + day?.day) ===
                getISODate(moment())
              const isCurrent =
                moment(currentDate).format('DD') === daysInMonth[idx].day &&
                moment(currentDate).format('MM') === daysInMonth[idx].month

              return day.isDisabled ? (
                <DatePickerDayDisabledMobile
                  key={idx}
                  onMouseEnter={() => setHoveredItems(daysInMonth[idx])}
                >
                  <MonthDayNameMobile
                    isHovered={getHovered(idx)}
                    isSelected={getSelected(idx)}
                    isDisabled
                    isToday={isToday}
                    isHoliday={isHoliday}
                  >
                    {daysInMonth[idx].day}
                  </MonthDayNameMobile>
                </DatePickerDayDisabledMobile>
              ) : (
                <DatePickerCalendarDayMobile
                  key={idx}
                  onMouseEnter={() => setHoveredItems(daysInMonth[idx])}
                  onClick={() => {
                    setCurrentDate(date)

                    setDate
                      ? setDate(getISODate(date))
                      : goToCalendar(getISODate(date))
                  }}
                >
                  <MonthDayNameMobile
                    isHovered={getHovered(idx)}
                    isSelected={getSelected(idx)}
                    isCurrent={isCurrent}
                    isToday={isToday}
                    isHoliday={isHoliday}
                  >
                    {daysInMonth[idx].day}
                  </MonthDayNameMobile>

                  {isHoliday && isToday ? (
                    <div className="absolute top-[45px] w-[20px] h-[2px] bg-zoyya-accent5"></div>
                  ) : null}
                  {!isHoliday && isToday ? (
                    <div
                      className={`absolute top-[45px] w-[20px] h-[2px] ${
                        (view === 'week' && getSelected(idx)) || isCurrent
                          ? 'bg-zoyya-light'
                          : 'bg-zoyya-secondaryDarker'
                      }`}
                    ></div>
                  ) : null}
                </DatePickerCalendarDayMobile>
              )
            })}
          </DatePickerCalendarStyledMobile>
        </DatePickerLeftMobile>
      </DatePickerCalendarContainerMobile>
    </DatePickerOverlay>
  )
}

DatePickerCalendar.defaultProps = {
  selectedDayId: undefined,
}
