import { useTranslation } from 'react-i18next'

export const useGenders = () => {
  const { t } = useTranslation()

  return [
    { id: 'Female', title: t('translation.helpers.female') },
    { id: 'Male', title: t('translation.helpers.male') },
    { id: 'Unspecified', title: t('translation.helpers.dont-say') },
  ]
}
export const useRoles = () => {
  const { t } = useTranslation()

  return [
    { id: 'OWNER', title: t('translation.helpers.Owner') },
    { id: 'ADMIN', title: t('translation.helpers.administrator') },
    { id: 'USER', title: t('translation.helpers.User') },
  ]
}

export const usePaymentMethods = () => {
  const { t } = useTranslation()

  return [
    {
      id: 'CASH',
      name: 'Gotovina',
    },
    {
      id: 'CARD',
      name: 'Kartica',
    },
    {
      id: 'WIRE',
      name: 'Transakcijski račun',
    },
  ]
}
