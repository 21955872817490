import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Resources = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 21v-4.5M18 14.5h0a2.238 2.238 0 002.181-1.992L20.5 9M6 14.5h0a2.238 2.238 0 01-2.181-1.992L3.5 9M17 13.139C15.874 7.325 17 4.021 17 2.5v0a2 2 0 00-2-2H9h0a2 2 0 00-2 2s0 0 0 0c0 1.521 1.126 4.825 0 10.639M7.09 3.5h9.82M22.5 8.5v0a.5.5 0 01-.5.5h-3 0a.5.5 0 01-.5-.5s0 0 0 0h0a2 2 0 114 0s0 0 0 0zM5.5 8.5v0A.5.5 0 015 9H2h0a.5.5 0 01-.5-.5s0 0 0 0h0a2 2 0 014 0zM17.591 22.377C13.681 21.2 13.257 21 12 21c-1.257 0-1.681.2-5.591 1.377h0a.574.574 0 00.164 1.123h10.854a.574.574 0 00.164-1.123z"></path>
        <path d="M18 15.5v0a1 1 0 01-1 1H7h0a1 1 0 01-1-1s0 0 0 0v-1.316h0a1 1 0 01.686-.95h0A17.1 17.1 0 0112 12.5h0a17.1 17.1 0 015.314.734h0a1 1 0 01.686.95z"></path>
      </g>
    </StyledIcon>
  )
}
