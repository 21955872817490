import React, { useEffect, useState } from 'react'
import 'moment/locale/hr'
import { getOrgId } from 'src/helpers'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Form, Button, Loader, FormField, icons, Avatar } from 'components'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { RouterOutput, trpc } from 'src/trpc'
import { format } from 'date-fns'
import { LuClock4 } from 'react-icons/lu'
import { BsFillPersonFill } from 'react-icons/bs'
import { hr } from 'date-fns/locale'
import { MdEdit } from 'react-icons/md'
import { FaPeopleGroup } from 'react-icons/fa6'
import { FiMail, FiPhone } from 'react-icons/fi'
import { GrupAppointmentClientSelect } from './components/GroupClientSelect'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { GroupBookingFindResult } from './types'
import { GroupSlotStatusPresenter } from './components/GroupSlotStatusPresenter'
import { set } from 'lodash'
const Schema = t =>
  Yup.object().shape({
    duration: Yup.string().required(
      t('translation.AppointmentModal.validation-duration-required')
    ),
    assignedTo: Yup.object()
      .nullable()
      .required(t('translation.AppointmentModal.validation-employeeRequired')),
    capacity: Yup.number().typeError(
      t('translation.AppointmentModal.validation-capacity-numeric')
    ),
  })

const ServiceSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.AppointmentModal.validation-nameRequired')
    ),
    durationMinutes: Yup.string().required(
      t('translation.AppointmentModal.validation-duration-required')
    ),
    type: Yup.object()
      .nullable()
      .required(
        t('translation.AppointmentModal.validation-service-type-required')
      ),
    price: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.AppointmentModal.validation-priceRequired')
    ),
  })

const CancellationSchema = t =>
  Yup.object().shape({
    cancelReason: Yup.string().required(
      t('translation.AppointmentModal.cancelReason')
    ),
  })
const GroupStatusChangeSchema = t =>
  Yup.object().shape({
    groupClientBookingStatus: Yup.object().required(
      t('translation.AppointmentModal.groupStatusChange')
    ),
    cancelGroupClientBookingReason: Yup.string().when(
      'groupClientBookingStatus',
      {
        is: status => {
          return status?.id === 'cancel'
        },
        then: Yup.string().required(
          t('translation.AppointmentModal.cancelReason')
        ),
      }
    ),
  })

type ViewState = 'preview' | 'bookClient' | `editSlot-${string}`

type Props = {
  id: bigint
  onClose: () => void
}
export const GroupAppointmentEditScreen = (props: Props) => {
  const { id, onClose } = props
  const { t } = useTranslation()
  let { locationId, orgId } = useParams<any>()

  const [view, setView] = useState<ViewState>('preview')

  const { data: appointment, isLoading: loadingAppointment } =
    trpc.groupBooking_find.useQuery({
      id: id,
      locationId: BigInt(locationId),
      orgId: orgId,
    })

  useEffect(() => {
    setView('preview')
  }, [id])
  if (loadingAppointment || !appointment) return <Loader isComponent />

  const handleClickSlot = slot => {
    setView(`editSlot-${slot.id}`)
  }

  return (
    <div
      className={`flex flex-1 flex-col gap-3  ${
        view === 'preview' ? '' : 'bg-gray-100'
      }`}
    >
      <header className="bg-zoyya-primary p-3 relative rounded-b-md">
        <button
          name="edit"
          type="button"
          title="edit"
          className="text-white absolute right-2 top-2 opacity-80 hover:opacity-100"
        >
          <MdEdit size={28} />
        </button>
        <div className="text-white  flex flex-col">
          <div className="text-3xl">
            {format(appointment.date!, 'iiii, dd.MM.yyyy', { locale: hr })}
          </div>
          <div className="opacity-90 text-lg">{appointment.service?.name}</div>
          <div className="flex flex-row gap-2 self-center text-3xl mt-4 ">
            <LuClock4 size={28} color="lightgray" />
            <div className="flex flex-row">
              <div className="font-semibold">
                {format(appointment.startTime, 'HH:mm')}
              </div>
              <div>-</div>
              <div className="opacity-90">
                {format(appointment.endTime, 'HH:mm')}
              </div>
            </div>
          </div>
          <div className="absolute bottom-2  right-2 flex flex-row gap-1 bg-zoyya-mainBackground text-zoyya-primary rounded-sm text-2xl p-2">
            <div className="mt-1">
              <BsFillPersonFill />
            </div>
            <div>{appointment.occupiedSlots.length || 0}</div>
            <div>/</div>
            <div>{appointment.capacity}</div>
          </div>
        </div>
      </header>
      {view === 'preview' ? (
        <>
          {appointment.occupiedSlots.length ? (
            <div className="flex  flex-col gap-2 p-2">
              <div className="self-center flex flex-row gap-2 text-zoyya-primary p-2 rounded-2xl pl-7 pr-7">
                <FaPeopleGroup size={20} />
                <div>Naručeni pacijenti</div>
              </div>
              <div className="flex flex-col gap-2">
                {appointment.occupiedSlots.map(slot => (
                  <div
                    key={slot.id.toString()}
                    onClick={() => handleClickSlot(slot)}
                    className="flex flex-row p-4 rounded-md bg-zoyya-lightBackground cursor-pointer border hover:border-zoyya-primary"
                  >
                    <div className="flex-1">{slot.Client?.fullName}</div>
                    <GroupSlotStatusPresenter slot={slot} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="self-center p-4  text-zoyya-accent1 text-xl">
              Nema naručenih pacijenata
            </div>
          )}
          <div className="self-end p-2">
            <ButtonTW
              onClick={() => setView('bookClient')}
              label="Naruči pacijenta"
              variant="primary"
            ></ButtonTW>
          </div>
        </>
      ) : view.startsWith('editSlot') ? (
        <EditClientSlotForm
          slot={
            appointment.occupiedSlots.find(
              s => s.id.toString() === view.split('-')[1]
            )!
          }
          onClose={() => setView('preview')}
        />
      ) : (
        <BookClientSlotForm
          appointment={appointment}
          onClose={() => setView('preview')}
        />
      )}
      <div className="flex-1 justify-end items-end p-4 flex flex-col">
        <ButtonTW variant="secondary" label="Zatvori" onClick={onClose} />
      </div>
    </div>
  )
}

const BookClientSlotForm = (props: {
  appointment: GroupBookingFindResult
  onClose: () => void
}) => {
  const { appointment, onClose } = props
  const bookSlotMutation = trpc.groupBooking_bookSlot.useMutation()
  const trpcContext = trpc.useContext()
  const handleSubmit = async values => {
    await bookSlotMutation.mutateAsync({
      clientId: BigInt(values.clientId),
      notes: values.notes,
      resourceBookingId: appointment.id,
      locationId: appointment.locationId,
      orgId: getOrgId()!,
    })
    await trpcContext.groupBooking_find.invalidate()

    onClose()
  }
  return (
    <div className=" flex flex-col p-2 flex-1 ">
      <Form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8  p-4 border shadow-sm">
          <GrupAppointmentClientSelect name="clientId" />
          <FormField.TextArea
            name="notes"
            label="Napomena"
          ></FormField.TextArea>
          <div className="flex flex-row gap-2 justify-end">
            <ButtonTW
              variant="secondary"
              onClick={onClose}
              label="Odustani"
              disabled={bookSlotMutation.isLoading}
            ></ButtonTW>
            <ButtonTW
              isLoading={bookSlotMutation.isLoading}
              disabled={bookSlotMutation.isLoading}
              type="submit"
              label="Naruči"
              className={'self-end'}
            ></ButtonTW>
          </div>
        </div>
      </Form>
    </div>
  )
}

const EditClientSlotForm = (props: {
  slot: GroupBookingFindResult['occupiedSlots'][0]
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const { slot, onClose } = props
  const [view, setView] = useState<
    'preview' | 'cancel' | 'confirm' | 'complete'
  >('preview')

  const cancelSlotMutation = trpc.groupBooking_cancelSlot.useMutation()
  const confirmSlotMutation = trpc.groupBooking_confirmSlot.useMutation()
  const trpcContext = trpc.useContext()

  const handleSubmitCancel = async values => {
    await cancelSlotMutation.mutateAsync({
      locationId: slot.locationId,
      orgId: getOrgId()!,
      resourceBookingId: slot.id,
      reason: values.cancelGroupClientBookingReason,
      sendEmail: values.sendEmail,
    })
    await trpcContext.groupBooking_find.invalidate()

    onClose()
  }
  const handleSubmitConfirm = async values => {
    await confirmSlotMutation.mutateAsync({
      locationId: slot.locationId,
      orgId: getOrgId()!,
      resourceBookingId: slot.id,
      sendEmail: values.sendEmail,
    })
    await trpcContext.groupBooking_find.invalidate()

    onClose()
  }
  const cancelReasonOptions =
    slot.status === 'DRAFT'
      ? [
          {
            id: 'ORG_CANCELED',
            name: t('translation.UpdateStatusModal.salonCanceled'),
          },
        ]
      : [
          {
            id: 'CLIENT_CANCELED',
            name: t('translation.UpdateStatusModal.clientCanceled'),
          },

          {
            id: 'CLIENT_NO_SHOW',
            name: t('translation.UpdateStatusModal.clientNoShow'),
          },
        ]

  return (
    <div className=" flex flex-col p-2 flex-1 ">
      <div className="flex flex-col gap-8  p-4 bg-white border rounded-sm shadow-sm relative">
        <div className="absolute top-3 right-3">
          <GroupSlotStatusPresenter slot={slot} />
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-2xl font-semibold">{slot.Client?.fullName}</div>
          <div className="flex flex-row gap-2 text-zoyya-primary items-center">
            <FiMail size={22} />
            <div>{slot.Client?.email}</div>
          </div>
          <div className="flex flex-row gap-2 text-zoyya-primary items-center">
            <FiPhone size={22} />
            <div>{slot.Client?.mobilePhone}</div>
          </div>
        </div>
        {view === 'preview' && slot.status !== 'canceled' ? (
          <div className="flex flex-row gap-4 items-center justify-center">
            {slot.status === 'DRAFT' ? (
              <ButtonTW
                variant="primary"
                label="POTVRDI"
                onClick={() => setView('confirm')}
              />
            ) : null}
            <ButtonTW
              variant="orangeOutline"
              label="OTKAŽI"
              onClick={() => setView('cancel')}
            />
          </div>
        ) : null}
        <div>{slot.internalNote}</div>
        {view === 'preview' ? (
          <div className="flex flex-row gap-2 justify-end">
            <ButtonTW
              variant="text"
              onClick={onClose}
              label="Zatvori"
              disabled={cancelSlotMutation.isLoading}
            ></ButtonTW>
          </div>
        ) : null}
        {view === 'cancel' ? (
          <Form
            initialValues={{
              sendEmail: true,
              cancelGroupClientBookingReason:
                cancelReasonOptions.length === 1
                  ? cancelReasonOptions[0].id
                  : undefined,
            }}
            onSubmit={handleSubmitCancel}
          >
            <div className="flex bg-zoyya-lightBackground gap-4 p-4 flex-col border rounded-sm">
              <FormField.Select
                closeMenuOnSelect
                parseValue={val => cancelReasonOptions?.find(e => e.id === val)}
                formatValue={val => val?.id}
                options={cancelReasonOptions}
                isClearable={false}
                // onChange={item => {}}
                label={t('translation.UpdateStatusModal.cancelReason')}
                name={'cancelGroupClientBookingReason'}
              />
              <FormField.Checkbox
                label="Pošalji email"
                name="sendEmail"
                type={'checkbox'}
              ></FormField.Checkbox>
              <FormField.TextArea
                label="Vaša poruka"
                name="messageToClient"
              ></FormField.TextArea>
              <div className="mt-8 flex gap-2 justify-end flex-row">
                <ButtonTW
                  variant="text"
                  onClick={() => setView('preview')}
                  label="Odustani"
                  disabled={cancelSlotMutation.isLoading}
                ></ButtonTW>
                <ButtonTW
                  variant="primary"
                  type="submit"
                  label="Otkaži"
                  disabled={cancelSlotMutation.isLoading}
                ></ButtonTW>
              </div>
            </div>
          </Form>
        ) : null}

        {view === 'confirm' ? (
          <Form
            initialValues={{ sendEmail: true }}
            onSubmit={handleSubmitConfirm}
          >
            <div className="flex bg-zoyya-lightBackground gap-4 p-4 flex-col border rounded-sm">
              <FormField.Checkbox
                label="Pošalji email"
                name="sendEmail"
                type={'checkbox'}
              ></FormField.Checkbox>
              <FormField.TextArea
                label="Vaša poruka"
                name="messageToClient"
              ></FormField.TextArea>
              <div className="mt-8 flex gap-2 justify-end flex-row">
                <ButtonTW
                  variant="text"
                  onClick={() => setView('preview')}
                  label="Odustani"
                  disabled={confirmSlotMutation.isLoading}
                ></ButtonTW>
                <ButtonTW
                  variant="primary"
                  type="submit"
                  label="POTVRDI"
                  disabled={confirmSlotMutation.isLoading}
                ></ButtonTW>
              </div>
            </div>
          </Form>
        ) : null}
      </div>
    </div>
  )
}
