import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'components'
import {
  useGetLocationLoading,
  queryLocation,
  useUpdateResourceSequence,
} from 'state/queries'
import { StickyHeader, UsersSelect } from 'views/Settings/styles'
import { useMutation } from '@apollo/client'
import { inviteUserGql } from 'state/gql'
import { useSdk } from 'sdk'
import {
  useLocationSetEmployeesMutation,
  useProfessionAllQuery,
  useResourceSearchQuery,
  GetResourceFutureAppointmentsDocument,
} from 'state/graphql'
import { icons } from 'components/Icon'
import SearchBar from 'components/SearchBar/SearchBar'
import { uniqBy } from 'lodash'
import { DataTable } from 'src/sdk/tw/DataTable'
import { TableItem } from 'src/styles'
import { ColorItem } from 'views/Settings/Services/styles'
import {
  //roles,
  getISODate,
  getOrgId,
  getLocationId,
  getUserAvatar,
  formatPhone,
} from 'src/helpers'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import Tooltip from 'rc-tooltip'
import { useHeader } from 'mobile/Header'
import { useFooter } from 'mobile/Footer'
import { MobileContext } from 'src/Context'
import styled from 'styled-components'
import { AddEmployeesSelectMobile } from '../components/AddEmployeeSelectMobile'
import { AddEmployeesSelect } from '../components/AddEmployeeSelect'
import { ListHeader } from 'layout/ListHeader'
import { trpc } from 'src/trpc'

const SHOW_FILTER_INPUT_IF_MIN_OPTIONS = 10

const EmployeeRow = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
  :nth-of-type(3) {
    @media (max-width: 345px) {
      flex-direction: column;
      align-items: baseline;
    }
  }
`

const EmployeeColumn = styled.div`
  display: flex;
  font-size: 12px;
  :not(:last-of-type) {
    margin-right: 20px;
  }
  svg {
    position: relative;
    top: 2px;
    stroke-width: 3;
  }
`

type TypeEmployee = {
  avatarUrl?: string
  code?: string
  color?: string | null
  email?: string
  firstName: string
  gender: string
  hasOrgMembership: boolean
  id: string
  isInvitation: boolean
  isLocked: boolean
  kind: string
  lastName?: string | null
  locationSettings: any
  mobilePhone?: string | null
  name: string
  nickName?: string | null
  professions: any[]
  receiveAppointmentEmails: boolean
  role: string
  showInCalendar: boolean
  type?: any
  user?: any
  address?: string | null
}

type TypeEmployeeListProps = {
  selectedTabIndex: number
  setSelectedTabIndex: any
}

const EmployeeList = (props: TypeEmployeeListProps) => {
  const { selectedLocationId } = useParams<any>()
  const { history, t, navigateTo, appServices, apolloClient, toast } = useSdk()
  const { selectedTabIndex, setSelectedTabIndex } = props
  const isMobile = useContext(MobileContext)

  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [selectedEmployees, setSelectedEmployees] = useState<
    TypeEmployee[] | []
  >([])
  const [filterText, setFilterText] = useState<String>('')
  const [hasSelectedEmployees, setHasSelectedEmployees] =
    useState<boolean>(false)

  const [setEmployees] = useLocationSetEmployeesMutation()
  const [inviteUserMutation, { loading: loadingUpdateInvite }] =
    useMutation(inviteUserGql)
  const { showPopup } = usePopup()

  const employeesData = useResourceSearchQuery()
  const employees = employeesData?.data?.resource?.search
  const { data: professionsData } = useProfessionAllQuery()
  const { data, loading } = useGetLocationLoading(selectedLocationId)

  const filteredEmployees = employees
    ? employees?.filter(x => x?.kind === 'USER')
    : []

  const { data: orgRoles } = trpc.getOrgRoles.useQuery()

  useHeader(
    {
      content:
        selectedTabIndex === 0
          ? t('translation.WorkHours.title-employees')
          : t('translation.EmployeesListMobile.label-professions'),
      onGoBack: navigateTo.mobileBranchSettings,
    },
    [selectedTabIndex]
  )

  useFooter(
    {
      showAddButton: true,
      addButtonIcon: 'Add',
      addButtonOptions: [
        {
          onClick: () => {
            navigateTo.employeesList({ selectedTab: 0 })
            setSelectedTabIndex(0)
          },
          title: t('translation.EmployeesListMobile.label-employees'),
        },
        {
          onClick: () => {
            navigateTo.employeesList({ selectedTab: 1 })
            setSelectedTabIndex(1)
          },
          title: t('translation.EmployeesListMobile.label-professions'),
        },
        {
          onClick: () => navigateTo.newEmployee({}),
          title: t('translation.EmployeesListMobile.label-new-employee'),
          icon: <icons.Add size="smaller" />,
        },
        {
          onClick: () => navigateTo.newProfession({}),
          title: t('translation.EmployeesListMobile.label-new-professions'),
          icon: <icons.Add size="smaller" />,
        },
        null as any,
      ],
      drawer: (
        <div className="flex items-center">
          <div className="w-[20px] h-[20px] mr-[10px] flex">
            <icons.MultipleAdd />
          </div>
          <AddEmployeesSelectMobile
            allEmployees={uniqBy(filteredEmployees, x => x?.id)}
            selectedEmployees={selectedEmployees}
            onSelectClick={items => {
              // the menu closes on select so onBlur cant be used
              addNewEmployees(items)
            }}
          />
        </div>
      ),
    },
    [employees, selectedEmployees]
  )

  useEffect(() => {
    if (loading) return

    if (data?.location?.get?.resources) {
      const resources = data?.location?.get?.resources.filter(
        x => x.kind === 'USER'
      )
      setSelectedEmployees(resources)
      setIsUpdating(false)
    }
  }, [data, loading])

  const professionsAll = professionsData?.profession?.all
  const professions = professionsAll?.filter(x => x?.kind === 'USER')
  const filteredAllResources = selectedEmployees.map(employee => {
    return {
      ...employee,
      professions: employee.professions?.filter(
        x => professions?.map(x => x?.id).includes(x?.id)
      ),
      //id: employee?.user?.id ? employee?.user?.id : employee?.id,
      isInvitation: !employee.user?.id,
    }
  })

  const [updateResourceSequence, { loading: loadingUpdateResourceSequence }] =
    useUpdateResourceSequence()

  const searchedEmployees = filteredAllResources?.filter(
    x =>
      x?.nickName?.toLowerCase()?.includes(filterText) ||
      x?.firstName?.toLowerCase()?.startsWith(filterText) ||
      x?.lastName?.toLowerCase()?.startsWith(filterText) ||
      x?.email?.toLowerCase()?.startsWith(filterText) ||
      x?.email?.toLowerCase()?.includes(filterText) ||
      x?.mobilePhone?.toLowerCase()?.includes(filterText) ||
      x?.name?.toLowerCase()?.includes(filterText)
  ) as TypeEmployee[] | []

  async function removeEmployee(selectedEmployees) {
    try {
      setIsBusy(true)
      let resources = selectedEmployees.map(x => x.id)

      let addedResources = await setEmployees({
        variables: { locationId: selectedLocationId, resourceId: resources },
        refetchQueries: [
          {
            query: queryLocation,
            variables: { id: selectedLocationId },
          },
        ],
      })

      if (addedResources) {
        appServices.toast.success(
          t('translation.LocationEmployees.removeEmployeeFromLocation')
        )
      }
    } finally {
      setIsBusy(false)
    }
  }

  async function addNewEmployees(selectedEmployees) {
    try {
      setIsBusy(true)
      let resources = selectedEmployees.map(x => {
        return x.id
      })

      let addedResources = await setEmployees({
        variables: { locationId: selectedLocationId, resourceId: resources },
        refetchQueries: [
          {
            query: queryLocation,
            variables: { id: selectedLocationId },
          },
        ],
      })

      if (addedResources) {
        appServices.toast.success(
          t('translation.LocationEmployees.toast-employess-added')
        )
      }
    } finally {
      setIsBusy(false)
    }
  }

  const inviteUser = async (values: TypeEmployee) => {
    try {
      setIsBusy(true)
      const invitationInput = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: !values.email ? null : values?.email?.trim(),
        mobilePhone: formatPhone(values.mobilePhone),
        address: values.address,
        gender: values.gender,
        emailInvite: true,
        smsInvite: false,
        addToLocations: [],
        role: values.role,
        nickName: values.nickName,
        resourceId: values.id,
      }
      await inviteUserMutation({ variables: { input: invitationInput } })
      appServices.toast.success(
        t('translation.ResourcesList.toast-employee-invited', {
          firstName: values.firstName,
          lastName: values.lastName,
        })
      )
    } finally {
      setIsBusy(false)
    }
  }

  async function reSequence(data: TypeEmployee[]) {
    try {
      setIsBusy(true)
      setIsUpdating(true)
      const newData = data
        .filter(e => e.id)
        .map((x, index) => {
          return {
            resourceId: x.id,
            sequence: index,
            locationId: selectedLocationId,
          }
        })

      await updateResourceSequence({
        variables: {
          input: {
            locationId: selectedLocationId,
            resourceSequence: newData,
          },
        },
        refetchQueries: [
          { query: queryLocation, variables: { id: selectedLocationId } },
        ],
      })
    } finally {
      setIsBusy(false)
    }
  }

  const moveItemUp = (index: number) => {
    if (index === 0) return

    const newOrder = [...selectedEmployees]
    newOrder.splice(index, 0, newOrder.splice(index - 1, 1)[0])
    setSelectedEmployees(newOrder)
    setTimeout(() => reSequence(newOrder))
  }

  const moveItemDown = async (index: number) => {
    if (index === selectedEmployees.length - 1) return

    const newOrder = [...selectedEmployees]
    newOrder.splice(index, 0, newOrder.splice(index + 1, 1)[0])
    setSelectedEmployees(newOrder)
    setTimeout(() => reSequence(newOrder))
  }

  const getIsExpired = (rowData: TypeEmployee) => {
    return (
      rowData?.isInvitation || rowData?.isLocked || !rowData?.hasOrgMembership
    )
  }

  const columns = [
    {
      Header: '',
      accessor: 'sort',
      width: 60,
      minWidth: 60,
      Cell: props => (
        <div className="relative bottom-[3px]">
          <div
            className="h-[19px] mb-[2px] overflow-hidden cursor-pointer text-zoyya-secondaryDark hover:text-zoyya-text"
            onClick={e => {
              e.stopPropagation()
              moveItemUp(props.row.index)
            }}
          >
            <icons.ArrowUpDrop stroke={2} size="large" />
          </div>

          <div
            className="h-[15px] overflow-hidden cursor-pointer text-zoyya-secondaryDark hover:text-zoyya-text"
            onClick={e => {
              e.stopPropagation()
              moveItemDown(props.row.index)
            }}
          >
            <icons.ArrowDownDrop
              size="large"
              stroke={2}
              style={{ position: 'relative', top: '-9px' }}
            />
          </div>
        </div>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-firstName'),
      accessor: 'firstName',
      minWidth: 200,
      width: 200,
      Cell: props => (
        <TableItem>
          {getUserAvatar(props.cell.row.original, true, 'medium')}
          <span
            data-cy={'employeeFirstName'}
            className={`pl-[10px] ${
              getIsExpired(props.cell.row.original)
                ? 'text-zoyya-secondaryDark'
                : 'text-zoyya-text'
            }`}
          >
            {props.cell.row.original?.firstName +
              ' ' +
              props.cell.row.original?.lastName}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-email'),
      accessor: 'email',
      minWidth: 200,
      width: 200,
      Cell: props => (
        <TableItem>
          <span
            className={`${
              getIsExpired(props.cell.row.original)
                ? 'text-zoyya-secondaryDark'
                : 'text-zoyya-text'
            }`}
          >
            {props.cell.row.original?.email}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-mobile'),
      accessor: 'mobilePhone',
      minWidth: 200,
      width: 200,
      Cell: props => (
        <TableItem>
          <span
            className={`${
              getIsExpired(props.cell.row.original)
                ? 'text-zoyya-secondaryDark'
                : 'text-zoyya-text'
            }`}
          >
            {props.cell.row.original?.mobilePhone}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-role'),
      accessor: 'role',
      minWidth: 150,
      width: 150,
      Cell: props => (
        <TableItem>
          <span
            className={`${
              getIsExpired(props.cell.row.original)
                ? 'text-zoyya-secondaryDark'
                : 'text-zoyya-text'
            }`}
          >
            {
              orgRoles?.find(x => x.role === props.cell.row.original.role)
                ?.roleTitle
            }
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-profession'),
      accessor: 'professions',
      minWidth: 170,
      width: 170,
      Cell: props => (
        <TableItem>
          <span
            className={`${
              getIsExpired(props.cell.row.original)
                ? 'text-zoyya-secondaryDark'
                : 'text-zoyya-text'
            }`}
          >
            {props.cell.row.original?.professions?.map(
              (profession, idx) =>
                profession.name +
                (idx + 1 < props.cell.row.original?.professions.length
                  ? ', '
                  : '')
            )}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-color'),
      accessor: 'color',
      minWidth: 80,
      width: 80,
      Cell: props => (
        <TableItem>
          <ColorItem color={props.cell.row.original?.color} />
        </TableItem>
      ),
    },
    {
      Header: t('translation.ResourcesList.label-online'),
      accessor: 'bookingAllowed',
      minWidth: 80,
      width: 80,
      Cell: props => {
        return (
          <TableItem>
            {props.cell.row.original?.locationSettings?.onlineBooking !==
            'DISABLED' ? (
              <icons.CheckMarkIcon
                className={`${
                  getIsExpired(props.cell.row.original)
                    ? 'text-zoyya-secondaryDark'
                    : 'text-zoyya-text'
                }`}
                style={{ marginLeft: '10px' }}
                size={'smaller'}
              />
            ) : null}
          </TableItem>
        )
      },
    },
    {
      Header: t('translation.FooterNavigation.title-calendar'),
      accessor: 'showInCalendar',
      minWidth: 100,
      width: 100,
      Cell: props => {
        return (
          <TableItem>
            {props.cell.row.original?.showInCalendar ? (
              <icons.CheckMarkIcon
                className={`${
                  getIsExpired(props.cell.row.original)
                    ? 'text-zoyya-secondaryDark'
                    : 'text-zoyya-text'
                }`}
                style={{ marginLeft: '20px' }}
                size={'smaller'}
              />
            ) : null}
          </TableItem>
        )
      },
    },
    {
      Header: '',
      accessor: 'isInvitation',
      minWidth: 80,
      width: 80,
      Cell: props =>
        props.cell.row.original?.isInvitation &&
        props.cell.row.original?.email ? (
          <TableItem>
            <Button
              buttonType={'secondary'}
              iconComponent={<icons.SendEmail />}
              hasIconOnly
              size={'small'}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                showPopup({
                  onConfirm: () => inviteUser(props.cell.row.original),
                  titleText: t('translation.ClientsList.title-confirmSend', {
                    firstName: props.cell.row.original?.firstName,
                    lastName: props.cell.row.original?.lastName,
                  }),
                  contentText: t(
                    'translation.ClientsList.label-sendInviteInfo'
                  ),
                })
              }}
              tooltip={t('translation.ResourcesList.tooltip-send-invite')}
              style={{ height: '35px' }}
            />
          </TableItem>
        ) : null,
    },
    {
      Header: '',
      accessor: 'goToCalendar',
      minWidth: 80,
      width: 80,
      Cell: props => (
        <TableItem>
          <Button
            buttonType={'secondary'}
            iconComponent={<icons.CalendarIcon size={'smaller'} />}
            hasIconOnly
            size={'small'}
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              navigateTo.calendar({
                date: getISODate(),
                view: 'week',
                selectedEmployeeId: props.cell.row.original?.id,
              })
            }}
            tooltip={t('translation.ResourcesList.label-show-in-calendar')}
            style={{ height: '35px' }}
          />
        </TableItem>
      ),
    },
    {
      Header: '',
      accessor: 'delete',
      minWidth: 80,
      width: 80,
      Cell: props => (
        <Tooltip
          placement={'top'}
          overlayStyle={{
            borderRadius: '4px',
            background: 'transparent',
            zIndex: 9999999999,
          }}
          overlayInnerStyle={{
            border: 'none',
            backgroundColor: '#333',
            color: '#ffffff',
          }}
          overlay={
            <div className="max-w-[250px]">
              <div>
                {t('translation.ResourcesList.tooltip-remove-employee')}
                <a
                  className="text-[#3bcbe8] ml-[2px] inline underline"
                  href={t('translation.HelpLinks.add-employee')}
                  target="_blank"
                >
                  {t('translation.ResourcesList.label-here')}
                </a>
              </div>
            </div>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner infoButton" />}
        >
          <TableItem>
            <Button
              buttonType={'secondary'}
              iconComponent={<icons.Trash size={'smaller'} />}
              hasIconOnly
              size={'small'}
              onClick={async event => {
                event.preventDefault()
                event.stopPropagation()
                const { data: futureAppointmentsData } =
                  await apolloClient.query({
                    query: GetResourceFutureAppointmentsDocument,
                    variables: {
                      resourceId: BigInt(props.cell.row.original?.id),
                    },
                  })
                const hasFutureAppointments =
                  Number(
                    futureAppointmentsData?.resource?.getFutureAppointments
                  ) > 0

                showPopup({
                  onConfirm: () =>
                    removeEmployee(
                      selectedEmployees.filter(
                        x => x.id !== props.cell.row.original?.id
                      )
                    ),
                  titleText: !hasFutureAppointments
                    ? t('translation.LocationEmployees.title-delete-employee', {
                        firstName: props.cell.row.original?.firstName,
                        lastName: props.cell.row.original?.lastName,
                      })
                    : t(
                        'translation.LocationEmployees.title-delete-employee-with-futureAppointments',
                        {
                          employeeName: !props.cell.row.original?.nickName
                            ? props.cell.row.original?.firstName +
                              ' ' +
                              props.cell.row.original?.lastName
                            : props.cell.row.original?.nickName,
                        }
                      ),
                })

                //setEmployeeToDelete(props.cell.row.original)
              }}
              style={{ height: '35px' }}
            />
          </TableItem>
        </Tooltip>
      ),
    },
  ]

  const onRowClick = (item: TypeEmployee) => {
    item?.isLocked
      ? toast.danger(
          t('translation.LocationEmployees.toast-locked-employee-warning')
        )
      : history.push(
          `/${getOrgId()}/${getLocationId()}/settings/organization/locations/${selectedLocationId}/employees/user/edit/${item?.id}`
        )
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col flex-1 lg:p-4">
        {!isMobile ? (
          <div
            className="flex w-full items-center justify-between sticky top-0 z-10 pb-3"
            data-cy={'employeesHeader'}
            style={{ borderBottom: 0, fontWeight: 400, paddingLeft: 18 }}
          >
            {filteredAllResources?.length >=
            SHOW_FILTER_INPUT_IF_MIN_OPTIONS ? (
              <SearchBar
                messageList={false}
                clientsList={false}
                search={filterText}
                onChange={search => setFilterText(search?.toLowerCase())}
                isOpen
                placeholder={t(
                  'translation.LocationEmployees.placeholder-search-employees'
                )}
                styleWrapper={{ marginRight: '20px', marginLeft: 0 }}
              />
            ) : null}

            <StickyHeader>
              <UsersSelect>
                <AddEmployeesSelect
                  allEmployees={uniqBy(filteredEmployees, x => x?.id)}
                  selectedEmployees={selectedEmployees}
                  onSelectClick={items => {
                    setSelectedEmployees(items)
                    setHasSelectedEmployees(true)
                  }}
                  onBlur={() => {
                    if (hasSelectedEmployees) {
                      addNewEmployees(selectedEmployees)
                    }
                    setHasSelectedEmployees(false)
                  }}
                />
              </UsersSelect>

              <Button
                onClick={() =>
                  history.push(
                    `/${getOrgId()}/${getLocationId()}/settings/organization/locations/${getLocationId()}/employees/user/new/newUser`
                  )
                }
                iconComponent={<icons.Add size="smaller" stroke={2} />}
                buttonType={'primary'}
                label={t('translation.LocationEmployees.label-add-new')}
                name="addEmployee"
                size={'small'}
              />
            </StickyHeader>
          </div>
        ) : filteredAllResources?.length >= SHOW_FILTER_INPUT_IF_MIN_OPTIONS ? (
          <ListHeader
            hasSearch
            onSearchChange={search => setFilterText(search?.toLowerCase())}
            messageList
            clientsList
            styleWrapper={{ top: '0' }}
            placeholder={t(
              'translation.LocationEmployees.placeholder-search-employees'
            )}
          />
        ) : null}
        <div className="flex flex-1 pt-1" id={'list-body-wrapper'}>
          <div className="flex flex-row p-0 m-0 w-full">
            <DataTable
              data={searchedEmployees || []}
              columns={columns}
              isLoading={isBusy}
              mobileCardHeight={200}
              renderMobileCard={(item, idx) => (
                <EmployeeMobileCard
                  idx={idx}
                  item={item}
                  onClick={item => onRowClick(item)}
                  moveItemUp={moveItemUp}
                  moveItemDown={moveItemDown}
                />
              )}
              onRowClick={item => onRowClick(item)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

type TypeEmployeeMobileCardProps = {
  item: TypeEmployee
  onClick: (TypeEmployee) => void
  moveItemUp: (number) => void
  moveItemDown: (number) => void
  idx: number
}

const EmployeeMobileCard = (props: TypeEmployeeMobileCardProps) => {
  const { item, onClick, moveItemUp, moveItemDown, idx } = props
  const { t } = useSdk()
  const { data: orgRoles } = trpc.getOrgRoles.useQuery()
  return (
    <div className="flex items-center h-full pr-[10px] align-middle">
      <div className="relative bottom-[12px]">
        <div
          className="h-[19px] mb-[10px] overflow-hidden cursor-pointer text-zoyya-secondaryDark"
          onClick={e => {
            e.stopPropagation()
            moveItemUp(idx)
          }}
        >
          <icons.ArrowUpDrop stroke={2} size="large" />
        </div>

        <div
          className="h-[15px] overflow-hidden cursor-pointer text-zoyya-secondaryDark"
          onClick={e => {
            e.stopPropagation()
            moveItemDown(idx)
          }}
        >
          <icons.ArrowDownDrop
            size="large"
            stroke={2}
            style={{ position: 'relative', top: '-9px' }}
          />
        </div>
      </div>

      <div
        className="p-[15px] shadow-[0_1px_8px_#00000026] rounded-[10px] mb-[15px] flex-[1] min-h-[150px]"
        key={idx}
        onClick={onClick}
      >
        <div>
          <EmployeeRow style={{ justifyContent: 'space-between' }}>
            <div className="font-medium mr-[10px] items-baseline flex">
              {item?.isInvitation || item?.isLocked ? (
                <icons.Lock
                  size={'size14'}
                  style={{
                    position: 'relative',
                    top: '2px',
                    marginRight: 4,
                  }}
                />
              ) : null}
              <span>{item?.firstName + ' ' + item?.lastName + ' '}</span>
            </div>
            <span
              className={`font-medium text-[11px] text-white rounded-[5px] p-[0px_7px] relative bottom-[1px]`}
              style={{ background: item?.color || '#888' }}
            >
              {orgRoles
                ?.find(x => x.role === item?.role)
                ?.roleTitle.toUpperCase()}
            </span>
          </EmployeeRow>
          <EmployeeRow>
            {getUserAvatar(item, true, 'size50')}
            <div className="ml-[10px] overflow-hidden">
              <div className="text-[12px] text-[#555] font-light text-ellipsis overflow-hidden">
                {item?.email}
              </div>
              <div className="text-[12px] text-[#555] font-light text-ellipsis overflow-hidden">
                {item?.mobilePhone}
              </div>
            </div>
          </EmployeeRow>
          <EmployeeRow>
            <EmployeeColumn>
              {t('translation.EmployeesListMobile.show-in-calendar') + ' '}

              {item?.showInCalendar ? (
                <icons.CheckMarkIcon
                  style={{ marginLeft: '10px' }}
                  size={'extraSmall'}
                />
              ) : (
                <icons.Cross
                  style={{ marginLeft: '10px' }}
                  size={'extraSmall'}
                />
              )}
            </EmployeeColumn>
            <EmployeeColumn>
              {t('translation.EmployeesListMobile.online-booking') + ' '}

              {item?.locationSettings?.onlineBooking !== 'DISABLED' ? (
                <icons.CheckMarkIcon
                  style={{ marginLeft: '10px' }}
                  size={'extraSmall'}
                />
              ) : (
                <icons.Cross
                  style={{ marginLeft: '10px' }}
                  size={'extraSmall'}
                />
              )}
            </EmployeeColumn>
          </EmployeeRow>
          {item?.professions?.length ? (
            <EmployeeRow>
              {t('translation.EmployeesListMobile.specialization') + ' '}
              {item?.professions?.map(
                (profession, idx) =>
                  profession.name +
                  (idx + 1 < item?.professions.length ? ', ' : '')
              )}
            </EmployeeRow>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default EmployeeList
