import styled from 'styled-components'
import { device } from '../../constants'

export const LoadMore = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  font-style: italic;
  padding: 20px;
`

export const TableStyled = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  position: relative;
  max-height: 100%;
  height: 100%;

  @media ${device.tablet} {
    height: auto;
  }
  [draggable='false'] {
    width: 0px;
  }

  .td {
    padding: 0px 14px;
  }

  .rt-tbody {
    min-height: 0;
    flex: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #dbdfe2;
    }
    @media ${device.tablet} {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .tr {
    min-height: 50px;
    align-items: center;
  }

  .th {
    height: 40px;
  }
  .rt-table {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    max-height: 100%;
    flex: 1;
    overflow-y: hidden;
    margin-bottom: 0px;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #dbdfe2;
    }
    @media ${device.tablet} {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .drag-handle {
    cursor: move !important;
  }

  .dragging-container tbody {
    xposition: relative;
    xoverflow: hidden;
  }

  .dragging-container tbody tr {
    background: #fff !important;
    xtransition: none !important;
  }

  .dragging-container tbody tr td {
    position: relative;
  }

  .dragging-container .drag-target-line {
    bottom: -1px;
    left: -500%;
    width: 1000%;
    xleft: 0;
    xwidth: 100%;
    height: 0;
    display: block;
    position: absolute;
    border-bottom: dashed 2px red;
    z-index: 2;
  }

  .dragging-container .drag-target-line.drag-target-top {
    bottom: auto;
    top: -1px;
  }

  .dragging-container .ant-table-tbody > tr.ant-table-row-hover > td,
  .dragging-container
    .ant-table-tbody
    > tr
    > td
    .dragging-container
    .ant-table-tbody
    > tr:hover
    > td {
    background: transparent;
  }
`

export const Pagination = styled.div`
  width: calc(100% - 40px);
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background: ${props => props.theme.colors.light};

  @media ${device.tablet} {
    width: calc(100% - 25px);
  }
`

export const PaginationLeft = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 10px;
  }
`

export const PaginationPage = styled.span`
  margin-right: 20px;
`
export const PaginationRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  button:last-child {
    margin-left: 10px;
  }
`
