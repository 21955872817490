import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useConfirmOtp } from 'state/queries'
import { LoginForm, LoginFormWrapper, LoginPage } from '../Login/styles'
import { AnimationDiv } from 'components/AnimationDiv/AnimationDiv'
import { Button, Form, FormField, Loader } from 'components'
import { useSdk } from 'sdk'
import { FieldRow } from '../Settings/styles'
import moment from 'moment'
import { analyticsTrack, getLocationId, getOrgId } from '../../helpers'
import { AnimatedDiv } from '../../components/AnimatedDiv/AnimatedDiv'
import {
  LogoColor,
  LoginImage,
  LoginHeader,
  LoginFormHeader,
  LoginTitle,
} from 'src/sdk/tw/styles'

export const CodeVerification = () => {
  const { appServices } = useSdk()
  const { inviteId } = useParams()
  const { navigateTo } = useSdk()
  const [isBusy, setIsBusy] = useState(false)
  const { t } = useTranslation()
  const [confirmOtp] = useConfirmOtp()

  async function createNewUser(values) {
    try {
      setIsBusy(true)

      let results = await confirmOtp({
        variables: {
          input: {
            inviteId,
            otp: values.verificationCode,
            pinId: localStorage.getItem('pinId'),
          },
        },
      })

      if (!results.errors) {
        const metadata = {
          time: moment().format('DD.MM.YYYY HH:mm'),
          orgId: getOrgId(),
          locationId: getLocationId(),
        }
        analyticsTrack('Client registered', metadata)

        const token = results?.data?.market?.confirmOtp?.token
        localStorage.setItem('token', token)

        if (token) {
          setTimeout(() => {
            appServices.init()
            navigateTo.myLocations()
          }, 100)

          appServices.toast.success(
            t('translation.CodeVerification.toast-account-created')
          )
        }
      } else {
        appServices.toast.danger(results?.errors?.[0]?.message)
      }
    } finally {
      setIsBusy(false)
    }
  }
  return (
    <AnimatedDiv
      style={{
        display: 'flex',
        height: '100',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPage>
        <LoginForm>
          <LoginFormHeader>
            <LoginHeader>
              <LogoColor src={'/assets/zoyya-final-logo-white.svg'} />
            </LoginHeader>
            <LoginImage src={'/assets/loginImg.svg'} />
          </LoginFormHeader>
          <LoginFormWrapper>
            <LoginTitle>
              {t('translation.CodeVerification.title-enter-sms-code')}
            </LoginTitle>
            <Form
              id={'tfa-form'}
              onSubmit={createNewUser}
              initialValues={{}}
              isInitialValid={true}
              isLoading={isBusy}
            >
              {({ values }) => (
                <>
                  <FieldRow>
                    <FormField.VerificationInput
                      name={'verificationCode'}
                      fields={6}
                      autoFocus
                    />
                  </FieldRow>
                  <Button
                    style={{ marginTop: 0 }}
                    type={'submit'}
                    buttonType={'primary'}
                    label={t('translation.CodeVerification.label-verify-code')}
                    fullWidth
                    disabled={!values.verificationCode}
                    name={'verify'}
                    size={'large'}
                  />
                </>
              )}
            </Form>
          </LoginFormWrapper>
          {isBusy ? <Loader /> : null}
        </LoginForm>
        <AnimationDiv />
      </LoginPage>
    </AnimatedDiv>
  )
}
