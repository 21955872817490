import gql from 'graphql-tag'

export const ResourceFragmentGql = gql`
  fragment ResourceFragment on Resource {
    id
    name
    code
    firstName
    lastName
    email
    mobilePhone
    address
    gender
    role
    nickName
    color
    showInCalendar
    description
    kind
    receiveAppointmentEmails
    isLocked
    avatarUrl
    type {
      id
      name
    }
    professions {
      id
      name
    }
    workShiftsTemplate {
      name
      workHours {
        day
        dayName
        startTime
        endTime
      }
    }

    user {
      id
      role
      gender
      email
      address
      firstName
      nickName
      lastName
      avatarUrl
      fb_profilePicUrl
      google_profilePicUrl
      phone
      locations {
        id
        name
        city
        address
      }
    }
    hasOrgMembership
  }
`
