import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  DeleteButton,
  Form,
  FormField,
  Loader,
  icons,
} from 'components'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { debounce, isInteger } from 'lodash'
import {
  formatPriceNumberWithDelimiter,
  getValidIteratorsOptions,
  getVoucherTerms,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { t } from 'src/localization'
import { trpc } from 'src/trpc'
import { FormContainer } from 'src/styles'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { FormFieldsWrapper } from 'src/components/Form/Form'
import {
  FieldRow,
  FieldColumn,
  FormFooterButtonsContainer,
  FormPageContainer,
} from 'src/sdk/tw/Form'
import { MobileContext } from 'src/Context'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { ServiceSelect } from 'views/CashRegister/NewReceipt/styles'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import NewService from '../Services/ServiceEditor/NewService'

const validationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
    validAmount: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    price: Yup.string()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validPrice',
        'Cijena mora biti jednaka ili manja od vrijednosti predloška i jednaka ili veća od 0',
        function (value) {
          const { parent } = this
          const price = value || '0'

          return parseFloat(price?.toString()?.replace(',', '.')) >= 0
            ? parent?.voucherType === 'VOUCHER'
              ? parseFloat(price?.toString()?.replace(',', '.')) <=
                parseFloat(parent?.value?.toString().replace(',', '.'))
              : true
            : false
        }
      ),

    discountRate: Yup.string()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validDiscountAmount',
        'Popust ne može biti manji od 0 ni veći od 100',
        function (value) {
          const { parent } = this
          const discount = parseFloat(value || '0')

          return discount >= 0 && discount <= 100
        }
      ),
  })

type TemplateFormProps = {
  onSubmit: (values: any) => void
  initialValues: any
  loading: boolean
  isEdit?: boolean
  refetch?: any
  currency: string
  hasVat: boolean
  locationName?: string
}

export const TemplateForm = (props: TemplateFormProps) => {
  const {
    onSubmit,
    initialValues,
    isEdit,
    loading,
    refetch,
    currency,
    locationName,
    hasVat,
  } = props
  const params = useParams<{
    selectedLocationId: string
    orgId: string
    id: string
    type: string
  }>()

  const { selectedLocationId, orgId, id, type } = params
  let formRef = useRef<FormikProps<any>>()
  const isMobile = useContext(MobileContext)
  const { navigateTo, appServices } = useSdk()
  const [showServicesModal, setShowServicesModal] = useState<boolean>(false)

  const loyaltyType = isEdit ? initialValues?.voucherType.toLowerCase() : type

  const optionsValidIterators = getValidIteratorsOptions()

  const deleteGiftCardTemplateMutation =
    trpc.giftCardTemplate_delete.useMutation()
  const createGiftCardTemplateItemMutation =
    trpc.giftCardTemplateItem_create.useMutation()
  const updateGiftCardTemplateMutation =
    trpc.giftCardTemplateVoucherTerms_update.useMutation()

  const handleDeleteGiftCardTemplate = async () => {
    try {
      const res = await deleteGiftCardTemplateMutation.mutateAsync({
        id: BigInt(id),
      })
      if (res) {
        appServices.toast.success('Predložak je uspješno obrisan')

        navigateTo.listTemplates({ voucherType: loyaltyType })
      } else appServices.toast.danger('Predložak nije obrisan')
    } catch (e) {
      appServices.toast.danger('Predložak nije obrisan')
    }
  }

  const handleCreateGiftCardTemplateItem = async ({
    itemName,
    serviceId,
    serviceTypeId,
    description,
    discountRate,
    quantity,
  }) => {
    try {
      const res = await createGiftCardTemplateItemMutation.mutateAsync({
        loyaltyType,
        locationId: BigInt(selectedLocationId),
        orgId,
        giftCardTemplateId: BigInt(id),
        itemName,
        serviceId,
        serviceTypeId,
        description,
        discountRate,
        quantity,
      })

      if (res) {
        appServices.toast.success('Usluga uspješno kreirana')
        await updateGiftCardTemplateMutation.mutateAsync({
          id: BigInt(formRef?.current?.values?.id),
          voucherTerms: getVoucherTerms(
            {
              ...formRef?.current?.values,
              value: res?.value,
              price: res?.price,
              items: [
                ...formRef?.current?.values?.items,
                {
                  itemName,
                  serviceId,
                  serviceTypeId,
                  description,
                  discountRate,
                  quantity,
                },
              ],
            },
            locationName
          ),
        })
        await refetch()
      } else appServices.toast.danger('Usluga nije kreirana')
    } catch (e) {
      appServices.toast.danger('Usluga nije kreirana')
    }
  }

  const MobileOption = props => {
    return (
      <div className="flex items-center">
        <p data-cy={'mobileSelect_value'}>{props.data?.name}</p>
      </div>
    )
  }
  const selectComponents = {
    MobileOption,
  }

  const handleValueChange = (name, value, values, form) => {
    if (
      name === 'price' ||
      name === 'validIterator' ||
      name === 'validAmount' ||
      name === 'discountRate'
    ) {
      form.setFieldValue(
        'voucherTerms',
        name === 'discountRate' && loyaltyType === 'voucher'
          ? values?.voucherTerms
          : getVoucherTerms(values, locationName)
      )
      form.setFieldValue('autoGenerateTermsOfUse', true)
    }
  }

  return (
    <>
      <FormPageContainer>
        {loading ? <Loader isComponent /> : null}
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...initialValues,
            valueCurrency: currency,
            priceCurrency: currency,
            validIterator: optionsValidIterators?.find(
              x => x?.id === initialValues?.validIterator
            ),
            items: [
              ...initialValues?.items?.filter(x => x?.serviceId),
              ...initialValues?.items?.filter(x => x?.serviceTypeId),
            ],
            autoGenerateTermsOfUse: false,
            voucherType: isEdit
              ? initialValues?.voucherType
              : loyaltyType?.toUpperCase(),
            percentage: '%',
          }}
          validationSchema={validationSchema(t)}
          onValueChange={handleValueChange}
        >
          {form => {
            formRef.current = form
            return (
              <FormFieldsWrapper>
                <FormContainer>
                  <FieldRow>
                    <FieldColumn>
                      <p className="w-[150px] mr-[14px]">Vrsta predloška</p>
                      <span className="text-gray-400">
                        {loyaltyType === 'giftcard'
                          ? 'Poklon bon'
                          : 'Personalizirani program'}
                      </span>
                    </FieldColumn>
                    {/* <FieldColumn className="justify-end items-center">
                      <span className="mr-[10px]">Status</span>
                      <div>
                        <FormField.Select
                          closeMenuOnSelect={false}
                          name="status"
                          options={[]}
                          components={selectComponents}
                          selectMinWidth={'140px'}
                        />
                      </div>
                    </FieldColumn> */}
                  </FieldRow>

                  <FieldRow>
                    <FieldColumn className="max-w-[150px]">
                      <span>Naziv</span>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.Text
                        forceAutoFocus
                        autoCapitalize="on"
                        name="name"
                      />
                    </FieldColumn>
                  </FieldRow>

                  <FieldRow>
                    <FieldColumn className="max-w-[150px]">
                      <span>Valjanost</span>
                    </FieldColumn>
                    <FieldColumn>
                      <div className="max-w-[100px] mr-[8px]">
                        <FormField.Text name="validAmount" />
                      </div>

                      <div>
                        <FormField.Select
                          helpText={t(
                            'translation.ProfessionEditorForm.EmployeesHint'
                          )}
                          closeMenuOnSelect={true}
                          name="validIterator"
                          options={optionsValidIterators}
                          components={selectComponents}
                          selectMinWidth={'140px'}
                          isClearable={false}
                        />
                      </div>
                    </FieldColumn>
                  </FieldRow>

                  {loyaltyType !== 'giftcard' ? (
                    <FieldRow>
                      <FieldColumn className="max-w-[150px]">
                        <p>Vrijednost</p>
                      </FieldColumn>
                      <FieldColumn>
                        <div className="max-w-[100px]">
                          <FormField.Text
                            name="value"
                            disabled={loyaltyType === 'voucher'}
                            onChange={e => {
                              if (loyaltyType === 'giftcard') {
                                form.setFieldValue(
                                  'price',
                                  !!parseFloat(form?.values?.discountRate) &&
                                    form?.values?.discountRateCheckbox
                                    ? roundNumberWithTwoDecimals(
                                        parseFloat(
                                          form?.values?.value
                                            .toString()
                                            .replace(',', '.') || 0
                                        ) -
                                          parseFloat(
                                            form?.values?.value.replace(
                                              ',',
                                              '.'
                                            )
                                          ) *
                                            roundNumberWithTwoDecimals(
                                              parseFloat(
                                                form?.values?.discountRate
                                                  .toString()
                                                  .replace(',', '.') || 0
                                              ) / 100
                                            )
                                      )
                                    : e
                                )
                                form.validateForm()
                              }
                            }}
                          />
                        </div>

                        <div className="max-w-[50px] ml-[4px]">
                          <FormField.Text disabled name="valueCurrency" />
                        </div>
                      </FieldColumn>
                    </FieldRow>
                  ) : null}

                  <FieldRow>
                    <FieldColumn className="max-w-[150px]">
                      <p>Cijena</p>
                    </FieldColumn>
                    <FieldColumn>
                      <div className="max-w-[100px]">
                        <FormField.Text
                          name="price"
                          disabled={loyaltyType === 'voucher' && !isEdit}
                          onChange={e => {
                            if (loyaltyType === 'voucher') {
                              const discount = e
                                ? 100 *
                                  (1 -
                                    parseFloat(
                                      e?.toString().replace(',', '.')
                                    ) /
                                      parseFloat(
                                        form?.values?.value
                                          ?.toString()
                                          .replace(',', '.')
                                      ))
                                : 0

                              if (discount > 0)
                                form.setFieldValue('discountRateCheckbox', true)
                              form.setFieldValue('discountRate', discount)
                              form.validateForm()
                            }
                          }}
                        />
                      </div>

                      <div className="max-w-[50px] ml-[4px]">
                        <FormField.Text disabled name="priceCurrency" />
                      </div>

                      <div className="max-w-[80px] ml-[15px]">
                        <FormField.Text
                          disabled={!form?.values?.discountRateCheckbox}
                          name="discountRate"
                          shouldDisplayZeroAsValue
                          // parseValue={e => {
                          //   return !form.values?.discountRate
                          //     ? parseFloat('0')
                          //     : roundNumberWithTwoDecimals(
                          //         parseFloat(form.values?.discountRate)
                          //       )
                          // }}
                          onChange={e => {
                            if (loyaltyType === 'voucher' && e) {
                              const discount =
                                parseFloat(
                                  form?.values.value
                                    ?.toString()
                                    .replace(',', '.')
                                ) *
                                (parseFloat(
                                  form?.values?.discountRate
                                    .toString()
                                    .replace(',', '.') || 0
                                ) /
                                  100)

                              const price =
                                parseFloat(
                                  form?.values.value
                                    ?.toString()
                                    .replace(',', '.')
                                ) - discount
                              form.setFieldValue('price', price || '0')
                              form.validateForm()
                            }
                          }}
                        />
                      </div>
                      <div className="max-w-[50px] ml-[4px]">
                        <FormField.Text
                          disabled
                          name="percentage"
                          className="text-lg p-4"
                          style={
                            {
                              fontSize: 18,
                              textAlign: 'center',
                            } as any
                          }
                        />
                      </div>

                      <div className="ml-[8px] items-center">
                        <FormField.Checkbox
                          label={'Omogući popust'}
                          size={50}
                          name="discountRateCheckbox"
                          type={'checkbox'}
                          onClick={e => {
                            if (
                              e.target.value === 'true' &&
                              loyaltyType === 'giftcard'
                            )
                              form.validateForm()

                            if (form?.values.voucherType === 'VOUCHER')
                              form.setFieldValue('price', form?.values.value)
                            form.setFieldValue('discountRate', '0')
                          }}
                        />
                      </div>
                    </FieldColumn>
                  </FieldRow>
                  {hasVat ? (
                    <FieldRow style={{ alignItems: 'center' }}>
                      <FieldColumn className="lg:max-w-[150px]">
                        <p>PDV (%)</p>
                      </FieldColumn>
                      <FieldColumn style={{ flexDirection: 'column' }}>
                        <FormField.Text
                          disabled
                          name="vatRate"
                          shouldDisplayZeroAsValue
                        />
                      </FieldColumn>
                    </FieldRow>
                  ) : null}

                  <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn className="lg:max-w-[150px]">
                      <p>Uvjeti korištenja</p>
                    </FieldColumn>
                    <FieldColumn style={{ flexDirection: 'column' }}>
                      <FormField.TextArea
                        onKeyPress={e =>
                          form.setFieldValue('autoGenerateTermsOfUse', false)
                        }
                        name="voucherTerms"
                        rows={7}
                      />
                    </FieldColumn>
                  </FieldRow>

                  {/* <FieldRow style={{ alignItems: 'flex-start' }}>
                    <FieldColumn>
                      <FormField.Checkbox
                        label={'Omogući darivanje'}
                        name="isTransferable"
                        type={'checkbox'}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Checkbox
                        label={'Jednokratno'}
                        name="singleUse"
                        type={'checkbox'}
                      />
                    </FieldColumn>
                  </FieldRow> */}

                  <ServiceList
                    setShowServicesModal={setShowServicesModal}
                    locationName={locationName}
                    values={form?.values}
                    isEdit={isEdit}
                    type={loyaltyType}
                    currency={currency}
                    refetch={refetch}
                    isAddButtonDisabled={
                      !isEdit
                        ? true
                        : loyaltyType === 'giftcard'
                        ? !form?.values?.name ||
                          !form?.values?.validAmount ||
                          !form?.values?.validIterator
                        : !form?.values?.name ||
                          !form?.values?.validAmount ||
                          !form?.values?.validIterator
                    }
                  />

                  {!isMobile ? (
                    <FieldRow className="mt-[40px]">
                      <FormFooterButtonsContainer>
                        <Button
                          footerButton
                          buttonType={'text'}
                          name="closeForm"
                          type={'button'}
                          label={t(
                            'translation.ResourceEditorForm.button-close'
                          )}
                          onClick={() => {
                            navigateTo.listTemplates({
                              voucherType: loyaltyType,
                            })
                          }}
                        />
                        <Button
                          type={'submit'}
                          buttonType={'primary'}
                          name="saveChanges"
                          label={t(
                            'translation.ResourceEditorForm.button-save'
                          )}
                        />
                      </FormFooterButtonsContainer>

                      {isEdit ? (
                        <DeleteButton
                          withConfirm
                          onConfirm={handleDeleteGiftCardTemplate}
                          confirmationMessage={t(
                            'translation.NewClientContainer.title-confirmResourceDelete'
                          )}
                        />
                      ) : null}
                    </FieldRow>
                  ) : null}
                </FormContainer>
              </FormFieldsWrapper>
            )
          }}
        </Form>
      </FormPageContainer>

      {isMobile ? (
        <FormFooterButtonsContainer>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.ResourceEditorForm.button-close')}
            onClick={() => {
              navigateTo.listTemplates({
                voucherType: loyaltyType,
              })
            }}
          />
          <Button
            onClick={() => {
              formRef?.current?.submitForm()
            }}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainer>
      ) : null}

      {showServicesModal ? (
        <ServicesModal
          setShowServicesModal={setShowServicesModal}
          currency={currency}
          onClickItem={handleCreateGiftCardTemplateItem}
          loyaltyType={loyaltyType}
          formRef={formRef.current}
        />
      ) : null}
    </>
  )
}

export default TemplateForm

type ServiceListProps = {
  // items: RouterOutput['giftCard_findOne']['items']
  values: any
  setShowServicesModal(arg1: boolean): any
  type: string
  currency: string
  refetch: any
  isAddButtonDisabled: boolean
  isEdit?: boolean
  locationName?: string
}

const ServiceList = (props: ServiceListProps) => {
  const {
    values,
    setShowServicesModal,
    type,
    currency,
    refetch,
    isAddButtonDisabled,
    isEdit,
    locationName,
  } = props
  const { appServices } = useSdk()
  const params = useParams<{
    selectedLocationId: string
    orgId: string
  }>()
  const { selectedLocationId, orgId } = params

  const deleteGiftCardTemplateItemMutation =
    trpc.giftCardTemplateItem_delete.useMutation()
  const updateGiftCardTemplateMutation =
    trpc.giftCardTemplateVoucherTerms_update.useMutation()

  const handleDeleteGiftCardTemplateItem = async (id: bigint) => {
    try {
      const res = await deleteGiftCardTemplateItemMutation.mutateAsync({
        locationId: BigInt(selectedLocationId),
        orgId,
        loyaltyType: type,
        id: BigInt(id),
        giftCardTemplateId: values?.id,
      })
      if (res) {
        appServices.toast.success('Usluga uspješno obrisana')
        await updateGiftCardTemplateMutation.mutateAsync({
          id: BigInt(values?.id),
          voucherTerms: getVoucherTerms(
            {
              ...values,
              value: res?.value,
              price: res?.price,
              items: values?.items?.filter(x => x?.id !== id),
            },
            locationName
          ),
        })
        await refetch()
      } else appServices.toast.danger('Usluga nije obrisana')
    } catch (e) {
      appServices.toast.danger('Usluga nije obrisana')
    }
  }

  return (
    <div className="mt-[30px]">
      <p className="text-[18px] mb-[10px]">Odobrene usluge</p>

      <div className="bg-zoyya-grayLighter rounded-sm border border-zoyya-border pr-3 pl-[1.143rem]">
        <div className="rounded-md w-full">
          <div className="w-full flex  py-2">
            {type === 'giftcard' ? (
              <>
                <p className="w-[300px] text-sm text-zoyya-secondaryDark">
                  Vrsta usluge
                </p>
                <p className="mr-auto text-sm text-zoyya-secondaryDark">
                  Usluga
                </p>
                <div className="w-20"></div>
              </>
            ) : (
              <>
                <p className="grow text-sm text-zoyya-secondaryDark">Usluga</p>
                <p className="w-[30px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Kol
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Cijena
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Vrijednost
                </p>
                <p className="w-[40px] mr-[10px] text-center text-sm text-zoyya-secondaryDark">
                  %
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Neto
                </p>
                <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                  Iznos
                </p>
                <div className="w-10"></div>
              </>
            )}
          </div>

          {values?.items?.length ? (
            values?.items?.map(x => (
              <div
                key={x?.id.toString()}
                className="w-full flex py-2 border-t border-zoyya-border "
              >
                {type === 'giftcard' ? (
                  <>
                    <p className="w-[300px]">
                      {x?.serviceTypeId ? x?.itemName : ''}
                    </p>
                    <p className="mr-auto">
                      {x?.serviceId ? x?.itemName : 'Sve usluge'}
                    </p>
                    <div
                      className="w-20 flex justify-center cursor-pointer"
                      onClick={() => {
                        handleDeleteGiftCardTemplateItem(x?.id)
                      }}
                    >
                      <icons.Trash
                        className="text-zoyya-accent5"
                        size="smaller"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="grow text-[13px] mr-[10px]">{x?.itemName}</p>
                    <p className="min-w-[30px] mr-[10px] text-[13px]">
                      {x?.quantity || ''}
                    </p>
                    <p className="min-w-[70px] mr-[10px] text-[13px]">
                      {`${
                        formatPriceNumberWithDelimiter(
                          parseFloat(x?.Service?.price)
                        ) || ''
                      }`}
                    </p>
                    <p className="min-w-[70px] mr-[10px] text-[13px]">{`${formatPriceNumberWithDelimiter(
                      x?.quantity * parseFloat(x?.Service?.price)
                    )}`}</p>
                    <p className="min-w-[40px] mr-[10px] text-center text-[13px]">
                      {formatPriceNumberWithDelimiter(
                        x?.discountRate * 100 || 0,
                        false
                      ) || ''}
                    </p>
                    <p className="min-w-[70px] mr-[10px] text-[13px]">
                      {formatPriceNumberWithDelimiter(
                        parseFloat(x?.Service?.price) -
                          roundNumberWithTwoDecimals(
                            parseFloat(x?.Service?.price) * x?.discountRate
                          )
                      )}
                    </p>
                    <p className="min-w-[80px] mr-[10px] text-[13px]">
                      {`${formatPriceNumberWithDelimiter(
                        (x?.Service?.price -
                          roundNumberWithTwoDecimals(
                            parseFloat(x?.Service?.price) * x?.discountRate
                          )) *
                          x?.quantity
                      )} ${currency}`}
                    </p>
                    <div
                      className="min-w-10 flex justify-center cursor-pointer"
                      onClick={() => {
                        handleDeleteGiftCardTemplateItem(x?.id)
                      }}
                    >
                      <icons.Trash
                        className="text-zoyya-accent5"
                        size="smaller"
                      />
                    </div>
                  </>
                )}
              </div>
            ))
          ) : isEdit && type === 'giftcard' ? (
            <div className="w-full flex py-2 border-t border-zoyya-border ">
              <>
                <p className="w-[300px]"></p>
                <p className="mr-auto">{'Sve usluge'}</p>
              </>
            </div>
          ) : null}
        </div>
      </div>

      <ButtonTW
        label="+ Dodaj uslugu"
        variant={isAddButtonDisabled ? 'primary' : 'primaryOutline'}
        className="flex-0 mt-[20px]"
        disabled={isAddButtonDisabled}
        onClick={() => {
          setShowServicesModal(true)
        }}
      />
    </div>
  )
}

type ServiceModalProps = {
  setShowServicesModal: (arg0: boolean) => void
  currency: string
  onClickItem: any
  loyaltyType: string
  formRef: any
}

const ServicesModal = (props: ServiceModalProps) => {
  const { setShowServicesModal, currency, onClickItem, loyaltyType, formRef } =
    props
  const params = useParams<{
    selectedLocationId: string
    orgId: string
  }>()
  const { selectedLocationId, orgId } = params

  const [showAddServiceForm, setShowAddServiceForm] = useState<boolean>(false)
  const [filteredServiceTypes, setFilteredServiceTypes] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const [selectedService, setSelectedService] = useState<any>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const { data: serviceTypes, isLoading: serviceTypesLoading } =
    trpc.serviceType_findMany.useQuery({
      orgId: orgId,
      locationId: BigInt(selectedLocationId),
    })

  const globalSearchMemo = useMemo(() => {
    return (searchInput, serviceTypes) => {
      if (searchInput === '') {
        return serviceTypes
      }
      let filteredData = serviceTypes
        ?.filter(element => {
          return element.Services.some(service =>
            searchInput
              ? service.name?.toLowerCase().includes(searchInput.toLowerCase())
              : service
          )
        })
        .map(element => {
          let newService = Object.assign({}, element) // copies element
          return {
            ...element,
            Services: newService.Services.filter(service =>
              searchInput
                ? service.name
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase())
                : service
            ),
          }
        })
      return filteredData
    }
  }, [serviceTypes])

  const debouncedFiltering = useMemo(() => {
    return debounce(text => {
      if (text === null) {
        setFilteredServiceTypes(serviceTypes)
      }
      const filteredData = globalSearchMemo(text || '', serviceTypes)
      setFilteredServiceTypes(filteredData)
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)
    }, 0)
  }, [globalSearchMemo])

  useEffect(() => {
    debouncedFiltering(searchValue)
  }, [searchValue, debouncedFiltering])

  return (
    <div
      className="absolute bg-black/50 z-[9999] w-full h-full top-0 right-0 flex justify-center items-center"
      ref={popupRef}
      onClick={e => {
        if (e?.target !== popupRef?.current) return
        setShowServicesModal(false)
      }}
    >
      <div className="w-11/12 h-5/6 bg-white rounded-lg flex flex-col overflow-hidden">
        <div className="flex items-center">
          <Button
            type="button"
            buttonType={'text'}
            onClick={() => {
              setShowServicesModal(false)
              setSearchValue('')
            }}
            size="small"
            iconComponent={<icons.ArrowLeft />}
            style={{
              paddingLeft: 15,
              paddingRight: 0,
              margin: '0 5px',
            }}
          />

          {!showAddServiceForm ? (
            <>
              <ServiceSelect
                placeholder="Pretraži..."
                onChange={e => setSearchValue(e.target.value)}
                ref={inputRef}
                value={searchValue}
                autoFocus={true}
              ></ServiceSelect>
              <ButtonTW
                label="+ Dodaj uslugu"
                variant="primaryOutline"
                className="flex-0 mr-[8px]"
                onClick={() => setShowAddServiceForm(true)}
              />
            </>
          ) : (
            <p className=" text-[18px]">Nova usluga</p>
          )}
        </div>

        {showAddServiceForm ? (
          <NewService setShowAddServiceForm={setShowAddServiceForm} />
        ) : serviceTypesLoading ? (
          <Loader isComponent />
        ) : filteredServiceTypes?.length ? (
          <div className="overflow-auto px-3 flex-1">
            {filteredServiceTypes
              ?.sort(
                (a, b) =>
                  Number(a?.sequence)
                    ?.toString()
                    ?.localeCompare(Number(b.sequence).toString(), undefined, {
                      numeric: true,
                    })
              )
              ?.map(type => {
                return (
                  <div key={type?.id}>
                    <button
                      className={`uppercase py-[10px] text-[16px] text-zoyya-secondaryDark font-semibold   px-2 rounded-md w-full flex flex-1 my-1 p-1  border border-transparent  outline-0 ${
                        loyaltyType === 'giftcard'
                          ? 'focus-visible:bg-zoyya-lightBackground  hover:bg-zoyya-lightBackground cursor-pointer focus-visible:border focus-visible:border-purple-500'
                          : ''
                      }`}
                      onClick={async () => {
                        if (loyaltyType === 'giftcard') {
                          setSearchValue('')
                          setShowServicesModal(false)

                          onClickItem({
                            itemName: type?.name,
                            serviceId: null,
                            serviceTypeId: BigInt(type?.id),
                            description: '',
                            discountRate: 0,
                            quantity: 1,
                          })
                        }
                      }}
                    >
                      {type.name}
                    </button>
                    <div>
                      {type?.Services?.slice()
                        ?.sort(
                          (a, b) =>
                            Number(a?.sequence)
                              ?.toString()
                              ?.localeCompare(
                                Number(b.sequence).toString(),
                                undefined,
                                {
                                  numeric: true,
                                }
                              )
                        )
                        ?.map(item => {
                          return (
                            <button
                              type="button"
                              key={item?.id}
                              className="px-[15px] cursor-pointer hover:bg-zoyya-lightBackground rounded-md w-full flex flex-1 my-1 p-1 focus-visible:bg-zoyya-lightBackground  border border-transparent focus-visible:border focus-visible:border-purple-500 outline-0"
                              onClick={async () => {
                                if (loyaltyType === 'giftcard') {
                                  setSearchValue('')
                                  setShowServicesModal(false)
                                  onClickItem({
                                    itemName: item?.name,
                                    serviceId: BigInt(item?.id),
                                    serviceTypeId: null,
                                    description: item?.description,
                                    discountRate: 0,
                                    quantity: 1,
                                  })
                                } else {
                                  setSelectedService(item?.id)
                                }
                              }}
                            >
                              <div className="min-w-200 mr-auto text-start">
                                <div>{item.name}</div>

                                <span
                                  className="text-sm text-gray-500 tracking-wider"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                />
                              </div>
                              {selectedService === item?.id ? (
                                <div className="flex items-center mr-[5px]">
                                  <span className="mr-[8px]">Kol:</span>
                                  <input
                                    pattern="/^[0-9]+$/"
                                    autoFocus
                                    className="w-[70px] h-full border-[1px] border-zoyya-primary rounded-[4px] p-[9px_8px_8px_8px]"
                                    name={`quantity_${item?.id}`}
                                    value={inputValue}
                                    onChange={e => {
                                      const value = e.target.value.replace(
                                        /[^\d]/,
                                        ''
                                      )

                                      if (parseInt(value) !== 0) {
                                        setInputValue(value)
                                      }
                                    }}
                                  />
                                </div>
                              ) : null}

                              <p className="mr-0 w-[100px] text-end self-center">
                                {formatPriceNumberWithDelimiter(item.price) +
                                  ' ' +
                                  currency}
                              </p>
                              <div className="w-[70px] flex justify-end">
                                {selectedService === item?.id ? (
                                  <ButtonTW
                                    disabled={
                                      !inputValue ||
                                      !isInteger(Number(inputValue))
                                    }
                                    icon={
                                      <icons.Add size="smaller" stroke={2} />
                                    }
                                    variant="primary"
                                    onClick={() => {
                                      setSearchValue('')
                                      setShowServicesModal(false)
                                      onClickItem({
                                        itemName: item?.name,
                                        serviceId: BigInt(item?.id),
                                        serviceTypeId: null,
                                        description: item?.description,
                                        discountRate:
                                          roundNumberWithTwoDecimals(
                                            Number(
                                              formRef?.values?.discountRate
                                            )
                                          ) / 100,
                                        quantity: Number(inputValue),
                                      })
                                    }}
                                  />
                                ) : null}
                              </div>
                            </button>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
        ) : (
          <EmptyList title="Nema rezultata pretrage" />
        )}
      </div>
    </div>
  )
}
