import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Graph = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.44 19.5h23M4.441 19.5V15v0a.5.5 0 00-.5-.5h-2 0a.5.5 0 00-.5.5s0 0 0 0v4.5M10.441 19.5V10v0a.5.5 0 00-.5-.5h-2 0a.5.5 0 00-.5.5s0 0 0 0v9.5M16.441 19.5V12v0a.5.5 0 00-.5-.5h-2 0a.5.5 0 00-.5.5s0 0 0 0v7.5M22.441 19.5V5v0a.5.5 0 00-.5-.5h-2 0a.5.5 0 00-.5.5s0 0 0 0v14.5"></path>
      </g>
    </StyledIcon>
  )
}

export default Graph
