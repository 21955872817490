import React from 'react'
import Button from '../Button/Button'
import { ErrorContainer, ErrorText, ErrorTitle } from '../ErrorBoundary/style'
import { useSdk } from '../../sdk'

const NotFound = () => {
  const { appServices } = useSdk()
  const onRefresh = () => {
    window.history.back()
    setTimeout(() => window.location.reload(), 1000)
  }

  const onLogout = () => {
    appServices.auth.signOut()
    window.open(`/login`, '_self')
  }

  const { t } = useSdk()

  return (
    <ErrorContainer>
      <img src={'/assets/404.svg'} alt="" />
      <ErrorTitle>{t('translation.NotFound.title-error-404')}</ErrorTitle>
      <ErrorText>
        {t('translation.NotFound.label-page-does-not-exists-1')}
        <br /> {t('translation.NotFound.label-page-does-not-exists-2')}
      </ErrorText>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          buttonType={'primary'}
          onClick={onRefresh}
          label={t('translation.NotFound.label-go-back')}
        />
        <Button
          buttonType={'secondaryLink'}
          onClick={onLogout}
          label={t('translation.NotFound.label-sign-out')}
          style={{ marginLeft: 10 }}
        />
      </div>
    </ErrorContainer>
  )
}
export default NotFound
