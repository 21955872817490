import styled from 'styled-components'
import { device } from '../../constants'
import { FieldRow, ButtonRow, FieldColumn } from '../Settings/styles'
import {
  LoginForm,
  LoginFormWrapper,
  LoginHeader,
  LoginFormHeader,
} from 'views/Login/styles'
import { red } from 'components/style/colors'

export const LoginFormFlow = styled(LoginForm)`
  flex-direction: row-reverse;
  width: 900px;
  height: 650px;
  position: relative;
  font-weight: 300;
  @media ${device.tablet} {
    width: unset;
    height: 100%;
    flex-direction: column;
  }
`

export const LoginFormHeaderFlow = styled(LoginFormHeader)`
  height: auto;
  width: 500px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 20px;
  @media ${device.tablet} {
    width: 100%;
    border-radius: 0;
  }
`
export const LoginHeaderFlow = styled(LoginHeader)``
export const OrganizationAvatarRow = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
`
export const OrganizationAvatar = styled.img<{ src: string }>`
  width: 100%;
  max-width: 100%;
  height: 150px;
  border-radius: 0.286rem;
  margin-right: 1.714rem;
  object-fit: ${props =>
    props.src.includes('noImage.svg') ? 'contain' : 'cover'};
  //border: 1px solid ${props => props.theme.colors.outline};
`
export const OrganizationAvatarButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  button {
    padding: 0;
  }
`
export const FeatureList = styled.ul`
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`
export const Feature = styled.li`
  display: flex;
  margin-bottom: 0.5rem;
  span {
    margin-left: 5px;
  }
`
export const LoginFormMain = styled.div`
  margin-bottom: auto;
  overflow-y: auto;
  padding: 3px 27px 0 30px;
  margin-right: 3px;
  height: 100%;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cdd0d3;
  }
  @media ${device.tablet} {
    overflow: initial;
    height: unset;
    padding: 20px 20px 0 20px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  input[type='checkbox'] {
    width: 17px;
  }
  label {
    color: #777;
    font-size: 12px;
    margin-bottom: 0px !important;
  }
`

export const LoginFormFooter = styled.div<{ step?: number }>`
  padding: 23px;
  box-shadow: ${props =>
    props.step === 3 ? null : '0 3px 15px rgba(0, 0, 0, 0.1)'};
  position: relative;
  ${FieldRow} {
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    box-shadow: none;
    padding: 0 20px;
  }
`

export const LoginFormWrapperFlow = styled(LoginFormWrapper)<{ step?: number }>`
  width: ${props => (props.step === 3 ? '100%' : '500px')};
  padding: 0;
  border-radius: ${props => (props.step === 3 ? '20px' : '20px 0 0 20px')};
  @media ${device.tablet} {
    width: 100%;
    //height: 100%
    &::-webkit-scrollbar {
      display: none;
    }
  }
  form > div:first-child {
    height: 100%;
  }
`
export const LoginTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0px 20px;
`
export const LoginTitleFlow = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  display: flex;
`
export const StyledBackButton = styled.div`
  display: flex;
  margin-right: 32px;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`
export const LoginFormText = styled.p`
  margin-bottom: 20px;
  color: #333;
`
export const LoginFormBullets = styled.div`
  margin-bottom: 15px;
`
export const LoginFormBullet = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`
export const Bullet = styled.div`
  height: 6px;
  width: 6px;
  background: ${props => props.theme.colors.primary};
  border-radius: 50px;
  margin-right: 10px;
`
export const ButtonRowFlow = styled(ButtonRow)`
  width: 190px;
  @media ${device.tablet} {
    width: 49%;
  }
`

export const ErrorMessage = styled.small`
  display: block;
  padding: 0 0 15px 10px;
  color: ${red};
  margin-top: -5px;
`
export const FeatureIcon = styled.div`
  flex-shrink: 0;
  margin-right: 8px;
  padding-top: 7px;
`
export const LoginFormTextBold = styled.span`
  font-weight: 500;
`
export const SignupFlowHeader = styled.div`
  padding: 19px 30px;
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    display: none;
  }
`
export const SignupOrgImageRow = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin-top: 5px;
`

export const SignupOrgImage = styled.img<{ src?: string }>`
  width: 100%;
  height: 193px;
  border-radius: 0.286rem;

  object-fit: ${props =>
    props?.src?.includes('noImage.svg') ? 'contain' : 'cover'};
  border: 1px solid ${props => props.theme.colors.outline};
`
export const SignupOrgImageButtons = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  button {
    padding: 0;
  }
`
export const FieldColumnMarginRight = styled(FieldColumn)`
  @media ${device.tablet} {
    margin-right: 4px;
  }
`
export const FieldColumnMarginLeft = styled(FieldColumn)`
  @media ${device.tablet} {
    margin-left: 4px;
  }
`

export const MobileSignupHeader = styled.div`
  height: 80px;
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.light};
  font-size: 20px;
  padding: 0 20px;
`

export const MobileSignupTitle = styled.div``

export const Condition = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`

export const SignupPage = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(72, 47, 180, 0.3) 100%,
    #30364d
  );
  @media ${device.tablet} {
    display: initial;
  }
`

export const LogoColor = styled.img`
  width: 130px;
  margin-right: auto;
`

export const SignupImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  @media ${device.tablet} {
    display: none;
  }
`
