import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Coins = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.04 9.793c1.952.39 1.952 1.024 0 1.414-1.953.39-5.119.39-7.072 0-1.952-.39-1.952-1.024 0-1.414 1.953-.39 5.119-.39 7.072 0"></path>
        <path d="M10.5 10.5v3c0 .553-2.238 1-5 1s-5-.447-5-1v-3"></path>
        <path d="M10.5 13.5v3c0 .553-2.238 1-5 1s-5-.447-5-1v-3"></path>
        <path d="M10.5 16.5v3c0 .553-2.238 1-5 1s-5-.447-5-1v-3"></path>
        <path d="M10.5 19.5v3c0 .553-2.238 1-5 1s-5-.447-5-1v-3M.5 7.5v-6h0a1 1 0 011-1h21a1 1 0 011 1v12a1 1 0 01-1 1H13"></path>
        <path d="M9 7.5h0a3 3 0 114 2.829M5 4.75h0a.25.25 0 11-.25.25h0A.25.25 0 015 4.75s0 0 0 0M19 9.75h0a.25.25 0 11-.25.25h0a.25.25 0 01.25-.25s0 0 0 0"></path>
        <path d="M2.5 7.5V3h0a.5.5 0 01.5-.5h18a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-8"></path>
      </g>
    </StyledIcon>
  )
}
