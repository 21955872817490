import React, { useRef, useContext } from 'react'
import * as Yup from 'yup'
import { useGetLocationLoading } from 'state/queries'
import { useParams } from 'react-router'
import { Button, Form, Loader } from 'components'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import {
  FormsContainer,
  ContainerForFixedFooterButtons,
  FormFooterButtonsContainerMobile,
} from 'src/styles'
import { getServiceDurationInMinutes } from 'src/helpers'
import { FormFieldsWrapper } from 'components/Form/Form'
import { MobileContext } from 'src/Context'
import { useAppServices } from 'sdk/appServices'
import { useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { ServiceForm } from './ServiceForm'
import { useLocation } from 'react-router-dom'

export const ServiceSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.ServiceEditorForm.validation-name-required')
    ),
    durationMinutes: Yup.string().required(
      t('translation.ServiceEditorForm.validation-duration-required')
    ),
    netDurationMinutes: Yup.string()
      .required(t('translation.ServiceEditorForm.validation-duration-required'))
      .test(
        'validDurationMinutes',
        t('translation.ServiceEditorForm.validation-net-duration'),
        function (value) {
          const { parent } = this
          const durationMinutes = Number.isInteger(
            Number(parent?.durationMinutes)
          )
            ? Number(parent?.durationMinutes)
            : getServiceDurationInMinutes(parent?.durationMinutes)

          return (
            Number.isInteger(Number(value)) && Number(value) <= durationMinutes
          )
        }
      ),
    type: Yup.object()
      .nullable()
      .required(
        t('translation.ServiceEditorForm.validation-service-type-required')
      ),
    price: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    vatRate: Yup.string().matches(
      /^-?(?:\d+)(?:(\.|,)\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    // .required(t('translation.ServiceEditorForm.validation-vatRate-required')),
  })

type ServiceEditorFormProps = {
  onSubmit: (values: any) => Promise<void>
  initialValues: any
  isNew?: boolean
  loading?: boolean
  onDelete?: () => Promise<void>
  hasOnlineBooking?: boolean
  setOpenBillingModal?: any
  setShowAddServiceForm?: (boolean) => any
}

export const ServiceEditorForm = ({
  onSubmit,
  initialValues,
  isNew,
  loading,
  onDelete,
  hasOnlineBooking,
  setOpenBillingModal,
  setShowAddServiceForm,
}: ServiceEditorFormProps) => {
  const { t, navigateTo } = useSdk()
  const { typeId, locationId, orgId } = useParams<{
    typeId: string
    locationId: string
    orgId: string
  }>()
  const { pathname } = useLocation<any>()

  const loadOrg = useQuery(getOrganizationByIdGql, {
    variables: { id: orgId },
    fetchPolicy: 'cache-first',
  })
  const { data: organizationData } = loadOrg
  const org = organizationData?.organization?.get

  let formRef = useRef<any>()

  useHeader({
    content: t('translation.ServiceEditorForm.label-service'),
    onGoBack: navigateTo.servicesList,
  })

  const viewLocationData = useGetLocationLoading(locationId)

  const appServices = useAppServices()
  const isMobile = useContext(MobileContext)

  const handleValueChange = (name, value, _, form) => {
    if (name === 'durationMinutes') {
      form.setFieldValue(
        'netDurationMinutes',
        getServiceDurationInMinutes(value)
      )
    }
  }

  return (
    <>
      <ContainerForFixedFooterButtons>
        {loading || viewLocationData?.loading || loadOrg?.loading ? (
          <Loader isComponent />
        ) : (
          <FormsContainer>
            <Form
              onValueChange={handleValueChange}
              onSubmit={onSubmit}
              initialValues={{
                ...initialValues,
                vatRate: org?.vatStatus === 'Y' ? initialValues.vatRate : 0,
                currencyId: org?.currency?.currencyCode,
              }}
              validationSchema={() => ServiceSchema(t)}
            >
              {form => {
                formRef.current = form
                return (
                  <FormFieldsWrapper>
                    <ServiceForm
                      form={form}
                      isNew={isNew}
                      hasOnlineBooking={hasOnlineBooking}
                      setOpenBillingModal={setOpenBillingModal}
                      onDelete={onDelete}
                      setShowAddServiceForm={setShowAddServiceForm}
                    />
                  </FormFieldsWrapper>
                )
              }}
            </Form>
          </FormsContainer>
        )}
      </ContainerForFixedFooterButtons>
      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            footerButton
            buttonType={'text'}
            name="close"
            type={'button'}
            label={t('translation.ServiceEditorForm.button-close')}
            onClick={() => {
              pathname.includes(`/loyalty`)
                ? setShowAddServiceForm?.(false)
                : navigateTo.servicesList({
                    locationId,
                    typeId,
                  })
            }}
          />
          <Button
            onClick={() => {
              if (Object.keys(formRef.current.errors).length !== 0) {
                if (formRef.current.errors.name)
                  appServices.toast.danger(formRef.current.errors.name)
                if (formRef.current.errors.durationMinutes)
                  appServices.toast.danger(
                    formRef.current.errors.durationMinutes
                  )
                if (formRef.current.errors.type)
                  appServices.toast.danger(formRef.current.errors.type)
                if (formRef.current.errors.price)
                  appServices.toast.danger(formRef.current.errors.price)
              } else formRef.current.submitForm()
            }}
            buttonType={'primary'}
            label={t('translation.ServiceEditorForm.button-save')}
            name="saveChanges"
          />
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}
