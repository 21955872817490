import React, { CSSProperties, useState, useCallback } from 'react'
import { useField } from 'formik'
import { FieldContainer } from '../FieldContainer'
import { useFormContext } from '../Form'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../../style/fonts'
import {
  blue,
  blueLighter,
  grayLight,
  grayDarker,
  grayDark,
  red,
  redLight,
  redLighter,
  white,
} from '../../style/colors'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/en-gb'
import 'moment/locale/hr'
import 'moment/locale/de'
import styled from 'styled-components'
import moment from 'moment'
import { useSdk } from '../../../sdk'
import { getCurrentLanguage } from 'src/localization'
import Calendar from 'components/Icon/Icons/Calendar'
const StyledDayInput = styled.div<any>`
  /* DayPicker styles */
  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;

    flex-direction: row;
    padding-bottom: 1em;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
  }

  .DayPicker-NavBar {
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 0.5em;
    right: 1.5em;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    z-index: 2000;
    cursor: pointer;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
  }

  .DayPicker-NavButton--next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    margin-top: -9px;
    padding: 0 0.5em;
    text-align: left;
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
    margin-top: 1em;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 6px 9px;
    border-radius: 100px;
    vertical-align: middle;
    text-align: center;
    outline: none;
    cursor: pointer;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: ${props => props.theme.colors.light};
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    color: ${props => props.theme.colors.accent4};
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    /* background-color: #eff1f1; */
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: #f7f8f8;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: ${props => props.theme.colors.accent4};
    color: white;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f0f8ff;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 99999999;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
  .DayPickerInput input {
    margin: 0px;
    padding: 6px;

    outline: none;
    width: 120px;
    margin: 0 0 8px 0;
    background: ${({ hasError }) => (hasError ? redLight : white)};
    border-radius: 4px;
    border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    box-shadow: 2px 2px 0 2px transparent;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
    color: ${({ hasError }) => (hasError ? red : grayDarker)};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    padding: 9px 8px 8px 8px;
    padding-left: ${props => (props.hasIcon ? '40px' : 'auto')};
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
    cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }

    &:focus {
      border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
      box-shadow: 0px 0px 0px 3px
        ${({ hasError }) => (hasError ? redLighter : blueLighter)};
      outline: none;
      transition-property: border-width, border-color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }

    &:disabled {
      background: ${grayLight};
      border: 1px solid ${grayLight};
      color: ${grayDark};
      cursor: not-allowed;

      &::placeholder {
        color: ${props => props.theme.colors.secondaryDark};
      }
    }
  }
`

const StyledInput = styled.input<{
  error?: string
  greyTextIfDisabled?: boolean
}>`
  outline: none;

  flex: 1;
  color: ${props => props.theme.color};
  border-radius: 4px;
  border: 1px solid #dbdfe2;
  /* :hover {
    border: 1px solid
      ${props =>
        props.error
          ? props.theme.borderColorError
          : props.theme.borderHoverColor};
  } */
  :focus {
    border: 1px solid #6a4ee1;
    box-shadow: 0px 0px 0px 3px #6b81ff;
  }
  &:disabled {
      color: ${props => (props.greyTextIfDisabled ? grayDark : undefined)};
    
    }
  &:read-only {
      color: ${props => (props.greyTextIfDisabled ? grayDark : undefined)};
    
    }
  //margin: 0 0 8px 0;
  background: #ffffff;
  box-shadow: 2px 2px 0 2px transparent;
  -webkit-transition-property: border-width, border-color, box-shadow;
  transition-property: border-width, border-color, box-shadow;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  font-family: Poppins, SFProDisplay, Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  padding: 9px 8px 8px 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: default;
`
type TFormFieldDateProps = {
  name: string
  label?: string
  width?: any
  disabled?: boolean
  style?: CSSProperties
  tabIndex?: number
  labelComponent?: any
  disabledDays?: any
  helpText?: string
  helpLink?: string
  greyTextIfDisabled?: boolean
}

export const FormFieldDate = (props: TFormFieldDateProps) => {
  const {
    disabled,
    label,
    helpText,
    helpLink,
    width,
    greyTextIfDisabled,
  } = props
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const [field, meta] = useField(props)
  const formContext = useFormContext()
  const { t } = useSdk()

  const formatValue = useCallback(value => {
    if (!value) return ''
    return moment(value).format('YYYY-MM-DD')
  }, [])

  const handleDayChange = e => {
    const newVal = formatValue(e)
    formContext?.valueChange(field, field.name, newVal)
  }

  const parseDate = date => {
    if (!date) return

    return MomentLocaleUtils.parseDate(
      date,
      t('regionalFormats.date.momentFormatDate'),
      getCurrentLanguage()
    )
  }
  const formatDate = date => {
    if (!date) return ''

    return MomentLocaleUtils.formatDate(
      date,
      t('regionalFormats.date.momentFormatDate'),
      getCurrentLanguage()
    )
  }

  return (
    <FieldContainer
      label={label}
      error={meta.error}
      helpText={helpText}
      helpLink={helpLink}
      style={{ width: width ? width : 170 }}
    >
      {disabled ? (
        <>
          <StyledInput
            readOnly // should be disabled but then in some browser it doesnt emit mouse events, this simulates the same thing
            greyTextIfDisabled={greyTextIfDisabled}
            data-cy={`input_${field.name}`}
            value={formatDate(field.value ? new Date(field.value) : undefined)}
          />
          {!props.labelComponent && (
            <CalendarIcon
              stroke={1.25}
              size="smaller"
              strokeColor={greyTextIfDisabled ? grayDark : undefined}
            />
          )}
        </>
      ) : (
        <StyledDayInput data-cy={'datePicker'}>
          <DayPickerInput
            value={field.value ? new Date(field.value) : undefined}
            format={t('regionalFormats.date.momentFormatDate')}
            keepFocus={false}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={t('regionalFormats.date.momentFormatDate')}
            onDayChange={handleDayChange}
            component={props => (
              <input {...props} data-cy={`input_${field.name}`} />
            )}
            dayPickerProps={{
              locale: getCurrentLanguage(),
              localeUtils: MomentLocaleUtils,
              month: new Date(year, month, 1),
              disabledDays: props.disabledDays,
              onMonthChange: month => {
                setYear(month.getFullYear())
                setMonth(month.getMonth())
              },
            }}
          />
          {!props.labelComponent && <CalendarIcon stroke={1.25} size="small" />}
        </StyledDayInput>
      )}
    </FieldContainer>
  )
}
FormFieldDate.defaultProps = {
  width: '100%',
}

const CalendarIcon = styled(Calendar)`
  pointer-events: none;
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`
