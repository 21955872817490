import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikProps,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import React, { useState, useRef, useEffect, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Form,
  FormField,
  Loader,
  Tab,
  TabView,
  ToolTip,
  icons,
} from 'src/components'
import { formatPhone, getServiceAmounts } from 'src/helpers'
import { useSdk } from 'src/sdk'
import {
  useClientUpdateMutation,
  useClientsAddMutation,
  useGetOrganizationByIdQuery,
  useGetServiceTypesQuery,
  useGetSmsBalanceQuery,
  useLocationGetQuery,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
  usePrepaymentsSendPaymentNotificationV2Mutation,
  useResourceBookingLocationScheduleLazyQuery,
} from 'src/state/graphql'
import { trpc } from 'src/trpc'
import { PaymentNotification } from '../AppointmentModal/components/PaymentNotification'
import { ClientDetails } from '../AppointmentModal/components/ClientDetails'
import { useACL } from 'src/sdk/acl'
import { useQuery } from '@apollo/client'
import { Row } from 'src/styles'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import {
  ServicesRow,
  StyledFieldColumn,
  StyledFieldRow,
  StyledTextArea,
  Container,
  Modal,
  ModalWrapper,
  FormWrapper,
} from '../Appointment/Appointment'
import {
  getStatusColor,
  getStatusLabel,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { ThemeContext } from 'styled-components'
import { HeaderWrapper } from '../Appointment/components/Header'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { AppointmentChangelog } from '../Appointment/AppointmentChangelog'
import { AppointmentAttachments } from '../AppointmentModal/components/AppointmentAttachments'
import { ClientFormWrapper } from '../AppointmentModal/components/ClientFormWrapper'
import { chain, orderBy, set, some } from 'lodash'
import { AppointmentHotelFooter } from './components/AppointmentHotelFooter'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { HotelDateRangePicker } from './components/HotelDateRangePicker/AppointmentHotelDateRangePicker'
import moment from 'moment'
import { AppointmentHotelService } from './components/AppointmentHotelServiceForm'
import { searchClients, useInviteUser } from 'src/state/queries'
import { useFooter } from 'src/mobile/Footer'

export const AppointmentHotelNew = (props: {
  onClose: () => void
  selectedDateFrom?: string
  selectedResourceId?: bigint
}) => {
  const { orgId, locationId } = useParams<{
    orgId: string
    locationId: string
  }>()
  const { onClose, selectedResourceId, selectedDateFrom } = props
  const { isMobile, t, toast, appServices } = useSdk()

  // either selected from cell date or set to today(plus button action)
  const initialDateFrom = selectedDateFrom || moment().format('YYYY-MM-DD')

  const { ClientSchema } = useValidationSchema()

  const trpcContext = trpc.useContext()

  const [activeForm, setActiveForm] = useState<
    'appointment' | 'client' | 'status' | 'paymentNotification'
  >('appointment')

  const selectedTheme = useContext(ThemeContext)

  const [isBusy, setIsBusy] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [clientsSortBy, setClientsSortBy] = useState('')
  const [isNewClient, setIsNewClient] = useState(false)

  const formRef = useRef<any>(null)

  const { hasPermission } = useACL()
  const canAccessInvoicing = hasPermission('ACCESS_INVOICING')
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const { data: locationData, loading: locationLoading } = useLocationGetQuery({
    variables: {
      locationId,
    },
  })

  const {
    data: smsBalanceData,
    loading: smsBalanceLoading,
  } = useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    const clientsSortBy = localStorage.getItem('clientsSortBy')
    if (clientsSortBy) {
      setClientsSortBy(clientsSortBy)
    } else setClientsSortBy('id_desc')
  }, [])

  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  const location = locationData?.location?.get

  const currentDayOpenTime =
    location?.workWeek?.[
      moment()
        .locale('en')
        .format('ddd')
        .toLowerCase()
    ][0]?.openTime

  const currentDayCloseTime =
    location?.workWeek?.[
      moment()
        .locale('en')
        .format('ddd')
        .toLowerCase()
    ][0]?.closeTime

  const {
    data: organizationData,
    loading: loadingOrgData,
  } = useGetOrganizationByIdQuery({
    variables: { id: orgId },
  })

  const org = organizationData?.organization?.get

  // #region PREPAYMENTS_INFO
  const {
    data: stripeConnectAccountData,
    loading: loadingStripeConnectAccountData,
  } = usePrepaymentsGetStripeConnectAccountInfoQuery({
    skip: !org?.stripeConnectAccountId,
  })
  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled

  const hasInvoicingData =
    location?.prepaymentsConfig?.userId &&
    location?.prepaymentsConfig?.operatorId &&
    location?.prepaymentsConfig?.prepaymentsDeviceId &&
    location?.prepaymentsConfig?.prepaymentServiceId

  const hasPrepaymentsCredentials =
    org?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : org?.hasVivaMerchantId && org?.hasVivaApiKey && hasInvoicingData

  const hasPrepaymentsEnabled = location?.prepaymentsConfig?.prepaymentsEnabled

  // #endregion PREPAYMENTS_INFO

  const serviceTypesData = useGetServiceTypesQuery({
    variables: { locationId },
    fetchPolicy: 'network-only',
  })

  const serviceTypes = serviceTypesData.data?.serviceType?.search

  const services = useMemo(() => {
    return serviceTypes?.map(type => {
      const sortedServices = type?.services
        ?.slice()
        ?.sort((a: any, b: any) =>
          a?.sequence?.localeCompare(b?.sequence, undefined, { numeric: true })
        )
      const options = sortedServices?.map(service => {
        return {
          ...service,
          id: service?.id,
          label: service?.name,
          currency: org?.currency?.id || '',
          resourceTypeId: service?.resourceType?.id,
        }
      })
      return {
        ...type,
        label: type?.name,
        options: orderBy(options, 'hasProfession'),
      }
    })
  }, [serviceTypes, org])

  const flattenedServices = useMemo(
    () =>
      chain(serviceTypes)
        .map('services')
        .flatten()
        .value(),
    [serviceTypes]
  )
  const resourcesData = location?.resources

  const employees = useMemo(
    () => resourcesData?.filter(x => x.showInCalendar && x.kind === 'USER'),
    [resourcesData]
  )

  const resources = useMemo(
    () => resourcesData?.filter(x => x.kind === 'RESOURCE'),
    [resourcesData]
  )

  const [resourceAvailabilityParams, setResourceAvailabilityParams] = useState({
    dateFrom: initialDateFrom,
    dateTo: initialDateFrom,
    startTime: currentDayOpenTime,
    endTime: currentDayCloseTime,
  })

  const {
    data: resourcesAvailabilityData,
    isLoading: resourcesAvailabilityLoading,
    refetch: refetchResourcesAvailability,
  } = trpc.hotel_getResourceAvailability.useQuery(
    {
      locationId: BigInt(locationId),
      dateFrom: moment(resourceAvailabilityParams.dateFrom).toDate(),
      dateTo: moment(resourceAvailabilityParams.dateTo).toDate(),
      startTime: resourceAvailabilityParams.startTime,
      endTime: resourceAvailabilityParams.endTime,
    },
    {
      enabled:
        !!resourceAvailabilityParams.dateFrom &&
        !!resourceAvailabilityParams.dateTo &&
        !!resourceAvailabilityParams.startTime &&
        !!resourceAvailabilityParams.endTime,
    }
  )

  //region MUTATIONS
  const [
    clientAddMutation,
    { loading: loadingAddClient },
  ] = useClientsAddMutation()

  const [
    updateClient,
    { loading: loadingUpdateClient },
  ] = useClientUpdateMutation()

  const [inviteUser] = useInviteUser()

  const [
    sendPaymentNotificationMutation,
  ] = usePrepaymentsSendPaymentNotificationV2Mutation()

  const bookHotelAppointmentMutation = trpc.hotel_bookingCreate.useMutation()

  const sendPaymentNotification = async (
    resourceBookingId,
    showSuccessToast = true
  ) => {
    try {
      setIsBusy(true)
      if (!resourceBookingId) return

      const results = await sendPaymentNotificationMutation({
        variables: {
          id: resourceBookingId,
        },
      })

      if (!results.errors) {
        if (showSuccessToast) {
          toast.success(t('translation.AppointmentModal.paymentRequest-sent'))
        }
      } else toast.danger(results?.errors?.[0]?.message)
    } catch (err) {
      console.log(err)
    } finally {
      setIsBusy(false)
    }
  }

  const handleCreateHotelAppointment = async values => {
    try {
      const shouldSendPaymentRequest =
        values?.sendPaymentRequest &&
        hasPrepaymentsCredentials &&
        !!values?.serviceId &&
        values?.client?.id

      const result = await bookHotelAppointmentMutation.mutateAsync({
        clientId: values?.client?.id ? BigInt(values?.client?.id) : null,
        sendAppointmentEmail: shouldSendPaymentRequest
          ? false
          : values?.sendAppointmentEmail,
        sendAppointmentSms: shouldSendPaymentRequest
          ? false
          : values?.sendAppointmentSms,
        notes: values?.internalNote,
        messageToClient: values?.sendAppointmentEmail
          ? values?.messageToClient
          : null,
        services: [
          {
            dateFrom: moment(values?.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(values?.dateTo).format('YYYY-MM-DD'),
            serviceId: BigInt(values?.serviceId),
            resourceId: BigInt(values?.resourceId),
            startTime: values?.startTime?.title,
            endTime: values?.endTime?.title,
            quantity: values?.quantity || 1,
            discountAmount: parseFloat(values?.discountAmount) || 0,
            discountType: values?.discountType || 'percent',
            discountRate: parseFloat(values?.discountRate) || 0,
            totalAmount: parseFloat(values?.totalAmount) || 0,
            price: parseFloat(values?.price) || 0,
          },
        ],
      })

      trpcContext.hotel_calendarDataGet.invalidate()
      if (shouldSendPaymentRequest) {
        sendPaymentNotification(result.id.toString())
      } else {
        toast.success(t('translation.AppointmentModal.toast-bookingSuccess'))
      }

      onClose()
    } catch (err) {
      toast.danger('Došlo je do pogreške')
    }
  }

  async function createClient(values) {
    try {
      setIsBusy(true)

      if (isNewClient) {
        const clientResult = await clientAddMutation({
          variables: {
            input: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              language: org?.language || 'hr',
              city: values.city,
              comment: values.comment,
              mobilePhone: formatPhone(values.mobilePhone),
              gender: values.gender,
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              sendBookingEmail: true,
              sendNewsEmail: true,
              sendHelpEmail: true,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })
        if (values?.sendInviteViaEMail) {
          await inviteUser({
            variables: {
              input: {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim() || '',
                email: values.email,
                gender: values.gender || 'Unspecified',
                mobilePhone: formatPhone(values.mobilePhone),
                role: 'CLIENT',
                clientId: clientResult?.data?.clients?.add?.payload?.id!,
              },
            },
          })
        }

        if (!clientResult?.errors) {
          toast.success(
            (t('translation.AppointmentModal.toast-newClientEntered'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.add?.payload
        } else {
          toast.danger(t('translation.AppointmentModal.toast-newClientError'))
        }
      } else {
        let clientResult = await updateClient({
          variables: {
            input: {
              id: values.client?.id,
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              comment: values.comment,
              gender: values.gender,
              mobilePhone: formatPhone(values.mobilePhone),
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })

        if (clientResult) {
          toast.success(
            (t('translation.EditClient.toast-editClient'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.update?.payload
        }
      }
    } finally {
      setIsBusy(false)
    }
  }

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('client', null)
      setActiveForm('client')
      setIsNewClient(true)
      form.setFieldValue('firstName', '')
      form.setFieldValue('lastName', '')
      form.setFieldValue('email', '')
      form.setFieldValue('mobilePhone', '')
      form.setFieldValue('address', '')
      form.setFieldValue('zipCode', '')
      form.setFieldValue('city', '')
      form.setFieldValue('gender', '')
      form.setFieldValue('comment', '')
      form.setFieldValue('paymentRequired', true)
    }
  }
  function getOnEditClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> },
    values
  ) {
    return () => {
      setIsNewClient(false)
      setActiveForm('client')
      form.setFieldValue('firstName', values?.client?.firstName)
      form.setFieldValue('lastName', values?.client?.lastName)
      form.setFieldValue('email', values?.client?.email)
      form.setFieldValue('mobilePhone', values?.client?.mobilePhone)
      form.setFieldValue('address', values?.client?.address)
      form.setFieldValue('zipCode', values?.client?.zipCode)
      form.setFieldValue('city', values?.client?.city)
      form.setFieldValue('gender', values?.client?.gender)
      form.setFieldValue('comment', values?.client?.comment)
      form.setFieldValue('birthDate', values?.client?.birthDate)
      form.setFieldValue('vatId', values?.client?.vatId)
      form.setFieldValue('country', values?.client?.country)
      form.setFieldValue('sendReminderEmail', values?.client?.sendReminderEmail)
      form.setFieldValue('sendReminderSms', values?.client?.sendReminderSms)
      form.setFieldValue('sendBookingEmail', values?.client?.sendBookingEmail)
      form.setFieldValue('sendNewsEmail', values?.client?.sendNewsEmail)
      form.setFieldValue('sendHelpEmail', values?.client?.sendHelpEmail)
      form.setFieldValue('paymentRequired', values?.client?.paymentRequired)
    }
  }

  const shouldShowSendNotificationEmailCheckBox = values => {
    return values?.sendPaymentRequest ? false : !!values.client?.email
  }

  const shouldShowSendNotificationSmsCheckBox = values => {
    return values?.sendPaymentRequest ? false : !!values.client?.mobilePhone
  }

  const getSaveButtonLabel = () => {
    return activeForm === 'paymentNotification'
      ? !hasPrepaymentsCredentials
        ? t('translation.AppointmentModal.button-goToPrepayments')
        : formRef.current.values.posPaymentRequest?.status === 'PENDING_PAYMENT'
        ? t('translation.AppointmentModal.resendPaymentNotification')
        : t('translation.AppointmentModal.sendPaymentNotification')
      : t('translation.AppointmentModal.button-save')
  }

  const initialValues = {
    id: null,
    notes: null,
    attachments: [],
    startTime: { id: currentDayOpenTime, title: currentDayOpenTime },
    endTime: { id: currentDayCloseTime, title: currentDayCloseTime },
    dateFrom: initialDateFrom,
    dateTo: initialDateFrom,
    sendAppointmentEmail: location?.sendAppointmentEmailsToClients,
    sendAppointmentSms: location?.sendAppointmentSmsToClients,
    appointmentCopySendAppointmentEmail:
      location?.sendAppointmentEmailsToClients,
    sendReminderSms: true,
    sendReminderEmail: true,
    client: null,
    resourceId: selectedResourceId ? selectedResourceId.toString() : null,
    serviceId: null,
    sendPaymentRequest: false,
    totalPrice: 0,
    quantity: 1,
    totalAmount: 0,
    price: 0,
    currencyId: org?.currency?.id || '',
  }

  const handleValueChange = async (name, value, values, form) => {
    if (
      name === 'dateFrom' ||
      name === 'dateTo' ||
      name === 'startTime' ||
      name === 'endTime'
    ) {
      const startDateTime = moment(
        `${moment(values?.dateFrom).format('YYYY-MM-DD')} ${
          values?.startTime?.title
        }`
      )
      const endDateTime = moment(
        `${moment(values?.dateTo).format('YYYY-MM-DD')} ${
          values?.endTime?.title
        }`
      )

      const rangeDurationDays = Math.ceil(
        moment.duration(endDateTime.diff(startDateTime)).asDays()
      )
      const {
        totalAmount,
        totalPrice,
        discountAmount,
        discountRate,
      } = getServiceAmounts({
        price: values?.price,
        quantity: rangeDurationDays,
        discountValue: values?.discountValue,
        discountType: values?.discountType,
      })

      form.setFieldValue('quantity', rangeDurationDays)
      form.setFieldValue('totalAmount', totalAmount)
      form.setFieldValue('totalPrice', totalPrice)
      form.setFieldValue('discountAmount', discountAmount)
      form.setFieldValue('discountRate', discountRate)

      setResourceAvailabilityParams({
        ...resourceAvailabilityParams,
        [name]:
          name === 'startTime' || name === 'endTime' ? value?.title : value,
      })

      await refetchResourcesAvailability()
    }

    if (
      activeForm === 'client' &&
      name === 'email' &&
      value !== value?.trim()
    ) {
      form.setFieldValue('email', value?.trim())
      formRef.current?.validateForm()
    }

    if (name === 'client') {
      const total = parseFloat(values?.totalAmount || '0')

      if (hasPrepaymentsCredentials && total > 0 && hasPrepaymentsEnabled) {
        form.setFieldValue('sendPaymentRequest', value?.paymentRequired)
      }

      form.setFieldValue(
        'sendAppointmentEmail',
        location?.sendAppointmentEmailsToClients && !!values?.client?.email
      )
    }
  }

  const handleSubmit = async (values, form) => {
    if (activeForm === 'appointment') {
      await handleCreateHotelAppointment(values)
    }

    if (activeForm === 'client') {
      const client = await createClient(values)
      // for some reason form.setFieldValue('client', client) doesn't work properly
      formRef.current.setFieldValue('client', client)
      formRef.current.setFieldValue('services', values?.services)
      formRef.current.setFieldValue('internalNote', values?.internalNote)
      formRef.current.setFieldValue(
        'sendAppointmentEmail',
        values?.sendAppointmentEmail
      )
      if (!location?.sendAppointmentEmailsToClients) {
        form.setFieldValue('sendAppointmentEmail', false)
      } else {
        formRef.current.setFieldValue(
          'sendAppointmentEmail',
          values?.sendAppointmentEmail
        )
      }
      formRef.current.setFieldValue(
        'sendPaymentRequest',
        values?.sendPaymentRequest
      )
      setActiveForm('appointment')
    }
  }

  const isLoading =
    locationLoading ||
    //smsBalanceLoading ||
    loadingOrgData ||
    loadingStripeConnectAccountData ||
    serviceTypesData.loading

  const isUpdating =
    loadingAddClient ||
    loadingUpdateClient ||
    resourcesAvailabilityLoading ||
    bookHotelAppointmentMutation.isLoading

  return (
    <div>
      <Container>
        <Modal>
          <ModalWrapper>
            {isLoading ? (
              <Loader isComponent />
            ) : (
              <>
                <FormWrapper>
                  <Form
                    onValueChange={handleValueChange}
                    initialValues={initialValues}
                    validationSchema={
                      activeForm === 'client' ? ClientSchema : null
                    }
                    // enableReinitialize
                    isInitialValid={true}
                    onSubmit={handleSubmit}
                  >
                    {form => {
                      const {
                        values,
                        errors,
                        setFieldValue,
                        validateForm,
                      } = form
                      formRef.current = form

                      const newTotalPrice = roundNumberWithTwoDecimals(
                        values?.totalAmount
                      )

                      const isPrepaymentDisabled =
                        !hasPrepaymentsCredentials ||
                        !values?.serviceId ||
                        newTotalPrice <= 0

                      return activeForm === 'appointment' ? (
                        <>
                          <TabView
                            selectedTabIndex={selectedTabIndex}
                            setSelectedTabIndex={setSelectedTabIndex}
                            isSingleTab={false}
                            hasFooter={false}
                          >
                            <Tab
                              hasFooter={false}
                              title={
                                initialValues?.id
                                  ? isMobile
                                    ? t('translation.Appointment.termin')
                                    : t(
                                        'translation.Appointment.title-view-appointment'
                                      )
                                  : t(
                                      'translation.AppointmentModal.addAppointment'
                                    )
                              }
                            >
                              {(isBusy || isUpdating) && <Loader isComponent />}

                              <div className="py-3 px-4 lg:py-6 lg:px-8">
                                <Row className="mb-4 pb-4 lg:pb-0 lg:border-none border-b-1 border-b-[#f0f0f0] lg:flex-row flex-col">
                                  <HotelDateRangePicker
                                    dateFrom={values?.dateFrom}
                                    dateTo={values?.dateTo}
                                    onDateSet={period => {
                                      setFieldValue('dateFrom', period.dateFrom)
                                      setFieldValue('dateTo', period.dateTo)
                                    }}
                                    // minDate={moment().format('YYYY-MM-DD')}
                                  />
                                </Row>

                                <Row>
                                  <ClientSelect
                                    onItemSelected={client =>
                                      form.setFieldValue('client', client)
                                    }
                                    selectedItem={values.client}
                                    disabled={
                                      !canEditAppointment ||
                                      values.status === 'canceled' ||
                                      values.status === 'DRAFT'
                                    }
                                    onNewClient={getOnNewClient(form)}
                                  />

                                  {values?.client?.comment ? (
                                    <p className="line-clamp-4 text-zoyya-secondaryDark text-sm">
                                      {values?.client?.comment}
                                    </p>
                                  ) : null}
                                </Row>

                                <ServicesRow
                                  className="lg:mt-6 mt-3"
                                  style={{ borderBottom: 'unset' }}
                                >
                                  <AppointmentHotelService
                                    flattenedServices={flattenedServices}
                                    isMobile={isMobile}
                                    values={values}
                                    isNew
                                    /*  employees={
                                      employees?.map(x => {
                                        const isAvailable = resourcesAvailabilityData?.find(
                                          r =>
                                            r?.resourceId?.toString() ===
                                              x?.id && r?.available
                                        )
                                        return {
                                          ...x,
                                          isBusy: !isAvailable,
                                          isInactive: false,
                                          // disabled: !isAvailable,
                                        }
                                      }) || []
                                    } */
                                    resources={
                                      resources?.map(x => {
                                        const isAvailable = resourcesAvailabilityData?.find(
                                          r =>
                                            r?.resourceId?.toString() ===
                                              x?.id && r?.available
                                        )
                                        return {
                                          ...x,
                                          isBusy: !isAvailable,
                                          isInactive: false,
                                          // disabled: !isAvailable,
                                        }
                                      }) || []
                                    }
                                    setFieldValue={setFieldValue}
                                    isLoading={isLoading}
                                    services={services!}
                                    timeSlotDuration={
                                      location?.timeSlotDuration!
                                    }
                                  />
                                </ServicesRow>

                                {values?.client?.email ? (
                                  <StyledFieldRow style={{ marginTop: 0 }}>
                                    <StyledFieldColumn>
                                      <FormField.Checkbox
                                        label={t(
                                          'translation.AppointmentModal.sendPaymentNotification'
                                        )}
                                        name="sendPaymentRequest"
                                        type={'checkbox'}
                                        disabled={isPrepaymentDisabled}
                                        helpText={t(
                                          'translation.AppointmentModal.sendPaymentNotification-hint'
                                        )}
                                      />
                                    </StyledFieldColumn>
                                  </StyledFieldRow>
                                ) : null}

                                <StyledTextArea
                                  style={{
                                    marginTop: !isMobile ? 10 : 0,
                                    paddingTop: !isMobile ? 10 : 5,
                                  }}
                                >
                                  <FormField.TextArea
                                    label={t(
                                      'translation.AppointmentModal.placeholderText'
                                    )}
                                    placeholder={t(
                                      'translation.Appointment.vidljivo-samo-zaposlenicima'
                                    )}
                                    readOnly={!canEditAppointment}
                                    name="internalNote"
                                    rows={2}
                                  />
                                </StyledTextArea>
                                <div className="flex flex-col lg:flex-row">
                                  {shouldShowSendNotificationEmailCheckBox(
                                    values
                                  ) ? (
                                    <StyledFieldRow style={{ flex: 1 }}>
                                      <StyledFieldColumn>
                                        <FormField.Checkbox
                                          label={t(
                                            'translation.UpdateStatusModal.sendClientEmail'
                                          )}
                                          name="sendAppointmentEmail"
                                          readOnly={!canEditAppointment}
                                          type={'checkbox'}
                                          helpText={t(
                                            'translation.UpdateStatusModal.sendEmailHintNew'
                                          )}
                                        />
                                      </StyledFieldColumn>
                                    </StyledFieldRow>
                                  ) : null}

                                  {shouldShowSendNotificationSmsCheckBox(
                                    values
                                  ) && !smsBalanceLoading ? (
                                    <StyledFieldRow style={{ flex: 1 }}>
                                      <StyledFieldColumn>
                                        {smsBalance > 0 ? (
                                          <FormField.Checkbox
                                            label={t(
                                              'translation.UpdateStatusModal.sendClientSms'
                                            )}
                                            name="sendAppointmentSms"
                                            disabled={smsBalance <= 0}
                                            type={'checkbox'}
                                            helpText={t(
                                              'translation.UpdateStatusModal.sendSmsHintNew'
                                            )}
                                          />
                                        ) : (
                                          <ToolTip
                                            label={t(
                                              'translation.Appointment.no-sms-available'
                                            )}
                                          >
                                            <FormField.Checkbox
                                              label={t(
                                                'translation.UpdateStatusModal.sentClientSms'
                                              )}
                                              name="sendAppointmentSms"
                                              disabled={smsBalance <= 0}
                                              type={'checkbox'}
                                              helpText={t(
                                                'translation.UpdateStatusModal.sendSmsHintNew'
                                              )}
                                            />
                                          </ToolTip>
                                        )}
                                      </StyledFieldColumn>
                                    </StyledFieldRow>
                                  ) : null}
                                </div>
                                {!values?.sendPaymentRequest &&
                                shouldShowSendNotificationEmailCheckBox(
                                  values
                                ) &&
                                values?.sendAppointmentEmail ? (
                                  <StyledTextArea
                                    style={{
                                      marginTop: !isMobile ? 10 : 0,
                                      paddingTop: !isMobile ? 10 : 5,
                                    }}
                                  >
                                    <FormField.TextArea
                                      label={t(
                                        'translation.AppointmentModal.messageToClient'
                                      )}
                                      placeholder={t(
                                        'translation.Appointment.vidljivo-klijentima-upis'
                                      )}
                                      name="messageToClient"
                                      readOnly={!canEditAppointment}
                                      rows={2}
                                    />
                                  </StyledTextArea>
                                ) : null}

                                {values.clientNote ? (
                                  <div className="p-3 bg-zoyya-grayLighter rounded-md mt-3">
                                    <label className="font-medium">
                                      {t(
                                        'translation.AppointmentModal.client-note'
                                      )}
                                    </label>
                                    <div>{values.clientNote}</div>
                                  </div>
                                ) : null}
                              </div>
                            </Tab>
                            {values.client && (
                              <Tab
                                hasFooter={false}
                                title={
                                  isMobile
                                    ? t(
                                        'translation.AppointmentModal.label-client'
                                      )
                                    : t(
                                        'translation.AppointmentModal.clientDetails'
                                      )
                                }
                              >
                                <ClientDetails
                                  initialValues={values}
                                  setActiveForm={setActiveForm}
                                  isNew={isNewClient}
                                  onEdit={getOnEditClient(form, values)}
                                  isHotel
                                />
                              </Tab>
                            )}
                            {/* {values.client && values?.id && (
                              <Tab
                                title={t('translation.Appointment.privitci')}
                                hasFooter={false}
                              >
                                <AppointmentAttachments
                                  initialValues={initialValues}
                                  isLoading={false}
                                  values={values}
                                  //booking={booking}
                                  appointment={{}}
                                  setFieldValue={setFieldValue}
                                  //refetchAppointment={refetchAppointment}
                                  closeModal={onClose}
                                  onSaveForm={() => {
                                    activeForm === 'appointment' &&
                                    Object.values(formRef.current.errors).length
                                      ? setSelectedTabIndex(0)
                                      : formRef.current.submitForm()
                                  }}
                                />
                              </Tab>
                            )} */}
                          </TabView>
                        </>
                      ) : activeForm === 'client' ? (
                        <ClientFormWrapper
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          loadingAddClient={loadingAddClient}
                          isNew={isNewClient}
                          setIsNewClient={setIsNewClient}
                          setActiveForm={setActiveForm}
                          onGoBack={() => setActiveForm('appointment')}
                          title={`${values?.client?.firstName} ${values.client?.lastName}`}
                          clientDetails={true}
                          isAppointment
                        />
                      ) : activeForm === 'paymentNotification' ? (
                        <PaymentNotification
                          flattenedServices={flattenedServices}
                          complexBooking
                          totalServicesPrice={newTotalPrice}
                          location={location}
                          values={values}
                          isBusy={isBusy}
                          org={org}
                          hasPrepaymentsCredentials={hasPrepaymentsCredentials}
                          closeModal={onClose}
                          stripeAccountInfo={
                            stripeConnectAccountData?.prepayments
                              ?.getStripeConnectAccountInfo
                          }
                          hasInvoicingData={hasInvoicingData}
                        />
                      ) : null
                    }}
                  </Form>
                </FormWrapper>
                <AppointmentHotelFooter
                  onSaveClick={() => {
                    formRef.current?.submitForm()
                  }}
                  saveButtonLabel={getSaveButtonLabel()}
                  onCloseClick={() => {
                    if (activeForm === 'client') {
                      setActiveForm('appointment')
                    } else if (activeForm === 'paymentNotification') {
                      setActiveForm('appointment')
                    } else if (activeForm === 'appointment') {
                      onClose()
                    }
                  }}
                />
              </>
            )}
          </ModalWrapper>
        </Modal>
      </Container>
    </div>
  )
}
