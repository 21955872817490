import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useParams } from 'react-router'
import { getISODate, useOnClickOutside } from 'src/helpers'
import { MobileContext } from 'src/Context'
import { getCurrentLanguage } from 'src/localization'
import { device } from '../../../constants'
import { DatePickerCalendar } from '../../../mobile/Calendar/components/DatePicker/DatePickerCalendar'
import ReactDOM from 'react-dom'
import {
  DatePickerCalendarContainerMobile,
  DatePickerOverlay,
} from '../../../mobile/Calendar/components/DatePicker/styles'

type TDatePickerProps = {
  setDate?: (date: any) => any
  isAbsolute?: boolean
  isHeaderPicker?: boolean
  inputComponent?: any
  selectedDayId?: any
  goToCalendar?: any
  isDisabled?: boolean
  showHeader?: boolean
  view?: string
  dontGoToCalendarAfterMonthChange?: boolean
}
export const DatePicker = (props: TDatePickerProps) => {
  const {
    goToCalendar,
    setDate,
    isAbsolute,
    isHeaderPicker,
    inputComponent,
    selectedDayId,
    isDisabled,
    showHeader,
    view,
    dontGoToCalendarAfterMonthChange,
  } = props
  const [showCalendar, setShowCalendar] = useState<boolean>()
  let { dayId } = useParams<any>()
  const [currentDate, setCurrentDate] = useState()

  useEffect(() => setCurrentDate(selectedDayId || dayId), [
    selectedDayId,
    dayId,
  ])

  const isYearSelectedRef = useRef(false)

  const isMobile = useContext(MobileContext)

  moment.locale(getCurrentLanguage())

  const containerRef = useRef()
  useOnClickOutside(containerRef, () => {
    if (!isMobile) {
      setShowCalendar(false)
    }
  })

  const onDateSelect = date => {
    if (view === 'day') {
      setCurrentDate(date)
      setShowCalendar(false)
      setDate
        ? setDate(getISODate(date))
        : goToCalendar(getISODate(date), false)
    } else {
      //if year is picked first datepicker closes on year select so to avoid that behaviour we close datepicker on second select(day)
      if (!isYearSelectedRef.current) {
        isYearSelectedRef.current = true
        return
      }

      setCurrentDate(date)
      setDate?.(getISODate(date))

      if (isYearSelectedRef.current) {
        setShowCalendar(false)
        isYearSelectedRef.current = false
      }
    }
  }

  const CalendarPicker = (
    <DatePickerCalendarContainer
      isAbsolute={isAbsolute}
      isHeaderPicker={isHeaderPicker}
      style={{
        marginTop: 0,
      }}
    >
      <DatePickerLeft>
        <DatePickerCalendar
          view={view}
          setDate={onDateSelect}
          selectedDayId={currentDate}
          wrapperRef={{}}
          goToCalendar={date => {
            onDateSelect(date)
          }}
          dontGoToCalendarAfterMonthChange={dontGoToCalendarAfterMonthChange}
        />
      </DatePickerLeft>
    </DatePickerCalendarContainer>
  )

  // close options drawer when clicked outside of it
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCalendar(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div ref={containerRef as any} style={{ width: '100%' }}>
      <div onClick={() => !isDisabled && setShowCalendar(true)}>
        {' '}
        {inputComponent}
      </div>

      {showCalendar &&
        (isMobile
          ? // <MobileCalendar
            //   component={CalendarPicker}
            //   onClose={() => setShowCalendar(false)}
            //   inputComponent={inputComponent}
            // />
            ReactDOM.createPortal(
              <MenuOverlay>
                <MenuOptionsContainer ref={wrapperRef}>
                  <DatePickerCalendar
                    view={view}
                    setDate={onDateSelect}
                    wrapperRef={{}}
                    selectedDayId={currentDate}
                    goToCalendar={() => true}
                    dontGoToCalendarAfterMonthChange={
                      dontGoToCalendarAfterMonthChange
                    }
                  />
                </MenuOptionsContainer>
              </MenuOverlay>,
              document.getElementById(
                'react-select-portal-target'
              ) as HTMLDivElement
            )
          : CalendarPicker)}
    </div>
  )
}

// ovo je u fileu views/calendar/components/datepickers/styles ali treba prebacit u typescript
type TDatePickerCCProps = {
  isAbsolute?: boolean
  isHeaderPicker?: boolean
}
export const DatePickerCalendarContainer = styled.div<TDatePickerCCProps>`
  margin: 10px 0px;
  overflow: hidden;
  display: flex;
  top: ${props => (props.isHeaderPicker ? '40px' : 'initial')};
  position: ${props => (props.isAbsolute ? 'absolute' : 'initial')};
  background: ${props => (props.isAbsolute ? 'white' : 'transparent')};
  box-shadow: ${props =>
    props.isAbsolute ? '0px 0px 10px 0px #8080803b' : 'none'};
  border-radius: 0px;
  z-index: 99999;
  width: 370px;
  height: 455px;

  ${DatePickerOverlay} {
    top: 0px;
    background: transparent;
  }
  @media ${device.tablet} {
    overflow: auto;
    margin-top: 0px;
    max-height: 100vh;
    height: 100vh;

    .Cal__MonthList__root {
      height: 100% !important;
      > div {
        height: 100vh !important;
      }
    }
    .Cal__Header__root {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
`

export const DatePickerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const MenuOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`

type TMenuOptionsContainer = {
  isCalendarMenu?: boolean
}
const MenuOptionsContainer = styled.div<TMenuOptionsContainer>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 100%;

  height: calc((var(--vh, 1vh) * 85));
  border-radius: 30px 30px 0px 0px;
  position: absolute;
  bottom: -400px;
  background-color: ${props => props.theme.colors.light};
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 30px 0px 0px 0px;
  animation: menu 0.5s linear;
  animation-fill-mode: forwards;
  @keyframes menu {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-400px);
    }
  }

  @media ${device.tablet} {
    ${DatePickerCalendarContainerMobile} {
      box-shadow: none;
      height: 100%;
      background: transparent;
    }

    ${DatePickerOverlay} {
      top: 0px;
      background: transparent;
    }
  }
`
