import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  usePosActiveJournalGetQuery,
  usePosReceiptByJournalGetQuery,
  usePosJournalOpenMutation,
  usePosOperatorsAllQuery,
} from 'state/graphql'
import moment from 'moment'
import { EmptyList } from 'components/EmptyList/EmptyList'
import { Loader, Button } from 'components'
import { SettingsHeader, TableItem, ListBodyWrapper } from 'src/styles'
import {
  CardFooter,
  PlanContainer,
  PlanHeader,
  PlanIcon,
  PlanWrapper,
} from 'views/Settings/Billing/style'
import { icons } from 'components/Icon'
import { ThemeContext } from 'styled-components'
import ReactTable from 'components/Table/Table'
import { Tooltip2 } from 'components/Tooltip2/Tooltip2'
import {
  getLocationId,
  getOrgId,
  getISODate,
  formatPriceNumberWithDelimiter,
  getReceiptStatusLabel,
  getPaymentMethod,
} from 'src/helpers'
import ReceiptDetailsModal, {
  TDeviceStore,
  TReceipt,
} from 'views/CashRegister/ReceiptDetailsModal'
import { useAppServices } from 'sdk/appServices'
import { NewReceipt } from './NewReceipt/NewReceipt'
import { useTableHeightCalculator } from 'components/Table/useTableHeightCalculator'
import {
  PosReceiptStore,
  printJournalToPosPrinter,
  usePosStore,
} from 'state/local/posReceiptStore'
import { useParams } from 'react-router-dom'
import { WarningTriangle } from 'components/Icon/Icons/WarningTriangle'
import {
  ClientWarningRow,
  ClientWarningText,
} from 'views/Appointment/Appointment'
import JournalModal, { TOperator } from './JournalModal'
import { withErrorHandler } from 'sdk/widthErrorHandler'
import { assert } from 'sdk/assert'
import Tooltip from 'src/components/Tooltip/Tooltip'
import { TJournal } from '../Reports/CashRegister'
import { useACL } from 'src/sdk/acl'
import { useFeatureFlags } from 'src/state/local/appStore'

const PosScreen = () => {
  const { t } = useTranslation()
  const selectedTheme = useContext(ThemeContext)
  const appServices = useAppServices()
  const { resetReceipt, setActiveJournal } = PosReceiptStore
  const { locationId, orgId } = useParams<any>()
  const [openNewReceiptModal, setOpenNewReceiptModal] = useState<boolean>(false)
  const [selectedReceipt, setSelectedReceipt] = useState<TReceipt | null>(null)
  const { tableContainerRef, tableHeight } = useTableHeightCalculator(400)
  const selectedPosDevice = usePosStore(
    store => store.pos.selectedPosDevice
  ) as TDeviceStore

  const { hasPermission } = useACL()
  const { data: operatorsData, loading: operatorsLoading } =
    usePosOperatorsAllQuery()
  const operators = operatorsData?.posOperators_all as TOperator[] | []

  const {
    data: journalData,
    loading: journalLoading,
    error: journalError,
    refetch: refetchActiveJournal,
  } = usePosActiveJournalGetQuery({
    variables: {
      input: {
        fisLocationId: selectedPosDevice?.fisLocationId as any,
        fisDeviceId: selectedPosDevice?.fisDeviceId as any,
      },
    },
    skip:
      !selectedPosDevice?.fisLocationId ||
      (locationId !== selectedPosDevice?.locationId &&
        orgId !== selectedPosDevice?.orgId),
  })
  const journal = journalData?.posActiveJournal_get as TJournal

  const [journalOpenMutation, { loading: journalOpenLoading }] =
    usePosJournalOpenMutation()

  const [closeJournal, setCloseJournal] = useState<boolean | TJournal | null>(
    false
  )
  // const handleCloseJournal = async () => {
  //   const result = await journalCloseMutation({
  //     variables: {
  //       input: {
  //         posJournalId: journal?.id,
  //       },
  //     },
  //     refetchQueries: [
  //       {
  //         query: PosActiveJournalGetDocument,
  //         variables: {
  //           input: {
  //             fisLocationId: selectedPosDevice?.fisLocationId as any,
  //             fisDeviceId: selectedPosDevice?.fisDeviceId as any,
  //           },
  //         },
  //       },
  //     ],
  //   })

  //   if (!result.errors) {
  //     appServices.toast.success(
  //       t('translation.JournalModal.label-journal-successfully-concluded')
  //     )
  //     refetchActiveJournal({
  //       input: {
  //         fisLocationId: selectedPosDevice!.fisLocationId as any,
  //         fisDeviceId: selectedPosDevice!.fisDeviceId as any,
  //       },
  //     })
  //     setActiveJournal(null as any)
  //   } else appServices.toast.danger(result?.errors?.[0]?.message)
  // }

  const handleOpenJournal = async () => {
    const result = await journalOpenMutation({
      variables: {
        input: {
          fisLocationId: selectedPosDevice!.fisLocationId as any,
          fisDeviceId: selectedPosDevice!.fisDeviceId as any,
        },
      },
    })
    if (!result.errors) {
      appServices.toast.success(
        t('translation.JournalModal.label-journal-successfully-opened')
      )
      const journalResult = await refetchActiveJournal({
        input: {
          fisLocationId: selectedPosDevice!.fisLocationId as any,
          fisDeviceId: selectedPosDevice!.fisDeviceId as any,
        },
      })

      setActiveJournal(journalResult.data.posActiveJournal_get as any)
    } else appServices.toast.danger(result?.errors?.[0]?.message)
  }

  const {
    data: receiptListData,
    loading: receiptListLoading,
    error: receiptListError,
  } = usePosReceiptByJournalGetQuery({
    variables: {
      input: {
        posJournalId: journal?.id,
      },
    },
    skip: !journal?.id,
  })
  const activeJournalReceipts =
    (receiptListData?.posReceiptByJournal_get as TReceipt[]) || []

  const featuresFlags = useFeatureFlags()
  const printClient = featuresFlags.printClient

  const handlePrintToPosPrinter = withErrorHandler(async () => {
    assert(journal, 'Journal is not defined')
    assert(selectedPosDevice, 'No device selected')

    await printJournalToPosPrinter(journal.id, printClient)
  })

  let cashAmount = 0
  let cardAmount = 0
  let transactionAmount = 0

  for (let i = 0; i < activeJournalReceipts?.length; i++) {
    activeJournalReceipts?.[i]?.paymentMethod === 'CASH'
      ? (cashAmount += activeJournalReceipts?.[i]?.totalAmount || 0)
      : activeJournalReceipts?.[i]?.paymentMethod === 'CARD'
      ? (cardAmount += activeJournalReceipts?.[i]?.totalAmount || 0)
      : (transactionAmount += activeJournalReceipts?.[i]?.totalAmount || 0)
  }

  const tipCashAmount =
    activeJournalReceipts
      ?.filter(x => x.tipPaymentMethod === 'CASH')
      ?.reduce((acc, curr) => {
        return acc + (curr?.tipAmount || 0)
      }, 0) || 0

  const tipCardAmount =
    activeJournalReceipts
      ?.filter(x => x.tipPaymentMethod === 'CARD')
      ?.reduce((acc, curr) => {
        return acc + (curr?.tipAmount || 0)
      }, 0) || 0

  const tipOtherAmount =
    activeJournalReceipts
      ?.filter(
        x => x.tipPaymentMethod !== 'CASH' && x.tipPaymentMethod !== 'CARD'
      )
      ?.reduce((acc, curr) => {
        return acc + (curr?.tipAmount || 0)
      }, 0) || 0

  const newReceiptButtonDisabled =
    journal?.status === 'CLOSED' ||
    !moment(getISODate(journal?.date)).isSame(getISODate(new Date()))

  const columns = [
    {
      Header: '',
      accessor: 'receiptWarningSign',
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      Cell: props => {
        return (
          <TableItem style={{ maxWidth: 50, margin: 0 }}>
            {!props.cell.row.original?.fisJIR ? (
              <Tooltip key={'tooltip'} label={'Račun nije fiskaliziran!'}>
                <icons.WarningTriangle size={'small'} color="orange" />
              </Tooltip>
            ) : props.cell.row.original?.tipAmount &&
              !props.cell.row.original?.tipFiscalized ? (
              <Tooltip key={'tooltip'} label={'Napojnica nije fiskalizirana!'}>
                <WarningTriangle size={'small'} color="orange" />
              </Tooltip>
            ) : null}
          </TableItem>
        )
      },
    },
    {
      Header: t('translation.PosScreen.label-receiptNo'),
      accessor: 'receiptNo',
      minWidth: 130,
      width: 130,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.receiptNo}</span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.PosScreen.label-amount'),
      accessor: 'amount',
      minWidth: 130,
      width: 130,
      Cell: props => (
        <TableItem style={{ justifyContent: 'end' }}>
          <span>
            {`${formatPriceNumberWithDelimiter(
              props.cell.row.original?.totalAmount
            )} ${props.cell.row.original?.currencyId}`}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.PosScreen.label-payment-method'),
      accessor: 'paymentMethod',
      minWidth: 150,
      width: 150,
      Cell: props => (
        <TableItem>
          <span>
            {getReceiptStatusLabel(props.cell.row.original?.paymentMethod)}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.PosScreen.label-creation-date'),
      accessor: 'creationDate',
      minWidth: 200,
      width: 200,
      Cell: props => (
        <TableItem>
          <span>
            {moment(props.cell.row.original?.createdAt).format(
              t('regionalFormats.date.momentFormatDateTime')
            )}
          </span>
        </TableItem>
      ),
    },
    {
      Header: t('translation.PosScreen.label-client'),
      accessor: 'client',
      minWidth: 200,
      width: 200,
      Cell: props => (
        <TableItem>
          <span>{props.cell.row.original?.clientName}</span>
        </TableItem>
      ),
    },
    {
      Header: 'Napojnica',
      accessor: 'tipAmount',
      minWidth: 125,
      width: 125,
      Cell: props => {
        const paymentMethod = getPaymentMethod(
          props.cell.row.original?.tipPaymentMethod
        )

        if (!paymentMethod) {
          return (
            <TableItem style={{ justifyContent: 'end' }}>
              <span></span>
            </TableItem>
          )
        }

        return (
          <TableItem style={{ justifyContent: 'end' }}>
            <span>
              {props.cell.row.original?.tipAmount ? (
                <div className="flex items-center">
                  <div>
                    {`${formatPriceNumberWithDelimiter(
                      props.cell.row.original?.tipAmount
                    )} ${props.cell.row.original?.currencyId}`}
                  </div>

                  <div className="ml-3">
                    {<paymentMethod.icon size="smaller" />}
                  </div>
                </div>
              ) : (
                ''
              )}
            </span>
          </TableItem>
        )
      },
    },
    {
      Header: 'Tip',
      accessor: 'receiptType',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          {props.cell.row.original?.receiptType === 'CANCELLATION'
            ? 'STORNO'
            : 'RAČUN'}
        </TableItem>
      ),
    },
    {
      Header: t('translation.ReceiptListScreen.label-status'),
      accessor: 'status',
      minWidth: 100,
      width: 100,
      Cell: props => (
        <TableItem>
          <span
            className={`pl-[14px] font-medium ${
              props.cell.row.original?.status === 'CANCELLED'
                ? 'text-zoyya-accent1'
                : 'text-zoyya-accent2'
            }`}
          >
            {props.cell.row.original?.status === 'CANCELLED'
              ? t('translation.ReceiptListScreen.label-status-cancelled')
              : t('translation.ReceiptListScreen.label-status-issued')}
          </span>
        </TableItem>
      ),
    },
  ]

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        openNewReceiptModal ? null : setSelectedReceipt(null)
      }
      if (event.keyCode === 119) {
        setOpenNewReceiptModal(true)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [openNewReceiptModal, selectedReceipt])

  if (
    journalLoading ||
    receiptListLoading ||
    journalOpenLoading ||
    operatorsLoading
  )
    return <Loader isComponent={true} />

  return (
    <>
      <SettingsHeader>
        <span>
          {t('translation.PosScreen.label-cash-register')}
          {journal?.journalNumber ? (
            <span style={{ fontSize: '18px', fontWeight: 500 }}>
              {' - ' + journal?.journalNumber}
            </span>
          ) : null}
        </span>

        {selectedPosDevice?.fisLocationId &&
        selectedPosDevice?.fisDeviceId &&
        locationId === selectedPosDevice?.locationId &&
        orgId === selectedPosDevice?.orgId &&
        journal ? (
          <div className="flex gap-2 mx-2.5 mb-1 font-normal">
            <Button
              type="button"
              buttonType={'primary'}
              onClick={() => setCloseJournal(true)}
              size={'medium'}
              label={t('translation.PosScreen.label-conclude-journal')}
              iconComponent={<icons.Lock size={'smaller'} />}
              name={'publishBooking'}
            />
            <Button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                handlePrintToPosPrinter()
              }}
              buttonType={'secondaryLink'}
              size={'medium'}
              label={t('translation.PosScreen.label-print-balance')}
              iconComponent={<icons.Logout size={'smaller'} />}
              name={'visitBooking'}
            />
          </div>
        ) : null}
      </SettingsHeader>

      {selectedPosDevice?.fisLocationId &&
      selectedPosDevice?.fisDeviceId &&
      locationId === selectedPosDevice?.locationId &&
      orgId === selectedPosDevice?.orgId ? (
        <>
          {!journal && !journalOpenLoading ? (
            <EmptyList
              title={t('translation.PosScreen.label-no-active-journal')}
              buttonCallback={() => handleOpenJournal()}
              buttonLabel={t('translation.PosScreen.label-open-journal')}
              showButton
            />
          ) : (
            <div
              ref={tableContainerRef}
              style={{
                padding: '20px',
                display: 'flex',
                maxWidth: '100%',
                height: '100%',
              }}
            >
              <div style={{ width: '100%' }}>
                <div className="flex">
                  <PlanContainer
                    style={{ minWidth: '400px', marginRight: '10px' }}
                  >
                    <PlanWrapper>
                      <PlanHeader
                        style={{
                          marginBottom: '20px',
                          color: selectedTheme.colors.text,
                          fontWeight: 600,
                        }}
                      >
                        {`${t(
                          'translation.PosScreen.label-opening-time'
                        )} ${moment(journal?.createdAt).format(
                          t('regionalFormats.date.momentFormatDateTime')
                        )}`}
                        <PlanIcon>
                          <icons.CashRegister />
                        </PlanIcon>
                      </PlanHeader>

                      <PlanHeader style={{ marginBottom: '0' }}>
                        <p>{t('translation.PosScreen.label-cash')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            cashAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                      <PlanHeader style={{ marginBottom: '0' }}>
                        <p>{t('translation.PosScreen.label-credit-cards')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            cardAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                      <PlanHeader>
                        <p>
                          {t(
                            'translation.PosScreen.label-transaction-receipts'
                          )}
                        </p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            transactionAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                    </PlanWrapper>
                    <CardFooter style={{ padding: '10px 25px' }}>
                      <PlanHeader
                        style={{
                          marginBottom: '0',
                          width: '100%',
                        }}
                      >
                        <p>{t('translation.PosScreen.label-total')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            cashAmount + cardAmount + transactionAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                    </CardFooter>
                  </PlanContainer>

                  {/* TIP INFO */}
                  <PlanContainer
                    style={{
                      minWidth: 400,
                    }}
                  >
                    <PlanWrapper>
                      <PlanHeader
                        style={{
                          marginBottom: '20px',
                          color: selectedTheme.colors.text,
                          fontWeight: 600,
                        }}
                      >
                        {t('translation.PosScreen.label-tip-recapitulation')}
                        <PlanIcon>
                          <icons.Coins />
                        </PlanIcon>
                      </PlanHeader>

                      <PlanHeader style={{ marginBottom: '0' }}>
                        <p>{t('translation.PosScreen.label-cash')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            tipCashAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                      <PlanHeader style={{ marginBottom: '0' }}>
                        <p>{t('translation.PosScreen.label-credit-cards')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            tipCardAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                      <PlanHeader>
                        <p>
                          {t(
                            'translation.PosScreen.label-transaction-receipts'
                          )}
                        </p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            tipOtherAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                    </PlanWrapper>
                    <CardFooter style={{ padding: '10px 25px' }}>
                      <PlanHeader
                        style={{
                          marginBottom: '0',
                          width: '100%',
                        }}
                      >
                        <p>{t('translation.PosScreen.label-total')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            tipCashAmount + tipCardAmount + tipOtherAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                    </CardFooter>
                  </PlanContainer>

                  {/* TOTAL CASH INFO */}
                  {/*  <PlanContainer
                    style={{
                      minWidth: 335,
                      width: 335,
                      maxWidth: 335,
                      marginRight: '0',
                    }}
                  >
                    <PlanWrapper>
                      <PlanHeader
                        style={{
                          marginBottom: 20,
                          color: selectedTheme.colors.text,
                          fontWeight: 600,
                        }}
                      >
                        {t('translation.PosScreen.label-cash-recapitulation')}
                        <PlanIcon>
                          <icons.Coins />
                        </PlanIcon>
                      </PlanHeader>

                      <PlanHeader style={{ marginBottom: 0 }}>
                        <p>{'Gotovinski utržak:'}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            cashAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                      <PlanHeader style={{ marginBottom: '0' }}>
                        <p>{'Napojnice:'}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            tipCashAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                      <PlanHeader style={{ marginBottom: '0' }}>
                        <p>{'Polog:'}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            journal?.cashDepositAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                    </PlanWrapper>
                    <CardFooter style={{ padding: '10px 25px' }}>
                      <PlanHeader
                        style={{
                          marginBottom: 0,
                          width: '100%',
                        }}
                      >
                        <p>{t('translation.PosScreen.label-total')}</p>
                        <p
                          style={{
                            color: selectedTheme.colors.text,
                            fontWeight: 600,
                          }}
                        >
                          {`${formatPriceNumberWithDelimiter(
                            tipCashAmount +
                              cashAmount +
                              journal?.cashDepositAmount
                          )} ${journal?.currencyId}`}
                        </p>
                      </PlanHeader>
                    </CardFooter>
                  </PlanContainer> */}
                </div>

                {newReceiptButtonDisabled ? (
                  <ClientWarningRow style={{ marginBottom: 20, marginTop: 20 }}>
                    <ClientWarningText
                      style={{
                        alignItems: 'end',
                        marginBottom: 0,
                      }}
                    >
                      <WarningTriangle
                        color={'orange'}
                        size="extraSmall"
                        style={{ height: 25, width: 25, marginRight: 13 }}
                      />
                      <p>{'Zaključite blagajnu prije nastavka rada'}</p>
                    </ClientWarningText>
                  </ClientWarningRow>
                ) : null}
                <Button
                  type="button"
                  buttonType={'primary'}
                  onClick={
                    !newReceiptButtonDisabled
                      ? () => {
                          setSelectedReceipt(null)
                          resetReceipt()
                          setOpenNewReceiptModal(true)
                        }
                      : () => {}
                  }
                  size={'medium'}
                  label={t('translation.PosScreen.label-new-receipt')}
                  iconComponent={<icons.Add size="smaller" />}
                  name={'publishBooking'}
                  disabled={newReceiptButtonDisabled}
                  style={{ margin: '15px 0 11px' }}
                />

                <ListBodyWrapper id={'list-body-wrapper'}>
                  <ReactTable
                    tableData={activeJournalReceipts}
                    columns={columns}
                    onRowClick={item => setSelectedReceipt(item)}
                    tableHeight={tableHeight}
                    innerWidth={900}
                    isSelected={selectedReceipt}
                  />
                </ListBodyWrapper>
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ padding: '20px' }}>
          <p style={{ marginBottom: '20px' }}>
            {t('translation.PosScreen.label-settings-warning')}
          </p>

          <div className="mb-[10px] flex items-center">
            <span className="text-[16px] mr-[10px]">
              {selectedPosDevice?.fisDeviceId &&
              locationId === selectedPosDevice?.locationId &&
              orgId === selectedPosDevice?.orgId ? (
                <icons.CheckBox
                  size="small"
                  color="green"
                  data-cy={'isValid-employeeSchedule'}
                />
              ) : (
                <icons.Error
                  size="small"
                  color="red"
                  data-cy={'isError-employeeSchedule'}
                />
              )}
            </span>
            <span className="text-[14px] text-zoyya-secondaryDarker">
              {t('translation.PosScreen.label-posDevice-setup')}
            </span>

            {hasPermission('ACCESS_CASH_REGISTER') ? (
              <Tooltip2
                helpText={t('translation.PosScreen.tooltip-set-posDevice')}
                isSameTab
                useHistoryRouting
                onClick={() => {}}
                helpLink={`/${getOrgId()}/${getLocationId()}/cashRegister/settings/devices`}
              >
                <div className="flex items-center text-zoyya-secondaryDark ml-3">
                  <icons.Info size={'smaller'} />
                </div>
              </Tooltip2>
            ) : null}
          </div>
          <div className="mb-[10px] flex items-center">
            <span className="text-[16px] mr-[10px]">
              {operators?.length ? (
                <icons.CheckBox
                  size="small"
                  color="green"
                  data-cy={'isValid-employeeSchedule'}
                />
              ) : (
                <icons.Error
                  size="small"
                  color="red"
                  data-cy={'isError-employeeSchedule'}
                />
              )}
            </span>
            <span className="text-[14px] text-zoyya-secondaryDarker">
              {t('translation.PosScreen.label-posOperator-setup')}
            </span>

            {hasPermission('ACCESS_CASH_REGISTER') ? (
              <Tooltip2
                helpText={t('translation.PosScreen.tooltip-set-posOperator')}
                isSameTab
                onClick={() => {}}
                useHistoryRouting
                helpLink={`/${getOrgId()}/${getLocationId()}/cashRegister/settings/operators`}
              >
                <div className="ml-3 flex items-center text-zoyya-secondaryDark">
                  <icons.Info size={'smaller'} />
                </div>
              </Tooltip2>
            ) : null}
          </div>
        </div>
      )}
      {openNewReceiptModal ? (
        <aside
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            minWidth: 200,
            background: 'white',
            zIndex: 12200,
          }}
        >
          <NewReceipt
            selectedReceipt={selectedReceipt}
            setSelectedReceipt={setSelectedReceipt}
            setOpenNewReceiptModal={setOpenNewReceiptModal}
          />
        </aside>
      ) : null}
      {!!selectedReceipt && !openNewReceiptModal ? (
        <aside
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            minWidth: 200,
            background: 'white',
            zIndex: 12200,
          }}
        >
          <ReceiptDetailsModal
            selectedReceipt={selectedReceipt}
            setSelectedReceipt={setSelectedReceipt}
            setOpenNewReceiptModal={setOpenNewReceiptModal}
          />
        </aside>
      ) : null}
      {closeJournal ? (
        <aside
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            minWidth: 200,
            background: 'white',
            zIndex: 12200,
          }}
        >
          <JournalModal
            selectedJournal={journal}
            setSelectedJournal={setCloseJournal}
            refetch={refetchActiveJournal}
          />
        </aside>
      ) : null}
    </>
  )
}

export default PosScreen
