import React, { useState, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppServices } from 'sdk/appServices'
import {
  Container,
  Modal,
  ModalWrapper,
  FormWrapper,
  StyledFieldRow,
  StyledFieldColumn,
} from 'views/Appointment/Appointment'
import { Button, FormFooterContainer, Loader, Form } from 'components'
import { Tab, TabView } from 'components/TabView/TabView'
import { HeaderTitle } from 'views/Appointment/components/Header'
import moment from 'moment'
import { Row } from 'src/styles'
import { icons } from 'components/Icon'
import {
  usePosOperatorsAllQuery,
  usePosReceiptByJournalGetQuery,
} from 'state/graphql'
import { usePosJournalCloseMutation } from 'state/graphql'
import { formatPriceNumberWithDelimiter, getFullReportSrc } from 'src/helpers'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { groupBy } from 'lodash'
import { withErrorHandler } from 'sdk/widthErrorHandler'
import { assert } from 'sdk/assert'
import {
  PosReceiptStore,
  printJournalToPosPrinter,
  usePosStore,
} from 'state/local/posReceiptStore'
import { TJournal } from '../Reports/CashRegister'
import { ServiceRowWrapper } from './PosSettingsDevices'
import { TDeviceStore, TReceipt } from './ReceiptDetailsModal'
import { MobileContext } from 'src/Context'
import { useFeatureFlags } from 'src/state/local/appStore'

type TJournalModalProps = {
  selectedJournal: TJournal
  setSelectedJournal: (arg0: TJournal | null) => void
  refetch: any
}

export type TOperator = {
  cid: string
  firstName: string
  id: string
  isActive: boolean
  lastName: string | null
  locationId: string
  oib: string
  orgId: string
  code?: string
}

const JournalModal = (props: TJournalModalProps) => {
  const { selectedJournal, setSelectedJournal, refetch } = props
  const { t } = useTranslation()
  const appServices = useAppServices()
  const selectedDevice = usePosStore(
    state => state.pos.selectedPosDevice
  ) as TDeviceStore
  const { setActiveJournal } = PosReceiptStore

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [printJournal, setPrintJournal] = useState<boolean>(true)
  const formRef = useRef<any>()
  const [journalCloseMutation, { loading }] = usePosJournalCloseMutation()
  const isMobile = useContext(MobileContext)

  const featuresFlags = useFeatureFlags()
  const printClient = featuresFlags.printClient

  const handlePrintToPosPrinter = withErrorHandler(async () => {
    assert(selectedDevice, 'No device selected')

    await printJournalToPosPrinter(selectedJournal.id, printClient)
  })

  const handleSubmit = async () => {
    const result = await journalCloseMutation({
      variables: {
        input: {
          posJournalId: selectedJournal?.id,
        },
      },
    })

    if (!result.errors) {
      if (printJournal)
        await printJournalToPosPrinter(selectedJournal.id, printClient)

      appServices.toast.success(
        t('translation.JournalModal.label-journal-successfully-concluded')
      )
      refetch()
      setSelectedJournal(null)
      setActiveJournal(null as any)
      if (printJournal) {
        // TODO: print
      }
    } else appServices.toast.danger(result?.errors?.[0]?.message)
  }

  const {
    data: posOperators,
    loading: loadingPosOperators,
  } = usePosOperatorsAllQuery()
  const operators = posOperators?.posOperators_all as TOperator[] | []

  const {
    data: receiptListData,
    loading: receiptListLoading,
    error: receiptListError,
  } = usePosReceiptByJournalGetQuery({
    variables: {
      input: {
        posJournalId: selectedJournal?.id,
      },
    },
  })
  const allJournalReceipts =
    (receiptListData?.posReceiptByJournal_get as TReceipt[]) || []
  const canceledReceipts =
    (allJournalReceipts?.filter(
      x => x?.status === 'ISSUED' && x.receiptType === 'CANCELLATION'
    ) as TReceipt[]) || []

  const receiptsGroupedByOperatorIds = groupBy(allJournalReceipts, receipt => {
    return receipt?.issuedByOperatorId
  })

  const receiptsTotalsByOperators: {
    id: string
    operatorName: string
    totalAmount: number
  }[] = []

  for (let [operatorId, operatorReceipts] of Object.entries(
    receiptsGroupedByOperatorIds
  )) {
    receiptsTotalsByOperators.push({
      id: operatorId,
      operatorName:
        operatorReceipts?.find(x => !!x?.operatorName)?.operatorName ||
        operators?.find(x => x?.id === operatorId)?.firstName ||
        '',
      totalAmount: receiptsGroupedByOperatorIds[operatorId]?.reduce(
        (acc: number, curr: any): number => {
          return acc + curr?.totalAmount
        },
        0
      ),
    })
  }

  let cashAmount = 0
  let cardAmount = 0
  let transactionAmount = 0
  const currencyId = selectedJournal?.currencyId

  for (let i = 0; i < allJournalReceipts?.length; i++) {
    allJournalReceipts[i]?.paymentMethod === 'CASH'
      ? (cashAmount += allJournalReceipts[i]?.totalAmount || 0)
      : allJournalReceipts[i]?.paymentMethod === 'CARD'
      ? (cardAmount += allJournalReceipts[i]?.totalAmount || 0)
      : (transactionAmount += allJournalReceipts[i]?.totalAmount || 0)
  }

  return (
    <Container>
      <Modal>
        <ModalWrapper>
          <FormWrapper>
            {loading || receiptListLoading || loadingPosOperators ? (
              <div style={{ minHeight: '500px' }}>
                <Loader isComponent />
              </div>
            ) : (
              <Form
                initialValues={{}}
                isInitialValid={true}
                onSubmit={handleSubmit}
              >
                {form => {
                  formRef.current = form
                  return (
                    <>
                      <TabView
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        isSingleTab={false}
                        hasFooter={false}
                      >
                        <Tab
                          hasFooter={false}
                          title={t(
                            'translation.JournalModal.label-cashRegister-detail'
                          )}
                        >
                          <div className="p-[20px] flex flex-col h-[inherit]">
                            <div className="pb-[20px] flex justify-between border-b-[1px] border-zoyya-outline">
                              {/* {isBusy && <Loader isComponent />} */}
                              {/* @ts-ignore */}
                              <HeaderTitle isDisabled>
                                <span>
                                  {`${t('translation.JournalModal.label-no')} ${
                                    selectedJournal?.journalNumber
                                  }`}
                                </span>
                              </HeaderTitle>

                              <span
                                className={`text-[16px] font-normal ${
                                  selectedJournal?.status === 'ACTIVE'
                                    ? 'text-zoyya-accent2'
                                    : 'text-zoyya-accent1'
                                }`}
                              >
                                {selectedJournal?.status === 'ACTIVE'
                                  ? t(
                                      'translation.JournalModal.label-cashRegister-active'
                                    )
                                  : t(
                                      'translation.JournalModal.label-cashRegister-closed'
                                    )}
                              </span>
                            </div>

                            <StyledFieldRow
                              style={{
                                flexDirection: 'column',
                              }}
                            >
                              <StyledFieldColumn
                                style={{ alignItems: 'center' }}
                              >
                                <p style={{ marginRight: '10px' }}>
                                  {t('translation.JournalModal.label-date')}
                                </p>
                                <p className="font-semibold text-[22px]">
                                  {moment(selectedJournal?.date).format(
                                    t('regionalFormats.date.momentFormatDate')
                                  )}
                                </p>
                              </StyledFieldColumn>
                              <StyledFieldColumn>
                                <p style={{ marginRight: '10px' }}>
                                  {t('translation.JournalModal.label-time')}
                                </p>
                                <p className="font-semibold">
                                  {moment(selectedJournal?.createdAt).format(
                                    'HH:mm'
                                  )}
                                </p>
                              </StyledFieldColumn>
                              {selectedJournal?.status === 'CLOSED' ? (
                                <StyledFieldColumn
                                  style={{ marginTop: '10px' }}
                                >
                                  <p className="font-semibold mr-[10px]">
                                    {t(
                                      'translation.JournalModal.label-cashRegister-closed-time'
                                    )}
                                  </p>
                                  <p>
                                    {moment(selectedJournal?.closedAt).format(
                                      t(
                                        'regionalFormats.date.momentFormatDateTime'
                                      )
                                    )}
                                  </p>
                                </StyledFieldColumn>
                              ) : null}
                            </StyledFieldRow>

                            <ServiceRowWrapper>
                              <p className="font-semibold mb-[20px]">
                                {t(
                                  'translation.JournalModal.label-receipt-records'
                                )}
                              </p>
                              <Row>
                                <span>
                                  {t(
                                    'translation.JournalModal.label-receipt-issued-count'
                                  )}
                                </span>
                                <p className="font-semibold">
                                  {allJournalReceipts?.length}
                                </p>
                              </Row>
                              <Row>
                                <span>
                                  {t(
                                    'translation.JournalModal.label-receipt-canceled-count'
                                  )}
                                </span>
                                <p className="font-semibold">
                                  {canceledReceipts?.length}
                                </p>
                              </Row>
                            </ServiceRowWrapper>

                            <ServiceRowWrapper>
                              <p className="font-semibold mb-[20px]">
                                <span>
                                  {t(
                                    'translation.JournalModal.label-traffic-record-by-operator'
                                  )}
                                </span>
                              </p>

                              {!receiptsTotalsByOperators?.length ? (
                                <Row>
                                  <span>-</span>
                                </Row>
                              ) : (
                                receiptsTotalsByOperators?.map(operator => {
                                  return (
                                    <Row key={operator?.id}>
                                      <span>{operator?.operatorName}</span>
                                      <p className="font-semibold">
                                        {`${formatPriceNumberWithDelimiter(
                                          operator?.totalAmount
                                        )} ${currencyId}`}
                                      </p>
                                    </Row>
                                  )
                                })
                              )}

                              <div className="w-full flex items-center justify-between mt-[10px] pt-[10px] border-t-[1px] border-zoyya-outline">
                                <span>
                                  {t('translation.JournalModal.label-total')}
                                </span>
                                <p className="font-semibold">
                                  {`${formatPriceNumberWithDelimiter(
                                    cashAmount + cardAmount + transactionAmount
                                  )} ${currencyId}`}
                                </p>
                              </div>
                            </ServiceRowWrapper>

                            <ServiceRowWrapper>
                              <p className="font-semibold mb-[20px]">
                                {t(
                                  'translation.JournalModal.label-traffic-record-by-payment-method'
                                )}
                              </p>
                              <Row>
                                <span>
                                  {t('translation.JournalModal.label-cash')}
                                </span>
                                <p className="font-semibold">
                                  {`${formatPriceNumberWithDelimiter(
                                    cashAmount
                                  )} ${currencyId}`}
                                </p>
                              </Row>
                              <Row>
                                <span>
                                  {t('translation.JournalModal.label-card')}
                                </span>
                                <p className="font-semibold">
                                  {`${formatPriceNumberWithDelimiter(
                                    cardAmount
                                  )} ${currencyId}`}
                                </p>
                              </Row>
                              <Row>
                                <span>
                                  {t(
                                    'translation.JournalModal.label-transaction'
                                  )}
                                </span>
                                <p className="font-semibold">
                                  {`${formatPriceNumberWithDelimiter(
                                    transactionAmount
                                  )} ${currencyId}`}
                                </p>
                              </Row>

                              <div className="w-full flex items-center justify-between mt-[10px] pt-[10px] border-t-[1px] border-zoyya-outline">
                                <span>
                                  {t('translation.JournalModal.label-total')}
                                </span>
                                <p className="font-semibold">
                                  {`${formatPriceNumberWithDelimiter(
                                    cashAmount + cardAmount + transactionAmount
                                  )} ${currencyId}`}
                                </p>
                              </div>
                            </ServiceRowWrapper>

                            <StyledFieldRow
                              style={{
                                paddingBottom: '20px',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              <StyledFieldColumn
                                style={isMobile ? { marginBottom: '8px' } : {}}
                              >
                                <Button
                                  onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handlePrintToPosPrinter()
                                  }}
                                  buttonType={'secondaryLink'}
                                  size={'large'}
                                  label={t(
                                    'translation.JournalModal.label-print-balance'
                                  )}
                                  iconComponent={
                                    <icons.Print size={'smaller'} />
                                  }
                                  fullWidth
                                  name={'printBalance'}
                                />
                              </StyledFieldColumn>
                              <StyledFieldColumn
                                style={isMobile ? { marginBottom: '8px' } : {}}
                              >
                                <Button
                                  onClick={e => {
                                    e.preventDefault()
                                    window.open(
                                      getFullReportSrc(
                                        `stanjeBlagajne&journalId=${selectedJournal?.cid}`
                                      ),
                                      '_blank'
                                    )
                                  }}
                                  buttonType={'secondaryLink'}
                                  size={'large'}
                                  label={t(
                                    'translation.JournalModal.label-download-balance'
                                  )}
                                  iconComponent={<icons.PDF size={'smaller'} />}
                                  fullWidth
                                  name={'downloadBalance'}
                                />
                              </StyledFieldColumn>
                              <StyledFieldColumn
                                style={isMobile ? { marginBottom: '8px' } : {}}
                              >
                                <Button
                                  onClick={e => {
                                    e.preventDefault()
                                    window.open(
                                      getFullReportSrc(
                                        `utrzakBlagajne&journalId=${selectedJournal?.cid}`
                                      ),
                                      '_blank'
                                    )
                                  }}
                                  buttonType={'secondaryLink'}
                                  size={'large'}
                                  label={t(
                                    'translation.JournalModal.label-cashRegister-revenue'
                                  )}
                                  iconComponent={<icons.PDF size={'smaller'} />}
                                  fullWidth
                                  name={'cashRegisterRevenue'}
                                />
                              </StyledFieldColumn>
                            </StyledFieldRow>
                          </div>
                        </Tab>
                      </TabView>
                    </>
                  )
                }}
              </Form>
            )}
          </FormWrapper>

          <FormFooterContainer hasShadow style={{ alignItems: 'center' }}>
            {selectedJournal?.status === 'ACTIVE' && !isMobile ? (
              <Checkbox
                label={t('translation.JournalModal.label-print-the-conclusion')}
                checked={printJournal}
                handleChange={() => setPrintJournal(!printJournal)}
                style={{ marginLeft: 0, marginTop: 0, marginRight: 'auto' }}
              />
            ) : null}
            <Button
              buttonType={
                selectedJournal?.status === 'ACTIVE' && !isMobile
                  ? 'text'
                  : 'primary'
              }
              name={'close'}
              label={t('translation.JournalModal.label-close')}
              footerButton
              onClick={() => setSelectedJournal(null)}
            />
            {selectedJournal?.status === 'ACTIVE' && !isMobile ? (
              <Button
                onClick={async () => {
                  formRef.current.submitForm()
                }}
                buttonType={'primary'}
                name={'saveChanges'}
                disabled={false}
                label={t('translation.JournalModal.label-conclude')}
              />
            ) : null}
          </FormFooterContainer>
        </ModalWrapper>
      </Modal>
    </Container>
  )
}

export default JournalModal
