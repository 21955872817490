import React from 'react'
import { User } from './state/graphql'
import { themes } from './themes'

export const SmallHeightContext = React.createContext(false)
export const MobileContext = React.createContext(false)

export const UserContext = React.createContext<{
  user?: User
  setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

export const WizardContext = React.createContext<{
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
} | null>(null)

export const PromptToInstall = React.createContext<{
  deferredPrompt: any
  hidePrompt?: React.Dispatch<React.SetStateAction<boolean>>
} | null>({
  deferredPrompt: null,
})

export const ThemeContext = React.createContext({
  theme: themes['default'],
})
