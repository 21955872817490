import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Chat = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round">
        <path d="M11.5 14.5v7a2 2 0 01-2 2h-7 0a2 2 0 01-2-2s0 0 0 0v-15h0a2 2 0 012-2h4M6.5 8.5h-6M11.5 19.5H.5M4.5 6.5h2M23.5 8v0A1.5 1.5 0 0122 9.5h-5.5l-3 3v-3H10h0A1.5 1.5 0 018.5 8s0 0 0 0V2h0A1.5 1.5 0 0110 .5h12A1.5 1.5 0 0123.5 2zM16.25 4.5v0"></path>
        <path d="M16.25 4.5h0a.25.25 0 10.25.25v0a.25.25 0 00-.25-.25M19.75 4.5v0M19.75 4.5h0a.25.25 0 10.25.25v0a.25.25 0 00-.25-.25M12.75 4.5v0M12.75 4.5h0a.25.25 0 10.25.25v0a.25.25 0 00-.25-.25M6 21.25v0h0a.25.25 0 01.25.25v0a.25.25 0 01-.25.25v0h0a.25.25 0 01-.25-.25s0 0 0 0v0h0a.25.25 0 01.25-.25"></path>
      </g>
    </StyledIcon>
  )
}
