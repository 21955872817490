import React from 'react'
import Text from 'components/Text/Text'
import styled from 'styled-components'
import { device } from '../../constants'

interface TRadioButton {
  name: string
  value: any
  options: any[]
  setValue: any
  allowNullValue?: boolean
  horizontal?: boolean
  spaceOut?: boolean
  error?: any
  label?: string
  labelStyle?: any
  disabled?: boolean
  html?: any
  style?: any
  title?: string
}

export const RadioButton = (props: TRadioButton) => {
  const {
    name,
    value,
    options,
    allowNullValue,
    setValue,
    horizontal,
    spaceOut,
    error,
    label,
    disabled,
    html,
    style,
    title,
  } = props

  return (
    <Container>
      {title ? <StyledTitle>{title}</StyledTitle> : null}

      <RadioContainer horizontal={horizontal} spaceOut={spaceOut}>
        {options?.map(item => {
          return (
            <StyledCheck
              key={item?.id}
              style={{
                ...style,
              }}
            >
              <CheckboxInput
                onChange={e => {
                  if (e) setValue(name, item?.id)
                  else if (!e && allowNullValue) setValue(name, null)
                }}
                style={{ marginRight: 10, height: 28 }}
                checked={item?.id === value}
                disabled={disabled}
                type={'radio'}
              />

              <div
                dangerouslySetInnerHTML={html ? { __html: html } : undefined}
              >
                {label ? (
                  <Text
                    style={{
                      display: 'flex',
                    }}
                    type="p"
                  >
                    {item?.title}
                  </Text>
                ) : null}
              </div>
            </StyledCheck>
          )
        })}
      </RadioContainer>
      {error ? (
        <p
          style={{
            fontSize: 12,
            color: 'red',
            marginBottom: 8,
          }}
        >
          {error}
        </p>
      ) : null}
    </Container>
  )
}

export const CheckboxInput = styled.input`
  position: relative;
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
  &[disabled] {
    &:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
    :checked:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -1px;
    left: -1px;
    background-color: ${props => props.theme.colors.light};
    border: 1px solid ${props => props.theme.colors.outline};
    border-radius: 50px;
  }
  :checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -1px;
    left: -1px;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 50px;
  }
  :checked:after {
    content: '';
    display: block;
    width: 7px;
    height: 17px;
    border: solid ${props => props.theme.colors.light};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 4px;
    left: 10px;
  }
`
export const StyledCheck = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`
export const StyledTitle = styled.p`
  font-weight: 500;
  font-size: 1rem;
`
export const RadioContainer = styled.div<{
  horizontal?: boolean
  spaceOut?: boolean
}>`
  display: flex;
  flex-direction: ${props => (props.horizontal ? 'row' : 'column')};
  flex: 1;
  justify-content: ${props => (props.spaceOut ? 'row' : 'column')};
  gap: ${props => (props.horizontal ? '30px' : '0')};
  @media ${device.tablet} {
    flex-direction: column;
    gap: 0;
  }
`
export const Container = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-bottom: 10px;
  }
`
