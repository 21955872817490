import React from 'react'
import { useParams } from 'react-router'
import { ResourceTypeEditorForm } from 'views/Settings/Resources/ResourceTypeEditor/ResourceTypeEditorForm'
import { useSdk } from 'sdk'
import * as Yup from 'yup'
import {
  ProfessionAllDocument,
  useProfessionDeleteMutation,
  useProfessionGetQuery,
  useProfessionUpdateMutation,
} from 'state/graphql'

const ResourceTypeValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
  })

type TEditResourceTypeProps = {}

const EditResourceType = (props: TEditResourceTypeProps) => {
  let { id, selectedLocationId } = useParams<any>()
  const { navigateTo, appServices, t } = useSdk()
  const { data, loading } = useProfessionGetQuery({
    variables: { id },
  })
  console.log({ data })
  const [
    resourceTypeUpdateMutation,
    { loading: loadingUpdateResource },
  ] = useProfessionUpdateMutation()
  const profession = data?.profession?.get

  const [
    resourceTypeDeleteMutation,
    { loading: loadingDeleteMutation },
  ] = useProfessionDeleteMutation()

  const handleDelete = async () => {
    try {
      let results = await resourceTypeDeleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: ProfessionAllDocument,
          },
        ],
      })
      if (!results.errors) {
        navigateTo.resourcesList({ selectedLocationId, selectedTab: 1 })
        appServices.toast.success(
          t('translation.ResourceTypeEditorForm.toast-delete-success')
        )
      } else appServices.toast.danger(results?.errors?.[0]?.message)
    } catch {
      appServices.toast.danger(
        t('translation.ProfessionsListMobile.toast-delete-error')
      )
    }
  }

  const handleSubmit = async values => {
    const result = await resourceTypeUpdateMutation({
      variables: {
        input: {
          id,
          name: values.name,
          employees: profession?.resources?.map(r => r?.id!),
        },
      },
      refetchQueries: [
        {
          query: ProfessionAllDocument,
        },
      ],
    })
    if (!result.errors) {
      navigateTo.resourcesList({ selectedLocationId, selectedTab: 1 })
      appServices.toast.success(
        t('translation.ResourceTypeEditorForm.toast-changes-saved')
      )
    } else {
      appServices.toast.danger(result?.errors?.[0]?.message)
    }
  }

  return (
    <ResourceTypeEditorForm
      onSubmit={handleSubmit}
      validationSchema={ResourceTypeValidationSchema(t)}
      initialValues={profession}
      isEdit
      onDelete={handleDelete}
      loading={loadingUpdateResource || loadingDeleteMutation || loading}
    />
  )
}

export default EditResourceType
