import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'

import regionalFormatsHR from './localizations/hr/regionalFormats.json'
import translationHR from './localizations/hr/translation.json'

import translationMedicalHR from './localizations/hr/medical.json'
import translationMedicalEN from './localizations/en/medical.json'

import regionalFormatsEN from './localizations/en/regionalFormats.json'
import translationEN from './localizations/en/translation.json'

import translationRO from './localizations/ro/translation.json'
import regionalFormatsRO from './localizations/ro/regionalFormats.json'

import translationES from './localizations/es/translation.json'
import regionalFormatsES from './localizations/es/regionalFormats.json'

import translationDE from './localizations/de/translation.json'
import regionalFormatsDE from './localizations/de/regionalFormats.json'

import translationSR from './localizations/sr/translation.json'
import regionalFormatsSR from './localizations/sr/regionalFormats.json'
import { hr, enGB, de, sr } from 'date-fns/locale'
import { format } from 'date-fns'
// the translations
// (tip move them in a EN file and import them)
const resources = {
  en: {
    translation: {
      regionalFormats: regionalFormatsEN,
      translation: translationEN,
    },
    medical: {
      regionalFormats: regionalFormatsEN,
      translation: translationMedicalEN,
    },
  },
  hr: {
    translation: {
      regionalFormats: regionalFormatsHR,
      translation: translationHR,
    },
    medical: {
      regionalFormats: regionalFormatsHR,
      translation: translationMedicalHR,
    },
  },
  ro: {
    translation: {
      regionalFormats: regionalFormatsRO,
      translation: translationRO,
    },
  },
  es: {
    translation: {
      regionalFormats: regionalFormatsES,
      translation: translationES,
    },
  },
  de: {
    translation: {
      regionalFormats: regionalFormatsDE,
      translation: translationDE,
    },
  },
  sr: {
    translation: {
      regionalFormats: regionalFormatsSR,
      translation: translationSR,
    },
  },
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(ChainedBackend)
  .init(
    {
      debug: import.meta.env.VITE_I18NEXT_DEBUG_ENABLED === 'true',
      backend: {
        backends: [HttpBackend, resourcesToBackend(resources)],
        backendOptions: [
          {
            loadPath: `${
              import.meta.env.VITE_API_URL
            }/api/localizations/{{lng}}/{{ns}}`,
            crossDomain: true,
            withCredentials: true,
            //  expirationTime: 1000, //7 * 24 * 60 * 60 * 1000 // 7 days
          },
        ],
      },
      fallbackLng: 'hr',
      ns: ['translation', 'medical'],
      defaultNS: 'translation',
      fallbackNS: 'translation',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    (err, t) => {
      if (err) {
        console.log('Something went wrong with loading translations', err)
      } else console.log('Localizations loaded')
    }
  )

export const getCurrentLanguage = () => {
  const language = localStorage.getItem('i18nextLng')
  return language || i18n.language
}

export const t: (key, options?) => string = (key, options) => {
  return i18n.t(key, options) || ''
}
export const getDateFnsLocale = () => {
  const language = getCurrentLanguage()
  switch (language) {
    case 'hr':
      return hr
    case 'en':
      return enGB
    case 'de':
      return de
    case 'sr':
      return sr
    default:
      return hr
  }
}
export const formatDate = (date: Date, formatStr: string) => {
  const locale = getDateFnsLocale()
  return format(date, formatStr, { locale })
}

export default i18n
