import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Download = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.5 8.5H14M4.5 11.5h6M4.5 5.5h7M4.5 14.5h4M4.5 17.5h4M10.5 23.5h-9 0a1 1 0 01-1-1s0 0 0 0v-21h0a1 1 0 011-1h13.293a1 1 0 01.707.293L19.207 4.5h0a1 1 0 01.293.707V8.5"></path>
        <path d="M21.743 13.257a6 6 0 11-8.486 8.486 6 6 0 018.486-8.486M17.5 14.5v6M17.5 20.5l-2.25-2.25M17.5 20.5l2.25-2.25"></path>
      </g>
    </StyledIcon>
  )
}
