import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import { ResourceFragmentGql } from 'state/fragments'
import { resourceSearchGql, locationGetGql, inviteUserGql } from 'state/gql'
import { isUrlNameAvailableGql } from 'state/query/isUrlOrgNameValid'
import {
  useGetAllHomeDataQuery,
  useGetAllCalendarDataQuery,
} from 'state/graphql'

export const queryResourceTimeSheet = gql`
  query getResourceShedule(
    $resourceId: [ID]!
    $locationId: ID!
    $fromDate: GraphQLDate
    $toDate: GraphQLDate
  ) {
    resourceTimeSheet {
      schedule(
        filter: {
          resourceId: $resourceId
          locationId: $locationId
          fromDate: $fromDate
          toDate: $toDate
        }
      ) {
        name
        resource {
          id
          firstName
          lastName
          avatarUrl
        }
        workHours {
          day
          date
          dayName
          startTime
          endTime
          dayOff
        }
      }
    }
  }
`
export const queryFreeBookingSlots = gql`
  query queryFreeBookingSlots(
    $locationId: ID!
    $services: [ID]!
    $dateFrom: GraphQLDate!
    $resourceId: ID
  ) {
    resourceBooking {
      freeSlots(
        input: {
          locationId: $locationId
          services: $services
          dateFrom: $dateFrom
          resourceId: $resourceId
        }
      ) {
        date
        timeSlots {
          startTime
          resources {
            id
            name
          }
        }
      }
    }
  }
`

export const queryFreeMarketBookingSlots = gql`
  query queryFreeMarketBookingSlots(
    $locationId: ID!
    $services: [ID]!
    $dateFrom: GraphQLDate!
    $resourceId: ID
  ) {
    market {
      freeSlots(
        input: {
          locationId: $locationId
          services: $services
          dateFrom: $dateFrom
          resourceId: $resourceId
        }
      ) {
        date
        timeSlots {
          startTime

          resources {
            id
            name
          }
        }
      }
    }
  }
`

export const queryMyBookings = gql`
  query queryMyBookings($dateFrom: GraphQLDate!, $dateTo: GraphQLDate!) {
    resourceBooking {
      myBookings(input: { dateFrom: $dateFrom, dateTo: $dateTo }) {
        id
        startTime
        endTime
        appointmentId
        status
        services {
          id
          name
          color
          price
          durationMinutes
        }
        description
        client {
          id
          firstName
          lastName
          user {
            id
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
        resource {
          id
          code
          name
          firstName
          lastName
          email
          color
          mobilePhone
          avatarUrl
          user {
            id
            firstName
            lastName
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
        location {
          id
          code
          name
          imageUrl
          orgId
        }
        durationMinutes
      }
    }
  }
`

export const queryUserInvites = gql`
  query queryUserInvites {
    invitation {
      activeUserInvites {
        id
        firstName
        lastName
        email
        mobilePhone
        isConfirmed
        mobilePhone
        role
        client {
          id
          firstName
          lastName
          nickName
          user {
            id
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
      }
    }
  }
`

export const searchJournal = gql`
  query posJournalSearch(
    $pageNumber: Int
    $pageSize: Int
    $searchTerm: String
    $role: String
  ) {
    posJournal_search(
      filter: {
        searchTerm: $searchTerm
        fetch: { pageNumber: $pageNumber, pageSize: $pageSize }
        role: $role
      }
    ) {
      id
      locationId
      orgId
      date
      createdByUserId
      closingBalanceAmount
      status
      closedAt
      createdAt
      fisDeviceId
      fisLocationId
      fisOperatorOib
      cid
      deviceId
      closedByUserId
      totalCreditAmount
      totalDebitAmount
      journalNumber
      currencyId
    }
  }
`

export const searchDevice = gql`
  query posDeviceSearch(
    $pageNumber: Int
    $pageSize: Int
    $searchTerm: String
    $role: String
  ) {
    posDevice_search(
      filter: {
        searchTerm: $searchTerm
        fetch: { pageNumber: $pageNumber, pageSize: $pageSize }
        role: $role
      }
    ) {
      id
      orgId
      locationId
      fisLocationId
      fisDeviceId
      defaultOperatorId
      defaultDepositAmount
      certData
      storeName
      storeCity
      storeAddress
      storeZip
      storeVatId
      storeIban
      deviceName
      printerName
    }
  }
`

export const searchProduct = gql`
  query posProductSearch(
    $pageNumber: Int
    $pageSize: Int
    $searchTerm: String
  ) {
    products_search(
      filter: {
        searchTerm: $searchTerm
        fetch: { pageNumber: $pageNumber, pageSize: $pageSize }
      }
    ) {
      id
      code
      type {
        id
        name
        status
      }
      name
      uom
      retailPrice
      vatRate
      barCode
      status
      description
    }
  }
`

export const searchClients = gql`
  query searchClients(
    $pageNumber: Int
    $pageSize: Int
    $searchTerm: String
    $sortBy: String
  ) {
    client {
      search(
        filter: {
          searchTerm: $searchTerm
          fetch: { pageNumber: $pageNumber, pageSize: $pageSize }
          sortBy: $sortBy
        }
      ) {
        user {
          id
          firstName
          lastName
          avatarUrl
          fb_profilePicUrl
          google_profilePicUrl
          emailNotificationSettings {
            sendReminderSms
            sendReminderEmail
          }
        }
        sendReminderEmail
        sendReminderSms
        id
        defaultAvatarUrl
        address
        city
        zipCode
        fullName
        lastName
        mobilePhone
        category
        firstName
        email
        avatarUrl
        birthDate
        comment
        nickName
        gender
        vatId
        identifier
        membershipExpirationDate
        mailboxStatus
        mailboxDisabledReason
        country {
          id
          countryCode
          countryName
        }
        paymentRequired
        status
      }
    }
  }
`

export const queryClients = gql`
  query queryClients {
    clients {
      id
      fullName
      lastName
      mobilePhone
      category
      firstName
      email
      avatarUrl
      birthDate
      comment
      nickName
      gender
      defaultAvatarUrl
      address
      city
      zipCode
      vatId
      identifier
      country {
        id
        countryCode
        countryName
      }
      birthDate
      sendReminderEmail
      sendReminderSms
    }
  }
`

export const queryClientInvites = gql`
  query queryClientInvites {
    invitation {
      activeClientInvites {
        id
        firstName
        lastName
        email
        mobilePhone
        isConfirmed
        client {
          id
          firstName
          lastName
          user {
            id
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
      }
    }
  }
`

const queryServices = gql`
  query queryServices {
    services {
      id
      name
      color
      durationMinutes
      bookingAllowed
      hasBookingConfirmation
      price
      description
      profession {
        id
        name
      }
      type {
        id
        name
        description
      }
    }
  }
`

const queryMarketServices = gql`
  query queryMarketServices($locationId: ID!) {
    market {
      services(locationId: $locationId) {
        id
        name
        color
        durationMinutes
        bookingAllowed
        hasBookingConfirmation
        price
        description
        profession {
          id
          name
        }
        type {
          id
          name
          description
        }
      }
    }
  }
`

export const queryServiceTypes = gql`
  query queryServiceTypes($locationId: ID) {
    serviceType {
      search(locationId: $locationId) {
        id
        name
        description
        services {
          id
          name
          description
          sequence
          color
          price
          durationMinutes
          bookingAllowed
          hasBookingConfirmation
          resourceType {
            id
            name
          }
          profession {
            id
            name
          }
          vatRate
        }
      }
    }
  }
`

export const queryMarketServiceTypes = gql`
  query queryMarketServiceTypes($locationId: ID) {
    market {
      serviceTypes(locationId: $locationId) {
        id
        name
        description

        services {
          id
          name
          description
          color
          price
          sequence
          durationMinutes
          bookingAllowed
          hasBookingConfirmation
          type {
            id
          }
          profession {
            id
            name
          }
        }
      }
    }
  }
`

export const queryMarketLocation = gql`
  query marketGetLocation($id: ID!) {
    market {
      getLocation(id: $id) {
        id
        code
        name
        description
        imageUrl
        facebookUrl
        instagramUrl
        emailNote
        onlineBooking_showAddress
        onlineBooking_showEmail
        onlineBooking_showPhone
        onlineBooking_listOnZoyya
        onlineBooking_bookingFlow
        workWeek {
          mon {
            openTime
            closeTime
          }
          tue {
            openTime
            closeTime
          }
          wed {
            openTime
            closeTime
          }
          thu {
            openTime
            closeTime
          }
          fri {
            openTime
            closeTime
          }
          sat {
            openTime
            closeTime
          }
          sun {
            openTime
            closeTime
          }
        }
        mobilePhone
        phone
        email
        city
        zipCode
        address
        country {
          id
          countryCode
          countryName
          currency {
            id
            currencyCode
            currencyName
          }
        }
        resources {
          id
          name
          code
          firstName
          lastName
          email
          mobilePhone
          nickName
          role
          color
          description
          professions {
            id
            name
          }
          user {
            id
            firstName
            lastName
            address
            email
            role
            phone
            gender
            nickName
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
          locationSettings {
            onlineBooking
          }
        }
        onlineBooking_showPrices
        onlineBooking_status
      }
    }
  }
`

export const queryLocation = gql`
  query queryLocation($id: ID!) {
    location {
      get(id: $id) {
        id
        code
        name
        timeSlotDuration
        description
        localizationNamespace
        imageUrl
        workWeek {
          mon {
            openTime
            closeTime
          }
          tue {
            openTime
            closeTime
          }
          wed {
            openTime
            closeTime
          }
          thu {
            openTime
            closeTime
          }
          fri {
            openTime
            closeTime
          }
          sat {
            openTime
            closeTime
          }
          sun {
            openTime
            closeTime
          }
        }
        mobilePhone
        phone
        email
        city
        zipCode
        address
        lat
        lng
        facebookUrl
        instagramUrl
        emailNote
        onlineBooking_status
        onlineBooking_showPrices
        onlineBooking_showAddress
        onlineBooking_showEmail
        onlineBooking_showPhone
        onlineBooking_listOnZoyya
        onlineBooking_bookingFlow
        notifications_emailReminder
        notifications_emailReviewRequest
        notifications_smsReviewRequest
        sendAppointmentEmailsToClients
        country {
          id
          countryCode
          countryName
          currency {
            id
            currencyCode
            currencyName
          }
        }
        resources {
          id
          name
          code
          firstName
          lastName
          email
          mobilePhone
          nickName
          role
          color
          locationSettings {
            onlineBooking
          }
          professions {
            id
            name
          }
          locationSettings {
            onlineBooking
          }
          user {
            id
            firstName
            lastName
            address
            email
            role
            phone
            gender
            nickName
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
      }
    }
  }
`

export const queryMarketLocations = gql`
  query marketLocations {
    market {
      locations {
        my {
          id
          name
          address
          city
          orgId
          imageUrl
          description
          onlineBooking_status
          category
        }
        other {
          id
          name
          address
          city
          orgId
          imageUrl
          description
          onlineBooking_status
          category
        }
      }
    }
  }
`

export const queryLocations = gql`
  query queryLocations {
    location {
      search {
        id
        orgId
        code
        timeSlotDuration
        name
        mobilePhone
        phone
        email
        city
        zipCode
        localizationNamespace
        description
        imageUrl
        workWeek {
          mon {
            openTime
            closeTime
          }
          tue {
            openTime
            closeTime
          }
          wed {
            openTime
            closeTime
          }
          thu {
            openTime
            closeTime
          }
          fri {
            openTime
            closeTime
          }
          sat {
            openTime
            closeTime
          }
          sun {
            openTime
            closeTime
          }
        }
        lat
        lng
        facebookUrl
        instagramUrl
        emailNote
        address
        country {
          id
          countryCode
          countryName
          currency {
            id
            currencyCode
            currencyName
          }
        }
        resources {
          id
          name
          code
          user {
            id
            firstName
            lastName
            address
            phone
            gender
            nickName
            email
            role
          }
        }
      }
    }
  }
`

const queryMyLocations = gql`
  query queryMyLocations {
    user {
      me {
        id
        locations {
          id
          name
          address
          email
          resources {
            id
            name
            user {
              id
              firstName
              lastName
              avatarUrl
              nickName
              fb_profilePicUrl
              google_profilePicUrl
            }
          }
        }
      }
    }
  }
`

export const queryOrganizationUsers = gql`
  query queryOrganizationUsers($orgId: ID!) {
    organization {
      users(orgId: $orgId) {
        id
        firstName
        lastName
        userName
        nickName
        email
        avatarUrl
        role
      }
      my {
        id
        orgName
      }
    }
  }
`

const queryDailySummary = gql`
  query queryDailySummary($dateFrom: GraphQLDate!, $dateTo: GraphQLDate!) {
    dailySummary(input: { dateFrom: $dateFrom, dateTo: $dateTo }) {
      date
      count
      appointments {
        id
        date
        startTime
        endTime
        client {
          id
          fullName
          user {
            id
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
        services {
          id
          name
          color
          price
          durationMinutes
        }
      }
    }
  }
`

const mutateBooking = gql`
  mutation mutateBooking($input: ResourceBookingInput!) {
    resourceBooking {
      add(input: $input) {
        status {
          success
        }
        payload {
          id
          service {
            id
            name
          }
          message
          startTime
          endTime
        }
      }
    }
  }
`

const addLocation = gql`
  mutation addLocation($input: LocationInput) {
    location {
      add(input: $input) {
        status {
          success
        }
        payload {
          id
          code
          name
          mobilePhone
          phone
          email
          city
          zipCode
          address
          country {
            id
          }
          resources {
            id
            name
            code
          }
        }
      }
    }
  }
`

const addResources = gql`
  mutation locationSetResources($locationId: ID, $resourceId: [ID]) {
    location {
      setResources(locationId: $locationId, resourceId: $resourceId) {
        status {
          success
        }
      }
    }
  }
`

const mutateLocationShifts = gql`
  mutation locationSetWorkShifts($input: SetLocationWorkShiftInput!) {
    location {
      setWorkShifts(input: $input) {
        status {
          success
        }
        payload {
          id
          code
          name
          resources {
            id
            name
            code
          }
        }
      }
    }
  }
`

const updateResourceSequence = gql`
  mutation updateResourceSequence($input: ResourceSequenceInput) {
    resource {
      updateSequence(input: $input) {
        status {
          success
        }
        payload {
          id
          code
          name
        }
      }
    }
  }
`

const updateResourceTimeSheetScheduleGql = gql`
  mutation updateResourceTimeSheetSchedule($input: SetWorkHoursInput!) {
    resourceTimeSheet {
      setWorkHours(input: $input) {
        id
        date
        startTime
        endTime
      }
    }
  }
`

const addResource = gql`
  mutation addResource($input: ResourceInput!) {
    resource {
      add(input: $input) {
        payload {
          ...ResourceFragment
        }
      }
    }
  }
  ${ResourceFragmentGql}
`

const updateClient = gql`
  mutation updateClient($input: ClientInput) {
    clients {
      update(input: $input) {
        status {
          success
        }
        payload {
          id
          fullName
          firstName
          lastName
          email
          mobilePhone
          nickName
        }
      }
    }
  }
`

const mutateService = gql`
  mutation mutateService($input: ServiceInput) {
    services {
      add(input: $input) {
        status {
          success
        }
        payload {
          id
          name
          color
          durationMinutes
          price
        }
      }
    }
  }
`

const mutateUpdateService = gql`
  mutation mutateUpdateService($input: ServiceInput) {
    services {
      update(input: $input) {
        status {
          success
        }
        payload {
          id
          name
          color
          durationMinutes
          price
        }
      }
    }
  }
`

const mutateUpdateServiceType = gql`
  mutation mutateUpdateServiceType($input: ServiceTypeInput) {
    serviceTypes {
      update(input: $input) {
        status {
          success
        }
        payload {
          id
          name
          description
        }
      }
    }
  }
`

const mutateClient = gql`
  mutation mutateClient($input: ClientInput) {
    clients {
      add(input: $input) {
        status {
          success
        }
        payload {
          id
          fullName
          mobilePhone
          firstName
          lastName
          email
        }
      }
    }
  }
`

const registerUser = gql`
  mutation registerUser($input: RegisterClientInput!) {
    market {
      registerUser(input: $input) {
        inviteId
      }
    }
  }
`

const registerOrgUser = gql`
  mutation registerOrgUser($input: RegisterUserInput!) {
    user {
      register(input: $input) {
        success
        payload {
          token
          user {
            id
          }
        }
      }
    }
  }
`

const registerUserPhone = gql`
  mutation registerUserPhone($input: RegisterPhoneInput!) {
    user {
      registerPhone(input: $input) {
        success
        pinId
      }
    }
  }
`

const confirmOtp = gql`
  mutation confirmOtp($input: ConfirmOtpInput!) {
    market {
      confirmOtp(input: $input) {
        token
      }
    }
  }
`

const resetPassword = gql`
  mutation requestPassswordRest($sendTo: String) {
    user {
      requestPasswordReset(input: { sendTo: $sendTo })
    }
  }
`

const setNewPassword = gql`
  mutation confirmPassswordReset($token: String, $password: String) {
    user {
      confirmPasswordReset(input: { hash: $token, password: $password }) {
        payload {
          token
          user {
            id
            firstName
            lastName
            role
          }
        }
      }
    }
  }
`

const mutateOrganization = gql`
  mutation mutateOrganization($input: UpdateOrganizationInput!) {
    organization {
      update(input: $input) {
        status {
          success
        }
        payload {
          id
          orgName
          description
          zipCode
          vatId
          address
          city
          country {
            id
            countryName
          }
          phone
          mobilePhone
          webUrl
          email
          settings {
            calendar {
              timeSlotDuration
            }
            booking {
              bookingEnabled
              bookingRequired
            }
            client {
              memberInviteRequired
            }
          }
        }
      }
    }
  }
`

export const queryMyOrganization = gql`
  query getMyOrganizations {
    organization {
      my {
        id
        orgName
        orgUrlName
        description
        mobilePhone
        phone
        zipCode
        language
        hasVivaMerchantId
        hasVivaApiKey
        currency {
          id
          currencyCode
          currencyName
        }
        city
        webUrl
        vatId
        iban
        subscriptionType
        subscriptionStartDate
        subscriptionAmount
        subscriptionMandateId
        subscriptionCustomerId
        subscriptionEndDate
        subscriptionPlan
        stripeCurrencyId
        stripePriceId
        paymentEnabled
        downgradeDate
        downgradePlan
        address
        email
        avatarUrl
        myRole
        country {
          id
          countryName
        }
        settings {
          calendar {
            timeSlotDuration
          }
          booking {
            bookingEnabled
            bookingRequired
          }
          client {
            memberInviteRequired
          }
        }
        paymentPlans {
          id
          plans {
            id
            name
            imageUrl
            priceMonthly
            priceYearly
            features {
              id
              name
            }
            interval
          }
          upgradeChargeAmountMonthly
          upgradeChargeAmountYearly
        }
        orgFeatures {
          planId
          hasClients
          hasCustomTimeSlotDuration
          hasEmployees
          hasFreeSms
          hasHolidays
          hasInvitations
          hasLocations
          hasOrgUrlName
          hasProfessions
          hasReminders
          hasReporting
          hasResources
          hasSchedule
          hasPos
          hasOnlineBooking
        }
        myLocations {
          id
          code
          name
        }
        smsReminders_enabled
        vatStatus
        vatExemptText
      }
    }
  }
`

export const mutateUploadAvatar = gql`
  mutation mutateUploadUserAvatar($file: Upload!) {
    user {
      uploadAvatar(file: $file) {
        filename
      }
    }
  }
`

export const mutateUploadClientAvatar = gql`
  mutation mutateUploadClientAvatar($file: Upload!, $clientId: ID!) {
    clients {
      uploadAvatar(file: $file, clientId: $clientId) {
        filename
        downloadUrl
      }
    }
  }
`

export const mutateUploadOrgAvatar = gql`
  mutation mutateUploadOrgAvatar($file: Upload!) {
    organization {
      uploadAvatar(file: $file) {
        filename
      }
    }
  }
`

export const uploadLocationImageGql = gql`
  mutation locationUploadImage($file: Upload!, $locationId: ID!) {
    location {
      uploadImage(file: $file, locationId: $locationId) {
        filename
        downloadUrl
      }
    }
  }
`

export const resendInvitation = gql`
  mutation resendInvitation($id: ID!) {
    invitation {
      resend(input: { id: $id }) {
        status {
          success
        }
      }
    }
  }
`

export const getInvitationGql = gql`
  query getInvitationByHash($hash: String!) {
    invitation {
      getByHash(hash: $hash) {
        id
        orgId
        firstName
        lastName
        email
        mobilePhone
      }
    }
  }
`
export const queryUserMe = gql`
  query queryUserMe {
    user {
      me {
        id
        userName
        firstName
        lastName
        avatarUrl
        nickName
        email
        language
        phone
        emailNotificationSettings {
          sendBookingEmail
          sendHelpEmail
          sendNewsEmail
          sendReminderEmail
          sendReminderSms
        }
        address
        gender
        isEmailConfirmed
        isMobilePhoneConfirmed
        hasLocalAccount
        hasFbAccount
        hasGoogleAccount
        google_profilePicUrl
        fb_profilePicUrl
        role
        google_email
        google_firstName
        google_lastName
        google_phone
        fb_email
        fb_firstName
        fb_lastName
        fb_phone
        resource {
          id
          name
          firstName
          lastName
          nickName
          email
          color
          avatarUrl
          user {
            id
            firstName
            lastName
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
      }
    }
  }
`

export const queryUserMeWithLocations = gql`
  query queryUserMe {
    user {
      me {
        id
        userName
        firstName
        lastName
        avatarUrl
        nickName
        email
        language
        phone
        emailNotificationSettings {
          sendBookingEmail
          sendHelpEmail
          sendNewsEmail
          sendReminderEmail
          sendReminderSms
        }
        address
        gender
        isEmailConfirmed
        isMobilePhoneConfirmed
        hasLocalAccount
        hasFbAccount
        hasGoogleAccount
        google_profilePicUrl
        fb_profilePicUrl
        role
        google_email
        google_firstName
        google_lastName
        google_phone
        fb_email
        fb_firstName
        fb_lastName
        fb_phone

        locations {
          id
          name
          address
          email
          city
          code
          description
          imageUrl
          orgId
        }
        resource {
          id
          name
          firstName
          lastName
          nickName
          email
          color
          avatarUrl
          user {
            id
            firstName
            lastName
            avatarUrl
            fb_profilePicUrl
            google_profilePicUrl
          }
        }
      }
    }
  }
`

export const mutateUpdateUser = gql`
  mutation mutateUpdateUser($input: UpdateUserInput!) {
    user {
      update(input: $input) {
        success
        payload {
          id
          firstName
          lastName
          nickName
          email
          avatarUrl
          phone
          address
          role
          isEmailConfirmed
          isMobilePhoneConfirmed
        }
      }
    }
  }
`

export const searchMessages = gql`
  query locationSearchMessages(
    $sendTo: String
    $pageNumber: Int
    $dateFrom: GraphQLDateTime!
    $kind: [MessageKindEnum]
  ) {
    location {
      searchMessages(
        filter: {
          to: $sendTo
          dateFrom: $dateFrom
          fetch: { pageNumber: $pageNumber }
          kind: $kind
        }
      ) {
        id
        sentTo
        status
        sentTime
        client {
          id
          firstName
          lastName
          avatarUrl
          user {
            id
            avatarUrl
          }
        }
        resourceBooking {
          id
        }
        appointment {
          id
          location {
            name
            imageUrl
          }
        }
        subject
        kind
      }
    }
  }
`

export const useSearchMessages = (sendTo, pageNumber, dateFrom, kind) => {
  const { data, loading, fetchMore, error } = useQuery(searchMessages, {
    variables: { sendTo, pageNumber, dateFrom, kind },
  })

  return {
    error: error,
    data: data ? data.location?.searchMessages : [],
    loading: loading,
    fetchMore,
  }
}

export const useGetMyBookings = (dateFrom, dateTo) => {
  const { data, loading, subscribeToMore } = useQuery(queryMyBookings, {
    variables: { dateFrom, dateTo },
  })

  return {
    data: data ? data.resourceBooking.myBookings : [],
    loading: loading,
    subscribeToMore,
  }
}

export const useGetFreeBookingsSlots = (
  locationId,
  services,
  dateFrom,
  resourceId
) => {
  const { data, loading } = useQuery(queryFreeBookingSlots, {
    variables: { locationId, services, dateFrom, resourceId },
  })

  return {
    data: data?.resourceBooking?.freeSlots,
    loading,
  }
}

export const useGetFreeMarketBookingsSlots = (
  locationId,
  services,
  dateFrom,
  resourceId
) => {
  return useQuery(queryFreeMarketBookingSlots, {
    variables: { locationId, services, dateFrom, resourceId },
    fetchPolicy: 'network-only',
  })
}

export const useGetResourceTimeSheet = (resource, skip) => {
  const { data, loading } = useQuery(queryResourceTimeSheet, {
    variables: { ...resource },
    skip,
  })

  return {
    data: data?.resourceTimeSheet?.schedule,
    loading,
  }
}

export const useGetResources = () => {
  const { data } = useQuery(resourceSearchGql)
  return data ? data.resource.search : null
}

export const useGetDailySummary = (dateFrom, dateTo) => {
  const { data } = useQuery(queryDailySummary, {
    variables: { dateFrom, dateTo },
  })

  return data ? data.dailySummary : []
}

export const useSearchJournals = (pageSize, pageNumber, searchTerm, role) => {
  return useQuery(searchJournal, {
    variables: { pageNumber, pageSize, searchTerm, role },
  })
}

export const useSearchClients = (pageSize, pageNumber, searchTerm, sortBy) => {
  return useQuery(searchClients, {
    variables: { pageNumber, pageSize, searchTerm, sortBy },
  })
}
export const useGetMarketServices = locationId => {
  return useQuery(queryMarketServices, {
    variables: { locationId },
    fetchPolicy: 'network-only',
  })
}

export const useGetServiceTypes = locationId => {
  const { data, loading } = useQuery(queryServiceTypes, {
    variables: { locationId },
    fetchPolicy: 'no-cache',
  })
  return {
    data: data ? data.serviceType.search : [],
    loading,
  }
}

export const useGetMarketServiceTypes = locationId => {
  return useQuery(queryMarketServiceTypes, {
    variables: { locationId },
    fetchPolicy: 'network-only',
  })
}

export const useIsValidUrlName = orgUrlName => {
  return useQuery(isUrlNameAvailableGql, {
    variables: { orgUrlName },
  })
}

export const useGetLocation = id => {
  const { data } = useQuery(queryLocation, {
    variables: { id: id?.toString() },
    skip: !id,
  })
  return data ? data.location.get : []
}

export const useGetMarketLocation = id => {
  return useQuery(queryMarketLocation, {
    variables: { id: id?.toString() },
    fetchPolicy: 'network-only',
    skip: !id,
  })
}

export const useGetLocationLoading = id => {
  return useQuery(locationGetGql, {
    variables: { id },
    skip: !id,
  })
}

export const useGetLocationsLoading = () => {
  return useQuery(queryLocations)
}

export const useGetMarketLocations = () => {
  return useQuery(queryMarketLocations)
}

export const useGetMyLocations = () => {
  const { data } = useQuery(queryMyLocations)
  return data ? data.user.me.locations : []
}

export const useGetMyOrganization = () => {
  return useQuery(queryMyOrganization)
}

export const useGetMe = () => {
  return useQuery(queryUserMeWithLocations)
}

export const useAddLocation = location => {
  return useMutation(addLocation, {
    variables: {
      input: location,
    },
    refetchQueries: [
      {
        query: queryLocations,
      },
    ],
  })
}

export const useAddReource = () => {
  return useMutation(addResources)
}

export const useUpdateLocationShifts = location => {
  return useMutation(mutateLocationShifts, {
    variables: {
      input: location,
    },
    refetchQueries: [
      {
        query: queryLocations,
      },
    ],
  })
}

export const useUpdateResourceSequence = input => {
  return useMutation(updateResourceSequence, {
    variables: {
      input: input,
    },
  })
}

export const useAddResource = resource => {
  return useMutation(addResource, {
    variables: {
      input: resource,
    },
  })
}

export const useMutateBooking = (booking, dateFrom, dateTo) => {
  return useMutation(mutateBooking)
}

export const useUpdateClient = client => {
  return useMutation(updateClient, {
    variables: client,
    refetchQueries: [
      {
        query: queryClients,
      },
    ],
  })
}

export const useMutateService = locationId => {
  return useMutation(mutateService, {
    refetchQueries: [
      {
        query: queryServiceTypes,
        variables: { locationId },
      },
      {
        query: queryServices,
      },
    ],
  })
}

export const useMutateOrganization = org => {
  return useMutation(mutateOrganization, {
    variables: { input: org },
    refetchQueries: [
      {
        query: queryMyOrganization,
      },
    ],
  })
}

export const useMutateUpdateService = locationId => {
  return useMutation(mutateUpdateService, {
    refetchQueries: [
      {
        query: queryServiceTypes,
        variables: { locationId },
      },
    ],
  })
}

export const useMutateUpdateServiceType = () => {
  return useMutation(mutateUpdateServiceType)
}

export const useMutateUser = () => {
  return useMutation(mutateUpdateUser, {
    refetchQueries: [{ query: queryUserMe }],
  })
}

export const useInviteUser = () => {
  return useMutation(inviteUserGql)
}

export const useMutateClient = client => {
  return useMutation(mutateClient, {
    variables: {
      input: client,
    },
    refetchQueries: [
      {
        query: queryClients,
      },
    ],
  })
}

export const useUpdateResourceTimeSheetSchedule = () => {
  return useMutation(updateResourceTimeSheetScheduleGql)
}

export const useRegisterUser = () => {
  return useMutation(registerUser)
}

export const useRegisterOrgUser = () => {
  return useMutation(registerOrgUser)
}

export const useRegisterUserPhone = () => {
  return useMutation(registerUserPhone)
}

export const useConfirmOtp = () => {
  return useMutation(confirmOtp)
}

export const useResetPassword = () => {
  return useMutation(resetPassword)
}

export const useSetNewPassword = () => {
  return useMutation(setNewPassword)
}

export const useGetAllHomeData = (
  dateFrom,
  dateTo,
  subscribe,
  locationId,
  resourceId,
  clientId,
  clientIds
) => {
  const variables = {
    locationId,
    dateFrom,
    dateTo,
    resourceId,
    clientId,
    clientIds,
  }

  const result = useGetAllHomeDataQuery({
    variables,
  })

  return result
}

export const useGetAllCalendarData = (
  dateFrom,
  dateTo,
  subscribe,
  locationId,
  resourceId
) => {
  const variables = {
    input: { dateFrom, dateTo },
    locationId,
    dateFrom,
    dateTo,
    resourceId,
  }
  const result = useGetAllCalendarDataQuery({
    variables,
    skip: !locationId,
    fetchPolicy: 'network-only',
  })

  return result
}
