import { set } from 'lodash'
import { RouterOutput } from 'src/trpc'
import { create } from 'zustand'

type FeatureFlags = RouterOutput['featureFlags_get']

const initialState = {
  orgId: '1',
  locationId: BigInt(1),
  featureFlags: {
    calendarVersion: 'v2',
    newAppointmentButtonBehavior: 'appointmentWizard',
    printAppointment: '',
    printClient: 'neodynamicPrintService',
    loyalty: 'false',
  } as FeatureFlags,
}

type Actions = {
  setFeatureFlags: (flags: FeatureFlags) => void
}

export const useAppStore = create<typeof initialState & Actions>(set => ({
  ...initialState,

  setFeatureFlags: (flags: FeatureFlags) => {
    set(state => ({
      ...state,
      featureFlags: flags,
    }))
  },
}))

export const useFeatureFlags = () => {
  const [featureFlags] = useAppStore(store => [store.featureFlags])
  return featureFlags
}
