import React, { useState } from 'react'
import Text from 'components/Text/Text'
import styled from 'styled-components'
import i18n from 'src/localization'
import Tooltip from '../../../../components/Tooltip/Tooltip'

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`

const Toggle = styled.button<{ enabled: boolean }>`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  background-color: #c8c8c8;
  border-radius: 100px;
  border-style: none;
  margin: 0 0.5rem;

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    top: 1px;
    left: ${props => (props.enabled ? '50%' : '1px')};
    transition: all 0.3s;
  }
`

type TToggleSwitchProps = {
  disabled: boolean
  enabled: boolean
  leftLabel: string
  rightLabel: string
  onClick?: (...args: any[]) => void
}

const ToggleSwitch = (props: TToggleSwitchProps) => {
  const {
    disabled,
    onClick,
    leftLabel,
    rightLabel,
    enabled: enabledProps,
  } = props
  const [enabled, setEnabled] = useState(enabledProps)

  const handleToggle = e => {
    e.preventDefault()
    setEnabled(prev => !prev)

    if (onClick) {
      onClick()
    }
  }

  return (
    <Wrapper>
      <Text type="span">{leftLabel}</Text>
      {disabled ? (
        <Tooltip label={i18n.t('translation.ToggleSwitch.toggleHint')}>
          <Toggle
            enabled={enabled}
            disabled={disabled}
            onClick={handleToggle}
          />
        </Tooltip>
      ) : (
        <Toggle enabled={enabled} disabled={disabled} onClick={handleToggle} />
      )}
      <Text type={'span'}>{rightLabel}</Text>
    </Wrapper>
  )
}

export default ToggleSwitch
