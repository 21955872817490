import React, { useState } from 'react'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import { useGetLocation } from 'state/queries'
import { useTranslation } from 'react-i18next'
import { EmployeeEditorForm } from 'views/Settings/Employees/EmployeeEditor/EmployeeEditorForm'
import { useMutation } from '@apollo/client'
import {
  resourceAddGql,
  locationGetGql,
  inviteUserGql,
  getResourceLocationSettingsGql,
  updateResourceLocationSettingsGql,
  resourceSearchGql,
} from 'state/gql'
import { formatPhone } from 'src/helpers'
import { useAppServices } from 'sdk/appServices'
import { useSdk } from 'src/sdk'

const EmployeeValidationSchema = t =>
  Yup.object().shape({
    firstName: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
    lastName: Yup.string().required(
      t('translation.NewResource.validation-last-name-required')
    ),
    role: Yup.string().required(
      t('translation.NewResource.validation-role-required')
    ),
    gender: Yup.string().required(
      t('translation.NewResource.validation-gender-required')
    ),
  })

export const NewEmployee = ({ hasOnlineBooking, setOpenBillingModal }) => {
  const { selectedLocationId, orgId } = useParams<any>()
  const location = useGetLocation(selectedLocationId)
  const [blob, setBlob] = useState<string | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  const [addNewResourceMutation, { loading: loadingAddResource }] =
    useMutation(resourceAddGql)
  const { navigateTo } = useSdk()
  const [inviteUserMutation, { loading: loadingInviteUser }] =
    useMutation(inviteUserGql)
  const [isBusy, setIsBusy] = useState(false)

  const { t } = useTranslation()
  const appServices = useAppServices()

  function getInvitationWarningHintText() {
    return t('translation.NewResource.label-resource-added', {
      locationName: location?.name,
    })
  }

  const [
    updateOnlineBookingSettingsMutation,
    { loading: loadingUpdateBooking },
  ] = useMutation(updateResourceLocationSettingsGql)

  const handleSubmitBookingChange = async values => {
    await updateOnlineBookingSettingsMutation({
      variables: {
        resourceId: values.id,
        locationId: selectedLocationId,
        onlineBooking: hasOnlineBooking ? values.onlineBooking : 'DISABLED',
      },
      refetchQueries: [
        {
          query: getResourceLocationSettingsGql,
          variables: { resourceId: values.id, locationId: selectedLocationId },
        },
        { query: locationGetGql, variables: { id: selectedLocationId } },
      ],
    })
  }

  const handleSubmit = async values => {
    try {
      setIsBusy(true)
      const input = {
        code: values.email || '*',
        name: `${values.firstName} ${values.lastName}`,
        firstName: values.firstName,
        lastName: values.lastName,
        email: !values.email ? null : values?.email?.trim(),
        mobilePhone: formatPhone(values.mobilePhone),
        professions: values?.professions
          ? values?.professions?.map(e => e.id)
          : [],
        address: values.address,
        gender: values.gender,
        role: values.role,
        color: values.color,
        nickName: values.nickName,
        addToLocations: [selectedLocationId],
        showInCalendar: values.showInCalendar,
        receiveAppointmentEmails: values.receiveAppointmentEmails,
        kind: 'USER',
        description: values.description,
      }
      const result = await addNewResourceMutation({
        variables: { input },
        refetchQueries: [{ query: resourceSearchGql }],
        awaitRefetchQueries: true,
      })
      if (values.sendInviteViaEMail) {
        const resource = result.data.resource.add.payload
        const invitationInput = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: !values.email ? null : values?.email?.trim(),
          mobilePhone: formatPhone(values.mobilePhone),
          address: values.address,
          gender: values.gender,
          emailInvite: true,
          smsInvite: false,
          addToLocations: [],
          role: values.role,
          nickName: values.nickName,
          resourceId: resource.id,
        }
        await inviteUserMutation({ variables: { input: invitationInput } })
      }
      if (!result.errors) {
        if (blob) {
          const formData = new FormData()
          // file must be appended last for fastify-multipart to work
          formData.append('locationId', selectedLocationId)
          formData.append(
            'resourceId',
            result?.data?.resource?.add?.payload?.id
          )
          formData.append('file', blob)

          const response = await fetch(
            `${
              import.meta.env.VITE_API_URL
            }/api/upload/${orgId}/employeeAvatarUpload`,
            {
              method: 'POST',
              body: formData,
              credentials: 'include',
            }
          )
          const resultJson = await response.json()

          if (!response.ok || !resultJson?.success) {
            appServices.toast.danger(
              t('translation.AppointmentAttachments.uploadWarning')
            )
          }
        }
        await handleSubmitBookingChange({
          ...values,
          id: result.data?.resource?.add.payload?.id,
        })
        navigateTo.employeesList({ selectedLocationId, selectedTab: 0 })
        appServices.toast.success(
          t('translation.NewResource.toast-employee-saved', {
            firstName: values.firstName,
            lastName: values.lastName,
          })
        )
      } else {
        appServices.toast.danger(result?.errors?.[0]?.message)
      }
    } finally {
      setIsBusy(false)
    }
  }
  return (
    <EmployeeEditorForm
      initialValues={{
        onlineBooking: 'ALLOWED',
        showInCalendar: true,
        receiveAppointmentEmails: false,
      }}
      setOpenBillingModal={setOpenBillingModal}
      hasOnlineBooking={hasOnlineBooking}
      emailInvitationHintWarning={getInvitationWarningHintText()}
      validationSchema={EmployeeValidationSchema(t)}
      onSubmit={handleSubmit}
      showEmailInvitationHint={true}
      setBlob={setBlob}
      previewUrl={previewUrl}
      setPreviewUrl={setPreviewUrl}
      loading={
        loadingInviteUser ||
        loadingAddResource ||
        loadingUpdateBooking ||
        isBusy
      }
    />
  )
}
export default NewEmployee
