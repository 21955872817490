import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  fill: none;
`

export const NewLocation = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M3.5 11.3v10.5c0 .3.2.5.5.5h7M.5 10.8L11.3.9c.4-.3 1-.3 1.3 0l10.8 9.9"
        fill="none"
      ></path>
      <path
        d="M11 8.3c-.3 0-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5h-2zM21.7 13.1c2.3 2.3 2.3 6.1 0 8.5-2.3 2.3-6.1 2.3-8.5 0-2.3-2.3-2.3-6.1 0-8.5 2.4-2.4 6.2-2.4 8.5 0M17.5 14.3v6M20.5 17.3h-6"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
