import styled from 'styled-components'
import { device } from '../../../constants'

export const OrganizationAvatarRow = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
`

export const OrganizationAvatar = styled.img`
  width: 360px;
  max-width: 100%;
  height: 193px;
  border-radius: 0.286rem;
  margin-right: 1.714rem;
  object-fit: ${props =>
    props.src.includes('noImage.svg') ? 'contain' : 'cover'};
  border: 1px solid ${props => props.theme.colors.outline};
`

export const OrganizationSubtitle = styled.span`
  font-size: 14px;
  line-height: 1.571rem;
  color: ${props => props.theme.colors.text};
  margin-bottom: 0.857rem;
  margin-top: 1.714rem;
  font-weight: 500;
`

export const OrganizationSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const OrganizationAvatarButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  button {
    padding: 0;
  }
`

export const OrganizationContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;

  @media ${device.tablet} {
    padding: unset;
    margin-top: unset;
  }
`

export const OrganizationsFormsContainer = styled.div`
  @media ${device.tablet} {
    padding: 10px 20px;
    overflow: auto;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
