import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const CashRegister = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 18.5h23v4a1 1 0 01-1 1h-21 0a1 1 0 01-1-1s0 0 0 0v-4zM5.5 8.5h-.791 0a1 1 0 00-.943.67L.5 18.5h23l-1.835-5.811"></path>
        <path d="M8.5 10.5h-3v-5l1.5 1 1.5-1v5zM12.5 6.5h7a1 1 0 011 1v3h-9v-3h0a1 1 0 011-1zM13.5 3.5a1 1 0 01-1-1v-1a1 1 0 011-1h5a1 1 0 011 1v1a1 1 0 01-1 1zM8.5 8.5h3M5 10.5h4M16 3.5v3M10 21.5h4M4.5 15.5h1M7.5 15.5h1M10.5 15.5h1M7.5 13.5h1M10.5 13.5h1M15 15.5h1M15 13.5h1M18 15.5h1M18 13.5h1"></path>
      </g>
    </StyledIcon>
  )
}
