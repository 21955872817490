import styled from 'styled-components'
import { device } from '../../constants'

export const UpgradeImage = styled.img`
  margin-bottom: 30px;
  margin-left: 10px;
  margin-top: 10px;
  width: 100%;

  @media (max-width: 1600px) {
    width: 114.285714286%;
  }
  @media ${device.laptop} {
    object-fit: none;
    width: 151.285714%;
  }

  @media (max-width: 1020px) {
    object-fit: none;
    width: 161.285714%;
  }
  @media ${device.tablet} {
    margin: 0;
    width: 100%;
    object-fit: inherit;
  }
  @media ${device.mobileM} {
    width: 120%;
    object-fit: inherit;
  }
`
