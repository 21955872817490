import React from 'react'
import { cls } from 'src/helpers'

export const FieldColumn = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}) => {
  return (
    <div
      className={cls(
        `w-full mb-4  last:pr-0 flex flex-1 lg:mb-0 lg:pr-0 lg:mr-4 last:mr-0 ${
          className ? className : ''
        }`
      )}
      style={style}
    >
      {children}
    </div>
  )
}
