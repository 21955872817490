import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { fontFamily } from './components/style/fonts'
import { AvatarWrapper } from './components/Avatar/Avatar'
import { NavBarStyled } from './components/NavBarMenu/style'
import { ButtonStyled } from './components/Button/Button'
import { DropdownWrapper } from './components/Dropdown/styles'
import { device } from './constants'
import transparentize from 'polished/lib/color/transparentize'
import { TabContentContainer } from './components/TabView/TabView'
import { ButtonLabel } from './components/Button/style'
import { SelectStyled } from './components/Select/style'
import { animated } from 'react-spring'
import { Menu } from 'components/Icon/Icons/Menu'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
export let vh = window.innerHeight * 0.01
export let vw = window.innerWidth * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)
document.documentElement.style.setProperty('--vw', `${vw}px`)

export const eventRowHeight = 20
export const workScheduleRowHeight = 4
export const employeeScheduleRowHeight = 8

export const AppContainer = styled.div<any>`
  color: ${props => props.theme.colors.text};
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const AppWrapper = styled.div<any>`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: auto;
`

export const NewAppointmentButton = styled(animated.div)<any>`
  position: absolute;
  bottom: 20px;
  right: 24px;
  cursor: pointer;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.light};
  width: 60px;
  height: 60px !important;
  border-radius: 100px;
  box-shadow: 3px 3px 10px 1px #0000003d;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    right: 20px;
  }
`

export const ClientAppWrapper = styled.div<any>`
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
`

export const LeftWrapper = styled.div<any>`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  position: relative;
  border-left: 1px solid #e8ecef;
  box-shadow: 4px 0px 10px 0px #80808070;
  background: ${props => props.theme.colors.light};

  @media ${device.tablet} {
    border-left: none;
    box-shadow: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const ClientBodyWrapper = styled.div<any>`
  margin: 0 auto;
  width: 100%;
  position: relative;

  @media ${device.tablet} {
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px 20px;
    overflow: auto;
  }
`

type TRouteContentContainerProps = {
  hasFooter?: boolean
}
export const RouteContentContainer = styled.div<TRouteContentContainerProps>`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  @media ${device.tablet} {
    max-height: unset;
    height: unset;
    overflow: ${props => (props.hasFooter ? 'unset' : 'hidden')};
  }
`
type TAppBodyProps = {
  minHeight?: any
}
export const AppBody = styled.div<TAppBodyProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: ${props => (!props.minHeight ? '100%' : props.minHeight)};

  @media ${device.tablet} {
    flex-direction: column;
    display: flex;
  }
`

export const ClientAppBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  @media ${device.tablet} {
    height: 100%;
    flex-direction: column;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`
type TContainerProps = {
  isInitial?: boolean
  style?: any
}
export const Container = styled.div<TContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: ${props => (props.isInitial ? 'initial' : 'relative')};
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 0px 15px;
  }
`

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;

  @media ${device.tablet} {
    &::-webkit-scrollbar {
      display: none;
    }

    ${TabContentContainer} {
      padding: 10px;
    }
  }
`

export const FormsContainer = styled.div`
  @media ${device.tablet} {
    padding: 10px 0px;
  }
`

export const SettingsLabel = styled.span`
  width: 100%;
`

export const SettingsHeader = styled.div`
  display: flex;
  padding: 5px 0px 0px 30px;
  width: 100%;
  border-bottom: 1px solid #edeef4;
  font-size: 20px;
  font-weight: 600;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  max-height: 57px;
  min-height: 57px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 9999;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FooterButton = styled.div`
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1.429rem 1.429rem;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 0.714rem 0.714rem;
  background: ${props => props.theme.colors.lightBackground};
  border-top: 1px solid ${props => props.theme.colors.outline};
  button:first-child {
    margin-right: 1.429rem;
  }
`

export const FooterButtonMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 1.071rem;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 0.714rem 0.714rem;
  border-top: 1px solid ${props => props.theme.colors.outline};
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  opacity: 0;
  left: 0;
  &.show {
    opacity: 1;
  }

  ${ButtonStyled} {
    height: 40px !important;
    font-size: 14px !important;
  }
`

export const FooterButtonsMobileBottom = styled(FooterButtonMobile)`
  padding: 0.714rem 1.071rem;
  z-index: 99999999999;
  background: white;
  height: 60px;
`

export const HeaderUserWrapperMobile = styled.div`
  position: absolute;
  left: 0.714rem;
  right: auto;
  display: flex;
  align-items: center;

  ${AvatarWrapper} {
    margin-right: 0.714rem;
  }
`

export const LogoIconMobile = styled.img`
  width: 130px;
`

export const LogoHeaderMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.light};
`

export const HeaderMobile = styled.div`
  width: auto;
  position: relative;
  padding: 1rem 1.143rem;
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  background: ${props => props.theme.colors.primary};

  ${NavBarStyled} {
    position: absolute;
    right: 0.714rem;
  }

  ${DropdownWrapper} {
    position: absolute;
    padding: 0px;
    right: 1.429rem;
    width: auto;

    button:first-child {
      left: 0px;
      padding-right: 1.143rem;
      padding-left: 1.143rem;
      position: relative;
      font-weight: 500;
      border: inherit;
      color: ${props => props.theme.colors.primary} !important;
      height: inherit;
    }
  }

  button:first-child {
    position: absolute;
    padding: 0px;
    left: 1.429rem;

    span {
      font-size: 30px;
    }
  }

  button:last-child {
    position: absolute;
    right: 0.714rem;
  }
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0.286rem auto;
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
    font-style: normal;
    font-size: 1rem;
    line-height: 27px;
    text-align: center;
  }
`

export const ListItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Footer = styled.div`
  width: 100%;

  @media ${device.tablet} {
    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.1);
    min-height: 65px;
    max-height: 65px;
    height: 65px;
    z-index: 9;
  }
`

type TNavigationButtonsProps = {
  isMobile: boolean
}
export const NavigationButtons = styled.div<TNavigationButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${props => (props.isMobile ? '0' : '1.429rem')};
  height: 60px;
  @media ${device.tablet} {
    height: 60px;
    max-height: 60px;
    overflow: hidden;
  }
`

export const ArrowButton = styled.div`
  display: flex;
  align-content: center;
  margin-left: 10px;
  button:first-child {
    margin-right: 0.571rem;
  }
  button:last-child {
    margin-left: 0px !important;
  }
`

export const NavigationItemWrapper = styled.div``

type TNavTitleProps = {
  isSelected: boolean
}
export const NavTitle = styled.span<TNavTitleProps>`
  color: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.text};
  font-size: 8px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0.2px;
`

export const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const NavigationItem = styled(NavLink)<{
  isSelected?: boolean
  iconColor?: string
  theme?: string
}>`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  min-height: 50px;
  transition: background 0.1s ease-in-out;
  :hover {
    background: ${props => (props.isSelected ? undefined : 'rgb(220 221 229)')};
  }
  color: ${props => props.theme.colors.text};
  width: 100%;
  margin-bottom: 5px;
  svg {
    color: ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : !!props.iconColor
        ? props.iconColor
        : props.theme.colors.secondaryDark};
  }
  svg.chavron {
    margin-right: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background: ${props =>
      props.isSelected ? 'rgb(220 221 229)' : 'transparent'};
    .nav-title {
      font-weight: bold;
    }
  }
  @media ${device.tablet} {
    margin-left: 0px;
    margin: 0px 1rem;
    padding: 15px 0px;
    min-height: 60px;
    width: auto;
    &.active {
      background: initial;
    }
    svg {
      color: ${props =>
        props.isSelected
          ? props.theme.colors.primary
          : props.theme.colors.text};
    }
  }
`

type TNavigationItemSmallerProps = {
  to: string
  isSelected?: boolean
}
export const NavigationItemSmaller = styled(
  NavigationItem
)<TNavigationItemSmallerProps>`
  min-height: 40px;
`

type TNavigationTitleProps = {
  name: string
  className?: string
  style?: any
  isSelected?: boolean
  isSubItem?: boolean
  id?: any
}
export const NavigationTitle = styled.span<TNavigationTitleProps>`
  font-weight: ${props => (props.isSelected ? '600' : '400')};
  font-size: 13px;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  margin-right: auto;
  max-width: ${props => (props.isSubItem ? '100px' : '100%')};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
  color: ${props => props.theme.colors.text};

  @media ${device.tablet} {
    font-weight: 500;
    font-size: 1.15rem;
  }
`

type TNavigationTitleSmallerProps = {
  name: string
  isSelected: boolean
}
export const NavigationTitleSmaller = styled(
  NavigationTitle
)<TNavigationTitleSmallerProps>`
  font-size: 12px;
  margin-left: 20px;
`

type TFooterWrapperProps = {
  isMobile: boolean
}
export const FooterWrapper = styled.div<TFooterWrapperProps>`
  background: ${props => props.theme.colors.light};
  height: 100%;

  ${NavigationItem} {
    margin-top: 7px;
  }
`

type TNavigationNumberProps = {
  style?: any
  name: string
}
export const NavigationNumber = styled.div<TNavigationNumberProps>`
  width: 20px;
  height: 20px;
  background: ${props => props.theme.colors.accent5};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  right: 0px;
  top: -5px;

  span {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.colors.light};
  }
`

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.429rem;
  a:first-child {
    margin-top: 0px !important;
  }
`

export const PageWrapper = styled.div<{}>`
  display: flex;
  width: 100%;
  background: ${props => props.theme.colors.light};
  border-radius: 0.714rem;
  box-shadow: 6px 8px 10px 0px #00000017;
  position: relative;
  border-radius: 10px;
  height: 100%;
`

export const TableHeaderItem = styled.div<any>`
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  font-weight: ${props => (props.isHomeTable ? '400' : '600')};
  text-align: left;
  padding: 10px 14px;
  background: ${props =>
    props.isHomeTable ? 'transparent' : props.theme.colors.mainBackground};
  border-bottom: ${props =>
    props.isHomeTable ? '1px solid ' + props.theme.colors.outline : 'none'};
  text-transform: ${props => (props.isHomeTable ? 'uppercase' : 'none')};
  position: sticky;
  top: 0px;
  z-index: 1;

  span {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .resizer {
    display: inline-block;
    background: ${props => props.theme.colors.light};
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: ${props => props.theme.colors.accent5};
    }
  }
`
export const StyledMenuIcon = styled(Menu)<{ size: string }>`
  color: ${props => props.theme.colors.secondaryDark}!important;
  cursor: move !important;
`
export const TableItem = styled.div<{
  isHomeTable?: boolean
  isDefaultPointer?: boolean
  hasAvatar?: boolean
}>`
  cursor: ${props => (props.isDefaultPointer ? 'default' : 'pointer')};
  font-style: normal;
  font-weight: ${props => (props.isHomeTable ? '400' : '500')};
  font-size: ${props => (props.isHomeTable ? '13px' : '1rem !important')};
  line-height: 1.429rem;
  color: ${props => props.theme.colors.text} !important;
  font-family: ${fontFamily};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 35px;

  ${AvatarWrapper} {
    float: ${props => (props.hasAvatar ? 'left' : 'none')};
  }

  span {
    float: ${props => (props.hasAvatar ? 'left' : 'none')};
    margin-left: ${props => (props.hasAvatar ? '10px' : 'inherit')};
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const TableRow = styled.div<{
  isSelected?: boolean
  isDisabled?: boolean
  isExpired?: boolean
}>`
  background: ${props =>
    props.isSelected ? props.theme.colors.mainBackground : null};
  &:hover {
    background: ${props =>
      props.isDisabled
        ? 'transparent'
        : transparentize(0.5, props.theme.colors.mainBackground)};
  }

  @media ${device.tablet} {
    :hover {
      background: transparent;
    }
  }

  ${TableItem} {
    color: ${props =>
      props.isExpired
        ? props.theme.colors.secondaryDark
        : props.theme.colors.text} !important;
  }
`

type THelpHintProps = {
  style?: any
}
export const HelpHint = styled.div<THelpHintProps>`
  color: ${props => props.theme.colors.secondaryDark};
  font-size: 0.857rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-style: italic;
  padding-top: 20px;

  span {
    margin-right: 0.286rem;
  }

  a {
    margin-left: 0.286rem;
    color: ${props => props.theme.colors.primary};
  }
`
type TContainerRowProps = {
  style?: any
}
export const ContainerRow = styled.div<TContainerRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 0px;
  }
`

export const ContainerRowDays = styled(ContainerRow)<{ style?: any }>`
  margin-bottom: 0px;
  @media ${device.tablet} {
    flex-direction: row;
    padding-bottom: 20px;
  }
`
export const ContainerRowShifts = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
`
export const ContainerColumnShifts = styled.div<{}>`
  display: flex;
  flex: 1;

  &:not(:last-child) {
    margin-right: 14px;
  }
`
export const ContainerColumn = styled.div<{ style?: any }>`
  display: flex;
  flex: 1;

  &:not(:last-child) {
    margin-right: 14px;
  }

  ${DropdownWrapper} {
    margin-right: 0px;

    button {
      margin-right: 0px !important;
    }
  }
  @media ${device.tablet} {
    width: 100%;

    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 0px;
    }
  }
`

export const ModalBodyContainer = styled.div<{ isAppointmentModal?: boolean }>`
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    display: inline;
    //overflow: auto;
    max-height: initial;
    height: ${props => (props.isAppointmentModal ? '100%' : null)};

    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const FormPageContainer = styled.div<{ style?: any }>`
  max-height: 100%;
  overflow: auto;

  @media ${device.tablet} {
    padding: 0px 15px;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const FormContainer = styled.div<{
  isMobile?: boolean
  isFullHeight?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => (props.isMobile ? '15px' : '20px')};
  height: ${props => (props.isFullHeight ? '100%' : 'auto')};

  @media ${device.tablet} {
    display: initial;
    height: 100%;
    padding: 20px 20px 20px 10px;
  }
`

export const ListBodyWrapper = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.light};
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media ${device.tablet} {
    &::-webkit-scrollbar {
      display: none;
    }
    min-height: 0px;
    flex: 1;
    display: block;
    overflow: initial;
  }
`
type TListContainerProps = {
  style?: any
  ref?: any
}
export const ListContainer = styled.div<TListContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  background: ${props => props.theme.colors.light};
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 10px 0px !important;
    overflow: hidden;
  }
`
export const UserAvatarWrapper = styled.div<{ style?: any }>`
  padding-bottom: 27px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const UserAvatar = styled.div<{}>`
  width: 112px;
  height: 112px;
  border: 3px solid ${props => props.theme.colors.primary};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  img {
    width: 112px;
    height: 112px;
    border-radius: 100px;
    object-fit: cover;
  }
`
export const UserName = styled.span<{}>`
  font-weight: 600;
  font-size: 20px;
  color: ${props => props.theme.colors.text};
  line-height: 35px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`
export const NavigationButton = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  color: ${props => props.theme.colors.text};
  width: calc(100% - 40px);
  margin-left: 0px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 20px 0;
  min-height: 60px;

  @media ${device.tablet} {
    margin-left: 0px;
    margin: 0px 1rem;
    padding: 15px 0px;
    min-height: 60px;
    //border-bottom: 1px solid #dbdfe2;
    border-bottom: none;
    width: auto;
  }
`

export const DesktopLocationTextBody = styled.div`
  display: flex;
`
export const DesktopLocationContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  position: relative;
  border-radius: 10px;
`
export const DesktopLocationContentWrapper = styled.div`
  transform: translate3d(0px, 0px, 0px);
  background: ${props => props.theme.colors.light};
  padding: 20px 0px 50px 50px;
  position: relative;
  max-width: 1220px;
  margin: auto;
  width: 100%;

  @media ${device.tablet} {
    padding: 0px;
  }
`
export const GalleryRow = styled.div<{
  style: any
  isSmall?: any
}>`
  display: flex;
  position: relative;
  width: 100%;
  height: ${props => (props.isSmall ? '250px' : '350px')};
  background: white;
  padding-bottom: 20px;
  max-width: 1220px;
  margin: auto;

  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`
export const GalleryName = styled.h1<{
  style: any
  isFullHeight?: boolean
}>`
  font-size: ${props => (props.isFullHeight ? '40px' : '32px')};
  line-height: ${props => (props.isFullHeight ? '50px' : '42px')};
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  padding-bottom: ${props => (props.isFullHeight ? '8px' : '0')};
  margin: 0px;
`
export const CategoryServicesList = styled.div<any>`
  margin-left: ${props => (props.isMobile ? '0' : '20px')};
  display: flex;
  flex-direction: column;
  flex: 1;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
`
export const CategoryServicesListRight = styled(CategoryServicesList)<{
  ref?: any
}>`
  max-width: calc(100% - 300px);

  @media ${device.tablet} {
    max-width: 100%;
    width: 100%;
  }
`
export const DesktopLocationsContainer = styled.div<{ style?: any }>`
  position: relative;
  width: 100%;
  padding: 20px 0px;
  margin: 0px auto;
  overflow: auto;
  @media ${device.tablet} {
    display: block !important;
    padding: 0px;
  }
`
export const DesktopLocationsGrid = styled.div<{}>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 30px;
  margin: auto;
  padding-bottom: 60px;
  width: 100%;

  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${device.tablet} {
    padding: 0px;
    padding-bottom: 60px;
    grid-template-columns: repeat(1, 100%);
    column-gap: 10px;
    margin-top: 20px;
    height: auto;
  }
`
export const DesktopLocationsItem = styled.div<{
  onClick: () => {}
  key?: any
}>`
  display: flex;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  border-width: 1px;
  border-color: rgb(210, 210, 245);
  border-radius: 10px;
  box-shadow: rgba(210, 210, 245, 0.6) 0px 5px 20px 0px;
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: ease;
  max-height: 400px;
  cursor: pointer;
  background: ${props => props.theme.colors.light};

  :hover {
    transform: translate(0px, -5px);
  }

  @media ${device.tablet} {
    background: transparent;
    box-shadow: none;
  }
`
export const DesktopLocationsLink = styled.a<{
  onClick?: () => {}
}>`
  display: flex;
  height: 100%;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  transition: transform 330ms ease-in-out;
  :active {
    transform: scale(0.93);
  }
`

export const DesktopLocationImage = styled.img<{ src?: string }>`
  object-fit: cover;
  display: flex;
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0px 0px;
  background-position: center;
  border: 1px solid #e5e5e5;
  object-fit: ${props =>
    props?.src?.includes('noImage.svg') ? 'contain' : 'cover'};
  @media ${device.tablet} {
    border-radius: 4px;
    height: 180px;
  }
`
export const DesktopLocationsImageDesc = styled.div<{}>`
  display: flex;
  height: auto;
  padding: 20px 20px 0px;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 0%;
  color: rgb(102, 102, 102);
  text-decoration: none;
  width: 100%;

  @media ${device.tablet} {
    padding: 10px 0px;
  }
`
export const DesktopLocationTag = styled.div<{}>`
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
`
export const DesktopLocationCardTitle = styled.p<{ style?: any }>`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  color: ${props => props.theme.colors.text};
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 0px;
  overflow: hidden;
`
export const DesktopLocationCardSubtitle = styled.p<{
  isSafari?: boolean
  isFirefox?: boolean
}>`
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.text};
  margin: 0px 0px 2px;
  font-family: 'DM Sans', serif;
  margin-bottom: 20px;
  &:first-child {
    color: red;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`
export const DesktopLocationLink = styled.div<{
  style?: any
  onClick?: () => {}
}>`
  margin-top: auto;
  margin-bottom: 20px;
  text-decoration: underline;
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
  font-family: 'DM Sans', serif;
  @media ${device.tablet} {
    display: none;
  }
`

export const NavigationContainer = styled.div<{}>`
  will-change: background;
  background-color: ${props => props.theme.colors.primary};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: auto;
  z-index: 99999;
  display: block;
  width: 100%;
  height: auto;
  padding: 10px 0;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(46, 50, 60, 0.09);

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    height: 70px;
    min-height: 70px;
    position: sticky;
  }
`

export const NavigationWrapper = styled.div<{}>`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 999;
  display: block;
  max-width: 1220px;
  margin-top: 0;
  margin-left: auto;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  position: relative;
  width: 100%;
  margin-right: auto;
`

export const SelectWrapper = styled.div<{
  style?: any
  isRight?: boolean
}>`
  ${SelectStyled} {
    left: ${props => (props.isRight ? '-190px' : 'inherit')};
  }
`
export const NavigationLeftColumn = styled.div<{}>`
  display: flex;
  margin-right: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: left;
  align-items: center;

  ${ButtonLabel} {
    font-weight: 700;
    font-family: 'DM Sans', serif;
    font-size: 17px;
    letter-spacing: -0.5px;
    padding: 0px 8px;
  }

  @media ${device.tablet} {
    button {
      display: none;
    }
  }
`

export const NavigationLogo = styled.a<{}>`
  float: left;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  text-align: left;

  @media ${device.tablet} {
    width: 150px;
    margin: auto;
    margin-left: 20px;
  }
`

export const NavigationLogoImage = styled.img<{
  onClick: () => {}
  src: string
}>`
  will-change: opacity;
  opacity: 1;
  width: 110px;
  margin-left: 10px;
  height: auto;
  max-width: 100%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
`

export const NavigationNav = styled.nav<{}>`
  display: flex;
  margin-left: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  float: right;
  position: relative;

  @media ${device.tablet} {
    display: none;
  }
`

export const NavigationNavLink = styled.span<{
  isDark?: boolean
  onClick?: () => {}
  isActive?: boolean
}>`
  color: ${props =>
    props.isDark
      ? props.theme.colors.light
      : props.isActive
      ? props.theme.colors.text
      : '#000'};
  max-width: 1220px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  display: block;
  line-height: 26px;
  padding: 15px 20px;
  font-size: 18px;
  line-height: 26px;
  font-weight: ${props => (props.isActive ? 600 : 400)};
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  vertical-align: top;
  text-decoration: none;
  background-color: transparent;
`
export const ContainerForFixedFooterButtons = styled(Container)<{}>`
  @media ${device.tablet} {
    height: 100%;
    padding: 10px 20px;
  }
`
export const FormFooterButtonsContainerMobile = styled.div<any>`
  @media ${device.tablet} {
    margin: 0;
    padding: 15px;
    min-height: 85px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    z-index: 9;
    position: relative;

    ${ButtonStyled} {
      height: 50px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      :first-child {
        margin-right: 10px;
      }
    }
  }
`
export const Badge = styled.div<{
  style?: any
  green?: boolean
}>`
  background-color: ${props =>
    props.green ? props.theme.colors.primary : props.theme.colors.accent5};
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  color: white;
  width: fit-content;
`
