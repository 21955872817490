import React from 'react'

export function useIdle(seconds: number) {
  const [idle, setIdle] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (seconds === 0) return
    let timeoutId

    const handleTimeout = () => {
      setIdle(true)
    }

    const handleEvent = throttle(e => {
      //setIdle(false)

      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(handleTimeout, seconds * 1000)
    }, 500)

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleEvent()
      }
    }

    timeoutId = window.setTimeout(handleTimeout, seconds * 1000)

    window.addEventListener('mousemove', handleEvent)
    window.addEventListener('mousedown', handleEvent)
    window.addEventListener('resize', handleEvent)
    window.addEventListener('keydown', handleEvent)
    window.addEventListener('touchstart', handleEvent)
    window.addEventListener('wheel', handleEvent)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('mousemove', handleEvent)
      window.removeEventListener('mousedown', handleEvent)
      window.removeEventListener('resize', handleEvent)
      window.removeEventListener('keydown', handleEvent)
      window.removeEventListener('touchstart', handleEvent)
      window.removeEventListener('wheel', handleEvent)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.clearTimeout(timeoutId)
    }
  }, [seconds])

  return { idle, reset: () => setIdle(false) }
}
function throttle(cb, ms) {
  let lastTime = 0
  return () => {
    const now = Date.now()
    if (now - lastTime >= ms) {
      cb()
      lastTime = now
    }
  }
}
