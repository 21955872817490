import styled from 'styled-components'

export const EmptyContainer = styled.div.attrs(() => ({
  'data-cy': 'empty-list-container',
}))`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  flex: 1;
`

export const EmptyText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 14px;
  color: ${props => props.theme.colors.secondaryDark};
  text-align: center;
`

export const EmptyImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
`
