import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import moment from 'moment'
import { device } from '../../../constants'
import {
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
  changeServiceStartTime,
  changeServiceDurations,
  formatPriceNumberWithDelimiter,
  roundNumberWithTwoDecimals,
  getServiceAmounts,
} from '../../../helpers'
import { useSdk } from 'sdk'
import { icons } from 'components'
import { Button, Loader } from 'components'
import { StartTimeSelect } from 'views/Appointment/components/StartTimeSelect'
import { DurationSelect } from 'views/Appointment/components/DurationSelect'
import { ServiceSelect } from 'views/Appointment/components/ServiceSelect'
import { EmployeeSelect } from 'views/Appointment/components/EmployeeSelect'
import { ButtonStyled } from 'components/Button/Button'
import {
  ServiceDiscount,
  StyledDiscountInput,
} from 'src/views/CashRegister/NewReceipt/styles'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { trpc } from 'src/trpc'
import { useParams } from 'react-router'
import {
  TServiceProps,
  TServiceStartTimeProps,
} from 'src/views/Appointment/components/Service'
import { ResourceTypesSelect } from 'src/views/Appointment/components/ResourceTypesSelect'

type TServiceFormProps = {
  isMobile: boolean
  isNew?: boolean
  resources: any[]
  flattenedServices: TServiceProps[]
  values: any
  isLoading: boolean
  timeSlotDuration: number
  services: any[]
  setFieldValue: (arg0: string, arg1: any) => void
}
export const AppointmentHotelService = (props: TServiceFormProps) => {
  const {
    isMobile,
    resources,
    flattenedServices,
    values,
    isLoading,
    timeSlotDuration,
    setFieldValue,
    services,
    isNew,
  } = props

  // here we should check which resources are available for the selected service

  // const employees = workingEmployees(values.date, values.startTime?.id)
  const resource = resources?.find(x => x.id === values.resourceId)
  //const resources = workingResources(values.date, values.startTime?.id)

  const selectedService = flattenedServices?.find(
    x => x.id === values?.serviceId
  )
  const { t } = useSdk()

  const isStatusDraft = values.status === 'DRAFT'
  const selectedTheme = useContext(ThemeContext)

  const filterEmployeeOptions = (
    candidate: {
      data: any
      label: string
      value: string
    },
    input: string
  ) => {
    return input
      ? candidate?.data?.typeName
          ?.toLowerCase()
          ?.includes(input.toLowerCase()) ||
          candidate?.data?.firstName
            ?.toLowerCase()
            ?.includes(input.toLowerCase()) ||
          candidate?.data?.lastName
            ?.toLowerCase()
            ?.includes(input.toLowerCase()) ||
          candidate?.data?.nickName
            ?.toLowerCase()
            ?.includes(input.toLowerCase()) ||
          candidate?.data?.name?.toLowerCase()?.includes(input.toLowerCase())
      : true
  }

  const filterOptions = (
    candidate: {
      data: any
      label: string
      value: string
    },
    input: string
  ) => {
    if (input)
      return candidate?.data?.name?.toLowerCase()?.includes(input.toLowerCase())

    return true
  }

  const [inputValue, setInputValue] = React.useState<number | undefined>()
  useEffect(() => {
    setInputValue(
      values?.discountValue === 0 || !values?.discountValue
        ? undefined
        : values?.discountValue
    )
    setSelectedItemDiscountType(values?.discountType || 'percent')
  }, [values.discountValue, values?.discountType])

  const [discountType, setSelectedItemDiscountType] = useState<
    'percent' | 'amount'
  >(values?.discountType || 'percent')

  const [totalPrice, setTotalPrice] = useState(values?.totalPrice)

  const updateDiscount = e => {
    const {
      discountAmount,
      totalAmount,
      discountRate,
      discountValue,
      totalPrice,
    } = getServiceAmounts({
      price: values?.price,
      quantity: values?.quantity,
      discountValue: e.target.value,
      discountType: discountType,
    })

    setInputValue(e.target.value)
    setTotalPrice(totalPrice)

    setFieldValue(`totalPrice`, totalPrice)
    setFieldValue(`discountType`, discountType)
    setFieldValue(`totalAmount`, totalAmount)
    setFieldValue(`discountRate`, discountRate)
    setFieldValue(`discountAmount`, discountAmount)
    setFieldValue(`discountValue`, discountValue)
  }

  const updateDiscountType = type => {
    const discountInputValue = inputValue ? inputValue : 0

    const {
      discountAmount,
      totalAmount,
      totalPrice,
      discountRate,
    } = getServiceAmounts({
      price: values?.price,
      quantity: values?.quantity,
      discountValue: discountInputValue,
      discountType: type,
    })

    setSelectedItemDiscountType(type)
    setTotalPrice(totalPrice)

    setFieldValue(`totalPrice`, totalPrice)
    setFieldValue(`discountType`, type)
    setFieldValue(`totalAmount`, totalAmount)
    setFieldValue(`discountRate`, discountRate)
    setFieldValue(`discountAmount`, discountAmount)
    setFieldValue(`discountValue`, discountInputValue)
  }

  const { locationId, orgId } = useParams<{
    locationId: string
    orgId: string
  }>()

  const {
    data: startTimesData,
    isLoading: startTimesLoading,
  } = trpc.startTimeGet.useQuery({
    orgId: orgId,
    locationId: locationId,
    timeSlotDuration: timeSlotDuration,
    employeeId: null,
    date: moment().format('YYYY-MM-DD'),
  })

  useEffect(() => {
    if (values?.resourceId) {
      const resource = resources?.find(
        x => x?.id === values?.resourceId?.toString()
      )

      const preSelectedService = flattenedServices?.find(s => {
        return (
          resource?.professions?.length &&
          s.resourceType?.id &&
          resource?.professions?.find(p => p?.id === s.resourceType?.id)
        )
      })

      if (preSelectedService) {
        const {
          totalAmount,
          totalPrice,
          discountAmount,
          discountRate,
        } = getServiceAmounts({
          price: preSelectedService?.price,
          quantity: values?.quantity,
          discountValue: values?.discountValue,
          discountType: values?.discountType,
        })

        setFieldValue(`serviceId`, preSelectedService?.id)
        setFieldValue(`price`, preSelectedService?.price)
        setFieldValue(`totalPrice`, totalPrice)
        setFieldValue(`totalAmount`, totalAmount)
        setFieldValue(`discountAmount`, discountAmount)
        setFieldValue(`discountRate`, discountRate)

        setTotalPrice(roundNumberWithTwoDecimals(preSelectedService?.price))
      }
    }
  }, [values?.resourceId])

  return (
    <>
      {isLoading || startTimesLoading ? (
        <div style={{ minHeight: '500px' }}>
          <Loader isComponent />
        </div>
      ) : (
        <div className="flex flex-col w-[100%] h-full lg:bg-zoyya-lighterBackground lg:rounded-md lg:px-5 lg:py-3 lg:mb-6">
          <div className=" overflow-hidden flex bg-zoyya-lighterBackground  lg:m-0 px-5 lg:p-0 lg:border-0 lg:bg-transparent border border-zoyya-grayDarker rounded-md">
            <div className=" overflow-auto no-scrollbar relative lg:h-[auto] lg:overflow-visible md:h-[80vh] w-[100%] py-3 lg:py-0">
              <div className="flex-1 overflow-auto">
                <div className="w-[100%] lg:w-[auto] flex-col-reverse lg:flex-row lg:mb-[10px] mb-0 justify-between items-center flex">
                  <div className="flex-[1] w-[100%] lg:mr-[20px] lg:max-w-[180px] mb-[20px] lg:mb-0">
                    <StartTimeSelect
                      name={`startTime`}
                      label={`${t(
                        'translation.StartTimeSelect.pocinje'
                      )} - ${moment(values?.dateFrom).format('ddd DD.MM.')}`}
                      minusBtnDisabled={values?.startTime?.id === '00:00'}
                      minusBtnOnClick={event => {
                        event.stopPropagation()
                      }}
                      plusBtnDisabled={true}
                      plusBtnOnClick={event => {
                        event.stopPropagation()
                      }}
                      hasPlusMinusButtons={false}
                      isDisabled={isStatusDraft}
                      workHours={startTimesData?.slice(0, -1)}
                    />
                  </div>
                  <div className="flex-[1] w-[100%] mb-[20px] lg:mb-0">
                    <EmployeeSelect
                      employees={resources}
                      label={t('translation.ResourceEditorForm.label-resource')}
                      filterOptions={filterEmployeeOptions}
                      name={`resourceId`}
                    />
                  </div>
                </div>

                <div className="w-[100%] lg:w-[auto] flex-col lg:flex-row lg:mb-[10px] mb-0 justify-between items-center flex">
                  <div className="flex-[1] w-[100%] lg:mr-[20px] lg:max-w-[180px] mb-[20px] lg:mb-0">
                    <StartTimeSelect
                      name={`endTime`}
                      label={`${t(
                        'translation.StartTimeSelect.zavrsava'
                      )} - ${moment(values?.dateTo).format('ddd DD.MM.')}`}
                      minusBtnDisabled={values?.startTime?.id === '00:00'}
                      minusBtnOnClick={event => {
                        event.stopPropagation()
                      }}
                      plusBtnDisabled={true}
                      plusBtnOnClick={event => {
                        event.stopPropagation()
                      }}
                      hasPlusMinusButtons={false}
                      isDisabled={isStatusDraft}
                      workHours={startTimesData?.slice(0, -1)}
                    />
                  </div>
                  <div className="flex-[1] w-[100%] mb-[20px] lg:mb-0">
                    <ServiceSelect
                      isDisabled={true}
                      employee={resource}
                      filterOptions={filterOptions}
                      name={`serviceId`}
                      hideAddNewService
                      onNewService={() => ({})}
                      services={services}
                      flattenedServices={flattenedServices}
                    />
                  </div>

                  {/* {!!selectedService && !!selectedService?.resourceType?.id && (
                    <div className="lg:ml-[20px] flex-[1] w-[100%] mb-[20px] lg:mb-0">
                      <ResourceTypesSelect
                        label={
                          service?.resourceType?.name ||
                          t('translation.ResourceEditorForm.label-resource')
                        }
                        resourceTypes={
                          resources?.length && inappropriateResources?.length
                            ? [...resources, ...inappropriateResources]
                            : resources?.length
                            ? resources
                            : inappropriateResources?.length
                            ? inappropriateResources
                            : []
                        }
                        name={`services[${index}].resourceId`}
                      />
                    </div>
                  )} */}
                </div>

                <div className="flex items-end justify-between">
                  {isMobile && selectedService && selectedService?.price > 0 ? (
                    <div className="flex flex-col">
                      <p className="font-medium mt-[5px]">
                        {t('translation.ServicesList.label-price')}
                      </p>
                      <div className="flex gap-3 items-center">
                        <div className="flex flex-col items-end">
                          {inputValue && inputValue !== 0 ? (
                            <p>{`${formatPriceNumberWithDelimiter(
                              values?.totalPrice
                            )} ${values?.currencyId}`}</p>
                          ) : null}
                          <p
                            className={`${
                              inputValue && inputValue !== 0
                                ? 'text-sm text-zoyya-secondaryDark line-through'
                                : ''
                            }`}
                          >{`${formatPriceNumberWithDelimiter(values?.price)} ${
                            values?.currencyId
                          }`}</p>
                        </div>
                        <ServiceDiscount style={{ position: 'unset' }}>
                          <div
                            style={{
                              width: 65,
                              marginRight: 5,
                            }}
                          >
                            <StyledDiscountInput
                              name={`discountValue`}
                              type="text"
                              value={inputValue}
                              onChange={e => updateDiscount(e)}
                              placeholder="popust"
                            />
                          </div>
                          <Button
                            type="button"
                            buttonType={
                              discountType === 'percent'
                                ? 'primary'
                                : 'secondary'
                            }
                            label={'%'}
                            onClick={() => updateDiscountType('percent')}
                            size="small"
                            style={{
                              fontSize: 16,
                              fontWeight: 300,
                            }}
                          />
                          <Button
                            type="button"
                            buttonType={
                              discountType === 'amount'
                                ? 'primary'
                                : 'secondary'
                            }
                            label={values?.currencyId}
                            onClick={() => updateDiscountType('amount')}
                            size="small"
                            style={{ width: 'auto' }}
                          />
                        </ServiceDiscount>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-end justify-between mt-1 pr-0">
            {!isMobile && selectedService && selectedService?.price > 0 ? (
              <>
                <div className="flex flex-col">
                  <p className="font-medium mt-[5px]">
                    {t('translation.ServicesList.label-price')}
                  </p>
                  <div className="flex gap-3 items-center">
                    <div className="flex flex-col items-end">
                      {inputValue && inputValue !== 0 ? (
                        <p>{`${formatPriceNumberWithDelimiter(
                          values?.totalPrice
                        )} ${values?.currencyId}`}</p>
                      ) : null}

                      <p
                        className={`${
                          inputValue && inputValue !== 0
                            ? 'text-sm text-zoyya-secondaryDark line-through'
                            : ''
                        }`}
                      >
                        {`${formatPriceNumberWithDelimiter(values?.price)} ${
                          values?.currencyId
                        }`}
                      </p>
                    </div>
                    <ServiceDiscount style={{ position: 'unset' }}>
                      <div
                        style={{
                          width: 65,
                          marginRight: 5,
                        }}
                      >
                        <StyledDiscountInput
                          name={`discountValue`}
                          type="text"
                          value={inputValue}
                          onChange={e => updateDiscount(e)}
                          placeholder="popust"
                        />
                      </div>
                      <Button
                        type="button"
                        buttonType={
                          discountType === 'percent' ? 'primary' : 'secondary'
                        }
                        label={'%'}
                        onClick={() => updateDiscountType('percent')}
                        size="small"
                        style={{
                          fontSize: 16,
                          fontWeight: 300,
                        }}
                      />
                      <Button
                        type="button"
                        buttonType={
                          discountType === 'amount' ? 'primary' : 'secondary'
                        }
                        label={values?.currencyId}
                        onClick={() => updateDiscountType('amount')}
                        size="small"
                        style={{ width: 'auto' }}
                      />
                    </ServiceDiscount>
                  </div>
                </div>

                <div className="flex flex-col ">
                  <p className="font-medium mt-[5px]">{'Trajanje'}</p>
                  <div className="flex gap-3 items-center mt-1 mb-2">
                    {`${values?.quantity} ${
                      Number(values?.quantity) === 1
                        ? t('translation.AppointmentHotelServiceForm.day')
                        : t('translation.AppointmentHotelServiceForm.days')
                    }`}
                  </div>
                </div>

                <div className="flex flex-col justify-end">
                  <p className="font-medium mt-[5px] text-end">{'Ukupno'}</p>
                  <div className="flex gap-3 items-center mt-1 mb-2 text-end">
                    {`${formatPriceNumberWithDelimiter(values?.totalAmount)} ${
                      values?.currencyId
                    }`}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}

export const ButtonsRow = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: end;
  margin-top: 10px;
  ${ButtonStyled} {
    &:last-child {
      margin-left: 15px;
    }
  }

  @media ${device.tablet} {
    margin-top: auto;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    width: 100%;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: white;

    ${ButtonStyled} {
      height: 50px;
      flex: 1;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
`
