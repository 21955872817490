import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Print = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.5 19.5h9M7.5 16.5h9M5.5 16.5h-3 0a2.006 2.006 0 01-2-2v-6h0a2.006 2.006 0 012-2h19a2.006 2.006 0 012 2v6h0a2.006 2.006 0 01-2 2h-3M5.5 4.5v-4h9.586a1 1 0 01.707.293l2.414 2.414h0a1 1 0 01.293.707V4.5"></path>
        <path d="M18.5 22.5v0a1 1 0 01-1 1h-11 0a1 1 0 01-1-1s0 0 0 0v-9h13zM4.207 8.792a1 1 0 11-1.414 1.414 1 1 0 011.414-1.414M14.5.499v4h4"></path>
      </g>
    </StyledIcon>
  )
}
