import React, { useEffect, useRef, useState, useContext, useMemo } from 'react'
import { Tab, TabView } from 'components/TabView/TabView'
import { Button, Form, FormFooterContainer, icons, Loader } from 'components'
import { useSdk } from 'sdk'
import moment from 'moment'
import { FormField } from 'components'
import { useQuery } from '@apollo/client'
import {
  queryServiceTypes,
  searchClients,
  useGetLocationLoading,
  useGetMyOrganization,
  useInviteUser,
} from 'state/queries'
import {
  analyticsTrack,
  formatPhone,
  formatPriceNumberWithDelimiter,
  getFullReportSrc,
  roundNumberWithTwoDecimals,
  useOnClickOutsideWithoutTriggeringAction,
} from 'src/helpers'
import {
  CalendarGetEntriesDocument,
  PosReceiptByJournalGetDocument,
  PosReceipt_SearchDocument,
  useClientsAddMutation,
  useClientUpdateMutation,
  usePosOperatorsAllQuery,
  usePosReceiptCreateMutation,
  useProductTypeCurrentStockQuery,
  WmsOrderListDocument,
} from 'state/graphql'
import { useParams } from 'react-router'
import { ClientFormWrapper } from 'views/AppointmentModal/components/ClientFormWrapper'
import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import * as Yup from 'yup'
import { FieldColumn } from 'views/Settings/styles'
import {
  PosReceiptStore,
  printPDF,
  printReceiptToPosPrinter,
} from 'state/local/posReceiptStore'
import { usePosStore } from 'state/local/posReceiptStore'
import {
  Container,
  FormWrapper,
  Modal,
  ServiceSelect,
  PopupContainer,
  StyledDiscountInput,
  ServicePrice,
  ServiceDiscount,
  ServiceQuantityButtons,
} from 'views/CashRegister/NewReceipt/styles'
import { PaymentMethodSelector } from 'views/CashRegister/NewReceipt/PaymentMethodSelector'
import { PosReceiptItem } from 'views/CashRegister/NewReceipt/PosReceiptItem'
import { EurExchangeRate } from '../../../constants'
import { SelectOptionLabel, ServiceName } from 'views/AppointmentModal/styles'
import { debounce } from 'lodash'
import { HeaderTitle } from 'views/Appointment/components/Header'
import {
  ControlContainer,
  ControlAvatar,
  ControlRow,
  ControlLabel,
  ControlColumn,
  ControlName,
} from 'views/Appointment/components/Client'
import { Badge } from 'src/styles'
import { ThemeContext } from 'styled-components'
import ReceiptDetailsModal, { TReceipt } from '../ReceiptDetailsModal'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { Add } from 'src/components/Icon/Icons/Add'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import { Trash } from 'src/components/Icon/Icons/Trash'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { useFeatureFlags } from 'src/state/local/appStore'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { trpc } from 'src/trpc'
import { t } from 'src/localization'
import { usePaymentMethods } from 'src/sdk/helperHooks'

const ReceiptSchema = Yup.object().shape({
  tipAmount: Yup.string().test(
    'test-name',
    t('translation.ServiceEditorForm.validation-numeric-data-only'),
    (value): any => {
      if (!value) return true

      return value.match(/^-?(?:\d+)(?:(\.|,)\d+)?$/)
    }
  ),
})

type TAppointmentModalProps = {
  setOpenNewReceiptModal?: any
  selectedReceipt?: any
  setSelectedReceipt?: any
  isAppointment?: boolean
  setIsIssueReceipt?: any
  handleOnCloseEvent?: any
}

export const NewReceipt = (props: TAppointmentModalProps) => {
  const {
    setOpenNewReceiptModal,
    selectedReceipt,
    setSelectedReceipt,
    isAppointment,
    setIsIssueReceipt,
    handleOnCloseEvent,
  } = props
  const { locationId, orgId } = useParams<any>()
  const { t, appServices, apolloClient } = useSdk()
  const selectedTheme = useContext(ThemeContext)
  const { ClientSchema } = useValidationSchema()
  const paymentMethods = usePaymentMethods()

  const {
    selectClient,
    setReceiptComment,
    selectOperator,
    addItem,
    initPosStoreReceipt,
    resetReceipt,
    selectItem,
    incrementItemQty,
    decrementItemQty,
    removeItem,
    confirmSelectedItem,
    setSelectedItemDiscountType,
    removeSelectedItem,
    changeQuantity,
    updateSelectedItemDiscountValue,
    setSelectedItemPrice,
    setTipAmount,
    setTipPaymentMethod,
    selectPaymentMethod,
  } = PosReceiptStore

  useEffect(() => {
    if (selectedReceipt) initPosStoreReceipt(selectedReceipt)
  }, [initPosStoreReceipt, selectedReceipt])

  const receipt = usePosStore(store => store.pos.receipt)

  const selectedItem = usePosStore(store => store.pos.selectedItem)
  const selectedOperatorId = usePosStore(store => store.pos.selectedOperatorId)
  const [clientsSortBy, setClientsSortBy] = useState('')
  const formRef = useRef<any>()
  const [activeForm, setActiveForm] = useState('newReceipt')
  const [isNewClient, setIsNewClient] = useState(false)
  const [error, setError] = useState('')
  const [showServicesModal, setShowServicesModal] = useState(
    isAppointment ? false : true
  )

  const trpcContext = trpc.useContext()

  const [stockCountWarning, setStockCountWarning] = useState<any>(null)

  const featuresFlags = useFeatureFlags()
  const printClient = featuresFlags.printClient
  const loyaltyFeatureFlag = featuresFlags.loyalty === 'true'

  const wrapperRef = useRef<any>()

  useOnClickOutsideWithoutTriggeringAction(wrapperRef, () =>
    !stockCountWarning?.itemDescription
      ? (setSelectedReceipt(null),
        setSearchValue(''),
        setShowServicesModal(false))
      : null
  )

  const { data: serviceTypesData, loading } = useQuery(queryServiceTypes, {
    variables: { locationId },
    fetchPolicy: 'network-only',
  })

  const serviceTypes = serviceTypesData?.serviceType?.search

  const { data: productTypes, loading: productTypesLoading } =
    useProductTypeCurrentStockQuery({
      fetchPolicy: 'network-only',
    })

  const productsAll = productTypes?.productType_currentStock?.filter(
    x => x?.products?.length
  )
  const [activeTab, setActiveTab] = useState('services')
  const [searchValue, setSearchValue] = useState('')
  const [filteredServiceTypes, setFilteredServiceTypes] =
    useState<any>(serviceTypes)
  const [filteredProductTypes, setFilteredProductTypes] =
    useState<any>(productsAll)

  const [issueState, setIssueState] = useState<{
    state: 'WAITING' | 'INPROGRESS' | 'ISSUED'
  }>({ state: 'WAITING' })
  const [issuedReceipt, setIssuedReceipt] = useState<TReceipt | null>(null)

  const { data: viewLocationData, loading: loadingLocation } =
    useGetLocationLoading(locationId)

  const { data: giftCardsData, isLoading: giftCardsLoading } =
    trpc.giftCard_pos_findMany.useQuery({
      orgId: orgId,
      locationId: BigInt(locationId),
      searchTerm: searchValue,
    })
  const giftCards = receipt?.items?.length
    ? giftCardsData?.filter(x => {
        return !receipt?.items?.find(
          item => item?.giftCardId === x?.id.toString()
        )
      })
    : giftCardsData

  const viewLocation = viewLocationData?.location?.get
  const orgData = useGetMyOrganization()
  const org = orgData?.data?.organization?.my?.find(x => x.id === orgId)
  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)

  const emptyReceiptItem = receipt?.items?.find(x =>
    loyaltyFeatureFlag
      ? !x.serviceId && !x.productId && !x.giftCardId
      : !x.serviceId && !x.productId
  )
  const productOutOfStock = receipt?.items?.find(x => x?.stockCount! <= 0)

  const productsInReceipt = receipt?.items?.filter(x => x?.productId)
  const productsInReceiptIncludingSelectedItem = productsInReceipt?.map(
    product => {
      if (selectedItem?.productId && product?.id === selectedItem?.id) {
        return selectedItem
      }
      return product
    }
  )

  function findOutOfStockProducts(productsInReceipt) {
    const sumByProductId = productsInReceipt?.reduce((acc, item) => {
      acc[item?.productId!] = (acc[item?.productId!] || 0) + item?.quantity
      return acc
    }, {})

    const result: Array<{ id: string; stock: 'out' | 'limit' }> = []

    productsInReceipt?.forEach(product => {
      if (sumByProductId[product?.productId!] > product?.stockCount!) {
        result.push({ id: product?.productId, stock: 'out' })
      } else if (sumByProductId[product?.productId!] === product?.stockCount!) {
        result.push({ id: product?.productId, stock: 'limit' })
      }
    })

    return result
  }
  const productsOutOfStock = findOutOfStockProducts(
    productsInReceiptIncludingSelectedItem
  )

  const [inputValue, setInputValue] = React.useState<string>('0')
  const [priceInputValue, setPriceInputValue] = React.useState<string>('0')

  useMemo(
    () =>
      setInputValue(
        selectedItem?.discount ? selectedItem?.discount?.toString() : '0'
      ),
    [selectedItem?.discount]
  )

  useMemo(
    () => setPriceInputValue(selectedItem?.price?.toString() || '0'),
    [selectedItem?.price]
  )

  const updateDiscount = (value: string) => {
    setInputValue(value)

    updateSelectedItemDiscountValue(
      !value ? 0 : Number(parseFloat(value?.replace(',', '.')))
    )
  }

  const updatePrice = (value: string) => {
    setPriceInputValue(value?.replace(',', '.'))
    setSelectedItemPrice(
      !value ? 0 : Number(parseFloat(value?.replace(',', '.')))
    )
  }

  const { data: posOperators, loading: loadingPosOperators } =
    usePosOperatorsAllQuery()

  const [clientAddMutation, { loading: loadingAddClient }] =
    useClientsAddMutation()

  const [receiptCreateMutation, { loading: receiptCreateLoading }] =
    usePosReceiptCreateMutation()

  const [updateClient, { loading: loadingUpdateClient }] =
    useClientUpdateMutation()
  const [inviteUser] = useInviteUser()

  const metadata = {
    time: moment().format('DD.MM.YYYY HH:mm'),
    orgId,
    locationId,
  }
  useEffect(() => {
    const clientsSortBy = localStorage.getItem('clientsSortBy')
    if (clientsSortBy) {
      setClientsSortBy(clientsSortBy)
    } else setClientsSortBy('id_desc')
  }, [])

  useEffect(() => {
    const handleKeyPressActions = event => {
      if (event.keyCode === 27) {
        if (showServicesModal) {
          if (stockCountWarning?.itemDescription) {
            setStockCountWarning(null)
          } else {
            setSelectedReceipt(null)
            setSearchValue('')
            setShowServicesModal(false)
          }
        } else if (selectedItem) {
          if (stockCountWarning?.itemDescription) {
            setStockCountWarning(null)
          } else {
            selectItem('')
          }
        } else {
          resetReceipt()
          setOpenNewReceiptModal(false)
        }
      }

      if (event.keyCode === 119) {
        !showServicesModal
          ? setShowServicesModal(true)
          : inputRef.current.focus()
      }
    }
    window.addEventListener('keydown', handleKeyPressActions)

    return () => {
      window.removeEventListener('keydown', handleKeyPressActions)
    }
  }, [showServicesModal, selectedItem, stockCountWarning?.itemDescription])

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('client', null)
      setActiveForm('client')
      setIsNewClient(true)
      form.setFieldValue('firstName', '')
      form.setFieldValue('lastName', '')
      form.setFieldValue('email', '')
      form.setFieldValue('mobilePhone', '')
      form.setFieldValue('address', '')
      form.setFieldValue('zipCode', '')
      form.setFieldValue('city', '')
      form.setFieldValue('gender', '')
      form.setFieldValue('comment', '')
      form.setFieldValue('paymentRequired', true)
    }
  }
  async function createClient(values) {
    try {
      if (isNewClient) {
        const clientResult = await clientAddMutation({
          variables: {
            input: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              language: org?.language || 'hr',
              city: values.city,
              comment: values.comment,
              mobilePhone: formatPhone(values.mobilePhone),
              gender: values.gender,
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              sendBookingEmail: true,
              sendNewsEmail: true,
              sendHelpEmail: true,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })
        if (values?.sendInviteViaEMail) {
          await inviteUser({
            variables: {
              input: {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim() || '',
                email: values.email ? values.email?.trim() : '',
                gender: values.gender || 'Unspecified',
                mobilePhone: formatPhone(values.mobilePhone),
                role: 'CLIENT',
                clientId: clientResult?.data?.clients?.add?.payload?.id!,
              },
            },
          })
        }

        if (!clientResult?.errors) {
          analyticsTrack('New client', {
            ...metadata,
            firstName: values.firstName,
            lastName: values.lastName || '',
            email: values.email ? values.email?.trim() : '',
            country: values.country?.id,
            address: values.address,
            zipCode: values.zipCode,
            city: values.city,
            comment: values.comment,
            gender: values.gender,
            identifier: values.identifier,
            vatId: values.vatId,
            birthDate: values.birthDate,
            mobilePhone: formatPhone(values.mobilePhone),
          })

          // @ts-ignore
          appServices.toast.success(
            (t('translation.AppointmentModal.toast-newClientEntered'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.add?.payload
        } else {
          appServices.toast.danger(
            t('translation.AppointmentModal.toast-newClientError')
          )
        }
      } else {
        let clientResult = await updateClient({
          variables: {
            input: {
              id: values.client?.id,
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              comment: values.comment,
              gender: values.gender,
              mobilePhone: formatPhone(values.mobilePhone),
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })

        if (clientResult) {
          analyticsTrack('Updated client', {
            ...metadata,
            firstName: values.firstName,
            lastName: values.lastName || '',
            email: values.email ? values.email?.trim() : '',
            address: values.address,
            zipCode: values.zipCode,
            city: values.city,
            comment: values.comment,
            gender: values.gender,
            mobilePhone: formatPhone(values.mobilePhone),
          })

          // if (!isNew) refetchAppointment()
          appServices.toast.success(
            (t('translation.EditClient.toast-editClient'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.update?.payload
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const issueReceipt = async () => {
    try {
      const items = receipt.items.map(item => {
        return {
          serviceId: item.serviceId ? BigInt(item.serviceId) : null,
          productId: item.productId ? BigInt(item.productId) : null,
          giftCardId: item.giftCardId ? BigInt(item.giftCardId) : null,
          qty: item.quantity,
          price: item.price,
          vatRate: item.vatRate / 100,
          amount: roundNumberWithTwoDecimals(item.price * item?.quantity),
          discountAmount:
            item.discountType === 'percent'
              ? roundNumberWithTwoDecimals(item.discountAmount)
              : roundNumberWithTwoDecimals(item.discount),
          discountRate: Math.abs(
            item.discountType === 'percent'
              ? item.discount / 100
              : 1 -
                  (item.price * item.quantity - item.discount) /
                    (item.price * item.quantity)
          ),
          totalAmount: roundNumberWithTwoDecimals(item.total),
          paymentRequestId: item.paymentRequestId
            ? BigInt(item.paymentRequestId)
            : null,
          resourceBookingServiceId: item.resourceBookingServiceId
            ? BigInt(item.resourceBookingServiceId)
            : null,
          discountType: item.discountType,
        }
      })
      const taxes = receipt?.vatItems?.map(vatItem => {
        return {
          taxKind: 'VAT',
          taxRate: vatItem?.vatRate,
          baseAmount: roundNumberWithTwoDecimals(vatItem?.baseAmount),
          taxAmount: roundNumberWithTwoDecimals(vatItem?.vatAmount),
        }
      })
      const amount = items?.reduce((acc, curr) => {
        return acc + curr?.amount
      }, 0)

      const receiptResult = await receiptCreateMutation({
        variables: {
          input: {
            receiptType: receipt?.receiptType,
            clientId: receipt?.client?.id,
            paymentMethod: receipt?.paymentMethod,
            originalReceiptId: null,
            fisDeviceId: selectedPosDevice?.fisDeviceId as any,
            fisLocationId: selectedPosDevice?.fisLocationId as any,
            appointmentId: receipt?.appointmentId,
            resourceBookingId: receipt?.resourceBookingId,
            operatorId: selectedOperatorId ? BigInt(selectedOperatorId) : null,
            amount: roundNumberWithTwoDecimals(amount),
            receiptComment: receipt?.receiptComment,
            vatAmount: receipt?.totalVatAmount,
            totalAmount: roundNumberWithTwoDecimals(receipt?.totalAmount),
            tipAmount: roundNumberWithTwoDecimals(
              parseFloat(
                receipt?.tipAmount?.toString()?.replace(',', '.') || '0'
              )
            ),
            tipPaymentMethod: receipt?.tipPaymentMethod,
            items,
            taxes,
          },
        },
        refetchQueries: [
          {
            query: PosReceipt_SearchDocument,
            variables: {
              pageNumber: 1,
              pageSize: 50,
              searchTerm: '',
            },
          },
          {
            query: WmsOrderListDocument,
            variables: {
              input: {
                searchTerm: '',
                documentType: 'GOODS_RELEASED',
                fetch: {
                  cursor: null,
                  limit: 40,
                },
              },
            },
          },
          { query: CalendarGetEntriesDocument },
        ],
      })
      if (
        !receiptResult?.errors ||
        receiptResult?.errors?.[0]?.extensions?.code ===
          'FISCALIZATION_TIMEOUT_EXCEEDED'
      ) {
        receiptResult?.errors?.[0]?.extensions?.code ===
        'FISCALIZATION_TIMEOUT_EXCEEDED'
          ? appServices.toast.danger(receiptResult?.errors[0]?.message)
          : appServices.toast.success('Račun je uspješno izdan')

        const journalId = receiptResult?.data?.posReceipt_create?.journalId

        // here refetch receipt list for the current journal fisDeviceId fisLocationId
        await apolloClient.query({
          query: PosReceiptByJournalGetDocument,
          fetchPolicy: 'network-only',
          variables: { input: { posJournalId: journalId } },
        })

        const printResult =
          selectedPosDevice?.printerType === 'ROLL'
            ? await printReceiptToPosPrinter(
                receiptResult.data?.posReceipt_create.id,
                printClient
              )
            : await printPDF(
                printClient,
                getFullReportSrc(
                  `racun&receiptCid=${receiptResult?.data?.posReceipt_create?.cid}`,
                  true
                ),
                selectedPosDevice?.printerName,
                'receipt.pdf',
                false,
                'Račun je uspješno isprintan!'
              )

        printResult?.success
          ? appServices.toast.success(printResult?.text)
          : appServices.toast.danger(printResult?.text)

        if (items?.some(x => x?.giftCardId)) {
          trpcContext.giftCard_pos_findMany.invalidate()
        }
        resetReceipt()
        !isAppointment && setOpenNewReceiptModal(false)
        setIssuedReceipt(receiptResult?.data?.posReceipt_create as any)
        isAppointment && setIssueState({ state: 'ISSUED' })
        setSelectedReceipt &&
          setSelectedReceipt(receiptResult?.data?.posReceipt_create as any)
        return receiptResult?.data?.posReceipt_create
      } else {
        appServices.toast.danger(
          receiptResult?.errors[0]?.message ||
            'Došlo je do pogreške kod izdavanja računa'
        )
      }
    } catch (err) {
      appServices.toast.danger('Došlo je do pogreške kod izdavanja računa')
    }
  }

  const cancelReceipt = async () => {
    const amount = receipt?.items?.reduce((acc, curr) => {
      return acc + roundNumberWithTwoDecimals(curr?.price * curr?.quantity)
    }, 0)

    const result = await receiptCreateMutation({
      variables: {
        input: {
          originalReceiptId: selectedReceipt.id,
          receiptType: 'CANCELLATION',
          clientId: receipt.client?.id,
          paymentMethod: receipt.paymentMethod,
          fisDeviceId: selectedPosDevice?.fisDeviceId as any,
          fisLocationId: selectedPosDevice?.fisLocationId as any,
          appointmentId: receipt.appointmentId,
          resourceBookingId: receipt.resourceBookingId,
          operatorId: selectedOperatorId ? BigInt(selectedOperatorId) : null,
          vatAmount: receipt.totalVatAmount,
          totalAmount: roundNumberWithTwoDecimals(receipt.totalAmount),
          amount: roundNumberWithTwoDecimals(amount),
          receiptComment: receipt.receiptComment,
          originalReceiptPaymentRequestId: selectedReceipt.paymentRequestId,
          originalReceiptPaymentRequestOrderCode:
            selectedReceipt.paymentRequestOrderCode,
          tipAmount: roundNumberWithTwoDecimals(
            parseFloat(receipt?.tipAmount?.toString()?.replace(',', '.') || '0')
          ),
          tipPaymentMethod: receipt?.tipPaymentMethod,
          items: receipt?.items?.map(item => {
            return {
              serviceId: item.serviceId ? BigInt(item?.serviceId) : null,
              productId: item.productId ? BigInt(item?.productId) : null,
              giftCardId: item.giftCardId ? BigInt(item.giftCardId) : null,
              qty: item.quantity,
              price: item.price,
              vatRate: item.vatRate / 100,
              amount: item.price * item.quantity,
              discountAmount:
                item.discountType === 'percent'
                  ? -roundNumberWithTwoDecimals(item.discountAmount)
                  : -roundNumberWithTwoDecimals(item.discount),
              discountRate: Math.abs(
                item.discountType === 'percent'
                  ? item?.discount!
                  : 1 -
                      (item.price * item.quantity - item.discount) /
                        (item.price * item.quantity)
              ),
              totalAmount: item.total,
              paymentRequestId: item.paymentRequestId
                ? BigInt(item.paymentRequestId)
                : null,
            }
          }),
          taxes: receipt?.vatItems?.map(vatItem => {
            return {
              taxKind: 'VAT',
              taxRate: vatItem?.vatRate,
              baseAmount: roundNumberWithTwoDecimals(vatItem?.baseAmount),
              taxAmount: roundNumberWithTwoDecimals(vatItem?.vatAmount),
            }
          }),
        },
      },
      refetchQueries: [
        {
          query: PosReceipt_SearchDocument,
          variables: {
            pageNumber: 1,
            pageSize: 50,
            searchTerm: '',
          },
        },
        {
          query: WmsOrderListDocument,
          variables: {
            input: {
              searchTerm: '',
              documentType: 'GOODS_RELEASED',
              fetch: {
                cursor: null,
                limit: 40,
              },
            },
          },
        },
        { query: CalendarGetEntriesDocument },
      ],
    })
    if (!result.errors) {
      const printResult =
        selectedPosDevice?.printerType === 'ROLL'
          ? await printReceiptToPosPrinter(
              result.data?.posReceipt_create.id,
              printClient
            )
          : await printPDF(
              printClient,
              getFullReportSrc(
                `racun&receiptCid=${result.data?.posReceipt_create?.cid}`,
                true
              ),
              selectedPosDevice?.printerName,
              'receipt.pdf',
              false,
              'Račun je uspješno isprintan!'
            )

      printResult?.success
        ? appServices.toast.success(printResult?.text)
        : appServices.toast.danger(printResult?.text)

      const journalId = result?.data?.posReceipt_create?.journalId

      // here refetch receipt list for the current journal fisDeviceId fisLocationId
      await apolloClient.query({
        query: PosReceiptByJournalGetDocument,
        fetchPolicy: 'network-only',
        variables: { input: { posJournalId: journalId } },
      })

      appServices.toast.success(
        t(
          'translation.ReceiptDetailsModal.label-receipt-successfully-cancelled'
        )
      )
      if (receipt?.items?.some(x => x?.giftCardId)) {
        trpcContext.giftCard_pos_findMany.invalidate()
      }

      setOpenNewReceiptModal(false)
      setSelectedReceipt && setSelectedReceipt(result?.data?.posReceipt_create)
    } else appServices.toast.danger(result?.errors?.[0]?.message)
  }

  const handleSubmit = async (values, form) => {
    if (selectedReceipt) cancelReceipt()
    else if (activeForm === 'newReceipt') await issueReceipt()
    else if (activeForm === 'client') {
      const val = values
      const client = await createClient(values)
      // for some reason form.setFieldValue('client', client) doesn't work properly
      formRef.current.setFieldValue('client', client)
      formRef.current.setFieldValue('services', val?.services)
      formRef.current.setFieldValue('internalNote', val?.internalNote)
      formRef.current.setFieldValue(
        'sendAppointmentEmail',
        val?.sendAppointmentEmail
      )
      formRef.current.setFieldValue(
        'sendPaymentRequest',
        val?.sendPaymentRequest
      )
      selectClient(client)
      setActiveForm('newReceipt')
    }
    setActiveForm('newReceipt')
  }

  const globalSearchMemo = useMemo(() => {
    return (searchInput, serviceTypes, type) => {
      if (searchInput === '') {
        return serviceTypes
      }
      let filteredData = serviceTypes
        ?.filter(element => {
          return element[type].some(service =>
            searchInput
              ? service.name
                  ?.toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                service?.code?.toLowerCase().includes(searchInput.toLowerCase())
              : service
          )
        })
        .map(element => {
          let newService = Object.assign({}, element)
          return {
            ...element,
            [type]: newService[type].filter(service =>
              searchInput
                ? service.name
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                  service?.code
                    ?.toLowerCase()
                    .includes(searchInput.toLowerCase())
                : service
            ),
          }
        })
      return filteredData
    }
  }, [serviceTypes, productTypes])

  const inputRef = useRef<any>(null)

  const debouncedFiltering = useMemo(() => {
    return debounce(text => {
      if (text === null) {
        setFilteredServiceTypes(serviceTypes)
        setFilteredProductTypes(productsAll)
      }
      const filteredServicesData = globalSearchMemo(
        text || '',
        serviceTypes,
        'services'
      )
      const filteredProductsData = globalSearchMemo(
        text || '',
        productsAll,
        'products'
      )

      setFilteredServiceTypes(filteredServicesData)
      setFilteredProductTypes(filteredProductsData)
    }, 0)
  }, [globalSearchMemo])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  useEffect(() => {
    setFilteredProductTypes(productsAll)
  }, [productTypesLoading])

  useEffect(() => {
    setFilteredServiceTypes(serviceTypes)
  }, [loading])

  useEffect(() => {
    debouncedFiltering(searchValue)
  }, [searchValue, debouncedFiltering])

  useEffect(() => {
    if (
      activeTab === 'services' &&
      filteredServiceTypes?.length === 0 &&
      filteredProductTypes?.length
    ) {
      setActiveTab('products')
    } else if (
      activeTab === 'products' &&
      filteredProductTypes?.length === 0 &&
      filteredServiceTypes?.length
    ) {
      setActiveTab('services')
    }
  }, [filteredServiceTypes, filteredProductTypes])

  const calculateTotalNumberOfItems = (arrayOfObjects, type) => {
    if (arrayOfObjects) {
      let totalNumberOfItems = 0
      for (const object of arrayOfObjects) {
        totalNumberOfItems += object[type]?.length
      }
      return totalNumberOfItems
    }
  }

  const isLoading =
    loadingPosOperators ||
    loadingAddClient ||
    loadingUpdateClient ||
    loadingLocation ||
    productTypesLoading ||
    loading

  if (
    posOperators?.posOperators_all &&
    posOperators?.posOperators_all?.length &&
    !selectedOperatorId
  ) {
    selectOperator(posOperators?.posOperators_all?.[0]?.id)
  }

  if (issueState.state === 'ISSUED') {
    return (
      <ReceiptDetailsModal
        setSelectedReceipt={
          !isAppointment ? setOpenNewReceiptModal : setSelectedReceipt
        }
        //@ts-ignore
        selectedReceipt={issuedReceipt}
        setOpenNewReceiptModal={setIsIssueReceipt}
        handleOnCloseEvent={handleOnCloseEvent}
        setIssueState={setIssueState}
        setIssuedReceipt={setIssuedReceipt}
        isAppointment={isAppointment}
      />
    )
  }

  const handleValueChange = async (name, value, values, form) => {
    if (name === 'email' && value !== value?.trim()) {
      form.setFieldValue('email', value?.trim())
      formRef.current?.validateForm()
    }
  }

  return (
    <Container>
      <Modal>
        <div className="w-full h-full flex flex-col relative">
          <FormWrapper>
            {isLoading ? (
              <div style={{ minHeight: '500px' }}>
                <Loader isComponent />
              </div>
            ) : (
              <Form
                onValueChange={handleValueChange}
                initialValues={{
                  receiptComment:
                    receipt?.receiptType === 'CANCELLATION'
                      ? ''
                      : receipt?.receiptComment,
                  posOperator: selectedOperatorId,
                  client: receipt?.client,
                  tipCurrency: org?.currency?.id,
                  tipAmount: receipt?.tipAmount,
                  tipPaymentMethod: receipt?.tipPaymentMethod,
                }}
                validationSchema={
                  activeForm === 'client' ? ClientSchema : ReceiptSchema
                }
                isInitialValid={true}
                onSubmit={handleSubmit}
              >
                {form => {
                  const { values, errors, setFieldValue } = form
                  formRef.current = form

                  return activeForm === 'newReceipt' ? (
                    <>
                      <TabView
                        setSelectedTabIndex={() => {}}
                        isSingleTab={true}
                        hasFooter={false}
                      >
                        <Tab
                          hasFooter={false}
                          title={
                            selectedReceipt
                              ? t('translation.NewReceipt.label-storno')
                              : t('translation.NewReceipt.label-new-receipt')
                          }
                        >
                          {false && <Loader isComponent />}
                          <div className="p-[10px_20px] lg:p-[20px_30px]">
                            {!!selectedReceipt ? (
                              <div className="pb-[20px] mb-[20px] items-start flex flex-col lg:flex-row justify-between border-b-[1px] border-zoyya-outline">
                                <HeaderTitle isDisabled>
                                  <span>
                                    {`${t(
                                      'translation.NewReceipt.label-original-receipt'
                                    )} ${selectedReceipt?.receiptNo}`}
                                  </span>
                                </HeaderTitle>
                                <div>
                                  <HeaderTitle isDisabled>
                                    <span>
                                      {moment(
                                        selectedReceipt?.createdAt
                                      ).format(
                                        t(
                                          'regionalFormats.date.momentFormatDateTime'
                                        )
                                      )}
                                    </span>
                                  </HeaderTitle>
                                </div>
                              </div>
                            ) : null}

                            <div className="pb-[15px]">
                              {selectedReceipt ? (
                                <ControlContainer
                                  style={{ width: '100%', margin: '20px 0' }}
                                  hasValue
                                >
                                  {selectedReceipt?.client?.user?.avatarUrl &&
                                  !selectedReceipt?.client?.user?.avatarUrl?.endsWith(
                                    'null'
                                  ) ? (
                                    <ControlAvatar
                                      image={
                                        selectedReceipt?.client?.user?.avatarUrl
                                      }
                                      hasValue
                                    />
                                  ) : !!selectedReceipt?.client?.user
                                      ?.google_profilePicUrl ? (
                                    <ControlAvatar
                                      image={
                                        selectedReceipt?.client?.user
                                          ?.google_profilePicUrl
                                      }
                                      hasValue
                                    />
                                  ) : !!selectedReceipt?.client?.user
                                      ?.fb_profilePicUrl ? (
                                    <ControlAvatar
                                      image={
                                        selectedReceipt?.client?.user
                                          ?.fb_profilePicUrl
                                      }
                                      hasValue
                                    />
                                  ) : (
                                    <ControlAvatar hasValue />
                                  )}
                                  <ControlRow style={{}}>
                                    {!selectedReceipt?.client ? (
                                      <ControlName
                                        style={{
                                          color:
                                            selectedTheme.colors.secondaryDark,
                                        }}
                                        hasValue
                                      >
                                        {t(
                                          'translation.ReceiptDetailsModal.label-no-client-selected'
                                        )}
                                      </ControlName>
                                    ) : (
                                      <>
                                        <ControlColumn>
                                          <ControlRow>
                                            <ControlName hasValue>
                                              {
                                                selectedReceipt?.client
                                                  ?.fullName
                                              }
                                            </ControlName>
                                            {selectedReceipt?.client?.status ===
                                            'BLOCKED' ? (
                                              <Badge
                                                style={{ marginLeft: '10px' }}
                                              >
                                                {t(
                                                  'translation.ClientsList.label-blocked'
                                                )}
                                              </Badge>
                                            ) : null}
                                          </ControlRow>
                                          <ControlLabel hasValue>
                                            {selectedReceipt?.client?.email}
                                          </ControlLabel>
                                        </ControlColumn>

                                        <ControlColumn>
                                          <ControlRow>
                                            {selectedReceipt?.client
                                              ?.address ? (
                                              <ControlLabel
                                                hasValue
                                              >{`${selectedReceipt?.client?.address}, ${selectedReceipt?.client?.city}`}</ControlLabel>
                                            ) : null}
                                          </ControlRow>
                                          {selectedReceipt?.client?.vatId ? (
                                            <ControlLabel hasValue>
                                              {selectedReceipt?.client?.vatId}
                                            </ControlLabel>
                                          ) : null}
                                        </ControlColumn>
                                      </>
                                    )}
                                  </ControlRow>
                                </ControlContainer>
                              ) : (
                                <div>
                                  <ClientSelect
                                    onItemSelected={selectClient}
                                    selectedItem={
                                      receipt?.client || values.client
                                    }
                                    disabled={receiptCreateLoading}
                                    onNewClient={getOnNewClient(form)}
                                  />
                                </div>
                              )}
                            </div>

                            {receipt?.items?.length ? (
                              <div className="bg-zoyya-lightBackground p-[0_10px] rounded-[4px]">
                                {receipt?.items?.map(service => {
                                  return (
                                    <PosReceiptItem
                                      service={service}
                                      org={org}
                                      editable={!selectedReceipt}
                                      loading={receiptCreateLoading}
                                      key={service?.id}
                                      productsOutOfStock={productsOutOfStock}
                                    />
                                  )
                                })}
                                {selectedItem ? (
                                  <PopupContainer>
                                    <>
                                      <div className="bg-zoyya-light p-[20px] rounded-[10px] flex flex-col w-[91%] overflow-hidden relative">
                                        <Button
                                          type="button"
                                          buttonType={'text'}
                                          onClick={() => {
                                            removeSelectedItem()
                                          }}
                                          size="small"
                                          iconComponent={<ArrowLeft />}
                                          style={{
                                            position: 'absolute',
                                            top: 8,
                                            left: 10,
                                          }}
                                        />

                                        <div className="flex flex-col mx-10">
                                          <span className="uppercase font-medium text-zoyya-secondaryDarker text-[10px] relative bottom-[1px] text-center">
                                            {selectedItem?.serviceType}
                                          </span>

                                          <span className="text-center">
                                            {selectedItem?.itemDescription}
                                            {selectedItem?.productId ? (
                                              <span className="text-sm text-zoyya-secondaryDark ml-1">
                                                ({selectedItem?.stockCount}
                                                {t(
                                                  'translation.NewReceipt.label-in-stock'
                                                )}
                                                )
                                              </span>
                                            ) : null}
                                          </span>
                                        </div>

                                        <div className="flex flex-col justify-center items-center">
                                          <div>
                                            <p className="text-[12px] text-zoyya-secondaryDarker text-center mt-[30px]">
                                              {t(
                                                'translation.NewReceipt.label-quantity'
                                              )}
                                            </p>
                                            <ServiceQuantityButtons
                                              style={{ marginBottom: 0 }}
                                            >
                                              <Button
                                                type="button"
                                                buttonType={'primary'}
                                                label={'-'}
                                                disabled={
                                                  selectedItem.quantity <= 1
                                                }
                                                onClick={decrementItemQty}
                                                size="small"
                                                style={{
                                                  fontSize: 22,
                                                  fontWeight: 300,
                                                }}
                                              />
                                              <span className="m-[0_20px] text-[16px] font-medium min-w-[20px] text-center">
                                                <StyledDiscountInput
                                                  type="text"
                                                  value={selectedItem?.quantity?.toString()}
                                                  onChange={e => {
                                                    const value = Number(
                                                      e.target.value.replace(
                                                        /\D/g,
                                                        ''
                                                      )
                                                    )
                                                    if (!isNaN(value))
                                                      changeQuantity(value)
                                                  }}
                                                  style={{
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                  }}
                                                />
                                              </span>
                                              <Button
                                                type="button"
                                                buttonType={
                                                  productsOutOfStock.find(
                                                    x =>
                                                      x.id ===
                                                        selectedItem?.productId! &&
                                                      x?.stock === 'out'
                                                  )
                                                    ? 'accent'
                                                    : 'primary'
                                                }
                                                label={'+'}
                                                onClick={incrementItemQty}
                                                size="small"
                                                tooltip={
                                                  productsOutOfStock.find(
                                                    x =>
                                                      x.id ===
                                                        selectedItem?.productId! &&
                                                      x?.stock === 'out'
                                                  )
                                                    ? t(
                                                        'translation.NewReceipt.not-enough-stock'
                                                      )
                                                    : ''
                                                }
                                                style={{
                                                  fontSize: 22,
                                                  fontWeight: 300,
                                                }}
                                              />
                                            </ServiceQuantityButtons>
                                          </div>
                                          {productsOutOfStock.find(
                                            x =>
                                              x.id ===
                                                selectedItem?.productId! &&
                                              x?.stock === 'out'
                                          ) ? (
                                            <p className="text-zoyya-accent5 text-sm mt-2">
                                              {t(
                                                'translation.NewReceipt.not-enough-stock'
                                              )}
                                            </p>
                                          ) : null}
                                          <div
                                            style={{
                                              position: 'relative',
                                              left: '33px',
                                            }}
                                          >
                                            <p className="text-[12px] text-zoyya-secondaryDarker text-center mr-[85px] ml-[10px] mt-[30px]">
                                              Cijena
                                            </p>
                                            <ServiceDiscount
                                              style={{ position: 'unset' }}
                                            >
                                              <div
                                                style={{
                                                  width: 65,
                                                  marginRight: 5,
                                                }}
                                              >
                                                <StyledDiscountInput
                                                  name="price"
                                                  type="text"
                                                  autoComplete="false"
                                                  value={priceInputValue?.toString()}
                                                  onChange={e =>
                                                    updatePrice(e.target.value)
                                                  }
                                                />
                                              </div>
                                              <div className="">
                                                {org?.currency?.id}
                                              </div>
                                            </ServiceDiscount>
                                          </div>
                                          <div
                                            style={{
                                              position: 'relative',
                                              left: '42px',
                                            }}
                                          >
                                            <p className="text-[12px] text-zoyya-secondaryDarker text-center mr-[85px] mt-[30px]">
                                              Popust
                                            </p>
                                            <ServiceDiscount
                                              style={{ position: 'unset' }}
                                            >
                                              <div
                                                style={{
                                                  width: 65,
                                                  marginRight: 5,
                                                }}
                                              >
                                                <StyledDiscountInput
                                                  name="discount"
                                                  type="text"
                                                  autoComplete="false"
                                                  value={inputValue?.toString()}
                                                  onChange={e =>
                                                    updateDiscount(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <Button
                                                type="button"
                                                buttonType={
                                                  selectedItem?.discountType ===
                                                  'percent'
                                                    ? 'primary'
                                                    : 'secondary'
                                                }
                                                label={'%'}
                                                onClick={() =>
                                                  setSelectedItemDiscountType(
                                                    'percent'
                                                  )
                                                }
                                                size="small"
                                                style={{
                                                  fontSize: 16,
                                                  fontWeight: 300,
                                                }}
                                              />
                                              <Button
                                                type="button"
                                                buttonType={
                                                  selectedItem?.discountType ===
                                                  'amount'
                                                    ? 'primary'
                                                    : 'secondary'
                                                }
                                                label={org?.currency?.id}
                                                onClick={() =>
                                                  setSelectedItemDiscountType(
                                                    'amount'
                                                  )
                                                }
                                                size="small"
                                                style={{ width: 'auto' }}
                                              />
                                            </ServiceDiscount>
                                          </div>
                                        </div>

                                        <div className="bg-zoyya-lightBackground p-5 mt-8 -m-5">
                                          <div className="flex border-b-[1px] border-white mb-[20px] pb-[16px]">
                                            <div style={{ minWidth: '150px' }}>
                                              <p className="text-[12px] text-zoyya-secondaryDarker">
                                                {t(
                                                  'translation.NewReceipt.label-price'
                                                )}
                                              </p>
                                              <p>
                                                {formatPriceNumberWithDelimiter(
                                                  selectedItem?.finalPrice
                                                ) +
                                                  ' ' +
                                                  org?.currency?.id}
                                              </p>
                                              {selectedItem?.discount ? (
                                                <ServicePrice
                                                  discount={
                                                    selectedItem?.discount
                                                  }
                                                  style={{
                                                    position: 'relative',
                                                    bottom: '6px',
                                                  }}
                                                >
                                                  {formatPriceNumberWithDelimiter(
                                                    selectedItem?.price
                                                  ) +
                                                    ' ' +
                                                    org?.currency?.id}
                                                </ServicePrice>
                                              ) : null}
                                            </div>
                                            <div
                                              style={{
                                                minWidth: '60px',
                                                marginRight: '72px',
                                              }}
                                            >
                                              <p className="text-[12px] text-zoyya-secondaryDarker text-center">
                                                {t(
                                                  'translation.NewReceipt.label-quantity'
                                                )}
                                              </p>
                                              <p
                                                style={{ textAlign: 'center' }}
                                              >
                                                {selectedItem?.quantity}
                                              </p>
                                            </div>
                                            <div style={{ minWidth: '70px' }}>
                                              <p className="text-[12px] text-zoyya-secondaryDarker text-center">
                                                {t(
                                                  'translation.NewReceipt.label-discount'
                                                )}
                                              </p>
                                              {selectedItem?.discount ? (
                                                <p
                                                  style={{
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  {selectedItem?.discountType ===
                                                  'amount'
                                                    ? formatPriceNumberWithDelimiter(
                                                        parseFloat(
                                                          selectedItem?.discount
                                                            ?.toString()
                                                            .replace(
                                                              ',',
                                                              '.'
                                                            ) || '0'
                                                        )
                                                      ) +
                                                      ' ' +
                                                      org?.currency?.id
                                                    : selectedItem?.discount +
                                                      ' ' +
                                                      '%'}
                                                </p>
                                              ) : null}
                                            </div>
                                            <div className="ml-auto">
                                              <p className="text-[12px] text-zoyya-secondaryDarker text-center">
                                                {t(
                                                  'translation.NewReceipt.label-total'
                                                )}
                                              </p>

                                              <p className="text-center font-medium">
                                                {formatPriceNumberWithDelimiter(
                                                  selectedItem?.total
                                                ) +
                                                  ' ' +
                                                  org?.currency?.id}
                                              </p>
                                            </div>
                                          </div>

                                          {isNaN(Number(priceInputValue)) ? (
                                            <p className="text-zoyya-accent1 text-right pb-1">
                                              {'Format cijene nije ispravan'}
                                            </p>
                                          ) : isNaN(Number(inputValue)) ? (
                                            <p className="text-zoyya-accent1 text-right pb-1">
                                              {'Format popusta nije ispravan'}
                                            </p>
                                          ) : selectedItem.price === 0 ? (
                                            <p className="text-zoyya-accent1 text-right pb-1">
                                              {'Cijena mora biti veća od nule'}
                                            </p>
                                          ) : null}
                                          <div className="flex justify-center gap-2">
                                            <Button
                                              type="button"
                                              buttonType={'primaryAccent'}
                                              label={t(
                                                'translation.NewReceipt.label-delete-item'
                                              )}
                                              onClick={() => {
                                                removeSelectedItem()
                                                removeItem(selectedItem.id)
                                              }}
                                              size="small"
                                              iconComponent={
                                                <Trash size="smaller" />
                                              }
                                              style={{ marginRight: 'auto' }}
                                            />
                                            <Button
                                              type="button"
                                              buttonType={'primary'}
                                              label={t(
                                                'translation.NewReceipt.label-confirm'
                                              )}
                                              disabled={
                                                isNaN(
                                                  Number(priceInputValue)
                                                ) ||
                                                isNaN(Number(inputValue)) ||
                                                selectedItem.price === 0
                                              }
                                              onClick={() => {
                                                if (
                                                  productsOutOfStock.find(
                                                    x =>
                                                      x.id ===
                                                        selectedItem?.productId! &&
                                                      x?.stock === 'out'
                                                  )
                                                ) {
                                                  setStockCountWarning(
                                                    selectedItem
                                                  )
                                                } else {
                                                  confirmSelectedItem()
                                                  removeSelectedItem()
                                                }
                                              }}
                                              size="medium"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </PopupContainer>
                                ) : null}
                              </div>
                            ) : null}
                            {!selectedReceipt && !receiptCreateLoading ? (
                              <div>
                                <Button
                                  type={'button'}
                                  buttonType={
                                    receipt?.items?.length === 0
                                      ? 'primary'
                                      : 'link'
                                  }
                                  name={'reset'}
                                  label={t(
                                    'translation.NewReceipt.label-add-item'
                                  )}
                                  footerButton
                                  onClick={() => setShowServicesModal(true)}
                                  fullWidth
                                  iconComponent={
                                    <Add style={{ width: 16, height: 16 }} />
                                  }
                                />

                                {showServicesModal ? (
                                  <PopupContainer>
                                    <div
                                      className="w-11/12 h-5/6 bg-white rounded-lg flex flex-col overflow-hidden"
                                      ref={wrapperRef}
                                    >
                                      <div className="flex items-center">
                                        <Button
                                          type="button"
                                          buttonType={'text'}
                                          onClick={() => {
                                            setShowServicesModal(false)
                                            setSearchValue('')
                                          }}
                                          size="small"
                                          iconComponent={<ArrowLeft />}
                                          style={{
                                            paddingLeft: 15,
                                            paddingRight: 0,
                                            margin: '0 5px',
                                          }}
                                        />
                                        <ServiceSelect
                                          placeholder={t(
                                            'translation.ServicesList.label-search'
                                          )}
                                          onChange={e =>
                                            setSearchValue(e.target.value)
                                          }
                                          ref={inputRef}
                                          value={searchValue}
                                          autoFocus={true}
                                          style={{
                                            marginLeft: 10,
                                            marginBottom: 0,
                                          }}
                                        ></ServiceSelect>
                                      </div>
                                      <div className="flex text-center ">
                                        <div
                                          className={`flex-1 border-b-2  py-2  cursor-pointer ${
                                            activeTab === 'services'
                                              ? 'border-zoyya-primary text-zoyya-primary'
                                              : 'border-zoyya-border'
                                          }`}
                                          onClick={() => {
                                            setActiveTab('services')
                                          }}
                                        >
                                          {t(
                                            'translation.NewReceipt.label-services'
                                          )}
                                          <span className="ml-1">
                                            (
                                            {calculateTotalNumberOfItems(
                                              filteredServiceTypes,
                                              'services'
                                            )}
                                            )
                                          </span>
                                        </div>
                                        <div
                                          className={`flex-1 border-b-2  py-2  cursor-pointer ${
                                            activeTab === 'products'
                                              ? 'border-zoyya-primary text-zoyya-primary'
                                              : 'border-zoyya-border'
                                          }`}
                                          onClick={() => {
                                            setActiveTab('products')
                                          }}
                                        >
                                          {t(
                                            'translation.NewReceipt.label-products'
                                          )}
                                          <span className="ml-1">
                                            (
                                            {calculateTotalNumberOfItems(
                                              filteredProductTypes,
                                              'products'
                                            )}
                                            )
                                          </span>
                                        </div>
                                        {loyaltyFeatureFlag ? (
                                          <div
                                            className={`flex-1 border-b-2  py-2  cursor-pointer ${
                                              activeTab === 'giftCards'
                                                ? 'border-zoyya-primary text-zoyya-primary'
                                                : 'border-zoyya-border'
                                            }`}
                                            onClick={() => {
                                              setActiveTab('giftCards')
                                            }}
                                          >
                                            {t(
                                              'translation.NewReceipt.label-giftCards'
                                            )}
                                            <span className="ml-1">
                                              ({giftCards?.length || 0})
                                            </span>
                                          </div>
                                        ) : null}
                                      </div>

                                      {activeTab === 'services' ? (
                                        <div className="overflow-auto px-3 flex-1 relative">
                                          {loading ? (
                                            <Loader isComponent />
                                          ) : filteredServiceTypes?.length ? (
                                            <>
                                              {filteredServiceTypes?.map(
                                                type => {
                                                  return (
                                                    <div key={type?.id}>
                                                      <p className="uppercase text-[12px] font-semibold text-zoyya-secondaryDark ml-[15px] mt-[10px]">
                                                        {type.name}
                                                      </p>
                                                      <div>
                                                        {type?.services
                                                          ?.sort(
                                                            (a, b) =>
                                                              a.sequence?.localeCompare(
                                                                b.sequence,
                                                                undefined,
                                                                {
                                                                  numeric: true,
                                                                }
                                                              )
                                                          )
                                                          ?.map(item => {
                                                            return (
                                                              <button
                                                                key={item?.id}
                                                                className="cursor-pointer hover:bg-zoyya-lightBackground px-2 rounded-md w-full flex flex-1 my-1 p-1 focus-visible:bg-zoyya-lightBackground  border border-transparent focus-visible:border focus-visible:border-purple-500 outline-0"
                                                                onClick={() => {
                                                                  addItem({
                                                                    itemDescription:
                                                                      item.name,
                                                                    price:
                                                                      item.price ||
                                                                      0,
                                                                    vatRate:
                                                                      item.vatRate ||
                                                                      0,
                                                                    serviceId:
                                                                      item.id,
                                                                    serviceType:
                                                                      type.name,
                                                                    discountAmount: 0,
                                                                    discountRate: 0,
                                                                  })

                                                                  setSearchValue(
                                                                    ''
                                                                  )
                                                                  setShowServicesModal(
                                                                    false
                                                                  )
                                                                }}
                                                              >
                                                                <ServiceName
                                                                  style={{
                                                                    minWidth: 200,
                                                                    marginRight: 10,
                                                                    textAlign:
                                                                      'start',
                                                                  }}
                                                                >
                                                                  {item.name}
                                                                </ServiceName>
                                                                <SelectOptionLabel<any>
                                                                  style={{
                                                                    minWidth: 50,
                                                                    textAlign:
                                                                      'right',
                                                                  }}
                                                                >
                                                                  {item.durationMinutes
                                                                    ? item.durationMinutes +
                                                                      t(
                                                                        'translation.ServiceSelect.durationMinutes'
                                                                      )
                                                                    : ''}
                                                                </SelectOptionLabel>
                                                                <SelectOptionLabel<any>
                                                                  isClientSelect
                                                                  style={{
                                                                    marginRight: 0,
                                                                    minWidth: 85,
                                                                    textAlign:
                                                                      'end',
                                                                  }}
                                                                >
                                                                  {formatPriceNumberWithDelimiter(
                                                                    item.price
                                                                  ) +
                                                                    ' ' +
                                                                    org
                                                                      ?.currency
                                                                      ?.id}
                                                                </SelectOptionLabel>
                                                              </button>
                                                            )
                                                          })}
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <EmptyList
                                              title={t(
                                                'translation.NewReceipt.no-results'
                                              )}
                                            />
                                          )}
                                        </div>
                                      ) : activeTab === 'products' ? (
                                        <div className="overflow-auto px-3 flex-1 relative">
                                          {productTypesLoading ? (
                                            <Loader isComponent />
                                          ) : filteredProductTypes?.length ? (
                                            <>
                                              {filteredProductTypes
                                                ?.sort(
                                                  (a, b) =>
                                                    Number(a?.sequence)
                                                      ?.toString()
                                                      ?.localeCompare(
                                                        Number(
                                                          b.sequence
                                                        ).toString(),
                                                        undefined,
                                                        {
                                                          numeric: true,
                                                        }
                                                      )
                                                )
                                                ?.map(type => {
                                                  return (
                                                    <div key={type?.id}>
                                                      <p className="uppercase text-[12px] font-semibold text-zoyya-secondaryDark ml-[15px] mt-[10px]">
                                                        {type.name}
                                                      </p>
                                                      <div>
                                                        {type?.products
                                                          ?.sort(
                                                            (a, b) =>
                                                              Number(
                                                                a?.sequence
                                                              )
                                                                ?.toString()
                                                                ?.localeCompare(
                                                                  Number(
                                                                    b.sequence
                                                                  ).toString(),
                                                                  undefined,
                                                                  {
                                                                    numeric:
                                                                      true,
                                                                  }
                                                                )
                                                          )
                                                          ?.map(item => {
                                                            return (
                                                              <button
                                                                key={item?.id}
                                                                type="button"
                                                                className={`cursor-pointer hover:bg-zoyya-lightBackground px-2 rounded-md w-full flex flex-1 my-1 p-1 focus-visible:bg-zoyya-lightBackground  border border-transparent focus-visible:border focus-visible:border-purple-500 outline-0 items-center gap-2 ${
                                                                  item?.stockCount <=
                                                                  0
                                                                    ? 'opacity-40'
                                                                    : ''
                                                                }`}
                                                                onClick={() => {
                                                                  const itemToAdd =
                                                                    {
                                                                      itemDescription:
                                                                        item.name,
                                                                      price:
                                                                        item.retailPrice ||
                                                                        0,
                                                                      vatRate:
                                                                        item.vatRate *
                                                                          100 ||
                                                                        0,
                                                                      productId:
                                                                        item.id,
                                                                      serviceType:
                                                                        type.name,
                                                                      stockCount:
                                                                        item?.stockCount,
                                                                      discountAmount: 0,
                                                                      discountRate: 0,
                                                                    }
                                                                  if (
                                                                    item?.stockCount <=
                                                                      0 ||
                                                                    productsOutOfStock.find(
                                                                      x =>
                                                                        x.id ===
                                                                        item?.id!
                                                                    )
                                                                  ) {
                                                                    setStockCountWarning(
                                                                      itemToAdd
                                                                    )
                                                                  } else {
                                                                    addItem(
                                                                      itemToAdd
                                                                    )
                                                                    setSearchValue(
                                                                      ''
                                                                    )
                                                                    setShowServicesModal(
                                                                      false
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <ServiceName
                                                                  style={{
                                                                    minWidth: 200,
                                                                    marginRight: 10,
                                                                    textAlign:
                                                                      'start',
                                                                  }}
                                                                >
                                                                  <div>
                                                                    {item?.name}
                                                                  </div>
                                                                  <div className="text-sm text-gray-500 tracking-wider">
                                                                    šifra:{' '}
                                                                    {item?.code}
                                                                  </div>
                                                                </ServiceName>

                                                                <p className="min-w-[50px] text-right font-medium text-sm">
                                                                  {parseFloat(
                                                                    (
                                                                      item?.stockCount ||
                                                                      0
                                                                    )?.toFixed(
                                                                      2
                                                                    )
                                                                  )}
                                                                </p>
                                                                <p className="min-w-[60px] text-right">
                                                                  {item?.uom ===
                                                                  'PC'
                                                                    ? 'KOM'
                                                                    : item.uom}
                                                                </p>
                                                                <p className="min-w-[100px] text-right">
                                                                  {formatPriceNumberWithDelimiter(
                                                                    item?.retailPrice
                                                                  ) +
                                                                    ' ' +
                                                                    org
                                                                      ?.currency
                                                                      ?.id}
                                                                </p>
                                                              </button>
                                                            )
                                                          })}
                                                      </div>
                                                    </div>
                                                  )
                                                })}
                                            </>
                                          ) : (
                                            <EmptyList
                                              title={t(
                                                'translation.NewReceipt.no-results'
                                              )}
                                            />
                                          )}
                                        </div>
                                      ) : activeTab === 'giftCards' &&
                                        loyaltyFeatureFlag ? (
                                        <div className="overflow-auto px-3 flex-1 relative">
                                          {giftCardsLoading ? (
                                            <Loader isComponent />
                                          ) : giftCards?.length ? (
                                            <>
                                              {giftCards?.map(item => {
                                                return (
                                                  <button
                                                    key={Number(item?.id)}
                                                    type="button"
                                                    className={`cursor-pointer hover:bg-zoyya-lightBackground px-2 rounded-md w-full flex flex-1 my-1 p-1 focus-visible:bg-zoyya-lightBackground  border border-transparent focus-visible:border focus-visible:border-purple-500 outline-0 items-center gap-2`}
                                                    onClick={() => {
                                                      const itemToAdd = {
                                                        itemDescription: `${item.name} (${item.code})`,
                                                        price: item.price || 0,
                                                        total: item.price || 0,
                                                        finalPrice:
                                                          item.price || 0,
                                                        discountAmount: 0,
                                                        discountRate: 0,
                                                        vatRate:
                                                          Number(item.vatRate) *
                                                          100,
                                                        giftCardId:
                                                          item?.id.toString() ||
                                                          '',
                                                      }
                                                      addItem(itemToAdd)
                                                      setSearchValue('')
                                                      setShowServicesModal(
                                                        false
                                                      )
                                                    }}
                                                  >
                                                    <ServiceName
                                                      style={{
                                                        minWidth: 200,
                                                        marginRight: 10,
                                                        textAlign: 'start',
                                                      }}
                                                    >
                                                      <div>{item?.name}</div>
                                                      <div className="text-sm text-gray-500 tracking-wider">
                                                        oznaka: {item?.code}
                                                      </div>
                                                    </ServiceName>

                                                    <p className="min-w-[100px] text-right">
                                                      {formatPriceNumberWithDelimiter(
                                                        item?.price
                                                      ) +
                                                        ' ' +
                                                        org?.currency?.id}
                                                    </p>
                                                  </button>
                                                )
                                              })}
                                            </>
                                          ) : (
                                            <EmptyList
                                              title={t(
                                                'translation.NewReceipt.no-results'
                                              )}
                                            />
                                          )}
                                        </div>
                                      ) : null}

                                      {/* <div className="p-3 bg-[#f4f4ff]">
                                        <Button
                                          type={'button'}
                                          buttonType={'text'}
                                          name={'reset'}
                                          label={'Zatvori'}
                                          footerButton
                                          onClick={() =>
                                            setShowServicesModal(false)
                                          }
                                          className="ml-auto"
                                        />
                                      </div> */}
                                    </div>
                                  </PopupContainer>
                                ) : null}
                              </div>
                            ) : null}
                            {stockCountWarning?.itemDescription ? (
                              <PopupContainer>
                                <div className="bg-white rounded-lg p-5 w-[300px]">
                                  <p className="text-center">
                                    {t(
                                      'translation.NewReceipt.label-product-out-of-stock-1'
                                    )}
                                    <span className="font-semibold mx-1">
                                      {stockCountWarning?.itemDescription}
                                    </span>
                                    {stockCountWarning?.stockCount! <= 0
                                      ? t(
                                          'translation.NewReceipt.label-product-out-of-stock-2'
                                        )
                                      : t(
                                          'translation.NewReceipt.label-product-out-of-stock-3'
                                        )}
                                  </p>
                                  <div className="flex justify-center gap-3 mt-4">
                                    <ButtonTW
                                      label={t(
                                        'translation.NewReceipt.label-no'
                                      )}
                                      size="small"
                                      variant="secondary"
                                      onClick={() => setStockCountWarning(null)}
                                    />
                                    <ButtonTW
                                      label={t(
                                        'translation.NewReceipt.label-yes'
                                      )}
                                      size="small"
                                      onClick={() => {
                                        if (selectedItem) {
                                          confirmSelectedItem()
                                          removeSelectedItem()
                                          setStockCountWarning(null)
                                        } else {
                                          addItem(stockCountWarning)
                                          setStockCountWarning(null)
                                          setSearchValue('')
                                          setShowServicesModal(false)
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </PopupContainer>
                            ) : null}
                            <div className="pb-[15px] mt-[15px]">
                              <FieldColumn>
                                <FormField.Select
                                  label={t(
                                    'translation.NewReceipt.label-operator'
                                  )}
                                  name="posOperator"
                                  options={posOperators?.posOperators_all!}
                                  parseValue={val =>
                                    posOperators?.posOperators_all?.find(
                                      e => e?.id === val
                                    )
                                  }
                                  formatValue={val => val?.id}
                                  isSearchable={false}
                                  customChange={selectOperator}
                                  isClearable={false}
                                  isDisabled={receiptCreateLoading}
                                />
                              </FieldColumn>
                            </div>
                            <PaymentMethodSelector
                              selectOption={value => {
                                selectPaymentMethod(value)
                                setTipPaymentMethod(value)
                                setFieldValue('tipPaymentMethod', value)
                                if (value === 'WIRE') {
                                  setFieldValue('tipAmount', 0)
                                }
                              }}
                              isDisabled={
                                receipt?.receiptType === 'CANCELLATION' ||
                                receiptCreateLoading
                              }
                            />
                            {receipt.paymentMethod !== 'WIRE' ? (
                              <div className="flex lg-flex-col flex-row justify-between mt-[15px]">
                                <div className="flex mr-2 flex-1">
                                  <FormField.Text
                                    label={'Napojnica'}
                                    name="tipAmount"
                                    disabled={
                                      receipt?.receiptType === 'CANCELLATION' ||
                                      receiptCreateLoading
                                    }
                                    onChangeText={setTipAmount}
                                  />
                                  <div className="w-[50px] ml-1">
                                    <FormField.Text
                                      name="tipCurrency"
                                      label=" "
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-1">
                                  <FormField.Select
                                    label={'Način plaćanja napojnice'}
                                    name="tipPaymentMethod"
                                    options={paymentMethods}
                                    parseValue={val =>
                                      paymentMethods.find(e => e?.id === val)
                                    }
                                    formatValue={val => val?.id}
                                    isSearchable={false}
                                    styles={{
                                      control: (provided, state) => ({
                                        ...provided,
                                        minHeight: '47px !important',
                                      }),
                                    }}
                                    customChange={setTipPaymentMethod}
                                    isClearable={false}
                                    isDisabled={
                                      receipt?.receiptType === 'CANCELLATION' ||
                                      receiptCreateLoading
                                    }
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div className="mt-[15px]">
                              <FormField.TextArea
                                label={t(
                                  'translation.AppointmentModal.placeholderText'
                                )}
                                name="receiptComment"
                                rows={2}
                                onChangeText={setReceiptComment}
                                disabled={receiptCreateLoading}
                              />
                            </div>
                          </div>
                        </Tab>
                      </TabView>
                    </>
                  ) : (
                    <ClientFormWrapper
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      loadingAddClient={loadingAddClient}
                      isNew={isNewClient}
                      setIsNewClient={setIsNewClient}
                      setActiveForm={setActiveForm}
                      onGoBack={() => setActiveForm('newReceipt')}
                      title={`${values?.client?.firstName} ${values.client?.lastName}`}
                      clientDetails={true}
                    />
                  )
                }}
              </Form>
            )}
          </FormWrapper>
          {false ? null : (
            <FormFooterContainer
              hasShadow
              style={{
                flexDirection: 'column',
                padding:
                  activeForm === 'newReceipt' && receipt?.items?.length
                    ? '15px 1.429rem'
                    : null,
                minHeight: 'unset',
              }}
            >
              {activeForm === 'newReceipt' && receipt?.items?.length ? (
                <div className="flex justify-between pb-[15px] border-b-[1px] border-white mb-[15px] w-full">
                  <div className="flex flex-col">
                    {org?.vatStatus === 'Y' ? (
                      <>
                        <div className="grid grid-cols-[45px_100px_100px]">
                          <span className="font-semibold">PDV</span>
                          <span className="font-semibold justify-self-end">
                            {t('translation.NewReceipt.label-osnovica')}
                          </span>
                          <span className="font-semibold justify-self-end">
                            {t('translation.NewReceipt.label-pdv-amount')}
                          </span>
                        </div>

                        {receipt?.vatItems?.map(vatItem => (
                          <div
                            key={vatItem.vatRate.toString()}
                            className="grid grid-cols-[45px_100px_100px]"
                          >
                            <div>{vatItem?.vatRate * 100}% </div>
                            <div className="text-right">
                              {`${formatPriceNumberWithDelimiter(
                                vatItem?.baseAmount
                              )} ${org?.currency?.id}`}
                            </div>
                            <div className="text-right">
                              {`${formatPriceNumberWithDelimiter(
                                vatItem?.vatAmount
                              )} ${org?.currency?.id}`}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold text-end">
                      {t('translation.NewReceipt.label-total')}
                    </span>
                    <span className="text-right text-[16px] font-medium">
                      {formatPriceNumberWithDelimiter(receipt.totalAmount) +
                        ' ' +
                        org?.currency?.id}
                    </span>
                  </div>
                </div>
              ) : null}
              {emptyReceiptItem ? (
                <p className="text-right w-full relative bottom-[5px] text-zoyya-accent5">
                  Nije moguće izdati račun koji sadrži praznu stavku
                </p>
              ) : null}
              {productOutOfStock ||
              productsOutOfStock?.some(x => x?.stock === 'out') ? (
                <div className="text-zoyya-accent5 flex items-center gap-1 justify-end w-full">
                  <icons.WarningTriangle />
                  <p>
                    {t(
                      'translation.NewReceipt.receipt-contains-product-out-of-stock'
                    )}
                  </p>
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {(receipt?.client ||
                  receipt?.items?.length ||
                  receipt?.paymentMethod ||
                  receipt?.receiptComment) &&
                !selectedReceipt ? (
                  <Button
                    type={'button'}
                    buttonType={'secondary'}
                    name={'reset'}
                    label={t('translation.NewReceipt.label-clear-all')}
                    footerButton
                    onClick={resetReceipt}
                    style={{ marginRight: 'auto' }}
                  />
                ) : null}

                <Button
                  buttonType={'text'}
                  name={'close'}
                  label={t('translation.AppointmentModal.button-close')}
                  footerButton
                  onClick={
                    activeForm !== 'newReceipt'
                      ? () => setActiveForm('newReceipt')
                      : () => setOpenNewReceiptModal(false)
                  }
                />
                <Button
                  isLoading={receiptCreateLoading}
                  style={receiptCreateLoading ? { paddingRight: '60px' } : {}}
                  onClick={async () => {
                    if (selectedReceipt && receipt?.items?.length)
                      formRef.current.submitForm()
                    else {
                      const errors = await formRef.current.validateForm()
                      setError(errors)
                      activeForm === 'newReceipt' &&
                      Object.values(formRef.current.errors).length
                        ? console.log(error)
                        : formRef.current.submitForm()
                    }
                  }}
                  buttonType={
                    selectedReceipt && receipt?.items?.length
                      ? 'redButton'
                      : 'primary'
                  }
                  name={'saveChanges'}
                  label={
                    selectedReceipt && receipt?.items?.length
                      ? `Storniraj ${formatPriceNumberWithDelimiter(
                          receipt.totalAmount
                        )} ${org?.currency?.id}`
                      : activeForm === 'newReceipt'
                      ? receipt?.items?.length
                        ? `Naplati ${formatPriceNumberWithDelimiter(
                            receipt.totalAmount
                          )} ${org?.currency?.id}`
                        : 'Naplati'
                      : 'Spremi'
                  }
                  disabled={
                    receiptCreateLoading ||
                    (activeForm === 'newReceipt' && !receipt?.items?.length) ||
                    (activeForm === 'newReceipt' && !receipt.paymentMethod) ||
                    (activeForm === 'newReceipt' && !selectedOperatorId) ||
                    emptyReceiptItem
                  }
                />
              </div>
            </FormFooterContainer>
          )}
        </div>
      </Modal>
    </Container>
  )
}
