import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const ArrowRightThick = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M1.1 12.5c0 1.1.9 2 2 2h13l-5.2 5c-.8.9-.7 2.2.2 3 .8.7 2.1.7 2.9 0l9.8-9.7c.2-.2.2-.5 0-.7l-9.6-9.7c-.8-.8-2.2-.7-3 .1-.8.8-.9 2.1-.1 2.9l5 5h-13c-1.1.1-2 1-2 2.1z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
