import React, { useContext, useRef, useState } from 'react'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import {
  FormPageContainer,
  FormContainer,
  FormFooterButtonsContainerMobile,
} from 'src/styles'
import {
  Button,
  DeleteButton,
  Form,
  FormField,
  FormFooterButtonsContainer,
  Loader,
} from 'components'
import { useProductTypeAllQuery } from 'state/graphql'
import { useSdk } from 'sdk'
import { FormFieldsWrapper } from 'components/Form/Form'
import { MobileContext } from 'src/Context'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import { useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useLocation } from 'react-router-dom'
import {
  formatPriceNumberWithDelimiter,
  getUnitsOfMeasure,
  useOnClickOutsideWithoutTriggeringAction,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { ThemeContext } from 'styled-components'
import { icons } from 'components'
import { TProduct, TProductStock } from './EditProduct'

const validationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.ServiceEditorForm.validation-name-required')
    ),
    /*  type: Yup.object()
      .nullable()
      .required(
        t('translation.ServiceEditorForm.validation-service-type-required')
      ), */
    retailPrice: Yup.string()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test('validPrice', 'Cijena mora biti veća od 0', function (value) {
        return !value ? false : parseFloat(value) > 0
      }),
    vatRate: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
    uom: Yup.string().required('Jedinica mjere je obavezan podatak'),
    purchasePrice: Yup.string()
      .nullable()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validPurchasePrice',
        'Nabavna cijena mora biti veća od 0',
        function (value) {
          return !value ? true : parseFloat(value) > 0
        }
      ),
    listPrice: Yup.string()
      .nullable()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validListPrice',
        'Cijena iz katolaga mora biti veća od 0',
        function (value) {
          return !value ? true : parseFloat(value) > 0
        }
      ),
    listDiscountRate: Yup.string()
      .nullable()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      ),
    newRetailPrice: Yup.string()
      .nullable()
      .matches(
        /^-?(?:\d+)(?:(\.|,)\d+)?$/,
        t('translation.ServiceEditorForm.validation-numeric-data-only')
      )
      .test(
        'validNewRetailPrice',
        'Nova cijena mora biti veća od 0',
        function (value) {
          return !value ? true : parseFloat(value) > 0
        }
      ),
    newVatRate: Yup.string().matches(
      /^[0-9]\d*(\.\d+)?$/,
      t('translation.ServiceEditorForm.validation-numeric-data-only')
    ),
  })

type TypeProductForm = {
  initialValues: TProduct
  onSubmit: any
  loading: boolean
  onDelete?: any
  isNew?: boolean
  setShowAddProductForm?: (boolean) => any
  productStock?: TProductStock | null
}

export const ProductForm = (props: TypeProductForm) => {
  const {
    onSubmit,
    initialValues,
    loading,
    onDelete,
    isNew,
    setShowAddProductForm,
    productStock,
  } = props
  const { t, navigateTo } = useSdk()
  const isMobile = useContext(MobileContext)
  let formRef = useRef<any>()
  const { pathname } = useLocation<any>()
  const params = useParams<{ orgId: string }>()
  const { orgId } = params

  const { data: productTypes, loading: productTypesLoading } =
    useProductTypeAllQuery()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get
  const hasVat = org?.vatStatus === 'Y'

  const unitsOfMeasure = getUnitsOfMeasure()

  const [showChangePriceModal, setShowChangePriceModal] = useState(false)
  const [confirmPriceChangeModal, setConfirmPriceChangeModal] = useState(false)
  const wrapperRef = useRef<any>()
  const selectedTheme = useContext(ThemeContext)

  useOnClickOutsideWithoutTriggeringAction(wrapperRef, () =>
    setShowChangePriceModal(false)
  )

  if (loading || productTypesLoading || loadingOrgData)
    return <Loader isComponent />

  return (
    <>
      <FormPageContainer>
        <Form
          onSubmit={values => onSubmit(values, showChangePriceModal)}
          initialValues={{
            ...initialValues,
            vatRate: initialValues.vatRate * 100,
            currencyId: org?.currency?.id,
            newVatRate: initialValues.vatRate * 100,
            listDiscountRate: initialValues.listDiscountRate * 100,
          }}
          validationSchema={validationSchema(t)}
        >
          {form => {
            formRef.current = form

            return (
              <FormFieldsWrapper>
                <FormContainer>
                  <FieldRow>
                    <FieldColumn>
                      <FormField.Text
                        label={t(
                          'translation.ResourceEditorForm.label-firstName'
                        )}
                        name="name"
                        autoFocus
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Text
                        label={t('translation.EditProduct.label-code')}
                        name="code"
                      />
                    </FieldColumn>
                  </FieldRow>
                  <FieldRow>
                    <FieldColumn>
                      <FormField.Select
                        label={t('translation.EditProduct.product-type')}
                        name="type"
                        options={productTypes?.productType_all || []}
                        parseValue={val => {
                          return productTypes?.productType_all.find(
                            e => e.id === val?.id
                          )
                        }}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Select
                        label={t('translation.EditProduct.label-uom')}
                        name="uom"
                        options={unitsOfMeasure}
                        isDisabled
                        parseValue={val =>
                          unitsOfMeasure?.find(e => e?.id === val)
                        }
                        formatValue={e => e?.id}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Text
                        label={t('translation.EditProduct.label-barcode')}
                        name="barCode"
                      />
                    </FieldColumn>
                  </FieldRow>
                  <FieldRow>
                    <FieldColumn>
                      <FormField.Text
                        label={t('translation.EditProduct.label-list-price')}
                        name="listPrice"
                        onChange={e => {
                          form.setFieldValue(
                            'listDiscountRate',
                            form?.values?.listDiscountRate
                          )
                          form.setFieldValue(
                            'purchasePrice',
                            !!parseFloat(form?.values?.listDiscountRate) ||
                              !!parseFloat(form?.values?.listPrice)
                              ? roundNumberWithTwoDecimals(
                                  parseFloat(
                                    form?.values?.listPrice.replace(',', '.')
                                  ) -
                                    parseFloat(
                                      form?.values?.listPrice.replace(',', '.')
                                    ) *
                                      (parseFloat(
                                        form?.values?.listDiscountRate
                                          .toString()
                                          .replace(',', '.') || 0
                                      ) /
                                        100)
                                )
                              : e
                          )
                          form.validateForm()
                        }}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Text
                        label={t('translation.EditProduct.label-margin')}
                        name="listDiscountRate"
                        onChange={e => {
                          const discount =
                            parseFloat(
                              form?.values.listPrice
                                ?.toString()
                                .replace(',', '.')
                            ) *
                            (parseFloat(
                              form?.values?.listDiscountRate
                                .toString()
                                .replace(',', '.') || 0
                            ) /
                              100)

                          form.setFieldValue(
                            'purchasePrice',
                            !!parseFloat(form?.values?.listDiscountRate) &&
                              !!parseFloat(form?.values?.listPrice)
                              ? roundNumberWithTwoDecimals(
                                  parseFloat(
                                    form?.values.listPrice
                                      ?.toString()
                                      .replace(',', '.')
                                  ) - discount
                                )
                              : null
                          )
                          form.validateForm()
                        }}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Text
                        label={t(
                          'translation.EditProduct.label-purchase-price'
                        )}
                        name="purchasePrice"
                        onChange={e => {
                          const newValue =
                            !!parseFloat(form?.values?.listDiscountRate) &&
                            !!parseFloat(form?.values?.purchasePrice)
                              ? parseFloat(
                                  form?.values?.purchasePrice
                                    ?.toString()
                                    .replace(',', '.')
                                ) /
                                ((100 -
                                  parseFloat(
                                    form?.values?.listDiscountRate
                                      .toString()
                                      .replace(',', '.') || 0
                                  )) /
                                  100)
                              : 0

                          form.setFieldValue(
                            'listPrice',
                            !!parseFloat(form?.values?.listDiscountRate) &&
                              !!parseFloat(form?.values?.purchasePrice)
                              ? roundNumberWithTwoDecimals(newValue)
                              : form?.values?.purchasePrice
                          )
                          form.validateForm()
                        }}
                      />
                    </FieldColumn>
                  </FieldRow>
                  <FieldRow style={{ alignItems: 'baseline' }}>
                    <FieldColumn style={{ flexDirection: 'column', gap: 10 }}>
                      <FormField.Text
                        label={t('translation.ProductForm.label-price')}
                        name="retailPrice"
                        forceAutoFocus={
                          !!form.errors?.retailPrice && !form.errors?.name
                        }
                        shouldDisplayZeroAsValue
                        parseValue={value => {
                          return !value ? parseFloat('0') : parseFloat(value)
                        }}
                        disabled={!isNew && !!productStock}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <FormField.Text
                        label={t('translation.ServiceEditorForm.currency')}
                        name="currencyId"
                        disabled
                        helpText={t(
                          'translation.ServiceEditorForm.tooltip-currency'
                        )}
                      />
                    </FieldColumn>
                    {hasVat ? (
                      <FieldColumn>
                        <FormField.Text
                          label={t('translation.ServiceEditorForm.label-vat')}
                          name="vatRate"
                          shouldDisplayZeroAsValue
                          parseValue={value => {
                            return !value ? parseFloat('0') : parseFloat(value)
                          }}
                          disabled={!isNew && !!productStock}
                        />
                      </FieldColumn>
                    ) : null}
                  </FieldRow>
                  {!isNew && !!productStock ? (
                    <FieldRow>
                      <FieldColumn className="self-baseline">
                        <Button
                          buttonType={'primary'}
                          type={'button'}
                          label={t(
                            'translation.ProductForm.label-change-price'
                          )}
                          onClick={() => setShowChangePriceModal(true)}
                          className="w-fit"
                        />
                      </FieldColumn>
                      <FieldColumn>
                        <div className="border rounded-sm w-full">
                          <div className="grid grid-cols-2 gap-x-3 p-3">
                            <p>
                              {t(
                                'translation.ProductForm.label-quantity-in-stock'
                              )}
                            </p>
                            <p className="text-right">
                              {`${productStock?.stockCount} ${unitsOfMeasure?.find(
                                e => e?.id === form.values?.uom
                              )?.title}`}
                            </p>
                            <p>
                              {t(
                                'translation.ProductForm.label-purchase-amount'
                              )}
                            </p>
                            <p className="text-right">
                              {`${formatPriceNumberWithDelimiter(
                                productStock?.stockPurchaseValue
                              )} ${form.values?.currencyId}`}
                            </p>
                            <p>
                              {t('translation.ProductForm.label-retail-amount')}
                            </p>
                            <p className="text-right">
                              {`${formatPriceNumberWithDelimiter(
                                productStock?.stockRetailValue
                              )} ${form.values?.currencyId}`}
                            </p>
                            <p>{t('translation.ProductForm.label-margin')}</p>
                            <p className="text-right">
                              {`${formatPriceNumberWithDelimiter(
                                productStock?.marginAmount
                              )} ${form.values?.currencyId}`}
                            </p>
                          </div>
                        </div>
                      </FieldColumn>
                      <FieldColumn></FieldColumn>
                    </FieldRow>
                  ) : null}
                  <FieldRow>
                    <FieldColumn>
                      <FormField.TextArea
                        label={t(
                          'translation.ServiceEditorForm.label-description'
                        )}
                        name="description"
                      />
                    </FieldColumn>
                  </FieldRow>
                  {showChangePriceModal ? (
                    <div className="bg-black/50 absolute top-0 h-full w-full left-0 flex justify-center items-center z-10">
                      <div
                        ref={wrapperRef}
                        className="bg-white lg:w-3/4 w-full lg:rounded-lg p-5 max-w-[700px] h-full lg:h-auto overflow-auto pt-[60px] lg:pt-5 "
                      >
                        <FieldRow>
                          <div className="lg:max-w-[150px] lg:mr-[14px] w-full">
                            <FieldColumn>
                              <FormField.Text
                                label={t('translation.EditProduct.label-code')}
                                name="code"
                              />
                            </FieldColumn>
                          </div>
                          <FieldColumn>
                            <FormField.Text
                              label={t(
                                'translation.ResourceEditorForm.label-firstName'
                              )}
                              name="name"
                              autoFocus
                            />
                          </FieldColumn>
                        </FieldRow>
                        <FieldRow className="lg:gap-10 gap-10">
                          <div className="w-full">
                            <div className="flex gap-3 lg:gap-0 items-start">
                              <FieldColumn>
                                <FormField.Text
                                  label={t(
                                    'translation.ProductForm.label-current-price'
                                  )}
                                  name="retailPrice"
                                  shouldDisplayZeroAsValue
                                  parseValue={value => {
                                    return !value
                                      ? parseFloat('0')
                                      : parseFloat(value)
                                  }}
                                  disabled
                                />
                              </FieldColumn>
                              <FieldColumn>
                                <FormField.Text
                                  label={t(
                                    'translation.ServiceEditorForm.label-vat'
                                  )}
                                  name="vatRate"
                                  shouldDisplayZeroAsValue
                                  parseValue={value => {
                                    return !value
                                      ? parseFloat('0')
                                      : parseFloat(value)
                                  }}
                                  disabled
                                />
                              </FieldColumn>
                            </div>
                            <div>
                              <div className="mt-[10px] h-[30px]"></div>
                              <p className="font-medium mt-3">
                                {t(
                                  'translation.ProductForm.label-current-state'
                                )}
                              </p>
                              <div className="border rounded-sm">
                                <div className="grid grid-cols-2 gap-x-3 p-3">
                                  <p>
                                    {t(
                                      'translation.ProductForm.label-quantity-in-stock'
                                    )}
                                  </p>
                                  <p className="text-right">
                                    {`${productStock?.stockCount} ${unitsOfMeasure?.find(
                                      e => e?.id === form.values?.uom
                                    )?.title}`}
                                  </p>
                                  <p>
                                    {t(
                                      'translation.ProductForm.label-purchase-amount'
                                    )}
                                  </p>
                                  <p className="text-right">
                                    {`${formatPriceNumberWithDelimiter(
                                      productStock?.stockPurchaseValue
                                    )} ${form.values?.currencyId}`}
                                  </p>
                                  <p>
                                    {t(
                                      'translation.ProductForm.label-retail-amount'
                                    )}
                                  </p>
                                  <p className="text-right">
                                    {`${formatPriceNumberWithDelimiter(
                                      productStock?.stockRetailValue
                                    )} ${form.values?.currencyId}`}
                                  </p>
                                  <p>
                                    {t('translation.ProductForm.label-margin')}
                                  </p>
                                  <p className="text-right">
                                    {`${formatPriceNumberWithDelimiter(
                                      productStock?.marginAmount
                                    )} ${form.values?.currencyId}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="w-full">
                            <div className="flex gap-3 lg:gap-0">
                              <FieldColumn>
                                <FormField.Text
                                  label={t(
                                    'translation.ProductForm.label-new-price'
                                  )}
                                  name="newRetailPrice"
                                  shouldDisplayZeroAsValue
                                  forceAutoFocus
                                  parseValue={value => {
                                    return !value
                                      ? parseFloat('0')
                                      : parseFloat(value)
                                  }}
                                />
                              </FieldColumn>
                              <FieldColumn>
                                <FormField.Text
                                  label={t(
                                    'translation.ServiceEditorForm.label-vat'
                                  )}
                                  name="newVatRate"
                                  shouldDisplayZeroAsValue
                                  parseValue={value => {
                                    return !value
                                      ? parseFloat('0')
                                      : parseFloat(value)
                                  }}
                                />
                              </FieldColumn>
                            </div>

                            <div>
                              <div className="flex mt-[10px] h-[30px] items-center">
                                {parseFloat(form.values?.newRetailPrice) <
                                parseFloat(
                                  !form.values?.purchasePrice &&
                                    !!productStock?.stockPurchaseValue &&
                                    !!productStock?.stockCount
                                    ? productStock?.stockPurchaseValue /
                                        productStock?.stockCount
                                    : form.values?.purchasePrice
                                ) ? (
                                  <>
                                    <icons.WarningTriangle
                                      size={'size16'}
                                      color={selectedTheme.colors.accent5}
                                    />
                                    <p className="ml-[10px] text-zoyya-accent5">
                                      Nova cijena je niža od nabavne
                                    </p>
                                  </>
                                ) : null}
                              </div>
                              <p className="font-medium mt-3">
                                {t('translation.ProductForm.label-new-state')}
                              </p>
                              <div className="border rounded-sm">
                                <div className="grid grid-cols-2 gap-x-3 p-3">
                                  <p>
                                    {t(
                                      'translation.ProductForm.label-quantity-in-stock'
                                    )}
                                  </p>
                                  <p className="text-right">{`${productStock?.stockCount} ${unitsOfMeasure?.find(
                                    e => e?.id === form.values?.uom
                                  )?.title}`}</p>
                                  <p>
                                    {t(
                                      'translation.ProductForm.label-purchase-amount'
                                    )}
                                  </p>
                                  <p className="text-right">
                                    {`${formatPriceNumberWithDelimiter(
                                      productStock?.stockPurchaseValue
                                    )} ${form.values?.currencyId}`}
                                  </p>
                                  <p>
                                    {t(
                                      'translation.ProductForm.label-retail-amount'
                                    )}
                                  </p>
                                  <p className="text-right">{`${
                                    !form.errors.newRetailPrice &&
                                    !!form.values.newRetailPrice
                                      ? formatPriceNumberWithDelimiter(
                                          form.values?.newRetailPrice *
                                            (!!productStock?.stockCount
                                              ? productStock?.stockCount
                                              : 0)
                                        )
                                      : 0
                                  } ${form.values?.currencyId}`}</p>
                                  <p>
                                    {t('translation.ProductForm.label-margin')}
                                  </p>
                                  <p className="text-right">{`${
                                    !form.errors.newRetailPrice &&
                                    !!form.values.newRetailPrice &&
                                    !form.errors.newVatRate
                                      ? formatPriceNumberWithDelimiter(
                                          form.values?.newRetailPrice *
                                            (!!productStock?.stockCount
                                              ? productStock?.stockCount
                                              : 0) -
                                            (form.values?.newRetailPrice *
                                              (form.values?.newVatRate / 100) *
                                              (!!productStock?.stockCount
                                                ? productStock?.stockCount
                                                : 0) +
                                              (!!productStock?.stockPurchaseValue
                                                ? productStock?.stockPurchaseValue
                                                : 0))
                                        )
                                      : 0
                                  } ${form.values?.currencyId}`}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </FieldRow>
                        <div className="flex gap-3 justify-end mt-10">
                          <ButtonTW
                            label="Odustani"
                            variant="secondary"
                            onClick={() => setShowChangePriceModal(false)}
                          />
                          <ButtonTW
                            label={t(
                              'translation.ProductForm.label-change-price'
                            )}
                            onClick={() => setConfirmPriceChangeModal(true)}
                            disabled={
                              !form.values?.newRetailPrice ||
                              !!form.errors?.newRetailPrice
                            }
                          />
                        </div>
                        {confirmPriceChangeModal ? (
                          <div className="bg-black/50 absolute top-0 h-full w-full left-0 flex justify-center items-center z-10">
                            <div className="bg-white rounded-lg p-5 max-w-[300px] text-center">
                              <p>
                                {t(
                                  'translation.ProductForm.warning-price-change-1'
                                )}
                              </p>
                              <p className="font-medium mt-5">
                                {t(
                                  'translation.ProductForm.warning-price-change-2'
                                )}
                              </p>

                              <div className="flex justify-center gap-3 mt-4">
                                <ButtonTW
                                  label={t('translation.NewReceipt.label-no')}
                                  size="small"
                                  variant="secondary"
                                  onClick={() =>
                                    setConfirmPriceChangeModal(false)
                                  }
                                />
                                <ButtonTW
                                  label={t('translation.NewReceipt.label-yes')}
                                  size="small"
                                  type={'submit'}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {!isNew ? (
                    <DeleteButton
                      withConfirm
                      onConfirm={onDelete}
                      confirmationMessage={t(
                        'translation.NewClientContainer.title-confirmResourceDelete'
                      )}
                    />
                  ) : null}
                  {!isMobile ? (
                    <FormFooterButtonsContainer>
                      <Button
                        footerButton
                        buttonType={'text'}
                        name="closeForm"
                        type={'button'}
                        label={t('translation.ResourceEditorForm.button-close')}
                        onClick={() =>
                          pathname.includes(`/wms/goodsReceived`)
                            ? // @ts-ignore
                              setShowAddProductForm(false)
                            : navigateTo.productsList({
                                ...params,
                              })
                        }
                      />
                      <Button
                        type={'submit'}
                        buttonType={'primary'}
                        name="saveChanges"
                        label={t('translation.ResourceEditorForm.button-save')}
                      />
                    </FormFooterButtonsContainer>
                  ) : null}
                </FormContainer>
              </FormFieldsWrapper>
            )
          }}
        </Form>
      </FormPageContainer>
      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.ResourceEditorForm.button-close')}
            onClick={() => {
              pathname.includes(`/wms/goodsReceived`)
                ? // @ts-ignore
                  setShowAddProductForm(false)
                : navigateTo.productsList({ ...params })
            }}
          />
          <Button
            onClick={() => formRef.current.submitForm()}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}
