import styled from 'styled-components'
import { transparentize } from 'polished'
import { device } from '../../../constants'

export const PlansContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media ${device.tablet} {
    margin-top: 0px;
    flex-direction: column;
  }
`

export const PlanWrapper = styled.div`
  padding: 25px 25px 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PlanContainer = styled.div<{
  isCurrent?: boolean
  isSMS?: boolean
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  padding: ${props => (props.isCurrent ? '25px' : '0')};
  justify-content: ${props => (props.isSMS ? 'space-between' : 'flex-start')};
  background: ${props =>
    props.isCurrent && props.isSMS
      ? 'transparent'
      : props.isCurrent
      ? 'linear-gradient(90deg,  #6a4ee1, #b87df9)'
      : 'transparent'};
  border: 1px solid ${props => props.theme.colors.outline};
  max-width: 400px;
  height: 225px;
  position: relative;
  &:not(last-child) {
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }

  @media ${device.tablet} {
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
    &:first-child {
      margin-right: 0px;
    }
    &:not(last-child) {
      margin-right: 0px;
    }
  }
`

export const PlanHeader = styled.span<{ isCurrent?: boolean; isSMS?: boolean }>`
  color: ${props =>
    props.isCurrent
      ? props.theme.colors.light
      : props.theme.colors.secondaryDark};
  opacity: ${props => (props.isCurrent ? '0.8' : '1')};
  margin-bottom: ${props => (props.isCurrent ? '30px' : '40px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${props => (props.isSMS ? '60px' : '0')};
`

export const PlanPriceNumber = styled.div``
export const PlanPrice = styled.span<{ isCurrent?: boolean }>`
  font-size: 22px;
  display: flex;
  font-weight: 600;
  align-items: baseline;
  line-height: 22px;
  color: ${props =>
    props.isCurrent ? props.theme.colors.light : props.theme.colors.text};
`

export const PlanIcon = styled.div<{ isCurrent?: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props =>
    transparentize(
      0.8,
      props.isCurrent ? props.theme.colors.light : props.theme.colors.primary
    )};
  border-radius: 100px;
  padding: 10px;
  svg {
    color: ${props =>
      props.isCurrent ? props.theme.colors.light : props.theme.colors.primary};
  }
`
export const PlanPriceDecimal = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
`

export const PlanBillingHint = styled.span`
  color: ${props => props.theme.colors.secondaryDark};
  font-size: 14px;
  margin-top: auto;
  margin-bottom: 35px;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${props => props.theme.colors.secondaryDark};
    font-size: 10px;
    text-transform: uppercase;
  }
`

export const CardHint = styled.span`
  color: ${props => props.theme.colors.secondaryDark};
  font-size: 12px;
`

export const CardType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.secondaryDark};
  span {
    font-size: 10px;
  }
`
export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.outline};
  margin-top: auto;
  padding: 15px 25px 10px 25px;
`

export const CardPrice = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${props => props.theme.colors.text};
    font-size: 18px;
    font-weight: 500;
  }
`
export const PlanTitle = styled.span<{ isCurrent?: boolean; twoRow?: boolean }>`
  font-size: 18px;
  opacity: ${props => (props.isCurrent ? '0.8' : '1')};
  color: ${props =>
    props.isCurrent ? props.theme.colors.light : props.theme.colors.text};
  margin-bottom: 20px;
  display: flex;
  flex-direction: ${props => (props.twoRow ? 'column' : 'row')};
  align-items: baseline;
  margin-top: auto;
  span {
    text-transform: ${props => (props.isCurrent ? 'uppercase' : 'initial')};
    margin-right: 5px;
  }
`

export const PlanTitleWrapper = styled.span`
  margin-right: 10px;
`

export const PlanExpiresWrapper = styled.div`
  display: flex;
  flex-direction: row;
  div:first-child {
    margin-left: 0;
  }
`

export const PlanExpires = styled.div`
  font-size: 12px;
  margin-left: 10px;
`

export const BillingTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`

export const PaymentTitle = styled.span`
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 500;

  @media ${device.tablet} {
    margin-bottom: 5px;
  }
`

export const PaymentTableHeader = styled.div`
  background: ${props => props.theme.colors.outline};
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, minmax(45px, 1fr));
`

export const PaymentHeaderName = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.text};
`
export const PaymentInfo = styled.div`
  margin-left: 15px;
  flex: 1 1 0%;
`

export const PaymentName = styled.p`
  font-size: 14px;
  font-weight: 600;
`

export const PaymentDetail = styled.p`
  font-size: 11.5px;
  color: #555;
`

export const Payment = styled.div`
  height: 88px;
  display: flex;
  align-items: center;

  :not(:last-of-type) {
    border-bottom: 1px solid #f0f0f0;
  }
`
