import React, { useContext, useMemo, useState, useRef } from 'react'
import {
  Formik,
  Form as FormikForm,
  FieldInputProps,
  FormikContextType,
  isPromise,
  FormikProps,
  useFormikContext,
} from 'formik'
import 'rc-tooltip/assets/bootstrap_white.css'
import { FormFieldText } from 'components/Form/Fields/Text'
import { FormFieldDate } from 'components/Form/Fields/Date'
import {
  FormFieldSelect,
  FormFieldColorPicker,
} from 'components/Form/Fields/Select2'
import { FormFieldTextArea } from 'components/Form/Fields/TextArea'
import { FormFieldRichTextEditor } from 'components/Form/Fields/RichTextEditor'
import { FlexRow, FlexColumn } from 'components/Flex/Flex'
import styled from 'styled-components'
import { useAppServices } from 'sdk/appServices'
import { FormFieldCheckbox } from 'components/Form/Fields/Checkbox'
import { Loader } from 'components/Loader/Loader'
import { device } from '../../constants'
import { FormFieldPhoneInput } from 'components/Form/Fields/PhoneInput'
import { FormFieldVerificationInput } from 'components/Form/Fields/VerificationInput'
import { FieldColumn } from 'views/Settings/styles'
import { Prompt } from 'react-router'
import 'react-phone-input-2/lib/bootstrap.css'
import i18n from 'src/localization'
import { FormFieldDateInput } from 'components/Form/Fields/DateInput'
import { FormFieldText2 } from 'components/Form/Fields/Text2'
import { FormFieldTimeInput } from 'components/Form/Fields/TimeInput'
import { FormFieldScrollerInput } from 'components/Form/Fields/ScrollerInput'
import { ButtonStyled } from '../Button/Button'
import { FormFieldOnlyCheckbox } from './Fields/OnlyCheckbox'

export interface IValueChangeHandlers {
  [key: string]: (value: any, values: any, form: FormikContextType<any>) => any
}

type TProps = {
  initialValues?: object
  id?: string
  onSubmit?: (values, formikHelpers?: any) => any
  children?: ((formik: FormikProps<any>) => any) | JSX.Element | JSX.Element[]
  style?: any
  validationSchema?: any
  validateOnMount?: boolean
  isInitialValid?: boolean
  isLoading?: boolean
  isSignupFlow?: boolean
  enableReinitialize?: boolean
  onValueChange?: (
    name: string,
    value: any,
    values: any,
    form: FormikContextType<any>
  ) => any | IValueChangeHandlers
}
type TFormContext = {
  valueChange: (field: FieldInputProps<any>, event: any, value: any) => any
  isSubmitting?: boolean
}
const FormContext = React.createContext<TFormContext | null>(null)
export const useFormContext = () => {
  return useContext(FormContext)
}

export const PromptIfDirty = () => {
  const formik = useFormikContext()
  return (
    <Prompt
      when={formik.dirty && formik.submitCount === 0}
      message={i18n.t('translation.Form.unsavedChangesMessage')}
    />
  )
}
export const Form = (props: TProps) => {
  const submitRef = useRef(false)
  const {
    validationSchema,
    validateOnMount,
    isInitialValid,
    children,
    isLoading,
    isSignupFlow,
    enableReinitialize,
  } = props

  const appServices = useAppServices()
  const initialValues = props.initialValues || {}

  //use a incrementing key to rerender form children after submit
  //we need this to rerender after the submit flag is changed
  const [formKey, setFormKey] = useState(1)

  const endSubmit = () => {
    setFormKey(key => key + 1)
    setTimeout(() => {
      submitRef.current = false
      setFormKey(key => key + 1)
    }, 300)
  }
  const handleSubmit = (values, formikHelpers) => {
    const submitResult = props.onSubmit?.(values, formikHelpers)

    if (isPromise(submitResult)) {
      submitResult.then(endSubmit, endSubmit)
    } else {
      endSubmit()
    }
    return submitResult
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={validateOnMount}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={!!enableReinitialize}
      isInitialValid={isInitialValid}
    >
      {params => {
        const {
          handleReset,
          handleSubmit,
          values,
          isSubmitting,
          isValidating,
          setFieldValue,
          validateForm,
          validateField,
        } = params
        return (
          <FormikForm
            style={props.style}
            onReset={handleReset}
            onSubmit={e => {
              e.preventDefault()
              e.stopPropagation()

              if (submitRef.current || isSubmitting) return

              submitRef.current = true
              return new Promise((resolve, reject) => {
                validateForm(values)
                  .then(validationResult => {
                    const valid = Object.keys(validationResult).length === 0
                    if (valid) {
                      handleSubmit()
                    } else {
                      const errors = (
                        <div>
                          {Object.values(validationResult).map(val => {
                            return <div>{val}</div>
                          })}
                        </div>
                      )

                      appServices.toast.danger(errors)
                      reject(errors)
                      endSubmit()
                    }
                  })
                  .catch(errors => {
                    reject(errors)
                    submitRef.current = false
                  })
              })
            }}
          >
            {!isSignupFlow ? <PromptIfDirty /> : null}
            <FormFieldsContainer
              key={formKey}
              isSubmitting={submitRef.current}
              values={values}
              validationSchema={validationSchema}
              setFieldValue={setFieldValue}
              validateForm={validateForm}
              validateField={validateField}
              isValidating={isValidating}
              onValueChange={props.onValueChange}
              formParams={params}
              showLoader={isLoading}
              isSignupFlow={isSignupFlow}
            >
              {children}
            </FormFieldsContainer>
          </FormikForm>
        )
      }}
    </Formik>
  )
}
const FormFieldsContainer = props => {
  const {
    children,
    values,
    setFieldValue,
    validateField,
    onValueChange,
    formParams,
    isSubmitting,
    isValidating,
    showLoader,
    isSignupFlow,
  } = props
  const formHandler = {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => {
      setFieldValue(field, value, shouldValidate)
      values[field] = value
      if (onValueChange) {
        setTimeout(() => {
          onValueChange(field, value, values, formHandler)
        })
      }
    },
  }
  const formContext: TFormContext = useMemo(
    () => ({
      isSubmitting: isSubmitting,
      isValidating,
      valueChange: (field, event, value) => {
        setFieldValue(field.name, value)
        field.onChange(event)
        values[field.name] = value
        try {
          validateField(field.name)
        } catch (error) {}
        if (onValueChange) {
          setTimeout(() => {
            onValueChange(field.name, value, values, formHandler)
          })
        }
      },
    }),
    [
      values,
      validateField,
      setFieldValue,
      isSubmitting,
      isValidating,
      formHandler,
      onValueChange,
    ]
  )
  return (
    <FormContext.Provider value={formContext}>
      <StyledFieldsContainer>
        {typeof children === 'function'
          ? children({
              ...formParams,
              setFieldValue: formHandler.setFieldValue,
            })
          : children}
        {showLoader ? <LoaderOverlay /> : null}
      </StyledFieldsContainer>
    </FormContext.Provider>
  )
}
export const LoaderOverlay = props => {
  return <Loader isComponent={false} />
}
export const FormField = {
  Text2: FormFieldText2,
  Text: FormFieldText,
  Date: FormFieldDate,
  Select: FormFieldSelect,
  ColorPicker: FormFieldColorPicker,
  TextArea: FormFieldTextArea,
  RichTextEditor: FormFieldRichTextEditor,
  Checkbox: FormFieldCheckbox,
  OnlyCheckbox: FormFieldOnlyCheckbox,
  PhoneInput: FormFieldPhoneInput,
  VerificationInput: FormFieldVerificationInput,
  DateInput: FormFieldDateInput,
  TimeInput: FormFieldTimeInput,
  ScrollInput: FormFieldScrollerInput,
}
export const StyledFieldsContainer = styled.div<any>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.light};
  height: ${props => (props.isSignupFlow ? '100%' : null)};
  button[disabled] {
    background: #eceded;
    pointer-events: none;
  }
  @media ${device.tablet} {
    height: 100%;
    max-height: 100%;
    display: initial;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const StyledFieldRow = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  > :not(:last-child) {
    padding-right: 14px;
  }
`
export const FormContentContainer = styled(FlexColumn)<any>`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  @media ${device.tablet} {
    padding: 10px 0 !important;
    overflow: ${props => (props.isAppointmentModal ? 'unset' : 'hidden')};
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const FormFieldsWrapper = styled.div`
  @media ${device.tablet} {
    ${FieldColumn} {
      &:not(last-child) {
        padding-right: 0px;
      }
    }
  }
`

export const FieldContainer = props => {
  return <StyledFieldRow>{props.children}</StyledFieldRow>
}
export const FormFooterButtonsContainer = styled.div<any>`
  display: flex;
  width: 100%;
  padding: 20px 20px 20px 0px;
  button:first-child {
    margin-right: 1.429rem;
  }
  @media ${device.tablet} {
    margin: 0;
    padding: 15px;
    min-height: 85px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    z-index: 9;
    width: 100%;

    button {
      height: 50px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      :first-child {
        margin-right: 10px;
      }
    }
  }
`
export const FormFooterContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  padding: 1.429rem 1.429rem;
  background: ${props => props.theme.colors.lightBackground};
  border-top: 1px solid ${props => props.theme.colors.outline};
  min-height: 85px;
  & > * {
    margin: 2px;
  }
  @media ${device.tablet} {
    background: ${props => props.theme.colors.light};
    margin: 0;
    padding: 15px;
    min-height: 85px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    z-index: 9;
    width: 100%;

    button {
      height: 50px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      :first-child {
        margin-right: 10px;
      }
    }
  }
`
