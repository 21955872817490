import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Birthday = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 23.5h23M1.5 13v6.5h0a2.005 2.005 0 002 2h17a2.005 2.005 0 002-2V13M7 3v0a1.5 1.5 0 01-3 0h0A5.567 5.567 0 015.109.688h0a.5.5 0 01.782 0h0A5.557 5.557 0 017 3zM20 3v0a1.5 1.5 0 01-3 0h0A5.567 5.567 0 0118.109.688h0a.5.5 0 01.782 0h0A5.557 5.557 0 0120 3zM13.5 3v0a1.5 1.5 0 01-3 0h0A5.567 5.567 0 0111.609.688h0a.5.5 0 01.782 0h0A5.557 5.557 0 0113.5 3zM13 10.5h-2V7h0a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zM6.5 10.5h-2V7h0a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zM19.5 10.5h-2V7h0a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"></path>
        <path d="M22.5 13v0a2.625 2.625 0 11-5.25 0v0A2.625 2.625 0 1112 13v0a2.625 2.625 0 11-5.25 0v0a2.625 2.625 0 11-5.25 0v-1.5h0a1 1 0 011-1h19a1 1 0 011 1z"></path>
      </g>
    </StyledIcon>
  )
}
