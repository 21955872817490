import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Clock = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M20.6 4.4c4.5 4.5 4.5 11.8 0 16.3s-11.8 4.5-16.3 0-4.5-11.8 0-16.3c4.6-4.5 11.8-4.5 16.3 0"
        fill="none"
      ></path>
      <path d="M12.5 7v5.5l6 5.5" fill="none"></path>
    </StyledIcon>
  )
}
