import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import moment from 'moment'
import {
  getRatingStars,
  getStatusColor,
  getStatusLabel,
  resolveFullName,
} from 'src/helpers'
import { useSdk } from 'sdk'
import { ShoppingCart } from 'components/Icon/Icons/ShoppingCart'

export type TAppointmentCardProps = {
  id: string
  notes?: string | null
  status: string | undefined
  date: string
  startTime: string | undefined
  endTime: string | undefined
  reason?: any
  reviewRating?: number | null
  reviewComment?: string | null
  isOnlineBooking?: boolean | null
  services?: any
  totalAmount?: any
  currencyId?: string | undefined | null
  isHotel?: boolean
}

const AppointmentCard = (props: TAppointmentCardProps) => {
  const {
    id,
    notes,
    status,
    date,
    startTime,
    endTime,
    reason,
    reviewRating,
    reviewComment,
    isOnlineBooking,
    services,
    totalAmount,
    currencyId,
    isHotel,
  } = props
  const { t } = useSdk()
  const selectedTheme = useContext(ThemeContext)

  return (
    <div className="flex flex-col relative p-[10px_20px] m-[10px] bg-zoyya-lighterBackground rounded-[4px]">
      <div className="flex items-start p-0 mb-1.5">
        <div className="w-[120px]">
          <span className="text-[14px] font-medium">
            {isHotel ? (
              <div className="flex flex-col justify-center">
                <div>
                  {moment(services[0].startTimeUtc).format('DD.MM.YYYY.')}
                </div>
                <div className="ml-9">-</div>
                <div>
                  {moment(services[0].endTimeUtc).format('DD.MM.YYYY.')}
                </div>
              </div>
            ) : (
              moment(date).format("DD MMM 'YY")
            )}
          </span>
        </div>

        <div className="w-[2px] h-[20px]" />

        <div className="w-[210px]">
          <span className="text-[11px] text-zoyya-secondaryDark">
            {t('translation.ClientDetails.services')}
          </span>
        </div>

        <div className="w-[2px] h-[20px]" />

        <span className="text-[11px] text-zoyya-secondaryDark">
          {isHotel
            ? t('translation.ResourcesList.label-resources')
            : t('translation.NewClientContainer.label-employee')}
        </span>

        <div className="flex flex-col w-[145px] pl-[5px] flex-grow">
          <div
            className="flex flex-col font-medium px-[20px] rounded-[4px] ml-[auto] text-[11px]"
            style={{
              color:
                reason === 'ORG_CANCELED' || reason === 'CLIENT_CANCELED'
                  ? selectedTheme.colors.accent1
                  : getStatusColor(status, selectedTheme),
            }}
          >
            {getStatusLabel(status, t, false, reason)}
          </div>
          {isOnlineBooking ? (
            <ShoppingCart
              size="smaller"
              style={{
                strokeWidth: 1.5,
                position: 'absolute',
                top: 10,
                right: 10,
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="flex">
        <div className="flex flex-col">
          {services?.map(service => {
            return (
              <div className="flex items-start p-0" key={service.id}>
                <span className="text-[12px] text-zoyya-secondaryDark w-[120px]">
                  {isHotel
                    ? null
                    : `${service?.startTime} - ${service?.endTime}`}
                </span>

                <div className="w-[2px] h-[20px]" />

                <span className="w-[210px] font-medium text-[12.5px]">
                  {service?.service?.name}
                </span>

                <div className="w-[2px] h-[20px]" />

                <span className="w-[145px] font-medium text-[12.5px]">
                  {isHotel
                    ? resolveFullName(service?.resource, false)
                    : resolveFullName(service?.employee, false)}
                </span>
              </div>
            )
          })}
        </div>
        <div className="flex flex-col w-full">
          {!!reviewRating
            ? getRatingStars(reviewRating, reviewComment || null)
            : null}
          <span
            className={`text-end ${!!reviewRating ? 'pr-[20px]' : ''}`}
          >{`${totalAmount} ${currencyId === 'EUR' ? '€' : currencyId}`}</span>
        </div>
      </div>
      {notes ? (
        <span className="pt-[3px] text-[12px] text-zoyya-secondaryDark">
          <span className="text-zoyya-accent5">
            {t('translation.ClientDetails.note') + ': '}
          </span>
          {notes}
        </span>
      ) : null}
    </div>
  )
}

export default AppointmentCard
