import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const CalendarSearch = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1.5 23.501a1 1 0 01-1-1v-19a1 1 0 011-1h21a1 1 0 011 1v19a1 1 0 01-1 1zM5.5.5v5M18.5.5v5M.5 7.5h23"></path>
        <path d="M14.328 11.673a4 4 0 11-5.656 5.656 4 4 0 015.656-5.656M14.33 17.33l3.17 3.17"></path>
      </g>
    </StyledIcon>
  )
}
