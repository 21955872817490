import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  MonthDayName,
  DateRangeCalendarContainer,
  DateRangeCalendarDay,
  DateRangeCalendarStyled,
  DateRangeDayName,
  DateRangeName,
  DateArrow,
  DateRangeHeader,
  DateRangeLeft,
} from './styles'
import moment, { Moment } from 'moment'
import { useParams, useLocation } from 'react-router'
import { times, includes } from 'lodash'
import { getISODate } from 'src/helpers'
import { useTranslation } from 'react-i18next'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'
import { ChevronLeft } from 'components/Icon/Icons/ChevronLeft'
import { useHolidayAllQuery } from 'state/graphql'
import styled from 'styled-components'

import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { Text } from 'src/components'
import { MobileContext } from 'src/Context'

export const RangePopup = ({
  setInputDatePeriod,
  setShowCalendar,
  minDate,
  maxDate,
  dateFrom,
  dateTo,
}) => {
  const { t } = useTranslation()
  const { locationId } = useParams<{ locationId: string }>()
  const [hoveredDate, setHoveredDate] = useState<any>()

  const isMobile = useContext(MobileContext)

  const names = [
    t('translation.DateRangePopup.days.day-mon'),
    t('translation.DateRangePopup.days.day-tue'),
    t('translation.DateRangePopup.days.day-wen'),
    t('translation.DateRangePopup.days.day-thu'),
    t('translation.DateRangePopup.days.day-fri'),
    t('translation.DateRangePopup.days.day-sat'),
    t('translation.DateRangePopup.days.day-sun'),
  ]
  type DayInMonth = {
    day: string
    month: string
    year: string
    isHidden?: boolean
  }
  const daysInMonth: DayInMonth[] = []
  const daysInNextMonth: DayInMonth[] = []

  const [currentDate, setCurrentDate] = useState<Moment | null | undefined>()
  const [datePeriod, setDatePeriod] = useState({
    dateFrom: dateFrom,
    dateTo: dateTo,
  })
  const momentMinDate = minDate ? moment(minDate) : null
  const momentMaxDate = maxDate ? moment(maxDate) : null

  const getIsDisabled = date => {
    if (momentMinDate && momentMaxDate) {
      return !(
        moment(date).isSameOrAfter(moment(momentMinDate), 'day') &&
        moment(date).isSameOrBefore(moment(momentMaxDate), 'day')
      )
    } else if (momentMinDate) {
      return moment(date).isBefore(moment(momentMinDate), 'day')
    } else if (momentMaxDate) {
      return moment(date).isAfter(moment(momentMaxDate), 'day')
    } else return false
  }

  useEffect(() => {
    setDatePeriod({ dateFrom, dateTo })
  }, [dateFrom, dateTo])
  useEffect(() => setCurrentDate(datePeriod.dateFrom), [datePeriod])

  const previousMonth = moment(currentDate)
    .subtract(1, 'month')
    .endOf('month')
  const nextMonth = moment(currentDate)
    .add(1, 'month')
    .startOf('month')
  const nextNextMonth = moment(currentDate)
    .add(2, 'month')
    .startOf('month')

  const timesIndex =
    moment(currentDate)
      .startOf('month')
      .isoWeekday() - 1

  times(timesIndex, function() {
    daysInMonth.unshift({
      day: previousMonth.format('DD'),
      month: previousMonth.format('MM'),
      year: previousMonth.format('YYYY'),
      isHidden: true,
    }) // your format
    previousMonth.subtract(1, 'day')
  })

  const monthDate = moment(currentDate).startOf('month') // change to a date in the month of interest

  times(monthDate.daysInMonth(), function(n) {
    daysInMonth.push({
      day: monthDate.format('DD'),
      month: monthDate.format('MM'),
      year: monthDate.format('YYYY'),
    }) // your format
    monthDate.add(1, 'day')
  })

  const timesEndIndex =
    7 -
    moment(currentDate)
      .endOf('month')
      .isoWeekday()

  times(timesEndIndex, function(n) {
    daysInMonth.push({
      day: nextMonth.format('DD'),
      month: nextMonth.format('MM'),
      year: nextMonth.format('YYYY'),
      isHidden: true,
    }) // your format
    nextMonth.add(1, 'day')
  })
  const timesNextIndex =
    moment(currentDate)
      .add(1, 'month')
      .startOf('month')
      .isoWeekday() - 1

  times(timesNextIndex, function() {
    daysInNextMonth.unshift({
      day: monthDate.format('DD'),
      month: monthDate.format('MM'),
      year: monthDate.format('YYYY'),
      isHidden: true,
    }) // your format
    monthDate.subtract(1, 'day')
  })

  const currentNextMonth = moment(currentDate)
    .add(1, 'month')
    .startOf('month') // change to a date in the month of
  // interest

  times(currentNextMonth.daysInMonth(), function(n) {
    daysInNextMonth.push({
      day: currentNextMonth.format('DD'),
      month: currentNextMonth.format('MM'),
      year: currentNextMonth.format('YYYY'),
    }) // your format
    currentNextMonth.add(1, 'day')
  })

  const timesNextMonthEndIndex =
    7 -
    moment(currentDate)
      .add(2, 'month')
      .endOf('month')
      .isoWeekday()

  times(timesNextMonthEndIndex, function(n) {
    daysInNextMonth.push({
      day: nextNextMonth.format('DD'),
      month: nextNextMonth.format('MM'),
      year: nextNextMonth.format('YYYY'),
      isHidden: true,
    }) // your format
    nextNextMonth.add(1, 'day')
  })
  const weight = Number(localStorage.getItem('calendarWidth'))
  const height = Number(localStorage.getItem('calendarHeight'))

  const calendarRef = useRef<any>()
  const calendarHeight = (calendarRef?.current?.clientHeight || height) - 50
  const calendarWidth = calendarRef?.current?.clientWidth || weight

  localStorage.setItem('calendarWidth', calendarWidth)
  localStorage.setItem('calendarHeight', calendarHeight.toString())

  const { data } = useHolidayAllQuery({
    variables: { locationId },
  })
  const holidays = data?.Holiday?.all

  const { pathname, search } = useLocation()

  function getDate(idx, days) {
    return days[idx].year + '-' + days[idx].month + '-' + days[idx].day
  }

  function setDate(idx, days) {
    const date = getDate(idx, days)
    setDatePeriod(
      //if we have the date from and the dateTo is not set, set the datTo
      datePeriod.dateFrom && !datePeriod.dateTo
        ? {
            dateFrom: moment(date).isBefore(moment(datePeriod.dateFrom))
              ? date
              : datePeriod.dateFrom,
            dateTo: moment(date).isBefore(moment(datePeriod.dateFrom))
              ? datePeriod.dateFrom
              : date,
          }
        : //if we have both the dateTo and the dateFrom, reset the dateTo
        datePeriod.dateFrom && datePeriod.dateTo
        ? {
            dateFrom: date,
            dateTo: null,
          }
        : {
            dateFrom: date,
            dateTo: datePeriod.dateTo,
          }
    )
  }

  function getIsSelectedDay(idx, days) {
    const date = getDate(idx, days)
    return (
      (datePeriod.dateFrom && date === datePeriod.dateFrom) ||
      (datePeriod.dateTo && date === datePeriod.dateTo)
    )
  }

  function getIsHoveredDate(idx, days) {
    const date = getDate(idx, days)

    return (
      datePeriod.dateFrom &&
      datePeriod.dateTo &&
      moment(date).isBetween(
        moment(datePeriod.dateFrom),
        moment(datePeriod.dateTo)
      )
    )
  }

  return (
    <DateRangeCalendarContainer
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: isMobile ? 40 : 10,
        paddingBottom: 20,
        left: 10,
      }}
    >
      <DateRangeLeft>
        <DateRangeMonths style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <DateRangeMonth isMobile={isMobile}>
            <DateRangeHeader>
              {isMobile ? (
                <>
                  <DateArrow
                    onClick={() => {
                      setCurrentDate(previousMonth)
                    }}
                  >
                    <ChevronLeft size={'extraSmall'} />
                  </DateArrow>

                  <DateRangeName style={{ marginRight: 'unset' }}>
                    {`${moment(currentDate).format('MMMM')} - ${moment(
                      currentDate
                    )
                      .add(1, 'month')
                      .format('MMMM')}`}
                  </DateRangeName>

                  <DateArrow
                    onClick={() => {
                      setCurrentDate(nextMonth)
                    }}
                  >
                    <ChevronRight size={'extraSmall'} />
                  </DateArrow>
                </>
              ) : (
                <>
                  <DateArrow
                    onClick={() => {
                      setCurrentDate(previousMonth)
                    }}
                  >
                    <ChevronLeft size={'extraSmall'} />
                  </DateArrow>
                  <DateRangeName>
                    {moment(currentDate).format('MMMM')}
                  </DateRangeName>
                </>
              )}
            </DateRangeHeader>

            <DateRangeCalendarStyled ref={calendarRef}>
              {names.map(name => (
                <DateRangeDayName key={name}>{name}</DateRangeDayName>
              ))}
              {daysInMonth.map((day, idx) => {
                const date = getDate(idx, daysInMonth)
                const isDisabled = getIsDisabled(date)

                return (
                  <DateRangeCalendarDay
                    key={idx}
                    isDisabled={isDisabled}
                    onClick={() =>
                      isDisabled ? null : setDate(idx, daysInMonth)
                    }
                    /*  isHoliday={
                      holidays?.find(
                        x => getISODate(x?.date) === getISODate(date)
                      )?.id
                    } */
                  >
                    <MonthDayName
                      isHovered={getIsHoveredDate(idx, daysInMonth)}
                      isSelected={getIsSelectedDay(idx, daysInMonth)}
                      isDisabled={isDisabled}
                      isHidden={daysInMonth[idx].isHidden}
                      isCurrent={moment().format('YYYY-MM-DD') === date}
                    >
                      {daysInMonth[idx].day}
                    </MonthDayName>
                  </DateRangeCalendarDay>
                )
              })}
            </DateRangeCalendarStyled>
          </DateRangeMonth>
          <DateRangeMonth>
            {!isMobile ? (
              <DateRangeHeader>
                <DateRangeName>
                  {moment(currentDate)
                    .add(1, 'month')
                    .format('MMMM')}
                </DateRangeName>

                <DateArrow
                  onClick={() => {
                    setCurrentDate(nextMonth)
                  }}
                >
                  <ChevronRight size={'extraSmall'} />
                </DateArrow>
              </DateRangeHeader>
            ) : null}
            <DateRangeCalendarStyled ref={calendarRef}>
              {names.map(name => (
                <DateRangeDayName key={name}>{name}</DateRangeDayName>
              ))}
              {daysInNextMonth.map((day, idx) => {
                const date = getDate(idx, daysInNextMonth)
                const isDisabled = getIsDisabled(date)

                return (
                  <DateRangeCalendarDay
                    key={idx}
                    isDisabled={isDisabled}
                    /* isHoliday={
                      holidays?.find(
                        x => getISODate(x.date) === getISODate(date)
                      )?.id
                    } */
                    onClick={() =>
                      isDisabled ? null : setDate(idx, daysInNextMonth)
                    }
                  >
                    <MonthDayName
                      isHovered={getIsHoveredDate(idx, daysInNextMonth)}
                      isSelected={getIsSelectedDay(idx, daysInNextMonth)}
                      isHidden={daysInNextMonth[idx].isHidden}
                      isDisabled={isDisabled}
                      isCurrent={moment().format('YYYY-MM-DD') === date}
                    >
                      {daysInNextMonth[idx].day}
                    </MonthDayName>
                  </DateRangeCalendarDay>
                )
              })}
            </DateRangeCalendarStyled>
          </DateRangeMonth>
        </DateRangeMonths>
        <DateRangeButton>
          <ButtonTW
            label={t('translation.DateRangePopup.label-close')}
            variant={'text'}
            onClick={() => setShowCalendar(false)}
            className={'mr-2'}
          />
          <ButtonTW
            label={t('translation.DateRangePopup.label-set')}
            variant={'primary'}
            type="button"
            disabled={!(datePeriod.dateFrom && datePeriod.dateTo)}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setInputDatePeriod({
                dateFrom: datePeriod.dateFrom,
                dateTo: datePeriod.dateTo || datePeriod.dateFrom,
              })
              setShowCalendar(false)
            }}
          />
        </DateRangeButton>
      </DateRangeLeft>
    </DateRangeCalendarContainer>
  )
}

RangePopup.defaultProps = {
  minDate: null,
  maxDate: null,
  canAccessFullData: true,
}

export const DateRangeMonths = styled.div`
  display: flex;
`

export const DateRangeMonth = styled.div<{ isMobile?: boolean }>`
  margin-top: 20px;
  :first-child {
    margin-right: ${props => (props.isMobile ? undefined : '20px')};
  }
`
export const DateRangeButton = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${props => props.theme.colors.outline};

  padding-top: 15px;
`
