import { FormContentContainer, Loader } from 'components'
import { EventContainer, EventDateTitle } from 'views/AppointmentModal/styles'
import React from 'react'
import { ClientFormSwitcher } from 'views/Clients/ClientFormSwitcher'
import { useSdk } from '../../../sdk'
import styled from 'styled-components'
import { device } from '../../../constants'
import { useInviteUser } from 'src/state/queries'
import { formatPhone } from 'src/helpers'
import { useAppServices } from 'src/sdk/appServices'

type TClientFormWProps = {
  values: any
  loadingAddClient?: boolean
  isNew: boolean
  setIsNewClient: any
  title: string
  errors: any
  setFieldValue: any
  onGoBack?: any
  setActiveForm?: any
  clientDetails?: boolean
  isAppointment?: boolean
  refetchClient?: () => any
}
export function ClientFormWrapper(props: TClientFormWProps) {
  const {
    isNew,
    setIsNewClient,
    // title,
    loadingAddClient,
    errors,
    values,
    setFieldValue,
    //onGoBack,
    setActiveForm,
    clientDetails,
    isAppointment,
    refetchClient,
  } = props
  const appServices = useAppServices()
  const [inviteUser] = useInviteUser()

  async function sendInvitation(values) {
    await inviteUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email ? values.email?.trim() : '',
          gender: values.gender || 'Unspecified',
          mobilePhone: formatPhone(values.mobilePhone),
          role: 'CLIENT',
          clientId: values?.id,
        },
      },
    })
    appServices.toast.success(
      t('translation.EditClient.toast-employeeInvited', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
    )
  }
  isNew ? setIsNewClient(true) : setIsNewClient(false)
  const { t } = useSdk()

  return (
    <>
      {loadingAddClient && <Loader isComponent />}

      <FormContentContainer>
        <HeaderRow>
          {isNew ? (
            <EventDateTitle>
              {t('translation.AppointmentModal.title-newClient')}
            </EventDateTitle>
          ) : (
            <EventDateTitle>
              {t('translation.EditClient.editClientDetails-label')}
            </EventDateTitle>
          )}
        </HeaderRow>
        {/* @ts-ignore */}
        <EventContainer clientDetails={clientDetails}>
          <ClientFormSwitcher
            setActiveForm={setActiveForm}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            isEdit={!isNew}
            showSendInviteButton={
              !!values.email && !values.user?.id && !errors.email
            }
            onSendInviteClick={event => {
              event.preventDefault()
              event.stopPropagation()
              sendInvitation(values?.client)
            }}
            isAppointment={isAppointment}
            refetchClient={refetchClient}
          />
        </EventContainer>
      </FormContentContainer>
    </>
  )
}

type THeaderRowProps = {}
export const HeaderRow = styled.div<THeaderRowProps>`
  display: flex;
  align-items: center;
  background: #f4f4ff;
  height: 60px;
  margin-left: -20px;
  margin-top: -20px;
  margin-bottom: 12px;
  width: calc(100% + 40px);
  padding: 20px;
  svg {
    margin-right: 20px;
    cursor: pointer;
  }

  @media ${device.tablet} {
    margin-left: -5px;
    margin-top: -10px;
  }
`
