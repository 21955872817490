import { Button, FormField } from 'components'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
//import { genders } from 'src/helpers'
import React from 'react'
import { useSdk } from 'sdk'
import { useCountryAllQuery } from 'state/graphql'
import { MessagingButtons } from 'components/MessagingButtons/MessagingButtons'
import { getCurrentLanguage } from '../../localization'
import { useGenders } from 'src/sdk/helperHooks'

type TClientHealthFormProps = {
  values: any
  errors: any
  setFieldValue: any
  showSendInviteButton?: boolean
  onSendInviteClick?: () => any
  isEdit?: boolean
}

export const ClientHealthForm = (props: TClientHealthFormProps) => {
  const {
    // errors,
    // setFieldValue,
    values,
    showSendInviteButton,
    onSendInviteClick,
    isEdit,
  } = props
  const genders = useGenders()
  const { t, isMobile } = useSdk()
  const { data: countriesData } = useCountryAllQuery()

  const lang = getCurrentLanguage()
  return (
    <>
      <FieldRow>
        <FieldColumn>
          <FormField.Text
            label={t('translation.AppointmentModal.label-clientFirstName')}
            name="firstName"
            autoFocus={!values?.client?.firstName}
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.Text
            label={t('translation.AppointmentModal.label-clientLastName')}
            name="lastName"
          />
        </FieldColumn>
      </FieldRow>

      <FieldRow>
        <FieldColumn>
          <FormField.Select
            label={t('translation.NewClientContainer.label-gender')}
            name="gender"
            parseValue={val => genders.find(e => e.id === val)}
            formatValue={val => val?.id}
            options={genders}
          />
        </FieldColumn>

        <FieldColumn>
          <FormField.DateInput
            label={lang === 'en' ? 'Date of birth' : 'Datum rođenja'}
            name="birthDate"
            displayFormat="DD.MM.YYYY."
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <FormField.Text
            label={lang === 'en' ? 'Identifier' : 'OIB'}
            name="identifier"
          />
        </FieldColumn>
      </FieldRow>

      <FieldRow>
        <FieldColumn>
          <FormField.Text
            label="Email"
            name="email"
            helpText={t('translation.NewClientContainer.tooltip-clientEmail')}
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.PhoneInput
            label={t('translation.AppointmentModal.label-mobilePhone')}
            placeholder={t(
              'translation.AppointmentModal.placeholder-mobilePhone'
            )}
            helpText={t(
              'translation.NewClientContainer.tooltip-clientMobilePhone'
            )}
            name="mobilePhone"
          />{' '}
        </FieldColumn>
      </FieldRow>
      {isMobile && values?.client?.mobilePhone ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <MessagingButtons
            phoneNumber={values?.client?.mobilePhone}
          ></MessagingButtons>
        </div>
      ) : null}
      <FieldRow>
        {showSendInviteButton &&
          (isEdit ? (
            <FieldColumn>
              <Button
                label={t(
                  'translation.NewClientContainer.label-sendInviteToEmail'
                )}
                buttonType={'secondary'}
                tooltip={t('translation.NewClientContainer.tooltip-sendInvite')}
                onClick={onSendInviteClick}
              />
            </FieldColumn>
          ) : (
            <FieldColumn>
              <FormField.Checkbox
                label={t(
                  'translation.NewClientContainer.label-sendInviteToEmail'
                )}
                name="sendInviteViaEMail"
                type={'checkbox'}
              />
            </FieldColumn>
          ))}
      </FieldRow>

      <FieldRow style={{ alignItems: 'flex-end' }}>
        <FieldColumn>
          <FormField.Text
            label={t('translation.LocationGeneralInfo.label-address')}
            name="address"
            helpText={t('translation.LocationGeneralInfo.tooltip-info-address')}
          />
        </FieldColumn>
      </FieldRow>

      <FieldRow style={{ alignItems: 'flex-end' }}>
        <FieldColumn>
          <FormField.Text
            label={t('translation.LocationGeneralInfo.label-zip-code')}
            name="zipCode"
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.Text
            label={t('translation.LocationGeneralInfo.label-city')}
            name="city"
          />
        </FieldColumn>
      </FieldRow>

      <FieldRow>
        <FieldColumn>
          <FormField.Select
            label={t('translation.LocationGeneralInfo.label-country')}
            name="country"
            formatOptionLabel={e => e.countryName}
            options={countriesData?.country?.all as any[]}
            getOptionLabel={e => e.countryName}
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <FormField.TextArea
            rows={2}
            label={t('translation.NewClientContainer.label-comment')}
            name="comment"
          />
        </FieldColumn>
      </FieldRow>
    </>
  )
}
