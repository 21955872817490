import gql from 'graphql-tag'

export const UserFragmentGql = gql`
  fragment UserFragment on User {
    id
    userName
    firstName
    lastName
    avatarUrl
    nickName
    email
    phone
    language
    address
    gender
    isEmailConfirmed
    isMobilePhoneConfirmed
    hasLocalAccount
    hasFbAccount
    hasGoogleAccount
    role
    avatarUrl
    google_profilePicUrl
    google_firstName
    google_lastName
    google_email
    google_phone
    fb_profilePicUrl
    fb_firstName
    fb_lastName
    fb_email
    fb_phone
    emailNotificationSettings {
      sendBookingEmail
      sendHelpEmail
      sendNewsEmail
      sendReminderEmail
      sendReminderSms
    }
    locations {
      id
      name
      address
      email
      city
      code
      description
      imageUrl
    }
    resource {
      id
      name
      firstName
      lastName
      nickName
      email
      color
      avatarUrl
      user {
        id
        firstName
        lastName
        avatarUrl
        fb_profilePicUrl
        google_profilePicUrl
      }
    }
  }
`
