/* eslint-disable no-confusing-arrow */
import styled, { css } from 'styled-components'
import {
  blue,
  blueDark,
  white,
  boxShadow,
  grayLight,
  red,
  redLight,
  secondaryDarker,
  grayLighter,
  green,
  greenDark,
  redDark,
} from '../style/colors'
import { fontFamily, fontSize, lineHeight } from '../style/fonts'
import { borderRadius } from '../style/borders'

/* styles common to all buttons */
export const ButtonBase = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${fontSize};
  line-height: ${lineHeight};
  border-radius: ${borderRadius};
  cursor: pointer;
  user-select: none;
  border: 0;
  -webkit-appearance: none;
  position: relative;
  transition-property: background-color, border-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 0 0 auto;
  font-family: ${fontFamily};
  background-color: ${white};
  color: #252631;
  padding-top: 0;
  padding-bottom: 0;
  :hover {
    transition-property: background-color, border-color, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }
  :active {
    top: 1px;
  }
  :focus {
    box-shadow: 0 0 0 3px ${boxShadow};
    outline: 0;
  }

  span {
    width: auto !important;
  }
`

/* theme variants */
export const primary = css`
  background-color: ${blue};
  color: ${white};
  border: 1px solid ${blue};
  :hover {
    background-color: ${blueDark};
    border-color: ${blueDark};
    color: ${white};
  }
`
export const primaryOutline = css`
  background-color: ${white};
  color: ${blue};
  border: 1px solid ${blue};
  :hover {
    background-color: ${white};
    border-color: ${blueDark};
    color: ${blueDark};
  }
`

export const accent = css`
  background-color: #fe9060;
  color: ${white};
  border: 1px solid #fe9060;
  :hover {
    background-color: #fe743c;
    border-color: #fe743c;
    color: ${white};
  }
`

export const secondaryLink = css`
  background-color: ${white};
  color: ${blue};
  border: 1px solid ${blue};
  :hover {
    background-color: ${white};
    border-color: ${blueDark};
    color: ${blueDark};
  }
`

export const primaryAccent = css`
  background-color: ${white};
  color: #fe9060;
  border: 1px solid #fe9060;
  :hover {
    background-color: ${white};
    border-color: #fe8c4b;
    color: #fe8c4b;
  }
`

export const secondaryBlue = css`
  background-color: #3bcbe8;
  color: ${white};
  :hover {
    background-color: ${white};
    border-color: #3bcbe8;
    color: #3bcbe8;
  }
`

export const secondaryGreen = css`
  background-color: ${white};
  color: ${green};
  border: 1px solid ${green};
  :hover {
    background-color: ${white};
    border-color: ${greenDark};
    color: ${greenDark};
  }
`

export const secondaryRed = css`
  background-color: ${white};
  color: ${redDark};
  border: 1px solid ${redDark};
  :hover {
    background-color: ${white};
    border-color: ${red};
    color: ${red};
  }
`

export const secondaryAccent = css`
  background-color: #ffbd00;
  color: ${white};
  border: 1px solid transparent;
  :hover {
    background-color: #dda507;
  }
`

export const redButton = css`
  background-color: ${redDark};
  color: ${white};
  border: 1px solid ${redDark};
  :hover {
    background-color: ${redDark};
  }
`

export const secondary = css`
  background-color: ${white};
  color: ${secondaryDarker};
  border: 1px solid ${grayLight};

  :hover {
    color: #252631;
    border-color: #252631;
  }

  :focus {
    border-color: ${blue};
    color: #252631;
  }
`
export const link = css`
  background-color: transparent;
  border: 0;
  color: ${blue};
  :hover {
    color: ${blueDark};
  }
`
export const text = css`
  background-color: transparent;
  :hover {
    color: #252631;
  }
`

export const hasError = css`
  background: ${redLight};
  color: ${red} !important;
  border: 1px solid ${red} !important;
`

/* size variants */
export const small = css`
  padding-left: 0.571rem;
  padding-right: 0.571rem;
  height: 38px;
  font-size: 13px;
`

export const smaller = css`
  padding-left: 0.571rem;
  padding-right: 0.571rem;
  height: 32px;
  font-size: 13px;
`

export const extraSmall = css`
  padding-left: 7px;
  padding-right: 7px;
  height: 17px;
  line-height: 12px;
  font-size: 10px;
`

export const medium = css`
  padding-right: 1.143rem;
  padding-left: 1.143rem;
  height: 40px;
`
export const large = css`
  padding-right: 1.143rem;
  padding-left: 1.143rem;
  height: 48px;
`

export const extraLarge = css`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  height: 60px;
`

/* state variants */
export const disabled = css`
  background-color: ${grayLighter};
  color: ${blue};
  cursor: not-allowed;
  border: 1px solid ${grayLight};
`

export const fullWidth = css`
  width: 100%;
  justify-content: center;
`

export const isSelect = css`
  justify-content: flex-start;
`

export const ButtonArrow = styled.div`
  display: flex;
  margin-right: -0.286rem;
  margin-left: auto;
  svg {
    width: 1.429rem;
    height: 1.429rem;
  }
`

export const ButtonLabel = styled.div`
  margin-left: ${props => (props.hasIcon && !props.iconRight ? '5px' : '0px')};
  margin-right: ${props => (props.hasIcon ? '5px' : '0px')};
  max-width: ${props => (props.isTableFilter ? '85px' : '580px')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: ${props => (props.isColor ? props.color : 'none')};
  width: ${props => (props.isColor ? '100%' : 'auto')};
  color: ${props =>
    props.isColor ? 'white' : props.isPlaceholder ? '#98A9BC' : 'inherit'};
  border-radius: 4px;
  max-height: inherit;
  line-height: 17px;
`
export const accentBlue = css`
  background: linear-gradient(90deg, #6a4ee1, #b87df9);
  color: ${white};
  border: 1px solid #b87df9;
  :hover {
    background-color: #6a4ee1;
    border-color: #6a4ee1;
    color: ${white};
  }

  ${ButtonLabel} {
    background: transparent;
    background-color: transparent;
  }
`
