import { useContext } from 'react'
import moment from 'moment'
import { debounce, sumBy, chain } from 'lodash'
import i18n, { t } from 'src/localization'
import {
  employeeScheduleRowHeight,
  eventRowHeight,
  workScheduleRowHeight,
} from './styles'
import React, { useEffect, useState } from 'react'
import { OrganizationSwitchIcon } from 'layout/styles'
import Avatar, { AvatarWrapper } from 'components/Avatar/Avatar'
import { views } from './components/ReactBigCalendar/utils/constants'
import { icons } from 'components'
import parsePhoneNumber from 'libphonenumber-js'
import Tooltip from 'src/components/Tooltip/Tooltip'
import { ThemeContext } from 'styled-components'
import { getAuthToken } from './sdk/Auth'
import { environment } from 'sdk/environment'
import { RouterOutput } from './trpc'

export const getOptionStatus = (
  appointment,
  hasPrepaymentsCredentials,
  totalServicesPrice
) => {
  return [
    ...(appointment?.status === 'DRAFT'
      ? appointment?.posPaymentRequest?.status === 'PENDING_PAYMENT' ||
        appointment?.posPaymentRequest?.status === 'CANCELED' ||
        (!appointment?.posPaymentRequest?.id &&
          hasPrepaymentsCredentials &&
          totalServicesPrice > 0)
        ? [
            {
              id: 'confirmBooking',
              title: i18n.t('translation.AppointmentModal.confirmBooking'),
            },
            {
              id: 'cancelBooking',
              title: i18n.t('translation.AppointmentModal.cancelBooking'),
            },
            {
              id: 'sendPaymentNotification',
              title: i18n.t(
                'translation.AppointmentModal.sendPaymentNotification'
              ),
            },
          ]
        : [
            {
              id: 'confirmBooking',
              title: i18n.t('translation.AppointmentModal.confirmBooking'),
            },
            {
              id: 'cancelBooking',
              title: i18n.t('translation.AppointmentModal.cancelBooking'),
            },
          ]
      : appointment?.status === 'CONFIRMED'
      ? appointment?.posPaymentRequest?.status === 'PENDING_PAYMENT' ||
        appointment?.posPaymentRequest?.status === 'CANCELED' ||
        (!appointment?.posPaymentRequest?.id &&
          hasPrepaymentsCredentials &&
          totalServicesPrice > 0)
        ? [
            {
              id: 'finish',
              title: i18n.t('translation.AppointmentModal.concludeEvent'),
            },
            {
              id: 'cancel',
              title: i18n.t('translation.AppointmentModal.cancelEvent'),
            },
            {
              id: 'sendPaymentNotification',
              title: i18n.t(
                'translation.AppointmentModal.sendPaymentNotification'
              ),
            },
          ]
        : [
            {
              id: 'finish',
              title: i18n.t('translation.AppointmentModal.concludeEvent'),
            },
            {
              id: 'cancel',
              title: i18n.t('translation.AppointmentModal.cancelEvent'),
            },
          ]
      : appointment?.status === 'CONCLUDED' ||
        appointment?.status === 'concluded' ||
        appointment?.status === 'canceled'
      ? [
          {
            id: 'confirm',
            title: i18n.t('translation.AppointmentModal.changeToConfirmed'),
          },
        ]
      : []),
  ]
}

export const analyticsTrack = (event, metadata) => {
  let analytics = ((window as any).analytics = (window as any).analytics || [])
  if (analytics && typeof analytics.track === 'function') {
    analytics.track(event, metadata)
  }
}

export const isEmail = email => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.trim()
  )
}

export const formatTime = (appointment, isBooking) => {
  let startTime = ''
  if (isBooking) {
    startTime = moment(appointment?.startTimeBooking).format('HH:mm')
  } else {
    startTime = appointment?.startTime
  }
  const minutes = startTime?.split(':')[1]
  const hour = startTime?.split(':')[0]

  const minutesWithHours =
    parseInt(hour) * 60 + parseInt(minutes) + parseInt(appointment?.duration)

  const newMinutes = Math.floor(minutesWithHours % 60)
  const newHours = Math.floor(minutesWithHours / 60)

  return (
    (newHours < 10 ? '0' + newHours : newHours) +
    ':' +
    (newMinutes < 10 ? '0' + newMinutes : newMinutes)
  )
}

export const calculateDuration = event => {
  return moment(`2000-01-01 ${event.endTime}`).diff(
    moment(`2000-01-01 ${event.startTime}`),
    'minutes'
  )
}

export function getEventPosition(event, isWorkSchedule, isEmployeeSchedule) {
  const rowHeight = isWorkSchedule
    ? workScheduleRowHeight
    : isEmployeeSchedule
    ? employeeScheduleRowHeight
    : eventRowHeight
  const numberOfMinutes = event.duration
    ? parseInt(event.duration)
    : calculateDuration(event)
  const heightOf1Minute = rowHeight / 15
  const height = numberOfMinutes * heightOf1Minute

  const timeOfHour = event.startTime.split(':')[1]
  const hourSection =
    timeOfHour === '00'
      ? 0
      : timeOfHour === '15'
      ? 1
      : timeOfHour === '30'
      ? 2
      : 3
  const hour = parseInt(event.startTime.split(':')[0])

  const positionOfTheHour = rowHeight * 4 * hour //eventRowHeight represents
  // 15 min, so x2 to represent an hour. We multiply that by the number of hours

  const positionOfMinutes = hourSection * rowHeight
  const topPosition = positionOfTheHour + positionOfMinutes
  return {
    height,
    topPosition,
  }
}

export function getCurrentTimePosition(isWorkSchedule, isEmployeeSchedule) {
  const rowHeight = isWorkSchedule
    ? workScheduleRowHeight
    : isEmployeeSchedule
    ? employeeScheduleRowHeight
    : eventRowHeight
  const minutes = moment().minute()
  const minutesDivider = rowHeight / (minutes + 1)
  const hourSection =
    minutes < 15
      ? 0
      : minutes === 15 || minutes < 30
      ? 1
      : minutes === 30 || minutes < 45
      ? 2
      : 3

  const minutesSection = minutes * minutesDivider
  const hour = moment().hour()
  const positionOfTheHour = rowHeight * 4 * hour //eventRowHeight represents
  // 15 min, so x2 to represent an hour. We multiply that by the number of hours

  const positionOfMinutes = hourSection * rowHeight
  const topPosition = positionOfTheHour + positionOfMinutes + minutesSection

  return {
    topPosition,
  }
}

export const getMomentDays = dayId => {
  const getDayName = idx => moment(dayId).startOf('week').add(idx, 'day')
  const getDayNumber = idx => moment(dayId).startOf('week').add(idx, 'day')

  return [
    {
      id: getDayName(0).format('YYYY-MM-DD'),
      name: getDayName(0).format('dddd'),
      day: getDayNumber(0).format('DD'),
      isToday: getDayNumber(0).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(1).format('YYYY-MM-DD'),
      name: getDayName(1).format('dddd'),
      day: getDayNumber(1).format('DD'),
      isToday: getDayNumber(1).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(2).format('YYYY-MM-DD'),
      name: getDayName(2).format('dddd'),
      day: getDayNumber(2).format('DD'),
      isToday: getDayNumber(2).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(3).format('YYYY-MM-DD'),
      name: getDayName(3).format('dddd'),
      day: getDayNumber(3).format('DD'),
      isToday: getDayNumber(3).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(4).format('YYYY-MM-DD'),
      name: getDayName(4).format('dddd'),
      day: getDayNumber(4).format('DD'),
      isToday: getDayNumber(4).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(5).format('YYYY-MM-DD'),
      name: getDayName(5).format('dddd'),
      day: getDayNumber(5).format('DD'),
      isToday: getDayNumber(5).isSame(new Date(), 'day'),
    },
    {
      id: getDayName(6).format('YYYY-MM-DD'),
      name: getDayName(6).format('dddd'),
      day: getDayNumber(6).format('DD'),
      isToday: getDayNumber(6).isSame(new Date(), 'day'),
    },
  ]
}

export const themeAccentColors = [
  { id: '#3bcbe8', color: '#3bcbe8', title: '#3bcbe8' },
  { id: '#4bafb4', color: '#4bafb4', title: '#4bafb4' },
  { id: '#d282af', color: '#d282af', title: '#d282af' },
  { id: '#ffbd00', color: '#ffbd00', title: '#ffbd00' },
  { id: '#9373b4', color: '#9373b4', title: '#9373b4' },
  { id: '#6A4EE1', color: '#6A4EE1', title: '#6A4EE1' },
  { id: '#ce5f8f', color: '#ce5f8f', title: '#ce5f8f' },
  { id: '#FF974A', color: '#FF974A', title: '#FF974A' },
  { id: '#fe4d97', color: '#fe4d97', title: '#fe4d97' },
  { id: '#4D7CFE', color: '#4D7CFE', title: '#4D7CFE' },
  { id: '#ff6b52', color: '#ff6b52', title: '#ff6b52' },
  { id: '#97bf62', color: '#97bf62', title: '#97bf62' },
  { id: '#5b7fff', color: '#5b7fff', title: '#5b7fff' },
  { id: '#e16c62', color: '#e16c62', title: '#e16c62' },
  { id: '#4ed4e1', color: '#4ed4e1', title: '#4ed4e1' },
  { id: '#cd8452', color: '#cd8452', title: '#cd8452' },
  { id: '#949a8e', color: '#949a8e', title: '#949a8e' },
  { id: '#009900', color: '#009900', title: '#009900' },
  { id: '#FFB3BA', color: '#FFB3BA', title: '#FFB3BA' },
  { id: '#FFDFBA', color: '#FFDFBA', title: '#FFDFBA' },
  { id: '#FFFFBA', color: '#FFFFBA', title: '#FFFFBA' },
  { id: '#BAFFC9', color: '#BAFFC9', title: '#BAFFC9' },
  { id: '#809BCD', color: '#809BCD', title: '#809BCD' },
  { id: '#95B8D1', color: '#95B8D1', title: '#95B8D1' },
  { id: '#B8E0D2', color: '#B8E0D2', title: '#B8E0D2' },
  { id: '#E9C4D5', color: '#E9C4D5', title: '#E9C4D5' },
  { id: '#FFE9CA', color: '#FFE9CA', title: '#FFE9CA' },
  { id: '#FDC2C2', color: '#FDC2C2', title: '#FDC2C2' },
  { id: '#FDC2ED', color: '#FDC2ED', title: '#FDC2ED' },
  { id: '#D2C0FF', color: '#D2C0FF', title: '#D2C0FF' },
  { id: '#D6F6FF', color: '#D6F6FF', title: '#D6F6FF' },
  { id: '#CAFAC2', color: '#CAFAC2', title: '#CAFAC2' },
  { id: '#F3FFC2', color: '#F3FFC2', title: '#F3FFC2' },
]

export const getHoursInDay = timeSlotDuration => {
  let x = timeSlotDuration || 15 //minutes interval
  let times: any[] = [] // time array
  let tt = 0 // start time

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    let mm = tt % 60 // getting minutes of the hour in 0-55 format

    times[i] = {
      id: ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2),
      title: ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2),
    }
    tt = tt + x
  }
  return times
}

export const getHoursAndMinutes = (
  timeSlotDuration: number,
  isMobile: boolean
) => {
  let hours: any[] = []
  let minutes: any[] = []

  for (let i = 0, j = 0; i < 24; i++) {
    let iValue = i < 10 ? '0' + i.toString() : i.toString()
    let jValue = j < 10 ? '0' + j.toString() : j.toString()
    hours[i] = isMobile
      ? iValue
      : {
          id: iValue,
          title: iValue,
        }
    if (j < 56) {
      minutes[i] = isMobile
        ? jValue
        : {
            id: jValue,
            title: jValue,
          }
      j += timeSlotDuration > 60 ? 5 : timeSlotDuration
    }
  }
  return {
    hours: hours,
    minutes: minutes,
  }
}

export const getServiceHoursAndMinutes = durationMinutes => {
  let hours = durationMinutes / 60
  let rhours = Math.floor(hours)
  let minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)
  const time =
    (rhours < 10 ? '0' + rhours : rhours) +
    ':' +
    (rminutes < 10 ? '0' + rminutes : rminutes)
  return time
}

export const getServiceDurationInMinutes = durationMinutes => {
  if (typeof durationMinutes !== 'string') {
    return durationMinutes
  }
  const time = durationMinutes?.split(':')
  const hours = time[0]
  const minutes = time[1]
  const durationInMinutes = Number(hours) * 60 + Number(minutes)
  return durationInMinutes
}

export const getOrgId = () => {
  return localStorage.getItem('orgId') &&
    localStorage.getItem('orgId') !== 'null'
    ? localStorage.getItem('orgId')
    : null
}

export const getLocationId = () => {
  return (
    sessionStorage.getItem('locationId') || localStorage.getItem('locationId')
  )
}

export const getUserId = () => {
  return localStorage.getItem('user')?.id
}

export const getClientAvatar = (resource, isColor, size) => {
  return resource?.user?.avatarUrl &&
    !resource?.user?.avatarUrl.endsWith('null') ? (
    <Avatar
      style={{ margin: '0px 4px' }}
      size={size}
      src={resource?.user?.avatarUrl}
    />
  ) : resource?.user?.google_profilePicUrl ? (
    <Avatar
      style={{ margin: '0px 4px' }}
      size={size}
      src={resource?.user?.google_profilePicUrl}
    />
  ) : resource?.user?.fb_profilePicUrl ? (
    <Avatar
      style={{ margin: '0px 4px' }}
      size={size}
      src={resource?.user?.fb_profilePicUrl}
    />
  ) : (
    <AvatarWrapper size={size} style={{ marginRight: 10 }}>
      <OrganizationSwitchIcon
        style={{ width: 32, height: 32, margin: '0px 4px' }}
        background={resource?.color || '#6a4ee1'}
        size={size}
      >
        {resource?.firstName ? resource?.firstName.substring(0, 2) : 'NN'}
      </OrganizationSwitchIcon>
    </AvatarWrapper>
  )
}

export const getUserAvatar = (resource, isColor, size) => {
  let userAvatar: string =
    resource?.avatarUrl && !resource?.avatarUrl.endsWith('null')
      ? resource?.avatarUrl
      : resource?.user?.avatarUrl && !resource?.user?.avatarUrl.endsWith('null')
      ? resource?.user?.avatarUrl
      : !!resource?.user?.google_profilePicUrl
      ? resource?.user?.google_profilePicUrl
      : !!resource?.user?.fb_profilePicUrl
      ? resource?.user?.fb_profilePicUrl
      : null

  const noAvatarLetters =
    resource?.kind === 'RESOURCE'
      ? resource?.firstName?.substring(0, 1)
      : resource?.firstName && resource?.firstName
      ? resource?.firstName?.substring(0, 1) +
        resource?.lastName?.substring(0, 1)
      : resource?.user?.firstName && resource?.user?.lastName
      ? resource?.user?.firstName?.substring(0, 1) +
        resource?.user?.lastName?.substring(0, 1)
      : 'NN'

  return isColor ? (
    userAvatar ? (
      <Avatar
        size={size}
        src={userAvatar.replace('(', '%28').replace(')', '%29')}
      />
    ) : (
      <AvatarWrapper size={size}>
        <OrganizationSwitchIcon
          background={resource?.color || '#6a4ee1'}
          size={size}
        >
          {noAvatarLetters}
        </OrganizationSwitchIcon>
      </AvatarWrapper>
    )
  ) : (
    userAvatar
  )
}

export const getLocationImage = location => {
  const imageUrl = location?.imageUrl || location?.avatarUrl

  return !imageUrl || imageUrl?.endsWith('null')
    ? '/assets/noImage.svg'
    : imageUrl
}

export const getAttachmentImage = attachment => {
  if (!attachment) return null
  const isPdf = attachment.mimeType?.endsWith('pdf')
  if (isPdf) return '/assets/pdf-logo.png'

  const imageUrl = attachment.thumbnailUrl

  return !imageUrl || imageUrl?.endsWith('null')
    ? '/assets/noImage.svg'
    : imageUrl
}
// we use hooks to import these values because the i18next resources must be reloaded on language and namespace changes
// moved to sdk/helperHooks.ts
/* export const genders = [
  { id: 'Female', title: t('translation.helpers.female') },
  { id: 'Male', title: t('translation.helpers.male') },
  { id: 'Unspecified', title: t('translation.helpers.dont-say') },
]

export const roles = [
  { id: 'OWNER', title: i18n.t('translation.helpers.Owner') },
  { id: 'ADMIN', title: i18n.t('translation.helpers.administrator') },
  { id: 'USER', title: i18n.t('translation.helpers.User') },
] */

export const defaultWorkShift = () => {
  return {
    mon: [{ openTime: '08:00', closeTime: '16:00' }],
    tue: [{ openTime: '08:00', closeTime: '16:00' }],
    wed: [{ openTime: '08:00', closeTime: '16:00' }],
    thu: [{ openTime: '08:00', closeTime: '16:00' }],
    fri: [{ openTime: '08:00', closeTime: '16:00' }],
    sat: [],
    sun: [],
  }
}

function getSize() {
  const isClient = typeof window === 'object'
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

export function useViewportDimensions() {
  const [dimensions, setDimensions] = useState({
    isMobile: window?.innerWidth < 1020,
  })
  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) {
      return
    }

    const handleResize = debounce(() => {
      const dimensionsCalc = {
        isMobile: window?.innerWidth < 1020,
      }

      if (dimensionsCalc.isMobile !== dimensions.isMobile) {
        setDimensions(dimensionsCalc)
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [dimensions]) // Empty array ensures that effect is only run on mount and unmount

  return dimensions
}
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) {
      return
    }

    const handleResize = debounce(() => {
      setWindowSize(getSize())
    }, 400)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export const getHour = date => {
  return moment(date).format('HH:mm')
}

export const getISODate = (date?) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getStartOfWeek = date => {
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  })
  return moment(date).startOf('week').format('YYYY-MM-DD')
}

export const getEndOfWeek = date => {
  return moment(date).endOf('week').format('YYYY-MM-DD')
}

export const getEndOfWeekMobile = (dayId, view) => {
  return view === views.DAY
    ? moment(dayId).format('YYYY-MM-DD')
    : view === views.THREE_DAY
    ? moment(dayId).add(3, 'day').format('YYYY-MM-DD')
    : view === views.WEEK
    ? getEndOfWeek(dayId)
    : moment(dayId).format('YYYY-MM-DD')
}

export const getStartOfWeekMobile = (view, dayId) => {
  const dateFromInMobile =
    view === views.DAY
      ? moment(dayId).format('YYYY-MM-DD')
      : view === views.THREE_DAY
      ? moment(dayId).format('YYYY-MM-DD')
      : view == views.WEEK
      ? getStartOfWeek(dayId)
      : moment(dayId).format('YYYY-MM-DD')
  return dateFromInMobile
}

export const getPreviousDate = (currentView, dayId) => {
  return currentView === 'threeDay'
    ? moment(dayId).subtract(3, 'day').format('YYYY-MM-DD')
    : currentView === 'month'
    ? moment(dayId).startOf('month').subtract(1, 'month').format('YYYY-MM-DD')
    : moment(dayId).subtract(7, 'day').format('YYYY-MM-DD')
}

export const getNextDate = (currentView, dayId) => {
  return currentView === 'threeDay'
    ? moment(dayId).add(3, 'day').format('YYYY-MM-DD')
    : currentView === 'month'
    ? moment(dayId).startOf('month').add(1, 'month').format('YYYY-MM-DD')
    : moment(dayId).add(7, 'day').format('YYYY-MM-DD')
}

export const getCurrentHour = (reservationId, hour, view) => {
  if (reservationId && hour) {
    const selectedHour = moment(getISODate() + ' ' + hour)
    return new Date(
      new Date().setHours(moment(selectedHour).subtract(1, 'hour').hour(), 0)
    )
  } else {
    return new Date(new Date().setHours(moment().subtract(1, 'hour').hour(), 0))
  }
}

export const getStartOfMonth = date => {
  return moment(date).startOf('month').subtract(5, 'day').format('YYYY-MM-DD')
}

export const getEndOfMonth = date => {
  return moment(date).endOf('month').add(5, 'day').format('YYYY-MM-DD')
}

export const getStartOfYear = date => {
  return moment(date).startOf('year').format('YYYY-MM-DD')
}

export const getEndOfYear = date => {
  return moment(date).endOf('year').format('YYYY-MM-DD')
}

export const getTodayISO = () => {
  return moment().format('YYYY-MM-DD')
}

export const getDuration = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return (
    (hours > 0 ? hours + ' h ' : '') + (minutes > 0 ? minutes + ' min' : '')
  )
}

export const getStatusLabel = (label, t, isMobile, reason) => {
  switch (label) {
    case 'CONFIRMED':
      return isMobile ? '✓' : t('translation.status.confirmed')
    case 'new':
      return isMobile ? '⟲' : t('translation.status.new')
    case 'DRAFT':
      return isMobile ? '⟲' : t('translation.status.draft')
    case 'PENDING_PAYMENT':
      return isMobile ? '⟲' : t('translation.status.payment-pending')
    case 'PAID':
      return t('translation.status.paid')
    case 'CANCELED':
      return t('translation.status.payment-canceled')
    case 'REFUNDED':
      return t('translation.status.payment-refunded')
    case 'canceled':
      return isMobile
        ? '⤫'
        : reason === 'CLIENT_NO_SHOW'
        ? t('translation.helpers.noShow')
        : reason === 'CLIENT_CANCELED'
        ? t('translation.helpers.clientCanceled')
        : t('translation.status.canceled')
    case 'concluded':
      return isMobile ? '✓' : t('translation.helpers.concluded')
    default:
      return isMobile ? '⟲' : t('translation.status.new')
  }
}

export const getStatusLabelComplex = (label, t, isMobile, reason) => {
  switch (label) {
    case 'CONFIRMED':
      return t('translation.status.confirmed')
    case 'new':
      return t('translation.status.new')
    case 'DRAFT':
      return t('translation.status.draft')
    case 'PENDING_PAYMENT':
      return t('translation.status.payment-pending')
    case 'PAID':
      return t('translation.status.paid')
    case 'CANCELED':
      return t('translation.status.payment-canceled')
    case 'REFUNDED':
      return t('translation.status.payment-refunded')
    case 'canceled':
      return reason === 'CLIENT_NO_SHOW'
        ? t('translation.helpers.noShow')
        : reason === 'CLIENT_CANCELED'
        ? t('translation.helpers.clientCanceled')
        : t('translation.status.canceled')
    case 'concluded':
      return t('translation.helpers.concluded')
    default:
      return t('translation.status.new')
  }
}

export const getStatusColor = (label, selectedTheme) => {
  switch (label) {
    case 'CONFIRMED':
      return selectedTheme.colors.accent2
    case 'new':
      return selectedTheme.colors.primary
    case 'DRAFT':
      return selectedTheme.colors.accent5
    case 'PENDING_PAYMENT':
      return selectedTheme.colors.accent5
    case 'PAID':
      return selectedTheme.colors.accent2
    case 'CANCELED':
      return selectedTheme.colors.accent1
    case 'REFUNDED':
      return selectedTheme.colors.accent5
    case 'canceled':
      return selectedTheme.colors.accent1
    case 'noShow':
      return selectedTheme.colors.accent1
    case 'concluded':
      return selectedTheme.colors.primary
    default:
      return selectedTheme.colors.primary
  }
}

export const getStatusIcon = label => {
  switch (label) {
    case 'CONFIRMED':
      return <icons.CheckMarkIcon size={'smaller'} />
    case 'new':
      return <icons.CheckMarkIcon size={'smaller'} />
    case 'DRAFT':
      return <icons.Shop size={'smaller'} />
    case 'PENDING_PAYMENT':
      return <icons.CreditCard size={'smaller'} />
    case 'CANCELED':
      return <icons.CardCanceled size={'smaller'} />
    case 'PAID':
      return <icons.CreditCard size={'smaller'} />
    case 'REFUNDED':
      return <icons.CreditCard size={'smaller'} />
    case 'canceled':
      return <icons.Cross size={'smaller'} />
    case 'noShow':
      return <icons.Cross size={'smaller'} />
    default:
      return <icons.CheckMarkIcon size={'smaller'} />
  }
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        typeof ref.current.contains != 'function' ||
        ref.current.contains(event.target)
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export function useOnClickOutsideWithoutTriggeringAction(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (
        !ref.current ||
        typeof ref.current.contains != 'function' ||
        ref.current.contains(event.target)
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export const getDurationString = duration => {
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)

  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes)
  )
}

export const normalizeMobilePhoneNumber = number => {
  let normalized = number
  if (!normalized) return
  normalized = normalized.replace(/[^\d+]+/g, '')
  normalized = normalized.replace(/^3850/, '+385')
  normalized = normalized.replace(/^385/, '+385')
  return normalized
}
export const getServiceDuration = selectedServices => {
  return sumBy(selectedServices, 'durationMinutes')
}

export const getEndTime = (timeId, selectedServices) => {
  const duration = getServiceDuration(selectedServices)
  const time = moment().format('YYYY-MM-DD') + ' ' + timeId
  return moment(time).add(duration, 'minutes').format('HH:mm')
}

export const formatPhone = phone => {
  return phone?.length > 4
    ? phone?.replace(/[^\dxX+]/g, '').replace('3850', '385')
    : null
}
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export const getCalendarTimes = (calendarData, startDate, endDate) => {
  return calendarData?.data?.calendar?.getOpenHours?.filter(x =>
    moment(x.date).isBetween(startDate, endDate)
  )
}

export const getPlanName = planId => {
  const SMSPlanNames = {
    SMS_100: i18n.t('translation.PlansForm.sms100'),
    SMS_200: i18n.t('translation.PlansForm.sms200'),
    SMS_1000: i18n.t('translation.PlansForm.sms1000'),
  }
  switch (planId) {
    case 'free': {
      return i18n.t('translation.PlansForm.besplatan')
    }
    case 'trial': {
      return i18n.t('translation.helpers.proba')
    }
    case 'business': {
      return i18n.t('translation.PlansForm.business')
    }
    case 'advanced': {
      return i18n.t('translation.PlansForm.advanced')
    }
    default: {
      if (planId?.startsWith('SMS')) {
        // new package name convention SMS_100_hrk/free
        const slices = planId.split('_')
        const plan = slices[0] + '_' + slices[1]
        return SMSPlanNames[plan]
      }
      return i18n.t('translation.PlansForm.besplatan')
    }
  }
}

export const getPlanNameWithSuffix = planId => {
  switch (planId) {
    case 'free': {
      return 'Besplatni'
    }
    case 'business': {
      return 'Poslovni'
    }
    case 'advanced': {
      return 'Napredni'
    }
    default: {
      return 'Besplatni'
    }
  }
}

export const getPlanStatus = status => {
  switch (status) {
    case 'completed': {
      return i18n.t('translation.helpers.zavrsen')
    }
    case 'verification': {
      return 'verifikacija'
    }
    case 'sepaPaymentPending': {
      return i18n.t('translation.helpers.u-tijeku')
    }
    case 'requiresAction': {
      return i18n.t('translation.status.draft')
    }
    default: {
      return i18n.t('translation.helpers.greska')
    }
  }
}

export const getPlanInterval = interval => {
  switch (interval) {
    case '1 month': {
      return i18n.t('translation.helpers.mjesecno')
    }
    case '365 days': {
      return i18n.t('translation.helpers.godisnje')
    }
    case 'single': {
      return i18n.t('translation.helpers.jednokratno')
    }
    default: {
      return i18n.t('translation.helpers.mjesecno')
    }
  }
}

export const serialize = value => {
  if (!value) return '<p></p>'
  if (!Array.isArray(value)) return value

  let result = ''

  const addStyle = val => {
    if (val.bold) result += '<strong>'
    if (val.italic) result += '<em>'
    if (val.underline) result += '<u>'
    result += val.text
    if (val.underline) result += '</u>'
    if (val.italic) result += '</em>'
    if (val.bold) result += '</strong>'
  }

  const addText = i => {
    if (value[i].children.length === 1 && !value[i].children[0].text)
      result +=
        value[i].type === 'paragraph' ? '<p></br></p>' : '<h2></br></h2>'
    else {
      result += value[i].type === 'paragraph' ? '<p>' : '<h2>'
      for (let j = 0; j < value[i].children.length; j++) {
        addStyle(value[i].children[j])
      }
      result += value[i].type === 'paragraph' ? '</p>' : '</h2>'
    }
  }

  const addList = i => {
    result +=
      value[i].type === 'bulleted-list'
        ? '<ul style="padding-left: 40px;">'
        : '<ol style="padding-left: 40px;">'
    for (let j = 0; j < value[i].children.length; j++) {
      result += '<li>'
      for (let x = 0; x < value[i].children[j].children.length; x++) {
        addStyle(value[i].children[j].children[x])
      }
      result += '</li>'
    }
    result += value[i].type === 'bulleted-list' ? '</ul>' : '</ol>'
  }

  for (let i = 0; i < value.length; i++) {
    value[i].type === 'paragraph' || value[i].type === 'heading-two'
      ? addText(i)
      : addList(i)
  }

  return result
}

export const getDayName = day => {
  switch (day) {
    case 'mon': {
      return i18n.t('translation.helpers.monday')
    }
    case 'tue': {
      return i18n.t('translation.helpers.tuesday')
    }
    case 'wed': {
      return i18n.t('translation.helpers.wednesday')
    }
    case 'thu': {
      return i18n.t('translation.helpers.thursday')
    }
    case 'fri': {
      return i18n.t('translation.helpers.friday')
    }
    case 'sat': {
      return i18n.t('translation.helpers.saturday')
    }
    default: {
      return i18n.t('translation.helpers.sunday')
    }
  }
}

export const getDayNameByIndex = index => {
  switch (index) {
    case 0: {
      return 'PONEDJELJAK'
    }
    case 1: {
      return 'UTORAK'
    }
    case 2: {
      return 'SRIJEDA'
    }
    case 3: {
      return 'ČETVRTAK'
    }
    case 4: {
      return 'PETAK'
    }
    case 5: {
      return 'SUBOTA'
    }
    default: {
      return 'NEDJELJA'
    }
  }
}

export const convertWorkWeekToWorkShift = workWeek => {
  const locationWorkHours = chain(workWeek)
    .valuesIn(workWeek)
    .filter(item => Array.isArray(item))
    .value()

  let firstShift = {
    name: !locationWorkHours.find(item => item?.length > 1)
      ? 'Jednokratno'
      : 'Cijeli dan',
    openHours: [] as any[],
    __typename: 'LocationWorkShift',
  }

  let secondShift = {
    name: 'One-time',
    openHours: [] as any[],
    __typename: 'LocationWorkShift',
  }

  locationWorkHours?.map((item, idx) => {
    firstShift.openHours = [
      ...firstShift.openHours,
      {
        day: idx,
        dayName: getDayNameByIndex(idx),
        dayOff: !item?.length,
        startTime: item[0]?.openTime || '08:00',
        endTime: item[0]?.closeTime || '16:00',
        _typename: 'WorkShiftDaySchedule',
      },
    ]
    if (!!locationWorkHours.find(item => item?.length > 1)) {
      secondShift.openHours = [
        ...secondShift.openHours,
        {
          day: idx,
          dayName: getDayNameByIndex(idx),
          dayOff: item?.length !== 2,
          startTime: item[1]?.openTime || '08:00',
          endTime: item[1]?.closeTime || '16:00',
          _typename: 'WorkShiftDaySchedule',
        },
      ]
    }
  })

  return !locationWorkHours.find(item => item?.length > 1)
    ? [firstShift]
    : [firstShift, secondShift]
}

export const getLast20Years = () => {
  let years: number[] = []
  for (let i = moment().year(); i > moment().year() - 20; i--) {
    years.push(i)
  }
  return years
}

export const getMonths = () => {
  return [
    { id: 1, name: i18n.t('translation.helpers.january') },
    { id: 2, name: i18n.t('translation.helpers.february') },
    { id: 3, name: i18n.t('translation.helpers.march') },
    { id: 4, name: i18n.t('translation.helpers.april') },
    { id: 5, name: i18n.t('translation.helpers.may') },
    { id: 6, name: i18n.t('translation.helpers.june') },
    { id: 7, name: i18n.t('translation.helpers.july') },
    { id: 8, name: i18n.t('translation.helpers.august') },
    { id: 9, name: i18n.t('translation.helpers.september') },
    { id: 10, name: i18n.t('translation.helpers.october') },
    { id: 11, name: i18n.t('translation.helpers.november') },
    { id: 12, name: i18n.t('translation.helpers.december') },
  ]
}
export const getEmployeeAvatarUrl = resource => {
  let userAvatar: string =
    resource?.avatarUrl && !resource?.avatarUrl.endsWith('null')
      ? resource?.avatarUrl
      : resource?.user?.avatarUrl && !resource?.user?.avatarUrl.endsWith('null')
      ? resource?.user?.avatarUrl
      : !!resource?.user?.google_profilePicUrl
      ? resource?.user?.google_profilePicUrl
      : !!resource?.user?.fb_profilePicUrl
      ? resource?.user?.fb_profilePicUrl
      : null

  return userAvatar ? userAvatar.replace('(', '%28').replace(')', '%29') : null
}

export function formatPhoneNumber(phoneNumberString) {
  const phoneNumber = parsePhoneNumber(phoneNumberString)
  return phoneNumber?.formatInternational()
}

export const getLatestOrNewestDate = (type, dates) => {
  if (!dates?.length) return null

  return type === 'min' ? moment.min(dates) : moment.max(dates)
  // .format('YYYY-MM-DD')
}

export const formatPriceNumberWithDelimiter = (
  number,
  addZerosIfNoDecimals = true
) => {
  const parsed = Math.round(parseFloat(number) * 100) / 100
  /* const parsed =
    typeof number === 'number'
      ? parseFloat(number.toFixed(2))
      : typeof number === 'string'
      ? parseFloat(parseFloat(number).toFixed(2))
      : 0 */
  if ((!number && number !== 0) || isNaN(parsed)) return
  const formatter = Intl.NumberFormat('en-US')
  const decimalChange = Math.floor(parseFloat((parsed * 100).toFixed(2))) % 100
  const zerosToAdd =
    decimalChange === 0 ? '.00' : decimalChange % 10 === 0 ? '0' : ''

  return addZerosIfNoDecimals && zerosToAdd
    ? formatter.format(parsed) + zerosToAdd
    : formatter.format(parsed)
}

export const isOibValid = oib => {
  if (oib?.length !== 11) return false

  let calculated = 10
  for (const digit of oib.substring(0, 10)) {
    calculated += parseInt(digit)
    calculated %= 10

    if (calculated === 0) calculated = 10

    calculated *= 2
    calculated %= 11
  }

  return 11 - calculated === 10
    ? 0 === parseInt(oib[10])
    : 11 - calculated === parseInt(oib[10])
}

export const getAddressString = (
  obj = { address: '', zipCode: '', city: '' }
) => {
  if (!obj) return ''
  const { address, zipCode, city } = obj
  let baseString = ''
  if (address) {
    baseString += address
  }

  if (address && zipCode) {
    baseString += `, ${zipCode}`
  }

  if (address && city) {
    baseString += `, ${city}`
  } else if (city) {
    baseString += `${city}`
  }

  return baseString
}
export const isIBANValid = input => {
  var CODE_LENGTHS = {
    HR: 21,
  }
  if (
    input?.includes(' ') &&
    Number.isInteger(parseInt(input?.slice(0, 2))) &&
    !input?.slice(2)?.match(/^[0-9]\d*(\.\d+)?$/)
  ) {
    return false
  }
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })

  // mod97 operation
  var checksum = digits.slice(0, 2),
    fragment
  for (var offset = 2; offset < digits.length; offset += 7) {
    fragment = String(checksum) + digits.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }

  return checksum === 1
}

export const getReceiptStatusLabel = (status: string | null) => {
  if (!status) return null

  return status === 'CASH'
    ? i18n.t('translation.helpers.label-status-cash')
    : status === 'CARD'
    ? i18n.t('translation.helpers.label-status-card')
    : status === 'VIVA_WALLET'
    ? 'Viva Wallet'
    : i18n.t('translation.helpers.label-status-transaction')
}
export const asNonNullable = <T extends {}>(x: T | null): NonNullable<T> =>
  x as NonNullable<T>

export const changeServiceDurations = (
  action: '-' | '+',
  itemToChange: any,
  selectedServices: any[],
  timeSlotDuration: number,
  replace: any,
  freeSpace?: boolean
) => {
  for (
    let index = itemToChange?.id;
    index < selectedServices?.length;
    index++
  ) {
    const newStartTimeInMinutes =
      action === '+'
        ? getServiceDurationInMinutes(
            selectedServices[index]?.startTime?.title
          ) + timeSlotDuration
        : getServiceDurationInMinutes(
            selectedServices[index]?.startTime?.title
          ) - timeSlotDuration
    const endTime = getServiceHoursAndMinutes(
      newStartTimeInMinutes + selectedServices[index]?.durationMinutes
    )

    if (
      newStartTimeInMinutes !== '24:00' &&
      newStartTimeInMinutes !== '00:00'
    ) {
      const newEndTime = moment(
        moment(itemToChange?.endTimeUtc)?.format('YYYY-MM-DD') + ' ' + endTime
      )
      const startTime = getServiceHoursAndMinutes(newStartTimeInMinutes)

      const durationMinutes =
        action === '+'
          ? itemToChange?.durationMinutes + timeSlotDuration
          : itemToChange?.durationMinutes - timeSlotDuration

      const changes =
        itemToChange?.id === index && !freeSpace
          ? {
              durationMinutes,
              duration: getServiceHoursAndMinutes(durationMinutes),
              endTime: newEndTime?.format('HH:mm'),
              endTimeUtc: newEndTime?.toISOString(),
            }
          : {
              startTime: {
                id: startTime,
                title: startTime,
                isBusy: false,
                isInactive: false,
              },
              endTime: newEndTime?.format('HH:mm'),
              endTimeUtc: newEndTime?.toISOString(),
              startTimeUtc: moment(
                moment(itemToChange?.endTimeUtc)?.format('YYYY-MM-DD') +
                  ' ' +
                  startTime
              ).toISOString(),
            }

      replace(selectedServices[index]?.id, {
        ...selectedServices[index],
        ...changes,
        original: {
          ...selectedServices[index]?.original,
          ...changes,
        },
      })
    }
  }
}

export const getServiceWithLatestEnding = (
  services: any,
  returnInMinutes?: boolean
) => {
  let latestEndTime = 0 as number
  for (let index = 0; index < services?.length; index++) {
    if (getServiceDurationInMinutes(services[index]?.endTime) > latestEndTime)
      latestEndTime = getServiceDurationInMinutes(services[index]?.endTime)
  }

  return returnInMinutes
    ? Number(latestEndTime)
    : getServiceHoursAndMinutes(latestEndTime)
}

export const changeServiceStartTime = (
  action: '-' | '+',
  itemToChange: any,
  timeSlotDuration: number,
  replace?: any,
  header?: boolean
) => {
  const newStartTimeInMinutes =
    action === '+'
      ? getServiceDurationInMinutes(itemToChange?.startTime?.title) +
        timeSlotDuration
      : getServiceDurationInMinutes(itemToChange?.startTime?.title) -
        timeSlotDuration

  const newEndTimeTimeInMinutes =
    action === '+'
      ? getServiceDurationInMinutes(itemToChange?.endTime) + timeSlotDuration
      : getServiceDurationInMinutes(itemToChange?.endTime) - timeSlotDuration

  const startTime = {
    startTime: {
      id: getServiceHoursAndMinutes(newStartTimeInMinutes),
      title: getServiceHoursAndMinutes(newStartTimeInMinutes),
      isBusy: false,
      isInactive: false,
    },
    startTimeUtc: moment(
      moment(itemToChange?.endTimeUtc)?.format('YYYY-MM-DD') +
        ' ' +
        getServiceHoursAndMinutes(newStartTimeInMinutes)
    ).toISOString(),
  }

  const changes = !header
    ? {
        ...startTime,
        endTime: getServiceHoursAndMinutes(newEndTimeTimeInMinutes),
        endTimeUtc: moment(
          moment(itemToChange?.endTimeUtc)?.format('YYYY-MM-DD') +
            ' ' +
            getServiceHoursAndMinutes(newEndTimeTimeInMinutes)
        ).toISOString(),
      }
    : {
        ...startTime,
        duration: getServiceHoursAndMinutes(
          itemToChange?.durationMinutes + timeSlotDuration
        ),
        durationMinutes: itemToChange?.durationMinutes + timeSlotDuration,
      }

  replace(itemToChange?.id, {
    ...itemToChange,
    ...changes,
    original: {
      ...itemToChange?.original,
      ...changes,
    },
  })
}

export const getZoomLabel = zoom => {
  switch (zoom) {
    case 0:
      return '25%'
    case 1:
      return '50%'
    case 2:
      return '75%'
    case 3:
      return '100%'
    case 4:
      return '125%'
    case 5:
      return '150%'
    case 6:
      return '175%'
    default:
      return '100%'
  }
}

export const printRatingStars = (reviewRating: number) => {
  const selectedTheme = useContext(ThemeContext)

  return (
    <>
      <icons.RatingStar
        size={'smaller'}
        color={selectedTheme.colors.ratingStarColor}
        fill={reviewRating >= 1 ? selectedTheme.colors.ratingStarColor : 'none'}
      />
      <icons.RatingStar
        size={'smaller'}
        color={selectedTheme.colors.ratingStarColor}
        fill={reviewRating >= 2 ? selectedTheme.colors.ratingStarColor : 'none'}
      />
      <icons.RatingStar
        size={'smaller'}
        color={selectedTheme.colors.ratingStarColor}
        fill={reviewRating >= 3 ? selectedTheme.colors.ratingStarColor : 'none'}
      />
      <icons.RatingStar
        size={'smaller'}
        color={selectedTheme.colors.ratingStarColor}
        fill={reviewRating >= 4 ? selectedTheme.colors.ratingStarColor : 'none'}
      />
      <icons.RatingStar
        size={'smaller'}
        color={selectedTheme.colors.ratingStarColor}
        fill={
          reviewRating === 5 ? selectedTheme.colors.ratingStarColor : 'none'
        }
      />
    </>
  )
}

export const getRatingStars = (
  reviewRating: number,
  reviewComment?: string | null,
  style?: any
) => {
  return (
    <div style={style} className="flex items-start justify-end mt-0 z-0">
      {reviewComment ? (
        <Tooltip label={reviewComment}>
          {printRatingStars(reviewRating)}
        </Tooltip>
      ) : (
        printRatingStars(reviewRating)
      )}
    </div>
  )
}

export const getFullReportSrc = (reportSrc: string, pdf?: boolean) => {
  return `${
    pdf
      ? import.meta.env.VITE_CASH_REGISTER_PDF_URL_API
      : import.meta.env.VITE_CASH_REGISTER_API
  }?authorization=${getAuthToken()}&x-orgid=${getOrgId()}&x-locationid=${getLocationId()}${
    environment.REACT_APP_ENV === 'production' ? '&environment=production' : ''
  }&reportId=${reportSrc}`
}

export const cls = input =>
  input
    .replace(/\s+/gm, ' ')
    .split(' ')
    .filter(cond => typeof cond === 'string')
    .join(' ')
    .trim()

export const roundNumberWithTwoDecimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const getUnitsOfMeasure = () => {
  return [
    { id: 'PC', title: i18n.t('translation.helpers.piece') },
    { id: 'KG', title: i18n.t('translation.helpers.kilogram') },
    { id: 'LIT', title: i18n.t('translation.helpers.liter') },
    { id: 'M2', title: i18n.t('translation.helpers.square-meter') },
    { id: 'M3', title: i18n.t('translation.helpers.cubic-meter') },
  ]
}

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return

  return word.charAt(0).toUpperCase() + word.slice(1)
}

type ItemType = {
  firstName?: string | null
  lastName?: string | null
  nickName?: string | null
} & Partial<Record<string, any>>

export const resolveFullName = (
  item?: ItemType | null,
  useNicknameFirstIfExists: boolean = true
) => {
  if (!item || !item?.firstName) return ''

  if (item.nickName && useNicknameFirstIfExists) return item.nickName

  return `${item.firstName}${item.lastName ? ' ' + item.lastName : ''}`
}

export const getServiceAmounts = (args: {
  price: number
  quantity: number
  discountValue?: number | string
  discountType?: 'percent' | 'amount'
}) => {
  const { discountValue: value, discountType, price, quantity } = args

  const discountValue = value
    ? parseFloat(value?.toString().replace(',', '.') || '0')
    : 0

  const newTotalPrice =
    discountType === 'percent'
      ? price * (1 - discountValue / 100)
      : price - discountValue / quantity > 0
      ? price - discountValue / quantity
      : 0

  const discountAmount =
    discountType === 'percent'
      ? roundNumberWithTwoDecimals(price * quantity * (discountValue / 100))
      : price - discountValue > 0
      ? discountValue
      : price

  const discountRate =
    discountType === 'percent'
      ? discountValue
      : price - discountValue > 0
      ? roundNumberWithTwoDecimals((discountValue / (price * quantity)) * 100)
      : 0

  return {
    totalPrice: roundNumberWithTwoDecimals(newTotalPrice),
    totalAmount: roundNumberWithTwoDecimals(newTotalPrice * quantity),
    discountAmount,
    discountRate,
    discountValue,
  }
}

export const getValidIteratorsOptions = () => {
  return [
    {
      id: 'day',
      name: i18n.t('translation.helpers.days'),
    },
    {
      id: 'week',
      name: i18n.t('translation.helpers.weeks'),
    },
    {
      id: 'month',
      name: i18n.t('translation.helpers.months'),
    },
  ]
}

export const getGiftCardStatusOptions = (
  isGiftCardUsed: boolean,
  giftCardStatus: string
) => {
  return [
    {
      id: 'active',
      name: t('translation.GiftCardList.status-single-active'),
    },
    ...(!isGiftCardUsed ||
    (isGiftCardUsed && giftCardStatus.toUpperCase() === 'EXPIRED') ||
    giftCardStatus.toUpperCase() === 'INACTIVE'
      ? [
          {
            id: 'inactive',
            name: t('translation.GiftCardList.status-single-inactive'),
          },
        ]
      : []),
    ...(giftCardStatus.toUpperCase() === 'EXPIRED'
      ? [
          {
            id: 'expired',
            name: t('translation.GiftCardList.status-single-expired'),
          },
        ]
      : []),
  ]
}

export const getLoyaltyListName = (voucherType: string, entityType: string) => {
  if (entityType === 'giftcard') {
    switch (voucherType) {
      case 'giftcard':
        return i18n.t('translation.Sidebar.label-giftCards')
      case 'voucher':
        return i18n.t('translation.Sidebar.label-vouchers')
      default:
        return ''
    }
  } else {
    switch (voucherType) {
      case 'giftcard':
        return i18n.t('translation.Sidebar.label-giftcardsTemplates')
      case 'voucher':
        return i18n.t('translation.Sidebar.label-voucherTemplates')
      default:
        return ''
    }
  }
}

export const getVoucherTerms = (values: any, locationName?: any) => {
  const priceWithDiscount = values?.price
    ? roundNumberWithTwoDecimals(
        parseFloat(values?.price?.toString().replace(',', '.')) -
          parseFloat(values?.price?.toString().replace(',', '.')) *
            roundNumberWithTwoDecimals(
              parseFloat(
                values?.discountRate?.toString().replace(',', '.') || 0
              ) / 100
            )
      )
    : 0

  const typeVoucher = values?.voucherType === 'VOUCHER'
  let categoryItems = [] as any
  let serviceItems = [] as any
  values?.items?.forEach(element => {
    element?.serviceTypeId
      ? categoryItems.push(element)
      : serviceItems.push(element)
  })

  let categoryList = '\t\t'
  categoryItems.forEach((x, idx) => {
    categoryList =
      idx === categoryItems?.length - 1
        ? categoryList + '• ' + x.itemName
        : categoryList + '• ' + x.itemName + ', \r\n\t\t'
  })

  const quantityArray = serviceItems?.map(x => x?.quantity)
  const biggestNumber = quantityArray
    ?.reduce(
      (largest, current) => (current > largest ? current : largest),
      quantityArray[0]
    )
    ?.toString()?.length

  let servicesList = '\t\t'
  serviceItems.forEach((x, idx) => {
    let quantity = x.quantity?.toString()

    if (x.quantity?.toString()?.length !== biggestNumber && typeVoucher)
      for (let index = quantity?.length; index < biggestNumber; index++) {
        quantity = ' ' + quantity
      }

    const quantityString = typeVoucher ? quantity + ' x ' : ''

    servicesList =
      idx === serviceItems?.length - 1
        ? servicesList + '• ' + quantityString + x.itemName
        : servicesList + '• ' + quantityString + x.itemName + ', \r\n\t\t'
  })

  if (typeVoucher)
    return !values?.items?.length
      ? ''
      : `${locationName || ''} vlasniku paketa usluga omogućava korištenje${
          serviceItems?.length ? `:\r\n ${servicesList}` : ' svih usluga '
        }
Paket usluga potrebno je iskoristiti u roku od ${values?.validAmount} ${values
          ?.validIterator?.name} od izdavanja.`

  return `${locationName || ''} donosiocu poklon bona omogućava korištenje ${
    categoryItems?.length
      ? `usluga iz\r\n\kategorije: 
  ${categoryList}`
      : ''
  }
${
  serviceItems?.length
    ? `${categoryItems?.length ? 'i ' : ''}usluga: 
${servicesList} \r\n`
    : ''
}${
    !serviceItems?.length && !categoryItems?.length ? 'svih usluga ' : ''
  }${`u vrijednosti do ${values?.price || 0} EUR${
    Number(values?.discountRate) !== 0
      ? ` bez popusta, odnosno do ${
          priceWithDiscount || 0
        } EUR sa uključenih ${formatPriceNumberWithDelimiter(
          Number(values?.discountRate),
          false
        )}% popusta`
      : ''
  }.`}
Poklon bon potrebno je iskoristiti u roku od ${values?.validAmount} ${values
    ?.validIterator?.name} od izdavanja.`
}

export const getChangelogEventDescription = (
  event: string,
  t: (key: string) => string
) => {
  const eventObject = {
    APPOINTMENT_CREATED: t(
      'translation.AppointmentChangelog.appointment-created'
    ),
    APPOINTMENT_UPDATE: t(
      'translation.AppointmentChangelog.appointment-update'
    ),
    APPOINTMENT_CANCELED: t(
      'translation.AppointmentChangelog.appointment-canceled'
    ),
    APPOINTMENT_REVIEWED: t(
      'translation.AppointmentChangelog.appointment-reviewed'
    ),
    APPOINTMENT_COMPLETED: t(
      'translation.AppointmentChangelog.appointment-completed'
    ),

    BOOKING_CREATED: t('translation.AppointmentChangelog.booking-created'),
    BOOKING_UPDATED: t('translation.AppointmentChangelog.booking-updated'),
    BOOKING_CONFIRMED: t('translation.AppointmentChangelog.booking-confirmed'),
    BOOKING_CANCELED: t('translation.AppointmentChangelog.booking-canceled'),
    EMAIL_SENT: t('translation.AppointmentChangelog.email-sent'),
    SMS_SENT: t('translation.AppointmentChangelog.sms-sent'),
    PAYMENT_NOTIFICATION_CREATED: t(
      'translation.AppointmentChangelog.payment-notif-created'
    ),
    PAYMENT_NOTIFICATION_PAID: t(
      'translation.AppointmentChangelog.payment-notif-paid'
    ),
    PAYMENT_NOTIFICATION_REFUNDED: t(
      'translation.AppointmentChangelog.payment-notif-refunded'
    ),
    PAYMENT_NOTIFICATION_ERROR: t(
      'translation.AppointmentChangelog.payment-notif-error'
    ),
    FISCALIZATION_REQUEST: t(
      'translation.AppointmentChangelog.fiscalization-req'
    ),
    FISCALIZATION_SUCCESS: t(
      'translation.AppointmentChangelog.fiscalization-success'
    ),
    FISCALIZATION_ERROR: t(
      'translation.AppointmentChangelog.fiscalization-error'
    ),
    BOOKING_RESCHEDULED_BY_CLIENT: t(
      'translation.AppointmentChangelog.booking-rescheduled-by-client'
    ),
    BOOKING_RESCHEDULE_REQUEST_BY_CLIENT: t(
      'translation.AppointmentChangelog.booking-reschedule-request-by-client'
    ),
    RECEIPT_ISSUED: t('translation.AppointmentChangelog.receipt-issued'),
    RECEIPT_CANCELLED: t('translation.AppointmentChangelog.receipt-cancelled'),
    TIP_FISCALIZATION_REQUEST: t(
      'translation.AppointmentChangelog.tip-fiscalization-req'
    ),
    TIP_FISCALIZATION_SUCCESS: t(
      'translation.AppointmentChangelog.tip-fiscalization-success'
    ),
    TIP_FISCALIZATION_ERROR: t(
      'translation.AppointmentChangelog.tip-fiscalization-error'
    ),
    GIFTCARD_USED: t('translation.AppointmentChangelog.giftcard-used'),
    GIFTCARD_REMOVED: t('translation.AppointmentChangelog.giftcard-removed'),
    GIFTCARD_CREATED: t('translation.AppointmentChangelog.giftcard-created'),
    GIFTCARD_UPDATED: t('translation.AppointmentChangelog.giftcard-updated'),
    GIFTCARD_EMAIL_SENT_TO_CLIENT: t(
      'translation.AppointmentChangelog.giftcard-email-sent-to-client'
    ),
    CLIENT_MERGED: t('translation.AppointmentChangelog.client-merged'),
  }

  return eventObject[event] || event
}

export const getPaymentMethod = (
  method: string
):
  | {
      name: string
      icon: (props: any) => JSX.Element
    }
  | undefined => {
  if (!method) return

  const paymentMethodMapper = {
    CASH: {
      name: 'Gotovina',
      icon: icons.Coins,
    },
    CARD: {
      name: 'Kartica',
      icon: icons.CreditCard,
    },
    WIRE: {
      name: 'Transakcijski račun',
      icon: icons.Globe,
    },
  }

  return (
    paymentMethodMapper[method] || {
      name: 'Ostalo',
      icon: icons.CreditCard,
    }
  )
}

export const giftCardStatusOptions = [
  { id: 'ALL', name: t('translation.GiftCardList.status-all') },
  { id: 'ACTIVE', name: t('translation.GiftCardList.status-active') },
  { id: 'INACTIVE', name: t('translation.GiftCardList.status-inactive') },
  { id: 'DELETED', name: t('translation.GiftCardList.status-deleted') },
  { id: 'EXPIRED', name: t('translation.GiftCardList.status-expired') },
]

export const reminderTypeTitleMapper = {
  CLIENT_REMINDER: i18n.t('translation.ClientReminders.client-reminder'),
  APPOINTMENT_NOTIFICATION: t(
    'translation.ClientReminders.appointment-notification'
  ),
  GROUP_APPOINTMENT_NOTIFICATION: i18n.t(
    'translation.ClientReminders.appointment-notification'
  ),
}

export const getReminderTypeTitleMapper = (
  reminder: RouterOutput['reminders_get'][0] | RouterOutput['reminder_get'],
  t: (key: string) => string
) => {
  const reminderTypeObject = {
    CLIENT_REMINDER: reminder.RemindersConfig?.description,
    APPOINTMENT_NOTIFICATION: t(
      'translation.ClientReminders.appointment-notification'
    ),
    GROUP_APPOINTMENT_NOTIFICATION: i18n.t(
      'translation.ClientReminders.appointment-notification'
    ),
  }

  return reminderTypeObject[reminder.type] || reminder.type
}

export const getReminderStatusTitleMapper = (
  event: string,
  t: (key: string) => string
) => {
  const eventObject = {
    SCHEDULED: {
      title: t('translation.ClientReminders.scheduled-label'),
      className: 'text-zoyya-primary',
    },
    RESCHEDULED: {
      title: t('translation.ClientReminders.rescheduled-label'),
      className: 'text-zoyya-accent3',
    },
    PROCESSED: {
      title: t('translation.ClientReminders.processed-label'),
      className: 'text-zoyya-accent2',
    },
    CANCELED: {
      title: t('translation.ClientReminders.canceled-label'),
      className: 'text-zoyya-accent5',
    },
    ERROR: {
      title: t('translation.ClientReminders.error-label'),
      className: 'text-zoyya-accent1',
    },
  }

  return eventObject[event] || event
}
