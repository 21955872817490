import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: 'pub4ac642b4ce08cd2e171f9acc3624d480',
  site: 'datadoghq.eu',
  service: import.meta.env.VITE_DATADOG_SERVICE || 'zoyya-backoffice',
  env: import.meta.env.VITE_DATADOG_ENV,
  version: import.meta.env.VITE_VERSION_NUMBER,
  forwardConsoleLogs: ['error'],
  telemetrySampleRate: 0,
  sampleRate: 100,
  beforeSend(event) {
    if (event.message?.includes("Can't perform a React state update on")) {
      return false
    }
    if (
      event.error?.stack?.includes('AbortError: The user aborted a request.')
    ) {
      return false
    }
    if (event.error?.stack?.includes('AbortError: Fetch is aborted')) {
      return false
    }
    if (
      event.message?.startsWith('XHR') &&
      event.error?.stack?.includes('Failed to load')
    ) {
      return false
    }
    return true
  },
})
export const setLogContextProperty = datadogLogs.setGlobalContextProperty
export const removeLogContextProperty = datadogLogs.removeGlobalContextProperty
export const browserLogs = datadogLogs.logger
