import { FormField, Loader } from 'components'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { getHoursInDay } from 'src/helpers'
import { FieldColumn } from 'src/sdk/tw/Form'
import { trpc } from 'src/trpc'
import styled from 'styled-components'
import { device } from '../../constants'

export const AppointmentClientRemindersForm = ({ isLoading }) => {
  const { locationId, orgId } = useParams<{
    locationId: string
    orgId: string
  }>()

  const { t } = useSdk()

  const { data: remindersConfigData } = trpc.remindersConfig_get.useQuery({
    orgId,
    locationId: BigInt(locationId),
  })

  const reminderTypesOptions = remindersConfigData || []

  const hoursInDay = getHoursInDay(15)

  const reminderTimeOptions = hoursInDay

  return (
    <>
      <Wrapper>
        <TitleRow>
          <EventDateTitle>
            {t('translation.ClientReminders.add-new')}
          </EventDateTitle>
        </TitleRow>
      </Wrapper>
      {isLoading && <Loader isComponent />}

      <div className="p-4 px-6">
        <Row>
          <FieldColumn>
            <FormField.Select
              label={t('translation.ClientReminders.reminder-type')}
              name="reminder.reminderConfigId"
              parseValue={val => reminderTypesOptions?.find(e => e.id === val)}
              formatValue={val => val?.id}
              isClearable={false}
              options={reminderTypesOptions}
              isSearchable={false}
            />
          </FieldColumn>
        </Row>
        <Row className="flex lg:flex-row flex-col">
          <FieldColumn>
            <FormField.DateInput
              label={t('translation.ClientReminders.reminder-date')}
              name="reminder.reminderDate"
              min={moment().format('YYYY-MM-DD')}
              displayFormat="DD.MM.YYYY."
            />
          </FieldColumn>
          <FieldColumn>
            <FormField.Select
              label={t('translation.ClientReminders.reminder-time')}
              name="reminder.reminderTime"
              isClearable={false}
              parseValue={val => reminderTimeOptions?.find(e => e.id === val)}
              styles={{
                control: styles => ({
                  ...styles,
                  height: '46px !important',
                }),
              }}
              formatValue={val => val?.id}
              options={reminderTimeOptions || []}
              isSearchable={true}
            />
          </FieldColumn>
        </Row>
        <Row className="flex lg:flex-row flex-col">
          <FieldColumn>
            <FormField.TextArea
              rows={2}
              label={t('translation.ClientReminders.email-message')}
              name="reminder.messageToClient"
            />
          </FieldColumn>
        </Row>
      </div>
    </>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type TTitleRowProps = {
  status?: string
}
const TitleRow = styled.div<TTitleRowProps>`
  padding: 11px 25px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  display: flex;
  justify-content: space-between;
  background: #f4f4ff;

  @media ${device.tablet} {
    padding: 10px;
  }
`

type TEventDateTitleProps = {
  status?: string
}
const EventDateTitle = styled.div<TEventDateTitleProps>`
  font-size: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`
