import React from 'react'
type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const InputTW = (props: InputProps) => {
  const { className } = props
  return (
    <input
      {...props}
      className={`border border-zoyya-grayLight p-2 rounded-md outline-none focus:border-zoyya-primary focus:shadow-[0_0_0_3px_#6B81FF] ${className ||
        ''}`}
    />
  )
}
