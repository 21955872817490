import React from 'react'
import { Button, Form, FormField, Loader } from 'components'
import { useSdk } from 'sdk'
import styled from 'styled-components'
import {
  useGetNotificationPreferencesQuery,
  useUserUpdateNotificationPreferencesMutation,
} from 'state/graphql'
import { useParams } from 'react-router-dom'
import { fontFamily } from '../../../components/style/fonts'
import { StyledFieldsContainer } from '../../../components/Form/Form'
import { device } from '../../../constants'
import Text from 'components/Text/Text'
import { FieldRow } from '../styles'
import {
  NavigationContainer,
  NavigationLeftColumn,
  NavigationLogo,
  NavigationLogoImage,
  NavigationWrapper,
} from 'src/styles'
import { Error } from '../../../components/Icon/Icons/Error'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${NavigationLogo} {
    padding: 10px;
  }
`
export const Container = styled.div`
  font-family: ${fontFamily};
  display: flex;
  align-items: center;
  max-width: 1220px;
  margin: auto;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 40px 0px;
  height: 100%;

  img {
    max-width: 50%;
    margin-right: 60px;
    margin-bottom: 40px;
  }

  @media ${device.tablet} {
    img {
      margin-right: auto;
      margin-left: auto;
      max-width: 80vw;
    }
  }

  ${StyledFieldsContainer} {
    background: transparent;
    min-width: 600px;
    max-width: 100vw;
    padding: 20px;
    display: initial;

    button {
      margin: 40px auto auto auto;
    }
  }

  label {
    white-space: pre-wrap;

    @media ${device.tablet} {
      text-align: left;
    }
  }

  @media ${device.tablet} {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    padding: 20px 10px;
    ${FieldRow} {
      margin-bottom: 10px;
    }
  }
`

export const Title = styled.h1`
  color: white;
  font-size: 2vw;
  font-weight: 700;
  margin-bottom: 1.429rem;

  @media ${device.tablet} {
    font-size: 20px;
    margin-top: 20px;
  }
`

export const TitleBig = styled(Title)`
  font-size: 3vw;
`

export const RatingStars = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`

export const Label = styled.span`
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-right: 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 580px;
  max-width: 100vw;
  padding: 20px;
`

export const BigText = styled.span`
  color: white;
  font-size: 20px;
`
const NotificationPreferences = () => {
  const { t, navigateTo, appServices } = useSdk()
  const { token } = useParams<{ token: string }>()
  const { data, loading } = useGetNotificationPreferencesQuery({
    variables: { token },
  })
  const [
    updateNotificationPreferences,
  ] = useUserUpdateNotificationPreferencesMutation()

  if (loading) return <Loader isComponent />

  const initialValues = data?.user?.getNotificationPreferences!

  const handleSubmit = async values => {
    const result = await updateNotificationPreferences({
      variables: {
        input: {
          token: token,
          sendBookingEmail: values.sendBookingEmail,
          sendHelpEmail: values.sendHelpEmail,
          sendNewsEmail: values.sendNewsEmail,
          sendReminderEmail: values.sendReminderEmail,
        },
      },
    })
    appServices
      .handleMutationResult(
        result,
        t('translation.NotificationPreferences.emailSettingsToast')
      )
      .onSuccess(() => {
        navigateTo.thankYou()
      })
  }
  return (
    <Body>
      <NavigationContainer>
        <NavigationWrapper>
          <NavigationLeftColumn>
            <NavigationLogo>
              <NavigationLogoImage
                onClick={() => window.open('https://zoyya.com', '_self')}
                src={'/assets/zoyya-final-logo-white.svg'}
              />
            </NavigationLogo>
          </NavigationLeftColumn>
        </NavigationWrapper>
      </NavigationContainer>
      <Container>
        <img src={'/assets/notificationSettings.svg'} alt="" />
        <Wrapper>
          <Text type="h2">{`${t('translation.NotificationPreferences.hey')} ${
            initialValues?.firstName
          }, ${t('translation.NotificationPreferences.stayInTouch')}`}</Text>
          <Text type="small">
            {t('translation.NotificationPreferences.emailNotificationHint')}
          </Text>
          <Form initialValues={initialValues} onSubmit={handleSubmit}>
            {form => {
              return (
                <>
                  <FieldRow>
                    <FormField.Checkbox
                      name="sendNewsEmail"
                      type="checkbox"
                      label={t(
                        'translation.NotificationPreferences.emailSettings2'
                      )}
                      labelAlignment={'horizontal'}
                      helpText={t(
                        'translation.NotificationPreferences.promoHint'
                      )}
                    />
                  </FieldRow>
                  <FieldRow>
                    <FormField.Checkbox
                      name="sendHelpEmail"
                      type="checkbox"
                      label={t(
                        'translation.NotificationPreferences.emailSettings3'
                      )}
                      labelAlignment={'horizontal'}
                      helpText={t(
                        'translation.NotificationPreferences.infoHint'
                      )}
                    />
                  </FieldRow>
                  <FieldRow>
                    <FormField.Checkbox
                      name="sendReminderEmail"
                      type="checkbox"
                      label={t('translation.PlansForm.reminders')}
                      labelAlignment={'horizontal'}
                      helpText={t(
                        'translation.NotificationPreferences.emailBookingHint'
                      )}
                    />
                  </FieldRow>
                  <FieldRow>
                    <FormField.Checkbox
                      name="sendBookingEmail"
                      type="checkbox"
                      label={t(
                        'translation.NotificationPreferences.emailSettings1'
                      )}
                      labelAlignment={'horizontal'}
                      helpText={t(
                        'translation.NotificationPreferences.emailBookingHint'
                      )}
                    />
                  </FieldRow>
                  {!form.values.sendBookingEmail && (
                    <ErrorWrapper>
                      <Error color={'#fe9060'} />
                      <ErrorText>
                        {t(
                          'translation.NotificationPreferences.bookingWarning'
                        )}
                      </ErrorText>
                    </ErrorWrapper>
                  )}
                  <Button
                    type="submit"
                    buttonType="primary"
                    name="saveChanges"
                    label={t('translation.OnLineBookingSettings.button-save')}
                  />
                </>
              )
            }}
          </Form>
        </Wrapper>
      </Container>
    </Body>
  )
}
export default NotificationPreferences
export const Wrapper = styled.div``

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ErrorText = styled.span`
  color: #fe9060;
  max-width: 400px;
  margin-left: 10px;
`
