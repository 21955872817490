import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Copy = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2 6.4h0a.9.9 0 01.9-.9h9.178a1 1 0 01.717.3l2.922 3.006h0a1 1 0 01.283.7V22.6h0a.9.9 0 01-.9.9H2.9h0a.9.9 0 01-.9-.9z"></path>
        <path d="M8 5.5V1.4h0a.9.9 0 01.9-.9s0 0 0 0h9.178a1 1 0 01.722.3l2.922 3.006h0a1 1 0 01.283.7V17.6a.9.9 0 01-.9.9H16"></path>
      </g>
    </StyledIcon>
  )
}
export default Copy
