import React, { useContext, useState } from 'react'

const ScreenLockContext = React.createContext<{
  isLocked: boolean
  lock: () => void
  unLock: () => void
}>({
  isLocked: false,
  lock: () => {},
  unLock: () => {},
})

export const useScreenLock = () => {
  const screenLock = useContext(ScreenLockContext)

  return {
    isLocked: screenLock.isLocked,
    lock: screenLock.lock,
    unLock: screenLock.unLock,
  }
}

export const ScreenLockProvider = props => {
  const [isLocked, setIsLocked] = useState(false)
  return (
    <ScreenLockContext.Provider
      value={{
        isLocked: isLocked,
        lock: () => setIsLocked(true),
        unLock: () => setIsLocked(false),
      }}
    >
      {props.children}
    </ScreenLockContext.Provider>
  )
}
