import React, { useCallback } from 'react'
import { ErrorContainer, ErrorText, ErrorTitle } from './style'
import Button from '../Button/Button'
import { Translation } from 'react-i18next'
const ErrorBoundary = () => {
  const onRefresh = useCallback(() => {
    window.history.back()
    setTimeout(() => window.location.reload(), 1000)
  }, [])

  // You can render any custom fallback UI
  return (
    <Translation>
      {(t, { i18n }) => (
        <ErrorContainer>
          <img src={'/assets/ooops.svg'} alt="Glass" />
          <ErrorTitle>
            {t('translation.ErrorBoundary.label-error-404')}
          </ErrorTitle>
          <ErrorText>
            {t('translation.ErrorBoundary.label-error-line-1')}
            <br /> {t('translation.ErrorBoundary.label-error-line-2')}
          </ErrorText>
          <Button
            buttonType={'primary'}
            onClick={onRefresh}
            label={t('translation.ErrorBoundary.label-refresh')}
          />
        </ErrorContainer>
      )}
    </Translation>
  )
}
export default ErrorBoundary
