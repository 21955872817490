import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Loader } from 'components'
import {
  useGetLocationLoading,
  queryLocation,
  useUpdateResourceSequence,
} from 'state/queries'
import { StickyHeader } from 'views/Settings/styles'
import { ResourcesTable } from 'views/Settings/Resources/ResourcesTable'
import { ListBodyWrapper } from 'src/styles'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { MobileContext } from 'src/Context'
import {
  ProfessionAllDocument,
  useLocationSetResourcesMutation,
  useProfessionAllQuery,
  useProfessionDeleteMutation,
} from 'state/graphql'
import { Add } from 'components/Icon/Icons/Add'
import { TabContainer } from 'src/styles'
import { TabView, Tab } from 'components'
import { useTableHeightCalculator } from 'components/Table/useTableHeightCalculator'
import { ResourceTypesTable } from 'views/Settings/Resources/ResourceTypesTable'
import { EmptyList } from 'components/EmptyList/EmptyList'

const ResourcesList = props => {
  const params = useParams<any>()
  const { selectedLocationId, selectedTab } = params
  const { t, navigateTo, appServices } = useSdk()
  const isMobile = useContext(MobileContext)

  useHeader({
    content: t('translation.ResourcesList.title-resources'),
    onGoBack: navigateTo.locationSettings,
  })
  const { data, loading } = useGetLocationLoading(selectedLocationId)
  const { tableContainerRef, tableHeight } = useTableHeightCalculator(0)

  const {
    data: professionsData,
    loading: loadingProfessions,
  } = useProfessionAllQuery()

  const professionsAll = professionsData?.profession?.all
  const resourceTypes = professionsAll?.filter(x => x?.kind === 'RESOURCE')
  const isEmpty = resourceTypes?.length === 0

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    Number(selectedTab)
  )

  const [selectedResources, setSelectedResources] = useState<any[]>([])
  const [setResources] = useLocationSetResourcesMutation()
  const [isUpdating, setIsUpdating] = useState(false)
  const [
    updateResourceSequence,
    { loading: loadingUpdateResourceSequence },
  ] = useUpdateResourceSequence()

  const [isBusy, setIsBusy] = useState(false)
  useEffect(() => {
    if (loading) return

    if (data?.location?.get?.resources) {
      const resources = data?.location?.get?.resources.filter(
        x => x.kind === 'RESOURCE'
      )
      setSelectedResources(resources)
      setIsUpdating(false)
    }
  }, [data, loading])

  async function reSequence(data) {
    try {
      setIsBusy(true)
      setIsUpdating(true)
      const newData = data
        .filter(e => e.id)
        .map((x, index) => {
          return {
            resourceId: x.id,
            sequence: index + 1000,
            locationId: selectedLocationId,
          }
        })

      await updateResourceSequence({
        variables: {
          input: {
            locationId: selectedLocationId,
            resourceSequence: newData,
          },
        },
        refetchQueries: [
          {
            query: queryLocation,
            variables: {
              id: selectedLocationId,
            },
          },
        ],
      })
    } finally {
      setIsBusy(false)
    }
  }

  async function removeResource(selectedResources) {
    try {
      setIsBusy(true)
      let resources = selectedResources.map(x => x.id)

      let result = await setResources({
        variables: {
          locationId: selectedLocationId,
          resourceId: resources,
        },
        refetchQueries: [
          {
            query: queryLocation,
            variables: {
              id: selectedLocationId,
            },
          },
        ],
      })

      if (result) {
        appServices.toast.success(
          t('translation.EditResource.toast-delete-resource')
        )
      }
    } finally {
      setIsBusy(false)
    }
  }
  const moveItemUp = index => {
    if (index === 0) return
    const newOrder = [...selectedResources]
    newOrder.splice(index, 0, newOrder.splice(index - 1, 1)[0])
    setSelectedResources(newOrder)
    setTimeout(() => reSequence(newOrder))
  }
  const moveItemDown = async index => {
    if (index === selectedResources.length - 1) return
    const newOrder = [...selectedResources]
    newOrder.splice(index, 0, newOrder.splice(index + 1, 1)[0])
    setSelectedResources(newOrder)
    setTimeout(() => reSequence(newOrder))
  }

  const [
    resourceTypeDeleteMutation,
    { loading: loadingDeleteMutation },
  ] = useProfessionDeleteMutation()

  const removeResourceType = async id => {
    try {
      let results = await resourceTypeDeleteMutation({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: ProfessionAllDocument,
          },
        ],
      })
      if (!results.errors) {
        appServices.toast.success(
          t('translation.ResourcesList.toast-delete-success')
        )
      } else appServices.toast.danger(results?.errors?.[0]?.message)
    } catch {
      appServices.toast.danger(
        t('translation.ProfessionsListMobile.toast-delete-error')
      )
    }
  }

  useEffect(() => {
    setSelectedTabIndex(Number(selectedTab))
  }, [selectedTab])

  useEffect(() => {
    navigateTo.resourcesList({ selectedTab: selectedTabIndex })
  }, [selectedTabIndex])

  if (loading || loadingProfessions) return <Loader isComponent />

  return (
    <>
      {!isMobile ? (
        <TabContainer ref={tableContainerRef}>
          <TabView
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            isSingleTab={false}
            hasFooter={false}
          >
            <Tab
              hasFooter={false}
              title={t('translation.ResourcesList.title-resources')}
            >
              <div className="flex flex-col overflow-y-auto w[100%] p-[0] lg:p-4">
                {!selectedResources?.length ? (
                  <EmptyList
                    title={t('translation.ResourcesList.title-no-resource')}
                    showButton
                    buttonLabel={t('translation.ResourcesList.label-add-new')}
                    buttonCallback={() => navigateTo.newResource({})}
                  />
                ) : (
                  <div className="relative flex min-h-0 flex-col w-[100%]">
                    <StickyHeader
                      style={{
                        justifyContent: 'flex-end',
                        marginBottom: '10px',
                      }}
                    >
                      <Button
                        onClick={() => navigateTo.newResource({})}
                        iconComponent={<Add size="smaller" stroke={3} />}
                        buttonType={'primary'}
                        size={'small'}
                        label={t('translation.ResourcesList.label-add-new')}
                        name={'addEmployee'}
                      />
                    </StickyHeader>

                    <ListBodyWrapper id={'list-body-wrapper'}>
                      <ResourcesTable
                        isUpdating={isUpdating}
                        selectedResources={selectedResources}
                        updateResourceSequence={reSequence}
                        removeResource={removeResource}
                        tableRef={null}
                        moveItemUp={moveItemUp}
                        moveItemDown={moveItemDown}
                        tableHeight={tableHeight - 190}
                      />
                    </ListBodyWrapper>
                  </div>
                )}
              </div>
            </Tab>

            <Tab
              hasFooter={false}
              title={t('translation.ResourcesList.label-resource-types')}
            >
              <div className="flex flex-col overflow-y-auto w[100%] p-[0] lg:p-4">
                {isEmpty ? (
                  <EmptyList
                    title={t(
                      'translation.ResourcesList.title-no-resource-type'
                    )}
                    showButton
                    buttonLabel={t('translation.ResourcesList.label-add-new')}
                    buttonCallback={() => navigateTo.newResourceType({})}
                  />
                ) : (
                  <div className="relative flex min-h-0 flex-col w-[100%]">
                    <StickyHeader
                      style={{
                        justifyContent: 'flex-end',
                        marginBottom: '10px',
                      }}
                    >
                      <Button
                        onClick={() => navigateTo.newResourceType({})}
                        iconComponent={<Add size="smaller" stroke={3} />}
                        buttonType={'primary'}
                        size={'small'}
                        label={t('translation.ResourcesList.label-add-new')}
                        name={'addEmployee'}
                      />
                    </StickyHeader>

                    <ListBodyWrapper id={'list-body-wrapper'}>
                      <ResourceTypesTable
                        selectedResourceTypes={resourceTypes}
                        removeResourceType={removeResourceType}
                        tableHeight={tableHeight - 190}
                      />
                    </ListBodyWrapper>
                  </div>
                )}
              </div>
            </Tab>

            {loading ||
            isBusy ||
            loadingUpdateResourceSequence ||
            loadingDeleteMutation ? (
              <Loader isComponent />
            ) : null}
          </TabView>
        </TabContainer>
      ) : null}
    </>
  )
}
export default ResourcesList
