import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAppServices } from 'sdk/appServices'

type TProps = {
  path: string
  children: any
  referrer?: string
}

export const PrivateRoute = (props: TProps) => {
  const { path, children } = props
  const location = useLocation()
  const appServices = useAppServices()
  const isSignedIn = appServices.auth.isSignedIn()
  if (!appServices.isInitialized) return null
  if (
    !isSignedIn &&
    !location.pathname.startsWith('/signup') &&
    location.pathname !== '/register' &&
    location.pathname !== '/invite'
  ) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { referrer: location.pathname } }}
      />
    )
  }

  return <Route path={path}>{children}</Route>
}
