import React, { useContext } from 'react'
import { Auth } from './Auth'
import { History } from 'history'
import { ApolloClient } from '@apollo/client'
import { Toast } from './Toast'
import i18n from '../localization'

export class AppServices {
  auth: Auth
  history: History
  apolloClient: ApolloClient<any>
  isInitialized: Boolean
  toast: Toast
  constructor(apolloClient: ApolloClient<any>, history: History) {
    this.history = history
    this.apolloClient = apolloClient
    this.isInitialized = false
    this.toast = new Toast()

    this.auth = new Auth(apolloClient, history, this.toast)
  }

  updateUserLanguage = (language, reload) => {
    const lowerCaseLanguage = language?.toLowerCase()
    localStorage.setItem('i18nextLng', lowerCaseLanguage)
    document.cookie = `i18next=${lowerCaseLanguage};path=/;domain=zoyya.com}`
    document.cookie = `i18next=${lowerCaseLanguage};path=/;domain=azurewebsites.net}`
    document.cookie = `i18next=${lowerCaseLanguage};path=/;domain=localhost}`

    i18n.changeLanguage(lowerCaseLanguage)
    if (reload) {
      window.location.reload()
    }
  }
  handleMutationResult = (result: any, successMessage: string) => {
    const error = result.errors && result.errors.length && result.errors[0]
    if (error) {
      this.toast.danger(
        error.message || i18n.t('translation.appServices.errorNotification')
      )
    } else if (successMessage) {
      this.toast.success(successMessage)
    }
    return {
      onSuccess: (handler: () => any) => {
        if (!error) {
          handler?.()
        }
      },
    }
  }
  init = async () => {
    try {
      return await this.auth.init()
    } finally {
      this.isInitialized = true
    }
  }
}

export const AppContext = React.createContext({})

export const useAppServices = (): AppServices => {
  const appServices = useContext(AppContext)
  return appServices as AppServices
}
