import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: ${props => props.fill || 'none'};
`

export const WifiWarning = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g
        fill="inherit"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.032 7.6h0a8.944 8.944 0 0112.653 0"></path>
        <path d="M18.216 4.169h0C13.324-.723 5.392-.723.5 4.169c0 0 0 0 0 0M5.562 11.02h0a5.37 5.37 0 017.593 0"></path>
        <path d="M15.685 7.6h0a8.944 8.944 0 00-12.653 0M13.155 11.02h0a5.37 5.37 0 00-7.593 0M11.147 15.71h0a1.79 1.79 0 11-1.789-1.789h0c.988 0 1.789.801 1.789 1.789zM17.358 20.5h0a.25.25 0 11-.25.25h0a.25.25 0 01.25-.25M17.36 18.5v-3"></path>
        <path d="M18.2 12.5h0a.95.95 0 00-1.676 0l-5.056 9.635h0a.923.923 0 00.031.914h0a.948.948 0 00.807.448h10.108a.948.948 0 00.807-.448h0a.923.923 0 00.031-.914z"></path>
      </g>
    </StyledIcon>
  )
}
