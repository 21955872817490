import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button } from 'components'
import moment from 'moment'
import { FieldArray } from 'formik'
import { MobileContext } from 'src/Context'
import ReactDOM from 'react-dom'
import {
  formatPriceNumberWithDelimiter,
  getISODate,
  getServiceDurationInMinutes,
  getServiceHoursAndMinutes,
  getServiceWithLatestEnding,
} from '../../../helpers'
import { useSdk } from 'sdk'
import { icons } from 'components'
import { ServiceCard } from 'views/Appointment/components/ServiceCard'
import { FreeSpaceCard } from 'views/Appointment/components/FreeSpaceCard'
import { ServiceForm } from 'views/Appointment/components/ServiceForm'
import { findIndex, sumBy } from 'lodash'
import { useACL } from 'src/sdk/acl'
import {
  AppointmentFindQuery,
  AppointmentResourceBooking,
} from 'src/state/graphql'

export type TServiceStartTimeProps = {
  id: string
  isBusy: boolean
  isInactive: boolean
  title: string
}
export type TServiceItemProps = {
  duration: string
  durationMinutes: number
  employee: string
  endTime: string
  endTimeUtc: string
  id: number
  isEdit: boolean
  isNew: boolean
  missingProfession: boolean
  original: any
  resourceId?: string
  resourceType?: string
  service?: string
  startTime: TServiceStartTimeProps
  startTimeUtc: string
  discountType: string
  totalAmount: number
  discountRate: number
  discountAmount: number
  discountValue?: number
  price?: number
  currencyId?: string
  giftCard?: AppointmentResourceBooking['services'][0]['giftCard']
  giftCardAmount?: number
}
export type TServiceProps = {
  bookingAllowed?: boolean
  color?: string
  description?: string
  durationMinutes: number
  hasBookingConfirmation?: boolean
  id: string
  name: string
  price: number
  profession?: { id: string; name: string }
  resourceType: { id: string; name: string }
  sequence?: any
  vatRate: number
}
type TServiceComponentProps = {
  setFieldValue: (arg0: string, arg1: any) => void
  getWorkHours: (
    arg0: string,
    arg1: string,
    arg2: number
  ) => TServiceStartTimeProps[]
  services: any[]
  duration: { id: number; title: string }[]
  workingEmployees: (arg0: string, arg1: string) => any[]
  values: any
  isLoading: boolean
  flattenedServices: TServiceProps[]
  validateForm: () => any
  assignToResource?: any
  timeSlotDuration: number
  onNewService: () => void
  newEvent: {
    selectedHour: string
    selectedMinute: string
    resourceId?: string
    selectedDayId?: string
  }
  employees: any[]
  resources: any[]
  workingResources: (arg0: string, arg1: string) => any[]
  setServiceInEdit: (arg0: TServiceItemProps) => void
  totalServicesPrice: number
  error?: any[] | string | undefined
  setError: any
  isMenuOpen?: boolean
  setIsMenuOpen?: any
  totalServicesDiscount?: number
  totalServicesGiftCardDiscount?: number
}
export const Service = (props: TServiceComponentProps) => {
  const {
    setFieldValue,
    getWorkHours,
    services,
    duration,
    workingEmployees,
    values,
    isLoading,
    flattenedServices,
    validateForm,
    assignToResource,
    timeSlotDuration,
    onNewService,
    newEvent,
    employees,
    resources,
    workingResources,
    setServiceInEdit,
    totalServicesPrice,
    error,
    setError,
    isMenuOpen,
    setIsMenuOpen,
    totalServicesDiscount,
    totalServicesGiftCardDiscount,
  } = props
  const { t } = useSdk()
  const selectedTheme = useContext(ThemeContext)
  const isMobile = useContext(MobileContext)
  const { hasPermission } = useACL()
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const getEndTime = (item: TServiceItemProps) => {
    const duration =
      item?.durationMinutes || getServiceDurationInMinutes(item?.duration)
    return item
      ? moment(`2000-01-01 ${item?.startTime?.id}`)
          .add('minutes', duration)
          .format('HH:mm')
      : newEvent?.selectedHour
      ? `${newEvent.selectedHour}:${newEvent.selectedMinute}`
      : moment().format('HH') + ':30'
  }

  const selectedServices =
    values?.services
      ?.sort((a, b) =>
        moment(
          moment().format('YYYY-MM-DD').toString() +
            ' ' +
            a?.startTime?.id.toString()
        ).diff(
          moment(
            moment().format('YYYY-MM-DD').toString() +
              ' ' +
              b?.startTime?.id.toString()
          )
        )
      )
      ?.map((item, idx) => {
        return {
          ...item,
          id: idx,
        }
      }) || []

  const [indexInEdit, setIndexInEdit] = useState<number>()
  const idxInEdit = findIndex(selectedServices, {
    isEdit: true,
  })
  const itemInEdit = selectedServices[idxInEdit]
  const isStatusDraft = values.status === 'DRAFT'

  const lastItem = selectedServices?.[0]
    ? selectedServices[selectedServices?.length - 1]
    : null

  const durationInMinutes =
    (getServiceWithLatestEnding(values?.services, true) as number) -
    getServiceDurationInMinutes(values?.services[0]?.startTime?.id)

  return (
    <FieldArray name="services">
      {({ push, replace, remove }) => (
        <div>
          {selectedServices?.length > 0 &&
            selectedServices.map((item, index) => {
              return (
                <>
                  {getServiceDurationInMinutes(
                    selectedServices[index - 1]?.endTime
                  ) <
                    getServiceDurationInMinutes(
                      selectedServices[index]?.startTime?.title
                    ) && index > 0 ? (
                    <FreeSpaceCard
                      item={{
                        id: index,
                        duration: getServiceHoursAndMinutes(
                          getServiceDurationInMinutes(
                            selectedServices[index]?.startTime?.title
                          ) -
                            getServiceDurationInMinutes(
                              selectedServices[index - 1]?.endTime
                            )
                        ),
                        durationMinutes:
                          getServiceDurationInMinutes(
                            selectedServices[index]?.startTime?.title
                          ) -
                          getServiceDurationInMinutes(
                            selectedServices[index - 1]?.endTime
                          ),
                        startTime: selectedServices[index - 1]?.endTime,
                        endTime: selectedServices[index]?.startTime?.title,
                      }}
                      itemToChange={selectedServices[index]}
                      replace={replace}
                      selectedServices={selectedServices}
                      timeSlotDuration={timeSlotDuration}
                    />
                  ) : null}
                  {!item.isEdit ? (
                    <ServiceCard
                      item={item}
                      index={index}
                      remove={remove}
                      replace={replace}
                      getEndTime={getEndTime}
                      flattenedServices={flattenedServices}
                      employees={employees}
                      resources={resources}
                      selectedServices={selectedServices}
                      setIndexInEdit={setIndexInEdit}
                      setServiceInEdit={setServiceInEdit}
                      values={values}
                      setIsMenuOpen={setIsMenuOpen}
                      timeSlotDuration={timeSlotDuration}
                      setFieldValue={setFieldValue}
                    />
                  ) : !isMobile ? (
                    <ServiceForm
                      index={index}
                      item={item}
                      remove={remove}
                      replace={replace}
                      isMobile={false}
                      allResources={resources}
                      workingEmployees={workingEmployees}
                      workingResources={workingResources}
                      flattenedServices={flattenedServices}
                      values={values}
                      isLoading={isLoading}
                      selectedServices={selectedServices}
                      setIsMenuOpen={setIsMenuOpen}
                      timeSlotDuration={timeSlotDuration}
                      setFieldValue={setFieldValue}
                      onNewService={onNewService}
                      validateForm={validateForm}
                      setError={setError}
                      indexInEdit={indexInEdit}
                      idxInEdit={idxInEdit}
                      itemInEdit={itemInEdit}
                      getWorkHours={getWorkHours}
                      services={services}
                      duration={duration}
                    />
                  ) : null}
                </>
              )
            })}

          <div className="hidden items-end justify-end mb-[20px] lg:flex lg:justify-between lg:items-center">
            {values?.services?.length ? (
              <div className="min-w-[180px] mt-[40px] lg:mt-0">
                {totalServicesPrice &&
                (values?.posPaymentRequest?.status === 'PAID' ||
                  totalServicesGiftCardDiscount ||
                  totalServicesDiscount) ? (
                  <div className="flex justify-between">
                    <span>{t('translation.Sidebar.label-total')}:</span>
                    <span
                      style={{ fontWeight: 500 }}
                    >{` ${formatPriceNumberWithDelimiter(
                      totalServicesPrice
                    )} ${values?.currencyId}`}</span>
                  </div>
                ) : null}

                {values?.posPaymentRequest?.status === 'PAID' ? (
                  <div className="flex justify-between">
                    <span>{t('translation.Sidebar.label-advance')}:</span>
                    <span
                      style={{ fontWeight: 500 }}
                    >{`- ${formatPriceNumberWithDelimiter(
                      values?.posPaymentRequest?.amount || 0
                    )} ${values?.currencyId}`}</span>
                  </div>
                ) : null}

                {totalServicesGiftCardDiscount ? (
                  <div className="flex justify-between">
                    <span>Poklon bon:</span>
                    <span className="font-medium">{`- ${formatPriceNumberWithDelimiter(
                      totalServicesGiftCardDiscount || 0
                    )} ${values?.currencyId}`}</span>
                  </div>
                ) : null}
                {totalServicesDiscount ? (
                  <div className="flex justify-between">
                    <span>Popust:</span>
                    <span className="font-medium">{`- ${formatPriceNumberWithDelimiter(
                      totalServicesDiscount || 0
                    )} ${values?.currencyId}`}</span>
                  </div>
                ) : null}
                <div className="w-[100%] my-[10px] mx-[0] border-t-[1px] border-zoyya-outline" />
                <div className="flex justify-between">
                  <span>{t('translation.Sidebar.label-to-pay')}:</span>
                  <span className="font-medium">{` ${formatPriceNumberWithDelimiter(
                    totalServicesPrice -
                      (totalServicesGiftCardDiscount || 0) -
                      (totalServicesDiscount || 0) -
                      (values?.posPaymentRequest?.status === 'PAID'
                        ? values?.posPaymentRequest?.amount || 0
                        : 0)
                  )} ${values?.currencyId}`}</span>
                </div>
              </div>
            ) : null}

            <div className="ml-[60px] flex justify-between items-start">
              <span>{t('translation.ServicesList.label-duration')}:</span>
              <span className="font-medium ml-[10px]">{`${durationInMinutes} min`}</span>
            </div>
            {canEditAppointment &&
            !selectedServices.some(item => item.isEdit) &&
            !isMobile &&
            !isStatusDraft &&
            values?.status !== 'canceled' ? (
              <div className="flex justify-end ml-[auto]">
                <Button
                  label={t('translation.Service.dodaj-uslugu')}
                  buttonType={'secondaryLink'}
                  onClick={e => {
                    e.preventDefault()
                    const startTime = !values?.services?.length
                      ? {
                          id: values?.startTime,
                          title: values?.startTime,
                        }
                      : {
                          id: getEndTime(lastItem),
                          title: getEndTime(lastItem),
                        }
                    const endTime = getServiceHoursAndMinutes(
                      getServiceDurationInMinutes(startTime?.id) +
                        getServiceDurationInMinutes(duration[0]?.id)
                    )
                    push({
                      id: values?.services?.length,
                      isNew: true,
                      isEdit: true,
                      isNotSaved: true,
                      employee: values?.services?.length
                        ? values?.services[values?.services?.length - 1]
                            ?.original?.employee
                        : values?.employeeId || assignToResource?.id,
                      startTime,
                      startTimeUtc: values?.date,
                      endTime,
                      endTimeUtc: moment(
                        moment(getISODate())?.format('YYYY-MM-DD') +
                          ' ' +
                          endTime
                      ).toISOString(),
                      duration: duration[0]?.id,
                      durationMinutes: getServiceDurationInMinutes(
                        duration[0]?.id
                      ),
                      price: 0,
                      discountAmount: 0,
                      discountRate: 0,
                      discountType: 'percent',
                      discountValue: 0,
                      totalAmount: 0,
                      giftCard: null,
                    })
                  }}
                />
              </div>
            ) : null}
          </div>

          {isMobile && values?.status !== 'canceled' ? (
            <>
              {canEditAppointment ? (
                <div>
                  {!isStatusDraft ? (
                    <Button
                      label={t('translation.ServicesList.button-add-service')}
                      buttonType={'secondaryLink'}
                      iconComponent={
                        <icons.Add
                          size="smaller"
                          color={selectedTheme.colors.primary}
                        />
                      }
                      hasValidationError={error}
                      iconRight
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setIsMenuOpen(true)

                        push({
                          isNew: true,
                          isEdit: true,
                          isNotSaved: true,
                          employee: values?.services?.length
                            ? values?.services[values?.services?.length - 1]
                                ?.original?.employee
                            : values?.employeeId || assignToResource?.id,
                          startTime: !values?.services?.length
                            ? {
                                id: values?.startTime,
                                title: values?.startTime,
                              }
                            : {
                                id: getEndTime(lastItem),
                                title: getEndTime(lastItem),
                              },
                          duration: duration[0]?.id,
                          durationMinutes: getServiceDurationInMinutes(
                            duration[0]?.id
                          ),
                          price: 0,
                          discountAmount: 0,
                          discountRate: 0,
                          discountType: 'percent',
                          discountValue: 0,
                          totalAmount: 0,
                          giftCard: null,
                        })
                      }}
                    />
                  ) : null}
                  {isMenuOpen &&
                    ReactDOM.createPortal(
                      <div className="absolute top-0 left-0 h-[100%] w-[100vw] bg-[rgba(0, 0, 0, 0.5)]">
                        <MenuOptionsContainer>
                          <ServiceForm
                            item={
                              itemInEdit
                                ? itemInEdit
                                : {
                                    isEdit: true,
                                    employee: assignToResource?.id,
                                    durationMinutes: lastItem?.durationMinutes,
                                    startTime: {
                                      id: getEndTime(lastItem),
                                      title: getEndTime(lastItem),
                                    },
                                  }
                            }
                            index={
                              //if we are editing an item, get it's index, otherwise set the new index
                              idxInEdit >= 0
                                ? idxInEdit
                                : selectedServices.length > 0
                                ? selectedServices.length - 1
                                : 0
                            }
                            remove={remove}
                            replace={replace}
                            isMobile={true}
                            allResources={resources}
                            workingEmployees={workingEmployees}
                            workingResources={workingResources}
                            flattenedServices={flattenedServices}
                            values={values}
                            isLoading={isLoading}
                            selectedServices={selectedServices}
                            setIsMenuOpen={setIsMenuOpen}
                            timeSlotDuration={timeSlotDuration}
                            setFieldValue={setFieldValue}
                            onNewService={onNewService}
                            indexInEdit={indexInEdit}
                            validateForm={validateForm}
                            setError={setError}
                            idxInEdit={idxInEdit}
                            itemInEdit={itemInEdit}
                            getWorkHours={getWorkHours}
                            services={services}
                            duration={duration}
                          />
                        </MenuOptionsContainer>
                      </div>,
                      document.getElementById(
                        'react-select-portal-target'
                      ) as HTMLDivElement
                    )}
                </div>
              ) : null}
              {values?.services[0]?.isNew !== true &&
              values?.services?.length ? (
                <div className="min-w-[150px] mt-[40px] lg:mt-0">
                  {totalServicesPrice &&
                  (values?.posPaymentRequest?.status === 'PAID' ||
                    totalServicesGiftCardDiscount ||
                    totalServicesDiscount) ? (
                    <div className="flex justify-between">
                      <span>{t('translation.Sidebar.label-total')}:</span>
                      <span
                        style={{ fontWeight: 500 }}
                      >{` ${formatPriceNumberWithDelimiter(
                        totalServicesPrice
                      )} ${values?.currencyId}`}</span>
                    </div>
                  ) : null}

                  {values?.posPaymentRequest?.status === 'PAID' ? (
                    <div className="flex justify-between">
                      <span>{t('translation.Sidebar.label-advance')}:</span>
                      <span
                        style={{ fontWeight: 500 }}
                      >{`- ${formatPriceNumberWithDelimiter(
                        values?.posPaymentRequest?.amount || 0
                      )} ${values?.currencyId}`}</span>
                    </div>
                  ) : null}

                  {totalServicesGiftCardDiscount ? (
                    <div className="flex justify-between">
                      <span>Poklon bon:</span>
                      <span className="font-medium">{`- ${formatPriceNumberWithDelimiter(
                        totalServicesGiftCardDiscount || 0
                      )} ${values?.currencyId}`}</span>
                    </div>
                  ) : null}
                  {totalServicesDiscount ? (
                    <div className="flex justify-between">
                      <span>Popust:</span>
                      <span className="font-medium">{`- ${formatPriceNumberWithDelimiter(
                        totalServicesDiscount || 0
                      )} ${values?.currencyId}`}</span>
                    </div>
                  ) : null}
                  <div className="w-[100%] my-[10px] mx-[0] border-t-[1px] border-zoyya-outline" />
                  <div className="flex justify-between">
                    <span>{t('translation.Sidebar.label-to-pay')}:</span>
                    <span className="font-medium">{` ${formatPriceNumberWithDelimiter(
                      totalServicesPrice -
                        (totalServicesGiftCardDiscount || 0) -
                        (totalServicesDiscount || 0) -
                        (values?.posPaymentRequest?.status === 'PAID'
                          ? values?.posPaymentRequest?.amount || 0
                          : 0)
                    )} ${values?.currencyId}`}</span>
                  </div>

                  <div className="flex justify-between mt-[20px]">
                    <span>{t('translation.ServicesList.label-duration')}:</span>
                    <span
                      style={{ fontWeight: 500 }}
                    >{`${durationInMinutes} min`}</span>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      )}
    </FieldArray>
  )
}

type TMenuOptionsContainer = {
  isCalendarMenu?: boolean
}
const MenuOptionsContainer = styled.div<TMenuOptionsContainer>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 100%;
  height: 100%;
  border-radius: 0px;
  position: absolute;
  bottom: -400px;
  background-color: ${props => props.theme.colors.light};
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 30px 0px 0px 0px;
  animation: menu 0.5s linear;
  animation-fill-mode: forwards;
  @keyframes menu {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-400px);
    }
  }
`
