import styled, { keyframes } from 'styled-components'
import { easeOutQuart } from 'components/style/animations'
import { StyledFieldsContainer } from '../../../components/Form/Form'
import { StyledTabContainer } from 'components/TabView/TabView'
import { FormsContainer } from '../../../styles'
import { device } from 'src/constants'

const stagingAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const ServiceSelect = styled.input`
  height: 40px;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  border: ${props => `1px solid ${props.theme.colors.grayLight}`};
  cursor: pointer;
  outline: none;
  margin: 10px;
  ::placeholder {
    color: ${props => props.theme.colors.secondaryDarker};
  }
  flex: 1;
  margin-left: 0;
  :focus {
    border: 1px solid #6a4ee1;
    box-shadow: 0px 0px 0px 3px #6b81ff;
  }
`

export const ServiceSelectModal = styled.div`
  max-height: 300px;
  position: absolute;
  overflow: auto;
  background: white;
  z-index: 1;
  top: 45px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  right: 0;
  padding: 0 10px 5px 10px;
  width: 100%;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
`

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 640px;
  box-sizing: border-box;
  //width: 100%;
  z-index: 100000;
  flex-direction: column;
  top: 0px;
  left: 0px;
  overflow: auto;
  animation: 200ms ${fadeIn} ${easeOutQuart};
`

export const FormWrapper = styled.div`
  flex: 1;
  overflow: auto;
  background: white;

  form {
    height: 100%;
  }

  ${StyledFieldsContainer} {
    height: 100%;
  }

  ${StyledTabContainer} {
    height: 100%;
  }

  ${FormsContainer} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const Modal = styled.section`
  background-size: 100% auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 0 1rem;
  width: 640px;
  height: 100vh;
  overflow: inherit;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: inherit;
  flex-direction: column;
  flex-grow: 0;
  outline: none;
  right: 0;
  animation: 300ms ${stagingAnimation} ${easeOutQuart};

  @media ${device.tablet} {
    width: 100%;
  }
`

export const PaymentMethodButton = styled.div<{
  isSelected: boolean | undefined
  isDisabled: boolean | undefined
}>`
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 6px;
  min-height: 90px;
  border: 1px solid ${props => props.theme.colors.outline};
  background: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.light};
  color: ${props =>
    props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.secondaryDarker};
  cursor: ${props => (props.isDisabled ? 'unset' : 'pointer')};
  opacity: ${props => (props.isDisabled ? '0.8' : '1')};
`

export const ServiceItem = styled.div<{ isOutOfStock?: boolean }>`
  display: flex;
  padding: 8px 0;
  align-items: center;
  color: ${props => (props.isOutOfStock ? props.theme.colors.accent5 : null)};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors.light};
  }
`

export const ServicePrice = styled.span<{ discount?: boolean | number }>`
  color: ${props => (props.discount ? props.theme.colors.secondaryDark : null)};
  text-decoration: ${props => (props.discount ? 'line-through' : null)};
  font-size: ${props => (props.discount ? '12px' : '14px')};
  line-height: ${props => (props.discount ? 1 : 1.5)};
`

export const ServiceQuantityButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  button {
    width: 35px;
    height: 35px;
    justify-content: center;
  }
`

export const ServiceDiscount = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  position: relative;
  left: 44px;
  button {
    width: 35px;
    height: 35px;
    justify-content: center;
  }
`

export const StyledDiscountInput = styled.input`
  width: 60px;
  text-align: center;
  outline: none;
  border: 0;
  background: transparent;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  border-radius: 0;
  :focus {
    border: 0;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    box-shadow: none;
  }
  //hide arrows
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`

export const PopupContainer = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
