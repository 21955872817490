import React, { useState } from 'react'
import { Flex, Button } from 'components'
import styled from 'styled-components'
import { useSdk } from 'sdk'
import { device } from '../../constants'

type TProps = {
  withConfirm?: boolean
  confirmationMessage?: string
  onConfirm?: () => any
  title?: string
  buttonLabel: string
  buttonIcon?: any
}
export const ButtonWithConfirmation = (props: TProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const {
    title,
    onConfirm,
    withConfirm,
    confirmationMessage,
    buttonLabel,
    buttonIcon,
  } = props

  const handleClick = e => {
    e.preventDefault()
    withConfirm && !showConfirmation ? setShowConfirmation(true) : onConfirm?.()
  }

  return (
    <StyledButtonsContainer
      style={{ justifyContent: 'flex-end', marginRight: '10px' }}
    >
      {showConfirmation ? (
        <ConfirmationPanel
          onYes={() => onConfirm?.()}
          onNo={() => setShowConfirmation(false)}
          message={confirmationMessage}
        />
      ) : (
        <Button
          type="button"
          buttonType="primaryAccent"
          iconComponent={buttonIcon}
          onClick={handleClick}
          label={buttonLabel}
        >
          {title}
        </Button>
      )}
    </StyledButtonsContainer>
  )
}
const StyledButtonsContainer = styled(Flex.Row)`
  button:last-child {
    margin-left: 6px;
  }
  @media ${device.tablet} {
    display: initial;
  }
`
const StyledMessageContainer = styled(Flex.Column)`
  justify-content: center;
  padding-right: 6px;
  max-width: 450px;
`
const ConfirmationPanel = props => {
  const { onYes, onNo, message } = props
  const { t } = useSdk()

  return (
    <>
      <StyledMessageContainer>{message}</StyledMessageContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="button"
          buttonType="secondary"
          onClick={onNo}
          label={t('translation.DeleteButton.label-no')}
        />
        <Button
          type="button"
          buttonType="primary"
          onClick={onYes}
          label={t('translation.DeleteButton.label-yes')}
        />
      </div>
    </>
  )
}