import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
  useContext,
  useRef,
} from 'react'
import { useField, useFormikContext } from 'formik'
import styled, { ThemeContext } from 'styled-components'
import {
  blue,
  blueLighter,
  grayLight,
  grayDarker,
  grayDark,
  red,
  redLight,
  redLighter,
  white,
} from '../../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../../style/fonts'
import { FieldContainer } from '../FieldContainer'
import { MobileContext } from 'src/Context'
import moment from 'moment'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.scss'
import Calendar from 'components/Icon/Icons/Calendar'
import { getCurrentLanguage } from 'src/localization'
import { useSdk } from 'sdk'

mobiscroll.settings = {
  theme: 'default',
  themeVariant: 'light',
  display: 'bubble',
}

const monthNamesLocale = {
  hr: [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
}
export const StyledInput = styled.input<any>`
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? redLight : white)};
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? red : grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  padding: 9px 8px 8px 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};
  padding-left: ${props => (props.hasIcon ? '50px' : 'auto')};

  &::placeholder {
    color: ${props => props.theme.colors.secondaryDark};
  }

  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? redLighter : blueLighter)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    background: #f1f1f2;
    border: 1px solid ${grayLight};
    color: #818e9c;
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
`
type TFormFieldTextProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  multiline?: boolean
  style?: any
  parseValue?: (value?: string) => any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  icon?: any
  showPassword?: boolean
  helpText?: string
  helpLink?: string
  displayFormat?: 'DD.MM.YYYY.' | 'DD/MM/YYYY'
}

export const FormFieldDateInput = React.forwardRef(
  (props: TFormFieldTextProps, ref) => {
    const { parseValue, onChange, icon, name, displayFormat, ...rest } = props
    const [field, meta] = useField(props)

    const selectedTheme = useContext(ThemeContext)
    const { setFieldValue } = useFormikContext()
    const { t } = useSdk()
    const isMobile = useContext(MobileContext)

    const dateRef = useRef<any>(null)

    const lang = getCurrentLanguage()
    const dateFormat: 'DD.MM.YYYY.' | 'DD/MM/YYYY' =
      displayFormat || 'DD/MM/YYYY'

    return (
      <FieldContainer
        label={props.label}
        error={meta.error}
        fieldName={field.name}
        type={props.type}
        icon={props.icon}
        helpText={props.helpText}
        helpLink={props.helpLink}
        warningPosition
      >
        <div className="mbsc" style={{ width: '100%', padding: 0 }}>
          <mobiscroll.Date
            ref={dateRef as any}
            value={
              field.value
                ? moment(field.value, 'YYYY-MM-DD').format(dateFormat)
                : null
            }
            showOnTap={false}
            lang={lang === 'en' ? 'en-US' : 'hr'}
            monthNames={monthNamesLocale[lang]}
            showOnFocus={false}
            buttons={[
              {
                text: t('translation.ClientsList.button-cancel'),
                handler: 'cancel',
              },
              {
                text: t('translation.LocationWorkHoursMobile.button-set'),
                handler: 'set',
              },
            ]}
            dateFormat={dateFormat === 'DD.MM.YYYY.' ? 'dd.mm.yy.' : 'dd/mm/yy'}
            disabled={props.disabled}
            display={isMobile ? 'bottom' : 'bubble'}
            onSet={(e, instance) => {
              setFieldValue(
                name,
                moment(instance.getVal()).format('YYYY/MM/DD')
              )
            }}
          >
            <StyledInput
              {...rest}
              ref={ref}
              style={
                meta.error
                  ? {
                      background: '#e972842e',
                      border: '1px solid #9D2637',
                    }
                  : {}
              }
              placeholder={
                lang === 'hr'
                  ? dateFormat === 'DD/MM/YYYY'
                    ? 'DD/MM/GGGG'
                    : 'DD.MM.GGGG.'
                  : dateFormat === 'DD/MM/YYYY'
                  ? 'DD/MM/YYYY'
                  : 'DD.MM.YYYY.'
              }
              name={name}
              data-cy={`input_birthDate`}
              autoFocus={!isMobile && props.autoFocus}
              disabled={props.disabled}
              onBlur={e => {
                setFieldValue(
                  name,
                  e.target.value
                    ? moment(e.target.value, dateFormat).format('YYYY/MM/DD')
                    : null
                )
              }}
              onChange={e => {
                if (!e.target.value) {
                  setFieldValue(name, null)
                }
              }}
            />
          </mobiscroll.Date>
          <IconContainer
            onClick={() => dateRef.current && dateRef.current.instance.show()}
          >
            <StyledCalendar
              stroke={1.5}
              size={'small'}
              color={selectedTheme.colors.primary}
            />
          </IconContainer>
        </div>
      </FieldContainer>
    )
  }
)
FormFieldDateInput.defaultProps = {
  autoComplete: 'off',
  spellCheck: false,
  autoCorrect: 'off',
  autoCapitalize: 'off',
}
const IconContainer = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  display: flex;
  padding: 11px;
  background: ${props => props.theme.colors.lightBackground};
  height: 45px;
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid #dbdfe2;
`
const StyledCalendar = styled(Calendar)<any>`
  color: ${props => props.theme.colors.primary};
`
