/**
 * Created by ivanazuberold on 05/12/2020.
 */
import React, { useState, useEffect } from 'react'
import { EventBodyWrapper } from 'views/AppointmentModal/styles'
import {
  formatPhone,
  getLatestOrNewestDate,
  formatPriceNumberWithDelimiter,
  getRatingStars,
  resolveFullName,
} from 'src/helpers'
import moment from 'moment'
import { useSdk } from 'sdk'
import { orderBy } from 'lodash'
import { sumBy } from 'lodash'
import {
  useClientGetBookingsQuery,
  AppointmentCancellationEnum,
  AppointmentStatusEnum,
} from 'state/graphql'
import styled from 'styled-components'
import { useInviteUser } from 'state/queries'
import { useAppServices } from 'sdk/appServices'
import { icons } from 'components/Icon'
import AppointmentCard from 'views/AppointmentModal/components/AppointmentCard'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { EmptyList } from 'components/EmptyList/EmptyList'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import { LoaderTW } from 'src/sdk/tw/Loader'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import ClientDetailsMobile from 'src/views/Clients/ClientDetailsMobile'
import { useACL } from 'src/sdk/acl'
import { Tooltip2 } from 'src/components/Tooltip2/Tooltip2'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'

type TClientListContainerProps = {}
export const ClientListContainer = styled.div<TClientListContainerProps>`
  border-radius: 10px;
  flex: 1;
  //max-height: calc(100vh - 443px);
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
`

type TClientDetailsProps = {
  initialValues: any
  onEdit: any
  setActiveForm: any
  isNew: boolean
  isHotel?: boolean
  mobile_onReminders?: () => void
}
export const ClientDetails = (props: TClientDetailsProps) => {
  const { initialValues, onEdit, isHotel, mobile_onReminders } = props
  const { t, isMobile } = useSdk()

  const { hasPermission } = useACL()
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const clientBookingsData = useClientGetBookingsQuery({
    variables: { clientId: initialValues?.client?.id },
    skip: !initialValues?.client?.id,
  })
  const { showPopup } = usePopup()
  useEffect(() => {
    clientBookingsData.refetch()
  }, [])

  const isLoading = clientBookingsData?.loading
  const allData = clientBookingsData?.data?.client?.getBookings

  const sortedAllEvents = orderBy(
    allData,
    ['date', 'startTime'],
    ['desc', 'asc']
  )

  const sortedFutureEvents = sortedAllEvents?.filter(
    event =>
      moment(event?.services?.[0]?.endTimeUtc).isAfter(moment()) &&
      event?.status !== AppointmentStatusEnum.Canceled
  )

  const sortedPastEvents = sortedAllEvents?.filter(
    event =>
      moment(event?.services?.[0]?.endTimeUtc).isBefore(moment()) &&
      event?.status !== AppointmentStatusEnum.Canceled
  )

  const noShows = sortedAllEvents?.filter(
    event => event?.status === AppointmentStatusEnum.Canceled
  )
  const clientNoShows = noShows?.filter(
    event => event?.reason === AppointmentCancellationEnum.ClientNoShow
  )

  const reviewsTotalSum = sumBy(sortedAllEvents, 'reviewRating')
  const reviewsTotalCount = sortedAllEvents.filter(item => item?.reviewRating)
    ?.length

  const reviewNumber =
    reviewsTotalSum && sortedAllEvents[0]
      ? Math.round(reviewsTotalSum / reviewsTotalCount)
      : 0

  const appServices = useAppServices()

  const [inviteUser] = useInviteUser()

  const [showAllCanceled, setShowAllCanceled] = useState(false)

  async function sendInvitation(values) {
    await inviteUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          gender: values.gender || 'Unspecified',
          mobilePhone: formatPhone(values.mobilePhone),
          role: 'CLIENT',
          clientId: values?.id,
        },
      },
    })
    appServices.toast.success(
      t('translation.EditClient.toast-employeeInvited', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
    )
  }
  const [filter, setFilter] = useState<string>('future')
  const [isInitialFilter, setIsInitialFilter] = useState(true)

  useEffect(() => {
    if (isInitialFilter) {
      setFilter(sortedFutureEvents.length > 0 ? 'future' : 'past')
    }
  }, [sortedFutureEvents])

  const totalBookingsPriceForLastYear =
    allData
      ?.filter(
        item =>
          item?.status !== AppointmentStatusEnum.Canceled &&
          item?.status !== 'noShow' &&
          moment()
            .subtract(365, 'day')
            .isSameOrBefore(moment(item?.date))
      )
      ?.map(
        item =>
          item?.services?.reduce(
            (n, service) =>
              n + (service?.service?.price ? service?.service?.price : 0),
            0
          )
      )
      ?.reduce((a, b) => {
        //@ts-ignore
        return a + b
      }, 0) || 0

  const getMonthlyAverage = () => {
    const latestDate = getLatestOrNewestDate(
      'min',
      allData
        ?.filter(
          item =>
            item?.status !== AppointmentStatusEnum.Canceled &&
            item?.status !== 'noShow'
        )
        ?.map(item => moment(item?.date))
    )
    const newestDate = getLatestOrNewestDate(
      'max',
      allData
        ?.filter(
          item =>
            item?.status !== AppointmentStatusEnum.Canceled &&
            item?.status !== 'noShow'
        )
        ?.map(item => moment(item?.date))
    )
    const isLatestDateInsideLastYear = moment()
      .subtract(365, 'day')
      .isSameOrBefore(moment(latestDate))

    if (isLatestDateInsideLastYear) {
      const dates =
        allData
          ?.filter(
            item =>
              item?.status !== AppointmentStatusEnum.Canceled &&
              item?.status !== 'noShow' &&
              moment(latestDate).isSameOrBefore(moment(item?.date))
          )
          ?.map(
            item =>
              item?.services?.reduce(
                (n, service) =>
                  n + (service?.service?.price ? service?.service?.price : 0),
                0
              )
          )
          ?.reduce((a, b) => {
            //@ts-ignore
            return a + b
          }, 0) || 0

      return moment(newestDate).month() === moment(latestDate).month()
        ? dates
        : dates /
            (moment(newestDate).month() -
              moment(latestDate).month() +
              (moment(newestDate).year() === moment(latestDate).year()
                ? 1
                : 13))
    } else return totalBookingsPriceForLastYear / 12
  }

  return (
    <>
      {!isMobile ? (
        <div className="max-h-full w-full flex flex-col flex-1">
          {isLoading ? (
            <div className="min-h-[500px]">
              <LoaderTW isComponent />
            </div>
          ) : (
            <EventBodyWrapper>
              <div className="flex flex-col w-full h-full">
                <div className="flex flex-col m-4">
                  <div className="flex flex-1 gap-3 pb-3 mb-5  border-b border-zoyya-outline">
                    <div className="flex flex-col gap-2 items-center">
                      <div className="h-[95px] w-[95px] relative">
                        <img
                          className="w-full h-full rounded-lg object-cover"
                          src={
                            initialValues.client?.defaultAvatarUrl &&
                            !initialValues.client?.defaultAvatarUrl?.endsWith(
                              'null'
                            )
                              ? initialValues.client?.defaultAvatarUrl
                              : '/assets/default-avatar.svg'
                          }
                        />

                        {initialValues.client?.userId ? (
                          <div className="absolute right-[0] bottom-[0] w-fit border-[0.5px] border-[#c1c1c1] rounded-[7px]">
                            <img src={'/zoyyaLetter.png'} className="h-7 w-7" />
                          </div>
                        ) : null}
                      </div>
                      <div>{getRatingStars(reviewNumber)}</div>
                      {initialValues.client?.status === 'BLOCKED' ? (
                        <div className="bg-zoyya-accent5 pt-px px-3 text-sm rounded-sm uppercase text-white w-fit">
                          <p>{t('translation.ClientsList.label-blocked')}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-1 flex-col">
                      <div className="flex justify-between w-full gap-3">
                        <span className="text-[20px] font-semibold line-clamp-1">
                          {resolveFullName(initialValues.client, false)}
                        </span>

                        {canEditAppointment ? (
                          <div className="flex gap-2">
                            <ButtonTW
                              variant={'secondary'}
                              size="small"
                              label={t('translation.Client.izmjeni')}
                              icon={<icons.Edit size={'smaller'} />}
                              onClick={onEdit}
                            />
                            {!(
                              initialValues?.client?.user?.id ||
                              initialValues?.client?.userId
                            ) && initialValues?.client?.email ? (
                              <div>
                                <ButtonTW
                                  variant="primaryOutline"
                                  label={t(
                                    'translation.ClientsList.tooltip-sendInvitation'
                                  )}
                                  name="sendInvite"
                                  size="small"
                                  icon={<icons.SendEmail size={'smaller'} />}
                                  onClick={event => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    showPopup({
                                      onConfirm: () =>
                                        sendInvitation(initialValues?.client),
                                      titleText: t(
                                        'translation.ClientsList.title-confirmSend',
                                        {
                                          firstName:
                                            initialValues?.client?.firstName,
                                          lastName:
                                            initialValues?.client?.lastName,
                                        }
                                      ),
                                      contentText: t(
                                        'translation.ClientsList.label-sendInviteInfo'
                                      ),
                                    })
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      <div className="min-h-[70px]">
                        <div className="flex gap-2 items-center">
                          <icons.Email size="size14" />
                          {initialValues.client?.email ? (
                            <>
                              <a
                                className="text-sm relative top-px"
                                href={'mailto:' + initialValues?.client?.email}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {initialValues?.client?.email}
                              </a>

                              {initialValues?.client?.mailboxStatus ===
                              'DISABLED' ? (
                                <Tooltip2
                                  helpText={t(
                                    'translation.NewClientContainer.email-blocked'
                                  )}
                                >
                                  <WarningTriangle
                                    size="size14"
                                    color="orange"
                                  />
                                </Tooltip2>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                        <div className="flex gap-2 items-center">
                          <div>
                            <icons.PhoneBold size="size14" />
                          </div>
                          {initialValues?.client?.mobilePhone ? (
                            <a
                              className="text-sm relative top-px"
                              href={'tel:' + initialValues?.client?.mobilePhone}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {initialValues?.client?.mobilePhone}
                            </a>
                          ) : null}
                        </div>
                        <div className="flex gap-2 items-center">
                          <div>
                            <icons.Birthday size="size14" />
                          </div>
                          {initialValues?.client?.birthDate ? (
                            <span className="text-sm relative top-px">
                              {moment(initialValues?.client?.birthDate).format(
                                t('regionalFormats.date.momentFormatDate')
                              )}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <p className="line-clamp-3 text-sm text-zoyya-secondaryDark">
                        {initialValues?.client?.comment}
                      </p>
                    </div>
                  </div>
                  <div className="mt-auto flex items-center ml-2 mb-1">
                    <div className="flex gap-10">
                      <div className="flex flex-col">
                        <span className="font-medium text-lg">
                          {`${formatPriceNumberWithDelimiter(
                            totalBookingsPriceForLastYear
                          )} ${
                            initialValues?.currencyId
                              ? initialValues?.currencyId
                              : ''
                          }`}
                        </span>

                        <span className="text-sm text-[#555]">
                          {t('translation.ClientDetailsMobile.label-12-month')}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-medium  text-lg">
                          {`${formatPriceNumberWithDelimiter(
                            getMonthlyAverage()
                          )} ${
                            initialValues?.currencyId
                              ? initialValues?.currencyId
                              : ''
                          }`}
                        </span>
                        <span className="text-sm text-[#555]">
                          {t(
                            'translation.ClientDetailsMobile.label-monthly-average'
                          )}
                        </span>
                      </div>

                      <div className="flex flex-col border-l-1 border-zoyya-outline pl-10">
                        <span className="font-medium text-lg">
                          {sortedFutureEvents?.length +
                            sortedPastEvents?.length}
                        </span>
                        <span className="text-sm text-[#555]">
                          {t('translation.ClientDetailsMobile.total-bookings')}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-medium text-lg">
                          {`${clientNoShows?.length} (${noShows?.length})`}
                        </span>
                        <span className="text-sm text-[#555]">
                          {`${t('translation.ClientDetails.noShows')} ${t(
                            'translation.ClientDetailsMobile.canceled'
                          )}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col p-1.5 pb-0 bg-white w-full flex-1 overflow-hidden">
                  <div className="flex items-center mx-4 justify-between">
                    <div
                      className={`flex-1 h-full p-1.5 text-center cursor-pointer text-zoyya-secondaryDarker border-b-2 ${
                        filter === 'future'
                          ? 'border-zoyya-primary'
                          : 'border-zoyya-outline'
                      }`}
                      onClick={() => {
                        setFilter('future')
                        setIsInitialFilter(false)
                      }}
                    >
                      {`${t(
                        'translation.ClientDetails.futureAppointments'
                      )}: ${sortedFutureEvents?.length}`}
                    </div>
                    <div
                      className={`flex-1 h-full p-1.5 text-center cursor-pointer text-zoyya-secondaryDarker border-b-2 ${
                        filter === 'past'
                          ? 'border-zoyya-primary'
                          : 'border-zoyya-outline'
                      }`}
                      onClick={() => {
                        setFilter('past')
                        setIsInitialFilter(false)
                      }}
                    >
                      {`${t(
                        'translation.ClientDetails.pastAppointments'
                      )}: ${sortedPastEvents?.length}`}
                    </div>
                    <div
                      className={`flex-1 h-full p-1.5 text-center cursor-pointer border-b-2 ${
                        filter === 'missed'
                          ? 'border-zoyya-primary text-zoyya-accent5'
                          : 'border-zoyya-outline text-zoyya-secondaryDarker'
                      }`}
                      onClick={() => {
                        setFilter('missed')
                        setIsInitialFilter(false)
                      }}
                    >
                      {(showAllCanceled
                        ? t('translation.ClientDetailsMobile.canceled-dates')
                        : t('translation.ClientDetailsMobile.client-missed')) +
                        ': ' +
                        (showAllCanceled
                          ? noShows?.length
                          : clientNoShows?.length)}
                    </div>
                  </div>
                  {filter === 'missed' ? (
                    <Checkbox
                      style={{ margin: '10px' }}
                      label={t(
                        'translation.ClientDetailsMobile.show-only-missed'
                      )}
                      checked={!showAllCanceled}
                      handleChange={() => setShowAllCanceled(!showAllCanceled)}
                    />
                  ) : null}
                  <ClientListContainer>
                    {(filter === 'future' && !sortedFutureEvents.length) ||
                    (filter === 'past' && !sortedPastEvents.length) ||
                    (filter === 'missed' &&
                      showAllCanceled &&
                      !noShows.length) ||
                    (filter === 'missed' &&
                      !showAllCanceled &&
                      !clientNoShows.length) ? (
                      <EmptyList
                        imageStyle={{ width: 350 }}
                        title={
                          filter === 'future'
                            ? t(
                                'translation.RezervationsDesktop.title-no-future-appointments',
                                {
                                  clientName: resolveFullName(
                                    initialValues.client,
                                    false
                                  ),
                                }
                              )
                            : filter === 'past'
                            ? t(
                                'translation.RezervationsDesktop.title-no-past-appointments',
                                {
                                  clientName: resolveFullName(
                                    initialValues.client,
                                    false
                                  ),
                                }
                              )
                            : showAllCanceled
                            ? t(
                                'translation.RezervationsDesktop.title-no-canceled-appointments',
                                {
                                  clientName: resolveFullName(
                                    initialValues.client,
                                    false
                                  ),
                                }
                              )
                            : t(
                                'translation.RezervationsDesktop.title-no-missed-appointments',
                                {
                                  clientName: resolveFullName(
                                    initialValues.client,
                                    false
                                  ),
                                }
                              )
                        }
                      />
                    ) : (
                      <>
                        {filter === 'future'
                          ? sortedFutureEvents?.map((event, idx) => (
                              <AppointmentCard
                                key={idx}
                                id={event?.id}
                                notes={event?.notes}
                                status={event?.status}
                                date={event?.date}
                                startTime={event?.startTime}
                                endTime={event?.endTime}
                                reason={event?.reason}
                                reviewRating={event?.reviewRating}
                                reviewComment={event?.reviewComment}
                                isOnlineBooking={event?.isOnlineBooking}
                                services={event?.services}
                                totalAmount={event?.totalAmount}
                                currencyId={event?.currencyId}
                                isHotel={isHotel}
                              />
                            ))
                          : filter === 'past'
                          ? sortedPastEvents.map((event, idx) => (
                              <AppointmentCard
                                key={idx}
                                id={event?.id}
                                notes={event?.notes}
                                status={event?.status}
                                date={event?.date}
                                startTime={event?.startTime}
                                endTime={event?.endTime}
                                reason={event?.reason}
                                reviewRating={event?.reviewRating}
                                reviewComment={event?.reviewComment}
                                isOnlineBooking={event?.isOnlineBooking}
                                services={event?.services}
                                totalAmount={event?.totalAmount}
                                currencyId={event?.currencyId}
                                isHotel={isHotel}
                              />
                            ))
                          : filter === 'missed'
                          ? showAllCanceled
                            ? noShows.map((event, idx) => (
                                <AppointmentCard
                                  key={idx}
                                  id={event?.id}
                                  notes={event?.notes}
                                  status={event?.status}
                                  date={event?.date}
                                  startTime={event?.startTime}
                                  endTime={event?.endTime}
                                  reason={event?.reason}
                                  reviewRating={event?.reviewRating}
                                  reviewComment={event?.reviewComment}
                                  isOnlineBooking={event?.isOnlineBooking}
                                  services={event?.services}
                                  totalAmount={event?.totalAmount}
                                  currencyId={event?.currencyId}
                                  isHotel={isHotel}
                                />
                              ))
                            : clientNoShows.map((event, idx) => (
                                <AppointmentCard
                                  key={idx}
                                  id={event?.id}
                                  notes={event?.notes}
                                  status={event?.status}
                                  date={event?.date}
                                  startTime={event?.startTime}
                                  endTime={event?.endTime}
                                  reason={event?.reason}
                                  reviewRating={event?.reviewRating}
                                  reviewComment={event?.reviewComment}
                                  isOnlineBooking={event?.isOnlineBooking}
                                  services={event?.services}
                                  totalAmount={event?.totalAmount}
                                  currencyId={event?.currencyId}
                                  isHotel={isHotel}
                                />
                              ))
                          : null}
                      </>
                    )}
                  </ClientListContainer>
                </div>
              </div>
            </EventBodyWrapper>
          )}
        </div>
      ) : (
        <ClientDetailsMobile
          isAppointment
          onEdit={onEdit}
          values={initialValues.client}
          onReminders={mobile_onReminders}
        />
      )}
    </>
  )
}
