import React, { useState, useCallback } from 'react'

export const useTableHeightCalculator = (tableHeightOffset?: number) => {
  const [domNode, setDomNode] = useState<any>(null)

  const onRefChange = useCallback(node => {
    setDomNode(node)
  }, [])

  const height = domNode
    ? tableHeightOffset
      ? domNode?.clientHeight - tableHeightOffset
      : domNode?.clientHeight
    : 0
  return { tableContainerRef: onRefChange, tableHeight: height }
}
