import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FormContentContainer, FormField, Loader, ToolTip } from 'components'
import { FieldColumn, FieldRow } from '../Settings/styles'
import { useSdk } from 'sdk'
import transparentize from 'polished/lib/color/transparentize'
import { device } from '../../constants'
import { WarningTriangle } from '../../components/Icon/Icons/WarningTriangle'
import { ClientWarningRow, ClientWarningText } from './Appointment'

type TUpdateStatusModal = {
  appointmentStatus: string
  event: any
  setFieldValue: any
  smsBalance: number
  smsBalanceLoading: boolean
  isLoading?: boolean
}

export const UpdateStatusModal = (props: TUpdateStatusModal) => {
  const {
    appointmentStatus,
    event,
    setFieldValue,
    smsBalance,
    smsBalanceLoading,
    isLoading,
  } = props
  const { t } = useSdk()

  const statusOptions = [
    ...(appointmentStatus !== 'cancel'
      ? [
          {
            id: 'ORG_CANCELED',
            name: t('translation.UpdateStatusModal.salonCanceled'),
          },
        ]
      : []),
    ...(appointmentStatus !== 'cancelBooking'
      ? [
          {
            id: 'ORG_CANCELED',
            name: t('translation.UpdateStatusModal.salonCanceled'),
          },
        ]
      : []),
    ...(appointmentStatus !== 'cancelBooking' && !!event?.client
      ? [
          {
            id: 'CLIENT_CANCELED',
            name: t('translation.UpdateStatusModal.clientCanceled'),
          },

          {
            id: 'CLIENT_NO_SHOW',
            name: t('translation.UpdateStatusModal.clientNoShow'),
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (appointmentStatus === 'cancelBooking') {
      setFieldValue('cancelReason', statusOptions[0]?.id)
    } else if (appointmentStatus === 'cancel' && !event.client) {
      setFieldValue('cancelReason', statusOptions[0]?.id)
    }
  }, [appointmentStatus])

  useEffect(() => {
    if (!smsBalanceLoading && smsBalance <= 0) {
      setFieldValue('sendSms', false)
    }
  }, [smsBalance, smsBalanceLoading])

  const shouldShowRefundCheckbox =
    event?.posPaymentRequest &&
    event?.posPaymentRequest?.status === 'PAID' /*  &&
    moment().isAfter(event?.posPaymentRequest?.paymentRequestDueDate) */

  return (
    <>
      <Wrapper>
        {isLoading ? <Loader isComponent /> : null}
        <TitleRow status={appointmentStatus}>
          <EventDateTitle status={appointmentStatus}>
            {appointmentStatus === 'cancel'
              ? t('translation.UpdateStatusModal.canceledEvent')
              : appointmentStatus === 'cancelBooking'
              ? t('translation.UpdateStatusModal.pleaseConfirmCancellation')
              : appointmentStatus === 'confirmBooking'
              ? t('translation.UpdateStatusModal.bookingConfirmation')
              : t('translation.UpdateStatusModal.pleaseConfirmConclusion')}
          </EventDateTitle>
        </TitleRow>
      </Wrapper>

      <FormContentContainer>
        <EventContainer style={{ minHeight: 220 }}>
          {appointmentStatus === 'backToConfirm' &&
          event?.reason === 'CLIENT_NO_SHOW' ? (
            <FieldColumn>
              <ClientWarningRow>
                <ClientWarningText>
                  <WarningTriangle color={'orange'} />
                  <p>
                    {t(
                      'translation.UpdateStatusModal.label-confirm-status-change'
                    )}
                  </p>
                </ClientWarningText>
              </ClientWarningRow>
            </FieldColumn>
          ) : (
            <>
              {appointmentStatus === 'cancel' ||
              appointmentStatus === 'cancelBooking' ? (
                <FieldRow>
                  <FieldColumn>
                    <FormField.Select
                      isDisabled={appointmentStatus === 'cancelBooking'}
                      closeMenuOnSelect
                      parseValue={val => statusOptions?.find(e => e.id === val)}
                      formatValue={val => val?.id}
                      options={statusOptions}
                      label={t('translation.UpdateStatusModal.cancelReason')}
                      name={'cancelReason'}
                    />
                  </FieldColumn>
                </FieldRow>
              ) : null}
              <div className="flex flex-col lg:flex-row">
                {appointmentStatus !== 'confirm' && (
                  <>
                    <FieldRow style={{ flex: 1 }}>
                      {event?.client?.email ? (
                        <FieldColumn style={{ marginLeft: '-4px' }}>
                          <FormField.Checkbox
                            label={t(
                              'translation.UpdateStatusModal.sendClientEmail'
                            )}
                            name="sendEmail"
                            type={'checkbox'}
                            disabled={!event?.client}
                            helpText={t(
                              'translation.UpdateStatusModal.sendEmailHint'
                            )}
                          />
                        </FieldColumn>
                      ) : event?.client ? (
                        <FieldColumn>
                          <ClientWarningRow>
                            <ClientWarningText>
                              <WarningTriangle color={'orange'} />
                              <p>
                                {t(
                                  'translation.AppointmentModal.client-email-missing-warning'
                                )}
                              </p>
                            </ClientWarningText>
                          </ClientWarningRow>
                        </FieldColumn>
                      ) : null}
                    </FieldRow>

                    {!smsBalanceLoading &&
                      event?.client?.mobilePhone /* &&
                event?.cancelReason !== 'CLIENT_NO_SHOW' &&
                appointmentStatus === 'cancel' */ && (
                        <FieldRow style={{ flex: 1 }}>
                          <FieldColumn style={{ marginLeft: '-4px' }}>
                            {smsBalance > 0 ? (
                              <FormField.Checkbox
                                label={t(
                                  'translation.UpdateStatusModal.sendClientSms'
                                )}
                                name="sendSms"
                                type={'checkbox'}
                                disabled={smsBalance <= 0}
                                helpText={t(
                                  'translation.UpdateStatusModal.sendSmsHint'
                                )}
                              />
                            ) : (
                              <ToolTip
                                label={t(
                                  'translation.Appointment.no-sms-available'
                                )}
                              >
                                <FormField.Checkbox
                                  label={t(
                                    'translation.UpdateStatusModal.sendClientSms'
                                  )}
                                  name="sendSms"
                                  type={'checkbox'}
                                  disabled={smsBalance <= 0}
                                  helpText={t(
                                    'translation.UpdateStatusModal.sendSmsHint'
                                  )}
                                />
                              </ToolTip>
                            )}
                          </FieldColumn>
                        </FieldRow>
                      )}
                  </>
                )}
              </div>
              <div className="flex flex-col lg:flex-row">
                {appointmentStatus === 'confirm' && (
                  <FieldRow style={{ flex: 1 }}>
                    {event?.client?.email ? (
                      <FieldColumn style={{ marginLeft: '-4px' }}>
                        <FormField.Checkbox
                          label={t(
                            'translation.UpdateStatusModal.sendReviewRequestClientEmail'
                          )}
                          name="sendReviewRequestEmail"
                          type={'checkbox'}
                          helpText={t(
                            'translation.UpdateStatusModal.sendReviewEmailHint'
                          )}
                        />
                      </FieldColumn>
                    ) : event?.client ? (
                      <FieldColumn>
                        <ClientWarningRow>
                          <ClientWarningText>
                            <WarningTriangle color={'orange'} />
                            <p>
                              {t(
                                'translation.AppointmentModal.client-email-missing-warning'
                              )}
                            </p>
                          </ClientWarningText>
                        </ClientWarningRow>
                      </FieldColumn>
                    ) : null}
                  </FieldRow>
                )}

                {/* Add validation if organization has sms messages */}
                {appointmentStatus === 'confirm' &&
                  event?.client?.mobilePhone &&
                  !smsBalanceLoading && (
                    <FieldRow style={{ flex: 1 }}>
                      <FieldColumn style={{ marginLeft: '-4px' }}>
                        {smsBalance > 0 ? (
                          <FormField.Checkbox
                            label={t(
                              'translation.UpdateStatusModal.sendReviewRequestClientSms'
                            )}
                            name="sendReviewRequestSms"
                            disabled={smsBalance <= 0}
                            type={'checkbox'}
                            helpText={t(
                              'translation.UpdateStatusModal.sendReviewSmsHint'
                            )}
                          />
                        ) : (
                          <ToolTip
                            label={t(
                              'translation.Appointment.no-sms-available'
                            )}
                          >
                            <FormField.Checkbox
                              label={t(
                                'translation.UpdateStatusModal.sendReviewRequestClientSms'
                              )}
                              name="sendReviewRequestSms"
                              disabled={smsBalance <= 0}
                              type={'checkbox'}
                              helpText={t(
                                'translation.UpdateStatusModal.sendReviewSmsHint'
                              )}
                            />
                          </ToolTip>
                        )}
                      </FieldColumn>
                    </FieldRow>
                  )}
              </div>
              {(appointmentStatus === 'cancel' ||
                appointmentStatus === 'cancelBooking') &&
              shouldShowRefundCheckbox ? (
                <FieldRow>
                  <FieldColumn style={{ marginLeft: '-4px' }}>
                    <FormField.Checkbox
                      helpText={t(
                        'translation.UpdateStatusModal.refund-checkbox-help'
                      )}
                      label={t(
                        'translation.UpdateStatusModal.refund-checkbox-label'
                      )}
                      type={'checkbox'}
                      name={'shouldRefundPaymentRequest'}
                    />
                  </FieldColumn>
                </FieldRow>
              ) : null}
              {event?.sendEmail ? (
                <FieldRow>
                  <FieldColumn>
                    <FormField.TextArea
                      label={
                        !!event?.client
                          ? t(
                              'translation.UpdateStatusModal.clientEmailMessage'
                            )
                          : t('translation.AppointmentModal.label-comment')
                      }
                      name="cancelNotes"
                      rows={2}
                      helpText={
                        !!event?.client
                          ? t('translation.UpdateStatusModal.noteLabel')
                          : ''
                      }
                    />
                  </FieldColumn>
                </FieldRow>
              ) : null}
            </>
          )}
        </EventContainer>
      </FormContentContainer>
    </>
  )
}

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 15px 25px 5px 25px;
  background: ${props => props.theme.colors.lightBackground};

  @media ${device.tablet} {
    flex-direction: column;
    padding: 15px 15px 5px 15px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.span`
  color: #333;
  font-family: Poppins, SFProDisplay, Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
  margin-right: 10px;
  min-width: 70px;
`

export const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
  max-width: 255px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type TStatusLabelProps = {
  status: string
}
export const StatusLabel = styled.div<TStatusLabelProps>`
  background: ${props =>
    props.status === 'cancel' || props.status === 'cancelBooking'
      ? transparentize(0.2, props.theme.colors.accent5)
      : transparentize(0.2, props.theme.colors.primary)};
  color: ${props => props.theme.colors.light};
  padding: 5px 10px;
  border-radius: 4px;
`

type TTitleRowProps = {
  status: string
}
export const TitleRow = styled.div<TTitleRowProps>`
  padding: 11px 25px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  display: flex;
  justify-content: space-between;
  background: ${props =>
    props.status === 'cancel' || props.status === 'cancelBooking'
      ? props.theme.colors.accent1
      : props.status === 'finish'
      ? props.theme.colors.primary
      : props.status === 'confirm'
      ? props.theme.colors.accent2
      : props.theme.colors.light};

  @media ${device.tablet} {
    padding: 10px;
  }
`
export const EventContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }

  @media ${device.tablet} {
    max-height: 100%;
    overflow: hidden;
    padding: 10px 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TEventDateTitleProps = {
  status: string
}
export const EventDateTitle = styled.div<TEventDateTitleProps>`
  font-size: 22px;
  cursor: pointer;
  color: ${props =>
    props.status === 'cancel' ||
    props.status === 'cancelBooking' ||
    props.status === 'finish' ||
    props.status === 'confirm'
      ? props.theme.colors.light
      : props.theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`
