import React, { useContext, useRef, useState } from 'react'
import {
  Container,
  FormContainer,
  FormFooterButtonsContainerMobile,
  FormPageContainer,
  TabContainer,
} from 'src/styles'
import { Button, Loader, Tab, TabView } from 'components'
import { icons } from 'components/Icon'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { MobileContext } from 'src/Context'
import { useHeader } from 'mobile/Header'
import {
  Form,
  FormField,
  FormFieldsWrapper,
  FormFooterButtonsContainer,
} from 'components/Form/Form'
import {
  useGetSmsBalanceQuery,
  useLocationGetQuery,
  useLocationUpdateMutation,
} from 'state/graphql'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import {
  ErrorWrapper,
  ErrorText,
} from 'views/Settings/NotificationPreferences/NotificationPreferences'
import { PlanUpgradeField } from 'components/PlanUpgradeField/PlanUpgradeField'
import styled from 'styled-components'
import { locationGetGql } from 'src/state/gql'

const LocationNotifications = ({ hasOnlineBooking, setOpenBillingModal }) => {
  const { locationId } = useParams<any>()
  const params = useParams<any>()
  const { navigateTo, t, appServices } = useSdk()
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  const { data: locationData, loading: loadingLocationData } =
    useLocationGetQuery({
      variables: { locationId },
      fetchPolicy: 'network-only',
    })
  const location = locationData?.location?.get
  const [updateLocationMutation, { loading }] = useLocationUpdateMutation()

  const { data: smsBalanceData, loading: smsBalanceLoading } =
    useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })
  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  useHeader({
    content: null,
  })
  const isMobile = useContext(MobileContext)

  const handleOnSubmit = async values => {
    const result = await updateLocationMutation({
      variables: {
        input: {
          id: locationId,
          notifications_smsReminder: values?.smsNotifications,
          notifications_emailReminder: values?.emailNotifications,
          notifications_emailReviewRequest: values?.emailReviewRequest,
          notifications_smsReviewRequest: values?.smsReviewRequest,
          sendAppointmentEmailsToClients:
            values?.sendAppointmentEmailsToClients,
          sendAppointmentSmsToClients: values?.sendAppointmentSmsToClients,
          notifications_b2b_sendAppointmentEmails:
            values?.notifications_b2b_sendAppointmentEmails,
        },
      },
      refetchQueries: [
        {
          query: locationGetGql,
          variables: {
            id: locationId,
          },
        },
      ],
    })

    appServices
      .handleMutationResult(
        result,
        t('translation.NotificationPreferences.emailSettingsToast')
      )
      .onSuccess(async () => {
        isMobile && navigateTo.mobileBranchSettings(params)
      })
  }
  let formRef = useRef<any>()
  return (
    <>
      {loadingLocationData || loading ? <Loader isComponent /> : null}

      <Container style={{ padding: isMobile ? 0 : undefined }}>
        <TabContainer>
          <TabView
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            isSingleTab={false}
            hasFooter={false}
          >
            <Tab
              hasFooter={false}
              isFullHeight
              title={t('translation.Notifications.for-clients-title')}
            >
              {!loadingLocationData ? (
                <FormPageContainer>
                  <Form
                    initialValues={{
                      emailNotifications: location?.notifications_emailReminder,
                      smsNotifications: location?.notifications_smsReminder,
                      emailReviewRequest:
                        location?.notifications_emailReviewRequest,
                      notifications_b2b_sendAppointmentEmails:
                        location?.notifications_b2b_sendAppointmentEmails,
                      smsReviewRequest:
                        location?.notifications_smsReviewRequest,
                      sendAppointmentEmailsToClients:
                        location?.sendAppointmentEmailsToClients,
                      sendAppointmentSmsToClients:
                        location?.sendAppointmentSmsToClients,
                      reviewRequestText: t(
                        'translation.Notifications.reviewRequestTextExample',
                        {
                          firstName: 'Ana',
                          locationName: location?.name,
                          reviewLink: 'https://eel.nu/_g8pJSa/_F4Jzp',
                        }
                      ),
                    }}
                    onSubmit={values => handleOnSubmit(values)}
                  >
                    {form => {
                      formRef.current = form
                      return (
                        <FormFieldsWrapper>
                          <FormContainer style={{ paddingTop: 0 }}>
                            <SettingsSubHeader style={{ marginBottom: 20 }}>
                              {t('translation.Notifications.title')}
                            </SettingsSubHeader>

                            <FieldRow>
                              <FieldColumn>
                                <FormField.Checkbox
                                  name="emailNotifications"
                                  type="checkbox"
                                  label={t(
                                    'translation.Notifications.email-notification'
                                  )}
                                  labelAlignment={'horizontal'}
                                  helpText={t(
                                    'translation.Notifications.email-notifications-hint'
                                  )}
                                />
                              </FieldColumn>
                            </FieldRow>
                            {!form.values.emailNotifications ? (
                              <ErrorWrapper>
                                <icons.Error
                                  color={'#fe9060'}
                                  className="shrink-0"
                                />
                                <ErrorText>
                                  {t(
                                    'translation.Notifications.email-notification-warning'
                                  )}
                                </ErrorText>
                              </ErrorWrapper>
                            ) : null}
                            <FieldRow>
                              <FieldColumn>
                                {hasOnlineBooking ? (
                                  <FormField.Checkbox
                                    name="smsNotifications"
                                    type="checkbox"
                                    label={t(
                                      'translation.Notifications.sms-notification'
                                    )}
                                    labelAlignment={'horizontal'}
                                    helpText={t(
                                      'translation.Notifications.sms-notifications-hint'
                                    )}
                                  />
                                ) : (
                                  <div style={{ minWidth: 219, marginTop: 20 }}>
                                    <PlanUpgradeField
                                      isAvailableOnAdvancedPlan
                                      title={t(
                                        'translation.Notifications.sms-notification'
                                      )}
                                      text={t(
                                        'translation.UpgradePage.button-label-business-plan'
                                      )}
                                      handleClick={() =>
                                        setOpenBillingModal(true)
                                      }
                                    />
                                  </div>
                                )}
                              </FieldColumn>
                            </FieldRow>
                            {hasOnlineBooking &&
                            !form.values.smsNotifications ? (
                              <ErrorWrapper>
                                <icons.Error
                                  color={'#fe9060'}
                                  className="shrink-0"
                                />
                                <ErrorText>
                                  {t(
                                    'translation.Notifications.sms-notification-warning'
                                  )}
                                </ErrorText>
                              </ErrorWrapper>
                            ) : null}
                            <SettingsSubHeader style={{ marginBottom: 20 }}>
                              {t(
                                'translation.Notifications.title-notifications'
                              )}
                            </SettingsSubHeader>

                            <FieldRow>
                              <FieldColumn>
                                <FormField.Checkbox
                                  name="sendAppointmentEmailsToClients"
                                  type="checkbox"
                                  label={t(
                                    'translation.Notifications.email-notifications'
                                  )}
                                  labelAlignment={'horizontal'}
                                  helpText={t(
                                    'translation.Notifications.email-appointment-notifications-hint'
                                  )}
                                />
                              </FieldColumn>
                            </FieldRow>
                            <FieldRow>
                              <FieldColumn>
                                <FormField.Checkbox
                                  name="sendAppointmentSmsToClients"
                                  type="checkbox"
                                  label={t(
                                    'translation.Notifications.sms-notifications'
                                  )}
                                  labelAlignment={'horizontal'}
                                  helpText={t(
                                    'translation.Notifications.sms-appointment-notifications-hint'
                                  )}
                                />
                              </FieldColumn>
                            </FieldRow>

                            <SettingsSubHeader style={{ marginBottom: 20 }}>
                              {t(
                                'translation.Notifications.title-review-request'
                              )}
                            </SettingsSubHeader>

                            <FieldRow>
                              <FieldColumn>
                                <FormField.Checkbox
                                  name="emailReviewRequest"
                                  type="checkbox"
                                  label={t(
                                    'translation.Notifications.email-review-request'
                                  )}
                                  labelAlignment={'horizontal'}
                                  helpText={t(
                                    'translation.Notifications.email-review-requests-hint'
                                  )}
                                />
                              </FieldColumn>
                            </FieldRow>
                            {!smsBalanceLoading && smsBalance > 0 ? (
                              <FieldRow>
                                <FieldColumn>
                                  <FormField.Checkbox
                                    name="smsReviewRequest"
                                    type="checkbox"
                                    label={t(
                                      'translation.Notifications.sms-review-request'
                                    )}
                                    labelAlignment={'horizontal'}
                                    helpText={t(
                                      'translation.Notifications.sms-review-requests-hint'
                                    )}
                                  />
                                </FieldColumn>
                              </FieldRow>
                            ) : null}
                            <FieldRow
                              style={{
                                width: !isMobile ? '40%' : '100%',
                                marginTop: 20,
                              }}
                            >
                              <FieldColumn>
                                <FormField.TextArea
                                  label={t(
                                    'translation.Notifications.label-reviewRequestText'
                                  )}
                                  name="reviewRequestText"
                                  style={{ height: '120px' }}
                                  disabled
                                  helpText={t(
                                    'translation.Notifications.help-reviewRequestText'
                                  )}
                                />
                              </FieldColumn>
                            </FieldRow>

                            {!isMobile && (
                              <FormFooterButtonsContainer>
                                <Button
                                  type={'submit'}
                                  buttonType={'primary'}
                                  name="saveChanges"
                                  data-cy="button_saveChanges"
                                  label={t(
                                    'translation.Notifications.button-save'
                                  )}
                                />
                              </FormFooterButtonsContainer>
                            )}
                          </FormContainer>
                        </FormFieldsWrapper>
                      )
                    }}
                  </Form>
                </FormPageContainer>
              ) : null}
            </Tab>
            <Tab
              hasFooter={false}
              title={t('translation.Notifications.for-location-title')}
              className="h-full"
            >
              {!loadingLocationData ? (
                <FormPageContainer>
                  <Form
                    initialValues={{
                      emailNotifications: location?.notifications_emailReminder,
                      notifications_b2b_sendAppointmentEmails:
                        location?.notifications_b2b_sendAppointmentEmails,
                      smsNotifications: location?.notifications_smsReminder,
                      emailReviewRequest:
                        location?.notifications_emailReviewRequest,
                      smsReviewRequest:
                        location?.notifications_smsReviewRequest,
                      sendAppointmentEmailsToClients:
                        location?.sendAppointmentEmailsToClients,
                      sendAppointmentSmsToClients:
                        location?.sendAppointmentSmsToClients,
                      reviewRequestText: t(
                        'translation.Notifications.reviewRequestTextExample',
                        {
                          firstName: 'Ana',
                          locationName: location?.name,
                          reviewLink: 'https://eel.nu/_g8pJSa/_F4Jzp',
                        }
                      ),
                    }}
                    onSubmit={values => handleOnSubmit(values)}
                  >
                    {form => {
                      formRef.current = form
                      return (
                        <FormFieldsWrapper>
                          <FormContainer style={{ paddingTop: 0 }}>
                            <SettingsSubHeader style={{ marginBottom: 20 }}>
                              {t(
                                'translation.Notifications.email-notifications-subtitle'
                              )}
                            </SettingsSubHeader>

                            <FieldRow>
                              <FieldColumn>
                                <FormField.Checkbox
                                  name="notifications_b2b_sendAppointmentEmails"
                                  type="checkbox"
                                  label={t(
                                    'translation.Notifications.email-notifications-subtitle'
                                  )}
                                  labelAlignment={'horizontal'}
                                  helpText={t(
                                    'translation.Notifications.email-appointment-b2b-notifications-hint'
                                  )}
                                />
                              </FieldColumn>
                            </FieldRow>

                            {/* <ErrorWrapper>
                              <icons.Error
                                color={'#fe9060'}
                                className="shrink-0"
                              />
                              <ErrorText>
                                {t(
                                  'translation.Notifications.sms-notification-warning'
                                )}
                              </ErrorText>
                            </ErrorWrapper> */}

                            {!isMobile && (
                              <FormFooterButtonsContainer>
                                <Button
                                  type={'submit'}
                                  buttonType={'primary'}
                                  name="saveChanges"
                                  data-cy="button_saveChanges"
                                  label={t(
                                    'translation.Notifications.button-save'
                                  )}
                                />
                              </FormFooterButtonsContainer>
                            )}
                          </FormContainer>
                        </FormFieldsWrapper>
                      )
                    }}
                  </Form>
                </FormPageContainer>
              ) : null}
            </Tab>
          </TabView>
        </TabContainer>
      </Container>

      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            onClick={navigateTo.mobileBranchSettings}
            buttonType={'text'}
            footerButton
            name="close"
            data-cy="button_closeForm"
            label={t('translation.Notifications.button-close')}
          />
          <Button
            onClick={() => formRef.current.submitForm()}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}

const SettingsSubHeader = styled.div`
  display: flex;
  margin-top: 5px;
  width: 100%;
  border-bottom: 1px solid #edeef4;
  font-size: 20px;
  font-weight: 600;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  max-height: 57px;
  min-height: 57px;
`

export default LocationNotifications
