import {
  Button,
  FormContentContainer,
  FormFooterContainer,
  Loader,
} from 'components'
import { ChangelogUpgradePage } from 'components/UpgradePage/UpgradePage'
import React from 'react'
import { useSdk } from 'sdk'
import { ChangelogItem } from 'src/components/ChangelogItem/ChangelogItem'
import { device } from 'src/constants'
import { useACL } from 'src/sdk/acl'
import { ModalBodyContainer } from 'src/styles'
import { trpc } from 'src/trpc'
import { useAppointmentGetChangelogQuery } from 'state/graphql'
import styled from 'styled-components'
import { EventBodyWrapper } from 'views/AppointmentModal/styles'

export const AppointmentChangelog = ({
  closeModal,
  isActiveTab,
  org,
  booking,
  onSaveForm,
  onCloseForm,
}) => {
  const { t, isMobile } = useSdk()
  const { hasPermission } = useACL()
  const { data: changelogData, loading: changelogLoading } =
    useAppointmentGetChangelogQuery({
      variables: {
        resourceBookingId: BigInt(booking?.id),
      },
      fetchPolicy: 'network-only',
      skip: org?.subscriptionPlan !== 'advanced',
    })

  const { data: orgRoles } = trpc.getOrgRoles.useQuery()

  const logs = changelogData?.appointment?.getChangelog

  if (org?.subscriptionPlan !== 'advanced') {
    return <ChangelogUpgradePage isAppointmentModal closeModal={closeModal} />
  }

  return (
    <ModalBodyContainer>
      {changelogLoading ? (
        <div style={{ minHeight: '500px' }}>
          <Loader isComponent />
        </div>
      ) : (
        <EventBodyWrapper>
          <ChangeLogFormContentContainer isAppointmentModal>
            {logs?.map(log => {
              const logMeta = JSON.parse(log?.meta || '{}')
              return (
                <ChangelogItem
                  log={{
                    ...log,
                    meta: logMeta,
                    user: log?.user,
                    event: log?.event,
                  }}
                  orgRoles={orgRoles || []}
                  hideGoToCalendarButton
                />
              )
            })}
          </ChangeLogFormContentContainer>
        </EventBodyWrapper>
      )}
      {isMobile ? (
        <FormFooterContainer
          hasShadow
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: 9999999,
          }}
        >
          <Button
            buttonType={isMobile ? 'secondary' : 'text'}
            name={'close'}
            label={t('translation.AppointmentModal.button-close')}
            footerButton
            type="button"
            onClick={onCloseForm}
          />

          {hasPermission('EDIT_APPOINTMENT') ? (
            <Button
              onClick={onSaveForm}
              buttonType={'primary'}
              name={'saveChanges'}
              label={t('translation.AppointmentModal.button-save')}
            />
          ) : null}
        </FormFooterContainer>
      ) : null}
    </ModalBodyContainer>
  )
}

const ChangeLogFormContentContainer = styled(FormContentContainer)`
  padding: 10px;
  @media ${device.tablet} {
    padding-bottom: 85px !important;
  }
`
