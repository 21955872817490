import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Changelog = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.5 9.5v0a1 1 0 01-1 1h-8 0a1 1 0 01-1-1s0 0 0 0v-8h0a1 1 0 011-1h8a1 1 0 011 1zM10.5 22.5v0a1 1 0 01-1 1h-8 0a1 1 0 01-1-1s0 0 0 0v-8h0a1 1 0 011-1h8a1 1 0 011 1zM13.5 2.5h10M13.5 5.5h10M13.5 8.5h10M13.5 15.5h10M13.5 18.5h10M13.5 21.5h10"></path>
      </g>
    </StyledIcon>
  )
}
