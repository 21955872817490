import React from 'react'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { Form, FormField, FormFooterContainer, Loader } from 'components'
import * as Yup from 'yup'
import { useSdk } from 'sdk'
import { useCountryAllQuery, useGetOrganizationByIdQuery } from 'state/graphql'
import { useParams } from 'react-router'
import Button from 'components/Button/Button'
import styled from 'styled-components'
import {
  fontFamily,
  fontSize,
  fontWeight,
  fontWeightMedium,
  lineHeight,
} from 'components/style/fonts'
import { device } from '../../../../constants'
import { icons } from 'components'

const ValidationSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('translation.Signup.validation-invalid-email'))
      .required(t('translation.Signup.validation-email-required')),
    orgName: Yup.string().required(
      t('translation.OrgDataForm.org-name-is-required')
    ),
  })

export const OrgDataForm = props => {
  const { updateOrg, isBusy, closeModal, setStep } = props
  const { t } = useSdk()
  const { data: countriesData } = useCountryAllQuery()

  const { orgId } = useParams<{ [key: string]: string }>()
  const {
    data: organizationData,
    loading: organizationDataLoading,
  } = useGetOrganizationByIdQuery({
    variables: { id: orgId },
  })
  const org = organizationData?.organization?.get

  return (
    <>
      {organizationDataLoading || isBusy ? (
        <Loader isComponent />
      ) : (
        <Form
          onSubmit={values => updateOrg(values)}
          initialValues={org || {}}
          validationSchema={() => ValidationSchema(t)}
          isInitialValid={false}
        >
          <ModalCOntainer data-cy={'orgForm'}>
            <ModalLeft>
              <div style={{ padding: '0px 30px 20px 30px' }}>
                <FieldRow>
                  <FieldColumn>
                    <FormField.Text
                      label={t(
                        'translation.OrganizationDesktop.label-company-name'
                      )}
                      name="orgName"
                      autoFocus
                    />
                  </FieldColumn>
                </FieldRow>
                <FieldRow>
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.OrganizationDesktop.label-address')}
                      name="address"
                    />
                  </FieldColumn>
                </FieldRow>
                <FieldRow>
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.OrganizationDesktop.label-zipcode')}
                      name="zipCode"
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.OrganizationDesktop.label-city')}
                      name="city"
                    />
                  </FieldColumn>
                </FieldRow>
                <FieldRow>
                  <FieldColumn>
                    <FormField.Select
                      label={t('translation.OrganizationDesktop.label-country')}
                      name="country"
                      formatOptionLabel={e => e.countryName}
                      getOptionLabel={e => e.countryName}
                      options={countriesData?.country?.all || []}
                    />
                  </FieldColumn>
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.OrganizationDesktop.label-vatid')}
                      name="vatId"
                    />
                  </FieldColumn>
                </FieldRow>

                <FieldRow>
                  <FieldColumn>
                    <FormField.Text
                      label={t('translation.OrganizationDesktop.label-email')}
                      name="email"
                    />
                  </FieldColumn>
                </FieldRow>
              </div>
            </ModalLeft>
          </ModalCOntainer>
          <FormFooterContainer
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Button
                buttonType={'link'}
                type="button"
                name={'back'}
                label={t('translation.OrgDataForm.natrag')}
                iconComponent={<icons.ArrowLeft />}
                footerButton
                onClick={e => {
                  setStep(1)
                  e.preventDefault()
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                buttonType={'text'}
                type="button"
                name={'close'}
                label={t('translation.OrgDataForm.close')}
                footerButton
                onClick={e => {
                  e.preventDefault()
                  closeModal()
                }}
              />
              <Button
                buttonType={'primary'}
                type={'submit'}
                name={'saveChanges'}
                iconComponent={<icons.ArrowRight />}
                iconRight
                label={t('translation.OrgDataForm.next')}
              />
            </div>
          </FormFooterContainer>
        </Form>
      )}
    </>
  )
}

export const StyledInput = styled.div`
  font-size: 14px;
  height: 45px;
`

export const Label = styled.label<{ textLight?: boolean }>`
  color: ${props => props.theme.colors.text};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${props => (props.textLight ? fontWeight : fontWeightMedium)};
  line-height: ${lineHeight};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
`

export const ModalLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    ${FieldColumn} {
      :not(last-child) {
        padding-right: 0;
      }
    }
  }
`

export const ModalLeftTitle = styled.span`
  color: ${props => props.theme.colors.secondaryDark};
  margin-bottom: 10px;
`

export const ModalLeftText = styled.span`
  padding-bottom: 5px;
  font-size: 16px;
`

export const PlanTitle = styled.div`
  background: #e7e7e7;
  padding: 10px 20px;
  font-weight: 600;
  margin-bottom: 15px;
`

export const ModalCOntainer = styled.div`
  display: flex;
  padding: 0px 20px 0px 0px;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0px;
  }
`

export const ModalRight = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.outline};
  flex: 1;
  background: ${props => props.theme.colors.light};
  border-radius: 6px;
  max-width: 300px;
  margin: 30px 0px;

  @media ${device.tablet} {
    margin: 0px 30px 20px;
    max-width: 100%;
  }
`

export const ModalRightTitle = styled.span<{ hasUpperMargin?: boolean }>`
  font-size: 22px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  position: relative;
  padding: 10px 20px;
  margin-top: ${props => (props.hasUpperMargin ? 'auto' : 'initial')};
  border-top: 1px solid ${props => props.theme.colors.outline};
  span {
    position: absolute;
    margin-left: 5px;
    font-size: 14px;
    margin-top: 3px;
  }
`

export const ModalRightWarning = styled.div<{ hasBottomMargin?: boolean }>`
  background: #fff4d4;
  border: 1px solid #ffbd00;
  color: #bb8a00;
  border-radius: 4px;
  padding: 10px;
  /* width: 100%; */
  margin: 10px 20px;
  font-size: 12px;
  margin-bottom: ${props => (props.hasBottomMargin ? '25px' : '10px')};
`

export const ModalRightRow = styled.div<{ isDowngrade?: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  span {
    margin-left: 10px;
    text-decoration: ${props =>
      props.isDowngrade ? 'line-through' : 'initial'};
  }
`
export const ModalRightCheck = styled.div``

export const CardFields = styled.div`
  flex: 1.5;
  padding: 0px 30px 40px 30px;
`

export const BottomSection = styled.div`
  display: flex;
  margin-top: 25px;
`

export const BillingText = styled.div`
  display: inline;
`

export const BillingTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: auto;

  span {
    color: ${props => props.theme.colors.secondaryDark};
    font-size: 10px;
    text-transform: uppercase;
  }
`
