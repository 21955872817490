import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { device } from '../constants'
import { icons } from 'components'
import { useHistory } from 'react-router-dom'
import { Menu } from './Menu'
export const HeaderContext = React.createContext<THeader | undefined>(undefined)
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0px 15px 0px;
  height: 55px;
  min-height: 55px;
  border-bottom: ${props =>
    `1px solid ${darken(0.4, props.theme.colors.primary)}`};
  top: 0px;
  position: sticky;
  background: ${props => props.theme.colors.primary};
  z-index: 99;
  display: none;

  button {
    :first-child {
      padding-left: 20px;
      margin-right: auto;
    }

    :last-child {
      padding-right: 20px;
      margin-left: auto;
    }
    span:before {
      color: ${props => props.theme.colors.light};
    }
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    //justify-content: end;
    justify-content: center;
    position: fixed;
  }
`
const StyledBackButton = styled.div`
  margin-left: 20px;
`

export const MobileHeader = props => {
  const { children, showBackButton, backRoute, onGoBack } = props
  const history = useHistory()
  const handleBackButtonClick = () => {
    if (onGoBack) {
      onGoBack()
      return
    }
    backRoute ? history.push(backRoute) : history.goBack()
  }
  return (
    <HeaderWrapper>
      {showBackButton ? (
        <StyledBackButton onClick={handleBackButtonClick}>
          <icons.ArrowLeft style={{ color: 'white' }} />
        </StyledBackButton>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',

          alignItems: 'center',
        }}
      >
        {children}
      </div>
      <div style={{ width: showBackButton ? 30 : 0 }}></div>
    </HeaderWrapper>
  )
}
type THeader = {
  setOptions: (options: THeaderOptions) => any
  getOptions: () => THeaderOptions
}

const StyledClientMobileHeader = styled.div`
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0px 15px 0px;
  height: 55px;
  min-height: 55px;
  top: 0px;
  left: 0px;
  position: sticky;
  z-index: 1000;
  background: ${props => props.theme.colors.primary};

  .bm-item-list {
    height: auto !important;
  }
`

export const ClientMobileHeader = props => {
  const history = useHistory()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const header = useContext(HeaderContext)
  const headerOptions = header?.getOptions()
  const handleBackButtonClick = () => {
    headerOptions?.onGoBack ? headerOptions.onGoBack() : history.goBack()
  }
  let headerContent
  if (typeof headerOptions?.content === 'string') {
    headerContent = (
      <span style={{ margin: 'auto' }}>{headerOptions?.content}</span>
    )
  } else {
    headerContent = headerOptions?.content
  }
  const Drawer = headerOptions?.drawer
  const DrawerButton = headerOptions?.drawerButton

  if (!headerContent) return null

  return (
    <StyledClientMobileHeader data-cy={'mobileHeader'}>
      {headerOptions?.onGoBack ? (
        <icons.ChevronLeft
          style={{
            color: 'white',
            position: 'absolute',
            left: '10px',
            width: '40px',
            height: '40px',
            padding: '10px',
            zIndex: 999999,
          }}
          data-cy={'mobileGoBack'}
          onClick={handleBackButtonClick}
        />
      ) : null}
      {typeof headerContent === 'function' ? headerContent() : headerContent}
      {DrawerButton ? DrawerButton : Drawer ? <Menu>{Drawer}</Menu> : null}
    </StyledClientMobileHeader>
  )
}

type THeaderOptions = {
  content: any
  drawer?: any
  drawerButton?: any
  hide?: boolean
  onGoBack?: (params?: any) => any
}
export const useHeader = (
  options?: THeaderOptions | (() => THeaderOptions),
  deps?: React.DependencyList
) => {
  const headerContext = useContext(HeaderContext)
  const contextRef = useRef(headerContext)
  const getOptionsCallback =
    typeof options === 'function' ? options : () => options
  const getOptions = useCallback(getOptionsCallback, deps || [])
  const hasOptions = !!options
  useEffect(() => {
    const hc = contextRef.current
    if (!hc) return
    if (!hasOptions) return
    const optionsResolved = getOptions()
    if (hc && optionsResolved) {
      hc.setOptions(optionsResolved)
    }
  }, [hasOptions, getOptions])

  return headerContext
}
