import React from 'react'
import PropTypes from 'prop-types'
import { SearchBarWrapper } from 'components/SearchBar/style'
import Input from 'components/Input/Input'
import { throttle } from 'lodash'
import SearchIcon from 'components/Icon/Icons/Search'
import Cross from 'components/Icon/Icons/Cross'

/** Search input that filters the Select items and adds keyboard navigation */
export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.fetchDataWithDebounce = throttle(props.onChange, 500, {
      leading: true,
      trailing: true,
    })
  }

  state = {
    search: this.props?.value || '',
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search && this.state.search === '') {
      this.props.onChange('')
    }
  }

  onChange = event => {
    const search = event.target.value

    this.fetchDataWithDebounce(search)
    this.setState({
      search,
    })
  }

  onBlur = () => {
    const { onChange } = this.props

    onChange('')
    this.setState({
      search: '',
    })
  }

  render() {
    const {
      placeholder,
      onClick,
      height,
      clearSearchOnBlur,
      isMobile,
      isHeader,
      clientsList,
      styleWrapper,
    } = this.props

    const { search } = this.state

    return (
      <SearchBarWrapper
        isMobile={isMobile}
        isHeader={isHeader}
        messageList={this.props.messageList}
        clientsList={clientsList}
        style={styleWrapper}
      >
        <Input
          name="searchBar"
          placeholder={placeholder}
          type="text"
          size={'small'}
          value={search}
          ref={inputRef => (this.inputRef = inputRef)}
          onChange={event => this.onChange(event)}
          onClick={onClick}
          clearSearchOnBlur={clearSearchOnBlur}
          onBlur={clearSearchOnBlur ? this.onBlur : undefined}
          height={height}
          icon={<SearchIcon />}
        />
        {search && (
          <div
            className="absolute right-[10px] top-[7px] text-zoyya-secondaryDark cursor-pointer max-md:top-[15px]"
            onClick={() =>
              this.setState({
                search: '',
              })
            }
          >
            <Cross size={'smaller'} />
          </div>
        )}
      </SearchBarWrapper>
    )
  }
}

SearchBar.propTypes = {
  /** Search placeholder */
  placeholder: PropTypes.string,

  /** Function to call on search input change */
  onChange: PropTypes.func.isRequired,

  /** Is the select menu open */
  isOpen: PropTypes.bool,

  /** Is onClick event */
  onClick: PropTypes.func,

  /** Input height */
  height: PropTypes.string,

  /** Should the search clear on blur */
  clearSearchOnBlur: PropTypes.bool,

  /** Is onBlur event */
  onBlur: PropTypes.func,
}

SearchBar.defaultProps = {
  placeholder: '',
  height: 'tall',
  isOpen: false,
  clearSearchOnBlur: false,
  onClick: () => {},
  onBlur: () => {},
}
