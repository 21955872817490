import React from 'react'
import styled from 'styled-components'
import * as styles from './style'
const { base, ...sizes } = styles

type Size = keyof typeof sizes

const StyledIcon = styled.svg<{ size: Size; stroke?: any }>`
  ${styles.base};
  ${props => sizes[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Warehouse = (props: {
  size: Size
  stroke?: any
  [key: string]: any
}) => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g
          fill="none"
          stroke="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 2C5.327 2 1.928 4.661 1.167 5.343h0A.5.5 0 001 5.715V7.5h0a.5.5 0 00.5.5h21a.5.5 0 00.5-.5V5.715h0a.5.5 0 00-.167-.372C22.072 4.661 18.673 2 12 2zM2 8v14M22 8v14M5.5 16H12v6H5.5h0a.5.5 0 01-.5-.5s0 0 0 0v-5h0a.5.5 0 01.5-.5zM12 16h6.5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H12v-6zM8.5 10h6a.5.5 0 01.5.5V16H8v-5.5h0a.5.5 0 01.5-.5z"></path>
          <path d="M10 10h3v3h-3zM7 16h3v2.5a.5.5 0 01-.5.5h-2 0a.5.5 0 01-.5-.5s0 0 0 0V16zM14 16h3v2.5a.5.5 0 01-.5.5h-2 0a.5.5 0 01-.5-.5s0 0 0 0V16zM10.25 5h3.5"></path>
        </g>
      </svg>
    </StyledIcon>
  )
}
