import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import { FormFooterContainer, Loader } from 'components'
import { useMutation } from '@apollo/client'
import WizardNavigation from 'components/WizardNavigation/WizardNavigation'
import { CreditCardForm } from 'views/Settings/Billing/Modals/CreditCardForm'
import { OrgDataForm } from 'views/Settings/Billing/Modals/OrgDataForm'
import { StyledFieldsContainer } from 'components/Form/Form'
import { SuccessForm } from 'views/Settings/Billing/Modals/SuccessForm'
import { useAppServices } from 'sdk/appServices'
import { useMutateOrganization } from 'state/queries'
import { useSdk } from 'sdk'
import { getISODate } from 'src/helpers'
import moment from 'moment'
import { PlansForm } from 'views/Settings/Billing/Modals/PlansForm'
import { downgradeOrgToFreeGql } from 'state/mutation/downgradeOrgToFree'
import {
  analyticsTrack,
  getOrgId,
  getLocationId,
  getPlanName,
  getPlanNameWithSuffix,
} from 'src/helpers'
import { useHistory, useLocation, useParams } from 'react-router'
import {
  usePaymentCreateFreePlanMutation,
  usePaymentUpdateStripeSubscriptionMutation,
  usePaymentCreateStripeSubscriptionMutation,
  useGetOrganizationByIdQuery,
  GetOrganizationByIdDocument,
  GetAllPaymentsDocument,
  PaymentPlan,
} from 'state/graphql'
import { device } from '../../../../constants'
import { MobileContext } from 'src/Context'
import { ConfirmationForm } from 'views/Settings/Billing/Modals/ConfirmationForm'
import { icons } from 'components/Icon'
import { FormFooterButtonsContainer } from 'components'

type TBillingFormProps = {
  closeModal: () => void
  showStep: number
  paymentStatus?: string
  trial3DayExpire?: boolean
  trialExpired?: boolean
  downgradeExpired?: boolean
  showUpdatePayment: boolean
}

const BillingForm = (props: TBillingFormProps) => {
  const {
    closeModal,
    showStep,
    paymentStatus: tmpPaymentIntentStatus,
    trial3DayExpire,
    trialExpired,
    downgradeExpired,
    showUpdatePayment,
  } = props

  const appServices = useAppServices()
  const [step, setStep] = useState(
    showUpdatePayment ? 0 : showStep ? showStep : 1
  )
  const { t } = useSdk()

  const { orgId } = useParams<{ [key: string]: string }>()
  const {
    data: organizationData,
    loading: loadingOrg,
  } = useGetOrganizationByIdQuery({
    variables: { id: orgId },
  })
  const org = organizationData?.organization?.get
  const [
    createStripeSubscription,
  ] = usePaymentCreateStripeSubscriptionMutation()
  const [cycle, setCycle] = useState(org?.subscriptionCycle || '1 month')
  const [isBusy, setIsBusy] = useState(false)

  const shouldShowPlanQuantity =
    (org?.subscriptionQuantity || 0) > 1 &&
    org?.subscriptionPlan !== 'free' &&
    (!org?.downgradePlan ||
      org?.downgradePlan === 'business' ||
      (org?.downgradePlan === 'free' && org?.subscriptionType === 'trial'))

  const changeCycle = () => {
    setCycle(cycle === '1 month' ? '365 days' : '1 month')
  }

  const [selectedPlan, setSelectedPlan] = useState<PaymentPlan | null>(null)

  const [paymentIntentStatus, setPaymentIntentStatus] = useState(
    tmpPaymentIntentStatus
  )
  const [failedPaymentHostedUrl, setFailedPaymentHostedUrl] = useState('')

  const daysRemaining = moment(getISODate(org?.subscriptionEndDate)).diff(
    moment(getISODate()),
    'days'
  )
  const hasMollieData =
    org?.subscriptionMandateId?.startsWith('mdt_') ||
    org?.subscriptionCustomerId?.startsWith('cst_')
  const trialDaysRemaining = daysRemaining > 0 ? daysRemaining : 0

  useEffect(() => {
    setPaymentIntentStatus(tmpPaymentIntentStatus)
  }, [tmpPaymentIntentStatus])
  useEffect(() => {
    const org = organizationData?.organization?.get
    if (!org || !!selectedPlan) return

    const orgPlan =
      org?.subscriptionType === 'trial'
        ? 'advanced'
        : org?.downgradePlan || org?.subscriptionPlan
    const plan = org?.paymentPlans?.plans?.find(x => x?.id === orgPlan)

    if (plan) {
      setSelectedPlan({
        ...plan,
      })
    }
  }, [organizationData, selectedPlan])

  const selectPlan = id => {
    if (!id) return
    const plan = org?.paymentPlans?.plans?.find(x => x?.id === id)
    if (plan) {
      setSelectedPlan({
        ...plan,
      })
    }
  }

  const [paymentCreateFreePlanMutation] = usePaymentCreateFreePlanMutation()

  const [
    updateStripeSubscription,
  ] = usePaymentUpdateStripeSubscriptionMutation()
  const [clientSecret, setClientSecret] = useState('')

  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (
      pathname.startsWith(
        `/${getOrgId()}/${getLocationId()}/settings/organization/locations`
      ) &&
      (pathname.includes('resources') ||
        pathname.endsWith('onlineBooking/integrations') ||
        pathname.includes('onlineBooking/prepayments'))
    ) {
      const plan = org?.paymentPlans?.plans?.find(x => x?.id === 'advanced')
      if (plan) {
        setSelectedPlan(plan)
      }
    } else if (
      pathname.startsWith(
        `/${getOrgId()}/${getLocationId()}/settings/organization/locations`
      ) &&
      (pathname.includes('holidays') ||
        pathname.includes('workHours') ||
        pathname.endsWith('employees/user/new/newUser') ||
        pathname.endsWith('professions') ||
        pathname.endsWith('onlineBooking') ||
        pathname.endsWith('onlineBooking/notifications'))
    ) {
      const plan = org?.paymentPlans?.plans?.find(x => x?.id === 'business')
      if (plan) {
        setSelectedPlan(plan)
      }
    }
  }, [])

  const [
    downgradeOrgToFreeMutation,
    { loading: loadingDowngrade },
  ] = useMutation(downgradeOrgToFreeGql)

  const createSubscription = async showSuccess => {
    if (!selectedPlan) return
    setIsBusy(true)

    const result = await createStripeSubscription({
      variables: {
        input: {
          planId: selectedPlan?.id,
          cycle,
        },
      },
    })

    if (!result.errors) {
      if (
        result?.data?.payment?.createStripeSubscription
          ?.unconfirmedInvoiceHostedUrl
      ) {
        setPaymentIntentStatus('paymentRequiresAction')
        setFailedPaymentHostedUrl(
          result?.data?.payment?.createStripeSubscription
            .unconfirmedInvoiceHostedUrl
        )
      } else {
        if (
          result?.data?.payment?.createStripeSubscription?.successInfo
            ?.status === 'noBillingOccured'
        ) {
          setPaymentIntentStatus('noBillingOccured')
        }
        analyticsTrack('SUBSCRIPTION created', {
          plan: selectedPlan?.id,
          cycle: cycle,
        })
      }
      showSuccess && setStep(5)
    } else if (result.errors?.[0]?.extensions?.code === 'CODE_ALREADY_USED') {
      appServices.toast.danger(result.errors?.[0]?.message)
    } else {
      appServices.toast.danger(
        t(
          'translation.BillingForm.oh-izgleda-da-nismu-uspjeli-izvrsiti-naplatu-molimo-provjerite-unesene-podatke'
        )
      )
    }
    setIsBusy(false)
  }

  const changeSubscriptionPlan = async () => {
    setIsBusy(true)
    if (!selectedPlan) return

    const result = await updateStripeSubscription({
      variables: {
        input: {
          planId: selectedPlan.id,
          cycle,
        },
      },
      refetchQueries: [
        { query: GetOrganizationByIdDocument, variables: { id: orgId } },
        { query: GetAllPaymentsDocument, variables: { orgId } },
        'queryResources',
        'locationGet',
      ],
    })

    if (!result.errors) {
      if (
        result?.data?.payment?.updateStripeSubscription
          ?.unconfirmedInvoiceHostedUrl
      ) {
        setPaymentIntentStatus('paymentRequiresAction')
        setFailedPaymentHostedUrl(
          result?.data?.payment.updateStripeSubscription
            .unconfirmedInvoiceHostedUrl
        )
      } else {
        if (
          result?.data?.payment?.updateStripeSubscription?.successInfo
            ?.status === 'noBillingOccured'
        ) {
          setPaymentIntentStatus('noBillingOccured')
        }
        analyticsTrack('PLAN changed', {
          plan: selectedPlan?.id,
          cycle: cycle,
        })
        /* setTimeout(() => {
          window.location.reload()
        }, 1500) */
      }

      setStep(5)
    } else if (result.errors?.[0]?.extensions?.code === 'CODE_ALREADY_USED') {
      appServices.toast.danger(result.errors?.[0]?.message)
    } else {
      appServices.toast.danger(
        t(
          'translation.BillingForm.oh-izgleda-da-nismu-uspjeli-izvrsiti-naplatu-molimo-provjerite-unesene-podatke'
        )
      )
    }
    setIsBusy(false)
  }

  const createFreePlan = async () => {
    const result = await paymentCreateFreePlanMutation({
      refetchQueries: [
        { query: GetOrganizationByIdDocument, variables: { id: orgId } },
        { query: GetAllPaymentsDocument, variables: { orgId } },
        'queryResources',
        'locationGet',
      ],
    })

    if (
      !result.errors &&
      result.data?.payment?.createFreePlan?.status?.success
    ) {
      analyticsTrack('FREE PLAN created', { plan: 'FREE', cycle: cycle })
      setPaymentIntentStatus('noBillingOccured')
      setStep(5)
    } else {
      appServices.toast.danger(
        t(
          'translation.BillingForm.oh-izgleda-da-nismu-uspjeli-izvrsiti-naplatu-molimo-provjerite-unesene-podatke'
        )
      )
    }
  }

  const downgradeToFree = async () => {
    const result = await downgradeOrgToFreeMutation({
      variables: {
        input: {
          orgId: getOrgId(),
        },
      },
      refetchQueries: [
        { query: GetOrganizationByIdDocument, variables: { id: orgId } },
        { query: GetAllPaymentsDocument, variables: { orgId } },
        'queryResources',
        'locationGet',
      ],
    })

    if (!result.errors) {
      analyticsTrack('FREE PLAN downgrade', {
        plan: 'FREE',
        cycle: cycle,
      })
      setPaymentIntentStatus('noBillingOccured')
      setStep(5)
    } else {
      appServices.toast.danger(
        t(
          'translation.BillingForm.oh-izgleda-da-nismu-uspjeli-izvrsiti-naplatu-molimo-provjerite-unesene-podatke'
        )
      )
    }
  }

  const setupCreateStripeSubscription = async () => {
    if (clientSecret) return
    //setIsBusy(true)
    if (!selectedPlan) return

    const result = await createStripeSubscription({
      variables: {
        input: {
          planId: selectedPlan.id,
          cycle,
        },
      },
    })
    if (!result?.errors?.length) {
      const secret =
        result?.data?.payment?.createStripeSubscription?.clientSecret
      if (secret) {
        setClientSecret(secret)
      }
    } else {
      const error = result.errors[0]
      closeModal()
      appServices.toast.danger(error.message)
    }
  }

  const [updateOrganization] = useMutateOrganization()
  async function updateOrg(values) {
    setIsBusy(true)
    const updatedOrg = {
      id: getOrgId(),
      orgName: values.orgName,
      city: values.city,
      zipCode: values.zipCode,
      address: values.address,
      vatId: !values.vatId ? '' : values.vatId.trim(),
      email: values.email,
      countryId: values.country?.id,
    }
    let updateResults = await updateOrganization({
      variables: {
        input: updatedOrg,
      },
    })
    if (updateResults) {
      analyticsTrack('ORG changed', { plan: 'FREE', cycle: cycle })
      !!org?.subscriptionMandateId ? setStep(4) : setStep(3)
      setIsBusy(false)
    }
  }

  const onSubmit = async () => {
    //if the plan is FREE, downgrade
    if (selectedPlan?.id === 'free' && org?.subscriptionType === 'trial') {
      createFreePlan()
    } else if (selectedPlan?.id === 'free' && step > 1) {
      downgradeToFree()
    } else if (
      !!org?.subscriptionMandateId &&
      org?.downgradePlan === 'free' &&
      step > 1
    ) {
      createSubscription(true)
    } else if (!!org?.subscriptionMandateId && step > 1) {
      changeSubscriptionPlan()
    } else if (step === 1 && !!org?.subscriptionMandateId) {
      setStep(4)
    } else {
      if (step === 1 && !org?.subscriptionMandateId) {
        setupCreateStripeSubscription()
      }
      setStep(step + 1)
    }
  }

  /*
   * First select which payment plan you want
   * */
  const Step1 = () => {
    if (!selectedPlan || !org) return null
    analyticsTrack('PLANS modal', selectedPlan)
    return (
      <PlansForm
        selectedPlan={selectedPlan}
        selectPlan={selectPlan}
        cycle={cycle}
        changeCycle={changeCycle}
        activePlan={org?.subscriptionPlan || org?.downgradePlan || ''}
        plans={org?.paymentPlans?.plans}
        disabledCycle={org?.subscriptionCycle === '365 days'}
        currency={org?.stripeCurrencyId || ''}
        isTrial={org?.subscriptionType === 'trial'}
        org={org}
        shouldShowPlanQuantity={shouldShowPlanQuantity}
        trialDaysRemaining={trialDaysRemaining}
      />
    )
  }

  /*
  If the user selected the FREE plan, we need to show the "are you sure you want to downgrade?" page
  Otherwise, if this is the first payment, show the Org Details page
   */
  const Step2 = () => {
    analyticsTrack('ORG DATA modal', selectedPlan)
    return (
      <OrgDataForm
        isBusy={isBusy}
        setStep={setStep}
        closeModal={closeModal}
        updateOrg={updateOrg}
        selectedPlan={selectedPlan}
        cycle={cycle}
      />
    )
  }

  /*
 Show the credit card payment form
  */
  const Step0 = () => {
    analyticsTrack('CREDIT CARD modal', selectedPlan)
    if (!selectedPlan || !cycle || !org) return null
    return (
      <CreditCardForm
        setStep={setStep}
        closeModal={closeModal}
        selectedPlan={selectedPlan}
        org={org}
        cycle={cycle}
        isUpdatePayment
      />
    )
  }

  /*
  Show the credit card payment form
   */

  const Step3 = () => {
    analyticsTrack('CREDIT CARD modal', selectedPlan)

    return (
      <CreditCardForm
        setStep={setStep}
        closeModal={closeModal}
        selectedPlan={selectedPlan}
        org={org}
        clientSecret={clientSecret}
        cycle={cycle}
        shouldShowPlanQuantity={shouldShowPlanQuantity}
      />
    )
  }

  const getSuccessFormInfo = () => {
    let title = t('translation.BillingForm.paymentText5')
    let text = t('translation.BillingForm.paymentText1')
    let imageUrl = '/assets/celebratePlan.svg'
    let button1Text
    let button2Text
    let status = 'upgradeSuccess'

    const onButton1Click = () => {
      if (failedPaymentHostedUrl) {
        window.open(failedPaymentHostedUrl, '_blank')
      } else {
        setStep(1)
      }
    }

    const onButton2Click = () => {
      closeModal()
    }

    if (trial3DayExpire) {
      title = t('translation.BillingForm.3DaysTitle')
      text = t('translation.BillingForm.3DaysText')
      imageUrl = '/assets/trialExpired.svg'
      button1Text = t('translation.BillingForm.selectPlan')
      button2Text = t('translation.BillingForm.sontinueTrial')
      status = '3DayExpire'
    } else if (trialExpired) {
      title = t('translation.BillingForm.expiredTrialTitle')
      text = t('translation.BillingForm.expiredTrialText')
      imageUrl = '/assets/trialExpired.svg'
      button1Text = t('translation.BillingContainer.change-plan')
      button2Text = t('translation.BillingForm.continueFree')
      status = 'trialExpired'
    } else if (downgradeExpired) {
      title = t('translation.BillingForm.planEnded', {
        plan: getPlanName(org?.subscriptionPlan),
      })
      text = t('translation.BillingForm.planEndedText', {
        plan:
          getPlanName(org?.downgradePlan) || getPlanName(org?.subscriptionPlan),
      })
      imageUrl = '/assets/trialExpired.svg'
      button1Text = t('translation.BillingContainer.change-plan')
      button2Text = t('translation.BillingForm.ok-nastavi')
      status = 'downgradeExpired'
    } else if (paymentIntentStatus === 'cardChangedSuccessfully') {
      title = t('translation.BillingForm.cardSucess')
      text = t('translation.BillingForm.cardSuccessText')
      imageUrl = '/assets/creditCard.svg'
      status = 'paymentSuccess'
    } else if (paymentIntentStatus === 'noBillingOccured') {
      //if there is no payment and we have already downgraded, just change the plan
      text = t('translation.BillingForm.downgradeSyccess')
      status = 'downgradeSuccess'
    } else if (paymentIntentStatus === 'paymentRequiresAction') {
      button1Text = t('translation.BillingContainer.confirm-payment')
      title = t('translation.BillingContainer.action-required')
      text = t('translation.BillingContainer.transaction-needs-confirmation')
      imageUrl = '/assets/billingWarning.svg'
      status = 'paymentFailed'
    } else if (
      !!paymentIntentStatus &&
      paymentIntentStatus !== 'succeeded' &&
      paymentIntentStatus !== 'processing'
    ) {
      title = t('translation.BillingForm.paymentText3')
      text = t('translation.BillingForm.paymentText4')
      imageUrl = '/assets/billingWarning.svg'
      status = 'paymentFailed'
    }

    if (hasMollieData) {
      title = t('translation.BillingForm.corrupted-data')
      text = t('translation.BillingForm.corrupted-data-text')
      imageUrl = '/assets/billingWarning.svg'
      status = 'billingDataCorrupted'
    }

    return {
      title,
      text,
      imageUrl,
      button1Text,
      button2Text,
      onButton1Click,
      onButton2Click,
      status,
    }
  }

  const Step4 = () => {
    analyticsTrack('CONFIRMATION modal', selectedPlan)
    return (
      <ConfirmationForm
        setStep={setStep}
        closeModal={closeModal}
        selectedPlan={selectedPlan}
        org={org}
        cycle={cycle}
        isBusy={isBusy}
        onSubmit={onSubmit}
        shouldShowPlanQuantity={shouldShowPlanQuantity}
      />
    )
  }

  /*
  After the payment is successful, show the success page
   */
  const Step5 = () => {
    analyticsTrack('SUCCESS modal', selectedPlan)
    return <SuccessForm {...getSuccessFormInfo()} />
  }
  const StepMollieStripe = () => {
    return <SuccessForm {...getSuccessFormInfo()} />
  }

  const getFormTitle = () => {
    switch (step) {
      case 0:
        return t('translation.BillingForm.enterNewCard')
      case 1:
        return t('translation.BillingForm.select-your-zoyya-plan')
      case 2:
        return t('translation.BillingForm.add-your-company-details')
      case 3:
        return t('translation.BillingForm.add-your-payment-details')
      case 4:
        return t('translation.BillingForm.planSwitch', {
          fromPlan: getPlanNameWithSuffix(
            org?.downgradePlan || org?.subscriptionPlan
          ),
          toPlan: getPlanNameWithSuffix(selectedPlan?.id),
        })
      default:
        return t('translation.BillingForm.congrats-youve-setup-your-zoyya-plan')
    }
  }

  function getDisabled() {
    if (hasMollieData) return true
    //ako je odabran isti plan i isti ciklus na koji smo već downgradeali
    if (org?.downgradePlan) {
      return (
        org?.downgradePlan === selectedPlan?.id &&
        org?.subscriptionCycle === cycle
      )
    }
    //ako je odabran isti plan i isti ciklus na kojem smo trenutno
    return (
      org?.subscriptionPlan === selectedPlan?.id &&
      org?.subscriptionType !== 'trial' &&
      (org?.subscriptionCycle === cycle || !org?.subscriptionCycle)
    )
  }

  useLayoutEffect(() => {
    const modal = document.getElementById('modalWrapper')
    if (modal) {
      modal.scrollTo(0, 0)
    }
  }, [step])

  const isMobile = useContext(MobileContext)

  const getCurrentStep = () => {
    if (!!org?.subscriptionMandateId && step === 4) {
      return 2
    } else if (!!org?.subscriptionMandateId && step === 5) {
      return 3
    } else return step
  }

  return (
    <BillingModal width={'900px'} id={'modalWrapper'}>
      {step < 5 /*  && !hasMollieData */ ? (
        <Title>
          <ModalTitle>{getFormTitle()}</ModalTitle>
          {step === 3 && !isMobile ? (
            <IconsWrapper>
              <CCIcon src={'/assets/1.png'} />
              <CCIcon src={'/assets/2.png'} />
              <CCIcon src={'/assets/3.png'} />
              <CCIcon src={'/assets/22.png'} />
            </IconsWrapper>
          ) : null}
        </Title>
      ) : null}
      <ModalContent>
        {!trial3DayExpire &&
        !trialExpired &&
        !downgradeExpired &&
        step !== 0 ? (
          <WizardNavigation
            totalSteps={!!org?.subscriptionMandateId ? 3 : 4}
            currentStep={getCurrentStep()}
          />
        ) : null}
        <>
          {/* We changed <Step0 /> to Step0() because defining components inside components
        makes everything rerender and we have inputs they lose value -> this is a solution
        */}
          {hasMollieData
            ? StepMollieStripe()
            : step === 0
            ? Step0()
            : step === 1
            ? Step1()
            : step === 2
            ? Step2()
            : step === 3
            ? Step3()
            : step === 4
            ? Step4()
            : Step5()}
        </>
        {loadingDowngrade || loadingOrg ? <Loader isComponent /> : null}
      </ModalContent>
      {step === 1 ? (
        <FormFooterButtonsContainer
          style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: 20,
          }}
        >
          {step > 1 && (
            <Button
              buttonType={'link'}
              type="button"
              name={'back'}
              label={t('translation.BillingForm.natrag')}
              iconComponent={<icons.ArrowLeft />}
              footerButton
              onClick={e => {
                e.preventDefault()
                setStep(step - 1)
              }}
            />
          )}

          <Button
            buttonType={'text'}
            type="button"
            name={'close'}
            label={t('translation.BillingForm.zatvori')}
            footerButton
            onClick={e => {
              e.preventDefault()
              closeModal()
              history.replace({
                pathname,
                search: '',
                state: { isActive: true },
              })
            }}
          />
          {step < 5 && (
            <Button
              buttonType={'primary'}
              name={'saveChanges'}
              label={t('translation.BillingForm.nastavi')}
              iconComponent={<icons.ArrowRight />}
              disabled={getDisabled()}
              iconRight
              onClick={() => onSubmit()}
            />
          )}
        </FormFooterButtonsContainer>
      ) : null}
      {step === 5 ? (
        <FormFooterContainer
          style={{ width: '100%', justifyContent: 'flex-end' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              buttonType={'text'}
              type="button"
              name={'close'}
              label={t('translation.BillingForm.zatvori')}
              footerButton
              onClick={e => {
                e.preventDefault()
                history.replace({
                  pathname,
                  search: '',
                  state: { isActive: true },
                })
                closeModal()
              }}
            />
          </div>
        </FormFooterContainer>
      ) : null}
    </BillingModal>
  )
}

export const ButtonStyled = styled(Button)`
  color: #7860dc;
`
export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
export const CCIcon = styled.img`
  width: 40px;
  margin-right: 10px;
`
export const ModalContent = styled.main`
  background: #f5f5f5;
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;

  ${StyledFieldsContainer} {
    background: transparent;
  }
  @media ${device.tablet} {
    overflow: auto;
  }
`

export const ModalTitle = styled.span`
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 10px;
  font-weight: 600;
`

export const Title = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  @media ${device.tablet} {
    min-height: 60px;
    justify-content: center;
  }
`

export const H3 = styled.h3`
  color: #3d3d3d;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
`

export const Strong = styled.span`
  color: #3d3d3d;
  font-weight: bold;
`

export const BillingModal = styled(Modal)`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export default BillingForm
