import styled from 'styled-components'
import { transparentize } from 'polished'
import { TooltipWrapper } from '../../components/Tooltip/style'
import { FieldRow } from '../Settings/styles'
import { device } from '../../constants'
import { ButtonLabel } from '../../components/Button/style'
import { AvatarWrapper } from '../../components/Avatar/Avatar'
import { OrganizationSwitchIcon } from '../../layout/styles'
import { DatePickerCalendarContainer } from '../Calendar/components/DatePicker/styles'
import { FormContentContainer, FormFooterContainer } from '../../components'
import { SelectStyled } from '../../components/Select/style'
import { StyledSelect } from '../../components/Select2/style'

export const EventGroupCapacity = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 26px;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
`

export const EventBodyWrapper = styled.div<{ isAppointmentModal?: boolean }>`
  height: 100%;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
  ${FieldRow} {
    margin-bottom: 10px;
  }

  ${SelectStyled} {
    max-width: inherit;
    min-width: 180px;
    width: auto;
  }

  ${StyledSelect} {
    min-width: 180px;

    @media ${device.tablet} {
      min-width: 140px;
    }
  }

  ${DatePickerCalendarContainer} {
    top: 40px;
  }
  @media ${device.tablet} {
    overflow: ${props => (props.isAppointmentModal ? 'auto' : 'hidden')};
    height: ${props => (props.isAppointmentModal ? 'calc(100% - 85px)' : null)};
    &::-webkit-scrollbar {
      width: ${props => (props.isAppointmentModal ? '4px' : '8px')};
      height: ${props => (props.isAppointmentModal ? '4px' : '8px')};
    }
    ${FormFooterContainer} {
      background: none;
      border-top: none;
    }

    ${FormContentContainer} {
      padding: 20px 0px 0px 20px;
    }

    ${SelectStyled} {
      left: -125px;
    }
  }
`

export const EventStatus = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  @media ${device.tablet} {
    margin-right: 0;
  }
`

export const EventTimeRow = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  align-items: center;
  width: ${props => (props.isFullWidth ? '100%' : '340px')};

  ${TooltipWrapper} {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  @media ${device.tablet} {
    padding-right: 10px;
    ${ButtonLabel} {
      display: none !important;
    }
  }
`

export const EventContainer = styled.div<{ clientDetails?: boolean }>`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }

  @media ${device.tablet} {
    max-height: 100%;
    overflow: ${props => (props.clientDetails ? 'auto' : 'hidden')};
    padding: 10px 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const EventDateTitle = styled.div<{ cancelBooking?: boolean }>`
  font-size: 22px;
  cursor: pointer;
  color: ${props =>
    props.cancelBooking ? '#df0d0d' : props.theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`

export const EventServicesList = styled.div<{
  isEvent?: boolean
  isMobile?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${props => (props.isEvent ? '300px' : 'auto')};
  overflow: auto;
  padding: 5px 20px;
  background: ${props =>
    props.isMobile
      ? 'transparent'
      : transparentize(0.5, props.theme.colors.mainBackground)};
  margin-top: -14px;
  border: 1px solid
    ${props =>
      props.isMobile
        ? 'transparent'
        : transparentize(0.5, props.theme.colors.outline)};
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  @media ${device.tablet} {
    padding: 5px 10px;
  }
`

export const WorkHourRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const WorkHourName = styled.span<{
  isInactive?: boolean
  isBusy?: boolean
  noProfession?: boolean
}>`
  flex: 2;
  font-size: 14px;
  color: ${props =>
    props.isInactive
      ? props.theme.colors.accent4
      : props.isBusy
      ? props.theme.colors.accent5
      : props.noProfession
      ? props.theme.colors.accent1
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const WorkHourHint = styled.span<{
  isInactive?: boolean
  isBusy?: boolean
}>`
  flex: 1;
  font-size: 14px;
  font-style: italic;
  color: ${props =>
    props.isInactive
      ? props.theme.colors.accent4
      : props.isBusy
      ? props.theme.colors.accent5
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
`

export const ServiceCircle = styled.div`
  width: 1.714rem;
  height: 1.714rem;
  border-radius: 0.857rem;
  background: ${props => props.color};
  min-width: 1.714rem;
  margin-right: 12px;
  @media ${device.tablet} {
    width: 2px;
    min-width: unset;
    margin-right: 0;
  }
`
export const SelectRow = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  ${AvatarWrapper} {
    margin-right: 8px;
  }

  ${OrganizationSwitchIcon} {
    margin-left: 0px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`

export const EventService = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 0px;
  position: relative;
  span {
    margin-left: 8px;
    font-weight: 500;
  }

  @media ${device.tablet} {
    ${ServiceCircle} {
      display: none;
    }

    ${SelectRow} {
      max-width: calc(100% - 40px);
    }
  }
`

export const ServiceName = styled.span<{
  hasNoProfession?: boolean
  resourceBookingNotConfirmed?: boolean
  resourceBookingCanceled?: boolean
  isServiceSelect?: boolean
}>`
  flex: 3;
  font-size: 14px;
  color: ${props =>
    props.hasNoProfession || props.resourceBookingNotConfirmed
      ? props.theme.colors.accent5
      : props.resourceBookingCanceled
      ? props.theme.colors.accent1
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 500px;
  @media ${device.tablet} {
    min-width: unset;
    margin-right: ${props => (props.isServiceSelect ? '10px' : null)};
  }
`

export const SelectOptionLabel = styled.span<{
  hasNoProfession?: boolean
  resourceBookingNotConfirmed?: boolean
  resourceBookingCanceled?: boolean
  isClientSelect?: boolean
}>`
  font-size: 14px;
  color: ${props =>
    props.hasNoProfession || props.resourceBookingNotConfirmed
      ? props.theme.colors.accent5
      : props.resourceBookingCanceled
      ? props.theme.colors.accent1
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  margin-right: 15px;
  flex-shrink: 0;

  @media ${device.tablet} {
    flex: initial;
    margin-right: ${props => (props.isClientSelect ? '10px' : '15px')};
    font-size: ${props => (props.isClientSelect ? '12px' : null)};
  }
`

export const SelectOptionWarning = styled.span`
  flex: 1;
  font-size: 14px;
  color: ${props => props.theme.colors.accent5};
  text-overflow: ellipsis;
  //overflow: hidden;
  white-space: nowrap;
  display: inline;
  margin-right: 5px;
  max-width: 20px;

  @media ${device.tablet} {
    flex: initial;
  }
`

export const EventServiceCloseButton = styled.div`
  cursor: pointer;
`

export const ClientHeader = styled.div`
  display: flex;
  align-items: center;
`

export const ClientRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const ClientContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 10px 25px;
  background: ${props => props.theme.colors.lightBackground};

  @media ${device.tablet} {
    flex-direction: column;
    padding: 15px 15px 5px 15px;
  }
`
export const ClientColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const ClientItem = styled.div`
  display: flex;
  padding: 0 12px;
  flex-direction: column;

  @media ${device.tablet} {
    padding: 0px 12px;
  }
`

export const ClientItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.lightBackground};
  }

  @media ${device.tablet} {
    flex-wrap: wrap;
    padding: 15px 12px 0px;
    &:hover {
      background: none;
    }
  }
`

export const ClientItemTitle = styled.div`
  margin-right: 20px;
  font-weight: 600;
  @media ${device.tablet} {
    font-weight: 500;
    margin-bottom: 10px;

    &:last-child {
      margin-top: 10px;
    }
  }
`

export const ClientEmployee = styled.div`
  margin-right: 20px;
  min-width: 100px;
  margin-left: 10px;
  @media ${device.tablet} {
    margin-right: auto;
    min-width: 100px;
    margin-bottom: 10px;
    margin-left: 0px;
    font-weight: 500;
    width: auto;
  }
`
export const ClientItemServices = styled.div`
  margin-right: auto;
  max-width: 350px;
  display: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`

export const ClientItemIcon = styled.div`
  margin-right: 20px;
  @media ${device.tablet} {
    font-weight: 500;
    margin-bottom: 10px;
  }
`

export const ClientList = styled.div`
  max-height: 500px;
  overflow: auto;
  padding: 10px 20px;

  @media ${device.tablet} {
    max-height: 100%;
    overflow: initial;
    padding: 10px 5px;
  }
`

export const NoProfessionLabel = styled.span`
  margin-left: 20px;
  font-style: italic;
  text-transform: uppercase;
`

export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
