import React from 'react'

export const LogoColor = (props: { src: any }) => {
  const { src } = props

  return <img src={src} className={'w-[130px] mr-[auto]'} />
}

export const LoginImage = (props: { src: any }) => {
  const { src } = props

  return (
    <img
      src={src}
      className={
        'hidden lg:flex items-center justify-center absolute top-[0] h-full w-full m-[auto]'
      }
    />
  )
}

export const LoginHeader = (props: { children: any }) => {
  const { children } = props

  return (
    <div
      className={
        'flex items-center justify-between relative lg:absolute left-[0] top-[0] w-full p-[30px] z-[9999]'
      }
    >
      {children}
    </div>
  )
}

export const LoginFormHeader = (props: {
  children: any
  isPartner?: boolean
  href?: string
}) => {
  const { children, isPartner, href } = props

  return (
    <a
      className={`h-[auto] lg:h-[270px] flex flex-col relative rounded-tl-[0px] rounded-tr-[0px] lg:rounded-tl-[20px] lg:rounded-tr-[20px] ${
        isPartner ? 'bg-[#5a47b6]' : 'bg-[#846fdb]'
      }`}
      href={href}
    >
      {children}
    </a>
  )
}

export const LoginHint = (props: {
  children: any
  hasMarginBottom?: boolean
  style?: any
}) => {
  const { children, hasMarginBottom, style } = props

  return (
    <span
      className={`flex flex-col lg:flex-row m-[auto] text-center text-zoyya-secondaryDark mt-[10px] justify-center ${
        hasMarginBottom ? 'mb-[10px]' : ''
      }`}
      style={style}
    >
      {children}
    </span>
  )
}

export const LoginLink = (props: { children: any; onClick?: any }) => {
  const { children, onClick } = props

  return (
    <span
      className={`text-zoyya-primary underline ml-[0] lg:ml-[10px] cursor-pointer`}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export const LoginPartnerRedirect = (props: {
  children: any
  onClick?: any
}) => {
  const { children, onClick } = props

  return (
    <span
      className={`text-zoyya-primary mt-[5px] cursor-pointer`}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export const LoginTitle = (props: { children: any }) => {
  const { children } = props

  return (
    <span className="text-[20px] text-zoyya-primary text-center mb-[20px] w-full flex items-center justify-center">
      {children}
    </span>
  )
}

export const Divider = (props: {}) => {
  return <div className="w-full h-[1px] m-[5px_0px_20px] bg-zoyya-outline" />
}
