import React from 'react'
import styled from 'styled-components'

type TProps = {
  size: 'small' | 'normal' | 'large' | 'extraLarge'
  color: 'primary' | 'secondary'
  weight: number | 'normal' | 'bold' | 'bolder' | 'lighter'
  children?: any
  style?: any
  type?: string
}

const fontSizeMap = {
  small: '0.8rem',
  large: '1.2rem',
  normal: '1rem',
  extraLarge: '1.4rem',
}

const StyledText = styled.span<any>`
  font-size: ${props => fontSizeMap[props.size]};
  font-weight: ${props => props.weight};
  color: ${props =>
    props.color === 'primary'
      ? props.theme.colors.text
      : props.theme.colors.secondaryDarker};
`

export const Text = (props: TProps) => {
  const { children } = props
  return <StyledText {...props}>{children}</StyledText>
}
Text.defaultProps = {
  size: 'normal',
  color: 'primary',
  weight: 'normal',
}
