import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
  useState,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import styled from 'styled-components'

type TFormFieldCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  style?: React.CSSProperties
  parseValue?: (value?: string) => any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  checkboxSize?: 'small' | 'medium' | 'large'
}

export const FormFieldOnlyCheckbox = (props: TFormFieldCheckboxProps) => {
  const { parseValue, checkboxSize, ...rest } = props
  const [field, meta] = useField(props)
  const formContext = useFormContext()
  const [isChecked, setIsChecked] = useState(props.checked)

  const handleChange = useCallback(
    event => {
      setIsChecked(!isChecked)
      formContext?.valueChange(field, event, !isChecked)
    },
    [formContext, field, isChecked]
  )
  return (
    <CheckboxInput
      {...rest}
      name={props.name}
      onChange={handleChange}
      checkboxSize={checkboxSize}
      value={field.value || ''}
      checked={props.checked || field.value}
      disabled={props.disabled}
      data-cy={`checkbox_${props.name}`}
    />
  )
}

export const CheckboxInput = styled.input<{
  checkboxSize?: 'small' | 'medium' | 'large'
}>`
  position: relative;
  cursor: pointer;
  height: ${props =>
    props.checkboxSize === 'small'
      ? '15px'
      : props.checkboxSize === 'medium'
      ? '30px'
      : '40px'};
  &[type='checkbox'] {
    width: 0px; // hide original blue checkbox
    margin-left: 0px !important;
  }
  &[disabled] {
    &:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
    :checked:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    height: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    top: ${props =>
      props.checkboxSize === 'small'
        ? '-2px'
        : props.checkboxSize === 'medium'
        ? '0px'
        : '0px'};
    left: ${props =>
      props.checkboxSize === 'small'
        ? '6px'
        : props.checkboxSize === 'medium'
        ? '5px'
        : '-1px'};
    background-color: ${props => props.theme.colors.light};
    border: 1px solid ${props => props.theme.colors.outline};
    border-radius: 4px;
  }
  :checked:before {
    content: '';
    display: block;
    position: absolute;
    width: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    height: ${props =>
      props.checkboxSize === 'small'
        ? '20px'
        : props.checkboxSize === 'medium'
        ? '30px'
        : '40px'};
    top: ${props =>
      props.checkboxSize === 'small'
        ? '-2px'
        : props.checkboxSize === 'medium'
        ? '0px'
        : '0px'};
    left: ${props =>
      props.checkboxSize === 'small'
        ? '6px'
        : props.checkboxSize === 'medium'
        ? '5px'
        : '-1px'};
    background-color: ${props => props.theme.colors.primary};
  }
  :checked:after {
    content: '';
    display: block;
    width: ${props =>
      props.checkboxSize === 'small'
        ? '6px'
        : props.checkboxSize === 'medium'
        ? '8px'
        : '10px'};
    height: ${props =>
      props.checkboxSize === 'small'
        ? '12px'
        : props.checkboxSize === 'medium'
        ? '17px'
        : '20px'};
    border: solid ${props => props.theme.colors.light};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: ${props =>
      props.checkboxSize === 'small'
        ? '0px'
        : props.checkboxSize === 'medium'
        ? '5px'
        : '7px'};
    left: ${props =>
      props.checkboxSize === 'small'
        ? '12.5px'
        : props.checkboxSize === 'medium'
        ? '16px'
        : '15px'};
  }
`
