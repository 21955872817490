import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useCalendarStore = create<{
  view?: string
  dateFrom?: string
  dateTo?: string
  newEvent?: {
    selectedHour: string
    selectedMinute: string
    resourceId?: string
    selectedDayId?: string
  }
  editAppointmentId?: string
  showAppointmentModal: 'NEW' | 'EDIT' | 'NONE' | 'WIZARD'
  closeAppointmentModal: () => void
  openEditEventModal: (id: string) => void
  openNewEventModal: (event: {
    selectedHour: string
    selectedMinute: string
    resourceId?: string
    selectedDayId?: string
  }) => any
  openWizardModal: () => void
}>(set => ({
  view: 'DAY',
  showAppointmentModal: 'NONE',
  closeAppointmentModal: () =>
    set(state => ({
      showAppointmentModal: 'NONE',
      editAppointmentId: undefined,
      newEvent: undefined,
    })),
  openEditEventModal: (id: string) =>
    set(state => ({
      showAppointmentModal: 'EDIT',
      editAppointmentId: id,
      newEvent: undefined,
    })),
  openNewEventModal: (event: {
    selectedHour: string
    selectedMinute: string
    resourceId?: string
    selectedDayId?: string
  }) => {
    set({
      newEvent: event,
      showAppointmentModal: 'NEW',
      editAppointmentId: undefined,
    })
  },
  openWizardModal: () => {
    set({
      showAppointmentModal: 'WIZARD',
    })
  },
}))

export const { setState, getState } = useCalendarStore

export const calendarActions = {
  setSearchParams({
    dateFrom,
    dateTo,
    view,
  }: {
    dateFrom?: string
    dateTo?: string
    view?: string
  }) {
    setState({ dateFrom, dateTo, view })
  },
}

interface PersistedCalendarStore {
  calendarZoom: number
  onZoomIn: () => void
  onZoomOut: () => void
}

export const usePersistedCalendarStore = create<PersistedCalendarStore>()(
  persist(
    set => ({
      calendarZoom: 3,
      onZoomIn: () =>
        set(state => ({
          calendarZoom: state.calendarZoom + 1,
        })),
      onZoomOut: () =>
        set(state => ({
          calendarZoom: state.calendarZoom - 1,
        })),
    }),
    {
      name: 'calendarZoom',
    }
  )
)
