import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Hand = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="inherit"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19 17.35v.001c0-.887.218-1.76.635-2.544l2.142-4.022h0a1.5 1.5 0 00-2.555-1.57L16.5 13.5l1.617-8.821h0a1.5 1.5 0 00-2.932-.631L13.5 11.5V2v0a1.5 1.5 0 00-3 0v9.5L8.816 3.683h0a1.5 1.5 0 10-2.933.631L7.5 15.5l-2.987-2.608h0a1.5 1.5 0 10-2.025 2.213l5.257 7h0A4.5 4.5 0 0011 23.5h3.5c3.555 0 4.5-2.777 4.5-6.15z"
      ></path>
    </StyledIcon>
  )
}
