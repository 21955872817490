import gql from 'graphql-tag'

export const downgradeOrgToFreeGql = gql`
  mutation downgradeOrgToFree($input: SubscriptionCancelInput!) {
    payment {
      cancelSubscription(input: $input) {
        status {
          success
        }
      }
    }
  }
`
