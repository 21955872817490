import styled from 'styled-components'
import { transparentize } from 'polished'
import { fontFamily } from 'components/style/fonts'
import { ChevronLeft } from 'components/Icon/Icons/ChevronLeft'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'

export const DatePickerCalendarContainerMobile = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  z-index: 99999;
  display: flex;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 250ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 250ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  //box-shadow: 0 5px 15px 5px rgba(164, 173, 186, 0.25);
  box-shadow: 0 0px 15px 0px rgb(0 0 0 / 15%);
  width: 100%;
  height: 455px;
`

export const DatePickerLeftMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const DatePickerRightMobile = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  border-left: 1px solid
    ${props => transparentize(0.4, props.theme.colors.outline)};
  padding: 20px;
`

export const DatePickerButtonMobile = styled.div`
  color: ${props => props.theme.colors.text};
  background: ${props => props.theme.colors.light};
  border: 1px solid ${props => transparentize(0.4, props.theme.colors.outline)};
  font-size: 14px;
  font-family: muli, sans-serif;
  box-shadow: 2px 2px 4px 0 rgb(86 93 105 / 10%);
  user-select: none;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 14px 16px;
  min-width: 128px;
  border-radius: 4px;

  &:hover {
    transform: translateY(-1px);
  }
`

export const DatePickerHeaderMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 20px 0px;

  span {
    color: ${props => props.theme.colors.secondaryDark};
  }
`
export const DateArrowMobile = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    span {
      color: ${props => props.theme.colors.text};
    }
  }
`

export const DatePickerContainerMobile = styled.div`
  display: flex;
  align-content: center;
  margin: auto;
  background: transparent;
`

export const DatePickerWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`

export const DateButtonMobile = styled.button`
  padding: 8px 8px;
  white-space: nowrap;

  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  font-size: 14px;
  height: 38px;
  font-family: ${fontFamily};
  color: ${props => props.theme.colors.light};
  &:focus,
  :active {
    border: none;
    outline: none;
  }
`

export const DatePickerArrowMobile = styled(DateButtonMobile)`
  box-shadow: none;
  transform: none;
  color: #101928;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 40px;
  border: none;
  border-radius: 4px;
  background: transparent;
  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`

export const DatePickerTodayMobile = styled(DateButtonMobile)`
  min-width: 80px;
  height: 38px;
  border: none;
  box-shadow: none;
  transform: none;
  color: ${props => props.theme.colors.light};
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
`

export const DatePickerDateMobile = styled(DateButtonMobile)`
  min-width: 180px;
  border: none;
  cursor: pointer;
  background: transparent;
`

export const ArrowLeftMobile = styled(DatePickerArrowMobile)`
  padding-left: 8px;
  padding-right: 8px;

  span {
    color: ${props => props.theme.colors.light};
    font-size: 20px;
  }
`
export const StyledChevronLeft = styled(ChevronLeft)`
  color: ${props => props.theme.colors.light};
`
export const StyledChevronRight = styled(ChevronRight)`
  color: ${props => props.theme.colors.light};
`

export const DatePickerNameMobile = styled.p`
  font-size: 18px;
  font-weight: 600;

  text-align: center;

  color: ${props => props.theme.colors.text};
`

export const DatePickerCalendarStyledMobile = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(20px, 1fr));
  grid-template-rows: ${props => (props.isSmall ? 'auto' : '50px')};
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
`

export const MonthDayNameMobile = styled.span`
  letter-spacing: 1px;
  font-size: 14px;
  background: ${props =>
    props.isCurrent && !props.isDisabled
      ? props.theme.colors.accent4
      : props.isSelected
      ? props.theme.colors.primary
      : props.isHovered
      ? transparentize(0.9, props.theme.colors.primary)
      : props.theme.colors.light};
  color: ${props =>
    props.isHoliday
      ? `${props.theme.colors.accent5} !important`
      : props.isToday && props.isSelected
      ? props.theme.colors.light
      : (props.isCurrent && !props.isDisabled) || props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.secondaryDarker};
  padding: ${props => (props.isSmall && !props.isCurrent ? '0' : '3px 6px;')};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => (props.isToday ? 'bolder' : 'normal')};

  &:hover {
    background: ${props => transparentize(0.9, props.theme.colors.primary)};
  }
`

export const DatePickerCalendarDayMobile = styled.div`
  text-align: right;
  padding: 10px;
  cursor: pointer;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(7n + 7) {
    border-right: 0;
  }

  &:nth-of-type(n + 1):nth-of-type(-n + 7) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 14) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 21) {
    grid-row: 3;
  }

  &:nth-of-type(n + 22):nth-of-type(-n + 28) {
    grid-row: 4;
  }

  &:nth-of-type(n + 29):nth-of-type(-n + 35) {
    grid-row: 5;
  }

  &:nth-of-type(7n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(7n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(7n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(7n + 4) {
    grid-column: 4/4;
  }

  &:nth-of-type(7n + 5) {
    grid-column: 5/5;
  }

  &:nth-of-type(7n + 6) {
    grid-column: 6/6;
  }
  &:nth-of-type(7n + 7) {
    grid-column: 7/7;
  }
`

export const DatePickerDayHolidayMobile = styled(DatePickerCalendarDayMobile)`
  span {
    color: ${props => props.theme.colors.accent5};
    font-weight: 500;
  }
`

export const DatePickerDayNameMobile = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text};
  text-align: center;
  line-height: 60px;
  font-weight: 600;
`

export const DatePickerDayDisabledMobile = styled(DatePickerCalendarDayMobile)`
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  span {
    color: rgba(152, 160, 166, 0.5);
  }
`
export const DatePickerOverlay = styled.div`
  height: 100%;
  position: absolute;
  top: 55px;
  right: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
`
