import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Lock = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.707 14.293a1 1 0 11-1.414 1.414 1 1 0 011.414-1.414M12 16v3"></path>
        <path d="M4.5 23.5a1 1 0 01-1-1v-12a1 1 0 011-1h15a1 1 0 011 1v12a1 1 0 01-1 1zM6.5 6h0a5.5 5.5 0 1111 0s0 0 0 0v3.5h-11z"></path>
      </g>
    </StyledIcon>
  )
}
