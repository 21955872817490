import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import { FormFooterContainer, Loader } from 'components'
import WizardNavigation from 'components/WizardNavigation/WizardNavigation'
import { StyledFieldsContainer } from 'components/Form/Form'
import { useHistory, useLocation, useParams } from 'react-router'
import { device } from '../../../../constants'
import { PackagesForm } from 'views/Settings/Billing/Modals/PackagesForm'
import {
  useGetSmsPackagesQuery,
  useBuySmsCreditsMutation,
  GetSmsBalanceDocument,
  SmsPackage,
  GetOrganizationByIdDocument,
  useGetOrganizationByIdQuery,
} from 'state/graphql'
import { CreditCardSmsForm } from 'views/Settings/Billing/Modals/CreditCardForm'
import { useSdk } from 'sdk'
import { SuccessForm } from 'views/Settings/Billing/Modals/SuccessForm'
import { MobileContext } from 'src/Context'
import { icons } from 'components/Icon'
import { OrgDataForm } from 'views/Settings/Billing/Modals/OrgDataForm'
import { useMutateOrganization } from 'state/queries'
import { SmsConfirmationForm } from './SmsConfirmationForm'

type TBuySmsModalProps = {
  closeModal: () => void
  showStep: number
  purchasedPackageName: string
  paymentIntentId?: string
  paymentStatus?: string
}

const BuySmsModal = (props: TBuySmsModalProps) => {
  const {
    closeModal,
    showStep,
    paymentStatus: tmpPaymentIntentStatus,
    purchasedPackageName,
    paymentIntentId,
  } = props
  const { pathname } = useLocation()
  const history = useHistory()
  const { t, toast } = useSdk()
  const [step, setStep] = useState(showStep)
  const [selectedPackage, setSelectedPackage] = useState<SmsPackage | null>(
    null
  )

  const [clientSecret, setClientSecret] = useState('')

  const [paymentIntentStatus, setPaymentIntentStatus] = useState(
    tmpPaymentIntentStatus
  )
  const [failedPaymentHostedUrl, setFailedPaymentHostedUrl] = useState('')
  const { orgId } = useParams<{ [key: string]: string }>()
  const {
    data: organizationData,
    loading: loadingOrg,
  } = useGetOrganizationByIdQuery({
    variables: { id: orgId },
  })
  const org = organizationData?.organization?.get

  const hasMollieData =
    org?.subscriptionMandateId?.startsWith('mdt_') ||
    org?.subscriptionCustomerId?.startsWith('cst_')

  const {
    data: smsPackageData,
    loading: smsPackageLoading,
  } = useGetSmsPackagesQuery({
    //skip: !org,
  })
  const smsPackages = smsPackageData?.payment?.getSmsPackages

  const [buySmsCreditsMutation] = useBuySmsCreditsMutation()

  useEffect(() => {
    if (smsPackages?.[0]) {
      setSelectedPackage({
        ...smsPackages?.[0],
      })
    }
  }, [smsPackageLoading])

  const buySmsCredits = async () => {
    setIsBusy(true)
    if (!selectedPackage) return

    const result = (await buySmsCreditsMutation({
      variables: {
        input: {
          cycle: 'single',
          planId: selectedPackage?.id,
        },
      },
      refetchQueries: [
        {
          query: GetSmsBalanceDocument,
        },
        {
          query: GetOrganizationByIdDocument,
          variables: {
            id: orgId,
          },
        },
      ],
    })) as any

    if (!result.errors) {
      if (result.data?.payment?.buySmsCredits?.unconfirmedInvoiceHostedUrl) {
        setPaymentIntentStatus('paymentRequiresAction')
        setFailedPaymentHostedUrl(
          result.data.payment.buySmsCredits.unconfirmedInvoiceHostedUrl
        )
      } else {
        /*  setTimeout(() => {
          window.location.reload()
        }, 1500) */
      }
      setStep(4)
    } else {
      setPaymentIntentStatus('failed')
      setStep(4)
    }
    setIsBusy(false)
  }

  const selectPackage = id => {
    if (!id) return
    const smsPackage = smsPackages?.find(x => x.id === id)
    if (smsPackage) {
      setSelectedPackage({
        ...smsPackage,
      })
    }
  }

  const getSuccessFormInfo = () => {
    let title = t(
      'translation.BuySmsModal.congrats-youve-purchased-sms-package',
      { packageName: purchasedPackageName }
    )
    let text = t('translation.BuySmsModal.success-text')
    let imageUrl = '/assets/celebratePlan.svg'
    let button1Text
    let button2Text
    let status = 'buySmsSuccess'

    const onButton1Click = () => {
      if (failedPaymentHostedUrl) {
        window.open(failedPaymentHostedUrl, '_blank')
      } else {
        history.replace({
          search: '',
        })
        setStep(1)
      }
    }

    const onButton2Click = () => {
      closeModal()
    }

    if (paymentIntentStatus === 'succeeded') {
      status = 'paymentSuccess'
      button1Text = t('translation.BuySmsModal.button-buy-more')
    } else if (paymentIntentStatus === 'paymentRequiresAction') {
      /* title = t('translation.BillingForm.cardSucess')
      text = t('translation.BillingForm.cardSuccessText')
      imageUrl = '/assets/creditCard.svg'
      status = 'paymentSuccess'
      button1Text = t('translation.BuySmsModal.button-buy-more') */
      button1Text = t('translation.BillingContainer.confirm-payment')
      title = t('translation.BillingContainer.action-required')
      text = t('translation.BillingContainer.transaction-needs-confirmation')
      imageUrl = '/assets/billingWarning.svg'
      status = 'paymentFailed'
    } else if (
      (!!paymentIntentStatus && paymentIntentStatus !== 'succeeded') ||
      paymentIntentStatus === 'failed'
    ) {
      button1Text = t('translation.BuySmsModal.button-try-again')
      title = t('translation.BillingForm.paymentText3')
      text = t('translation.BillingForm.paymentText4')
      imageUrl = '/assets/billingWarning.svg'
      status = 'paymentFailed'
    }

    if (hasMollieData) {
      title = t('translation.BillingForm.corrupted-data')
      text = t('translation.BillingForm.corrupted-data-text')
      imageUrl = '/assets/billingWarning.svg'
      status = 'billingDataCorrupted'
    }

    return {
      title,
      text,
      imageUrl,
      button1Text,
      button2Text,
      onButton1Click,
      onButton2Click,
      status,
    }
  }

  const [isBusy, setIsBusy] = useState(false)

  const [updateOrganization] = useMutateOrganization()
  async function updateOrg(values) {
    setIsBusy(true)
    const updatedOrg = {
      id: values.id,
      orgName: values.orgName,
      city: values.city,
      zipCode: values.zipCode,
      address: values.address,
      vatId: !values.vatId ? '' : values.vatId.trim(),
      email: values.email,
      countryId: values.country?.id,
    }
    let updateResults = await updateOrganization({
      variables: {
        input: updatedOrg,
      },
    })
    if (updateResults) {
      setIsBusy(false)
      setStep(3)
    }
  }

  const Step1 = () => {
    return (
      <PackagesForm
        selectedPackage={selectedPackage}
        selectPackage={selectPackage}
        packages={smsPackages || []}
        currency={org?.stripeCurrencyId || ''}
        isBusy={isBusy}
        country={org?.country?.countryCode}
      />
    )
  }

  const Step2 = () => {
    return (
      <OrgDataForm
        isBusy={isBusy}
        setStep={setStep}
        closeModal={closeModal}
        updateOrg={updateOrg}
      />
    )
  }

  const Step3 = () => {
    if (!selectedPackage) return null
    return (
      <CreditCardSmsForm
        setStep={setStep}
        closeModal={closeModal}
        selectedPackage={selectedPackage}
        org={org}
        clientSecret={clientSecret}
      />
    )
  }
  const Step5 = () => {
    return (
      <SmsConfirmationForm
        setStep={setStep}
        closeModal={closeModal}
        selectedPackage={selectedPackage}
        org={org}
        isBusy={isBusy}
        onSubmit={buySmsCredits}
      />
    )
  }

  const Step4 = () => {
    return <SuccessForm {...getSuccessFormInfo()} />
  }

  const StepMollieStripe = () => {
    return <SuccessForm {...getSuccessFormInfo()} />
  }

  const getFormTitle = () => {
    switch (step) {
      case 1:
        return t('translation.BuySmsModal.select-sms-package')
      case 2:
        return t('translation.BillingForm.add-your-company-details')
      case 5:
        return t('translation.BuySmsModal.confirm-payment')
      case 3:
        return t('translation.BuySmsModal.enter-new-card')
      default:
        return t(
          'translation.BuySmsModal.congrats-youve-purchased-sms-package',
          { packageName: selectedPackage?.packageName }
        )
    }
  }

  const isMobile = useContext(MobileContext)

  useLayoutEffect(() => {
    const modal = document.getElementById('modalWrapper')
    if (modal) {
      modal.scrollTo(0, 0)
    }
  }, [step])

  const handleStepNavigation = async () => {
    if (!selectedPackage) return
    if (step === 1) {
      if (org?.subscriptionMandateId) {
        setStep(5)
      } else {
        if (!clientSecret) {
          setIsBusy(true)
          const result = await buySmsCreditsMutation({
            variables: {
              input: {
                planId: selectedPackage?.id,
                cycle: 'single',
              },
            },
          })
          if (!result?.errors?.length) {
            const secret = result?.data?.payment?.buySmsCredits?.clientSecret
            if (secret) {
              setClientSecret(secret)
            }
          } else {
            const error = result.errors[0]
            console.log(error)
            toast.danger(error.message)
          }
          setIsBusy(false)
        }

        setStep(step + 1)
      }
    } else {
      setStep(step + 1)
    }
  }
  return (
    <BillingModal width={'900px'} id={'modalWrapper'}>
      {step < 4 || step === 5 ? (
        <Title>
          <ModalTitle>{getFormTitle()}</ModalTitle>
          {step === 3 && !isMobile ? (
            <IconsWrapper>
              <CCIcon src={'/assets/1.png'} />
              <CCIcon src={'/assets/2.png'} />
              <CCIcon src={'/assets/3.png'} />
              <CCIcon src={'/assets/22.png'} />
            </IconsWrapper>
          ) : null}
        </Title>
      ) : null}
      <ModalContent>
        <WizardNavigation totalSteps={4} currentStep={step} />
        {hasMollieData ? (
          <StepMollieStripe />
        ) : step === 1 ? (
          <Step1 />
        ) : step === 2 ? (
          <Step2 />
        ) : step === 3 ? (
          <Step3 />
        ) : step === 5 ? (
          <Step5 />
        ) : paymentIntentId || step === 4 ? (
          <Step4 />
        ) : null}
        {smsPackageLoading || loadingOrg ? <Loader isComponent /> : null}
      </ModalContent>
      {step === 1 ? (
        <FormFooterContainer
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {step > 1 ? (
            <Button
              buttonType={'link'}
              type="button"
              name={'back'}
              label={t('translation.BuySmsModal.button-back')}
              iconComponent={<icons.ArrowLeft />}
              footerButton
              onClick={e => {
                e.preventDefault()
                setStep(step - 1)
              }}
            />
          ) : null}

          <Button
            buttonType={'text'}
            type="button"
            name={'close'}
            label={t('translation.BuySmsModal.button-close')}
            footerButton
            onClick={e => {
              e.preventDefault()
              closeModal()
              history.replace({
                pathname,
                search: '',
                state: { isActive: true },
              })
            }}
          />
          {step < 4 ? (
            <Button
              buttonType={'primary'}
              name={'saveChanges'}
              label={t('translation.BuySmsModal.button-continue')}
              iconComponent={<icons.ArrowRight />}
              iconRight
              disabled={hasMollieData}
              onClick={handleStepNavigation}
            />
          ) : null}
        </FormFooterContainer>
      ) : null}
      {step === 4 ? (
        <FormFooterContainer
          style={{ width: '100%', justifyContent: 'flex-end' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              buttonType={'text'}
              type="button"
              name={'close'}
              label={t('translation.BillingForm.zatvori')}
              footerButton
              onClick={e => {
                e.preventDefault()
                history.replace({
                  pathname,
                  search: '',
                  state: { isActive: true },
                })
                closeModal()
              }}
            />
          </div>
        </FormFooterContainer>
      ) : null}
    </BillingModal>
  )
}

export const ButtonStyled = styled(Button)`
  color: #7860dc;
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const CCIcon = styled.img`
  width: 40px;
  margin-right: 10px;
`

export const ModalContent = styled.main`
  background: #f5f5f5;
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;

  ${StyledFieldsContainer} {
    background: transparent;
  }

  @media ${device.tablet} {
    overflow: auto;
  }
`

export const ModalTitle = styled.span`
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 10px;
  font-weight: 600;
`

export const Title = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  @media ${device.tablet} {
    min-height: 60px;
    justify-content: center;
  }
`

export const H3 = styled.h3`
  color: #3d3d3d;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
`

export const Strong = styled.span`
  color: #3d3d3d;
  font-weight: bold;
`

export const BillingModal = styled(Modal)`
  @media ${device.tablet} {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export default BuySmsModal
