import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
`

export const CheckFilled = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="inherit"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.854 15.646h0a1.206 1.206 0 000 1.707l5.792 5.793h0a1.21 1.21 0 001.708 0L23.146 8.353h0a1.206 1.206 0 000-1.707l-2.292-2.293h0a1.207 1.207 0 00-1.708 0L7.5 16l-2.646-2.647h0a1.207 1.207 0 00-1.708 0z"
      ></path>
    </StyledIcon>
  )
}
