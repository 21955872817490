import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  stroke: ${props => props.strokeColor};
`

const Calendar = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g>
        <path
          d="M2 24c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h21c.6 0 1 .4 1 1v19c0 .6-.4 1-1 1H2zM6 1v5M19 1v5M1 8h23M7 19.5h5"
          className="st0"
        ></path>
        <path
          d="M9.5 19.5V12c0-.3-.2-.5-.5-.5-.1 0-.2 0-.3.1L7 13M16 19.5l2.9-7.3c.1-.2.1-.3 0-.5s-.3-.2-.5-.2h-4c-.3 0-.5.2-.5.5v1"
          className="st0"
        ></path>
      </g>
    </StyledIcon>
  )
}
Calendar.displayName = 'Calendar'

export default Calendar
