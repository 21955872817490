import { useParams } from 'react-router-dom'
import { useHeader } from 'src/mobile/Header'
import { useSdk } from 'src/sdk'
import React, { useEffect } from 'react'
import { Button, Loader } from 'components'
import { icons } from 'components'
import { useFooter } from 'mobile/Footer'
import moment from 'moment'
import { RouterOutput, trpc } from 'src/trpc'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import {
  getReminderStatusTitleMapper,
  getReminderTypeTitleMapper,
} from 'src/helpers'
import { ClientRemindersUpgradePage } from 'src/components/UpgradePage/UpgradePage'
import { useGetOrganizationByIdQuery } from 'src/state/graphql'

export const ClientRemindersListMobile = () => {
  const { t, navigateTo } = useSdk()
  const params = useParams<any>()
  const trpcContext = trpc.useContext()

  const { data, isLoading: remindersIsLoading } = trpc.reminders_get.useQuery({
    orgId: params.orgId,
    locationId: BigInt(params.locationId),
    clientId: BigInt(params.id),
  })

  const { data: orgData, loading: orgLoading } = useGetOrganizationByIdQuery({
    variables: { id: params?.orgId },
  })
  const org = orgData?.organization?.get

  const remindersData = data || []

  useHeader({
    content: t('translation.ClientReminders.reminders-label'),
    onGoBack: () => navigateTo.clientDetails(params),
  })

  useFooter({
    showAddButton: true,
    addButtonOptions: [
      {
        onClick: () => navigateTo.newClientReminder(params),
        title: t('translation.ClientReminders.new-reminder'),
      },
    ],
  })

  useEffect(() => {
    trpcContext.reminders_get.invalidate()
  }, [])

  if (!orgLoading && org && org?.subscriptionPlan !== 'advanced') {
    return (
      <div>
        <ClientRemindersUpgradePage />
      </div>
    )
  }

  return (
    <>
      {remindersIsLoading ? (
        <Loader />
      ) : (
        <div className="my-5">
          <RemindersListComponent remindersData={remindersData} />
        </div>
      )}
    </>
  )
}

export const RemindersListComponent = ({
  remindersData,
}: {
  remindersData: RouterOutput['reminders_get']
}) => {
  const { t, appServices } = useSdk()
  const { showPopup } = usePopup()
  const trpcContext = trpc.useContext()
  const cancelReminderMutation = trpc.reminder_cancel.useMutation()

  const handleCancelReminder = async (id: bigint) => {
    try {
      await cancelReminderMutation.mutateAsync({
        reminderId: id,
      })

      trpcContext.reminders_get.invalidate()
      appServices.toast.success(
        t('translation.ClientReminders.cancelation-success-toast')
      )
    } catch (err) {
      appServices.toast.danger(
        t('translation.ClientReminders.cancelation-error-toast')
      )
    }
  }

  return (
    <>
      {remindersData?.length ? (
        remindersData?.map(reminder => {
          return (
            <div className="p-[15px] pb-[16px] lg:pb-[20px] lg:mx-[10px] mx-[16px] border rounded-[10px] mb-[15px] flex-1 ">
              <div className="flex justify-between items-center mb-2">
                <p className="py-1 mr-3 font-medium uppercase ">
                  {getReminderTypeTitleMapper(reminder, t)}
                </p>
                <span
                  className={`font-medium text-right ${
                    getReminderStatusTitleMapper(reminder?.status, t).className
                  }`}
                >
                  {getReminderStatusTitleMapper(reminder?.status, t).title}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex">
                  <p className="min-w-[115px] flex items-center">
                    <span className="mr-2">
                      {<icons.CalendarIcon size="smaller" />}
                    </span>

                    <span className="relative top-px">
                      {moment(reminder?.notificationTimeUtc).format(
                        'DD.MM.YYYY.'
                      )}
                    </span>
                  </p>
                  <p className="flex items-center">
                    <span className="mr-2">
                      {<icons.Clock size="smaller" />}
                    </span>
                    <span className="relative top-px">
                      {moment(reminder?.notificationTimeUtc).format('HH:mm')}
                    </span>
                  </p>
                </div>

                <div>
                  {reminder?.status === 'SCHEDULED' ? (
                    <div className="flex justify-end">
                      <Button
                        onClick={async e => {
                          e.preventDefault()
                          e.stopPropagation()
                          showPopup({
                            titleText: t(
                              'translation.ClientReminders.cancelation-confirm'
                            ),
                            onConfirm: async () =>
                              await handleCancelReminder(reminder.id),
                          })
                        }}
                        buttonType={'primaryAccent'}
                        size="smaller"
                        iconComponent={<icons.Trash size={'smaller'} />}
                        name={'cancel'}
                        type="button"
                        disabled={false}
                        label={t(
                          'translation.AppointmentModal.button-cancelRequest'
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <EmptyList title={t('translation.ClientReminders.no-reminders')} />
      )}
    </>
  )
}
