import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Employees = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M14.4 6.8c1.1 1.1 1.1 2.8 0 3.9s-2.8 1.1-3.9 0-1.1-2.8 0-3.9 2.9-1.1 3.9 0M16 16c0-1.9-1.6-3.5-3.5-3.5S9 14.1 9 16v3h1.6l.4 5h3l.4-5H16v-3zM5.6 1.7c.9.9.9 2.3 0 3.2s-2.3.9-3.2 0-.9-2.3 0-3.2 2.3-.9 3.2 0M4 6.5c-1.7 0-3 1.3-3 3V12h1.5l.5 4.5h2l.5-4.5H7V9.5c0-1.7-1.3-3-3-3zM22.6 1.7c.9.9.9 2.3 0 3.2s-2.3.9-3.2 0c-.9-.9-.9-2.3 0-3.2s2.3-.9 3.2 0M21 6.5c-1.7 0-3 1.3-3 3V12h1.5l.5 4.5h2l.5-4.5H24V9.5c0-1.7-1.3-3-3-3z"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
