import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Info = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        d="M20.3 4.7c4.3 4.3 4.3 11.3 0 15.6-4.3 4.3-11.3 4.3-15.6 0C.4 16 .4 9 4.7 4.7 9 .4 16 .4 20.3 4.7"
        fill="none"
      ></path>
      <path
        d="M12.5 17.5c-.1 0-.2.1-.2.2s.1.2.2.2.2-.1.2-.2c.1-.1-.1-.2-.2-.2M9.5 10.5c0-1.7 1.3-3 3-3s3 1.3 3 3c0 1.2-.7 2.3-1.8 2.7-.7.3-1.2 1-1.2 1.8v.4"
        fill="none"
      ></path>
    </StyledIcon>
  )
}
