import { MutationHookOptions } from '@apollo/client'
import produce from 'immer'
import {
  AppointmentBookMutation,
  AppointmentBookMutationVariables,
  AppointmentCancelMutation,
  AppointmentCancelMutationVariables,
  AppointmentsRescheduleMutation,
  AppointmentsRescheduleMutationVariables,
  CalendarGetEntriesDocument,
  CalendarGetEntriesQuery,
} from 'state/graphql'
import { useCalendarStore } from './calendarStore'
import {
  isWithinInterval,
  startOfDay,
  endOfDay,
  parse,
  parseISO,
} from 'date-fns'

/**
 * Apollo cache updater for appointmentBook mutation
 * Will update calendar entries in cache after booking an appointment
 * @param cache
 * @returns
 */
export const appointmentBookMutationUpdateCache: MutationHookOptions<
  AppointmentBookMutation,
  AppointmentBookMutationVariables
>['update'] = (cache, { data }) => {
  const calendarParams = useCalendarStore.getState()
  const { dateFrom, dateTo } = calendarParams
  if (!dateFrom || !dateTo) return

  const currentCalendarEntries = cache.readQuery<CalendarGetEntriesQuery>({
    query: CalendarGetEntriesDocument,
    variables: {
      startDate: dateFrom,
      endDate: dateTo,
    },
  })

  if (!currentCalendarEntries || !data?.appointment_book) return

  cache.writeQuery<CalendarGetEntriesQuery>({
    query: CalendarGetEntriesDocument,
    data: produce(currentCalendarEntries, draft => {
      draft.calendar.getEntries.push(
        ...data.appointment_book.filter(
          entry =>
            isWithinInterval(new Date(entry.startTimeUtc), {
              start: startOfDay(parseISO(dateFrom)),
              end: endOfDay(parseISO(dateTo)),
            }) &&
            !currentCalendarEntries.calendar.getEntries.find(
              e => e.id === entry.id
            )
        )
      )
    }),
    variables: {
      startDate: dateFrom,
      endDate: dateTo,
    },
  })
}

/**
 * Apollo cache updater for appointmentBook mutation
 * Will update calendar entries in cache after updating appointment.
 * Old entries removed from cache, new updated entries added to cache
 * @param cache
 * @returns
 */
export const appointmentRescheduleUpdateCache: MutationHookOptions<
  AppointmentsRescheduleMutation,
  AppointmentsRescheduleMutationVariables
>['update'] = (cache, { data }) => {
  const calendarParams = useCalendarStore.getState()
  const { dateFrom, dateTo } = calendarParams
  if (!dateFrom || !dateTo) return

  const currentCalendarEntries = cache.readQuery<CalendarGetEntriesQuery>({
    query: CalendarGetEntriesDocument,
    variables: {
      startDate: dateFrom,
      endDate: dateTo,
    },
  })

  if (!currentCalendarEntries || !data?.appointment_reschedule) return

  cache.writeQuery<CalendarGetEntriesQuery>({
    query: CalendarGetEntriesDocument,
    data: produce(currentCalendarEntries, draft => {
      //remove existing entries for the same appointment
      draft.calendar.getEntries = draft.calendar.getEntries.filter(
        e =>
          !data.appointment_reschedule.find(
            a => a.resourceBookingId === e.resourceBookingId
          )
      )

      //add received updated entries to the calendar
      draft.calendar.getEntries.push(
        ...data.appointment_reschedule.filter(entry =>
          isWithinInterval(new Date(entry.startTimeUtc), {
            start: startOfDay(parseISO(dateFrom)),
            end: endOfDay(parseISO(dateTo)),
          })
        )
      )
    }),
    variables: {
      startDate: dateFrom,
      endDate: dateTo,
    },
  })
}

/**
 * Apollo cache updater for appointmentCancel mutation
 * Will remove calendar entries for canceled appointment
 * @param cache
 * @returns
 */
export const appointmentCancelUpdateCache: MutationHookOptions<
  AppointmentCancelMutation,
  AppointmentCancelMutationVariables
>['update'] = (cache, { data }) => {
  const calendarParams = useCalendarStore.getState()
  const { dateFrom, dateTo } = calendarParams
  if (!dateFrom || !dateTo) return

  const currentCalendarEntries = cache.readQuery<CalendarGetEntriesQuery>({
    query: CalendarGetEntriesDocument,
    variables: {
      startDate: dateFrom,
      endDate: dateTo,
    },
  })

  if (!currentCalendarEntries || !data?.appointment_cancel) return
  const entriesToRemove = data.appointment_cancel.filter(
    e => e.status === 'canceled' && e.reason !== 'CLIENT_NO_SHOW'
  )
  cache.writeQuery<CalendarGetEntriesQuery>({
    query: CalendarGetEntriesDocument,
    data: produce(currentCalendarEntries, draft => {
      //remove existing entries for the same appointment
      draft.calendar.getEntries = draft.calendar.getEntries.filter(
        e =>
          !entriesToRemove.find(
            a => a.resourceBookingId === e.resourceBookingId
          )
      )
    }),
    variables: {
      startDate: dateFrom,
      endDate: dateTo,
    },
  })
}
