import React, { useState } from 'react'
import { useParams } from 'react-router'
import TemplateForm from './TemplateForm'
import { RouterOutput, trpc } from 'src/trpc'
import { Loader } from 'src/components'
import { useHeader } from 'src/mobile/Header'
import { useSdk } from 'src/sdk'
import { sumBy } from 'lodash'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import {
  formatPriceNumberWithDelimiter,
  getVoucherTerms,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { useLocationGetQuery } from 'src/state/graphql'

export const EditTemplate = () => {
  const params = useParams<{
    selectedLocationId: string
    orgId: string
    id: string
    type: string
  }>()
  const { selectedLocationId, orgId, id, type } = params
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const { navigateTo, appServices } = useSdk()

  const { data: locationData, loading: loadingLocationData } =
    useLocationGetQuery({
      variables: { locationId: selectedLocationId },
      fetchPolicy: 'network-only',
    })
  const location = locationData?.location?.get

  useHeader({
    content: 'Predložak',
    onGoBack: () => navigateTo.listTemplates({ voucherType: type }),
  })

  const updateGiftCardTemplateMutation =
    trpc.giftCardTemplate_update.useMutation()
  const updateGiftCardTemplateVoucherTermsMutation =
    trpc.giftCardTemplateVoucherTerms_update.useMutation()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const {
    data,
    isLoading,
    refetch: refetchGiftCardTemplate,
    isRefetching,
  } = trpc.giftCardTemplate_findOne.useQuery({
    orgId: orgId,
    locationId: BigInt(selectedLocationId),
    id: BigInt(id),
  })
  const giftCardTemplate = data as RouterOutput['giftCardTemplate_findOne']

  const handleSubmit = async values => {
    try {
      setIsBusy(true)

      const value =
        giftCardTemplate?.voucherType === 'GIFTCARD'
          ? parseFloat(values?.price?.toString().replace(',', '.'))
          : sumBy(giftCardTemplate.items, function (item: any) {
              return item?.Service?.price * item?.quantity
            })

      const input = {
        id: BigInt(id),
        name: values?.name,
        voucherType: giftCardTemplate?.voucherType || '',
        voucherDesc: values?.voucherDesc,
        voucherTerms: values?.voucherTerms,
        singleUse: values?.singleUse,
        validAmount: Number(values?.validAmount),
        validIterator: values?.validIterator?.id,
        price: parseFloat(values?.price?.toString().replace(',', '.')),
        value,
        isTransferable: values?.isTransferable,
        discountRate:
          roundNumberWithTwoDecimals(
            parseFloat(values?.discountRate.toString().replace(',', '.') || 0)
          ) / 100,
        vatRate: org?.vatStatus === 'Y' ? Number(values?.vatRate) / 100 : 0,
      }
      const res = await updateGiftCardTemplateMutation.mutateAsync(input)

      if (res) {
        appServices.toast.success('Predložak je uspješno izmijenjen')
        if (
          giftCardTemplate?.voucherType === 'VOUCHER' &&
          values?.autoGenerateTermsOfUse
        )
          await updateGiftCardTemplateVoucherTermsMutation.mutateAsync({
            id: BigInt(values?.id),
            voucherTerms: getVoucherTerms(
              {
                ...values,
                price: res?.price,
                value: res?.value,
              },
              location?.name
            ),
          })

        await refetchGiftCardTemplate()
      } else
        appServices.toast.danger(
          'Došlo je do greške prilikom spremanja predloška'
        )
    } catch (e) {
      appServices.toast.danger(
        'Došlo je do greške prilikom spremanja predloška'
      )
    } finally {
      setIsBusy(false)
    }
  }

  if (isLoading || isRefetching || loadingLocationData)
    return <Loader isComponent />

  return (
    <TemplateForm
      initialValues={{
        ...giftCardTemplate,
        discountRateCheckbox: giftCardTemplate?.discountRate
          ? parseFloat(giftCardTemplate?.discountRate?.toString()) > 0
          : false,
        voucherTerms: giftCardTemplate?.voucherTerms,
        price: giftCardTemplate?.price,
        value: giftCardTemplate?.value,
        discountRate: giftCardTemplate?.discountRate
          ? formatPriceNumberWithDelimiter(
              Number(giftCardTemplate?.discountRate) * 100,
              false
            ) || '0'
          : '0',
        vatRate: roundNumberWithTwoDecimals(
          Number(giftCardTemplate?.vatRate) * 100
        ),
      }}
      locationName={location?.name}
      hasVat={org?.vatStatus === 'Y'}
      currency={org?.currency?.id}
      onSubmit={handleSubmit}
      loading={isBusy || isLoading || loadingOrgData}
      isEdit={true}
      refetch={refetchGiftCardTemplate}
    />
  )
}

export default EditTemplate
