import React from 'react'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import { ResourceTypeEditorForm } from './ResourceTypeEditorForm'
import { ProfessionAllDocument, ProfessionKindEnum } from 'state/graphql'
import { useSdk } from 'sdk'
import { useProfessionAddMutation } from 'state/graphql'

const ResourceTypeValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.NewResource.validation-name-required')
    ),
  })

type TNewResourceTypeProps = {}

export const NewResourceType = (props: TNewResourceTypeProps) => {
  const { selectedLocationId } = useParams<any>()
  const { navigateTo, appServices, t } = useSdk()

  const [
    newResourceTypeAddMutation,
    { loading: loadingAddResourceType },
  ] = useProfessionAddMutation()

  const handleSubmit = async values => {
    const result = await newResourceTypeAddMutation({
      variables: {
        input: {
          name: values.name,
          kind: ProfessionKindEnum.Resource,
          // employees: [ID] => lista zaposlenika
        },
      },
      refetchQueries: [
        {
          query: ProfessionAllDocument,
        },
      ],
      awaitRefetchQueries: true,
    })

    if (!result.errors) {
      navigateTo.resourcesList({ selectedLocationId, selectedTab: 1 })
      appServices.toast.success(
        t('translation.ResourcesList.toast-resource-type-added')
      )
    } else {
      appServices.toast.danger(result?.errors?.[0]?.message)
    }
  }

  return (
    <ResourceTypeEditorForm
      initialValues={{
        name: '',
      }}
      validationSchema={ResourceTypeValidationSchema(t)}
      onSubmit={handleSubmit}
      loading={loadingAddResourceType}
      isEdit={false}
    />
  )
}
