import React, { useState, useEffect, useRef, useContext } from 'react'
import { Form, FormField, FormFooterContainer, Loader } from 'components'
import { FormsContainer } from 'src/styles'
import { EventBodyWrapper } from 'views/AppointmentModal/styles'
import { useSdk } from 'sdk'
import styled, { ThemeContext } from 'styled-components'
import Button, { ButtonStyled } from 'components/Button/Button'
import { device } from '../../constants'
import { useAppServices } from 'sdk/appServices'
import { FormFieldsWrapper, StyledFieldsContainer } from 'components/Form/Form'
import {
  getAttachmentImage,
  getISODate,
  useOnClickOutsideWithoutTriggeringAction,
} from 'src/helpers'
import Dropzone from 'react-dropzone'
import { FieldRow, FieldColumn } from 'views/Settings/styles'
import Webcam from 'react-webcam'
import {
  OrganizationAvatarRow,
  OrganizationAvatar,
} from 'views/SignupFlow/styles'
import {
  useClientUploadAttachmentMutation,
  useClientUpdateAttachmentMutation,
  useClientDeleteAttachmentsMutation,
  useClientGetQuery,
  ClientGetDocument,
} from 'state/graphql'
import { Download } from 'components/Icon/Icons/Download'
import Tooltip from 'components/Tooltip/Tooltip'
import { Edit } from 'components/Icon/Icons/Edit'
import { Checkbox, StyledCheck } from 'components/Checkbox/Checkbox'
import { CheckboxInput } from 'components/Form/Fields/Checkbox'
import { Trash } from 'components/Icon/Icons/Trash'
import { useHeader } from 'mobile/Header'
import { useParams } from 'react-router-dom'
import { SyncArrowsSquare } from 'components/Icon/Icons/SyncArrowsSquare'
import Share from 'components/Icon/Icons/Share'
import Cross from '../../components/Icon/Icons/Cross'
import { CameraOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { AttachmentsUpgradePage } from 'components/UpgradePage/UpgradePage'
import { useQuery } from '@apollo/client'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { icons } from 'components/Icon'
import moment from 'moment'
import { getCurrentLanguage } from 'src/localization'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import { useFeatureFlags } from 'src/state/local/appStore'

const MAX_SIZE = 5002880 * 2

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl)
  const blob: Blob = await res.blob()
  const file: File = new File([blob], fileName, { type: blob.type })

  return file
}

export const ClientAttachments = props => {
  const { t, navigateTo, isMobile } = useSdk()
  const { id, locationId } = useParams<any>()
  const {
    data: clientData,
    loading: loadingClient,
    refetch: refetchClient,
  } = useClientGetQuery({
    variables: {
      id,
    },
    skip: props?.client?.id,
  })

  const client = props.client ? props.client : clientData?.client?.get

  const [updateAttachment] = useClientUpdateAttachmentMutation()
  const [deleteAttachments] = useClientDeleteAttachmentsMutation()

  const formRef = useRef<any>(null)
  const imageInput = useRef<any>(null)

  const [attachmentFile, setAttachmentFile] = useState<File | null>(null)
  const [clientAttachments, setClientAttachments] = useState<any>([])
  const [isBusy, setIsBusy] = useState(false)

  const [attachmentPreview, setAttachmentPreview] = useState<any>([])
  const [uploadDrawerOpen, setUploadDrawerOpen] = useState<any>(false)
  const [isUpdateMode, setIsUpdateMode] = useState<any>(false)
  const [attachmentToUpdate, setAttachmentToUpdate] = useState<any>(null)
  const [selectedAttachments, setSelectedAttachments] = useState<any>([])
  const [cameraOrientation, setCameraOrientation] = useState<any>({
    exact: 'environment',
  })
  const [zoomedImage, setZoomedImage] = useState<any>(null)
  const [isCameraMode, setIsCameraMode] = useState<any>(false)
  const canShare =
    !!navigator.share &&
    //@ts-ignore
    !!navigator.canShare &&
    //@ts-ignore
    navigator.canShare({ files: [new File(['foo'], 'foo.txt')] })

  const appServices = useAppServices()
  const webcamRef = useRef<any>(null)
  const containerRef = useRef<any>()

  const { showPopup } = usePopup()

  const featureFlags = useFeatureFlags()
  const calendarVersion = featureFlags.calendarVersion

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef?.current?.getScreenshot()
    setAttachmentPreview([{ preview: imageSrc }])
    const file = await dataUrlToFile(imageSrc, `attachment_${Date.now()}.jpeg`)
    if (file.size > MAX_SIZE) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadError')
      )
      return
    } else {
      setAttachmentFile(file)
    }
  }, [webcamRef])

  const handleImageSelect = async acceptedFiles => {
    if (acceptedFiles.find(file => file.size > MAX_SIZE)) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadError')
      )
      return
    }
    setAttachmentFile(acceptedFiles[0])
    // dodat provjeru ako nije slika
    if (acceptedFiles?.[0]?.type?.includes('image')) {
      setAttachmentPreview(
        acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        })
      )
    } else if (acceptedFiles?.[0]?.type?.includes('pdf')) {
      setAttachmentPreview([{ preview: '/assets/pdf-logo.png' }])
    }
    formRef?.current?.setFieldValue('attachmentTitle', acceptedFiles[0].name)
  }

  const handleUpload = async e => {
    e.stopPropagation()
    e.preventDefault()
    if (!attachmentFile) return
    setIsBusy(true)

    const formData = new FormData()

    // file must be appended last for fastify-multipart to work
    formData.append('title', formRef?.current?.values?.attachmentTitle)
    formData.append(
      'description',
      formRef?.current?.values?.attachmentDescription || ''
    )
    formData.append('clientId', client?.id)
    formData.append('locationId', locationId)
    formData.append('file', attachmentFile)

    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/api/upload/${orgId}/clientAttachmentUpload`,
      {
        method: 'POST',
        body: formData,
        credentials: 'include',
      }
    )
    const result = await response.json()

    if (!response.ok || !result?.success) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadWarning')
      )
    } else {
      appServices.toast.success(
        t('translation.AppointmentAttachments.uploadSuccess')
      )
      setAttachmentFile(null)
      setAttachmentPreview([])
      formRef?.current?.setFieldValue('attachmentTitle', '')
      formRef?.current?.setFieldValue('attachmentDescription', '')
      formRef?.current?.resetForm({ values: formRef?.current?.values })
      await refetchClient()
    }

    setIsBusy(false)
  }

  const handleUpdate = async e => {
    e.stopPropagation()
    e.preventDefault()
    if (!attachmentToUpdate) return
    setIsBusy(true)
    const result = await updateAttachment({
      variables: {
        input: {
          id: attachmentToUpdate?.id,
          title: formRef?.current?.values?.attachmentTitle,
          description: formRef?.current?.values?.attachmentDescription,
        },
      },
      refetchQueries: [
        {
          query: ClientGetDocument,
          variables: { id: client?.id },
        },
      ],
    })
    if (!result?.errors?.length) {
      appServices.toast.success(
        t('translation.AppointmentAttachments.uploadEditSuccess')
      )

      setIsUpdateMode(false)
      setUploadDrawerOpen(false)
      setAttachmentToUpdate(null)
      setAttachmentPreview([])
      formRef?.current?.setFieldValue('attachmentTitle', '')
      formRef?.current?.setFieldValue('attachmentDescription', '')
    } else {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.doslo-je-do-pogreske')
      )
    }
    setIsBusy(false)
  }

  const handleDelete = async () => {
    if (!selectedAttachments?.length) return
    setIsBusy(true)
    const result = await deleteAttachments({
      variables: {
        attachmentIds: selectedAttachments,
      },
      refetchQueries: [
        {
          query: ClientGetDocument,
          variables: { id: client?.id },
        },
      ],
    })
    if (!result?.errors?.length) {
      appServices.toast.success(
        t('translation.AppointmentAttachments.privitci-uspjesno-obrisani')
      )
      setSelectedAttachments([])
    } else {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.doslo-je-do-pogreske')
      )
    }
    setIsBusy(false)
  }

  const handleShare = async attachment => {
    if (!canShare) return
    try {
      const file = await dataUrlToFile(
        attachment.downloadUrl,
        attachment.fileName
      )

      navigator
        .share({
          title: attachment.title || attachment.fileName,
          text: '',
          files: [file],
        } as any)
        .then(() =>
          appServices.toast.success(
            t('translation.AppointmentAttachments.attachmentShareSuccess')
          )
        )
        .catch(error =>
          //appServices.toast.success('Došlo je do pogreške pri dijeljenju')
          console.log(error)
        )
    } catch (err) {
      appServices.toast.success(
        t('translation.AppointmentAttachments.attachmentShareError')
      )
    }
  }

  useEffect(() => {
    setClientAttachments(client?.attachments)
  }, [client])

  const handleDrawerAction = (action: 'open' | 'close') => {
    setUploadDrawerOpen(action === 'open' ? true : false)
    setIsUpdateMode(false)
    setAttachmentFile(null)
    setAttachmentPreview([])
    setAttachmentToUpdate(null)
  }

  useHeader({
    content: t('translation.ClientAttachments.privitci-klijenta'),
    onGoBack: () => navigateTo.clientDetails({ id: client?.id }),
  })

  const selectedTheme = useContext(ThemeContext)
  const { orgId } = useParams<any>()
  const { data: organizationData, loading: loadingOrg } = useQuery(
    getOrganizationByIdGql,
    { variables: { id: orgId } }
  )

  const org = organizationData?.organization?.get

  const wrapperRef = useRef<any>()

  useOnClickOutsideWithoutTriggeringAction(wrapperRef, () =>
    setZoomedImage(null)
  )
  useEffect(() => {
    const handleKeyPressActions = event => {
      if (event.keyCode === 27) setZoomedImage(null)
    }
    window.addEventListener('keydown', handleKeyPressActions)

    return () => {
      window.removeEventListener('keydown', handleKeyPressActions)
    }
  }, [])

  if (org?.subscriptionPlan === 'free') {
    return (
      <AttachmentsUpgradePage
        isAppointmentModal={false}
        closeModal={() => {}}
      />
    )
  }
  return (
    <ClientAttachmentsWrapper>
      <EventBodyWrapper>
        {isBusy || loadingOrg || loadingClient ? (
          <Loader isComponent={false} />
        ) : null}
        <FormsContainer>
          {uploadDrawerOpen ? (
            <FormFieldsWrapperAttachments>
              <AttachmentsFormContainer>
                <Form
                  initialValues={{
                    ...client,
                    attachmentTitle: attachmentToUpdate?.title || '',
                    attachmentDescription:
                      attachmentToUpdate?.description || '',
                    attachmentId: attachmentToUpdate?.id || '',
                  }}
                  onSubmit={handleUpload}
                >
                  {form => {
                    formRef.current = form
                    return (
                      <>
                        <FieldRow style={{ alignItems: 'flex-start' }}>
                          <AttachmentColumn>
                            <OrganizationAvatarRow
                              style={{ width: '100%', marginBottom: 'unset' }}
                            >
                              {isCameraMode ? (
                                <>
                                  <WebcamWrapper>
                                    <Webcam
                                      style={{ height: 220, width: 'inherit' }}
                                      audio={false}
                                      ref={webcamRef}
                                      //mirrored={cameraOrientation === 'user'}
                                      minScreenshotHeight={1920}
                                      minScreenshotWidth={1080}
                                      screenshotQuality={1}
                                      videoConstraints={{
                                        facingMode: isMobile
                                          ? cameraOrientation
                                          : 'user',
                                        height: {
                                          min: 720,
                                          ideal: 1920,
                                          max: 1920,
                                        },
                                        width: { min: 720, ideal: 1080 },
                                        aspectRatio: 1.777777778,
                                      }}
                                      screenshotFormat="image/jpeg"
                                    />

                                    {isMobile ? (
                                      <BottomIcon
                                        onClick={e => {
                                          e.stopPropagation()
                                          setCameraOrientation(
                                            typeof cameraOrientation ===
                                              'object'
                                              ? 'user'
                                              : { exact: 'environment' }
                                          )
                                        }}
                                        style={{
                                          position: 'absolute',
                                          top: 10,
                                          right: 10,
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <SyncArrowsSquare
                                          color={'#333'}
                                          size={'small'}
                                          style={{ marginRight: 'auto' }}
                                        />
                                      </BottomIcon>
                                    ) : null}
                                  </WebcamWrapper>
                                  <div
                                    style={{
                                      justifyContent: 'space-between',
                                      display: 'flex',
                                      paddingTop: 20,
                                    }}
                                  >
                                    <Button
                                      onClick={event => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        capture()
                                        setIsCameraMode(false)
                                      }}
                                      buttonType={'secondaryLink'}
                                      label={t(
                                        'translation.AppointmentAttachments.capture'
                                      )}
                                      iconComponent={<CameraOutlined />}
                                      name="captureButton"
                                    />
                                    <Button
                                      onClick={event => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        setIsCameraMode(false)
                                        setAttachmentFile(null)
                                        setAttachmentPreview([])
                                      }}
                                      name="backButton"
                                      label={t('translation.App.label-back')}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <AttachmentPreviewImage
                                    src={
                                      attachmentPreview[0]
                                        ? attachmentPreview[0].preview
                                        : attachmentToUpdate
                                        ? attachmentToUpdate.thumbnailUrl
                                        : getAttachmentImage(client)
                                    }
                                  />
                                  {!isUpdateMode ? (
                                    <div
                                      style={{
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        paddingTop: 20,
                                      }}
                                    >
                                      <Button
                                        onClick={event => {
                                          event.preventDefault()
                                          event.stopPropagation()
                                          imageInput?.current?.open()
                                        }}
                                        buttonType={'secondary'}
                                        disabled={isUpdateMode}
                                        label={t(
                                          'translation.AppointmentAttachments.prenesi'
                                        )}
                                        iconComponent={
                                          <CloudDownloadOutlined />
                                        }
                                        name="uploadButton"
                                      />
                                      <Button
                                        onClick={event => {
                                          event.preventDefault()
                                          event.stopPropagation()
                                          setIsCameraMode(true)
                                        }}
                                        disabled={isUpdateMode}
                                        name="goToCaptureButton"
                                        iconComponent={<CameraOutlined />}
                                        label={t(
                                          'translation.AppointmentAttachments.slikaj'
                                        )}
                                      />
                                    </div>
                                  ) : null}

                                  <Dropzone
                                    ref={imageInput}
                                    onDrop={handleImageSelect}
                                    accept="image/jpeg, image/png, application/pdf"
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                      </div>
                                    )}
                                  </Dropzone>
                                </>
                              )}
                            </OrganizationAvatarRow>
                          </AttachmentColumn>
                          <FieldColumn
                            style={{
                              flexDirection: 'column',
                            }}
                          >
                            <FieldRow>
                              <FieldColumn>
                                <FormField.Text
                                  label={t(
                                    'translation.LocationGeneralInfo.label-name'
                                  )}
                                  name="attachmentTitle"
                                  autoFocus
                                />
                              </FieldColumn>
                            </FieldRow>
                            <FieldRow>
                              <FieldColumn>
                                <FormField.Text
                                  label={t(
                                    'translation.LocationGeneralInfo.label-description'
                                  )}
                                  name="attachmentDescription"
                                />
                              </FieldColumn>
                            </FieldRow>
                            <FormButtons>
                              <Button
                                footerButton
                                type="button"
                                buttonType={'text'}
                                label={t(
                                  'translation.LocationGeneralInfo.button-close'
                                )}
                                name="closeUploadDrawerButton"
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  handleDrawerAction('close')
                                }}
                              />

                              <Button
                                footerButton
                                type="button"
                                buttonType={'secondaryLink'}
                                label={t(
                                  'translation.LocationGeneralInfo.button-save'
                                )}
                                disabled={
                                  !isUpdateMode ? !attachmentFile : false
                                }
                                name="saveChanges"
                                onClick={
                                  isUpdateMode ? handleUpdate : handleUpload
                                }
                              />
                            </FormButtons>
                          </FieldColumn>
                        </FieldRow>
                      </>
                    )
                  }}
                </Form>
              </AttachmentsFormContainer>
            </FormFieldsWrapperAttachments>
          ) : null}
          {!uploadDrawerOpen && (
            <AttachmentUploadButton>
              <Button
                type="button"
                buttonType={'secondaryLink'}
                label={t('translation.AppointmentAttachments.dodaj-privitak')}
                name="addAttachment"
                onClick={() => handleDrawerAction('open')}
                iconComponent={<icons.Add size={'smaller'} />}
              />
            </AttachmentUploadButton>
          )}
          {selectedAttachments?.length ? (
            <AttachmentHeader
              style={{
                fontSize: isMobile ? 13 : 14,
                fontWeight: 400,
                paddingBottom: 0,
              }}
            >
              {t('translation.AppointmentAttachments.selectedAttachments', {
                selected: selectedAttachments?.length,
                total: clientAttachments?.length,
              })}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    footerButton
                    type="button"
                    buttonType={'text'}
                    label={t('translation.AppointmentAttachments.odznaci-sve')}
                    size={'small'}
                    name="unselectAll"
                    onClick={() => setSelectedAttachments([])}
                    iconComponent={<Cross size={'smaller'} />}
                    hasIconOnly
                    style={{ marginRight: 10 }}
                  />
                  <Button
                    footerButton
                    type="button"
                    buttonType={'text'}
                    size={'small'}
                    label={'Obriši'}
                    hasIconOnly
                    iconComponent={
                      <Trash
                        size="smaller"
                        stroke={2}
                        color={selectedTheme.colors.accent1}
                        style={{ width: 22, height: 22 }}
                      />
                    }
                    name="deleteAll"
                    onClick={() =>
                      showPopup({
                        onConfirm: () => handleDelete(),
                        titleText: t(
                          'translation.ClientAttachments.jeste-li-sigurni-da-zelite-obrisati-odabrane-privitke'
                        ),
                      })
                    }
                  />
                </div>
              </div>
            </AttachmentHeader>
          ) : null}

          <AttachmentItemsGrid>
            {clientAttachments?.map(attachment => {
              return (
                <AttachmentItemWrapper
                  isClickable
                  isClient
                  key={attachment?.id}
                >
                  <AttachmentItemLink
                    isClickable
                    onClick={async () => {
                      const isSelected = selectedAttachments.find(
                        x => x === attachment.id
                      )
                      isSelected
                        ? setSelectedAttachments(prev =>
                            prev.filter(x => x !== attachment.id)
                          )
                        : setSelectedAttachments(prev => [
                            ...prev,
                            attachment.id,
                          ])
                    }}
                  >
                    <AttachmentItemImage
                      isClient
                      isPdf={attachment.mimeType === 'application/pdf'}
                      src={attachment?.thumbnailUrl}
                    />
                    {attachment.mimeType !== 'application/pdf' ? (
                      <div
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          setZoomedImage(attachment?.thumbnailUrl)
                        }}
                        className="absolute top-[5px] left-[5px] bg-black/10 rounded-lg w-7 h-7 flex justify-center items-center shadow-[0_0_5px_rgba(0,0,0,0.1)] hover:bg-black/30 transition"
                      >
                        <icons.Search
                          size="size16"
                          className="text-white stroke-0"
                        />
                      </div>
                    ) : null}
                    {selectedAttachments.find(x => x === attachment.id) ? (
                      <CheckboxWrapper>
                        <Checkbox
                          checked={true}
                          style={{ position: 'absolute', top: 10, right: 10 }}
                        />
                      </CheckboxWrapper>
                    ) : null}

                    <AttachmentItemInfo>
                      <p className="text-sm text-zoyya-secondaryDark relative top-[2px] my-1">
                        {moment(attachment?.createdAt).format('DD.MM.YYYY.')}
                      </p>
                      <p className="font-medium text-zoyya-text text-left break-words lg:mb-2">
                        {attachment.title}
                      </p>
                      {attachment.description ? (
                        <AttachmentItemInfoSubtitle>
                          {attachment.description}
                        </AttachmentItemInfoSubtitle>
                      ) : null}
                    </AttachmentItemInfo>
                  </AttachmentItemLink>

                  <BottomSectionButtonsWrapper>
                    <BottomIcon
                      onClick={e => {
                        e.stopPropagation()
                        setAttachmentToUpdate(attachment)
                        setUploadDrawerOpen(true)
                        setAttachmentFile(null)
                        setAttachmentPreview([])
                        setIsUpdateMode(true)
                        setIsCameraMode(false)
                        containerRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })
                      }}
                    >
                      <Tooltip label={t('translation.ClientAttachments.edit')}>
                        <Edit
                          color={'#6a4ee1'}
                          size={'smaller'}
                          style={{ marginRight: 'auto' }}
                        />
                      </Tooltip>
                    </BottomIcon>
                    {canShare ? (
                      <BottomShareIcon
                        onClick={e => {
                          e.stopPropagation()
                          handleShare(attachment)
                        }}
                      >
                        <Tooltip
                          label={t('translation.ClientAttachments.share')}
                        >
                          <Share
                            color={'#6a4ee1'}
                            size={'smaller'}
                            stroke={1.7}
                            style={{ marginRight: 'auto' }}
                          />
                        </Tooltip>
                      </BottomShareIcon>
                    ) : null}
                    <BottomIcon
                      href={attachment.downloadUrl}
                      target="_blank"
                      download={true}
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <Tooltip
                        label={t('translation.ClientAttachments.download')}
                      >
                        <Download
                          color={'#6a4ee1'}
                          size={'smaller'}
                          style={{ marginRight: 'auto' }}
                        />
                      </Tooltip>
                    </BottomIcon>
                  </BottomSectionButtonsWrapper>

                  <OpenInCalendarWrapper
                    style={{
                      marginBottom:
                        attachment?.appointmentId ||
                        attachment?.bookingRequestId ||
                        isMobile
                          ? '0'
                          : '40px',
                    }}
                  >
                    {attachment?.appointmentId ||
                    attachment?.bookingRequestId ? (
                      <Button
                        buttonType={'link'}
                        name={'saveChanges'}
                        label={t(
                          'translation.ClientAttachments.open-in-calendar'
                        )}
                        iconComponent={<icons.ArrowRight />}
                        iconRight
                        onClick={() => {
                          const selectedHour = !attachment?.appointmentId
                            ? moment(attachment?.resourceBooking?.startTime)
                                .locale(getCurrentLanguage())
                                .format('HH:mm')
                                .split(':')
                            : attachment?.appointment?.startTime?.split(':')
                          navigateTo.dailyCalendarAppointment({
                            date: getISODate(
                              !attachment?.appointmentId
                                ? attachment?.resourceBooking?.date
                                : attachment?.appointment?.date
                            ),
                            reservationId: !attachment?.appointmentId
                              ? attachment?.bookingRequestId
                              : attachment?.appointmentId,
                            hour: selectedHour[0],
                            minute: selectedHour[1],
                            type:
                              calendarVersion === 'v2'
                                ? 'appointment'
                                : !attachment?.appointmentId
                                ? 'booking'
                                : 'appointment',
                          })
                        }}
                      />
                    ) : null}
                  </OpenInCalendarWrapper>
                </AttachmentItemWrapper>
              )
            })}
            {zoomedImage && (
              <div className="fixed lg:bg-black/50 bg-white/80 h-screen w-full top-0 left-0 flex items-center justify-center z-[9]">
                <div className="relative lg:w-[70vw] lg:h-[70vh] w-11/12">
                  <div
                    onClick={() => setZoomedImage(null)}
                    className="absolute lg:top-[-15px] top-[5px] lg:right-0 right-[5px] bg-black/30 rounded-lg w-7 h-7 flex justify-center items-center hover:bg-black/50 transition cursor-pointer"
                  >
                    <icons.Cross
                      size="size14"
                      className="text-white !stroke-2"
                    />
                  </div>
                  <div className="lg:max-w-full lg:h-full">
                    <img
                      ref={wrapperRef}
                      src={zoomedImage}
                      className="rounded-lg w-full lg:h-full lg:object-contain"
                    />
                  </div>
                </div>
              </div>
            )}
          </AttachmentItemsGrid>
        </FormsContainer>
      </EventBodyWrapper>
    </ClientAttachmentsWrapper>
  )
}

export const AttachmentItemWrapper = styled.div<{
  isClient?: boolean
  isClickable: boolean
}>`
  display: flex;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: space-between;
  border-style: none;
  border-width: 1px;
  border-color: rgb(210, 210, 245);
  border-radius: 5px;
  border: 1px solid #edeef4;
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: ease;
  //max-height: 240px;
  cursor: ${props => (props.isClickable ? 'pointer' : undefined)};
  background: ${props => props.theme.colors.light};

  :hover {
    transform: ${props =>
      props.isClickable ? 'translate(0px, -5px)' : undefined};
  }

  max-width: ${props => (props.isClient ? '250px' : '180px')};
  width: 100%;

  @media ${device.tablet} {
    margin-right: 0px;
    min-width: 100%;
  }
`
export const OpenInCalendarWrapper = styled.div<any>`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`
export const AttachmentItemImage = styled.img<any>`
  object-fit: cover;
  display: flex;
  width: 100%;
  height: ${props => (props.isClient ? '200px' : '120px')};
  border-radius: 5px 5px 0px 0px;
  background-position: center;
  padding: ${props => (props.isPdf ? '10px' : null)};
  object-fit: ${props => (props.isPdf ? 'contain' : 'cover')};
  @media ${device.tablet} {
    min-height: 174px;
    height: 174px;
  }
`
export const AttachmentPreviewImage = styled<any>(OrganizationAvatar)`
  height: 172px;
  object-fit: cover;
  border: 1px solid #eeeeee;
  background: ${props => props.theme.colors.light};
`

export const AttachmentItemInfo = styled.div`
  display: flex;
  height: auto;
  //padding: 8px 10px 10px;
  padding: 0 10px;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 0%;
  color: rgb(102, 102, 102);
  text-decoration: none;
  width: 100%;

  background: #fafaff;

  @media ${device.tablet} {
  }
`

export const AttachmentItemInfoTitle = styled.p<any>`
  font-weight: 500;
  color: ${props => props.theme.colors.text};
  //margin-bottom: 5px;
  text-align: left;
  word-break: break-word;
  font-size: 14px;
  padding: 10px 0;
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const AttachmentItemInfoSubtitle = styled.p`
  font-size: 13px;
  padding-bottom: 10px;
  font-weight: 300;
  line-height: 1.2;
  color: ${props => props.theme.colors.text};
  @media ${device.tablet} {
    line-height: 1.3;
  }
`
export const AttachmentItemLink = styled.a<{ isClickable: boolean }>`
  display: flex;
  position: relative;
  height: 100%;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  transition: ${props =>
    props.isClickable ? 'transform 330ms ease-in-out' : undefined};
  :active {
    transform: ${props => (props.isClickable ? 'scale(0.93)' : undefined)};
  }
`
export const AttachmentItemsGrid = styled.div<any>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 7px 20px;
  padding-top: 20px;
  //min-height: 320px;
  //height: calc(100% - 57px);
  max-height: 100%;
  overflow: auto;
  max-width: 100%;
  margin: auto;
  padding-bottom: 30px;
  padding-top: 20px;
  //background: ${props => props.theme.colors.lightBackground};
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }

  @media ${device.tablet} {
    padding: 10px 15px 0px 15px;
    //padding-bottom: 90px;
    grid-template-columns: 1fr;
    column-gap: 0px;
    //height: 100%;
    display: flex;
    overflow: auto;
    min-height: 0;
    margin: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const AttachmentItemsGridColumn = styled(AttachmentItemsGrid)`
  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 15px;
  }
`
export const ChevronIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  svg {
    color: ${props => props.theme.colors.primary};
  }
`
export const BottomSectionButtonsWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.outline};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 10px;
`
export const BottomIcon = styled.a`
  text-decoration: underline;
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
  font-family: 'DM Sans', serif;
`
export const BottomShareIcon = styled.div`
  text-decoration: underline;
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
  font-family: 'DM Sans', serif;
`
export const WebcamWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 220px;
  border-radius: 0.286rem;
  border: 1px solid ${props => props.theme.colors.outline};
`

export const CheckboxWrapper = styled.div`
  background: transparent;
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  input[type='checkbox'] {
    :before {
      border-radius: 100px;
      width: 30px;
      height: 30px;
      @media ${device.tablet} {
        width: 20px;
        height: 20px;
      }
    }
    :after {
      top: 6px;
      left: 11px;
      width: 7px;
      height: 15px;
      @media ${device.tablet} {
        top: 4px;
        left: 7px;
        width: 5px;
        height: 11px;
      }
    }
  }

  ${CheckboxInput} {
    margin-right: 30px !important;
    height: 22px !important;
    width: 5px !important;
    margin-top: -10px !important;
  }

  ${StyledCheck} {
    margin-bottom: 20px !important;
    margin-left: 20px !important;
    top: 16px !important;
    @media ${device.tablet} {
      top: 12px !important;
      right: -3px !important;
    }
  }
`
export const AttachmentHeader = styled.div`
  display: flex;
  padding: 20px;
  padding-right: 0px;
  width: 100%;
  //border-bottom: 1px solid #edeef4;
  font-size: 20px;
  font-weight: 600;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  // height: 57px;
  // max-height: 57px;
  // min-height: 57px;
  //position: sticky;
  top: 0;
  background: white;
  //z-index: 9999;
  @media ${device.tablet} {
    padding: 0 20px;
    padding-right: 0;
  }
`
export const FormFieldsWrapperAttachments = styled(FormFieldsWrapper)`
  background: #f4f4ff7d;
  border: 1px solid rgb(237, 238, 244);
  margin: 20px;
  border-radius: 4px;
  margin-bottom: 0px;
  padding: 0px 20px 10px;
  @media ${device.tablet} {
    padding: 0 15px;
    // position: absolute;
    // height: calc((var(--vh, 1vh) * 100 - 84px));
    min-width: 100vw;
    background: white;
    z-index: 9999;
    margin: -10px 0px;
    border: 0;
    margin-bottom: 30px;
  }
`

export const ClientAttachmentsWrapper = styled.div<any>`
  display: flex;
  overflow: hidden;
  ${AttachmentItemsGrid} {
    display: flex;
    flex-wrap: wrap;
  }

  ${StyledFieldsContainer} {
    background: none;
  }

  @media ${device.tablet} {
    ${FieldRow} {
      margin-bottom: 0px;
    }

    ${FormFooterContainer} {
      position: absolute;
      bottom: 0px;
      width: 100%;
      z-index: 9999999;

      button[disabled] {
        margin-left: -1px;
      }
    }
  }
`

type TAttachmentUploadButtonProps = {}
export const AttachmentUploadButton = styled.div<TAttachmentUploadButtonProps>`
  ${ButtonStyled} {
    margin: 20px auto 0 20px;

    @media ${device.tablet} {
      margin: 10px auto 10px 15px;
    }
  }
`

type TAttachmentColumnProps = {}
export const AttachmentColumn = styled(FieldColumn)<TAttachmentColumnProps>`
  max-width: 250px;
  margin-right: 20px;
  padding-right: 0px !important;

  @media ${device.tablet} {
    max-width: 100%;
  }
`

type TFormButtonsProps = {}
export const FormButtons = styled.div<TFormButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  margin-bottom: 0px;

  @media ${device.tablet} {
    margin-top: auto;
    //position: absolute;
    bottom: 20px;
    right: 20px;
  }
`

type TAttachmentsFormContainerProps = {}
export const AttachmentsFormContainer = styled.div<TAttachmentsFormContainerProps>`
  margin-bottom: 0px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding: 10px;
    padding-bottom: 0;
    background: rgba(244, 244, 255, 0.49);
    border: 1px solid rgb(237, 238, 244);
    margin-top: 10px;
  }
`
