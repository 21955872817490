import React from 'react'
import styled from 'styled-components'
import * as styles from './style'
const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`
export function Sms(props) {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 1C5.649 1 .5 5.253.5 10.5h0a8.738 8.738 0 003.4 6.741L1.5 23l6.372-3.641h0C9.207 19.784 10.599 20 12 20c6.351 0 11.5-4.253 11.5-9.5S18.351 1 12 1z"
      ></path>
    </StyledIcon>
  )
}
