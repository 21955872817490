import { useEffect } from 'react'

export const GoogleAnalytics = ({ trackingId, enabled }) => {
  useEffect(() => {
    if (enabled && trackingId) {
      // Load the Google Analytics script
      const script1 = document.createElement('script')
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
      script1.async = true
      script1.id = 'ga_script1'
      document.head.appendChild(script1)

      const script2 = document.createElement('script')
      script2.id = 'ga_script2'
      script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingId}');
          
          gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
          });
        `
      document.head.appendChild(script2)
    }
  }, [enabled, trackingId])

  return null
}
