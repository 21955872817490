import { ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import omitDeep from 'omit-deep-lodash'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { getAuthToken } from 'sdk/Auth'
import { getSessionId } from 'sdk/session'
import { sha256 } from 'crypto-hash'
import { onError } from '@apollo/client/link/error'
//@ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString()
}

import { createUploadLink } from 'apollo-upload-client'
import { browserLogs } from './sdk/browserLogs'
import { round } from 'lodash'
const undefinedToNullReplacer = (key, value) =>
  value === undefined ? null : value
function replaceNull(obj) {
  //^ because you seem to want to replace (strings) "null" or "undefined" too
  return JSON.parse(JSON.stringify(obj, undefinedToNullReplacer))
}
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename'] // more keys like timestamps could be included here
  const def = getMainDefinition(operation.query) as any

  //check if mutation operation and it is not a file upload as variables must not be changed for uploads
  //upload is detected if there is a variable property named "file"
  if (
    def &&
    def.operation === 'mutation' &&
    def.operation &&
    !operation.variables?.file
  ) {
    const removeTypeNameVars = omitDeep(operation.variables, keysToOmit)
    const nullNormalized = replaceNull(removeTypeNameVars)
    operation.variables = nullNormalized
  }
  return forward ? forward(operation) : null
})
// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, name, message, locations, path }) => {
      browserLogs.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  if (networkError) {
    browserLogs.error(`[Network error]: ${networkError}`)
  }
})
/**
 * provides Apollo link with custom http headers
 * see: https://www.apollographql.com/docs/react/networking/authentication/
 */
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken()
  const orgId = localStorage.getItem('orgId')
  const language = localStorage.getItem('i18nextLng')
  const locationId =
    sessionStorage.getItem('locationId') || localStorage.getItem('locationId')
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
      'x-orgId': orgId,
      'x-locationId': locationId,
      'x-session-id': getSessionId(),
      'accept-culture': language,
      'x-integration-source': `web-b2b${
        import.meta.env.VITE_VERSION_NUMBER !== '#{version}#'
          ? `-v${import.meta.env.VITE_VERSION_NUMBER}`
          : ''
      }`,
    },
  }
})

const roundTripLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  operation.setContext({ start: new Date() })

  return forward(operation).map(data => {
    const { getContext, operationName, variables } = operation
    // Called after server responds
    const time = (new Date() as any) - getContext().start
    browserLogs.info(
      `[GraphQL operation]:  ${operationName} took ${round(time, 2)} ms`,
      { duration: time, variables, operationName }
    )

    return data
  })
})

const uploadLink =
  import.meta.env.VITE_USE_PERSISTED_QUERY === 'false'
    ? createUploadLink({
        uri: import.meta.env.VITE_API_URL,
        credentials: 'include',
        fetchOptions: {
          credentials: 'include',
        },
      })
    : createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
        createUploadLink({
          uri: import.meta.env.VITE_API_URL,
          credentials: 'include',
          fetchOptions: {
            credentials: 'include',
          },
        })
      )

// const uploadLink = createUploadLink({
//   uri: import.meta.env.VITE_API_URL,
// }
// )

// const operationLink = roundTripLink.concat(
//   errorLink.concat(cleanTypenameLink.concat(authLink.concat(uploadLink) as any))
// )
const operationLink = from([
  errorLink,
  roundTripLink,
  cleanTypenameLink,
  authLink,
  uploadLink,
])

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          service: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          appointment: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          client: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          serviceType: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          user: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          invitation: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          organization: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          location: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          resource: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          country: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          currency: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          resourceTimeSheet: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          resourceBooking: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          Holiday: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          payment: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          calendar: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          BusinessCategory: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          report: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          config: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          prepayments: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
      Mutation: {
        fields: {
          services: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          serviceType: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          user: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          invitation: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },

          organization: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          location: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          resource: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          resourceTimeSheet: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          resourceBooking: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          profession: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          Holiday: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          payment: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          calendar: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          BusinessCategory: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
          prepayments: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
    },
  }),
  link: operationLink,

  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
    watchQuery: { errorPolicy: 'all' },
  },
})
