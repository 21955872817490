import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from 'formik'
import { useFormContext } from 'components/Form/Form'
import { FieldContainer } from 'components/Form/FieldContainer'

import ReactQuill from 'react-quill'

export const FormFieldRichTextEditor = props => {
  const { label, helpText, helpLink, val } = props
  const [field, meta] = useField(props)
  const [isMounted, setIsMounted] = useState(false)

  //bug in react-quill grabs focus on mount, so we need to wait until it's mounted
  //https://github.com/zenoamaro/react-quill/issues/317
  useEffect(() => {
    setIsMounted(true)
  }, [])
  const formContext = useFormContext()
  const handleChange = useCallback(
    value => {
      formContext?.valueChange(field, '', value)
    },
    [formContext, field]
  )
  const editorContainerRef = useRef()

  return (
    <FieldContainer
      label={label}
      error={meta.error}
      fieldName={field.name}
      type="richText"
      helpText={helpText}
      helpLink={helpLink}
    >
      {/* @ts-ignore */}
      <div ref={editorContainerRef}>
        {isMounted ? (
          <ReactQuill
            theme="snow"
            value={val}
            onChange={handleChange}
            bounds={editorContainerRef?.current}
          />
        ) : null}
      </div>
    </FieldContainer>
  )
}
