import React, { useState } from 'react'
import { ChevronRight } from 'src/components/Icon/Icons/ChevronRight'

type TSidebarLeftItemsProps = {
  isBottom?: boolean
  orgSidebar?: boolean
  id?: string
  children: any
}

export const SidebarLeftItems = (props: TSidebarLeftItemsProps) => {
  const { orgSidebar, isBottom, id, children } = props

  return (
    <div
      id={id}
      className={`flex items-center text-zoyya-light flex-col ${
        isBottom ? 'mb-0' : 'mb-[auto]'
      } ${isBottom ? 'mt-[auto]' : 'mt-0'} ${
        orgSidebar ? 'bg-zoyya-orgSettingsSidebar' : 'bg-zoyya-primary'
      }`}
    >
      {children}
    </div>
  )
}

type TSidebarWrapperProps = {
  isCollapsed?: boolean
  dataIntercomTarget?: string
  children: any
}

export const SidebarWrapper = (props: TSidebarWrapperProps) => {
  const { isCollapsed, dataIntercomTarget, children } = props

  return (
    <div
      data-intercom-target={dataIntercomTarget}
      className={`flex justify-center relative flex-col h-full bg-[#edeef4] ${
        isCollapsed ? 'min-w-0' : 'min-w-[250px]'
      } ${isCollapsed ? 'max-w-0' : 'max-w-[250px]'} ${
        isCollapsed ? 'w-0' : 'w-[auto]'
      }`}
    >
      {children}
    </div>
  )
}

type TSidebarNoAvatarStyledProps = {
  background: string
  onClick?: () => void
  children: any
}

export const SidebarNoAvatarStyled = (props: TSidebarNoAvatarStyledProps) => {
  const { background, onClick, children } = props

  return (
    <div
      onClick={onClick}
      className={`bg-[${background}] w-[40px] h-[40px] flex justify-center items-center border-[1px] bg-zoyya-light rounded-[4px] font-normal text-[18px] leading-[1.143rem] text-center text-zoyya-light`}
    >
      {children}
    </div>
  )
}

type TSidebarLeftItemTooltipProps = {
  orgSidebar?: boolean
  children: any
}

export const SidebarLeftItemTooltip = (props: TSidebarLeftItemTooltipProps) => {
  const { orgSidebar, children } = props

  return (
    <div
      className={`text-zoyya-light ${
        orgSidebar ? 'bg-zoyya-orgSettingsSidebar' : 'bg-zoyya-primary'
      }`}
    >
      {children}
    </div>
  )
}

type TSidebarHintProps = {
  textCentered?: any
  children: any
}

export const SidebarHint = (props: TSidebarHintProps) => {
  const { textCentered, children } = props

  return (
    <span
      className={`text-zoyya-secondaryDark lg:text-[inherit] text-[0.9rem] lg:text-[8px] max-w-[224px] overflow-hidden text-ellipsis whitespace-nowrap mt-[initial] lg:mt-[5px] leading-[1rem] lg:leading-[12px] ${
        textCentered ? 'text-center' : 'text-left'
      }`}
    >
      {children}
    </span>
  )
}

type TSidebarIconWrapperProps = {
  children: any
}

export const SidebarIconWrapper = (props: TSidebarIconWrapperProps) => {
  const { children } = props

  return (
    <div className={`flex flex-col items-center justify-center`}>
      {children}
    </div>
  )
}

export const SidebarRow = (props: { style?: any; children: any }) => {
  const { style, children } = props
  return (
    <div style={style} className="flex flex-col mx-[15px] w-full">
      {children}
    </div>
  )
}

export const NavigationTitle = (props: {
  isSelected?: any
  isSubItem?: any
  children: any
  name?: string
  isSmallerText?: boolean
  hasSubItems?: boolean
  isExpanded?: boolean
}) => {
  const {
    isSelected,
    isSubItem,
    children,
    name,
    isSmallerText,
    hasSubItems,
    isExpanded,
  } = props

  return (
    <span
      className={`text-[1.15rem] lg:text-[13px] leading-6 tracking-[0.1px] mr-[auto] overflow-hidden text-ellipsis whitespace-nowrap flex text-zoyya-text ${
        isSmallerText ? 'text-[12px] ml-[20px]' : ''
      }
        ${isSelected ? 'font-semibold' : 'lg:font-normal font-medium'}
        ${isSubItem ? 'max-w-[100px]' : 'max-w-[100%] w-[100%]'}
      `}
    >
      {children}
      {hasSubItems ? (
        <div
          className={`text-zoyya-text ml-auto transition-all ${
            isExpanded ? 'rotate-90' : ''
          }`}
        >
          <ChevronRight size="size14" stroke="2" style={{ color: 'inherit' }} />
        </div>
      ) : null}
    </span>
  )
}
