import React from 'react'
import * as styles from './style'
import styled from 'styled-components'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

const Cross = props => {
  return (
    <StyledIcon
      {...props}
      style={props.style}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path d="M1 1l23 23M24 1L1 24" fill="none"></path>
    </StyledIcon>
  )
}
Cross.displayName = 'Cross'

export default Cross
