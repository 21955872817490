import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const VisitsBook = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.5 2.5h11a1 1 0 011 1v1"></path>
        <path d="M19.5 4.5v-3 0a1 1 0 00-1-1h-13 0a2 2 0 000 4h14a1 1 0 011 1v17a1 1 0 01-1 1h-14 0a2 2 0 01-2-2s0 0 0 0v-19"></path>
        <path d="M17.5 18.719s-2.02-.893-3.432-1.415c-.926-.344-.794-1.907-.258-2.494h0a4.283 4.283 0 001.259-3.531h0A3 3 0 0012 8h0a3 3 0 00-3.07 3.279h0a4.283 4.283 0 001.259 3.531c.536.587.668 2.15-.258 2.494-1.412.523-3.431 1.415-3.431 1.415"></path>
      </g>
    </StyledIcon>
  )
}

export default VisitsBook
