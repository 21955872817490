import gql from 'graphql-tag'

export const ClientFragmentGql = gql`
  fragment ClientFragment on Client {
    id
    firstName
    lastName
    email
    fullName
    category
    mobilePhone
    gender
    comment
    avatarUrl
    nickName
    defaultAvatarUrl
    address
    city
    zipCode
    vatId
    identifier
    attachments {
      id
      title
      description
      downloadUrl
      fileName
      fileSize
      mimeType
      thumbnailUrl
      appointmentId
      bookingRequestId
      createdAt
      appointment {
        startTime
        date
      }
      resourceBooking {
        startTime
        date
      }
    }
    country {
      id
      countryCode
      countryName
    }
    birthDate
    sendReminderSms
    sendReminderEmail
    sendBookingEmail
    sendHelpEmail
    sendNewsEmail
    user {
      id
      avatarUrl
      fb_profilePicUrl
      google_profilePicUrl
      emailNotificationSettings {
        sendReminderSms
        sendReminderEmail
      }
    }
    paymentRequired
    status
    membershipExpirationDate
    mailboxStatus
    mailboxDisabledReason
  }
`
