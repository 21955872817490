import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Tab, TabView } from 'components'
import { Container, TabContainer } from 'src/styles'
import { useHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import ProfessionsList from '../../Professions/ProfessionsList'
import EmployeeList from './EmployeeList'
import { MobileContext } from 'src/Context'

const EmployeesList = props => {
  const { selectedTab } = useParams<any>()
  const { t, navigateTo } = useSdk()
  const isMobile = useContext(MobileContext)

  useHeader({
    content: t('translation.WorkHours.title-employees'),
    onGoBack: navigateTo.mobileBranchSettings,
  })

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    Number(selectedTab)
  )

  useEffect(() => {
    setSelectedTabIndex(Number(selectedTab))
  }, [selectedTab])

  useEffect(() => {
    navigateTo.employeesList({ selectedTab: selectedTabIndex })
  }, [selectedTabIndex])

  return !isMobile ? (
    <Container>
      <TabContainer>
        <TabView
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          isSingleTab={false}
          hasFooter={false}
        >
          <Tab
            hasFooter={false}
            isFullHeight
            title={t('translation.EmployeesListMobile.label-employees')}
          >
            <EmployeeList
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          </Tab>
          <Tab
            hasFooter={false}
            title={t('translation.EmployeesListMobile.label-professions')}
            className="h-full"
          >
            <ProfessionsList />
          </Tab>
        </TabView>
      </TabContainer>
    </Container>
  ) : selectedTabIndex === 0 ? (
    <EmployeeList
      selectedTabIndex={selectedTabIndex}
      setSelectedTabIndex={setSelectedTabIndex}
    />
  ) : (
    <ProfessionsList
      selectedTabIndex={selectedTabIndex}
      setSelectedTabIndex={setSelectedTabIndex}
    />
  )
}
export default EmployeesList
