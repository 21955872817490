import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../constants'
import Button from 'components/Button/Button'
import { analyticsTrack } from 'src/helpers'
import { useACL } from 'src/sdk/acl'

type TSuccessFormProps = {
  title: string
  text: string
  imageUrl?: string
  button1Text?: string
  button2Text?: string
  onButton1Click?: () => void
  onButton2Click?: () => void
  status: string
}

export const SuccessForm = (props: TSuccessFormProps) => {
  const {
    title,
    text,
    imageUrl,
    button1Text,
    button2Text,
    onButton1Click,
    onButton2Click,
    status,
  } = props

  const { hasPermission } = useACL()
  analyticsTrack('BILLING modal', { status })
  return (
    <BodyWrapper>
      <BodyTextSection>
        <BodyTitle>{title}</BodyTitle>
        <BodyText>{text}</BodyText>
        <ButtonSection>
          {hasPermission('ACCESS_BILLING') && button1Text ? (
            <Button
              label={button1Text}
              buttonType={'primary'}
              onClick={onButton1Click}
            />
          ) : null}
          {hasPermission('ACCESS_BILLING') && button2Text ? (
            <Button
              label={button2Text}
              buttonType={'secondary'}
              onClick={onButton2Click}
            />
          ) : null}
        </ButtonSection>
      </BodyTextSection>
      <BodyImage src={imageUrl} alt={'success'} />
    </BodyWrapper>
  )
}

export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 30px;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 5px 20px;
    min-height: 100%;
  }
`

export const BodyImage = styled.img`
  margin-top: 20px;
  max-height: 350px;
  flex: 2;
  min-width: 440px;

  @media ${device.tablet} {
    min-width: 100vw;
    max-height: 40vh;
    margin-top: 30px;
  }
`

export const BodyTitle = styled.span`
  font-size: 32px;
  font-weight: 700;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 44px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`

export const BodyTextSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const BodyText = styled.span``

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  button:first-child {
    margin-right: 15px;
  }
`
