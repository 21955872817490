import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  Column,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { useInView } from 'react-intersection-observer'

type Props<TData extends object> = {
  /**
   * Definition of the columns to be displayed in the table.
   */
  columns: readonly Column<any>[]
  /**
   * Data to be displayed in the table.
   * */
  data: TData[]
  /**
   * Function to be called when the user clicks on a row.
   * */
  onRowClick?: (row: TData) => void
  /**
   * Component to render in mobile view
   * */
  renderMobileCard?: (row: TData, index: number) => JSX.Element
  /**
   * Height of the table row, defaults to 30px
   * */
  tableRowHeight?: number

  /**
   * Height of the mobile card, defaults to 50px
   * */
  mobileCardHeight?: number

  /**
   * Pass true to show the loading animation
   * */
  isLoading?: boolean
  /*
   * Pass true to show the empty list message if the data is empty
   * */
  showEmptyListIfNoData: boolean

  /*
   * Text to show in the empty list message
   */
  emptyListTitle?: string

  /**
   * Pass a function to load more data when the user scrolls to the bottom of the table
   */
  fetchMore?: () => Promise<any>
  /**
   * Pass true to get a thin vertical scrollbar
   */
  thinScrollbar?: boolean
  /**
   * Last index fetched
   */
  saveLastIndexFetched?: string
  /**
   * Function that returns whether a row is selected
   * */
  isSelected?: (row: TData, index: number) => boolean
}

//dummy data used for loading animated shimmer
const shimmerLoadingRows: any[] = [{}, {}, {}]

/**
 * DataTable is a wrapper around react-table that provides a tabular data view
 * On small screen sizes, it renders a mobile card view
 * @param props
 * @returns
 */
export const DataTable = <TData extends object>(props: Props<TData>) => {
  const {
    columns,
    data,
    onRowClick,
    renderMobileCard,
    tableRowHeight,
    mobileCardHeight,
    isLoading,
    showEmptyListIfNoData,
    emptyListTitle,
    fetchMore,
    thinScrollbar,
    saveLastIndexFetched,
    isSelected,
  } = props
  const resolvedData = useMemo(() => data || [], [data])
  const table = useTable<any>(
    { columns, data: isLoading ? shimmerLoadingRows : resolvedData },
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns
  )
  const [isFetchingMore, setIsFetchingMore] = React.useState(false)
  //track if the bottom of the table is in view using intersection observer
  const { ref, inView } = useInView()

  const { getTableBodyProps, headerGroups, rows, prepareRow } = table

  useEffect(() => {
    if (isFetchingMore || !fetchMore || !inView) return

    const fetchMoreData = async () => {
      try {
        await fetchMore()
      } finally {
        setTimeout(() => setIsFetchingMore(false))
      }
    }
    setIsFetchingMore(true)
    setTimeout(fetchMoreData)
  }, [inView, fetchMore, isFetchingMore])

  const RenderMobileCard = React.useCallback(
    ({ index, style }) => {
      if (isLoading) {
        const row = rows[index]
        return (
          <div onClick={() => onRowClick?.(row.original)} style={style}>
            <div className="animate-pulse rounded-sm bg-gray-200 h-[90%] flex items-center m-3"></div>
          </div>
        )
      }
      const row = rows[index]
      return (
        <div onClick={() => onRowClick?.(row.original)} style={style}>
          {renderMobileCard?.(row.original, index)}
        </div>
      )
    },
    [renderMobileCard, rows, onRowClick]
  )

  const onScroll = useCallback((onScrollProps: { scrollOffset: number }) => {
    if (saveLastIndexFetched)
      sessionStorage.setItem(
        saveLastIndexFetched,
        onScrollProps.scrollOffset?.toString()
      )
  }, [])

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      if (isLoading) {
        const row = rows[index]
        prepareRow(row)
        return (
          <div
            className={`flex hover:bg-slate-50  content-center justify-center align-middle ${
              onRowClick ? 'cursor-pointer' : ''
            }`}
            {...row.getRowProps({
              style,
            })}
          >
            {row?.cells.map(cell => {
              return (
                <div
                  className="flex  rounded-sm animate-pulse bg-gray-200 mt-1  mr-1 flex-col justify-center "
                  {...cell.getCellProps()}
                >
                  <div className="mr-1"></div>
                </div>
              )
            })}
          </div>
        )
      }
      const row = rows[index]
      const isRowSelected = isSelected?.(row.original, index)
      prepareRow(row)
      return (
        <div
          ref={index === rows.length - 5 ? ref : undefined}
          data-row-index={index}
          className={`flex pl-1 hover:bg-purple-200/40 content-center justify-center align-middle ${
            onRowClick ? 'cursor-pointer' : ''
          } ${isRowSelected ? 'bg-zoyya-mainBackground' : ''}`}
          onClick={() => onRowClick?.(row.original)}
          {...row.getRowProps({
            style,
          })}
        >
          {row?.cells.map(cell => {
            return (
              <div
                className="flex flex-col justify-center font-medium"
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows, onRowClick, isLoading]
  )
  if (!isLoading && resolvedData.length === 0 && showEmptyListIfNoData) {
    return <EmptyList title={emptyListTitle || ''} />
  }
  return (
    <>
      <div className="flex-col flex-1 hidden lg:flex ">
        <div className="bg-purple-200 p-1 font-semibold">
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <div className="py-1" {...column.getHeaderProps()}>
                  <div>
                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                    </span>
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div id="tableBody" className="flex-1 " {...getTableBodyProps()}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  className={`List ${
                    thinScrollbar ? 'webkitThinScrollbar' : ''
                  }`}
                  height={height}
                  width={width}
                  itemCount={isLoading ? 3 : rows.length}
                  itemSize={tableRowHeight || 50}
                  style={{ overflowX: 'hidden' }}
                  onScroll={onScroll}
                  initialScrollOffset={
                    saveLastIndexFetched
                      ? Number(sessionStorage.getItem(saveLastIndexFetched))
                      : 0
                  }
                >
                  {RenderRow}
                </FixedSizeList>
              )
            }}
          </AutoSizer>
        </div>
      </div>
      {renderMobileCard ? (
        <div className="flex flex-col flex-1 lg:hidden">
          <AutoSizer>
            {({ height, width }) => {
              return (
                <FixedSizeList
                  className="MobileList"
                  height={height}
                  width={width}
                  itemCount={isLoading ? 10 : rows.length}
                  itemSize={mobileCardHeight || 50}
                  onScroll={onScroll}
                  initialScrollOffset={
                    saveLastIndexFetched
                      ? Number(sessionStorage.getItem(saveLastIndexFetched))
                      : 0
                  }
                >
                  {RenderMobileCard}
                </FixedSizeList>
              )
            }}
          </AutoSizer>
        </div>
      ) : null}
    </>
  )
}

DataTable.defaultProps = {
  showEmptyListIfNoData: true,
}
