import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { icons } from 'components'
import { useOnClickOutside } from 'src/helpers'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { debounce } from 'lodash'
import ReactDOM from 'react-dom'
import { MobileContext } from 'src/Context'
import { t } from 'src/localization'
import { LoaderTW } from './Loader'

/**On mobile devices we are rendering dropdown as absolute positioned inside body */
const portalTarget = document.getElementById('body')!

type SelectProps<T> = {
  /**
   * Array of options to be displayed in dropdown
   */
  data: T[]
  /**
   *  Set to true if data is loading
   */
  isLoading?: boolean

  /**
   * Currently selected item
   */
  selectedItem?: T
  /**
   * Height of the dropdown row in pixels
   */
  itemHeight: number
  /**
   * Set to true if dropdown is readonly
   */
  disabled?: boolean

  /**
   * Set to true if Add new item button should be displayed inside dropdown
   */
  showAddNewButton?: boolean

  /**
   * Text to be displayed on Add new item button
   */
  addNewButtonText?: string

  /**
   * Callback to be called when Add new item button is clicked
   */
  onAddNewClick?: () => void

  /**
   * Callback that is called when user selects an item
   */
  onItemSelected?: (item?: T) => void
  /**
   * Render function that is used to render empty dropdown (no data)
   */
  renderEmpty?: () => React.ReactNode

  /**
   * Render function that is used to render dropdown item
   */
  renderItem: (props: { item: T }) => React.ReactNode
  /**
   * Render function that is used to render selected item
   */
  renderSelectedItem?: (props: { item: T }) => React.ReactNode
  /**
   * Callback that is called when user changes search input
   */
  onSearch?: (value: string) => void

  /**
   * Callback that is called when the dropdown list is scrolled to the bottom
   */
  onEndReached?: () => Promise<void>
  /**
   * Set to true if selected item should be clearable
   */
  isClearable?: boolean
  /**
   * Custom re-select icon, by default it's the chevron down icon
   */
  customReselectIcon?: React.ReactNode
  /**
   * Custom style for input
   */
  customInputStyle?: string

  onComponentClick?: any

  currency?: string
  /**
   * Height of the dropdown in pixels, defaults to 50vh
   */
  dropdownHeight?: number
}
type DropdownProps<T> = {
  data: T[]
  isLoading?: boolean
  searchTerm?: string
  onItemSelected: (item: T) => void
  renderItem: (props: {
    item: T
    isSelected?: boolean
    currency?: string
  }) => React.ReactNode
  itemHeight: number
  onClose?: () => void
  showAddNewButton?: boolean
  addNewButtonText?: string
  onAddNewClick?: () => void
  onEndReached?: () => Promise<void>
  isFetchingMoreData: boolean
  selectedItem?: T
  elementIdsThatWontCloseDropdown?: string[]
  currency?: string
  dropdownHeight?: number
}

type SelectItemContainerProps = {
  item: any
  renderItem: (props: {
    item: any
    isSelected: boolean
    currency?: string
  }) => React.ReactNode
  onClick: () => void
  style: React.CSSProperties
  selectedItem?: any
  currency?: string
}

/**
 * Select component styled with TailwindCSS
 * @param props
 * @returns
 */
export const SelectTw = <T,>(props: SelectProps<T>) => {
  const {
    data,
    onSearch,
    selectedItem,
    onItemSelected,
    renderItem,
    renderSelectedItem,
    renderEmpty,
    itemHeight,
    disabled,
    showAddNewButton,
    addNewButtonText,
    onAddNewClick,
    onEndReached,
    isClearable,
    customReselectIcon,
    customInputStyle,
    onComponentClick,
    currency,
    dropdownHeight,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isFetchingMoreData, setIsFetchingMoreData] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const isMobile = useContext(MobileContext)
  const dropwdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (!!onComponentClick) {
      onComponentClick()
    } else {
      if (disabled) return
      setIsOpen(true)
      !isMobile ? setTimeout(() => inputRef.current?.focus()) : null
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    if (!onSearch) return
    debounce(onSearch, 300)(e.target.value)
  }
  const handleSelectItem = (item: T) => {
    setIsOpen(false)
    onItemSelected && onItemSelected(item)
  }
  const DropDown = isMobile ? SelectDropDownMobile : SelectDropDownDesktop

  const handleEndReached = async () => {
    if (!onEndReached || isFetchingMoreData) return

    const fetchMoreData = async () => {
      try {
        await onEndReached()
      } finally {
        setTimeout(() => setIsFetchingMoreData(false))
      }
    }
    setIsFetchingMoreData(true)
    setTimeout(fetchMoreData)
  }

  return (
    <div
      ref={dropwdownRef}
      onClick={handleClick}
      id="select-input-wrapper"
      className={`
        ${
          isOpen
            ? 'border-1 border-zoyya-primary shadow-[0_0_0_3px_#6B81FF]'
            : selectedItem
            ? 'border-0'
            : 'border-1 border-zoyya-grayLight'
        } ${
          customInputStyle ? customInputStyle : null
        } relative min-h-[60px] w-full flex flex-row items-center rounded-sm `}
    >
      <div
        className={`flex-1 flex flex-row ${
          disabled ? '' : 'cursor-pointer'
        } items-center`}
      >
        <>
          <input
            ref={inputRef}
            id="select-input"
            className={`m-0 outline-none  ${
              isOpen
                ? 'visible w-full p-2 pl-4  min-h-[40px]'
                : 'collapse  w-0 h-0'
            }`}
            onChange={handleOnChange}
          ></input>
          {!isOpen ? (
            <div className="flex-1">
              {selectedItem
                ? renderSelectedItem
                  ? renderSelectedItem({ item: selectedItem })
                  : renderItem({ item: selectedItem })
                : renderEmpty?.()}
            </div>
          ) : null}
        </>
      </div>

      {!isOpen && selectedItem && isClearable ? (
        <div
          className="p-2.5 cursor-pointer text-[#a0a0a0]"
          onClick={e => {
            e.stopPropagation()
            props.onItemSelected?.(undefined)
          }}
        >
          <icons.Cross style={{ width: 12, height: 12, strokeWidth: 4 }} />
        </div>
      ) : null}

      {!selectedItem && !customInputStyle ? (
        <div className="border-l border-[#ccc]">
          <div className="p-2.5 hover:text-[#999]  cursor-pointer text-[#ccc]">
            <icons.ChevronDownRounded />
          </div>
        </div>
      ) : null}
      {!isOpen && selectedItem ? (
        customReselectIcon ? (
          customReselectIcon
        ) : (
          <div className="border-l border-[#ccc]">
            <div className="p-2.5 hover:text-[#999]  cursor-pointer text-[#ccc]">
              <icons.ChevronDownRounded />
            </div>
          </div>
        )
      ) : null}

      {isOpen ? (
        <DropDown
          data={data || []}
          isLoading={props.isLoading}
          isFetchingMoreData={isFetchingMoreData}
          onItemSelected={handleSelectItem}
          renderItem={renderItem}
          itemHeight={itemHeight}
          onClose={() => setIsOpen(false)}
          onSearchInputChange={handleOnChange}
          searchTerm={searchTerm}
          showAddNewButton={showAddNewButton}
          addNewButtonText={addNewButtonText}
          onAddNewClick={onAddNewClick}
          onEndReached={handleEndReached}
          selectedItem={selectedItem}
          elementIdsThatWontCloseDropdown={[
            'select-input',
            'select-input-wrapper',
          ]}
          currency={currency}
          dropdownHeight={dropdownHeight}
        />
      ) : null}
    </div>
  )
}

type SelectDropDownMobileProps<T> = DropdownProps<T> & {
  onSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
/**
 * Dropdown component for mobile devices
 * @param props
 * @returns
 */
const SelectDropDownMobile = <T,>(props: SelectDropDownMobileProps<T>) => {
  const {
    data,
    isLoading,
    onItemSelected,
    renderItem,
    itemHeight,
    onClose,
    onSearchInputChange,
    showAddNewButton,
    addNewButtonText,
    onAddNewClick,
    onEndReached,
    searchTerm,
    isFetchingMoreData,
    selectedItem,
    elementIdsThatWontCloseDropdown,
    currency,
  } = props
  const dropDownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(dropDownRef, e => {
    if (elementIdsThatWontCloseDropdown?.includes(e.target.id)) return

    onClose?.()
  })

  const rootRef = useRef<HTMLDivElement>(null)
  //track if the last item is in view (user scrolled to the end of the list)
  const currentDataLength = useRef(0)
  useEffect(() => {
    currentDataLength.current = 0
  }, [isLoading])

  //callback passed to intersection observer
  //will executed onEndReached if this is the first time the last item is in view
  //and we are currently not fetching more data
  const inViewCallback = useCallback(async () => {
    if (isFetchingMoreData || !onEndReached) return
    if (currentDataLength.current === data.length) return
    await onEndReached()
    currentDataLength.current = data.length
  }, [data.length, isFetchingMoreData])

  //create intersection observer to track if the last item is in view
  const observer = useMemo(() => {
    return new IntersectionObserver(inViewCallback, {
      root: rootRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    })
  }, [rootRef.current, inViewCallback])

  //callback attached to the last item in the list
  //will start observing the last item
  const lastElementCallback = useCallback(
    node => {
      if (!node) {
        observer.disconnect()
      } else {
        observer.observe(node)
      }
    },
    [observer]
  )

  const dropdownHeightClass = props.dropdownHeight
    ? `lg:h-[${props.dropdownHeight}px] h-full`
    : 'h-full'

  return ReactDOM.createPortal(
    <div
      className={`absolute flex bottom-0 flex-col border-gray-500 z-[2147483647] bg-black/50 ${dropdownHeightClass} w-full overflow-hidden`}
    >
      <>
        <div
          ref={dropDownRef}
          className="flex flex-1 flex-col mt-24 bg-white rounded-t-3xl overflow-hidden"
        >
          <div className="flex p-4">
            <div className="relative w-full ">
              <div className="absolute top-3 left-2.5 text-zoyya-secondaryDark">
                <icons.Search style={{ strokeWidth: 0 }} />
              </div>
              <input
                value={searchTerm}
                onChange={onSearchInputChange}
                onClick={e => e.stopPropagation()}
                autoFocus
                className="p-3 w-full rounded-md border-1 border-zoyya-grayLight pl-11 focus:shadow-[0_0_0_3px_#6B81FF] focus:border-zoyya-primary"
              ></input>
            </div>
          </div>

          <div className="flex-1">
            {isLoading ? (
              <LoaderTW isComponent />
            ) : !data.length ? (
              <div className="flex items-center justify-center flex-col text-zoyya-secondaryDark">
                <div className="w-8/12">
                  <img src="/assets/empty-box.svg" />
                </div>
                <p>{t('translation.ClientSelect.noResult')}</p>
              </div>
            ) : (
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <FixedSizeList
                      itemCount={data.length}
                      height={height}
                      width={width}
                      itemSize={itemHeight}
                      className="no-scrollbar"
                    >
                      {({ index, style }) => (
                        <ItemContainer
                          ref={
                            index === data.length - 1
                              ? lastElementCallback
                              : undefined
                          }
                          style={style}
                          item={data[index]}
                          renderItem={renderItem}
                          onClick={() => onItemSelected(data[index])}
                          selectedItem={selectedItem}
                          currency={currency}
                        ></ItemContainer>
                      )}
                    </FixedSizeList>
                  )
                }}
              </AutoSizer>
            )}
          </div>
          {showAddNewButton ? (
            <div className="p-4 flex">
              <button
                type="button"
                className="flex w-full justify-center bg-zoyya-primary text-white p-4 rounded-md"
                onClick={e => {
                  e.stopPropagation()
                  onAddNewClick?.()
                }}
              >
                {addNewButtonText || <icons.Add />}
              </button>
            </div>
          ) : null}
        </div>
      </>
    </div>,
    portalTarget
  )
}

/**
 * Dropdown component for desktop devices
 * @param props
 * @returns
 */
const SelectDropDownDesktop = <T,>(props: DropdownProps<T>) => {
  const {
    data,
    isLoading,
    onItemSelected,
    renderItem,
    itemHeight,
    onClose,
    onEndReached,
    isFetchingMoreData,
    showAddNewButton,
    addNewButtonText,
    onAddNewClick,
    selectedItem,
    elementIdsThatWontCloseDropdown,
    currency,
  } = props
  const dropDownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(dropDownRef, e => {
    if (elementIdsThatWontCloseDropdown?.includes(e?.target?.id)) return

    onClose?.()
  })
  const rootRef = useRef<HTMLDivElement>(null)
  //track if the last item is in view (user scrolled to the end of the list)
  const currentDataLength = useRef(0)
  useEffect(() => {
    currentDataLength.current = 0
  }, [isLoading])
  const inViewCallback = useCallback(async () => {
    if (isFetchingMoreData || !onEndReached) return
    if (currentDataLength.current === data.length) return
    await onEndReached()
    currentDataLength.current = data.length
  }, [data.length, isFetchingMoreData])

  const observer = useMemo(() => {
    return new IntersectionObserver(inViewCallback, {
      root: rootRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    })
  }, [rootRef.current, inViewCallback])

  const lastElementCallback = useCallback(
    node => {
      if (!node) {
        observer.disconnect()
      } else {
        observer.observe(node)
      }
    },
    [observer]
  )

  const dropdownHeightClass = props.dropdownHeight
    ? `h-[${props.dropdownHeight}px]`
    : 'h-[50vh]'

  return (
    <div
      className={`absolute top-[65px] flex bg-white  left-0 shadow-xl rounded-md w-full border-1 border-zoyya-grayLight z-[1000] ${dropdownHeightClass} overflow-hidden`}
    >
      {isLoading ? (
        <LoaderTW isComponent />
      ) : (
        <div
          ref={dropDownRef}
          className="flex flex-1 flex-col rounded-t-3xl overflow-hidden bg-white"
        >
          {showAddNewButton ? (
            <button
              className="self-start p-2 text-zoyya-primary w-full text-left"
              type="button"
              onClick={e => {
                e.stopPropagation()
                onAddNewClick?.()
              }}
            >
              {addNewButtonText || <icons.Add />}
            </button>
          ) : null}
          {!data.length ? (
            <div className="flex items-center justify-center flex-col text-zoyya-secondaryDark">
              <div className="w-6/12">
                <img src="/assets/empty-box.svg" />
              </div>
              <p>{t('translation.ClientSelect.noResult')}</p>
            </div>
          ) : (
            <div className="flex-1" ref={rootRef}>
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <FixedSizeList
                      itemCount={data.length}
                      height={height}
                      width={width}
                      itemSize={itemHeight}
                    >
                      {({ index, style }) => (
                        <div
                          style={style}
                          ref={
                            index === data.length - 1
                              ? lastElementCallback
                              : undefined
                          }
                          id={`dropdown-item-${index}`}
                          className={`cursor-pointer p-2 hover:bg-zoyya-mainBackground `}
                          onClick={e => {
                            e.stopPropagation()
                            onItemSelected(data[index])
                          }}
                        >
                          {renderItem({
                            item: data[index],
                            isSelected: false,
                            currency: currency,
                          })}
                        </div>
                      )}
                    </FixedSizeList>
                  )
                }}
              </AutoSizer>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const ItemContainer = React.forwardRef(
  (
    props: SelectItemContainerProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { item, style, onClick, renderItem, selectedItem, currency } = props
    const isSelected = selectedItem?.id === item?.id
    return (
      <div
        ref={ref}
        className={`cursor-pointer p-2 hover:bg-zoyya-mainBackground `}
        onClick={e => {
          e.stopPropagation()
          onClick()
        }}
        style={style}
      >
        {renderItem({ item, isSelected, currency })}
      </div>
    )
  }
)
