import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'components/Select2/Select'
import { SelectOptionLabel, SelectRow } from 'views/AppointmentModal/styles'
import { icons } from 'components'
import { getUserAvatar } from 'src/helpers'

const MobileOption = props => {
  const isSelected = props.selectProps.value?.find(
    val => val.id === props.data?.id
  )
  return (
    <SelectRow>
      <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
        {isSelected && <icons.CheckMarkIcon color="grayDarker" />}
      </span>
      <div className="w-[40px]">
        {getUserAvatar(props.data, true, 'smaller')}
      </div>
      <SelectOptionLabel>
        {props.data.firstName + ' ' + props.data.lastName}
      </SelectOptionLabel>
    </SelectRow>
  )
}

const selectComponents: any = {
  MobileOption,
}

interface Props {
  onSelectClick?: any
  onBlur?: any
  selectedEmployees?: any
  allEmployees?: any
}
export const AddEmployeesSelectMobile = ({
  onSelectClick,
  onBlur,
  selectedEmployees,
  allEmployees,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Select
      closeMenuOnSelect={false}
      value={selectedEmployees}
      hideSelectedOptions={false}
      isMulti
      components={selectComponents}
      options={allEmployees}
      onChange={onSelectClick}
      placeholder={t(
        'translation.EmployeesListMobile.label-add-existing-employees'
      )}
      controlShouldRenderValue={false}
      onBlur={onBlur}
      isMenuItem={true}
    />
  )
}
