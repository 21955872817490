import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  vertical-align: ${props =>
    props.verticalAlign ? props.verticalAlign : null};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const EmailCheck = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.674 13.31a6 6 0 11-8.486 8.485 6 6 0 018.486-8.486"></path>
        <path d="M20.105 15.808L17.2 19.681h0a.75.75 0 01-1.131.081l-1.5-1.5M9.526 14.533h-7.5 0a1.5 1.5 0 01-1.5-1.5s0 0 0 0v-11h0a1.5 1.5 0 011.5-1.5h18a1.5 1.5 0 011.5 1.5v7.5"></path>
        <path d="M21.093.979L11.026 9.033.958.979"></path>
      </g>
    </StyledIcon>
  )
}
