import {
  differenceInCalendarDays,
  endOfMonth,
  getDay,
  startOfMonth,
  format,
  isWeekend,
  getDaysInMonth,
  isBefore,
  isAfter,
  isToday,
  isThisMonth,
  addDays,
  startOfYear,
  addYears,
  endOfYear,
} from 'date-fns'
import React, {
  UIEventHandler,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useParams } from 'react-router-dom'
import { icons } from 'src/components'
import { formatDate, t } from 'src/localization'
import { RouterOutput, trpc } from 'src/trpc'
import { AppointmentHotelEdit } from './AppointmentHotelEdit'
import moment from 'moment'
import { AppointmentHotelNew } from './AppointmentHotelNew'
import { useFooter } from 'src/mobile/Footer'
import { useHeader } from 'src/mobile/Header'
import { getStatusLabel, resolveFullName } from 'src/helpers'
import { ThemeContext } from 'styled-components'
import Tooltip from 'src/components/Tooltip/Tooltip'

import { useDebouncedCallback } from 'use-debounce'

type Booking = RouterOutput['hotel_calendarDataGet'][0]['bookings'][0]

const DAY_COLUMN_WIDTH = 64
const ROW_HEIGHT = 64

export const CalendarHotel = ({ setMobileCalendarFooterVisible }) => {
  const [editBookingId, setEditBookingId] = React.useState<null | bigint>(null)
  const [isNew, setIsNew] = React.useState(false)
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<
    string | undefined
  >()
  const [selectedResourceId, setSelectedResourceId] = React.useState<
    bigint | undefined
  >()
  const headerRef = useRef<HTMLDivElement>(null)

  const { orgId, locationId } = useParams<{
    locationId: string
    orgId: string
  }>()

  useHeader({
    content: null,
  })

  useFooter({
    showAddButton: false,
  })

  const { data: resourcesData, refetch } = trpc.hotel_calendarDataGet.useQuery({
    locationId: BigInt(locationId),
    orgId: orgId!,
    start: startOfYear(new Date()),
    end: endOfYear(addYears(new Date(), 1)),
  })

  trpc.onCalendarUpdated.useSubscription(
    { locationId: locationId, orgId: orgId },
    {
      onData: payload => {
        refetch()
      },
    }
  )

  const resources = resourcesData || []

  const handleBodyScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (!headerRef.current) return
      if (e.target) {
        headerRef.current.scrollLeft = (e.target as any).scrollLeft
      }
    },
    [headerRef.current]
  )

  const handleBookingClick = useCallback((booking: Booking) => {
    setIsNew(false)
    setEditBookingId(booking.id)
  }, [])

  const handleCloseEditBooking = useCallback(() => {
    setEditBookingId(null)
    setIsNew(false)
  }, [])

  const handleEmptyDateCellClick = (
    date: string,
    resource: RouterOutput['hotel_calendarDataGet'][0]
  ) => {
    setEditBookingId(null)
    setSelectedResourceId(resource.id)
    setSelectedDateFrom(date)
    setIsNew(true)
  }
  useEffect(() => {
    if (editBookingId || isNew) {
      setMobileCalendarFooterVisible(false)
    } else {
      setMobileCalendarFooterVisible(true)
    }
  }, [setMobileCalendarFooterVisible, isNew, editBookingId])
  return (
    <>
      <div className="flex flex-col w-full h-full max-w-full flex-1 overflow-y-hidden ">
        <MonthDaysHeader headerRef={headerRef} />
        <CalendarBody
          onEmptyDateCellClick={handleEmptyDateCellClick}
          onBookingClick={handleBookingClick}
          onScroll={handleBodyScroll}
          resources={resources}
        />
      </div>
      {editBookingId || isNew ? (
        <AppointmentHotel
          bookingId={editBookingId || BigInt(0)}
          onClose={handleCloseEditBooking}
          selectedDateFrom={selectedDateFrom}
          selectedResourceId={selectedResourceId}
          isNew={isNew}
        />
      ) : null}
    </>
  )
}

/**
 * Renders the body of the calendar
 * Sticky header row for the days of the month
 * One row for each resource with day slots
 * Booking cards are rendered as an absolute overlay on top of the day slots
 * Scroll position of the booking cards overlay and main body are synced
 * @param props
 * @returns
 */
const CalendarBody = (props: {
  onScroll: UIEventHandler<HTMLDivElement>
  onBookingClick: (booking: Booking) => void
  resources: RouterOutput['hotel_calendarDataGet']
  onEmptyDateCellClick: (
    date: string,
    resource: RouterOutput['hotel_calendarDataGet'][0]
  ) => void
}) => {
  const { resources, onBookingClick, onEmptyDateCellClick } = props
  const sidebarRef = React.useRef<HTMLDivElement>(null)
  const bodyRef = React.useRef<HTMLDivElement>(null)
  const calendarBodyRef = React.useRef<HTMLDivElement>(null)
  const daysArray = useMemo(() => getDateRange(), [])
  const overlayScrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const todayDate = new Date()
    const firstDay = daysArray[0]

    const days = differenceInCalendarDays(todayDate, firstDay)

    calendarBodyRef.current?.scrollTo((days - 2) * DAY_COLUMN_WIDTH, 0)
  }, [calendarBodyRef.current, resources])

  const handleBodyScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLDivElement
      if (sidebarRef.current && target) {
        sidebarRef.current.scrollTop = target.scrollTop
      }
      if (overlayScrollRef.current) {
        overlayScrollRef.current.scrollTop = target.scrollTop
        overlayScrollRef.current.scrollLeft = target.scrollLeft
      }
      props.onScroll(e)
    },
    [sidebarRef.current]
  )

  return (
    <div
      className="relative flex flex-row flex-1 overflow-hidden"
      ref={bodyRef}
    >
      <div
        id="calendar-body"
        className="relative flex flex-col flex-1 overflow-x-auto ml-[250px] "
        ref={calendarBodyRef}
        onScroll={handleBodyScroll}
      >
        {resources.map((resource, index) => {
          return (
            <ResourceDaysRow
              days={daysArray}
              key={`${resource.id.toString()}-${index}`}
              resource={resource}
              onEmptyDateCellClick={onEmptyDateCellClick}
              onBookingClick={onBookingClick}
            />
          )
        })}
      </div>
      <BookingsOverlay
        resources={resources}
        startDate={daysArray[0]}
        onBookingClick={onBookingClick}
      />
    </div>
  )
}

/**
 * Renders one row of days for a resource
 * @param props
 * @returns
 */
const ResourceDaysRow = (props: {
  days: Date[]
  resource: RouterOutput['hotel_calendarDataGet'][0]
  onBookingClick: (booking: Booking) => void
  onEmptyDateCellClick: (
    date: string,
    resource: RouterOutput['hotel_calendarDataGet'][0]
  ) => void
}) => {
  const bodyElement = document.getElementById('calendar-body')
  const { days, resource, onEmptyDateCellClick } = props
  const today = new Date()
  const daysDiff = differenceInCalendarDays(today, days[0])
  const [overlapCount, setOverlapCount] = useState(0)
  const getBookingsInView = useCallback(() => {
    if (!bodyElement) return
    const left = bodyElement.scrollLeft
    const numberOfDays = left / DAY_COLUMN_WIDTH
    const width = bodyElement.clientWidth

    const daysInView = width / DAY_COLUMN_WIDTH
    const fromDate = addDays(days[0], numberOfDays - 1)
    const toDate = addDays(fromDate, daysInView + 1)
    const bookingsInView = resource.bookings.filter(booking => {
      return (
        isBefore(new Date(booking.startTime), toDate) &&
        isAfter(new Date(booking.endTime), fromDate)
      )
    })

    return bookingsInView
  }, [days[0], resource, bodyElement])
  const handleScroll = useCallback(
    (e: Event) => {
      const bbs = getBookingsInView()
      const newOverlapCount = maxOverlapCount(bbs)
      if (newOverlapCount !== overlapCount) {
        setOverlapCount(newOverlapCount)
      }
    },
    [getBookingsInView, overlapCount]
  )
  useEffect(() => {
    if (!bodyElement) return
    bodyElement.addEventListener('scroll', handleScroll)
    const resizeObserver = new ResizeObserver(entries => {
      //updateBookingsInView()
    })
    resizeObserver.observe(bodyElement)
    return () => {
      bodyElement.removeEventListener('scroll', handleScroll)
      resizeObserver.disconnect()
    }
  }, [getBookingsInView, bodyElement, handleScroll])

  return (
    <div className="flex flex-row relative">
      <div
        className="absolute h-full w-[1px] bg-zoyya-primary"
        style={{ left: `${daysDiff * DAY_COLUMN_WIDTH - 2}px` }}
      ></div>
      <div className="flex flex-row">
        {days.map((day, index) => {
          const isBookingDay = resource.bookings.some(booking => {
            // check whether the day is between booking?.startTime and booking?.endTime
            return moment(day).isBetween(
              moment(booking.startTime),
              moment(booking.endTime),
              'dates',
              '[]'
            )
          })
          return (
            <div
              key={index}
              style={{
                width: `${DAY_COLUMN_WIDTH}px`,
                // height: `${(overlapCount + 1) * ROW_HEIGHT}px`,
                height: `${
                  overlapCount ? overlapCount * ROW_HEIGHT : ROW_HEIGHT
                }px`,
              }}
              onClick={() => {
                if (isBookingDay) return
                onEmptyDateCellClick(moment(day).format('YYYY-MM-DD'), resource)
              }}
              className={`${
                isWeekend(day) ? 'bg-gray-50' : 'bg-white'
              } flex flex-col  border-b  items-center justify-center border-gray-100 border-b-gray-200 ${
                !isBookingDay ? 'cursor-pointer hover:bg-zoyya-outline' : ''
              }`}
            >
              <div className="flex flex-row justify-center items-center "></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

/**
 * Overlay that renders the booking cards on top of the calendar
 * @param props
 * @returns
 */
const BookingsOverlay = (props: {
  resources: any[]
  startDate: Date
  onBookingClick: (booking: Booking) => void
}) => {
  const resourcesPanelRef = useRef<HTMLDivElement>(null)
  const bookingsOverlayRef = useRef<HTMLDivElement>(null)
  const { resources, startDate } = props

  const bodyElement = document.getElementById('calendar-body')

  const allBookings = useMemo(
    () =>
      resources.flatMap((resource, index) =>
        resource.bookings.map(booking => ({
          ...booking,
          resourceId: resource.id,
          rowIndex: index,
          resourceName: resource.name,
          overlapCount: countOverlappingBookings(booking, resource.bookings),
        }))
      ),
    [resources]
  )
  const [view, setView] = useState<any>({})

  /**
   * Get the bookings that are currently in view
   * Bookings are in view if they overlap with the current scroll position
   */
  const getBookingsInView = useCallback(() => {
    const bodyElement = document.getElementById('calendar-body')
    if (!bodyElement) return []
    const left = bodyElement.scrollLeft
    const daysCount = left / DAY_COLUMN_WIDTH
    const width = bodyElement.clientWidth

    const daysInView = width / DAY_COLUMN_WIDTH
    const fromDate = addDays(startDate, daysCount - 1)
    const toDate = addDays(fromDate, daysInView + 1)
    const bookingsInView = allBookings.filter(booking => {
      return (
        isBefore(new Date(booking.startTime), toDate) &&
        isAfter(new Date(booking.endTime), fromDate)
      )
    })

    //return bookings sorted by startTime field
    return bookingsInView.sort((a, b) => {
      return a.startTime < b.startTime ? -1 : 1
    })
  }, [startDate, allBookings, view])

  const bookingsInView = useMemo(() => getBookingsInView(), [getBookingsInView])

  const updateBookingsInView = useDebouncedCallback(() => {
    setView({})
  }, 10)

  /**
   * Add scroll event listener to the body element
   *
   */
  useEffect(() => {
    if (!bodyElement) return
    bodyElement.addEventListener('scroll', handleScroll)
    const resizeObserver = new ResizeObserver(entries => {
      if (resourcesPanelRef.current && bodyElement) {
        resourcesPanelRef.current.scrollTop = bodyElement.scrollTop
      }
      if (bookingsOverlayRef.current && bodyElement) {
        bookingsOverlayRef.current.scrollTop = bodyElement?.scrollTop
        bookingsOverlayRef.current.scrollLeft = bodyElement?.scrollLeft
      }
    })
    //rerender the bookings in view
    setView({})
    resizeObserver.observe(bodyElement)
    return () => {
      bodyElement.removeEventListener('scroll', handleScroll)
      resizeObserver.disconnect()
    }
  }, [allBookings, bodyElement])

  /**
   * Handle scroll event
   * Update the bookings in view, as the scroll position changes might cause new bookings to be in view
   * Update the scroll position of the resources panel and the bookings overlay
   */
  const handleScroll = useCallback(
    (e: Event) => {
      updateBookingsInView()
      if (resourcesPanelRef.current && bodyElement) {
        resourcesPanelRef.current.scrollTop = bodyElement.scrollTop
      }
      if (bookingsOverlayRef.current && bodyElement) {
        bookingsOverlayRef.current.scrollTop = bodyElement?.scrollTop
        bookingsOverlayRef.current.scrollLeft = bodyElement?.scrollLeft
      }
    },
    [
      updateBookingsInView,
      resourcesPanelRef.current,
      bookingsOverlayRef.current,
    ]
  )

  /***
   * Calculate the rows layout for each resource
   * Each row has a top position and a height
   * The height is calculated based on the number of overlapping bookings
   */
  const rows = useMemo(() => {
    const rows: any[] = []
    let topPosition = 0
    for (let resource of resources) {
      const resourceBookingsInView = getBookingsInView().filter(
        e => e.resourceId === resource.id
      )
      const overlapCount = maxOverlapCount(resourceBookingsInView)
      const rowHeight = overlapCount ? overlapCount * ROW_HEIGHT : ROW_HEIGHT
      rows.push({
        resource,
        top: topPosition,
        height: rowHeight,
      })
      topPosition += rowHeight
    }
    return rows
  }, [resources, getBookingsInView])

  /**
   * Calculate the absolute top position for each booking that is currently in view
   * Top position is based on the row position and the number of overlapping bookings
   * if bookings overlap, the top position is increased by 56px for each overlap
   *
   * return the bookings with the top position set, so that the booking cards can be rendered
   */
  const positionedBookings = useMemo(() => {
    const caluculatePositionFor = [...bookingsInView]
    for (let resource of resources) {
      const resourceBookingsInView = caluculatePositionFor.filter(
        e => e.resourceId === resource.id
      )
      for (let booking of resourceBookingsInView) {
        const overlaped = resourceBookingsInView.find(
          e =>
            e.id !== booking.id &&
            e.startTime <= booking.startTime &&
            e.endTime >= booking.startTime
        )
        booking.top = overlaped
          ? overlaped.top + 56
          : rows[booking.rowIndex].top + 7
      }
    }
    return caluculatePositionFor
  }, [resources, bookingsInView])

  if (!bodyElement || !bookingsInView) return null

  return (
    <div
      className="absolute z-[200]  left-0 top-0 flex flex-row w-full overflow-y-auto  h-full pointer-events-none  "
      style={{
        maxWidth: bodyElement.clientWidth + 250,
      }}
    >
      <div
        ref={resourcesPanelRef}
        className="w-[316px] scrollbar-hide pointer-events-none  border-r border-gray-200  h-full max-h-full overflow-x-hidden overflow-y-auto"
        style={{
          maxHeight: bodyElement?.offsetHeight,
        }}
      >
        <div className="flex flex-col    ">
          {rows.map((row, index) => {
            return (
              <div
                key={index}
                style={{
                  height: `${row.height}px`,
                }}
                className="flex flex-col border-b shrink-0 border-r items-center justify-center  border-gray-200 w-[80px] lg:w-[250px] min-h-fit "
              >
                <div className="flex flex-row justify-center items-center shrink-0 lg:text-lg text-sm text-center font-semibold">
                  {row.resource.name}
                </div>
              </div>
            )
          })}
        </div>
        {/* Emtpty div to fill the space below the last resource, need this to align scrollbars */}
        <div className="h-[20px]"></div>
      </div>
      <div
        ref={bookingsOverlayRef}
        className="  w-full  overflow-x-auto scrollbar-hide "
        style={{
          height: bodyElement.clientHeight,
          maxHeight: bodyElement.clientHeight,
        }}
      >
        <div
          style={{
            width: bodyElement.scrollWidth - 20,
            height: bodyElement.scrollHeight,
          }}
          className="  relative "
        >
          {positionedBookings.map((booking, index) => {
            return (
              <BookingCard
                key={booking.id}
                onClick={() => props.onBookingClick(booking)}
                booking={booking}
                top={booking.top}
                left={
                  differenceInCalendarDays(booking.startTime, startDate) *
                  DAY_COLUMN_WIDTH
                }
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a booking card
 **/
const BookingCard = (props: {
  booking: Booking
  top: number
  left: number
  onClick: () => void
}) => {
  const { booking, top, left, onClick } = props
  const selectedTheme = useContext(ThemeContext)

  const isUnconfirmedBooking = booking?.status === 'DRAFT'

  const width = useMemo(() => {
    const startDate = booking.startTime
    const endDate = booking.endTime
    const daysInBooking = differenceInCalendarDays(endDate, startDate)

    return `${(daysInBooking + 1) * DAY_COLUMN_WIDTH}px`
  }, [booking])

  const isSingleDayBooking = width === `${DAY_COLUMN_WIDTH}px`
  if (booking.refId === '0651JRGV3ZR62D3XQKP7PMV7P8') {
    console.log({ bookingRef: booking })
  }
  return (
    <div
      onClick={onClick}
      className={`absolute   z-[10000] pointer-events-auto flex flex-row cursor-pointer rounded-md border items-center  hover:border-gray-400  h-14 top-2 shrink-0 border-gray-300 
    
      `}
      style={{
        width: width,
        maxWidth: width,
        left: left,
        zIndex: 100000,
        top: top,
        background: isUnconfirmedBooking
          ? `linear-gradient(45deg, #e9e9ff 20%, #ffffff 20%, #ffffff 50%, #e9e9ff 50%, #e9e9ff 70%, #ffffff 70%, #ffffff 100%) 10% 10% / 10px 10px`
          : '#e9e9ff',
      }}
    >
      <div
        className={`flex w-full max-w-full ${
          isSingleDayBooking ? 'flex-col items-start' : 'flex-row items-center'
        } text-xs font-semibold  p-1`}
      >
        <div className="flex flex-col max-w-full truncate text-ellipsis">
          <div className="font-bold truncate text-ellipsis">
            {resolveFullName(booking?.Client, false)}
          </div>
          <div className="font-normal">{`${formatDate(
            booking.startTime,
            'dd.MM'
          )}-${formatDate(booking.endTime, 'dd.MM')}`}</div>
        </div>

        {booking.posPaymentRequest?.id ? (
          <Tooltip
            label={getStatusLabel(
              booking?.posPaymentRequest?.status,
              t,
              false,
              null
            )}
            position="top"
          >
            <div className={isSingleDayBooking ? '' : 'ml-3'}>
              {booking.posPaymentRequest?.status === 'CANCELED' ? (
                <icons.CardCanceled
                  color={selectedTheme.colors.accent1}
                  size={'small'}
                  style={{
                    position: 'relative',
                    top: '3px',
                    marginRight: '-5px',
                  }}
                />
              ) : (
                <icons.CreditCard
                  color={
                    booking.posPaymentRequest?.status === 'PENDING_PAYMENT' ||
                    booking.posPaymentRequest?.status === 'REFUNDED'
                      ? selectedTheme.colors.accent5
                      : selectedTheme.colors.accent2
                  }
                  size={'smaller'}
                  style={{ strokeWidth: 2 }}
                />
              )}
            </div>
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

/**
 * Renders the header of the calendar with the days of the month
 * @param props
 * @returns
 */
const MonthDaysHeader = (props: {
  headerRef: React.RefObject<HTMLDivElement>
}) => {
  const days = useMemo(() => getDateRange(), [])
  const months = useMemo(() => {
    const seenMonths = new Set<string>() // to track which month/year combos we've seen
    const tuples: {
      month: number
      year: number
      startDay: number
      daysInMonth
    }[] = [] // our result list

    for (const date of days) {
      const month = date.getMonth() + 1 // months are 0-indexed, so we add 1
      const year = date.getFullYear()
      const key = `${month}-${year}` // a unique key for this month/year

      if (!seenMonths.has(key)) {
        seenMonths.add(key)
        const startDay = date.getDate()
        const endDate = endOfMonth(date)
        const days = differenceInCalendarDays(endDate, date) + 1
        tuples.push({ month, year, startDay, daysInMonth: days })
      }
    }

    return tuples
  }, [])

  const { data: locationData } = trpc.location_current.useQuery()
  return (
    <div className="flex  flex-row overflow-x-hidden" ref={props.headerRef}>
      <div className=" sticky lg:flex left-0 top-0 text-transparent lg:text-black w-[80px] lg:w-[250px] lg:min-w-[250px] border-r shrink-0 border-b text-xl font-semibold  border-gray-300  pt-8 px-4 justify-start bg-zoyya-outline">
        {locationData?.name}
      </div>

      <div className={`flex flex-col`}>
        {/* <div className=" h-[58px] sticky left-[40%] lg:left-[250px] border-b flex gap-6 items-center px-2 justify-start ">
          <button title="Previous month" onClick={onPreviousMonth}>
            <icons.ChevronLeft stroke={2.5} size={'smaller'} />
          </button>
          <div className="m-auto font-bold text-center w-[115px]">
            {formatDate(new Date(year, month - 1, 1), 'LLLL, y')}
          </div>
          <button title="Next month" onClick={onNextMonth}>
            <icons.ChevronRight size={'smaller'} stroke={2.5} />
          </button>
        </div> */}
        <span className="flex flex-row">
          {months.map((month, index) => (
            <div
              className={`flex p-1 flex-col items-center font-semibold text-xl border-r border-gray-500  text-white ${
                index % 2 === 0
                  ? 'bg-zoyya-orgSettingsSidebar'
                  : 'bg-zoyya-primary'
              }`}
              style={{ width: month.daysInMonth * DAY_COLUMN_WIDTH }}
            >
              {month.month}/{month.year}
            </div>
          ))}
        </span>
        <div className="flex flex-row ">
          {days.map((day, index) => {
            const today = isToday(day)
            return (
              <div
                key={index}
                style={{ width: `${DAY_COLUMN_WIDTH}px` }}
                className={`flex flex-col 
                ${isWeekend(day) ? 'bg-zoyya-orgSettingsSidebar' : ''}
                
                border-r border-b items-center justify-center bg-zoyya-primary text-white border-gray-500  h-15`}
              >
                <div className="text-sm pt-1">{formatDate(day, 'EEE')}</div>
                <div className="pb-1">
                  <div
                    className={
                      today
                        ? 'bg-zoyya-accent4 rounded-full h-6 w-6 text-center text-zoyya-text/75'
                        : ''
                    }
                  >
                    {day.getDate().toString()}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function getDateRange(): Date[] {
  const currentDate = new Date()
  let startDate = new Date(currentDate)
  startDate.setFullYear(startDate.getFullYear() - 1)
  let endDate = new Date(currentDate)
  endDate.setFullYear(endDate.getFullYear() + 1)

  let dates: Date[] = []
  while (startDate <= endDate) {
    dates.push(new Date(startDate)) // clone the date to avoid reference issues
    startDate.setDate(startDate.getDate() + 1) // increment day by 1
  }

  return dates
}

export const AppointmentHotel = (props: {
  bookingId: bigint
  onClose: () => void
  isNew: boolean
  selectedDateFrom?: string
  selectedResourceId?: bigint
}) => {
  const { bookingId, onClose, isNew, selectedDateFrom } = props
  return createPortal(
    <aside className="absolute cursor-pointer pointer-events-auto flex flex-col top-0 right-0  h-full max-h-full lg:min-w-[640px] min-w-full bg-white z-[12200] shadow-md border-l border-gray-300">
      {bookingId ? (
        <AppointmentHotelEdit
          bookingId={bookingId}
          onClose={onClose}
          key={bookingId.toString()}
        />
      ) : isNew ? (
        <AppointmentHotelNew
          onClose={onClose}
          key={`${selectedDateFrom}-${props.selectedResourceId}`}
          selectedResourceId={props.selectedResourceId}
          selectedDateFrom={selectedDateFrom}
        />
      ) : null}
    </aside>,
    document.body
  )
}
function countOverlappingBookings(targetBooking, existingBookings) {
  let overlapCount = 0

  for (let i = 0; i < existingBookings.length; i++) {
    let booking = existingBookings[i]
    if (
      targetBooking.startTime < booking.endTime &&
      targetBooking.endTime > booking.startTime
    ) {
      overlapCount++
    }
  }

  return overlapCount
}
function maxOverlapCount(bookings) {
  let maxCount = 0
  if (bookings.length <= 1) return 0
  for (let i = 0; i < bookings.length; i++) {
    let count = countOverlappingBookings(bookings[i], bookings)
    if (count > maxCount) {
      maxCount = count
    }
  }

  return maxCount
}
export default CalendarHotel
