import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Chart = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 23h23M4.5 23v-4.5 0A.5.5 0 004 18H2h0a.5.5 0 00-.5.5s0 0 0 0V23M10.5 23v-9.5 0a.5.5 0 00-.5-.5H8h0a.5.5 0 00-.5.5s0 0 0 0V23M16.5 23v-7.5 0a.5.5 0 00-.5-.5h-2 0a.5.5 0 00-.5.5s0 0 0 0V23M22.5 23V8.5v0A.5.5 0 0022 8h-2 0a.5.5 0 00-.5.5s0 0 0 0V23M21.5 1l-5.62 6.056h0a2 2 0 01-2.1.537l-3.719-1.241h0a2 2 0 00-1.913.361L3 11"></path>
        <path d="M17.504 1h4v4"></path>
      </g>
    </StyledIcon>
  )
}
