import React, { useContext } from 'react'
import { getISODate } from 'src/helpers'
import Button from 'components/Button/Button'
import ReactTable from 'components/Table/Table'
import { TableItem } from 'src/styles'
import { ColorItem } from 'views/Settings/Services/styles'
import { useTranslation } from 'react-i18next'
import { MobileContext } from 'src/Context'
import { useSdk } from 'sdk'
import styled from 'styled-components'
import Tooltip from 'rc-tooltip'
import { icons } from 'components'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'

export const overlayStyle = {
  borderRadius: '4px',
  background: 'transparent',
  zIndex: 9999999999,
}

export const overlayInnerStyle = {
  border: 'none',
  backgroundColor: '#333',
  color: '#ffffff',
}

export const InfoOverlay = styled.div`
  max-width: 250px;
`

export const InfoText = styled.div``

export const InfoLink = styled.a`
  color: #3bcbe8;
  text-decoration: underline;
  margin-left: 2px;
  display: inline;
`

export const ResourcesTable = ({
  selectedResources,
  updateResourceSequence,
  isUpdating,
  removeResource,
  tableRef,
  moveItemUp,
  moveItemDown,
  tableHeight,
}) => {
  const { t } = useTranslation()
  const { navigateTo } = useSdk()
  const { showPopup } = usePopup()
  const isMobile = useContext(MobileContext)

  const columns = React.useMemo(
    () => [
      ...(!isMobile
        ? [
            {
              Header: '',
              accessor: 'sort',
              width: 60,
              minWidth: 60,
              Cell: props => (
                <div className="relative bottom-[3px]">
                  <div
                    className={`h-[19px] mb-[2px] overflow-hidden cursor-pointer hover:text-zoyya-text text-zoyya-secondaryDark`}
                    onClick={e => {
                      e.stopPropagation()
                      moveItemUp(props.row.index)
                    }}
                  >
                    <icons.ArrowUpDrop stroke={2} size="large" />
                  </div>

                  <div
                    className={`h-[19px] mb-[2px] overflow-hidden cursor-pointer hover:text-zoyya-text text-zoyya-secondaryDark`}
                    onClick={e => {
                      e.stopPropagation()
                      moveItemDown(props.row.index)
                    }}
                  >
                    <icons.ArrowDownDrop
                      size="large"
                      stroke={2}
                      style={{ position: 'relative', top: '-9px' }}
                    />
                  </div>
                </div>
              ),
            },
          ]
        : []),
      {
        Header: t('translation.ResourcesList.label-firstName'),
        accessor: 'firstName',
        minWidth: 200,
        width: 200,
        Cell: props => (
          <TableItem>
            <span data-cy={'resourceFirstName'}>
              {props.cell.row.original?.firstName}
            </span>
          </TableItem>
        ),
      },
      {
        Header: t('translation.ResourcesList.label-color'),
        accessor: 'color',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <TableItem>
            <ColorItem
              color={props.cell.row.original?.color}
              data-cy={`color_${props.cell.row.original?.color}`}
            />
          </TableItem>
        ),
      },
      {
        Header: t('translation.ResourcesTable.vrsta'),
        accessor: 'professions',
        minWidth: 120,
        width: 120,
        Cell: props => (
          <TableItem>
            <span>
              {props.cell.row.original?.professions?.map(
                (profession, idx) =>
                  profession.name +
                  (idx + 1 < props.cell.row.original?.professions.length
                    ? ', '
                    : '')
              )}
            </span>
          </TableItem>
        ),
      },
      {
        Header: t('translation.ResourcesList.label-online'),
        accessor: 'bookingAllowed',
        minWidth: 80,
        width: 80,
        Cell: props => {
          return (
            <TableItem>
              {props.cell.row.original?.locationSettings?.onlineBooking !==
              'DISABLED' ? (
                <icons.CheckMarkIcon
                  style={{ marginLeft: '10px' }}
                  size={'smaller'}
                />
              ) : null}
            </TableItem>
          )
        },
      },
      {
        Header: t('translation.FooterNavigation.title-calendar'),
        accessor: 'showInCalendar',
        minWidth: 100,
        width: 100,
        Cell: props => {
          return (
            <TableItem>
              {props.cell.row.original?.showInCalendar ? (
                <icons.CheckMarkIcon
                  style={{ marginLeft: '30px' }}
                  size={'smaller'}
                />
              ) : null}
            </TableItem>
          )
        },
      },
      {
        Header: '',
        accessor: 'goToCalendar',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <TableItem>
            <Button
              buttonType={'secondary'}
              iconComponent={<icons.CalendarIcon size={'smaller'} />}
              hasIconOnly
              size={'smaller'}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                navigateTo.calendar({
                  date: getISODate(),
                  view: 'week',
                  selectedEmployeeId: props.cell.row.original?.id,
                })
              }}
              tooltip={t('translation.ResourcesList.label-show-in-calendar')}
            />
          </TableItem>
        ),
      },
      {
        Header: '',
        accessor: 'delete',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <Tooltip
            placement={'top'}
            overlayStyle={overlayStyle}
            overlayInnerStyle={overlayInnerStyle}
            overlay={
              <InfoOverlay>
                <InfoText>
                  {t('translation.ResourcesList.tooltip-remove-resource')}
                  <InfoLink
                    href={t('translation.HelpLinks.add-employee')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('translation.ResourcesList.label-here')}
                  </InfoLink>
                </InfoText>
              </InfoOverlay>
            }
            arrowContent={<div className="rc-tooltip-arrow-inner infoButton" />}
          >
            <TableItem>
              <Button
                buttonType={'secondary'}
                iconComponent={<icons.Trash size={'smaller'} />}
                hasIconOnly
                size={'smaller'}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  showPopup({
                    onConfirm: () =>
                      removeResource(
                        selectedResources.filter(
                          x => x.id !== props.cell.row.original?.id
                        )
                      ),
                    titleText: t(
                      'translation.ResourcesList.title-delete-resource',
                      {
                        firstName: props.cell.row.original?.firstName,
                        lastName: props.cell.row.original?.lastName,
                      }
                    ),
                  })
                }}
              />
            </TableItem>
          </Tooltip>
        ),
      },
    ],
    [t, isMobile, navigateTo, moveItemUp]
  )

  return (
    <>
      <ReactTable
        tableData={selectedResources}
        columns={columns}
        isUpdating={isUpdating}
        isMove={!isMobile}
        hasHeader
        onMove={updateResourceSequence}
        tableHeight={
          !tableHeight ? tableRef?.current?.clientHeight - 50 : tableHeight
        }
        onRowClick={item =>
          navigateTo.editResource({
            resourceId: item.id,
          })
        }
      />
    </>
  )
}
