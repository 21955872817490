import React from 'react'
import Text from 'components/Text/Text'
import {
  CheckboxInput,
  SmallCheckboxInput,
} from 'components/Form/Fields/Checkbox'
import styled from 'styled-components'
import { device } from '../../constants'

export const StyledCheck = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;

  @media ${device.tablet} {
    margin-left: 10px;
  }
`

export const Checkbox = props => {
  const { html, label, handleChange, checked, disabled, style, isSmall } = props
  return (
    <StyledCheck
      style={{
        ...style,
      }}
    >
      {isSmall ? (
        <SmallCheckboxInput
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          type={'checkbox'}
        />
      ) : (
        <CheckboxInput
          onChange={handleChange}
          style={{ marginRight: 15, height: 38 }}
          checked={checked}
          disabled={disabled}
          type={'checkbox'}
        />
      )}

      <div dangerouslySetInnerHTML={html ? { __html: html } : undefined}>
        {label ? (
          <Text
            style={{
              // marginBottom: '5px',
              display: 'flex',
            }}
            type="label"
          >
            {label}
          </Text>
        ) : null}
      </div>
    </StyledCheck>
  )
}
