import moment from 'moment'
import i18n from 'src/localization'

export const DateUtil = {
  toShortDateString: (date: Date | string) => {
    return moment(date).format(i18n.t('regionalFormats.date.momentFormatDate'))
  },
  minutesToDurationString: (durationMinutes: number) => {
    let hours = durationMinutes / 60
    let rhours = Math.floor(hours)
    let minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    const durationString =
      (rhours < 10 ? '0' + rhours : rhours) +
      ':' +
      (rminutes < 10 ? '0' + rminutes : rminutes)
    return durationString
  },
}
