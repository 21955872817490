import React from 'react'
import styled from 'styled-components'
import * as styles from 'components/Icon/Icons/style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Messages = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5.56 18.44a1.5 1.5 0 11-2.12 2.12 1.5 1.5 0 012.12-2.12M16 .5h0a7.29 7.29 0 00-6.49 4.075h0A5.6 5.6 0 006.212 3.5h0A5.773 5.773 0 00.5 9.333h0a5.773 5.773 0 005.712 5.833c.069 0 .136-.008.205-.01h0a5.632 5.632 0 008.325 1.178h0c.522.11 1.055.166 1.589.166 4.327 0 7.169-3.582 7.169-8s-3.173-8-7.5-8z"></path>
        <path d="M7 9.75h0a.25.25 0 11-.25.25h0A.25.25 0 017 9.75s0 0 0 0M12 9.75h0a.25.25 0 11-.25.25h0a.25.25 0 01.25-.25s0 0 0 0M17 9.75h0a.25.25 0 11-.25.25h0a.25.25 0 01.25-.25s0 0 0 0M.75 23h0a.25.25 0 11-.25.25h0A.25.25 0 01.75 23s0 0 0 0"></path>
      </g>
    </StyledIcon>
  )
}
