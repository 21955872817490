import React, { useContext } from 'react'
import Button from 'components/Button/Button'
import SearchBar from 'components/SearchBar/SearchBar'
import { useTranslation } from 'react-i18next'
import { StickyHeader } from 'views/Settings/styles'
import { Add } from 'components/Icon/Icons/Add'
import { Select } from 'components/Select2/Select'
import styled from 'styled-components'
import { MobileContext } from 'src/Context'
import { ButtonLabel } from 'components/Button/style'
import { grayDark } from 'components/style/colors'
import { components } from 'react-select'

const selectComponents = {
  SingleValue: props => {
    return (
      <components.SingleValue {...props}>
        {' ⭥ ' + props.selectProps?.value?.name}
      </components.SingleValue>
    )
  },
}
const selectStyles = {
  control: (styles, other) => {
    return {
      ...styles,
      minWidth: '210px',
      width: '210px',
      height: '40px !important',
      padding: '0 !important',
      marginLeft: '15px !important',
    }
  },
  valueContainer: styles => {
    return {
      ...styles,
      margin: 0,
      padding: '0 10px !important',
    }
  },

  clearIndicator: styles => {
    return {
      ...styles,
      display: 'none',
    }
  },
  indicatorSeparator: styles => {
    return {
      ...styles,
      display: 'none',
    }
  },
}

type TProps = {
  onNewButtonClick?: () => void
  newButtonTitle?: string
  tooltip?: string
  hasSearch: boolean
  onSearchChange: (arg0: string) => void
  hasNewButton?: boolean
  hasSorting?: boolean
  options?: any
  sortByValue?: any
  setSortByValue?: any
  messageList?: any
  clientsList?: any
  styleWrapper?: any
  placeholder: string
  defaultSearchValue?: string
}

export const ListHeader = (props: TProps) => {
  const {
    onNewButtonClick,
    newButtonTitle,
    tooltip,
    hasSearch,
    onSearchChange,
    hasNewButton,
    hasSorting,
    options,
    sortByValue,
    setSortByValue,
    messageList,
    clientsList,
    styleWrapper,
    placeholder,
    defaultSearchValue,
  } = props
  const isMobile = useContext(MobileContext)
  const { t } = useTranslation()
  const sortOptions = [
    { id: 'lastName_asc', name: t('translation.ListHeader.lastName-asc') },
    { id: 'lastName_desc', name: t('translation.ListHeader.lastName-desc') },
    { id: 'firstName_asc', name: t('translation.ListHeader.firstName-asc') },
    { id: 'firstName_desc', name: t('translation.ListHeader.firstName-desc') },
    { id: 'id_asc', name: t('translation.ListHeader.oldest') },
    { id: 'id_desc', name: t('translation.ListHeader.newest') },
  ]

  return (
    <StickyHeader
      messageList={messageList}
      clientsList={clientsList}
      data-intercom-target="List-Header-Button"
      data-cy="listHeaderButton"
      style={styleWrapper}
    >
      {hasNewButton ? (
        <Button
          onClick={onNewButtonClick}
          buttonType={'primary'}
          name="newClient"
          label={newButtonTitle}
          tooltip={tooltip}
          iconComponent={<Add size="smaller" stroke={3} />}
        />
      ) : null}

      {hasSearch ? (
        <SearchBar
          messageList={messageList}
          clientsList={clientsList}
          onChange={onSearchChange}
          isOpen
          placeholder={
            placeholder
              ? placeholder
              : messageList
              ? t('translation.ListHeader.label-search-messages')
              : t('translation.ListHeader.label-search-clients')
          }
          value={defaultSearchValue}
        />
      ) : null}

      {hasSorting ? (
        <Select
          closeMenuOnSelect
          value={sortOptions.find(option => option.id === sortByValue)}
          styles={selectStyles}
          controlShouldRenderValue={true}
          isSearchable={false}
          isClearable={false}
          placeholder={t('translation.ListHeader.sort')}
          customComponent={<StyledHeaderSortButton label="⭥" />}
          hideChevron={isMobile}
          options={sortOptions}
          components={selectComponents}
          onChange={val => {
            setSortByValue(val.id)
            localStorage.setItem('clientsSortBy', val.id)
          }}
        />
      ) : null}
      {/* {hasGrid ? (
        <ListHeaderRight>
          <ListHeaderButton
            isSelected={selectedView === 'list'}
            onClick={() => setSelectedView('list')}
          >
            <span>{t('translation.ListHeader.label-list')}</span>
          </ListHeaderButton>
          <ListHeaderButton
            isSelected={selectedView === 'grid'}
            onClick={() => setSelectedView('grid')}
          >
            <span>{t('translation.ListHeader.label-grid')}</span>
          </ListHeaderButton>
        </ListHeaderRight>
      ) : null} */}
    </StickyHeader>
  )
}

const StyledHeaderSortButton = styled(Button)`
  background-color: #f5f5f5;
  height: 50px;
  font-size: 23px;
  color: ${props => grayDark};
  border: none;
  border-left: 1.5px solid #dbdfe2;
  border-radius: 0;
  ${ButtonLabel} {
    line-height: 40px;
  }
`
