import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const MultipleAdd = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M22.035 14.964a5 5 0 11-7.07 7.072 5 5 0 017.07-7.072M18.5 16.5v4M16.5 18.5h4M.5 15.5h0a6 6 0 1112 0s0 0 0 0zM9.152 1.598a3.75 3.75 0 11-5.304 5.304 3.75 3.75 0 015.304-5.304M18.652 1.598a3.75 3.75 0 11-5.304 5.304 3.75 3.75 0 015.304-5.304M19.47 10.605h0a6 6 0 00-6.458-.309"></path>
      </g>
    </StyledIcon>
  )
}
