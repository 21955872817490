import React from 'react'

interface IFormFooterButtonsContainer {
  children: React.ReactNode
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}
export const FormFooterButtonsContainer = (
  props: IFormFooterButtonsContainer
) => {
  const { children } = props
  return (
    <div
      className={`border-box m-0 p-4 min-h-[85px] 
                flex justify-end item-center z-10 w-full 
                [&_button]:h-[50px] [&_button]:flex-1 
                [&_button]:flex [&_button]:items-center 
                [&_button]:justify-center [&_button]:first:mr-3
                shadow-xs lg:p-5 lg:pl-0 lg:first:[&_button]:mr-6 
                lg:shadow-none lg:min-h-[75px] lg:justify-start 
                lg:items-stretch lg:[&_button]:h-auto lg:[&_button]:block 
                lg:[&_button]:flex-initial [&_button]:text-[16px] 
                lg:[&_button]:text-md [&_button]:mr-3 ${props.className || ''}`}
    >
      {children}
    </div>
  )
}
