import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import styled from 'styled-components'
import { device } from '../constants'
import { useLocation } from 'react-router'
import { useGetUnconfirmedBookings } from 'state/hooks/useGetUnconfirmedBookings'
import { NavLink } from 'react-router-dom'
import { getLocationId, getOrgId } from 'src/helpers'
import moment from 'moment'
import { SidebarIconWrapper } from 'src/sdk/tw/components/SidebarLink'
import { useSdk } from 'sdk'
import { WizardContext } from 'src/Context'
import { icons } from 'components/Icon'
import { trpc } from 'src/trpc'

export const ClientMobileFooter = props => {
  const { pathname } = useLocation()
  const { t } = useSdk()
  const [isOpen, setIsOpen] = useState(false)
  const footer = useContext(FooterContext)
  const footerOptions = footer?.getOptions()
  const wizardContext = useContext<any>(WizardContext)
  const locationId = getLocationId()
  const orgId = getOrgId()

  if (!locationId || !orgId) return null
  const { data: pendingBookingsCountData, refetch } =
    trpc.unconfirmedBookingCount_get.useQuery({
      locationId: BigInt(locationId),
      orgId,
    })

  trpc.onUnconfirmedBookingCountChanged.useSubscription(
    { locationId: getLocationId(), orgId: getOrgId() },
    {
      onData: () => {
        refetch()
      },
    }
  )
  const pendingReservations = pendingBookingsCountData || 0

  // close options drawer when clicked outside of it
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (event.target.classList.contains('overlay')) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  const calendarView = localStorage.getItem('calendarView') || 'threeDay'

  return (
    <Footer onClick={() => wizardContext?.setIsOpen(false)}>
      <FooterWrapper isMobile>
        <NavigationButtons isMobile>
          <NavigationItemWrapper>
            <NavigationItem
              to={`/${getOrgId()}/${getLocationId()}/events/day/${moment().format(
                'YYYY-MM-DD'
              )}/calendar/${calendarView}`}
              isMobile
              isSelected={pathname.startsWith(
                `/${getOrgId()}/${getLocationId()}/events`
              )}
              data-cy={'Footer-Calendar'}
            >
              <NavSection>
                <icons.CalendarIcon size={'medium'} />
              </NavSection>
            </NavigationItem>
          </NavigationItemWrapper>
          <NavigationItemWrapper>
            <NavigationItem
              to={`/${getOrgId()}/${getLocationId()}/rezervations`}
              isMobile
              isSelected={pathname.startsWith(
                `/${getOrgId()}/${getLocationId()}/rezervations`
              )}
              data-cy={'Footer-Reservations'}
            >
              <NavSection>
                <SidebarIconWrapper>
                  {pendingReservations > 0 && (
                    <NavigationNumber
                      style={{
                        top: 10,
                      }}
                      name="sidebar_numberOfRequests"
                      data-cy="sidebar_numberOfRequests"
                    >
                      <span data-cy="numberOfPendingRequests">
                        {pendingReservations}
                      </span>
                    </NavigationNumber>
                  )}
                  <icons.Shop size={'medium'} />
                </SidebarIconWrapper>
              </NavSection>
            </NavigationItem>
          </NavigationItemWrapper>

          <NavigationItemWrapper>
            <NavigationItem
              to={`/${getOrgId()}/${getLocationId()}/clients/list`}
              isMobile
              isSelected={pathname.startsWith(
                `/${getOrgId()}/${getLocationId()}/clients`
              )}
              onClick={() => {
                sessionStorage.setItem('clientListLastIndexFetched', '')
                sessionStorage.setItem('clientListSearchValue', '')
              }}
              data-cy={'Footer-Clients'}
            >
              <NavSection>
                <icons.Clients size={'medium'} />
              </NavSection>
            </NavigationItem>
          </NavigationItemWrapper>
          <NavigationItemWrapper>
            <NavigationItem
              to={`/${getOrgId()}/${getLocationId()}/settings`}
              isMobile
              isSelected={pathname.startsWith(
                `/${getOrgId()}/${getLocationId()}/settings`
              )}
              data-cy={'Footer-Settings'}
            >
              <NavSection>
                <icons.More size={'medium'} />
              </NavSection>
            </NavigationItem>
          </NavigationItemWrapper>
        </NavigationButtons>
      </FooterWrapper>
      {footerOptions?.showAddButton === true &&
      footerOptions?.addButtonOptions ? (
        footerOptions?.addButtonOptions?.length === 1 &&
        !footerOptions?.drawer ? (
          <FooterPlusButton
            onClick={footerOptions?.addButtonOptions[0].onClick}
          >
            <icons.Add
              style={{
                stroke: 'white',
              }}
            />
          </FooterPlusButton>
        ) : (
          <FooterPlusButton onClick={() => setIsOpen(true)}>
            {footerOptions?.addButtonIcon === 'Add' ? (
              <icons.Add
                style={{
                  stroke: 'white',
                }}
              />
            ) : (
              <icons.ThreeDots
                size={'medium'}
                style={{
                  fill: '#fff',
                }}
              />
            )}
          </FooterPlusButton>
        )
      ) : null}
      {isOpen ? (
        <FooterOptionsOverlay className="overlay">
          <FooterOptions ref={wrapperRef}>
            {footerOptions?.hasTitle === false ? null : (
              <FooterOptionsTitle>
                {t('translation.Footer.choose-an-option')}
              </FooterOptionsTitle>
            )}
            {footerOptions?.addButtonOptions?.map((option, idx) => {
              if (!option) return null
              return option?.component ? (
                <FooterOptionComponent
                  key={idx}
                  onClick={() => setIsOpen(false)}
                >
                  {option?.component}
                </FooterOptionComponent>
              ) : (
                <FooterOption
                  key={idx}
                  onClick={() => {
                    option?.onClick()
                    setIsOpen(false)
                  }}
                >
                  {option?.icon ? (
                    <IconWrapper>{option?.icon}</IconWrapper>
                  ) : null}
                  <FooterOptionTitle>{option?.title}</FooterOptionTitle>
                </FooterOption>
              )
            })}
            {footerOptions?.drawer ? footerOptions?.drawer : null}
          </FooterOptions>
        </FooterOptionsOverlay>
      ) : null}
    </Footer>
  )
}

type TFooterOptions = {
  addButtonOptions?: {
    onClick: () => any
    title?: string
    icon?: any
    component?: any
  }[]
  showAddButton?: boolean
  addButtonIcon?: string
  drawer?: any
  hasTitle?: boolean
}
type TFooter = {
  setOptions: (options: TFooterOptions) => any
  getOptions: () => TFooterOptions
}
export const FooterContext = React.createContext<TFooter | undefined>(undefined)

export const useFooter = (
  options?: TFooterOptions | (() => TFooterOptions),
  deps?: React.DependencyList
) => {
  const footerContext = useContext(FooterContext)
  const contextRef = useRef(footerContext)
  const getOptionsCallback =
    typeof options === 'function' ? options : () => options
  const getOptions = useCallback(getOptionsCallback, deps || [])
  const hasOptions = !!options
  useEffect(() => {
    const hc = contextRef.current
    if (!hc) return
    if (!hasOptions) return
    const optionsResolved = getOptions()
    if (hc && optionsResolved) {
      hc.setOptions(optionsResolved)
    }
  }, [hasOptions, getOptions])

  return footerContext
}

type TNavigationButtons = {
  isMobile?: boolean
}
export const NavigationButtons = styled.div<TNavigationButtons>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${props => (props.isMobile ? '0' : '1.429rem')};
  height: 60px;
  @media ${device.tablet} {
    height: 60px;
    max-height: 60px;
    overflow: hidden;
  }
`
type TNavigationItem = {
  isMobile?: boolean
}
export const NavigationItem = styled<any>(NavLink).attrs(props => ({
  activeClassName: 'active',
}))/* .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['isSelected', 'isSecondChild'].includes(prop),
  }) */ `
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  min-height: 50px;
  color: ${props => props.theme.colors.text};
  width: 100%;
  margin-bottom: 5px;
  svg {
    color: ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.secondaryDark};
  }
  svg.chavron {
    margin-right: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background: ${props =>
      props.isSelected ? 'rgb(220 221 229)' : 'transparent'};
    .nav-title {
      font-weight: bold;
    }
  }
  @media ${device.tablet} {
    margin-left: 0px;
    margin: 0px 1rem;
    padding: 15px 0px;
    min-height: 60px;
    border-bottom: 1px solid #dbdfe2;
    width: auto;
    &.active {
      background: initial;
    }
    svg {
      color: ${props =>
        props.isSelected
          ? props.theme.colors.primary
          : props.theme.colors.text};
    }
  }
`

type TFooterWrapper = {
  isMobile?: boolean
}
const FooterWrapper = styled.div<TFooterWrapper>`
  background: ${props => props.theme.colors.light};
  height: 100%;
  border-radius: 20px 20px 0 0;
  ${NavigationItem} {
    margin-top: 7px;
  }
`
export const NavigationItemWrapper = styled.div``

type TNavigationNumber = {
  name?: string
}
export const NavigationNumber = styled.div<TNavigationNumber>`
  width: 20px;
  height: 20px;
  background: ${props => props.theme.colors.accent5};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  right: 0px;
  top: -5px;
  span {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.colors.light};
  }
`

export const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
type TNavTitle = {
  isSelected?: boolean
}
export const NavTitle = styled.span<TNavTitle>`
  color: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.text};
  font-size: 8px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0.2px;
`

export const Footer = styled.div`
  width: 100%;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.1);
  min-height: 65px;
  max-height: 65px;
  height: 65px;
  z-index: 247483642;
  border-radius: 20px 20px 0 0;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media all and (display-mode: standalone) {
      /* CSS specific to standalone applications */
      max-height: 80px;
      height: 80px;
    }
  }
`
export const FooterPlusButton = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: #2c2c36;
  position: relative;
  bottom: 90px;
  margin: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    @media all and (display-mode: standalone) {
      /* CSS specific to standalone applications */
      bottom: 105px;
    }
  }
`

const FooterOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  position: absolute;
  bottom: 0;
  background-color: ${props => props.theme.colors.light};
  border-radius: 40px 40px 0 0;
  box-shadow: 0px 1px 8px 0px #00000026;
  padding: 21px 38px 5px;
  animation: footer 0.5s ease-out;

  @keyframes footer {
    0% {
      bottom: -300px;
    }
    100% {
      bottom: 0;
    }
  }
`

const FooterOptionsOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
`
export const FooterOption = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :not(:last-of-type) {
    border-bottom: 1px solid #e4e4e4;
  }
`

export const FooterOptionComponent = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterOptionTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 20px;
  height: 20px;
`

const FooterOptionsTitle = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`
