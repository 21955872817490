import create from 'zustand'
import produce from 'immer'

type PopupConfig = {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
  titleText: string
  contentText: string
  cancelButtonLabel?: string
  confirmButtonLabel?: string
}
type TPopupState = {
  popup: PopupConfig
}

const updateState = (state: (s: TPopupState) => void) => {
  setState(produce(state))
}

export const usePopupStore = create<TPopupState>(set => ({
  popup: {
    isVisible: false,
    onConfirm: () =>
      set(state => ({ popup: { ...state.popup, isVisible: false } })),
    onCancel: () =>
      set(state => ({ popup: { ...state.popup, isVisible: false } })),
    titleText: '',
    contentText: '',
  },
}))

const { setState } = usePopupStore

export const PopupStore = {
  showPopup: () => {
    updateState(state => {
      state.popup.isVisible = true
    })
  },
  hidePopup: () => {
    updateState(state => {
      state.popup = {
        isVisible: false,
        onConfirm: () => null,
        onCancel: () => null,
        titleText: '',
        contentText: '',
      }
    })
  },
  setPopupConfig: (config: {
    onConfirm: () => void
    onCancel: () => void
    titleText: string
    contentText: string
    cancelButtonLabel?: string
    confirmButtonLabel?: string
  }) => {
    updateState(state => {
      state.popup = {
        isVisible: true,
        onCancel: config.onCancel,
        onConfirm: config.onConfirm,
        titleText: config.titleText,
        contentText: config.contentText,
        cancelButtonLabel: config.cancelButtonLabel,
        confirmButtonLabel: config.confirmButtonLabel,
      }
    })
  },
}
