import { useSdk } from 'sdk'
import { PopupStore } from 'src/state/local/popupStore'

export const usePopup = () => {
  const { t } = useSdk()
  const { setPopupConfig, hidePopup } = PopupStore

  const showPopup = (args: {
    onConfirm: () => void
    titleText: string
    contentText?: string
    onCancel?: () => void
    cancelButtonLabel?: string
    confirmButtonLabel?: string
  }) => {
    const {
      onConfirm,
      onCancel,
      titleText,
      contentText,
      cancelButtonLabel,
      confirmButtonLabel,
    } = args

    setPopupConfig({
      onConfirm: () => {
        onConfirm()
        hidePopup()
      },
      onCancel: () => {
        onCancel && onCancel()
        hidePopup()
      },
      titleText: titleText,
      contentText: contentText ? contentText : '',
      cancelButtonLabel:
        cancelButtonLabel || t('translation.Appointment.button-cancelText'),
      confirmButtonLabel:
        confirmButtonLabel || t('translation.Appointment.button-okText'),
    })
  }

  return { showPopup, hidePopup }
}
