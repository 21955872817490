import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../constants'
import Button from 'components/Button/Button'
import {
  ModalRightRow,
  PlanTitle,
} from 'views/Settings/Billing/Modals/OrgDataForm'
import { FormFooterContainer, Loader } from 'components'
import { useSdk } from 'sdk'
import { formatPriceNumberWithDelimiter, getPlanName } from 'src/helpers'
import moment from 'moment'
import { icons } from 'components/Icon'
import { usePaymentGetUpgradeSubscriptionAmountQuery } from 'state/graphql'

export const ConfirmationForm = ({
  selectedPlan,
  closeModal,
  setStep,
  cycle,
  org,
  onSubmit,
  isBusy,
  shouldShowPlanQuantity,
}) => {
  const { t } = useSdk()

  function getModalRightWarning(isDowngrade, isDowngradeAfter) {
    return (
      <ModalRightWarning isDowngrade={isDowngrade}>
        <span>
          {isDowngrade
            ? t('translation.ConfirmationForm.confirmationTextDowngrade')
            : isDowngradeAfter
            ? t('translation.ConfirmationForm.confirmationTextDowngradeAfter')
            : t('translation.ConfirmationForm.confirmationTextUpgrade')}
        </span>
      </ModalRightWarning>
    )
  }

  function getPaymentAmount(isDowngrade) {
    const upgradeAmount =
      cycle === '1 month'
        ? selectedPlan.upgradeChargeAmountMonthly
        : selectedPlan.upgradeChargeAmountYearly

    if (isDowngrade || !!org?.downgradePlan) {
      return 0
    }

    if (
      org?.subscriptionPlan === 'business' &&
      selectedPlan.id === 'advanced' &&
      upgradeAmount > 0
    ) {
      return upgradeAmount
    } else {
      return cycle === '1 month'
        ? selectedPlan.priceMonthly
        : selectedPlan.priceYearly
    }
  }

  const currentPlan = org?.downgradePlan || org?.subscriptionPlan
  const isDowngrade =
    (currentPlan === 'advanced' && selectedPlan.id === 'free') ||
    (currentPlan === 'business' && selectedPlan.id === 'free') ||
    (currentPlan === 'advanced' && selectedPlan.id === 'business')

  const { data, loading } = usePaymentGetUpgradeSubscriptionAmountQuery({
    variables: {
      input: {
        cycle,
        planId: selectedPlan.id,
      },
    },

    skip:
      isDowngrade || (org.downgradePlan && org.downgradePlan !== 'business'),
  })

  const totalUpgradeAmount =
    data?.payment?.getUpgradeSubscriptionAmount?.totalAmount || 0
  const unusedUpgradeAmount =
    data?.payment?.getUpgradeSubscriptionAmount?.unusedAmount || 0
  const originalUpgradeAmount =
    data?.payment?.getUpgradeSubscriptionAmount?.originalAmount || 0

  const activationDate =
    totalUpgradeAmount > 0
      ? t('translation.ConfirmationForm.now')
      : org?.downgradeDate
      ? moment(org.downgradeDate).format('DD.MM.YYYY')
      : moment(org?.subscriptionEndDate).format('DD.MM.YYYY')

  const getPeriod =
    cycle === '1 month'
      ? t('translation.ConfirmationForm.mjesecni')
      : t('translation.ConfirmationForm.godisnji')

  const advancedPlan = org?.paymentPlans?.plans?.find(x => x.id === 'advanced')
  const businessPlan = org?.paymentPlans?.plans?.find(x => x.id === 'business')
  const allFeatures = [...advancedPlan.features, ...businessPlan.features]

  const newFeatures =
    //if we are upgrading the advanced plan or downgrading to business
    selectedPlan.id === 'advanced' ||
    (currentPlan === 'advanced' && selectedPlan.id === 'business')
      ? advancedPlan.features
      : //if we are downgrading from the advanced to free
      currentPlan === 'advanced' && selectedPlan.id === 'free'
      ? allFeatures
      : businessPlan.features

  return (
    <>
      <ModalCOntainer data-cy={'orgForm'} style={{ minHeight: 300 }}>
        {(loading || isBusy) && <Loader isComponent />}
        <BodyTextSection>
          <Row>
            <Label>{t('translation.ConfirmationForm.currentPlan')}</Label>
            <Text>{getPlanName(org?.subscriptionPlan) + ' plan'}</Text>
          </Row>
          {org?.downgradePlan && (
            <>
              <Row>
                <Label>{t('translation.ConfirmationForm.expires')}</Label>
                <Text>{moment(org?.downgradeDate).format('DD.MM.YYYY')}</Text>
              </Row>
              <Row>
                <Label>{t('translation.ConfirmationForm.movesTo')}</Label>
                <Text>{getPlanName(org?.downgradePlan) + ' plan'}</Text>
              </Row>
              <Divider />
            </>
          )}
          <Row>
            <Label>{t('translation.ConfirmationForm.newPlan')}</Label>
            <Text>{getPlanName(selectedPlan.id) + ' plan'}</Text>
          </Row>
          <Row>
            <Label>{t('translation.ConfirmationForm.becomesActive')}</Label>
            <Text>{activationDate}</Text>
          </Row>
          <Row>
            <Label>{t('translation.ConfirmationForm.cycle')}</Label>
            <Text>{getPeriod}</Text>
          </Row>
          {totalUpgradeAmount > 0 && org?.subscriptionCreditCardNumber && (
            <Row>
              <Label>{t('translation.ConfirmationForm.paymentMethod')}</Label>
              <>
                <CardHeader>
                  <span>{org?.subscriptionCreditCardType}</span>
                </CardHeader>
                <CardDetail>
                  {/*  <span> {org?.subscriptionCreditCardHolderName}</span> */}
                  <span>
                    {org?.subscriptionCreditCardType?.startsWith('SEPA')
                      ? `HR********${org?.subscriptionCreditCardNumber}`
                      : `**** **** **** ${org?.subscriptionCreditCardNumber}`}
                  </span>
                </CardDetail>
              </>
            </Row>
          )}

          {shouldShowPlanQuantity && (
            <Row>
              <Label>{t('translation.ConfirmationForm.quantity')}</Label>
              <Text>{org?.subscriptionQuantity}</Text>
            </Row>
          )}

          {totalUpgradeAmount > 0 ? (
            <>
              <Divider />
              {originalUpgradeAmount ? (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Label>{t('translation.ConfirmationForm.full-amount')}</Label>
                  <Text isBold>
                    {formatPriceNumberWithDelimiter(originalUpgradeAmount) +
                      ' '}
                    {org?.stripeCurrencyId}
                  </Text>
                </Row>
              ) : null}
              {unusedUpgradeAmount ? (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Label>{t('translation.ConfirmationForm.unused-time')}</Label>
                  <Text isBold>
                    {formatPriceNumberWithDelimiter(unusedUpgradeAmount) + ' '}
                    {org?.stripeCurrencyId}
                  </Text>
                </Row>
              ) : null}
              <Row
                style={{ marginBottom: 20, justifyContent: 'space-between' }}
              >
                <Label>{t('translation.ConfirmationForm.totalAmount')}</Label>
                <Text isBold>
                  {formatPriceNumberWithDelimiter(totalUpgradeAmount) + ' '}
                  {org?.stripeCurrencyId}
                </Text>
              </Row>
            </>
          ) : (
            <div style={{ marginBottom: 20 }} />
          )}
          {getModalRightWarning(isDowngrade, !!org?.downgradePlan)}
        </BodyTextSection>
        <ModalRight>
          <PlanTitle>
            {isDowngrade
              ? t('translation.ConfirmationForm.featuresLossed')
              : t('translation.ConfirmationForm.featuresGained')}
          </PlanTitle>
          {newFeatures?.map((feature, idx) => {
            return (
              <ModalRightRow key={idx} isDowngrade={isDowngrade}>
                {isDowngrade ? (
                  <icons.Cross stroke={3} size={'extraSmall'} color={'red'} />
                ) : (
                  <icons.CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
                )}
                <span>{feature.name}</span>
              </ModalRightRow>
            )
          })}
        </ModalRight>
      </ModalCOntainer>
      <FormFooterContainer
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            buttonType={'link'}
            type="button"
            name={'back'}
            label={t('translation.OrgDataForm.natrag')}
            iconComponent={<icons.ArrowLeft />}
            footerButton
            onClick={e => {
              setStep(!!org?.subscriptionMandateId ? 1 : 2)
              e.preventDefault()
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            buttonType={'text'}
            type="button"
            name={'close'}
            label={t('translation.OrgDataForm.close')}
            footerButton
            onClick={e => {
              e.preventDefault()
              closeModal()
            }}
          />
          <Button
            buttonType={'primary'}
            onClick={onSubmit}
            disabled={isBusy}
            name={'saveChanges'}
            iconComponent={<icons.CheckFilled size={'extraSmall'} />}
            iconRight
            label={
              isDowngrade || !!org?.downgradePlan
                ? t('translation.ConfirmationForm.confirm')
                : t('translation.ConfirmationForm.pay')
            }
          />
        </div>
      </FormFooterContainer>
    </>
  )
}

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  span {
    color: ${props => props.theme.colors.text};
  }
`

export const ModalRightWarning = styled.div<{ isDowngrade?: boolean }>`
  background: ${props => (props.isDowngrade ? '#ffe8e8' : '#fff4d4')};
  border: ${props =>
    props.isDowngrade ? '1px solid #ff0000' : '1px solid #ffbd00'};
  color: ${props => (props.isDowngrade ? '#bb0000' : '#bb8a00')};
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  margin-top: auto;
  max-width: 100%;
  min-width: 400px;

  @media ${device.mobileL} {
    min-width: 100%;
  }
`

export const ModalRightTitle = styled.span`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  position: relative;
`

export const Currency = styled.span`
  position: absolute;
  margin-left: 5px;
  font-size: 14px;
  margin-top: 3px;
`

export const ModalCOntainer = styled.div`
  display: flex;
  padding: 0px 20px 0px 0px;
  justify-content: space-around;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0px;
  }
`

export const ModalRight = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.outline};
  flex: 1;
  background: ${props => props.theme.colors.light};
  border-radius: 6px;
  max-width: 300px;
  margin: 30px 0px;
  padding-bottom: 20px;

  @media ${device.tablet} {
    margin: 0px 30px 20px;
    max-width: 100%;
  }
`

export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 30px;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 5px 20px;
    min-height: 100%;
  }
`

export const BodyImage = styled.img`
  margin-top: 20px;
  max-height: 350px;
  flex: 2;
  min-width: 440px;

  @media ${device.tablet} {
    min-width: 100vw;
    max-height: 40vh;
    margin-top: 30px;
  }
`

export const BodyTitle = styled.span`
  font-size: 32px;
  font-weight: 700;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 44px;
  margin-bottom: 10px;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`

export const BodyTextSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 50px 30px 30px;
  flex: 1;

  @media ${device.tablet} {
    margin: 30px;
  }
`

export const BodyText = styled.span``

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  button:first-child {
    margin-right: 15px;
  }
`

export const Label = styled.span`
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  margin-right: 10px;
  min-width: 135px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`

export const Text = styled.span<{ isBold?: boolean }>`
  font-weight: ${props => (props.isBold ? '600' : '400')};
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${props => props.theme.colors.outline};
  margin: 10px 0px;
`

export const LabelTotal = styled.span`
  font-size: 18px;
  font-weight: 600;
`
