import styled from 'styled-components'
import { InputStyled, InputFieldWrapper } from '../Input/style'
import { device } from '../../constants'

export const SearchBarWrapper = styled.div<{
  isMobile?: boolean
  isHeader: boolean
  messageList?: boolean
  clientsList?: boolean
  style?: any
}>`
  width: ${props => (props.isMobile ? 'auto' : '100%')};
  position: relative;
  margin: ${props => (props.isMobile ? '3px' : '0')};
  margin-right: ${props => (props.isHeader ? 'auto' : '0px')};
  margin-left: ${props =>
    props.messageList || props.clientsList ? '0' : '14px'};
  ${InputStyled} {
    margin-bottom: 0px;
    border: ${props => props.clientsList && '0'};
    font-weight: ${props => props.clientsList && '300'};
    background: ${props => props.clientsList && '#F5F5F5'};
    height: ${props => props.clientsList && '50px'};
    :focus {
      border: ${props => props.clientsList && '0'};
      box-shadow: ${props => props.clientsList && 'none'};
      background: ${props => props.clientsList && '#F5F5F5'};
    }
  }
  ${InputFieldWrapper} {
    svg {
      top: ${props => props.clientsList && '13px'};
      stroke: ${props => props.clientsList && 'none'};
    }
  }

  @media ${device.tablet} {
    margin-left: ${props =>
      props.messageList || props.clientsList ? '0' : '14px'};
  }
`
