import React from 'react'
import { useGetLocationLoading } from 'state/queries'
import { useParams } from 'react-router'
import { ClientForm } from './ClientForm'
import { ClientHealthForm } from './ClientHealthForm'
import { Loader } from 'components'

type TClientFormSProps = {
  values: any // tu stavit clientData tip sa podacima
  errors: any
  setFieldValue: any
  showSendInviteButton?: boolean
  onSendInviteClick?: any
  isEdit?: boolean
  setActiveForm?: any
  isAppointment?: boolean
  refetchClient?: () => any
}
export const ClientFormSwitcher = (props: TClientFormSProps) => {
  const { locationId } = useParams<any>()
  const { data: locationData, loading } = useGetLocationLoading(locationId)
  const location = locationData?.location?.get

  if (loading) return <Loader isComponent />

  return location?.kind === 'HEALTH' ? (
    <ClientHealthForm {...props} />
  ) : (
    <ClientForm
      {...props}
      location={location}
      isAppointment={props.isAppointment}
    />
  )
}
