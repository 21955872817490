import { css } from 'styled-components'

export const base = css`
  display: inline-block;
  fill: currentcolor;
  stroke: currentColor;
`
export const extraSmall = css`
  width: 12px;
  height: 12px;
`
export const size14 = css`
  width: 14px;
  height: 14px;
`
export const size16 = css`
  width: 16px;
  height: 16px;
`

export const smaller = css`
  width: 18px;
  height: 18px;
`
export const size20 = css`
  height: 20px;
  width: 20px;
`

export const small = css`
  width: 22px;
  height: 22px;
`

export const medium = css`
  width: 26px;
  height: 26px;
`

export const large = css`
  width: 32px;
  height: 32px;
`

export const extraLarge = css`
  width: 40px;
  height: 40px;
`

export const extraExtraLarge = css`
  width: 50px;
  height: 50px;
`
