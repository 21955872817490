import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const ReviewsBook = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.5 2.5h11a1 1 0 011 1v1"></path>
        <path d="M19.5 4.5v-3 0a1 1 0 00-1-1h-13 0a2 2 0 000 4h14a1 1 0 011 1v17a1 1 0 01-1 1h-14 0a2 2 0 01-2-2s0 0 0 0v-19"></path>
        <path d="M12.7 7.93l1.539 3.57h2.995a.735.735 0 01.517 1.283l-2.6 2.316 1.44 3.313h0a.785.785 0 01-1.117.982L12 17.433l-3.48 1.961h0a.785.785 0 01-1.12-.982L8.841 15.1l-2.6-2.315h0a.735.735 0 01.515-1.285h2.995l1.541-3.57h0a.8.8 0 011.408 0z"></path>
      </g>
    </StyledIcon>
  )
}

export default ReviewsBook
