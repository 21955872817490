import React, { useContext } from 'react'
import { FormField } from '../../../components'
import { useSdk } from '../../../sdk'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { CheckMarkIcon } from '../../../components/Icon/Icons/Checkmark'
import { ThemeContext } from 'styled-components'
import transparentize from 'polished/lib/color/transparentize'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { WarningTriangle } from '../../../components/Icon/Icons/WarningTriangle'
import { ItemRow, SelectRow, WorkHourHint, WorkHourName } from './styles'

type TResourceTypesSelectProps = {
  resourceTypes: any
  name: string
  label: string
}

export const ResourceTypesSelect = (props: TResourceTypesSelectProps) => {
  const { resourceTypes, name, label } = props
  const { t } = useSdk()

  const formatOptionLabel = e => {
    return e?.firstName
  }

  const Option = props => {
    const { t } = useTranslation()
    const isSelected = props.selectProps.value?.id === props.data?.id

    return (
      <components.Option {...props}>
        <SelectRow>
          <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
            {isSelected && <CheckMarkIcon color="grayDarker" />}
          </span>
          <WorkHourName
            isInactive={props?.data?.isInactive}
            isDisabled={props?.data?.inappropriate}
            isBusy={props?.data?.isBusy}
          >
            {props.data.firstName}
          </WorkHourName>

          {props.data.isInactive ? (
            <WorkHourHint isInactive={props.data.isInactive}>
              {t('translation.EmployeeSelect.offDuty')}
            </WorkHourHint>
          ) : null}
          {props.data.isBusy ? (
            <WorkHourHint isBusy={props.data.isBusy}>
              {t('translation.StartTimeSelect.label-busy')}
            </WorkHourHint>
          ) : null}
          {props.data.inappropriate ? (
            <WorkHourHint isDisabled={props.data.inappropriate}>
              {t('translation.StartTimeSelect.label-wrong-type')}
            </WorkHourHint>
          ) : null}
        </SelectRow>
      </components.Option>
    )
  }
  const SingleValue = props => {
    const { t } = useTranslation()

    const currentOption = props?.options?.find(
      opt => opt?.id === props?.data?.id
    )

    // because between rerenders sometimes isInactive prop is undefined
    const isInactive = props?.data?.isInactive ?? currentOption?.isInactive
    const isBusy = props?.data?.isBusy ?? currentOption?.isBusy

    return (
      <components.SingleValue {...props}>
        <ItemRow>
          <WorkHourName>{props?.data?.firstName}</WorkHourName>

          {isInactive ? (
            <Tooltip
              label={t('translation.EmployeeSelect.offDuty')}
              style={{ marginLeft: 'auto' }}
            >
              <WarningTriangle
                size={'small'}
                color={selectedTheme.colors.accent5}
              ></WarningTriangle>
            </Tooltip>
          ) : null}
          {isBusy ? (
            <Tooltip label={'Zauzet'} style={{ marginLeft: 'auto' }}>
              <WarningTriangle
                size={'small'}
                color={selectedTheme.colors.accent4}
              ></WarningTriangle>
            </Tooltip>
          ) : null}
        </ItemRow>
      </components.SingleValue>
    )
  }

  const MobileOption = props => {
    const { t } = useTranslation()
    const isSelected = props.selectProps.value?.id === props.data?.id

    return (
      <SelectRow>
        <span className="mr-[6px!important] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>

        <WorkHourName
          isInactive={props?.data?.isInactive}
          isDisabled={props?.data?.inappropriate}
          isBusy={props?.data?.isBusy}
        >
          {props.data.firstName}
        </WorkHourName>

        {props.data.isInactive ? (
          <WorkHourHint isInactive={props.data.isInactive}>
            {t('translation.EmployeeSelect.offDuty')}
          </WorkHourHint>
        ) : null}
        {props.data.isBusy ? (
          <WorkHourHint isBusy={props.data.isBusy}>
            {t('translation.StartTimeSelect.label-busy')}
          </WorkHourHint>
        ) : null}
      </SelectRow>
    )
  }

  const selectComponents = {
    Option,
    MobileOption,
    SingleValue,
  }

  const selectedTheme = useContext(ThemeContext)
  const selectStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      boxShadow: 'none',
      minWidth: '200px',
    }),
    singleValue: styles => ({
      ...styles,
      width: '100%',
    }),
    menu: styles => ({
      ...styles,
      minWidth: '340px',
      left: '-160px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
        backgroundColor: isDisabled
          ? null
          : isFocused
          ? transparentize(0.9, selectedTheme.colors.primary)
          : null,
        fontWeight: isSelected ? 500 : 400,
        ':active': {
          ...styles[':active'],
          backgroundColor: transparentize(0.9, selectedTheme.colors.primary),
        },
      }
    },
  }

  return (
    <FormField.Select
      label={label}
      options={resourceTypes}
      closeMenuOnSelect
      hideSelectedOptions={false}
      components={selectComponents}
      styles={selectStyles}
      isDisabled={false}
      formatOptionLabel={formatOptionLabel}
      formatValue={val => val?.id}
      parseValue={val => {
        return resourceTypes?.find(e => e.id === val)
      }}
      placeholder={t('translation.EmployeeSelect.selectEmployee')}
      isSearchable={false}
      isClearable={false}
      name={name}
    />
  )
}
