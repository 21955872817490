import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Group = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M22.5 23.5h0a5.367 5.367 0 00-9 0M10.5 23.5h0a5.367 5.367 0 00-9 0M16.5 11h0a5.367 5.367 0 00-9 0M9.249 2.1h0a5.4 5.4 0 003.884 1.65h0a5.4 5.4 0 002.138-.44" />
        <path d="M14.348 1.452a3.25 3.25 0 11-4.596 4.596 3.25 3.25 0 014.596-4.596M3.249 14.6h0a5.4 5.4 0 006.022 1.21" />
        <path d="M8.348 13.952a3.25 3.25 0 11-4.596 4.596 3.25 3.25 0 014.596-4.596M15.249 14.6h0a5.4 5.4 0 006.022 1.21" />
        <path d="M20.348 13.952a3.25 3.25 0 11-4.596 4.596 3.25 3.25 0 014.596-4.596" />
      </g>
    </StyledIcon>
  )
}
