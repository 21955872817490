import React, { useMemo } from 'react'
import {
  useProfessionDeleteMutation,
  useProfessionAllQuery,
  ProfessionAllDocument,
} from 'state/graphql'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { useHeader } from 'mobile/Header'
import { icons } from 'components/Icon'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import { DataTable } from 'src/sdk/tw/DataTable'
import { asNonNullable } from 'src/helpers'
import { Column } from 'react-table'
import { useFooter } from 'src/mobile/Footer'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'

type TypeProfessionsListProps = {
  selectedTabIndex?: number
  setSelectedTabIndex?: any
}

const ProfessionsList = (props: TypeProfessionsListProps) => {
  const { selectedTabIndex, setSelectedTabIndex } = props
  const params = useParams()
  const { t, appServices, navigateTo } = useSdk()
  const { showPopup } = usePopup()
  const { data, loading } = useProfessionAllQuery()

  const professions =
    data?.profession?.all
      ?.map(asNonNullable)
      ?.filter(x => x?.kind === 'USER') || []

  const [professionDeleteMutation] = useProfessionDeleteMutation()

  useHeader(
    {
      content:
        selectedTabIndex === 0
          ? t('translation.WorkHours.title-employees')
          : t('translation.ProfessionsListMobile.label-profession'),
      onGoBack: navigateTo.mobileBranchSettings,
    },
    [selectedTabIndex]
  )

  useFooter(
    {
      showAddButton: true,
      addButtonIcon: 'Add',
      addButtonOptions: [
        {
          onClick: () => {
            navigateTo.employeesList({ selectedTab: 0 })
            setSelectedTabIndex(0)
          },
          title: t('translation.EmployeesListMobile.label-employees'),
        },
        {
          onClick: () => {
            navigateTo.employeesList({ selectedTab: 1 })
            setSelectedTabIndex(1)
          },
          title: t('translation.EmployeesListMobile.label-professions'),
        },
        {
          onClick: () => navigateTo.newEmployee({}),
          title: t('translation.EmployeesListMobile.label-new-employee'),
          icon: <icons.Add size="smaller" />,
        },
        {
          onClick: () => navigateTo.newProfession({}),
          title: t('translation.EmployeesListMobile.label-new-professions'),
          icon: <icons.Add size="smaller" />,
        },
        null as any,
      ],
    },
    []
  )

  async function deleteProfessionItem(professionId) {
    try {
      let results = await professionDeleteMutation({
        variables: {
          id: professionId,
        },
        refetchQueries: [
          {
            query: ProfessionAllDocument,
          },
        ],
      })
      !results.errors
        ? appServices.toast.success(
            t('translation.ProfessionsList.toast-delete-success')
          )
        : appServices.toast.danger(results?.errors?.[0]?.message)
    } catch {
      appServices.toast.danger(
        t('translation.ProfessionsListMobile.toast-delete-error')
      )
    }
  }

  const columns: Column<typeof professions[0]>[] = useMemo(
    () => [
      {
        Header: t('translation.ProfessionsList.label-name'),
        accessor: 'name',
        minWidth: 200,
        Cell: props => props.cell.row.original?.name,
      },
      {
        Header: t('translation.Sidebar.label-employees'),
        accessor: 'resources',
        minWidth: 300,
        Cell: props =>
          props.cell.row.original?.resources
            ?.filter(Boolean)
            ?.map(
              (employee, idx) =>
                employee?.firstName +
                ' ' +
                employee?.lastName +
                (idx + 1 <
                (props.cell.row.original?.resources?.filter(Boolean)?.length ||
                  0)
                  ? ', '
                  : '')
            ),
      },
      {
        Header: '',
        id: 'delete',
        minWidth: 60,
        Cell: props => (
          <div className="flex self-end">
            <ButtonTW
              variant="secondary"
              icon={<icons.Trash size="smaller" stroke={2} />}
              size="small"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                showPopup({
                  onConfirm: () =>
                    deleteProfessionItem(props.cell.row.original?.id),
                  titleText: t(
                    'translation.ProfessionsList.title-delete-profession',
                    {
                      professionName: "'" + props.cell.row.original?.name + "'",
                    }
                  ),
                })
              }}
              name="deleteProfession"
            />
          </div>
        ),
      },
    ],
    []
  )

  return (
    <div className="flex flex-col h-full">
      <div className="justify-end hidden lg:flex pt-4 text-2xl pb-2 pl-10 pr-4">
        <ButtonTW
          onClick={() => navigateTo.newProfession(params)}
          icon={<icons.Add size="smaller" stroke={2} />}
          size="small"
          label={t('translation.ProfessionsList.label-add-profession')}
          name={'add-profession'}
          className="!h-[38px]"
        />
      </div>
      <div className="flex flex-1 lg:p-4 flex-col lg:pt-2">
        <DataTable
          data={professions || []}
          renderMobileCard={item => (
            <ProfessionMobileCard
              item={item}
              onClick={() =>
                showPopup({
                  onConfirm: () => deleteProfessionItem(item?.id),
                  titleText: t(
                    'translation.ProfessionsList.title-delete-profession',
                    {
                      professionName: "'" + item?.name + "'",
                    }
                  ),
                })
              }
            />
          )}
          columns={columns}
          isLoading={loading}
          emptyListTitle={t('translation.ProfessionsList.title-no-professions')}
          onRowClick={item => {
            navigateTo.editProfession({ id: item.id })
          }}
          mobileCardHeight={89}
        />
      </div>
    </div>
  )
}

const ProfessionMobileCard = (props: { item: any; onClick: () => void }) => {
  const { item, onClick } = props
  const resources = item?.resources?.filter(Boolean)

  return (
    <div
      key={item?.id}
      className="flex flex-row py-6 border-b border-b-zoyya-outline items-center mx-4"
    >
      <div className="flex flex-col flex-1 mr-3">
        <p className="font-medium mb-2">{item?.name}</p>
        <div className="text-sm">
          {resources?.length ? (
            resources?.map(
              (employee, idx) =>
                employee?.firstName +
                ' ' +
                employee?.lastName +
                (idx + 1 < resources?.length ? ', ' : '')
            )
          ) : (
            <p>-</p>
          )}
        </div>
      </div>
      <ButtonTW
        variant="orangeOutline"
        icon={<icons.Trash size="smaller" stroke={2} />}
        size="small"
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          onClick()
        }}
      />
    </div>
  )
}
export default ProfessionsList
