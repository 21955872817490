import React from 'react'
import ReactDOM from 'react-dom'

type LoaderTWProps = {
  isComponent?: boolean
  isInnerComponent?: boolean
}

export const LoaderTW = (props: LoaderTWProps) => {
  const { isComponent, isInnerComponent } = props
  return isComponent ? (
    <div
      className={`bg-white/40 position top-0 bottom-0 overflow-hidden right-0 lg:z-[99999999999999] z-[2147483642] absolute h-full w-full`}
      data-cy={'zoyya-loading'}
    >
      <div className="w-full h-full relative flex items-center justify-center">
        <div className="animate-spin inline-block bg-cover bg-[url('/loader.svg')] absolute w-[60px] h-[56px]"></div>
      </div>
    </div>
  ) : isInnerComponent ? (
    <div className="w-full h-full relative flex items-center justify-center">
      <div className="animate-spin inline-block bg-cover bg-[url('/loader.svg')] absolute w-[60px] h-[56px]"></div>
    </div>
  ) : (
    ReactDOM.createPortal(
      <div
        className={`bg-white/40 w-full position top-0 bottom-0 overflow-hidden right-0 lg:z-[99999999999999] z-[2147483642] fixed h-screen f-screen`}
        data-cy={'zoyya-loading'}
      >
        <div className="w-full h-full relative flex items-center justify-center">
          <div className="animate-spin inline-block bg-cover bg-[url('/loader.svg')] absolute w-[60px] h-[56px]"></div>
        </div>
      </div>,
      document.getElementById('react-select-portal-target') as any
    )
  )
}
