import React from 'react'
import { EmptyContainer, EmptyImage, EmptyText } from './styles'
import Button from '../Button/Button'

type TEmptyListProps = {
  title: string
  buttonLabel?: string
  buttonCallback?: any
  showButton?: boolean
  imageStyle?: any
}
export const EmptyList = (props: TEmptyListProps) => {
  const { title, buttonLabel, buttonCallback, showButton, imageStyle } = props
  return (
    <EmptyContainer>
      <EmptyImage style={imageStyle} src={'/assets/empty-box.svg'} />
      <EmptyText>{title}</EmptyText>
      {showButton && (
        <Button
          label={buttonLabel}
          buttonType={'primary'}
          onClick={buttonCallback}
          name={'emptyListButton'}
        />
      )}
    </EmptyContainer>
  )
}
