import styled from 'styled-components'
import { device } from 'src/constants'

export const AppLoading = styled.div<{ isComponent: boolean }>`
  background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
  width: 100%;
  position: ${props => (props.isComponent ? 'absolute' : 'fixed')};
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  height: ${props => (props.isComponent ? '100%' : '100vh')};
  width: ${props => (props.isComponent ? '100%' : '100vw')};
  z-index: 99999999999999;
  top: 0;
  @media ${device.tablet} {
    z-index: 2147483642;
  }
`

export const LoadingCenter = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Spinner = styled.div<{ image?: string }>`
  animation-name: rotateClockwise;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  display: inline-block;
  background-size: cover;
  background-image: url(${props => props.image});
  position: absolute;
  width: 60px;
  height: 56px;
  @keyframes rotateClockwise {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner2 = styled.div<{ image?: string }>`
  position: absolute;
  animation-name: rotateCounterClockwise;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  display: inline-block;
  background-size: cover;
  background-image: url(${props => props.image});
  width: 60px;
  height: 60px;
  @keyframes rotateCounterClockwise {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`

export const LargeBox = styled.div`
  height: 3em;
  width: 3em;
  background-color: #6a4ee1;
  outline: 1px solid transparent;
  position: fixed;
`

export const SmallBox = styled.div`
  height: 3em;
  width: 3em;
  background-color: #d2d2f5;
  position: fixed;
  z-index: 1;
  outline: 1px solid transparent;
  animation: smallBoxAnim 1.25s alternate infinite ease-in-out;

  @keyframes smallBoxAnim {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(0.75);
    }
  }
`
