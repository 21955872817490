import variables from './themes.scss'
const colorsDefault = {
  // DEFAULT THEME
  background: '#dcdcfb',
  backgroundDarker: '#f2f4f6',
  outline: '#f0f0f0',
  accent1: '#fc5a5a',
  accent2: '#00ca79',
  accent3: '#3bcbe8',
  accent4: '#ffbd00',
  accent5: '#fe9060',
  secondaryDark: '#98a9bc',
  secondaryDarker: '#778ca2',
  text: '#333',
  primary: '#6a4ee1',
  light: '#ffffff',
  accent: '#0062ff',
  gradientBackground: '#e9e9ff',
  mainBackground: '#e9e9ff',
  lightBackground: '#f4f4ff',
  primaryDark: '#482fb4',
  orgSettingsSidebar: '#483599',
  grayLight: '#dbdfe2',
  grayLighter: '#fafafa',
  grayDark: '#a8a8a8',
  ratingStarColor: '#ffc359',
}
const colorsDark = {
  // DARK THEME
  darkBackground: '#000000',
  darkBackgroundDarker: '#000000',
  darkOutline: '#44444f',
  darkAccent1: '#50b5ff',
  darkAccent2: '#ff9ad5',
  darkAccent3: '#3dd598',
  darkAccent4: '#ffc542',
  darkAccent5: '#fc5a5a',
  darkSecondaryDark: '#92929d',
  darkSecondaryDarker: '#778ca2',
  darkText: '#fafafb',
  darkPrimary: '#0062ff',
  darkLight: '#1c1c24',
  darkAccent: '#0062ff',
  darkGradientBackground: '#000000',
  darkMainBackground: '#246ace',
}
const themeProps = [
  'background',
  'backgroundDarker',
  'primary',
  'outline',
  'accent1',
  'accent2',
  'accent3',
  'accent4',
  'accent5',
  'secondaryDark',
  'secondaryDarker',
  'text',
  'light',
  'gradientBackground',
  'mainBackground',
  'lightBackground',
  'primaryDark',
  'blue',
  'grayLight',
]

const getThemeProps = themeName => {
  let colors = []

  for (let i = 0; i < themeProps.length; i++) {
    let firstLetter = themeProps[i].slice(0, 1).toUpperCase()
    let otherLetters = themeProps[i].slice(1, themeProps[i].length)
    colors[themeProps[i]] = variables[themeName + firstLetter + otherLetters]
  }

  return colorsDefault
}

export const themes = {
  default: {
    colors: getThemeProps(''),
  },
  dark: {
    colors: getThemeProps('dark'),
  },
  // deep: {
  //   colors: getThemeProps("deep")
  // },
  // base: {
  //   colors: getThemeProps("base")
  // },
  // light: {
  //   colors: getThemeProps("light")
  // }
}
