import React, { useContext, useEffect, useRef, useState } from 'react'
import { getLocationId, getOrgId } from 'src/helpers'
import { FieldColumn, FieldRow } from '../styles'
import * as Yup from 'yup'
import {
  Form,
  FormField,
  Button,
  FormContentContainer,
  FormFooterButtonsContainer,
  Loader,
} from 'components'
import { useSdk } from 'sdk'
import { useHeader } from 'mobile/Header'
import {
  FormFooterButtonsContainerMobile,
  FormPageContainer,
} from '../../../styles'
import {
  queryServiceTypes,
  useMutateUpdateServiceType,
} from '../../../state/queries'
import { useParams } from 'react-router'
import { searchServiceTypesGql } from '../../../state/gql'
import { useQuery } from '@apollo/client'
import { deleteServiceTypeGql } from 'state/gql'
import { Trash } from 'components/Icon/Icons/Trash'
import styled from 'styled-components'
import { MobileContext } from 'src/Context'

const Schema = t =>
  Yup.object().shape({
    name: Yup.string().required(
      t('translation.ProfessionEditorForm.validation-name-required')
    ),
  })

export const ServiceTypeEditor = () => {
  const {
    history,
    t,
    navigateTo,
    appServices,
    updateQueryCache,
    useMutation,
  } = useSdk()
  const { locationId, typeId } = useParams()

  const { data: serviceTypesData, loading: loadingServiceTypes } = useQuery(
    searchServiceTypesGql,
    {
      variables: { locationId: locationId },
    }
  )
  const [deleteServiceTypeMutation] = useMutation(deleteServiceTypeGql)
  const [serviceType, setServiceType] = useState(null)

  useEffect(() => {
    const serviceTypes = serviceTypesData?.serviceType?.search
    if (serviceTypes?.length > 0) {
      const type = serviceTypes?.find(x => x.id === typeId)
      setServiceType(type)
    }
  }, [serviceTypesData, typeId])

  const [mutateServiceType] = useMutateUpdateServiceType()

  const handleUpdateServiceType = async input => {
    let results = await mutateServiceType({
      variables: {
        input: { id: input.id, name: input.name, locationId: locationId },
      },
      refetchQueries: [
        {
          query: queryServiceTypes,
          variables: { locationId: locationId },
        },
      ],
    })

    appServices
      .handleMutationResult(
        results,
        t('translation.ServicesList.toast-service-type-changed')
      )
      .onSuccess(navigateTo.servicesList)
  }

  useHeader({
    content:
      serviceType?.name || t('translation.ServiceTypeEditor.edit-service-type'),
    onGoBack: navigateTo.servicesList,
  })
  const handleDeleteServiceType = async id => {
    if (serviceType?.services.length > 0) {
      appServices.toast.danger(
        t('translation.ServicesList.tooltip-cant-delete-service-type')
      )
      return
    }

    const removeServiceType = () => {
      updateQueryCache(
        {
          query: searchServiceTypesGql,
          variables: { locationId },
        },
        draft => {
          draft.serviceType.search = draft.serviceType.search.filter(
            e => e.id !== id
          )
        }
      )
    }
    const result = await deleteServiceTypeMutation({
      variables: { id },
      update: removeServiceType,
    })
    appServices.handleMutationResult(
      result,
      t('translation.ServicesList.toast-service-type-deleted')
    )

    navigateTo.servicesList({
      locationId,
    })
  }

  const isMobile = useContext(MobileContext)
  const formRef = useRef()

  return (
    <>
      {loadingServiceTypes && <Loader isComponent />}
      {serviceType?.id && (
        <FormPageContainer>
          <Form
            onSubmit={handleUpdateServiceType}
            validationSchema={() => Schema(t)}
            initialValues={serviceType}
          >
            {form => {
              formRef.current = form
              return (
                <FormContentContainer>
                  <FieldRow>
                    <FieldColumn>
                      <FormField.Text
                        autoFocus
                        label={t('translation.ProfessionEditorForm.label-name')}
                        name="name"
                      />
                    </FieldColumn>
                  </FieldRow>
                  <StyledButtonsContainer>
                    <Button
                      type={'button'}
                      onClick={() => handleDeleteServiceType(serviceType?.id)}
                      buttonType={'primaryAccent'}
                      label={t('translation.DeleteButton.label-delete')}
                      name="deleteServiceType"
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid',
                      }}
                      iconComponent={<Trash size={'smaller'} />}
                    />
                  </StyledButtonsContainer>
                  {!isMobile && (
                    <FormFooterButtonsContainer>
                      <Button
                        footerButton
                        buttonType={'text'}
                        name="close"
                        type={'button'}
                        label={t(
                          'translation.ProfessionEditorForm.button-close'
                        )}
                        onClick={() => {
                          history.push(
                            `/${getOrgId()}/${getLocationId()}/settings/organization/locations/${getLocationId()}/services/serviceType`
                          )
                        }}
                      />
                      <Button
                        type={'submit'}
                        buttonType={'primary'}
                        label={t(
                          'translation.ProfessionEditorForm.button-save'
                        )}
                        name="saveChanges"
                      />
                    </FormFooterButtonsContainer>
                  )}
                </FormContentContainer>
              )
            }}
          </Form>
        </FormPageContainer>
      )}
      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            footerButton
            buttonType={'text'}
            name="close"
            type={'button'}
            label={t('translation.ProfessionEditorForm.button-close')}
            onClick={() => {
              history.push(
                `/${getOrgId()}/${getLocationId()}/settings/organization/locations/${getLocationId()}/services/serviceType`
              )
            }}
          />
          <Button
            onClick={() => formRef.current.submitForm()}
            buttonType={'primary'}
            name="saveChanges"
            label={t('translation.ResourceEditorForm.button-save')}
          />
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}

const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 10px;
`
