import React from 'react'
import Text from 'components/Text/Text'
import { Strong } from 'views/Settings/Billing/Modals/BillingForm'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { CheckFilled } from 'components/Icon/Icons/CheckFilled'
import { useSdk } from 'sdk'
import {
  Step1Card,
  Step1TitleWrapper,
  Step1Image,
  Step1FeatureList,
  Step1Feature,
  Step1Pricing,
  Step1Wrapper,
  Step1Picker,
  Step1Price,
  Step1PDV,
} from 'views/Settings/Billing/Modals/style'
import { Loader } from 'components'
import { SmsPackage } from 'src/state/graphql'
import { formatPriceNumberWithDelimiter } from 'src/helpers'

type TPackageProps = {
  id: string
  packageName: string
  imageUrl: string
  isCurrentPackage: boolean
  selectPackage: () => void
  units: number
  price: number
  currency?: string
  country?: string
}

const Package = (props: TPackageProps) => {
  const {
    packageName,
    imageUrl,
    isCurrentPackage,
    selectPackage,
    currency,
    id,
    price,
    units,
    country,
  } = props

  const { t } = useSdk()

  return (
    <Step1Card
      data-cy={`package_${id}`}
      selected={isCurrentPackage}
      onClick={() => selectPackage()}
    >
      <Step1TitleWrapper>
        <Text type="span">{packageName}</Text>
        <Checkbox
          checked={isCurrentPackage}
          style={{
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 10,
          }}
        />
      </Step1TitleWrapper>
      <Step1Image src={imageUrl} />
      <Step1FeatureList>
        <Step1Feature>
          <CheckFilled size={'extraSmall'} color={'#6a4ee1'} />
          <Text type="span">
            {units} {t('translation.BillingContainer.units', { plural: 'S' })}
          </Text>
        </Step1Feature>
      </Step1FeatureList>
      <Step1Pricing>
        <Text type="span">
          <Step1Price>
            {formatPriceNumberWithDelimiter(
              parseFloat((price * (country === 'HR' ? 0.8 : 1)).toFixed(2))
            )}{' '}
            {currency}
          </Step1Price>
          {country === 'HR' ? (
            <Step1PDV>{t('translation.PlansForm.pdv')}</Step1PDV>
          ) : null}
        </Text>
      </Step1Pricing>
    </Step1Card>
  )
}

type TPackagesFormProps = {
  selectPackage: (id: string) => void
  selectedPackage: SmsPackage | null
  packages: SmsPackage[]
  isBusy: boolean
  currency?: string
  country?: string
}

export const PackagesForm = (props: TPackagesFormProps) => {
  const {
    selectPackage,
    selectedPackage,
    packages,
    currency,
    isBusy,
    country,
  } = props

  const { t } = useSdk()
  return (
    <div style={{ padding: '0px 20px 40px 20px' }} data-cy={'packageForm'}>
      {isBusy ? <Loader isComponent /> : null}
      <Step1Wrapper>
        <Text type="span">
          {t('translation.SmsPackagesForm.you-have-selected-the')}{' '}
          <Strong>
            {t('translation.CreditCardForm.packageName', {
              packageName: selectedPackage?.packageName,
            })}
          </Strong>
        </Text>
      </Step1Wrapper>
      <Step1Picker>
        {packages?.map(item => {
          return (
            <Package
              key={item?.id}
              id={item?.id}
              packageName={item?.packageName}
              imageUrl={item?.imageUrl || ''}
              units={item?.units}
              price={item?.price}
              selectPackage={() => selectPackage(item?.id)}
              isCurrentPackage={selectedPackage?.id === item?.id}
              currency={currency}
              country={country}
            />
          )
        })}
      </Step1Picker>
      {/* Treba dodati link koji vodi na cjenik SMS Paketa */}
      {/* <Row>
        <ButtonStyled
          buttonType="link"
          label={t('translation.SmsPackagesForm.learn-more-about-these-packages')}
          onClick={() => window.open('https://zoyya.com/pricing', '_blank')}
        />
      </Row> */}
    </div>
  )
}
