import { Button, FormContentContainer, Loader, icons } from 'components'
import { ClientRemindersUpgradePage } from 'components/UpgradePage/UpgradePage'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { device } from 'src/constants'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import { ModalBodyContainer } from 'src/styles'
import { trpc } from 'src/trpc'
import styled from 'styled-components'
import { EventBodyWrapper } from 'views/AppointmentModal/styles'
import { RemindersListComponent } from '../Clients/ClientRemindersListMobile'

export const AppointmentClientReminders = ({
  clientId,
  org,
  closeModal,
  onAddNewReminder,
}) => {
  const { t, appServices } = useSdk()
  const params = useParams<any>()
  const { showPopup } = usePopup()
  const trpcContext = trpc.useContext()
  const cancelReminderMutation = trpc.reminder_cancel.useMutation()

  const { data, isLoading: remindersIsLoading } = trpc.reminders_get.useQuery(
    {
      orgId: params.orgId,
      locationId: BigInt(params.locationId),
      clientId: BigInt(clientId || 0),
    },
    {
      enabled: !!clientId,
    }
  )
  const handleCancelReminder = async (id: bigint) => {
    try {
      const result = await cancelReminderMutation.mutateAsync({
        reminderId: id,
      })

      trpcContext.reminders_get.invalidate()
      appServices.toast.success(
        t('translation.ClientReminders.cancelation-success-toast')
      )
    } catch (err) {
      appServices.toast.danger(
        t('translation.ClientReminders.cancelation-error-toast')
      )
    }
  }

  const remindersData = data || []

  useEffect(() => {
    trpcContext.reminders_get.invalidate()
  }, [])

  if (org?.subscriptionPlan !== 'advanced') {
    return <ClientRemindersUpgradePage closeModal={closeModal} />
  }

  return (
    <ModalBodyContainer>
      {remindersIsLoading ? (
        <div style={{ minHeight: '500px' }}>
          <Loader isComponent />
        </div>
      ) : (
        <EventBodyWrapper
          style={{
            paddingTop: 0,
            position: 'relative',
            overflow: 'hidden',
            paddingBottom: 50,
          }}
        >
          {cancelReminderMutation.isLoading && <Loader isComponent />}
          <div className="lg:flex m-[20px] hidden pb-5 mb-3  border-b-1 border-zoyya-border sticky top-0 left-0 bg-white z-50">
            <Button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onAddNewReminder()
              }}
              buttonType={'primaryOutline'}
              iconComponent={<icons.Add size={'smaller'} />}
              name={'cancel'}
              type="button"
              disabled={false}
              label={t('translation.ClientReminders.add-new')}
            />
          </div>
          <div className="lg:hidden text-lg py-4 text-center border-b-1 bg-zoyya-light border-zoyya-border sticky top-0 pl-2 left-0 z-50">
            {t('translation.ClientReminders.reminders-label')}
          </div>
          <AppointmentClientRemindersFormContentContainer isAppointmentModal>
            <div className="lg:hidden mx-[16px] mb-4 mt-1">
              <Button
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onAddNewReminder()
                }}
                buttonType={'primaryOutline'}
                iconComponent={<icons.Add size={'smaller'} />}
                name={'cancel'}
                type="button"
                disabled={false}
                label={t('translation.ClientReminders.add-new')}
              />
            </div>
            <RemindersListComponent remindersData={remindersData} />
          </AppointmentClientRemindersFormContentContainer>
        </EventBodyWrapper>
      )}
    </ModalBodyContainer>
  )
}

const AppointmentClientRemindersFormContentContainer = styled(
  FormContentContainer
)`
  padding: 10px;
  height: calc(100% - 40px);
  @media ${device.tablet} {
    overflow: auto;
    height: auto;
  }
`
