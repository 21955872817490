import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const TaskListSearch = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.5 9.991v-4.5h-11V18.49l6 .001"></path>
        <path d="M17.5 8.991v-5.5 0a1 1 0 00-1-1h-4.448 0a3.329 3.329 0 00-6.1 0H1.5h0a1 1 0 00-1 1s0 0 0 0v17h0a1 1 0 001 1h9M6.5 8.49h5M6.5 11.49h5M6.5 14.49h3"></path>
        <path d="M20.232 13.819a4.529 4.529 0 11-6.405 6.404 4.529 4.529 0 016.405-6.404M23.5 23.49l-3.25-3.25"></path>
      </g>
    </StyledIcon>
  )
}
