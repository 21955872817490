import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  formatPriceNumberWithDelimiter,
  getISODate,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { RouterOutput, trpc } from 'src/trpc'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { format } from 'date-fns'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { Edit } from 'src/components/Icon/Icons/Edit'
import { useSdk } from 'src/sdk'
import { Button, icons } from 'src/components'
import { Column } from 'react-table'
import { DataTable } from 'src/sdk/tw/DataTable'
import Calendar from 'src/components/Icon/Icons/Calendar'
import moment from 'moment'
import { GiftCardChangelog } from './GiftCardChangelog'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import Tooltip from 'src/components/Tooltip/Tooltip'

type GiftCardScreenProps = {
  data: RouterOutput['giftCard_findOne']
  currency: string
  setIsPreviewMode: (value: boolean) => void
}

export const GiftCardScreen = (props: GiftCardScreenProps) => {
  const { navigateTo, t, isMobile, appServices } = useSdk()
  const [selectedTab, setSelectedTab] = useState<'services' | 'usage' | 'log'>(
    props?.data?.giftCardType === 'GIFTCARD' ? 'usage' : 'services'
  )

  const { data, currency, setIsPreviewMode } = props
  const params = useParams<{
    locationId: string
    orgId: string
    id: string
  }>()

  const trpcContext = trpc.useContext()
  const { locationId, orgId, id } = params

  const { data: giftCardUsage, isLoading: isLoadingGiftCardUsage } =
    trpc.giftCard_usage_findMany.useQuery({
      orgId: orgId,
      locationId: BigInt(locationId),
      giftCardId: BigInt(data?.id || 0),
    })
  const isGiftCardUsed =
    data?.giftCardType === 'GIFTCARD'
      ? data?.balance !== data?.value
      : !data?.items?.every(item => {
          const itemInTemplate = data?.items?.find(
            x => x.serviceId === item.serviceId
          )
          return itemInTemplate?.quantity === item.quantity
        })

  const resendClientEmailMutation =
    trpc.giftCard_resendClientEmail.useMutation()

  const handleResendClientEmail = async () => {
    try {
      const res = await resendClientEmailMutation.mutateAsync({
        giftCardId: BigInt(id),
        locationId: BigInt(locationId),
        orgId,
      })

      appServices.toast.success('Email je uspješno poslan')
    } catch (e) {
      appServices.toast.danger('Email nije uspješno poslan')
    }
  }

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Datum korištenja',
        accessor: 'ledgerDate',
        minWidth: 70,
        width: 70,
        Cell: props => (
          <p>
            {props.cell.row.original?.ledgerDate
              ? format(props.cell.row.original?.ledgerDate, 'dd.MM.yyyy.')
              : ''}
          </p>
        ),
      },
      {
        Header: 'Datum termina',
        accessor: 'bookingDate',
        minWidth: 70,
        width: 70,
        Cell: props => (
          <p>
            {props.cell.row.original?.ResourceBooking
              ? `${format(
                  props.cell.row.original?.ResourceBooking?.date,
                  'dd.MM.yyyy.'
                )} ${format(
                  props.cell.row.original?.ResourceBooking?.startTime,
                  'HH:mm'
                )}`
              : ''}
          </p>
        ),
      },
      {
        Header: 'Usluga',
        accessor: 'itemDescription',
        minWidth: 200,
        width: 200,
        Cell: props => <p>{props.cell.row.original?.itemDescription}</p>,
      },
      {
        Header: () => <div className="text-end pr-[20px]">{'Iskorišteno'}</div>,
        accessor: 'price',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.creditAmount -
              props.cell.row.original?.debitAmount
          )} ${props.cell.row.original?.Currency?.currencyCode}`}</p>
        ),
      },
      {
        Header: () => <div className=" text-center">{''}</div>,
        accessor: 'seeOnCalendar',
        minWidth: 50,
        width: 50,
        Cell: props => (
          <p className="text-center">
            <Button
              buttonType={'secondary'}
              iconComponent={<Calendar size={'smaller'} />}
              hasIconOnly
              size={'small'}
              onClick={event => {
                // format props.cell.row.original?.ResourceBooking.startTime to hour and minut string using date-fns
                const startTime = moment(
                  props.cell.row.original?.ResourceBooking?.startTime
                ).format('HH:mm')
                const selectedHour = startTime.split(':')
                navigateTo.dailyCalendarAppointment({
                  date: getISODate(
                    props.cell.row.original?.ResourceBooking?.startTime
                  ),
                  reservationId: props.cell.row.original?.ResourceBooking?.id,
                  hour: selectedHour[0],
                  minute: selectedHour[1],
                  type: 'appointment',
                })
              }}
              tooltip={t('translation.NewClientContainer.tooltip-openCalendar')}
            />
          </p>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    trpcContext.giftCard_usage_findMany.invalidate({
      orgId: orgId,
      locationId: BigInt(locationId),
      giftCardId: BigInt(id),
    })
  }, [])

  return (
    <div className="mt-[10px] h-full overflow-y-auto">
      <div className="flex lg:flex-row flex-col w-full border-b border-zoyya-outline font-semibold items-center justify-between lg:min-h-[57px] sticky top-0 bg-white z-10 px-[15px] lg:px-[20px]">
        <div className="lg:max-w-[80%] flex items-center">
          <div className=" [&>button]:px-0 mr-6 hidden lg:block">
            <ButtonTW
              variant="text"
              className="p-0"
              icon={<ArrowLeft />}
              onClick={() =>
                navigateTo.listGiftCard({
                  type: 'giftcard',
                  voucherType: data?.giftCardType?.toLowerCase() || '',
                })
              }
            />
          </div>

          <div className="text-center lg:text-left">
            <span className="text-[20px]">{data?.name}</span>
            <div className="text-zoyya-secondaryDark font-normal flex items-center">
              <span className="mr-[10px]">{`Vrsta predloška: ${data?.giftCardTemplate?.name}`}</span>
            </div>
            {data?.giftCardTemplate?.status === 'DELETED' ? (
              <div className="text-zoyya-accent5 justify-center lg:justify-start font-normal flex items-center pb-[4px]">
                <icons.WarningTriangle size={'smaller'} color={'orange'} />
                <span className="mr-[10px] text-[12px]">
                  Predložak je obrisan
                </span>
              </div>
            ) : null}
            {/*   <div className="text-zoyya-secondaryDark font-normal">
              <span>{`Preostalo: ${formatPriceNumberWithDelimiter(
                data?.balance || 0
              )} ${currency}`}</span>
            </div> */}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-[20px]">
            <span>Oznaka:</span>
            <span className="text-[18px] font-medium ml-[10px]">
              {data?.code}
            </span>
          </div>
          <ButtonTW
            label="Uredi"
            className={'font-semibold'}
            size="small"
            variant="primaryOutline"
            onClick={() => setIsPreviewMode?.(false)}
            icon={<Edit size={'size14'} stroke={2} />}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row">
        <div>
          <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
            <span className="text-lg w-[140px] font-semibold">Iznos:</span>
            <span className="text-lg ml-[10px]">{`${formatPriceNumberWithDelimiter(
              data?.price || 0
            )} ${currency}`}</span>
          </div>

          <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
            <span className="text-lg w-[140px] font-semibold">
              Iskorišteno:
            </span>
            <span className="text-lg ml-[10px]">{`${formatPriceNumberWithDelimiter(
              data?.price - (data?.balance || 0)
            )} ${currency}`}</span>
          </div>

          <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
            <span className="text-lg w-[140px] font-semibold">Preostalo:</span>
            <span className="text-lg ml-[10px]">{`${formatPriceNumberWithDelimiter(
              data?.balance || 0
            )} ${currency}`}</span>
          </div>

          {data?.validTo ? (
            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg w-[140px] font-semibold">
                Vrijedi do:
              </span>
              <span className="text-lg ml-[10px]">
                {format(data?.validTo, 'dd.MM.yyyy.')}
              </span>
            </div>
          ) : null}

          {data?.client ? (
            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px] overflow-hidden">
              <span className="text-lg min-w-[140px] font-semibold">
                Klijent:
              </span>
              <ClientSelect
                showAddNewButton={false}
                disabled
                selectedItem={data?.client}
              />
            </div>
          ) : null}

          {!isGiftCardUsed && data?.client?.email ? (
            <div className="flex items-center mt-[20px] px-[15px] lg:px-[20px] overflow-hidden">
              <span className="text-lg min-w-[140px]"></span>
              <Tooltip
                position={'top'}
                label="Klijent će u e-mailu dobiti informacije o pridruženom poklon bonu"
              >
                <ButtonTW
                  label="Pošalji email klijentu"
                  variant="secondary"
                  type="button"
                  onClick={async e => {
                    await handleResendClientEmail()
                  }}
                />
              </Tooltip>
            </div>
          ) : null}

          {data?.description ? (
            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg min-w-[140px] font-semibold">Opis:</span>
              <span className="text-lg ml-[10px]">{data?.description}</span>
            </div>
          ) : null}
        </div>
        {data?.downloadGiftCardImgUrl || data?.downloadGiftCardQrCodeUrl ? (
          <div className="flex flex-col min-w-[400px] items-center lg:items-end p-[15px]">
            {data?.downloadGiftCardImgUrl ? (
              <img
                className="max-w-[350px]"
                src={data?.downloadGiftCardImgUrl}
              />
            ) : null}

            {/* {data?.downloadGiftCardQrCodeUrl ? (
              <img
                className="max-w-[100px]"
                src={data?.downloadGiftCardQrCodeUrl}
              />
            ) : null} */}
          </div>
        ) : null}
      </div>

      {data?.giftCardType === 'GIFTCARD' ? (
        <div className="flex lg:mt-[60px] mt-[30px] pl-6 w-full bg-zoyya-grayLighter border-zoyya-text/10 border-1">
          <div
            onClick={() => {
              setSelectedTab('usage')
            }}
            className={`mx-2 py-3 cursor-pointer ${
              selectedTab === 'usage'
                ? "after:content-[''] after:flex after:relative after:w-[115%] after:h-[4px] after:-bottom-[13px] after:-left-3 after:bg-zoyya-primary"
                : ''
            }`}
          >
            Aktivnost korištenja
          </div>
          <div
            onClick={() => {
              setSelectedTab('services')
            }}
            className={`ml-8 py-3 cursor-pointer ${
              selectedTab === 'services'
                ? "after:content-[''] after:flex after:relative after:w-[115%] after:h-[4px] after:-bottom-[13px] after:-left-3 after:bg-zoyya-primary"
                : ''
            }`}
          >
            Odobrene usluge
          </div>
          <div
            onClick={() => {
              setSelectedTab('log')
            }}
            className={`ml-8 py-3 cursor-pointer ${
              selectedTab === 'log'
                ? "after:content-[''] after:flex after:relative after:w-[115%] after:h-[4px] after:-bottom-[13px] after:-left-3 after:bg-zoyya-primary"
                : ''
            }`}
          >
            Log izmjena
          </div>
        </div>
      ) : (
        <div className="flex lg:mt-[60px] mt-[30px] pl-6 w-full bg-zoyya-grayLighter border-zoyya-text/10 border-1">
          <div
            onClick={() => {
              setSelectedTab('services')
            }}
            className={`mx-2 py-3 cursor-pointer ${
              selectedTab === 'services'
                ? "after:content-[''] after:flex after:relative after:w-[115%] after:h-[4px] after:-bottom-[13px] after:-left-3 after:bg-zoyya-primary"
                : ''
            }`}
          >
            Sadržaj paketa
          </div>
          <div
            onClick={() => {
              setSelectedTab('usage')
            }}
            className={`ml-8 py-3 cursor-pointer ${
              selectedTab === 'usage'
                ? "after:content-[''] after:flex after:relative after:w-[115%] after:h-[4px] after:-bottom-[13px] after:-left-3 after:bg-zoyya-primary"
                : ''
            }`}
          >
            Aktivnost korištenja
          </div>
          <div
            onClick={() => {
              setSelectedTab('log')
            }}
            className={`ml-8 py-3 cursor-pointer ${
              selectedTab === 'log'
                ? "after:content-[''] after:flex after:relative after:w-[115%] after:h-[4px] after:-bottom-[13px] after:-left-3 after:bg-zoyya-primary"
                : ''
            }`}
          >
            Log izmjena
          </div>
        </div>
      )}
      {selectedTab === 'usage' ? (
        <>
          <div
            className={`flex lg:min-h-[200px] lg:max-h-[600px] h-full w-full mt-[30px] px-[15px] lg:px-[20px]`}
          >
            <DataTable
              data={giftCardUsage || []}
              renderMobileCard={(item: any) => (
                <GiftCardUsageItemMobileCard item={item} />
              )}
              columns={columns}
              isLoading={isLoadingGiftCardUsage}
              emptyListTitle={'Nema zapisa o aktivnosti korištenja'}
              mobileCardHeight={120}
              tableRowHeight={50}
            />
          </div>
        </>
      ) : selectedTab === 'services' ? (
        <>
          <div className="mt-[30px] px-[15px] lg:px-[20px]">
            <div className="bg-zoyya-grayLighter rounded-sm border border-zoyya-border pr-3 pl-[1.143rem]">
              <div className="rounded-md w-full">
                <div className="w-full flex  py-2">
                  {data?.giftCardType === 'GIFTCARD' ? (
                    <>
                      <p className="w-[300px] text-sm text-zoyya-secondaryDark">
                        Vrsta usluge
                      </p>
                      <p className="mr-auto text-sm text-zoyya-secondaryDark">
                        Usluga
                      </p>
                      <div className="w-20"></div>
                    </>
                  ) : (
                    <>
                      <p className="grow text-sm text-zoyya-secondaryDark">
                        Usluga
                      </p>
                      <p className="w-[70px] mr-[14px] text-sm text-zoyya-secondaryDark">
                        Preostalo
                      </p>
                      <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                        Cijena
                      </p>
                      <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                        Vrijednost
                      </p>
                      <p className="w-[40px] mr-[10px] text-center text-sm text-zoyya-secondaryDark">
                        %
                      </p>
                      <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                        Neto
                      </p>
                      <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                        Iznos
                      </p>
                      <div className="w-10"></div>
                    </>
                  )}
                </div>

                {data?.items?.length ? (
                  data?.items?.map(x => (
                    <div
                      key={x?.id.toString()}
                      className="w-full flex py-2 border-t border-zoyya-border "
                    >
                      {data?.giftCardType === 'GIFTCARD' ? (
                        <>
                          <p className="w-[300px]">
                            {x?.serviceTypeId ? x?.name : ''}
                          </p>
                          <p className="mr-auto">
                            {x?.serviceId ? x?.name : 'Sve usluge'}
                          </p>
                          <div className="w-[20px]"></div>
                        </>
                      ) : x?.Service ? (
                        <>
                          <p className="grow text-[13px] mr-[10px]">
                            {x?.name}
                          </p>
                          <p className="min-w-[70px] text-[13px]">
                            {x?.quantity || '0'}
                          </p>
                          <p className="min-w-[70px] mr-[10px] text-[13px]">
                            {`${
                              formatPriceNumberWithDelimiter(
                                x?.Service?.price
                              ) || ''
                            }`}
                          </p>
                          <p className="min-w-[70px] mr-[10px] text-[13px]">{`${formatPriceNumberWithDelimiter(
                            x?.quantity * Number(x?.Service?.price)
                          )}`}</p>
                          <p className="min-w-[40px] mr-[10px] text-center text-[13px]">
                            {formatPriceNumberWithDelimiter(
                              Number(x.discountRate) * 100,
                              false
                            ) || ''}
                          </p>
                          <p className="min-w-[70px] mr-[10px] text-[13px]">
                            {formatPriceNumberWithDelimiter(
                              Number(x?.Service?.price) -
                                roundNumberWithTwoDecimals(
                                  Number(x?.Service?.price) *
                                    Number(x?.discountRate)
                                )
                            )}
                          </p>
                          <p className="min-w-[80px] mr-[10px] text-[13px]">
                            {`${formatPriceNumberWithDelimiter(
                              (Number(x?.Service?.price) -
                                roundNumberWithTwoDecimals(
                                  Number(x?.Service?.price) *
                                    Number(x?.discountRate)
                                )) *
                                x?.quantity
                            )} ${currency}`}
                          </p>

                          <div className="w-[20px] min-w-[20px]"></div>
                        </>
                      ) : null}
                    </div>
                  ))
                ) : data?.giftCardType === 'GIFTCARD' ? (
                  <div className="w-full flex py-2 border-t border-zoyya-border ">
                    <>
                      <p className="w-[300px]"></p>
                      <p className="mr-auto">{'Sve usluge'}</p>
                      <div className="w-[20px]"></div>
                    </>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex p-4">
          <GiftCardChangelog giftCardId={data?.id} />
        </div>
      )}
    </div>
  )
}
const GiftCardUsageItemMobileCard = (props: {
  item: RouterOutput['giftCard_usage_findMany'][0]
}) => {
  const { item } = props

  return (
    <div className="flex flex-row py-4 mx-4 border-b border-zoyya-outline items-center max-w-[100%]">
      <div className="flex flex-col flex-1">
        <div className="flex justify-between mb-2">
          <p className="font-semibold">{'Usluga'}</p>
          <p className="max-w-[70%] truncate whitespace-nowrap">
            {item?.itemDescription}
          </p>
        </div>

        <div className="flex justify-between">
          <p className="font-semibold">{'Datum korištenja: '}</p>
          <p>{format(item?.ledgerDate, 'dd.MM.yyyy.')}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-semibold">{'Datum termina: '}</p>
          <p>{`${format(item?.ResourceBooking?.date!, 'dd.MM.yyyy.')} ${format(
            item?.ResourceBooking?.startTime!,
            'HH:mm'
          )}`}</p>
        </div>
        <div className="flex justify-between">
          <p className="font-semibold">{'Iskorišteno: '}</p>
          <p>{`${formatPriceNumberWithDelimiter(
            item?.creditAmount - item?.debitAmount
          )} ${item?.Currency?.currencyCode}`}</p>
        </div>

        {/* {type === 'giftcard' ? (
          <div className="text-gray-400 mb-2 text-sm flex justify-between">
            <span>{item?.code}</span>
            <span>{`${formatPriceNumberWithDelimiter(
              item?.value
            )} ${currency}`}</span>
          </div>
        ) : (
          <div className="text-gray-400 mb-2 text-sm">
            {item?.items?.length ? (
              <span className="line-clamp-2 text-[12px]">
                {item?.items?.map((x, idx) => {
                  return (
                    // @ts-ignore
                    x?.itemName + (idx < item?.items?.length - 1 ? ', ' : '')
                  )
                })}
              </span>
            ) : null}
          </div>
        )} */}
      </div>
    </div>
  )
}
