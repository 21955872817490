import React from 'react'
import { useTranslation } from 'react-i18next'
import { GroupBookingSlot } from '../types'
import { getStatusColor } from 'src/helpers'
import { clsx } from 'clsx'
export const GroupSlotStatusPresenter = (props: { slot: GroupBookingSlot }) => {
  const { slot } = props
  const { t } = useTranslation()
  const reason = slot.reason
  const statusLabel = () => {
    switch (slot.status) {
      case 'CONFIRMED':
        return t('translation.status.confirmed')
      case 'new':
        return t('translation.status.new')
      case 'DRAFT':
        return t('translation.status.draft')
      case 'PENDING_PAYMENT':
        return t('translation.status.payment-pending')
      case 'PAID':
        return t('translation.status.paid')
      case 'CANCELED':
        return t('translation.status.payment-canceled')
      case 'REFUNDED':
        return t('translation.status.payment-refunded')
      case 'canceled':
        return reason === 'CLIENT_NO_SHOW'
          ? t('translation.helpers.noShow')
          : reason === 'CLIENT_CANCELED'
          ? t('translation.helpers.clientCanceled')
          : t('translation.status.canceled')
      case 'concluded':
        return t('translation.helpers.concluded')
      default:
        return t('translation.status.new')
    }
  }

  const className = clsx(
    `flex flex-col  text-sm leading-[16px] items-center  justify-center  p-1 pb-0  rounded-sm`,
    { 'bg-green-500 text-white': slot.status === 'CONFIRMED' },
    { 'bg-zoyya-primary text-white': slot.status === 'new' },
    { 'bg-zoyya-accent5 text-white': slot.status === 'DRAFT' },
    { 'bg-zoyya-accent5 text-white': slot.status === 'PENDING_PAYMENT' },
    { 'bg-green-500 text-white': slot.status === 'PAID' },
    { 'bg-zoyya-accent1 text-white': slot.status === 'CANCELED' },
    { 'bg-zoyya-accent5 text-white': slot.status === 'REFUNDED' },
    { 'bg-zoyya-accent1 text-white': slot.status === 'canceled' },
    { 'bg-zoyya-accent1 text-white': slot.status === 'noShow' },
    { 'bg-zoyya-primary text-white': slot.status === 'concluded' }
  )

  return <div className={className}>{statusLabel()}</div>
}
