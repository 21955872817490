import React from 'react'
import styled from 'styled-components'
import * as styles from './style'
const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export function WhatsApp(props) {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="none"
        stroke="green"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 .5h0C5.677.482.532 5.586.5 11.909h0a11.289 11.289 0 002.19 6.69l-1.435 4.239 4.419-1.405h0c5.315 3.488 12.451 2.007 15.94-3.308A11.511 11.511 0 0023.5 11.91h0C23.468 5.587 18.324.482 12 .5m6.907 14.535c-.084-.138-.307-.223-.642-.388s-1.985-.972-2.291-1.082c-.306-.11-.532-.167-.755.166-.223.333-.866 1.081-1.062 1.3-.196.219-.391.25-.726.084h0a9.188 9.188 0 01-2.7-1.651h0a9.976 9.976 0 01-1.864-2.3c-.2-.332-.021-.512.147-.678h0c.352-.32.636-.707.838-1.138h0a.606.606 0 00-.028-.583C9.743 8.6 9.072 6.962 8.793 6.3c-.316-.756-1.725-.782-2.292-.168-1.789 1.941-1.376 4.14.2 6.215.168.222 2.32 3.691 5.728 5.023 3.408 1.332 3.408.887 4.023.831 1.767-.159 2.963-2.323 2.459-3.163"
      ></path>
    </StyledIcon>
  )
}
